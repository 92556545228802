let hasValidCompanyStatus = false;
export const getHasValidCompanyStatus = () => hasValidCompanyStatus;
export const setHasValidCompanyStatus = (has = false) => {
  hasValidCompanyStatus = has;
};

let hasValidEmployeeStatus = false;
export const getHasValidEmployeeStatus = () => hasValidEmployeeStatus;
export const setHasValidEmployeeStatus = (has = false) => {
  hasValidEmployeeStatus = has;
};
