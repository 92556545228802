import * as FullStory from '@fullstory/browser';
import BillingService, { getPricePlanById } from '@/services/billing.service';

export default ({ app }) => {
  window.onNuxtReady(() => {
    const { $axios } = app;
    BillingService($axios);

    if (process.env.fullstory_org_id) {
      FullStory.init({
        orgId: process.env.fullstory_org_id,
      });
    }

    app.store.subscribe(({ type }, state) => {
      if (['setCurrentUser', 'setCurrentCompany'].includes(type)) {
        if (process.env.fullstory_org_id) {
          if (state.currentUser) {
            const { _id, role, _auth, profile, _company } = state.current_user;
            const fsUser = {
              role,
              displayName: profile.full_name,
              email: _auth.email,
              companyId: _company._id,
              companyName: _company.name,
              companyDBA: _company.profile?.dba,
              companyCreatedAt: new Date(_company.created_at),
              companyConvertedAt: new Date(_company.converted_at),
              companyIsTest: _company.test,
            };
            getPricePlanById(_company.plan)
              .then(({ tierNumber }) => {
                fsUser.tierNumber = tierNumber;
              })
              .catch((err) => {
                console.error(
                  `error getting price plan for ${_company.plan}`,
                  err
                );
              })
              .finally(() => {
                FullStory.identify(_id, fsUser);
              });
          }
        }
      }
    });
  });
};
