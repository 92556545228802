




























import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import { wizardActionState } from '@/modules/payroll/pages/employer/onboarding/OnboardingRoot.vue';
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  onMounted,
} from '@nuxtjs/composition-api';
import {
  overrideTransitionClass,
  TransitionClass,
} from '@/components/WizardFlow/WizardFlow.vue';
import {
  PayFrequency,
  ProcessingPeriod,
} from '@/modules/payroll/constants/payroll';
import {
  Company,
  DefaultPayScheduleDay,
  PaySchedule,
} from '@/modules/payroll/types/company';

import { TypeDisplay, TypeBody, BaseCard, BaseBanner } from '@bambeehr/pollen';
import PayScheduleList from '@/modules/payroll/components/PaySchedule/PayScheduleList.vue';
import PayScheduleDetails from '@/modules/payroll/components/PaySchedule/PayScheduleDetails.vue';
import {
  formatPayScheduleDays,
  formatPaySchedule,
} from '@/modules/payroll/components/PaySchedule/payScheduleHelpers';

import { trackEvent } from '@/modules/payroll/utils/track-apa';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';

export enum ProcessingPeriodLabel {
  FOUR_DAY = '4 days',
  TWO_DAY = '2 days',
}

function setWizardActionState() {
  wizardActionState.value = {
    next: {
      label: 'Continue',
      action: (next) => {
        next();
      },
    },
    finishLater: {
      label: 'Finish Later',
      action: (finishLater) => {
        finishLater();
      },
    },
    back: {
      label: 'Back',
      action: (back) => {
        overrideTransitionClass.value = TransitionClass.BACKWARDS;
        back('/payroll-setup/pay-schedule/create');
      },
    },
    disableNext: false,
  };
}

export default defineComponent({
  name: 'PaySchedulePreview',
  components: {
    BaseBanner,
    TypeDisplay,
    TypeBody,
    BaseCard,
    PayScheduleList,
    PayScheduleDetails,
  },
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
  },
  setup(props) {
    const { getDefaultPayScheduleDays } = usePayrollService();

    const company = computed(() => props.company);

    const payScheduleDays = computed<DefaultPayScheduleDay[]>(
      () => company.value.defaultPayScheduleDays
    );
    const paySchedule = computed<PaySchedule | null>(
      () => company.value.paySchedules?.find((s) => s.default) || null
    );

    const hasFourDayProcessing = computed<boolean>(
      () => company.value.processingPeriod === ProcessingPeriod.FOUR_DAY
    );
    const processingPeriodLabel = computed<string>(() =>
      hasFourDayProcessing.value
        ? ProcessingPeriodLabel.FOUR_DAY
        : ProcessingPeriodLabel.TWO_DAY
    );
    const payFrequencyLabel = computed<string>(
      () =>
        Object.values(PayFrequency).find(
          (f) => f.value === paySchedule.value?.payFrequency
        )?.label || ''
    );

    const allPayScheduleDays = ref<DefaultPayScheduleDay[]>([]);
    const formattedPayScheduleDays = computed<string[][]>(() =>
      formatPayScheduleDays(payScheduleDays.value, allPayScheduleDays.value)
    );
    const formattedPaySchedule = computed<PaySchedule>(() =>
      formatPaySchedule(paySchedule.value)
    );

    const loadingAllDays = ref(false);
    function getAllScheduledPaydays(): void {
      if (loadingAllDays.value) {
        return;
      }

      const { data } = getDefaultPayScheduleDays(company.value.id, {
        pending: loadingAllDays,
      });

      watch(data, (res: Partial<Company>) => {
        allPayScheduleDays.value =
          res.defaultPayScheduleDays as DefaultPayScheduleDay[];
      });
    }

    const showFourDayBanner = ref(true);
    function closeProcessingPeriodBanner() {
      showFourDayBanner.value = false;
    }

    setWizardActionState();

    // Reset top position
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    // APA Tracking
    const { isOnboarding } = usePayrollOnboardingStatus();

    if (isOnboarding.value) {
      trackEvent('payroll-onboarding-pay-schedule-preview');
    }

    return {
      allPayScheduleDays,
      formattedPaySchedule,
      formattedPayScheduleDays,
      getAllScheduledPaydays,
      hasFourDayProcessing,
      processingPeriodLabel,
      payFrequencyLabel,
      closeProcessingPeriodBanner,
      showFourDayBanner,
      paySchedule,
    };
  },
});
