import FeatureFlags from '@/constants/FeatureFlags';
import Store from '@/constants/Store';
import { BILLING } from '@/lib/globals/routes';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import useMiddlewareContext from './hooks/useMiddlewareContext';

export default () => {
  const { store, route, redirect } = useMiddlewareContext();

  const featureFlagEnabled =
    launchDarkly.getFlags()[FeatureFlags.LOCK_LONG_STANDING_DELINQUENTS];
  const isCompanyAdmin = store.getters[Store.auth.Getters.IS_COMPANY_ADMIN];

  // verify if is trying to access one of the restricted routes
  const blacklistedRoutes = [
    '/employee',
    '/insurance',
    '/policy',
    '/report-cards',
    '/requests',
  ];
  const blacklisted = blacklistedRoutes.some((white) =>
    route.path.includes(white)
  );
  if (!isCompanyAdmin || !featureFlagEnabled || !blacklisted) {
    return;
  }

  // if company is a long standing delinquent, don't let them enter the route
  const isLongStandingDelinquent =
    store.getters[Store.auth.Getters.IS_LONG_STANDING_DELINQUENT];
  if (isLongStandingDelinquent) {
    redirect(BILLING);
  }
};
