<template>
  <div>
    <BreadCrumbs :crumbs="crumbs" />
    <div class="flex justify-between items-center mb-4 -mt-6">
      <TypeDisplay variant="large" class="text-base-900">
        Browse Training
      </TypeDisplay>
    </div>
    <ContentCatalog />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { TypeDisplay } from '@bambeehr/pollen';
import ContentCatalog from '@/modules/Training/components/ContentCatalog/ContentCatalog.vue';
import BreadCrumbs from '@/components/BreadCrumbs';

const crumbs = [
  {
    label: 'Training',
    to: '/training',
  },
  {
    label: 'Browse',
  },
];

export default defineComponent({
  name: 'CourseCatalog',
  components: {
    ContentCatalog,
    TypeDisplay,
    BreadCrumbs,
  },

  setup: () => ({ crumbs }),
});
</script>
