const tos = `
<h1>DEPUTY EMBEDDED EDITION SUPPLEMENTAL TERMS</h1>
<p>
  These Embedded Terms amend and supplement the Customer Agreement between you
  (<b>Customer</b>) and the entity through which Customer has access to the
  Embedded Edition (<b>Company</b>) solely with regard to the Embedded Edition
  (defined below) hosted and provided by or on behalf of Deputy Corporation
  (<b>Deputy</b>). These Embedded Terms, together with the Customer Agreement,
  govern all integrations to and from the Company Service using the Embedded
  Edition. In the event of any inconsistency between the Customer Agreement and
  these Embedded Terms, these Embedded Terms shall prevail with respect to the
  Embedded Edition.
</p>
<p>
  By accessing or using the Embedded Edition, Customer acknowledges and agrees
  it has read, understood and agrees to be bound by these Embedded Terms
  (including all terms specified or referenced in these Embedded Terms). The
  effective date of these Embedded Terms shall be the date Customer first
  accesses or uses the Embedded Edition.
</p>
<h1>Definitions.</h1>
<p>
  Acceptable Use Policy
  means the Acceptable Use Policy at www.deputy.com/terms
</p>
<p>
  Authentication Credentials<span class="p"
    >means the username and password or other means of authentication which
    Customers or Authorized User are required to provide in order to be able to
    access the Embedded Edition.</span
  >
</p>
<p>
  Authorized User<span class="p"
    >means individuals: (i) who are authorized by Customer to access the
    Embedded Edition;</span
  >
</p>
<p>
  (ii) who have accepted the User Terms; and (iii) to whom the Customer (or,
  when applicable, Deputy at Customer’s request) has supplied a user
  identification and password. Authorized Users may include employees,
  consultants, agents or contractors of Customer.
</p>
<p>
  Company Service<span class="p"
    >means Company’s hosted service and/or products provided to Customer under
    the Customer Agreement, excluding the Deputy Platform, Embedded Edition and
    all associated products and/or services provided by Deputy.</span
  >
</p>
<p>
  Customer Agreement<span class="p"
    >means the agreement and any applicable order forms between Company and
    Customer to which Company is providing the Embedded Edition as part of the
    Company Service to Customer.</span
  >
</p>
<p>
  Customer Data<span class="p"
    >means electronic data and information submitted by or on behalf of Customer
    or an Authorized User to the Embedded Edition. It does not include
    de-identified or aggregated data.</span
  >
</p>
<p>
  Deputy Platform<span class="p"
    >means the employee scheduling, time and attendance, task management,
    business procedure management, payroll integration, workplace social media
    services and other online software applications made available by Deputy to
    its customers from time to time.</span
  >
</p>
<p>
  DPA means the Data Processing Addendum at
  www.deputy.com/terms
</p>
<p>
  Embedded Edition<span class="p"
    >means the customized version of the Deputy Platform that is embedded into
    the Company Service.</span
  >
</p>
<p>
  Embedded Software Agreement<span class="p"
    >means the agreement between Company and Deputy governing the provision of
    the Embedded Edition to Company to satisfy its obligations in the Customer
    Agreement.</span
  >
</p>
<p>
  Embedded Terms<span class="p"
    >means these Deputy Embedded Edition Supplemental Terms, which are deemed to
    include, to the extent applicable, the Product Specific Terms.</span
  >
</p>
<p>
  Excluded Data<span class="p"
    >means personal data or personal information which is considered ‘sensitive’
    under any applicable law, or relates to medical conditions of an individual.
    For avoidance of doubt, Excluded Data shall include data covered by Article
    9 of European Union Regulation (EU) 2016/679, Protected Health Information
    as defined by the United States Health Insurance Portability and
    Accountability Act, and Health Information as defined by the Australian
    Privacy Act 1988 (Cth).</span
  >
</p>
<p>
  Non-Deputy Application<span class="p"
    >means a web-based, mobile, offline or other software application with
    functionality that interoperates with the Deputy Platform but is solely
    provided by a third party (including Company or Customer).</span
  >
</p>
<p>
  Privacy Policy<span class="p"
    >means the Privacy Policy at deputy.com/terms.</span
  >
</p>
<p>
  Product Specific Terms means the supplemental terms and conditions that apply to specific Deputy
    products or features and published atwww.deputy.com/terms
</p>
<p>
  User Terms<span class="p"
    >means the User Terms of Service provided by Deputy to persons invited by
    the Customer to become Authorized Users following the Customer’s acceptance
    of these Embedded Terms.</span
  >
</p>
<h1>Use of the Embedded Edition.</h1>
<p>
  During the term of the Customer Agreement and subject to Customer’s compliance
  with the provisions of the
</p>
<p>
  Customer Agreement and these Embedded Terms, Customer will have a worldwide,
  non-exclusive, non-transferable, non-sublicensable and revocable right to use
  the Embedded Edition via the Company Service for its internal business
  purposes.
</p>
<p>
  In connection with the foregoing license, Customer may allow an Authorized
  User to access and use the Embedded Edition. A subscription is required for
  each Authorized User. Each Authorized User must agree to the User Terms to
  activate their access and use of the Embedded Edition.
</p>
<p>
  If Customer wishes to use the Deputy Platform or any of its other
  functionalities or services that are not included in the Embedded Edition,
  Customer may visit deputy.com to contract directly with Deputy for such
  functionalities or services.
</p>
<h1>Customer responsibilities and restrictions.</h1>
<p class="s1">
  Responsibilities.<span class="p"
    >Customer will (i) be responsible for Authorized Users’ compliance with
    these Embedded Terms (including the Product Specific Terms), the User Terms
    and for all acts or omissions of Authorized Users, (ii) be responsible for
    the accuracy, quality and legality of Customer Data, the means by which
    Customer acquired Customer Data, Customer’s use of Customer Data with the
    Embedded Edition, and the interoperation of any Non-Deputy Applications with
    which Customer uses the Embedded Edition, (iii) be responsible for ensuring
    that the use of the Embedded Edition (including the use of any outputs of
    the Embedded Edition) by the Customer and each of its Authorized Users
    complies with any applicable laws, Legal Requirements (as defined in the
    Product Specific Terms) and the Acceptable Use Policy, (iv) use commercially
    reasonable efforts to prevent unauthorized access to or use of the Embedded
    Edition (including the processing of Excluded Data by the Embedded Edition),
    (v) use the Embedded Edition only in accordance with these Embedded Terms
    and any applicable laws, (vi) comply with terms of service of any Non-Deputy
    Applications with which Customer uses the Embedded Edition, and (vii) take
    reasonable steps to ensure that all Authorized Users keep their
    Authentication Credentials strictly confidential in accordance with the User
    Terms.</span
  >
</p>
<p class="s1">
  Restrictions.<span class="p"
    >Customer will not (i) make the Embedded Edition available to anyone other
    than Customer or Authorized Users, (ii) sell, resell, license, sublicense,
    distribute, rent, lease, transfer or provide access to the Embedded Edition
    to a third party, (iii) use the Embedded Edition or Non-Deputy Application
    to store or transmit infringing, libelous, or otherwise unlawful or tortious
    material, or to store or transmit material in violation of third-party
    privacy rights, (iv) use the Embedded Edition or Non-Deputy Application to
    store or transmit malicious code, (v) interfere with or disrupt the
    integrity or performance of the Embedded Edition or third-party data
    contained therein, (vi) attempt to gain unauthorized access to the Embedded
    Edition, (vii) permit direct or indirect access to or use of the Embedded
    Edition in a way that circumvents a contractual usage limit, or use the
    Embedded Edition to access or use any of Deputy intellectual property except
    as permitted under these Embedded Terms, (viii) modify, copy, or create
    derivative works based on the Embedded Edition or any part, feature,
    function or user interface thereof, (ix) frame or mirror any part of the
    Embedded Edition, other than framing on Customer&#39;s own intranets or
    otherwise for its own internal business purposes, (x) except to the extent
    permitted by any applicable laws (and then only upon advance notice to
    Deputy), disassemble, reverse engineer, decompile, translate or otherwise
    seek to obtain or derive the source code, underlying ideas, algorithms, file
    formats or non-public APIs to the Embedded Edition, or (xi) access or use
    the Embedded Edition to: (A) build a competitive product or service, (B)
    build a product or service using similar ideas, features, functions or
    graphics of the Embedded Edition, (C) copy any ideas, features, functions or
    graphics of the Embedded Edition, or (D) determine whether the Embedded
    Edition is within the scope of any patent. Deputy’s competitors are
    prohibited from accessing the Embedded Edition and may not be accessed for
    purposes of monitoring their availability, performance or functionality, or
    for any other benchmarking or competitive purposes.</span
  >
</p>
<p>
  Non-Deputy Applications.<span class="p"
    >The Embedded Edition may contain features designed to interoperate with
    Non-Deputy Applications. Deputy cannot guarantee the continued availability
    of such features. Customer is solely responsible for acquiring the licenses
    and accepting and complying with the applicable terms and conditions and
    privacy policies of Non-Deputy Applications. Customer is solely responsible
    for its own use, and any data loss or other losses it may suffer as a result
    of using such Non-Deputy Applications. Customer agrees that Deputy shall
    have no obligations or liability related to any integration with Non-Deputy
    Applications.</span
  >
</p>
<h1>Proprietary rights and licenses.</h1>
<p class="s1">
  Reservation of rights.<span class="p"
    >Subject to the limited rights expressly granted under these Embedded Terms,
    Deputy, its affiliates and its licensors reserve all of their right, title
    and interest in and to the Deputy Platform and the Embedded Edition,
    including all of their related intellectual property rights. No rights are
    granted to Customer other than as expressly provided in these Embedded
    Terms.</span
  >
</p>
<p class="s1">
  License by Customer to Deputy.<span class="p"
    >(i) Customer grants Deputy and its affiliates a worldwide, limited-term
    license to sub-license, host, copy, use, transmit, and display any Customer
    Data, to the extent required for Deputy to provide and ensure proper
    operation of the Embedded Edition in accordance with these Embedded Terms.
    If Customer chooses to use a Non-Deputy Application, Deputy may allow the
    Non-Deputy Application and its provider to use and access Customer Data and
    information about Customer’s usage of the Non-Deputy Application, as
    appropriate, in connection with the supply of the Embedded Edition. Subject
    to the limited licenses granted herein, Deputy acquires no right, title or
    interest from Customer or its licensors under these Embedded Terms in or to
    any Customer Data, Non-Deputy Application or such program code. (ii)
    Customer grants to Deputy and its affiliates a worldwide, perpetual,
    irrevocable, royalty-free license to use and incorporate into its services
    any: (A) suggestion, enhancement request, recommendation, correction or
    other feedback provided by Customer or Authorized Users relating to the
    operation of Deputy’s or its affiliates’ services; and (ii) information
    available to Deputy relating to Customer’s or its affiliate’s use of the
    Embedded Edition, including Customer Data, but only in an aggregate or
    statistical composite form and combined with other similar information, such
    that the information used by Deputy or its affiliates will not specifically
    identify any Customer or affiliate of the Customer or any of their
    employees, agents, contractors, clients or Authorized Users. For clarity,
    the rights granted to Deputy under this section continue after the
    termination or expiration of these Embedded Terms.</span
  >
</p>
<p>
  No warranty.<span class="p"
    >TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE EMBEDDED EDITION IS
    PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND DEPUTY EXPRESSLY
    DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED,
    STATUTORY OR OTHERWISE, AND EACH PARTY SPECIFICALLY DISCLAIMS ALL IMPLIED
    WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
    PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT. WITHOUT LIMITING THE
    FOREGOING, DEPUTY DOES NOT WARRANT THAT THE CUSTOMER’S USE OF THE EMBEDDED
    EDITION WILL BE UNINTERRUPTED, SECURE OR FREE FROM ERROR OR THAT DEPUTY WILL
    PRESERVE OR MAINTAIN CUSTOMER DATA WITHOUT LOSS OR DAMAGE, OR THAT USE OF
    THE EMBEDDED EDITION WILL RESULT IN ANY PARTICULAR BUSINESS OR OTHER
    OUTCOME.</span
  >
</p>
<p>
  Indemnification.<span class="p"
    >Customer shall defend, indemnify and hold harmless Deputy, its affiliates
    and each of its and its affiliates’ officers, directors, agents and
    employees from all liabilities, claims and expenses (including reasonable
    attorneys’ fees) that arise from or relate to any third party claim (a)
    alleging that any Customer Data infringes or misappropriates such third
    party’s intellectual property rights, proprietary rights or any applicable
    laws, or (b) arising from Customer’s use of the Embedded Edition in
    violation of these Embedded Terms or any applicable laws.</span
  >
</p>
<p>
  Limitation of liability.<span class="p"
    >TO THE EXTENT NOT PROHIBITED BY LAW, UNDER NO CIRCUMSTANCES, INCLUDING BUT
    NOT LIMITED TO, NEGLIGENCE, SYSTEM FAILURE OR NETWORK OUTAGE, WILL EITHER
    PARTY OR ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL,
    CONSEQUENTIAL, PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES THAT RESULT FROM
    THESE EMBEDDED TERMS, EVEN IF SUCH PARTY OR ITS AUTHORIZED REPRESENTATIVE
    HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT
    PERMITTED BY LAW, IN NO EVENT WILL DEPUTY’S TOTAL LIABILITY FOR ANY CLAIMS
    EXCEED US$100.</span
  >
</p>
<p>
  Processing of personal data / personal information.<span class="p"
    >The terms of each of the DPA and the Privacy Policy are incorporated by
    reference into these Embedded Terms.</span
  >
</p>
<h1>Company Service.</h1>
<p>
  Notwithstanding any access Customer may have to the Embedded Edition via the
  Company Service, Company is the sole provider of the Company Service. Company
  and Customer alone are entering into a contractual relationship in relation to
  the Company Service. In the event that Company ceases operations or otherwise
  ceases or fails to provide the Company Service, Deputy cannot provide either
  the Company Service or the Embedded Edition to Customer nor refund Customer
  any fees paid by Customer to Company.
</p>
<p>
  All technical support, questions, complaints or claims related to the Company
  Service and the Embedded Edition will be provided by Company. Customer may not
  contact Deputy for any technical support, questions, complaints or claims for
  the Company Service or the Embedded Edition.
</p>
<p>
  Termination and suspension.<span class="p"
    >Customer’ access to and use of the Embedded Edition (including all of
    Customer’s rights granted in these Embedded Terms) may be terminated and/or
    suspended if: (a) Customer and/or any Authorized Users are in breach of
    these Embedded Terms, or are otherwise engaging in any actions that threaten
    the security, integrity, availability or stability of the Deputy Platform,
    (b) the Embedded Software Agreement has expired or is terminated, or (c)
    Company is in breach of the Embedded Software Agreement.</span
  >
</p>
`;

export default tos;
