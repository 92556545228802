<template>
  <div class="flex items-center">
    <BaseButton
      variant="secondary"
      size="large"
      flat
      class="mr-4"
      @click="handleCancel"
    >
      Cancel
    </BaseButton>

    <BaseButton
      variant="primary"
      size="large"
      :disabled="!allowSave"
      class="transition-all"
      @click="handleSave"
    >
      <span v-if="state.saving" class="flex items-center">
        {{ state.loadingText }}
        <AnnularThrobber size="sm" class="ml-2" />
      </span>
      <span v-else-if="state.overAllocated">
        Purchase &amp; Enroll • {{ state.overAllocatedCost }}
      </span>
      <span v-else>Enroll Staff</span>
    </BaseButton>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { BaseButton, AnnularThrobber } from '@bambeehr/pollen';
import useTrainingEnrollmentForm from '@/modules/Training/hooks/useTrainingEnrollmentForm/useTrainingEnrollmentForm';

export default {
  name: 'TrainingEnrollmentFormActions',
  components: {
    AnnularThrobber,
    BaseButton,
  },
  setup(_, { emit }) {
    const { state, save, cancel } = useTrainingEnrollmentForm();

    const handleCancel = () => {
      cancel();
      emit('cancel');
    };

    const onSave = (enrollmentNumber) => {
      emit('save', enrollmentNumber);
    };

    const onError = () => {
      emit(
        'error',
        `Sorry! We couldn't enroll your employees. Please try again.`
      );
    };

    const handleSave = () => {
      const enrollmentNumber = state.selected.length;

      save(() => {
        onSave(enrollmentNumber);
      }, onError);
    };

    const allowSave = computed(() => !!state.selected.length && !state.saving);

    return {
      state,
      handleCancel,
      handleSave,
      allowSave,
    };
  },
};
</script>
