import useMiddlewareContext from './hooks/useMiddlewareContext';

export default () => {
  const { store } = useMiddlewareContext();

  if (store.state.current_user.role !== 'user') {
    return;
  }

  if (store.state.current_user._auth.TOSAccepted) {
    return;
  }

  if (store.state.showedTermsOfService) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return store.commit('showTermsOfService', true);
};
