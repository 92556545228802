import Vue from 'vue';
import moment from 'moment';
import VueCurrencyFilter from 'vue-currency-filter';
import { insuranceLines } from '@/lib/globals/insurance';
import UsStates from '@/lib/globals/UsStates.json';

const US_STATES = UsStates.reduce((hash, state) => {
  hash[state.abbreviation] = state.name;

  return hash;
}, {});

export default () => {
  Vue.use(VueCurrencyFilter, {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
  });

  Vue.filter('capitalize', (val) => (val ? val.toUpperCase() : null));
  Vue.filter('date', (val, format = 'M/D/YYYY') =>
    val ? moment(val).format(format) : null
  );
  Vue.filter('time', (val, format = 'h a') =>
    val ? moment(val).format(format) : null
  );
  Vue.filter('dateAgo', (val) => (val ? moment(val).fromNow() : null));
  Vue.filter('capLabel', (type) => {
    switch (type) {
      case 'pip':
      case 'Pip':
        return 'Performance Improvement Plan';
      case 'written-warning':
      case 'Writtenwarning':
        return 'Written Warning';
      case 'verbal-warning':
      case 'Verbalwarning':
        return 'Verbal Warning';
      case 'incident':
      case 'Incident':
        return 'Incident';
      default:
        return type;
    }
  });
  Vue.filter('preview', (val) =>
    val
      .substr(0, 120)
      .substr(
        0,
        Math.min(val.substr(0, 120).length, val.substr(0, 120).lastIndexOf(' '))
      )
  );

  Vue.filter('capitalizeWords', (val) => {
    if (!val) {
      return '';
    }

    return val.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  });

  Vue.filter('policyPretty', (val) => {
    switch (val) {
      case 'handbook':
        return 'Employee Handbook';
      case 'employee_agreement':
        return 'Employee Agreement';
      case 'harassment_policy':
        return 'Harassment and Discrimination Policy';
      case 'hipaa_notice':
        return 'HIPAA Notice';
      case 'employee_notice':
        return 'Employee Notice';
      default:
        return val;
    }
  });

  Vue.filter('insuranceLineTypeName', (val) => {
    return (val && insuranceLines[val] && insuranceLines[val].name) || '';
  });

  Vue.filter('sanitizeHtml', (val) => {
    const htmlRegex = /(<([^>]+)>)/gi;
    if (val) {
      return val.replace(htmlRegex, '');
    }

    return '';
  });

  Vue.filter('phone', (val) => {
    if (!val) {
      return '';
    }

    const cleaned = `${val}`.replace(/\D/g, '');
    const cleanedSliced = cleaned.length === 11 ? cleaned.slice(1) : cleaned;
    const match = cleanedSliced.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return val;
  });

  Vue.filter('companySizeMap', (val) => {
    switch (val) {
      case '25':
      case '20':
      case '20y':
        return '1-20';
      case '50':
      case '50y':
        return '21-450';
      case '150':
      case '150y':
        return '51-99';
      default:
        return '';
    }
  });

  Vue.filter('stateName', (val) => {
    return (val && US_STATES[val]) || '';
  });
};
