


































import { defineComponent } from '@nuxtjs/composition-api';
import { BaseButton, TypeDisplay, TypeBody } from '@bambeehr/pollen';

export default defineComponent({
  name: 'TourCard',
  components: {
    BaseButton,
    TypeDisplay,
    TypeBody,
  },
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    nextBtn: {
      type: Object,
      default: () => ({}),
    },
    prevBtn: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
});
