<template>
  <portal to="crumbs">
    <TypeDisplay
      class="hidden lg:block text-base-800"
      tag="h2"
      variant="small"
      weight="strong"
    >
      <slot />
    </TypeDisplay>
    <TypeBody
      variant="text-medium-tight"
      weight="regular"
      class="lg:hidden text-gray-0"
      tag="h2"
    >
      <slot />
    </TypeBody>
  </portal>
</template>

<script>
import { TypeDisplay, TypeBody } from '@bambeehr/pollen';

export default {
  name: 'PageTitle',
  components: {
    TypeDisplay,
    TypeBody,
  },
};
</script>
