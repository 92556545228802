import bam from '@/lib/bam';
import { AIChatModalEvents } from '@/modules/AI/constants/events';
import { ref } from '@nuxtjs/composition-api';

const showAIChat = ref(false);

const closeAIChat = () => {
  bam.trackEvent(AIChatModalEvents.CLOSE);
  showAIChat.value = false;
};

const openAIChat = () => {
  bam.trackEvent(AIChatModalEvents.OPEN);
  showAIChat.value = true;
};

const useAIChatModal = () => {
  return {
    showAIChat,
    closeAIChat,
    openAIChat,
  };
};

export default useAIChatModal;
