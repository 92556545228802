import { PayTypes } from '@/modules/payroll/constants/payroll';
import { Contractor, CoreUser, Employee } from '@/gql/generated';
import { formatDate } from '@/utils/date';

const emptyAddress = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: '',
};

const getUnifiedStaffDrawerList = (
  coreStaffList: CoreUser[],
  payrollStaffList: (Employee | Contractor)[]
  // Leaving as any until we have a single source for data query a mutation for core.
  // Coalescing the two formats for this data is too complicated and will be short lived.
): any[] => {
  if (!coreStaffList || !payrollStaffList) {
    return [];
  }

  return coreStaffList.map((coreStaff) => {
    const matchingPayrollStaff = payrollStaffList.find(
      (i) => i.id === coreStaff.id
    );

    return {
      id: coreStaff.id,
      hasPayroll: !!matchingPayrollStaff,
      checkId: matchingPayrollStaff?.checkId,
      paymentMethodPreference: matchingPayrollStaff?.paymentMethodPreference,
      checkOnboarding: matchingPayrollStaff?.checkOnboarding,
      workplace: (matchingPayrollStaff as Employee)?.workplaces?.[0],
      bankAccounts: matchingPayrollStaff?.bankAccounts,
      createdAt: matchingPayrollStaff?.createdAt,
      profile: {
        contractor: coreStaff?.employment?.isContractor,
        contractor_business_name: coreStaff?.employment?.contractorBusinessName,
        contractor_type:
          coreStaff?.employment?.contractorType?.toLowerCase() || '',
        dob: coreStaff?.profile?.dob
          ? formatDate(coreStaff?.profile?.dob, '')
          : '',
        employee_type: coreStaff?.employment?.employeeType?.toLowerCase() || '',
        first_name: coreStaff?.profile?.firstName,
        last_name: coreStaff?.profile?.lastName,
        pay_rate: Number(coreStaff?.employment?.payRate || 0),
        phone: coreStaff?.profile?.phone
          ? coreStaff?.profile?.phone.substring(
              coreStaff?.profile?.phone.length - 10
            )
          : '',
        start_date: coreStaff?.employment?.startDate
          ? formatDate(coreStaff?.employment.startDate, '')
          : '',
        role: coreStaff?.employment?.title || '',
        type: coreStaff?.employment?.payType?.toUpperCase() || PayTypes.HOURLY,
        homeAddress:
          coreStaff?.profile?.address ||
          matchingPayrollStaff?.profile?.homeAddress ||
          emptyAddress,
      },
      _auth: {
        email: coreStaff?.profile?.email,
      },
    };
  });
};

export default getUnifiedStaffDrawerList;
