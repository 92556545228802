











import { defineComponent } from '@nuxtjs/composition-api';
import { BaseCard } from '@bambeehr/pollen';
import TrainingRegistrationHeader from '@/modules/Training/components/TrainingRegistrationHeader';

export default defineComponent({
  name: 'TrainingRegistrationCard',
  components: {
    BaseCard,
    TrainingRegistrationHeader,
  },
});
