






























































import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TinLogo',
  props: {
    blur: {
      type: Boolean,
      default: true,
    },
    move: {
      type: Boolean,
      default: true,
    },
    glow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 140,
    },
    bgColor: {
      type: String,
      default: '#e9e8f7',
    },
  },
  setup(props) {
    const height = computed(() => props.width * 1.1);
    const dimensions = computed(
      () => `width: ${props.width}px; height: ${height.value}px`
    );

    const hasThinBorders = computed(() => props.width < 80);

    return {
      dimensions,
      hasThinBorders,
    };
  },
});
