import useMiddlewareContext from './hooks/useMiddlewareContext';

export default () => {
  const { route, redirect, _auth } = useMiddlewareContext();

  const { activation_status } = _auth;

  if (activation_status === 'password-reset') {
    if (['reset-password'].includes(route.path)) {
      return;
    }

    if (route.path.includes('/reset-password/')) {
      return;
    }

    redirect('/reset-password/');
  }
};
