import { MongoCompany } from '@@/types/company';
import { computed, readonly, ref } from '@nuxtjs/composition-api';
import isEmployee from '@/helpers/accessControl/isEmployee';


export interface CurrentUserProfile {
  phone: string;
  first_name: string;
  last_name: string;
  avatar_url: string;
  full_name: string;
  role: string;
  contractor: boolean;
  state?: string;
}

export interface CurrentUser {
  _id: string;
  role: string;
  _company: MongoCompany;
  profile: CurrentUserProfile;
  [key: string]: any;
}

const currentUser = ref<CurrentUser>();
const currentUserId = computed(() => currentUser.value?._id || '');

const setCurrentUser = (user) => {
  // avoids [Vue warn]: onServerPrefetch is called when there is no active component instance to be associated with. Lifecycle injection APIs can only be used during execution of setup().
  currentUser.value = user;
};

const companyId = computed(() => currentUser.value?._company._id || '');
const isOwnerAdmin = computed(()=>{
  return !isEmployee(currentUser.value)
})

const useCurrentUser = () => {
  return {
    currentUser: readonly(currentUser),
    currentUserId,
    companyId,
    // Used on clientInit as store subscribe, to set on any store updates
    setCurrentUser,
    isOwnerAdmin,
  };
};

export default useCurrentUser;
