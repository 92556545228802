import { useApolloQuery } from '@/gql/apolloWrapper';

import {
  useGetTrainingRecommendationsQuery,
  RecommendationScope,
  CoreRecommendation,
  RecommendationStatus,
} from '@/gql/generated';
import { ref, computed } from '@nuxtjs/composition-api';

let init;

const recommendations = ref<CoreRecommendation[]>([]);

const pendingRecommendations = computed(
  () =>
    recommendations.value?.filter(
      (r) => r.status === RecommendationStatus.Pending
    ) || []
);

const acceptedRecommendations = computed(
  () =>
    recommendations.value?.filter(
      (r) => r.status === RecommendationStatus.Accepted
    ) || []
);

const rejectedRecommendations = computed(
  () =>
    recommendations.value?.filter(
      (r) => r.status === RecommendationStatus.Declined
    ) || []
);

const acceptedOrPendingRecommendations = computed(() => [
  ...pendingRecommendations.value,
  ...acceptedRecommendations.value,
]);

const setupHook = () => {
  init = true;

  // Get training recommendations here.
  const { onResult, onError, loading } = useApolloQuery(
    useGetTrainingRecommendationsQuery,
    { data: { scope: RecommendationScope.Company } },
    { data: recommendations },
    {
      placeholderPick: ({ recommendations: res }) =>
        (res || []) as CoreRecommendation[],
    }
  );

  return {
    onResult,
    onError,
    loading,
  };
};

const useTrainingRecommendations = () => {
  if (!init) {
    setupHook();
  }

  return {
    recommendations,
    pendingRecommendations,
    acceptedRecommendations,
    rejectedRecommendations,
    acceptedOrPendingRecommendations,
  };
};

export default useTrainingRecommendations;
