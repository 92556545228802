import { defineStore } from 'pinia';

export enum CLIENT {
  BENEFITS = 'simply-insured',
  TIME_AND_ATTENDANCE = 'deputy',
}

export const usePartnerAuth = defineStore('partnerAuth', {
  actions: {
    async getCode(client_id: CLIENT) {
      // const { $axios } = useContext();
      const code = this.$nuxt.$axios.post(
        `${process.env.PUBLICAPI_URL}/auth/v3/token/create`,
        { client_id }
      );

      return code;
    },
  },
});

export default null;
