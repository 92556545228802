
























































import {
  ModalDialog,
  TypeDisplay,
  BaseDivider,
  TypeBody,
  TypeHeading,
  BaseButton,
} from '@bambeehr/pollen';
import { defineComponent } from '@nuxtjs/composition-api';
import CheckList from '@/components/CheckList/CheckList.vue';

const checkList = [
  'No long-term contracts',
  'Free and Easy Setup',
  'Grows with your team',
  'Switch or cancel anytime',
];

enum Emits {
  PURCHASE = 'purchase',
  CLOSE = 'close',
}

export default defineComponent({
  name: 'PurchaseJobDescriptionModal',
  components: {
    ModalDialog,
    TypeDisplay,
    TypeBody,
    BaseDivider,
    CheckList,
    TypeHeading,
    BaseButton,
  },
  props: {
    pricePerEmployee: {
      type: Number,
      required: true,
    },
    monthlyPrice: {
      type: Number,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
  },
  setup: () => ({
    checkList,
    Emits,
  }),
});
