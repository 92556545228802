export const ContentfulEntryIDs = Object.freeze({
  APP_UPDATE_ID: '17W0djJ2OOD45xzoKihPWn',
  HOLIDAYS: '3tmSDxEe3SSVK2INEF5mmq',
  PAYROLL_BULK_UPLOAD_TEMPLATE: '5dEPZeCQdWnuadKFMTOO7D',
  PAYROLL_BULK_UPLOAD_NPO_TEMPLATE: '5Vo3ouPWc86k1fSSjYnRwb',
  PAYROLL_BULK_UPLOAD_RELIGIOUS_NPO_TEMPLATE: '7MuqVpcNSMVtP9cYPApEO8',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/tfoAc3kufxnd0ekiUdyrI
  PAYROLL_NEW_PLANS_MIGRATION: 'tfoAc3kufxnd0ekiUdyrI',
  SERVICE_UPDATE: '7I6AF6HMn8sldx3ZsniDiv',
  PAYROLL_SERVICE_AGREEMENT: '7z0T7mjMsqDioiN0ZeiFMM',
  PRE_SUB_CABINET: '5YCMgmbd5Kuo6lgxa5NPNH',
  PRE_SUB_POLICIES: '56s6XwJWjlzY8n8RH8SlN7',
  PRE_SUB_REPORT_CARDS: '5wTkw0qOUqQYXG3bHSjnnd',
  PRE_SUB_STAFF: '7tKOy8kSTZUoJCnVxlYi91',
  REFER_A_FRIEND_CONTENT: 'InkgUIZZdYwyBARNtW6AB',
  UPDATE_CHANGES_INFO_IMAGE: '6Dhl2b6ItE7bGXpL2WnyQT',
  VIDEO_TOUR_CABINET: '62p7LHhvIJFdo16MqQI09c',
  VIDEO_TOUR_POLICY: '5axmkCabuqbrjTePM9l4E7',
  VIDEO_TOUR_REPORT_CARDS: '4fwsOdEkqlDOLo713XxZtk',
  VIDEO_TOUR_STAFF_FOLDER: '7FYUH5DlgGmKRgkJQlZxeA',
  WELCOME_PAGE_CONTENT: '3B5XvjdBarq4QrAr7OO30T',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/NWJXSph1NlPVc5RqeCQky?focusedField=id
  PAYROLL_MANAGER_SUPPORT: 'NWJXSph1NlPVc5RqeCQky',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/63FuQbQzSMeLYWUvDvGgir?focusedField=id
  OC_SUPPORT: '63FuQbQzSMeLYWUvDvGgir',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/2Epu47u4687nFzCY81Sykf?focusedField=id
  HRC_SUPPORT: '2Epu47u4687nFzCY81Sykf',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/3AXDyYrSXVKyBEK7McO94h?isNewEntry=true&focusedField=forCompaniesCreatedBefore
  AI_ANNOUNCEMENT: '3AXDyYrSXVKyBEK7McO94h',

  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/61THSq8sK6TJxXQmY4e6Tz?isNewEntry=true&focusedField=text
  PAYROLL_PLAN_CHANGES_BANNER: '61THSq8sK6TJxXQmY4e6Tz',
  FINCH_STEPS: {
    ADP_RUN: '2rQKTQcQh7yua4qlkAI2o0',
    PAYCHEX: '3BlgAj8NZ6KhZzw8AQiveH',
    BAMBOO_HR: '2lyJgPp7fqY0KLXMJkaJVH',
    INSPERITY: '3bhpuBXO3dC2hlttudYl6K',
    NAMELY: 'NZGOlddWHoTIvI6KnGebb',
    PAYLOCITY: '2cwJYKIsnJYbBLMOEOe7bH',
    RIPPLING: '71CdRaT3bfqVdV72p0rhaf',
    SQUARE: '1rDFT4pid1qVDzSuQyQ4Vm',
    PAYCOR: '6dh7SfN4UmB9JBTi6nQiJl',
  },
  INTRODUCTION_MATERIALS: '4QcLAxx2YZFG4yZYztRRvB',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/6Xe2o3ztKWPvJ4BKgzJ1LO
  BUSINESS_HOURS: '6Xe2o3ztKWPvJ4BKgzJ1LO',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/6Hms0aBHUEzbN8uLQjplxp?isNewEntry=true&focusedField=title
  TAX_DOCUMENTS_BANNER: '6Hms0aBHUEzbN8uLQjplxp',
  POLICY_PREVIEW_HARRASSMENT: '1pjvaBVDV4bDqOO6QsFyS3',
  POLICY_PREVIEW_NON_HARRASSMENT: '69e6hxXRRrJogFU4Usb3WY',
  POLICY_PREVIEW_EQUAL_OPPORTUNITY: '5h62Oes7XTFczivTa4pGbF',
  POLICY_PREVIEW_HEALTH_AND_SAFETY: '7BkBLkOc42HyPlQN1Jrris',
  POLICY_PREVIEW_USE_OF_SYSTEMS: '969lcUIEtR5d6HTBVY9GX',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/3VjCDkUdPyaUjmC9yNSFtU
  PAYROLL_TIER_ZERO_UPGRADE_EXPLAINER: '3VjCDkUdPyaUjmC9yNSFtU',
  // https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/4TN7owycMxHpaPcY2T1I6W?focusedField=title
  PAYROLL_INELIGIBLE_UPGRADE_EXPLAINER: '4TN7owycMxHpaPcY2T1I6W',
});

export const ContentfulExplainerModalEntryIDs = Object.freeze({
  BONUS_TYPES: '6eL63X2XQpGeA7ZvUNo6so',
  CHECK_REQUIREMENT_DOCUMENTS: {
    SIGNATORY_PHOTO_ID: '2J5vVi2asrjxAzZEpEKC95',
    ARTICLES_OF_INCORPORATION: '7jX2sWZ9FJujURbFy9bI7Y',
    ARTICLES_OF_INCORPORATION_SIGNATORY_AMENDMENT: 'bdNXw9ET2cyUNnSocqQuk',
    BANK_STATEMENT: '3RSfFh2iAVOQdpfW3VACQM',
    SS4: '5U7iLmz8HlIwiGmZaHEmx8',
    PROFIT_AND_LOSS: '6nuSCtseiWStEVsVhyfv4D',
    BALANCE_SHEET: '5ABa4GfGZedf8G9rWYbWqq',
    CASH_FLOW_STATEMENT: '7uRsefNF7FiLasBF5VxeOV',
    LAST_QUARTER_HISTORICAL_PAYROLL: '5pk1Mia18dvjUsEQ2L9IqO',
    STATE_REGISTRATION: '64v9ePQPAL2dEqerZwioGV',
    BANK_LETTER: '134vK3lUJ28RKV4Y2Dtdsv',
  },
  WHAT_IS_A_DBA: '2L0cV2emkeMcpPRmv7KAeD',
  WHAT_IS_A_LEGAL_COMPANY_NAME: 'Yn4myvS94YL3bU6qiqpuZ',
  AI: {
    CERTIFIED_VS_VERIFIED: '4UqgzRblZLCiPsK4zM4UXj',
  },
});

export const ContentfulBannerEntryIDs = Object.freeze({
  ER_PAYROLL_OVERVIEW: '6owqJG8Ta4HQukdiDWN2rk',
  EE_PAYROLL_PAYSTUBS: '2UHmDrHvo5ShNVOOhb6rUT',
});

export const ContentfulContentTypes = Object.freeze({
  BAMBEE_PARTNER: 'bambeePartner',
  COPY: 'copy',
  HR_COMPLIANCE_LINES: 'hrComplianceLines',
});

export const ContentfulCopyTypes = Object.freeze({
  ANSWERS_WIZARD: 'Answers Wizard',
  EMPLOYEE_VOICES: 'Employee Voices',
  HR_COMPLIANCE: 'HR Compliance',
  REPORT_CARDS: 'Report Cards',
  TASK_CENTER: 'Task Center',
  OFFERS: 'Offers',
});

export const ContentfulMarketingEntryIds = Object.freeze({
  COOKIE_POLICY_AGREEMENT: 'R1eimTYyvMbZAfMEouyJS',
});

export default ContentfulEntryIDs;

// APP_UPDATE_ID: '17W0djJ2OOD45xzoKihPWn',
// APP_UPDATE_ID: '17W0djJ2OOD45xzoKihPWn',
// APP_UPDATE_ID: '17W0djJ2OOD45xzoKihPWn',
// APP_UPDATE_ID: '17W0djJ2OOD45xzoKihPWn',
// APP_UPDATE_ID: '17W0djJ2OOD45xzoKihPWn',
// APP_UPDATE_ID: '17W0djJ2OOD45xzoKihPWn',
// APP_UPDATE_ID: '17W0djJ2OOD45xzoKihPWn',
