





















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { TypeBody, HighlightedIcon } from '@bambeehr/pollen';

export enum Size {
  REGULAR = 'regular',
  SMALL = 'small',
}

export default defineComponent({
  name: 'CheckList',
  components: {
    TypeBody,
    HighlightedIcon,
  },
  props: {
    listItems: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: Size.REGULAR,
    },
  },
  setup(props) {
    const isSmall = computed(() => props.size === Size.SMALL);

    const iconClasses = computed(() => (isSmall.value ? 'h-6 w-6' : 'h-8 w-8'));
    const textVariant = computed(() =>
      isSmall.value ? 'text-small' : 'text-medium'
    );

    return {
      iconClasses,
      textVariant,
    };
  },
});
