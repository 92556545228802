<template>
  <portal to="layout">
    <ModalDialog
      id="terms"
      size="medium"
      :full-bleed="true"
      :show-close="showClose"
      :escape-button="showClose"
      :class="{ 'hide-close': !showClose }"
      @close="$emit('close')"
    >
      <div class="p-6 flex flex-col">
        <div class="flex-grow-0 flex-shrink-0">
          <TypeBody
            v-if="header"
            variant="link-large-tight"
            weight="strong"
            tag="div"
          >
            <slot name="header">
              {{ header }}
            </slot>
          </TypeBody>
          <TypeBody
            v-if="updatedDate"
            varient="text"
            weight="regular"
            tag="div"
            class="mt-2"
          >
            Updated {{ updatedDate }}
          </TypeBody>
        </div>
        <div
          id="terms-modal-body-wrap"
          class="terms-modal__body mt-4 overflow-y-auto flex-1"
          :class="{ 'terms-modal__body--shadow': !isAtBottom }"
          data-qa="agree-scroller"
          @scroll="onScroll"
        >
          <div id="terms-modal-body">
            <slot />
          </div>
        </div>
        <div class="flex justify-between mt-6">
          <div class="text-left">
            <CheckboxInput
              id="agree-to-terms"
              v-model="hasAgreed"
              data-qa="agree-check"
              name="agree-to-terms"
              :title="checkboxTitle"
              :disabled="!hasScrolled"
              :label="agreeLabel"
            />
          </div>
          <BaseButton
            data-qa="agree-btn"
            :disabled="!hasAgreed"
            @click="$emit('submit')"
          >
            Continue
          </BaseButton>
        </div>
      </div>
    </ModalDialog>
  </portal>
</template>

<script>
import { ref, computed, onMounted } from '@nuxtjs/composition-api';
import {
  ModalDialog,
  TypeBody,
  CheckboxInput,
  BaseButton,
} from '@bambeehr/pollen';

export default {
  name: 'TermsModal',
  components: {
    ModalDialog,
    TypeBody,
    CheckboxInput,
    BaseButton,
  },
  props: {
    header: {
      type: String,
      required: false,
      default: '',
    },
    updatedDate: {
      type: String,
      required: false,
      default: '',
    },
    agreeLabel: {
      type: String,
      required: true,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const hasAgreed = ref(false);
    const hasScrolled = ref(false);
    const isAtBottom = ref(false);

    const checkboxTitle = computed(() =>
      hasScrolled.value
        ? 'Click to agree to the terms and conditions'
        : 'You must read the terms and conditions to agree and continue'
    );

    function onScroll(event) {
      const bodyContainer = document.getElementById('terms-modal-body');
      const bodyContainerWrap = event.target;
      const containerHeight = bodyContainer.getClientRects()[0].height;
      const scrollPos =
        bodyContainerWrap.scrollTop +
        bodyContainerWrap.getClientRects()[0].height;

      // 20 is a small buffer so they don't have to be EXACTLY at the end of the div
      if (scrollPos >= containerHeight - 20) {
        hasScrolled.value = true;
        isAtBottom.value = true;
      } else {
        isAtBottom.value = false;
      }
    }

    // If you're moving from one TOS to another, you need to reset the scroll position
    onMounted(() => {
      document.getElementById('terms-modal-body-wrap')?.scrollTo(0, 0);
    });

    return {
      hasAgreed,
      hasScrolled,
      onScroll,
      isAtBottom,
      checkboxTitle,
    };
  },
};
</script>

<style scoped>
.terms-modal__body {
  max-height: 48vh;
}

.terms-modal__body--shadow {
  box-shadow: inset 0px -6px 7px -11px black;
}
</style>
