import { useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';

import {
  CompanyBenefitOnboardStatus,
  GetCompanyOnboardingStatusQuery,
  useGetCompanyOnboardingStatusQuery,
} from '@/gql/generated';
import { computed, ref, watch } from '@nuxtjs/composition-api';

const company = ref<GetCompanyOnboardingStatusQuery>();

// Benefits status is based solely on the onboarding enums from SI.
const hasBenefits = computed(() => {
  const status = company.value?.getCompany?.benefitOnboarding?.status;
  const nonEnrolledStatuses = [
    CompanyBenefitOnboardStatus.IframeLoaded,
    CompanyBenefitOnboardStatus.Enrolling,
    CompanyBenefitOnboardStatus.CoverageStartDateAtChanged,
    CompanyBenefitOnboardStatus.PendingEmployeeEnrollment,
    CompanyBenefitOnboardStatus.FinishedEmployeeEnrollment,
    CompanyBenefitOnboardStatus.BorSubmitted,
  ];

  return !!status && !nonEnrolledStatuses.includes(status);
});

// When customer has payroll only, upsell to benefits.
const upsellBenefits = computed(
  () => !hasBenefits.value && !!company.value?.getCompany
);

const getCompanyStatus = () => {
  const { companyId } = useCurrentCompany();

  watch(
    companyId,
    (id) => {
      if (id) {
        useApolloQuery(
          useGetCompanyOnboardingStatusQuery,
          { id },
          { data: company },
          // Silent fail if payroll company is not found.
          { handleErrors: false }
        );
      }
    },
    { immediate: true }
  );
};

const useEmployerBenefitsAccess = () => {
  getCompanyStatus();

  return {
    hasBenefits,
    upsellBenefits,
  };
};

export default useEmployerBenefitsAccess;
