import { PaymentMethod } from '@/modules/payroll/constants/payroll';

export const formatRow = (label: string, value: string) => ({
  label,
  value,
});

export const getStaffInfoRows = (
  paymentMethod: string,
  bankAccount?: string
) => {
  const isManualCheck = paymentMethod === PaymentMethod.MANUAL.label;
  if (isManualCheck) {
    return [formatRow('Pay Method', paymentMethod)];
  }

  return [
    formatRow('Pay Method', paymentMethod),
    ...(bankAccount ? [formatRow('Bank Account', bankAccount)] : []),
  ];
};

export const getCompanyInfoRows = (
  paymentMethod: string,
  bankAccount: string
) => {
  return [
    formatRow('Financial Info', paymentMethod),
    formatRow('', bankAccount),
  ];
};
