import Confirmation from '@/modules/payroll/components/PayrollRootModal/Modals/Confirmation.vue';
import Explainer from '@/modules/payroll/components/PayrollRootModal/Modals/Explainer.vue';
import PayrollComparison from '@/modules/payroll/components/PayrollRootModal/Modals/PayrollComparison.vue';
import PayrollCancellation from '@/modules/payroll/components/PayrollRootModal/Modals/PayrollCancellation.vue';
import PayrollCancellationConfirmation from '@/modules/payroll/components/PayrollRootModal/Modals/PayrollCancellationConfirmation.vue';

export interface IModal {
  id: number;
  title?: string;
  component: any;
  modalProps?: {
    variant?: string;
    'escape-button'?: boolean;
    'overlay-close'?: boolean;
    size?: string;
    'full-bleed'?: boolean;
  };
  componentProps?: {
    [key: string]: any;
  };
}

export interface IJourney {
  key: string;
  screens: IModal[];
}

export enum PayrollJourneyKeys {
  PAYROLL_PLAN_SELECT = 'payroll-plan-select',
  PAYROLL_CANCELLATION = 'payroll-cancellation',
  PAYROLL_MIGRATION_EXPLAINER = 'payroll-migration-explainer',
}

const baseProps = {
  overlay: 'dark',
  'escape-button': false,
  'overlay-close': true,
  size: 'large',
  'full-bleed': false,
};

export const payrollPlanSelectJourney: IJourney = {
  key: PayrollJourneyKeys.PAYROLL_PLAN_SELECT,
  screens: [
    {
      id: 1,
      title: 'Payroll Comparison',
      component: PayrollComparison,
      modalProps: {
        ...baseProps,
        'full-bleed': true,
        size: 'giant',
      },
    },
    {
      id: 2,
      title: 'Confirmation',
      component: Confirmation,
      modalProps: {
        ...baseProps,
        'full-bleed': true,
        'escape-button': true,
      },
    },
  ],
};

export const payrollCancellationJourney: IJourney = {
  key: PayrollJourneyKeys.PAYROLL_CANCELLATION,
  screens: [
    {
      id: 1,
      title: 'Payroll Cancellation',
      component: PayrollCancellation,
      modalProps: {
        ...baseProps,
        'full-bleed': true,
        size: 'large',
      },
    },
    {
      id: 2,
      title: 'Cancellation Confirmation',
      component: PayrollCancellationConfirmation,
      modalProps: {
        ...baseProps,
        'full-bleed': true,
        size: 'large',
      },
    },
  ],
};

export const payrollMigrationExplainer: IJourney = {
  key: PayrollJourneyKeys.PAYROLL_MIGRATION_EXPLAINER,
  screens: [
    {
      id: 1,
      title: 'Explainer',
      component: Explainer,
      modalProps: {
        ...baseProps,
      },
      componentProps: {
        title: 'Exciting Updates to Bambee Payroll™',
        img: '/images/payroll/plans.png',
        description: `We're introducing new Payroll plans to enhance support and streamline our services, ensuring you get the most value from Bambee Payroll™.`,
        reason:
          'At Bambee, we understand the challenges you face as a small business owner. Our new Payroll plans are crafted to provide you with peace of mind and the support you need to focus on what matters most: growing your business.',
        featureList: [
          {
            title: 'Enhanced Efficiency',
            description: 'Streamlined support to get you answers faster.',
          },
          {
            title: 'Tailored Service',
            description:
              'Plan options that cater to your specific business needs.',
          },
          {
            title: 'Value Alignment',
            description:
              'Keeping our promise of delivering exceptional value for small businesses.',
          },
        ],
        primaryActionCTA: 'Choose Plan',
      },
    },
    {
      id: 2,
      title: 'Payroll Comparison',
      component: PayrollComparison,
      modalProps: {
        ...baseProps,
        'full-bleed': true,
        size: 'giant',
      },
    },
    {
      id: 3,
      title: 'Confirmation',
      component: Confirmation,
      modalProps: {
        ...baseProps,
        'full-bleed': true,
        'escape-button': true,
      },
    },
  ],
};
