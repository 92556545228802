import { CompanyStatuses } from '@/modules/payroll/constants/company';
import {
  getCompanyStatus,
  companyPayrollCount,
} from '@/gql/services/payroll.service';
import bambeePlusStatus from '@/modules/BambeePlus/utils/bambeePlusStatus';
import getPlanPackage from '@/helpers/accessControl/getPlanPackage';

export const Routes = Object.freeze({
  PAYROLL_ROOT: '/payroll',
  PAYROLL_ONBOARDING: '/goal/payroll-onboarding',
  PAYROLL_SETUP: '/payroll/setup',
  PAYROLL_OVERVIEW: '/payroll/overview',
  PAYROLL_PAST: '/payroll/past-payrolls',
  PAYROLL_SETTINGS: '/payroll/settings',
  STAFF_PAY: '/payroll/worker-pay',
});

export default async ({ store, redirect, route }) => {
  const { companyId } = store.getters;
  const { currentUser } = store.getters;
  const company = (await getCompanyStatus(companyId))?.data;

  const payrollCount =
    (await companyPayrollCount(companyId))?.data?.countPayroll || 0;

  const { path = '' } = route;
  const toPath = path.replace(/\/$/, '');

  // Route Paths
  const isRoot = toPath === Routes.PAYROLL_ROOT;
  const isOverview = toPath === Routes.PAYROLL_OVERVIEW;
  const isPayrollSettings = toPath === Routes.PAYROLL_SETTINGS;
  const isStaffPay = toPath === Routes.STAFF_PAY;

  // Bambee Plus, Legacy
  const { hasBambeePlus } = await bambeePlusStatus(companyId);
  // Bambee Plus, New
  const { isBambeePlus } = await getPlanPackage(
    currentUser?._company?.stripe?.customer?.id
  );

  // Company states
  const hasRemainingOnboarding =
    !company ||
    !company?.checkId ||
    company?.checkOnboarding.blockingSteps.length;
  const isInitialized = company?.status === CompanyStatuses.INITIALIZED;
  const isSetupComplete = company?.status === CompanyStatuses.SETUP_COMPLETE;
  const hasCancelled = company?.status === CompanyStatuses.CANCELLED;
  const hasBackedOut = company?.status === CompanyStatuses.BACKED_OUT;

  if (
    (hasRemainingOnboarding &&
      !payrollCount &&
      !(hasBambeePlus || isBambeePlus)) ||
    hasBackedOut
  ) {
    redirect(Routes.PAYROLL_ONBOARDING);

    return;
  }

  if (
    hasCancelled &&
    (isRoot || isOverview || isPayrollSettings || isStaffPay)
  ) {
    redirect(Routes.PAYROLL_PAST);

    return;
  }

  if (isOverview || isRoot) {
    // If the user has run payroll before (has a payroll count), they aren't in an initial blocked status but have likely added some new information that has moved them back to blocked (like a new EE)
    // We'll send them to the settings page and they'll need to complete the necessary info via Check's onboarding flow to get back to the payroll overview
    if (payrollCount && hasRemainingOnboarding) {
      redirect(Routes.PAYROLL_SETTINGS);

      return;
    }

    if (isInitialized || isSetupComplete) {
      redirect(Routes.PAYROLL_SETUP);
    }

    if (hasCancelled) {
      redirect(Routes.PAYROLL_PAST);
    }

    if (isRoot) {
      redirect(Routes.PAYROLL_OVERVIEW);
    }
  }
};
