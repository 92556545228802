import { PayrollStatus } from '@/modules/payroll/constants/payroll';
import { differenceInBusinessDays, isAfter, isBefore } from 'date-fns';

/**
 * Determines the status of a payroll's current deadline relative to a Date (default is today)
 * @param {Object} payroll - Payroll object used to derive status and dates
 * @param {Date} relativeToDay - Relative date to compare payroll dates to. This is primarily used for testing. Default: Today
 * @returns Object - {isOverdue: Boolean, isMissedDirectDepositWindow: Boolean, isDueSoon: Boolean, }
 */
export const getDeadlineStatuses = (payroll, relativeToDay = new Date()) => {
  const deadlineStatuses = {
    isOverdue: false,
    isMissedDirectDepositWindow: false,
    isDueSoon: false,
  };

  const {
    status,
    approvalDeadline: approvalDeadlineStr,
    payday: paydayStr,
  } = payroll;

  if ([PayrollStatus.INITIAL, PayrollStatus.DRAFT].includes(status)) {
    const today = relativeToDay;
    const payday = new Date(paydayStr);
    const approvalDeadline = new Date(approvalDeadlineStr);

    deadlineStatuses.isOverdue = isAfter(today, new Date(payday));

    deadlineStatuses.isMissedDirectDepositWindow =
      isAfter(today, approvalDeadline) && isBefore(today, payday);

    const daysUntilApproval = differenceInBusinessDays(approvalDeadline, today);
    deadlineStatuses.isDueSoon =
      daysUntilApproval <= 3 && daysUntilApproval >= 0;
  }

  return deadlineStatuses;
};

export default {
  getDeadlineStatuses,
};
