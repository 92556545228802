







































































import {
  ref,
  computed,
  defineComponent,
  reactive,
  useRouter,
  PropType,
  useStore,
  watch,
  onMounted,
} from '@nuxtjs/composition-api';
import { wizardActionState } from '@/modules/payroll/pages/employer/onboarding/OnboardingRoot.vue';
import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import useCheckOnboarding from '@/modules/payroll/hooks/useCheckOnboarding';
import { Route } from '@/modules/payroll/constants/payroll';
import useNotifications from '@bambeehr/use-notifications';
import { useApolloMutation } from '@/gql/apolloWrapper';

import { useCheckSyncCompanyMutation } from '@/gql/generated';

import {
  BaseCard,
  TypeBody,
  RadioGroup,
  TypeDisplay,
  BaseBanner,
  BaseButton,
  TextInput,
} from '@bambeehr/pollen';
import SignatoryForm, {
  Signatory,
} from '@/modules/payroll/components/SignatoryForm/SignatoryForm.vue';
import BambeeAndCheck from '@/modules/payroll/components/BambeeAndCheck/BambeeAndCheck.vue';
import LoadingButtonLabel from '@/components/LoadingButtonLabel/LoadingButtonLabel.vue';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import { trackEvent } from '@/modules/payroll/utils/track-apa';
import { setUserPreference } from '@/utils/userPreference';

function setWizardActionState() {
  wizardActionState.value = {
    finishLater: {
      label: 'Finish Later',
      action: (finishLater) => {
        finishLater();
      },
    },
    back: {
      label: 'Back',
      action: (back) => {
        back('/payroll-setup/pay-schedule');
      },
    },
    disableNext: false,
  };
}

export enum CheckOnboardingEvent {
  LOADED = 'check-onboard-app-loaded',
  COMPLETED = 'check-onboard-app-completed',
  CLOSED = 'check-onboard-app-closed',
}

// Radio group doesn't work with true / false as value props
export enum Authorization {
  YES = 'Yes',
  NO = 'No',
}

const authorizationOptions = [Authorization.YES, Authorization.NO];

export default defineComponent({
  name: 'BankTax',
  components: {
    BambeeAndCheck,
    BaseBanner,
    BaseButton,
    BaseCard,
    LoadingButtonLabel,
    RadioGroup,
    SignatoryForm,
    TextInput,
    TypeBody,
    TypeDisplay,
  },
  props: {
    companyId: {
      type: String as PropType<string>,
    },
  },
  // We have custom plugins tied into the SetupInstance
  // "any" for ↴ SetupInstance open to better way of handling typing for this
  setup(props, { emit, root }: any) {
    const router = useRouter();
    const store = useStore();
    const { currentUser } = store.getters;
    const { generateCompanyOnboardingLink, getCompany } = usePayrollService();
    const { companyId } = props;
    const { isOnboarding } = usePayrollOnboardingStatus();
    const { addSuccess } = useNotifications();

    const authorizedTitle = ref<string>(currentUser?.profile?.role || '');
    const isSendingEmail = ref<boolean>(false);
    const onboardingLink = ref<string>('');
    const selectedAuthorization = ref<string>('');
    const signatoryForm = reactive<Signatory>({
      email: '',
      signerTitle: '',
      signerName: '',
    });

    const showBanner = computed<boolean>(() => !selectedAuthorization.value);
    const isAuthorized = computed<boolean>(
      () => selectedAuthorization.value === Authorization.YES
    );

    const isAuthorizedTitleFormValid = computed<boolean>(
      () => !!authorizedTitle.value.trim().length
    );
    const isSignatoryFormValid = computed<boolean>(
      () =>
        !Object.values(signatoryForm).some((v) => !v.trim()) &&
        root.validateEmail(signatoryForm.email)
    );

    function getOnboardingLink(sendEmail?: boolean, callback?: Function): void {
      const signatoryInfo = sendEmail
        ? {
            companyId,
            sendEmail: true,
            ...signatoryForm,
          }
        : {
            companyId,
            email: currentUser._auth.email,
            signerName: currentUser.profile.full_name,
            signerTitle: authorizedTitle.value,
          };

      generateCompanyOnboardingLink(signatoryInfo, {
        data: onboardingLink,
        pending: isSendingEmail,
      });

      if (callback) {
        const unwatch = watch(onboardingLink, (res) => {
          if (res) {
            callback();
          }
          unwatch();
        });
      }

      emit('set-signatory-name', signatoryForm.signerName);
    }

    function saveSignatory() {
      getOnboardingLink(true, () => {
        // Persist loading state til navigated
        isSendingEmail.value = true;

        if (isOnboarding.value) {
          setUserPreference(`skip-check-onboarding-${companyId}`, true);
          trackEvent('check-onboarding-skipped');
          addSuccess('Signatory email sent');
          router.push('/');

          return;
        }

        router.push(Route.PAYROLL_SETUP_CONFIRMATION);
      });
    }

    setWizardActionState();

    // Reset top position
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    // Check inline onboarding
    const {
      onClosed: onClosedOnboarding,
      openOnboarding,
      isLoading: isLoadingOnboardingModal,
    } = useCheckOnboarding(companyId as string, 'er');

    const handleCompleteSetup = () => {
      getOnboardingLink(false, () => {
        openOnboarding(onboardingLink.value);
      });
    };

    // When we close the onboarding modal we'll do a manual pull from Check to make sure we have the latest data
    // If we do, we'll send to /payroll. If the user is in the activation flow, the routeguards will take over.
    onClosedOnboarding(() => {
      const { mutate: syncCompany, onDone: onSyncDone } = useApolloMutation(
        useCheckSyncCompanyMutation
      );

      syncCompany({
        data: {
          companyId: companyId as string,
          correctData: true,
        },
      });

      onSyncDone(({ checkSyncCompany: res }) => {
        if (!res?.checkOnboarding?.blockingSteps.length) {
          router.push(Route.PAYROLL);
        }
      });
    });

    const shouldDisableComplete = computed<boolean>(
      () =>
        !isAuthorizedTitleFormValid.value ||
        isSendingEmail.value ||
        isLoadingOnboardingModal.value
    );

    // APA Tracking
    if (isOnboarding.value) {
      trackEvent('payroll-onboarding-bank-tax');
    }

    return {
      authorizationOptions,
      authorizedTitle,
      getOnboardingLink,
      handleCompleteSetup,
      isAuthorized,
      isAuthorizedTitleFormValid,
      isLoadingOnboardingModal,
      isSendingEmail,
      isSignatoryFormValid,
      saveSignatory,
      selectedAuthorization,
      shouldDisableComplete,
      showBanner,
      signatoryForm,
    };
  },
});
