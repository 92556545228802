<template>
  <ErrorBoundary>
    <section class="bg-primary-light flex-grow p-4 lg:p-20">
      <h1 class="title text-white">
        {{ error.statusCode }}
      </h1>
      <h2 class="text-primary-lighter">
        {{ error.message }}
      </h2>
      <nuxt-link v-if="error.statusCode === 404" class="button" to="/">
        Home
      </nuxt-link>
      <portal to="layout">
        <ModalDialog v-bind="modal" @close="redirectHome">
          <template #header>Error</template>
          {{ error.message }}
        </ModalDialog>
      </portal>
      <BaseNotifications />
    </section>
  </ErrorBoundary>
</template>

<script>
import { ModalDialog } from '@bambeehr/pollen';
import BaseNotifications from '@/components/BaseNotifications/BaseNotifications.vue';
import ErrorBoundary from '@/modules/payroll/components/ErrorBoundary/ErrorBoundary.vue';

export default {
  components: {
    ModalDialog,
    BaseNotifications,
    ErrorBoundary,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modal() {
      return {
        actions: {
          primary: {
            label: 'OK',
            handler: this.redirectHome,
          },
        },
        overlayClose: false,
      };
    },
  },
  methods: {
    redirectHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 7rem;
}
</style>
