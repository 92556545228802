<template>
  <div class="animated-checkmark text-secondary-default">
    <svg
      v-if="!isLoading"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <circle
        class="path circle"
        fill="none"
        stroke="currentColor"
        stroke-width="6"
        stroke-miterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        class="path check"
        fill="none"
        stroke="currentColor"
        stroke-width="6"
        stroke-linecap="round"
        stroke-miterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api';

export default {
  name: 'AnimatedCheckmark',
  props: {
    delay: {
      type: Number,
      require: false,
      default: 250,
    },
  },
  setup(props) {
    const isLoading = ref(true);

    // We want people to see the animation. Let's hold off on rendering until the page has a chance to catch up
    setTimeout(() => {
      isLoading.value = false;
    }, props.delay);

    return {
      isLoading,
    };
  },
};
</script>

<style scoped>
.animated-checkmark {
  height: 1em;
  margin: 0 auto 0;
  width: 1em;
}

svg {
  display: block;
  margin: 0 auto 0;
  width: 100%;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.path.line {
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
  stroke-dashoffset: 1000;
}

.path.check {
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
  stroke-dashoffset: -100;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}
</style>
