<template>
  <div>
    <BaseCard id="edit-totals" full-bleed class="p-4">
      <template v-if="showStatusHeader" #header>
        <TypeOverline
          class="flex justify-center p-4 pt-1 pb-1 mb-4 -m-4 payroll-status bg-base-400 rounded-t-md"
          tag="div"
          variant="x-small-tight"
        >
          {{ payrollStatus }} {{ formattedPayrollStatusDate }}
        </TypeOverline>
      </template>

      <div class="line-item-sections">
        <div v-if="showPrimarySection" class="payroll-summary__section">
          <SummaryTileLineItem
            v-if="totalGross && promoteGross"
            name="Total Earnings"
            :value="totalGross"
            :emphasize="true"
            :highlight-changes="true"
          />

          <SummaryTileLineItem
            v-if="totalDebited"
            name="Total Debited Amount"
            :value="totalDebited"
            :emphasize="true"
          >
            <template #tooltip>
              <ToolTip id="totalDebitedTooltip">
                The total debited amount is the sum of the direct deposit
                earnings and the total taxes.
              </ToolTip>
            </template>
          </SummaryTileLineItem>
        </div>

        <div v-if="showDatesAndGrossSection" class="payroll-summary__section">
          <SummaryTileLineItem
            v-if="approvalDeadline"
            name="Payroll Deadline"
            :value="approvalDeadline"
            type="datetime"
          />

          <SummaryTileLineItem
            v-if="withdrawDate"
            name="Withdraw Date"
            :value="withdrawDate"
            type="date"
          />

          <SummaryTileLineItem
            v-if="employeePayDate"
            name="Pay Date"
            :value="employeePayDate"
            type="date"
          />

          <SummaryTileLineItem
            v-if="totalGross && !promoteGross"
            name="Total Earnings"
            :value="totalGross"
          />
        </div>
      </div>

      <div v-if="errors.length">
        <BaseBanner
          v-for="error in errors"
          :key="error.id || error.message"
          class="mt-4"
          :message="error.message"
          :description="error.description"
          :show-close="false"
          variant="error"
        />
      </div>

      <footer v-if="$slots.actions" class="flex flex-col justify-center mt-4">
        <slot name="actions" />
      </footer>
    </BaseCard>
  </div>
</template>

<script>
import { BaseCard, TypeOverline, BaseBanner } from '@bambeehr/pollen';
import SummaryTileLineItem from '@/modules/payroll/components/SummaryTile/SummaryTileLineItem';
import { formatDate } from '@/utils/date';
import { computed } from '@nuxtjs/composition-api';
import ToolTip from '@/components/ToolTip';

export default {
  name: 'SummaryTile',
  components: {
    BaseBanner,
    SummaryTileLineItem,
    BaseCard,
    TypeOverline,
    ToolTip,
  },
  props: {
    totalGross: {
      type: Number,
      required: false,
      default: 0,
    },
    totalDebited: {
      type: Number,
      required: false,
      default: 0,
    },
    employeePayDate: {
      type: [Date, String],
      required: false,
      default: null,
    },
    approvalDeadline: {
      type: [Date, String],
      required: false,
      default: null,
    },
    withdrawDate: {
      type: [Date, String],
      required: false,
      default: null,
    },
    payrollStatus: {
      type: String,
      required: false,
      default: '',
    },
    payrollStatusDate: {
      type: [Date, String],
      required: false,
      default: null,
    },
    errors: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  setup(props, { emit }) {
    const showPrimarySection = computed(
      () => props.totalGross || props.totalDebited
    );
    const showDatesAndGrossSection = computed(
      () =>
        props.withdrawDate ||
        props.approvalDeadline ||
        props.employeePayDate ||
        (props.totalGross && !props.promoteGross)
    );
    const showStatusHeader = computed(() => props.payrollStatus);
    const formattedPayrollStatusDate = computed(
      () => props.payrollStatusDate && formatDate(props.payrollStatusDate)
    );
    const promoteGross = computed(() => !props.totalDebited);

    function handleDiscardDraft() {
      return emit('discard');
    }

    function togglePreview() {
      emit('preview');
    }

    return {
      formatDate,
      formattedPayrollStatusDate,
      handleDiscardDraft,
      promoteGross,
      showDatesAndGrossSection,
      showPrimarySection,
      showStatusHeader,
      togglePreview,
    };
  },
};
</script>

<style scoped>
.payroll-summary__section + .payroll-summary__section {
  @apply mt-3 pt-3 border-0 border-t border-solid border-base-400;
}

.summary-tile__line-item + .summary-tile__line-item {
  @apply mt-2;
}

footer > *:not(:last-child) {
  @apply mb-3;
}
</style>
