import phone from '@/lib/phone_validate';
import dayjs from 'dayjs';

// we are setting time to noon as a workaround to avoid issues with timezone
export function dateForServer(value) {
  const date = dayjs(value);

  return date.isValid() ? date.hour(12).toDate() : null;
}

export function phoneForServer(value) {
  if (!value) {
    return '';
  }
  const phoneSanitized = phone.validate(value);
  if (!phoneSanitized.isValid) {
    throw new Error(`${value} is not a valid Phone Number`);
  }

  return phoneSanitized.value;
}

// used for Pollen's DateInput component
export function dateOnlyFromServer(value) {
  const date = dayjs(value);

  return date.isValid() ? date.format('MM/DD/YYYY') : null;
}

export function phoneFromServer(value) {
  return value ? value.replace(/^\+1/, '').trim() : '';
}
