import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentUser from '@/hooks/useCurrentUser/useCurrentUser';
import usePolicies from '@/hooks/usePolicies/usePolicies';
import useState from '@/hooks/useState/useState';
import bam from '@/lib/bam';
import { AIUpsellEvents } from '@/modules/AI/constants/events';
import usePolicyDetails from '@/modules/PolicyCenter/hooks/usePolicyDetails';
import {
  ref,
  useRouter,
  watch,
  Ref,
  computed,
  reactive,
  useContext,
} from '@nuxtjs/composition-api';
import FeatureFlags from '@/constants/FeatureFlags';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import isAdmin from '@/utils/isAdmin';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';

export interface FileUpload {
  name: string;
  fileType: string;
  base64: string;
}

export interface INextQuestion {
  content: string;
  example?: string;
  affirmation?: string;
  options?: string[] | null;
  context?: string;
}

export interface IAIMessage {
  text: INextQuestion;
  isAI: boolean;
  isHidden?: boolean;
  isArtifact?: boolean;
  artifactId?: string;
}

export const LOCAL_STORAGE_USER_MESSAGE = 'userMessage';

let initialized = false;
let router;
let companyId;
let currentUser;
let policies;
let policyDetails;
let context;
let plan;

const {
  hydrateFromLocalStorage,
  state: userMessageState,
  clearState,
} = useState(
  {
    inputText: '',
    artifactId: '',
    artifactNote: '',
    threadId: '',
    thread: [{}],
  },
  LOCAL_STORAGE_USER_MESSAGE
);

export enum Assistants {
  POLICY = 'policy',
  JOB_DESCRIPTION = 'job-description',
  CAP = 'cap',
  PIP = 'pip',
  INCIDENTS = 'incidents',
  CORRECTIVE_ACTION = 'corrective-action',
}

const isLoading = ref(false);
const hasSendError = ref(false);

const messages: Ref<IAIMessage[]> = ref([]);

const hiddenUserMessage = ref();

const showRequestChanges = ref(false);
const jobDescription = ref();
const jobDescriptionId = ref();
const cap = ref();
const capId = ref();
const pip = ref();
const pipId = ref();
const correctiveAction = ref();
const correctiveActionId = ref();
const correctiveActionType = ref();
const incident = ref();
const incidentId = ref();
const response = ref();
const shouldShowTile = ref(false);
const shouldShowVerified = ref(false);
const solidifyText = ref(false);
const shouldFadeIn = ref(false);
const animateArtifact = ref(false);
const employeeList = ref([]);
const canUploadFiles = ref(false);
const currentThread = ref();
const currentAssistant = ref('');
const summary = computed(() => response.value?.artifact?.summary?.html || '');
const policy = computed(() => response.value?.artifact?.policy?.html || '');
const artifactType = computed(() => {
  switch (currentAssistant.value) {
    case Assistants.POLICY:
      return 'Policy';

    case Assistants.JOB_DESCRIPTION:
      return 'Job Description';

    case Assistants.CORRECTIVE_ACTION:
      return 'Corrective Action';

    case Assistants.INCIDENTS:
      return 'Report';

    default:
      return '';
  }
});
const policyTitle = computed(
  () => response.value?.artifact?.policy?.title || ''
);
const artifactTitle = computed(() => {
  switch (currentAssistant.value) {
    case Assistants.POLICY:
      return response.value?.artifact?.policy?.title || 'Your New Policy';

    case Assistants.JOB_DESCRIPTION:
      return (
        response.value?.artifact?.jobDescription?.title ||
        'your Job Description'
      );

    case Assistants.CORRECTIVE_ACTION:
      return response.value?.artifact?.cap?.title || 'your Corrective Action';

    case Assistants.INCIDENTS:
      return response.value?.artifact?.pip?.title || 'your Incident Report';

    default:
      return '';
  }
});

const isCertified = computed(() => {
  console.log(
    'Confidence Score: ',
    response.value?.artifact?.policy?.confidence
  );

  return response.value?.artifact?.policy?.confidence >= 80;
});

const employeeId = computed(
  () =>
    response.value?.artifact?.correctiveAction?.employeeId ||
    response.value?.artifact?.jobDescription?.employeeId
);

const policyId = computed(() => response.value?.artifact?.policy?.id || '');
const summaryStatus = computed(() => response.value?.artifact?.summary?.status);

const summaryIsApproved = computed(() => summaryStatus.value === 'accepted');
const hasEnteredDraftState = ref(false);

const showArtifact = computed(() => {
  return (
    policy.value ||
    jobDescription.value ||
    correctiveAction.value ||
    incident.value ||
    (summary.value && !summaryIsApproved.value)
  );
});
const currentArtifact = computed(
  () =>
    jobDescription.value ||
    correctiveAction.value ||
    incident.value ||
    policy.value ||
    summary.value
);

const isEditingartifact = computed(
  () => currentArtifact.value && isLoading.value
);

const isAIPolicyEnabled = computed(() => {
  return (
    launchDarkly.getFlags()[FeatureFlags.AI_POLICY_REQUEST] &&
    isAdmin.value &&
    !plan.isBambeeLite.value
  );
});

const isAIJobDescriptionEnabled = computed(() => {
  return (
    launchDarkly.getFlags()[FeatureFlags.AI_JOB_DESCRIPTION] &&
    isAdmin.value &&
    !plan.isBambeeLite.value
  );
});

// TODO: Replace with flag for AI Corrective Actions
const isAICorrectiveActionEnabled = computed(() => {
  return (
    launchDarkly.getFlags()[FeatureFlags.AI_CAPS] &&
    isAdmin.value &&
    !plan.isBambeeLite.value
  );
});

// TODO: Replace with flag for AI Incidents
const isAIIncidentEnabled = computed(() => {
  return (
    launchDarkly.getFlags()[FeatureFlags.AI_INCIDENT] &&
    isAdmin.value &&
    !plan.isBambeeLite.value
  );
});

const someAIIsEnabled = computed(
  () =>
    isAIPolicyEnabled.value ||
    isAIJobDescriptionEnabled.value ||
    isAICorrectiveActionEnabled.value ||
    isAIIncidentEnabled.value
);

const lastMessage = reactive({
  content: '',
  isHidden: false,
});
const nextQuestion = reactive({
  content: '',
  example: '',
  affirmation: '',
  options: [''],
  context: '',
  question: '',
});

const fullThread: any = ref([]);

const convertThreadToMessages = (thread: any) => {
  return thread
    .map((message: any) => {
      let parsedMessage;

      try {
        parsedMessage = JSON.parse(message.content[0].text.value);
      } catch (e) {
        parsedMessage = message.content[0].text.value;
      }

      return {
        text: {
          content:
            parsedMessage.message ||
            parsedMessage.content ||
            parsedMessage.question,
          example: parsedMessage.sample,
          affirmation: parsedMessage.affirmation,
          options: parsedMessage.options,
          context: parsedMessage.context,
        },
        isAI: message.role === 'assistant',
        isHidden: message.metadata.hidden === 'true',
        isArtifact: !!message.metadata.artifact,
        artifactId: message.metadata.value,
      };
    })
    .reverse();
};

const activeThread = computed(() => {
  const thread = fullThread.value.filter((message: any) => {
    return (
      (message.role === 'assistant' || message.role === 'user') &&
      message.metadata.hidden !== 'true'
    );
  });

  return convertThreadToMessages(thread);
});

const chatStatus = ref();

const DEFAULT_MESSAGE = 'Thinking...';

const getChatStatus = async (threadId: any, shouldReset: boolean = false) => {
  const { $axios } = context;

  if (shouldReset) {
    chatStatus.value = {
      loadingMessages: [DEFAULT_MESSAGE],
      updatedAt: new Date(),
    };
  }

  await $axios
    .get(`/core/ai/assistant/v1/chat/${threadId}/status`)
    .then(({ data: res }) => {
      chatStatus.value = {
        ...res,
        updatedAt: new Date(),
      };
    })
    .catch((err) => {
      console.log(err);
    });
};

const chatWithAssistant = async (
  threadId?: string,
  assistant?: string,
  files?: FileUpload[]
) => {
  hasSendError.value = false;
  isLoading.value = true;
  const skipRun = !!(
    !(userMessageState.inputText || hiddenUserMessage.value) && threadId
  );
  lastMessage.content = userMessageState.inputText;
  lastMessage.isHidden = !!hiddenUserMessage.value;

  let statusInterval;

  const runChecks = (shouldReset?) => {
    getChatStatus(threadId, shouldReset);
  };
  if (threadId) {
    runChecks(true);
    statusInterval = setInterval(
      runChecks,
      // See how this feels -- might be too long of a check here for when we're in draft state
      hasEnteredDraftState.value ? 6000 : 4000
    );
  }

  const { $axios } = context;

  await $axios
    .post(
      `/core/ai/assistant/v1/chat`,
      {
        content:
          userMessageState.inputText || hiddenUserMessage.value
            ? JSON.stringify({
                content: hiddenUserMessage.value || userMessageState.inputText,
              })
            : null,
        threadId,
        assistant,
        files,
        skipRun,
        hidden: !!hiddenUserMessage.value,
      },
      {
        timeout: 140000,
      }
    )
    .then(({ data: res }) => {
      hasSendError.value = false;

      response.value = res;
      userMessageState.threadId = response.value?.threadId;
      currentThread.value = response.value?.threadId || '';
      if (response.value?.assistant) {
        currentAssistant.value = response.value?.assistant;
      }

      jobDescription.value = res?.artifact?.jobDescription?.html || '';
      jobDescriptionId.value = res?.artifact?.jobDescription?.id || '';
      correctiveAction.value = res?.artifact?.correctiveAction?.html || '';
      correctiveActionId.value =
        res?.artifact?.correctiveAction?.documentId || '';
      correctiveActionType.value = res?.artifact?.correctiveAction?.type || '';

      incident.value = res?.artifact?.incident?.html || '';
      incidentId.value = res?.artifact?.incident?.id || '';

      employeeList.value = res?.artifact?.jobDescription?.assignments || [];

      canUploadFiles.value = res?.next?.acceptFileUpload ?? false;

      if (res?.messages?.[0]?.content) {
        nextQuestion.example = res?.next.sample || '';
        nextQuestion.content = res?.next.question || '';
        nextQuestion.affirmation = res?.next.affirmation || '';
        // @ts-ignore
        nextQuestion.options = (res?.next.options as string[]) || [''];
        nextQuestion.context = res?.next.context || '';
      }
      fullThread.value = res?.messages || [];
      userMessageState.inputText = '';
      if (!userMessageState.inputText && !threadId) {
        router.push(`/create-request/${res?.threadId}`);
      } else {
        isLoading.value = false;
      }
      userMessageState.thread = convertThreadToMessages(res?.messages) || [];
      clearInterval(statusInterval);
    })
    .catch((err) => {
      isLoading.value = false;
      userMessageState.inputText = '';
      hasSendError.value = true;
      console.log(err);
      clearInterval(statusInterval);
      bam.trackEvent(AIUpsellEvents.AI_REQUEST_ERROR, {
        type: currentAssistant.value,
      });
    });
};

const handleSendMessage = (
  message?: string | undefined,
  asstId?: string | undefined,
  files?: FileUpload[] | undefined
) => {
  if (!userMessageState.inputText && !message) {
    return;
  }
  hiddenUserMessage.value = message;
  isLoading.value = true;

  chatWithAssistant(
    currentThread.value,
    asstId || currentAssistant.value,
    files
  );

  bam.trackEvent(AIUpsellEvents.AI_REQUEST_START, {
    type: `${asstId || currentAssistant.value}`,
  });

  userMessageState.inputText = '';
  hiddenUserMessage.value = '';
};

const draftingPhrases = ref<string[]>([]);

const resetHook = () => {
  currentAssistant.value = '';
  currentThread.value = '';
  isLoading.value = false;
  userMessageState.inputText = '';
  userMessageState.artifactId = '';
  userMessageState.threadId = '';
  response.value = null;
  fullThread.value = [];
  hasEnteredDraftState.value = false;
  draftingPhrases.value = [];
  animateArtifact.value = false;
  shouldShowTile.value = false;
  shouldFadeIn.value = false;
  hiddenUserMessage.value = '';
  chatStatus.value = null;
  messages.value = [];
  nextQuestion.content = '';
  nextQuestion.example = '';
  nextQuestion.affirmation = '';
  nextQuestion.options = [''];
  nextQuestion.context = '';
  lastMessage.content = '';
  lastMessage.isHidden = false;
  shouldShowVerified.value = false;
  employeeList.value = [];
  clearState();
};
watch(
  messages,
  (newValue) => {
    const artifactMessage = newValue.find((message) => message.isArtifact);

    if (artifactMessage) {
      userMessageState.artifactId = artifactMessage.artifactId || '';
      policyDetails.setViewPolicyAs(currentUser.value._id);
      policies.policyInScopeId.value = userMessageState.artifactId;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

const allThinkingPhrases = computed(
  () => chatStatus.value?.loadingMessages || []
);

const currentThinkingPhrase = computed(() => {
  // select random item from the array
  return (
    chatStatus.value?.updatedAt &&
    // get random element from array
    allThinkingPhrases.value[
      Math.floor(Math.random() * chatStatus.value.loadingMessages.length)
    ]
  );
});

const isDraftingArtifact = computed(() =>
  [
    'pre_policy_change_requested',
    'post_policy_change_requested',
    'pre_policy_drafted',
    'post_policy_drafted',
    'pre_job_description_drafted',
    'post_job_description_drafted',
    'pre_job_description_change_requested',
    'post_job_description_change_requested',
    'pre_corrective_action_drafted',
    'post_corrective_action_drafted',
    'pre_corrective_action_change_requested',
    'post_corrective_action_change_requested',
    'pre_incident_drafted',
    'post_incident_drafted',
    'pre_incident_change_requested',
    'post_incident_change_requested',
    'pre_summary_drafted',
    'post_summary_drafted',
    'policy_drafted',
    'summary_drafted',
  ].includes(chatStatus.value?.status)
);

// non-reversable state
watch(
  isDraftingArtifact,
  (draftState) => {
    if (draftState) {
      hasEnteredDraftState.value = true;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  currentThinkingPhrase,
  (newPhrase) => {
    if (
      newPhrase &&
      hasEnteredDraftState.value &&
      newPhrase !== DEFAULT_MESSAGE
    ) {
      draftingPhrases.value.push(newPhrase);
    }
  },
  { immediate: true }
);

const setupHook = () => {
  router = useRouter();
  hydrateFromLocalStorage();
  const { companyId: tmpCompanyId } = useCurrentCompany();
  policies = usePolicies();
  const { currentUser: tmpCurrentUser } = useCurrentUser();
  currentUser = tmpCurrentUser;
  policyDetails = usePolicyDetails();
  plan = usePlanAccess();

  companyId = tmpCompanyId.value;
};

const useAIConversation = () => {
  if (!initialized) {
    setupHook();
    initialized = true;
    context = useContext();
  }

  return {
    currentAssistant,
    summary,
    messages,
    isLoading,
    handleSendMessage,
    userMessageState,
    resetHook,
    showRequestChanges,
    chatWithAssistant,
    nextQuestion,
    currentThread,
    activeThread,
    lastMessage,
    showArtifact,
    policyId,
    policy,
    policyTitle,
    currentArtifact,
    hasSendError,
    jobDescription,
    jobDescriptionId,
    correctiveAction,
    correctiveActionId,
    correctiveActionType,
    incidentId,
    incident,
    cap,
    capId,
    pip,
    pipId,
    summaryIsApproved,
    isAIPolicyEnabled,
    chatStatus,
    isDraftingArtifact,
    hasEnteredDraftState,
    currentThinkingPhrase,
    allThinkingPhrases,
    draftingPhrases,
    isEditingartifact,
    shouldShowTile,
    shouldShowVerified,
    shouldFadeIn,
    animateArtifact,
    isAIJobDescriptionEnabled,
    isAICorrectiveActionEnabled,
    isAIIncidentEnabled,
    artifactTitle,
    artifactType,
    employeeId,
    isCertified,
    solidifyText,
    someAIIsEnabled,
    employeeList,
    canUploadFiles,
  };
};

export default useAIConversation;
