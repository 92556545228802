import { ApolloLink } from '@apollo/client/core';

export default function () {
  return new ApolloLink((operation, forward) => {
    // console.log(`GraphQL Request: ${operation.operationName}`);
    // operation.setContext({ start: new Date() });

    return forward(operation).map((response) => {
      // if(process.env.NODE_ENV === "development") {
      //     const responseTime = new Date() - operation.getContext().start;
      //     console.log(`GraphQL Response took: ${responseTime}`);
      // }
      return response;
    });
  });
}
