import { useGlobalScript } from '@/hooks/useGlobalScript';
import { ref } from '@nuxtjs/composition-api';

import { useGenerateCompanyPayrollReportLinkMutation } from '@/gql/generated';
import { useApolloMutation } from '@/gql/apolloWrapper';

declare global {
  interface Window {
    CheckComponent: any;
  }
}

export enum CheckOnboardingEvent {
  LOADED = 'check-onboard-app-loaded',
  COMPLETED = 'check-onboard-app-completed',
  CLOSED = 'check-onboard-app-closed',
  UPDATED_TAX_FORMS = 'check-onboard-tax-setup-form-updated',
}

// Custom Check component for onboarding.
const { script: check } = useGlobalScript(
  'https://cdn.checkhq.com/component-initialize.js',
  'CheckComponent'
);

const useCheckPayrollReports = () => {
  const isLoading = ref(false);
  const handler = ref();
  const doneHandler = ref<Function>();
  const closedHandler = ref<Function>();

  function onDone(fn: Function) {
    doneHandler.value = fn;
  }

  function onClosed(fn: Function) {
    closedHandler.value = fn;
  }

  const getEmployerPayrollReportsLink = (id: string) => {
    const { mutate: getLink, onDone: onLinkResult } = useApolloMutation(
      useGenerateCompanyPayrollReportLinkMutation
    );

    getLink({
      id,
    });

    return {
      onLinkResult,
    };
  };

  const openComponent = async (id: string) => {
    const { onLinkResult } = getEmployerPayrollReportsLink(id);

    isLoading.value = true;
    onLinkResult(({ generateCompanyPayrollReportLink: link }) => {
      isLoading.value = false;

      handler.value = check.value.create({
        link,
      });

      handler.value?.open();
      const componentElement = document.getElementById(
        'check-component-embedded-div'
      );
      if (componentElement) {
        const componentHolder = document.getElementById(
          'check-component-holder'
        );
        componentHolder?.appendChild(componentElement);
      }
    });
  };

  const closeComponent = () => {
    handler.value?.close();
  };

  return {
    closeComponent,
    isLoading,
    onClosed,
    onDone,
    openComponent,
    getEmployerPayrollReportsLink,
  };
};

export default useCheckPayrollReports;
