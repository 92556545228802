import { CompanyStatuses } from '@/modules/payroll/constants/company';
import {
  getCompanyStatus,
  getContractorStatus,
  getEmployeeStatus,
} from '@/gql/services/payroll.service';

export const Routes = Object.freeze({
  MY_PAY_ROOT: '/my-pay',
  MY_PAY_PAYSTUBS: '/my-pay/paystubs',
  MY_PAY_PAYMENTS: '/my-pay/payments',
  MY_PAY_SETUP: '/my-pay/setup',
});

export default async ({ store, redirect, route }) => {
  const toPath = route.path?.replace(/\/$/, '');

  if (
    toPath !== Routes.MY_PAY_ROOT &&
    toPath !== Routes.MY_PAY_SETUP &&
    toPath !== Routes.MY_PAY_PAYSTUBS &&
    toPath !== Routes.MY_PAY_PAYMENTS
  ) {
    return;
  }

  const { companyId } = store.getters;
  const { data: company } = await getCompanyStatus(companyId);
  const allowedStatuses = [
    CompanyStatuses.INITIALIZED,
    CompanyStatuses.SETUP_COMPLETE,
    CompanyStatuses.LIVE,
    CompanyStatuses.CANCELLED,
  ];
  const isCompanySetupInPayroll = allowedStatuses.includes(company?.status);

  const { currentUser } = store.getters;
  const { data: employee } = await getEmployeeStatus(currentUser._id);
  const { data: contractor } = await getContractorStatus(currentUser._id);
  const isEmployeeInitializedInPayroll = !!employee || !!contractor;

  const isEmployeeSetupInPayroll =
    isEmployeeInitializedInPayroll &&
    !(
      employee?.checkOnboarding?.blockingSteps?.length ||
      contractor?.checkOnboarding?.blockingSteps?.length
    );

  if (
    (toPath === Routes.MY_PAY_ROOT ||
      toPath === Routes.MY_PAY_PAYSTUBS ||
      toPath === Routes.MY_PAY_PAYMENTS) &&
    !(isCompanySetupInPayroll && isEmployeeSetupInPayroll)
  ) {
    redirect(Routes.MY_PAY_SETUP);
  } else if (
    (toPath === Routes.MY_PAY_SETUP || toPath === Routes.MY_PAY_ROOT) &&
    isCompanySetupInPayroll &&
    isEmployeeSetupInPayroll
  ) {
    redirect(contractor ? Routes.MY_PAY_PAYMENTS : Routes.MY_PAY_PAYSTUBS);
  }

  if (toPath === Routes.MY_PAY_PAYMENTS && !contractor) {
    redirect(Routes.MY_PAY_PAYSTUBS);
  }

  if (toPath === Routes.MY_PAY_PAYSTUBS && !!contractor) {
    redirect(Routes.MY_PAY_PAYMENTS);
  }
};
