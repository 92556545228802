
























import { PropType, defineComponent, computed } from '@nuxtjs/composition-api';
import { getFullAddress } from '@/utils/address';
import { Workplace } from '@/modules/payroll/types/company';

import { TypeBody } from '@bambeehr/pollen';
import WorkplaceModal from '@/components/Workplaces/WorkplaceModal.vue';

export default defineComponent({
  name: 'WorkplaceListItem',
  components: {
    WorkplaceModal,
    TypeBody,
  },
  props: {
    workplace: {
      type: Object as PropType<Workplace>,
      required: true,
    },
  },
  setup(props) {
    const name = computed(
      () =>
        `${props.workplace.name} ${props.workplace.default ? '(default)' : ''}`
    );

    return {
      fullAddress: getFullAddress(props.workplace.address),
      name,
    };
  },
});
