


























import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'GlowButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    glow: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: false,
    },
    hoverAnimate: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },

  emits: ['click'],
  setup() {
    const isHovering = ref(false);

    return {
      isHovering,
    };
  },
});
