import { EarningType } from '@/modules/payroll/constants/earning-types';
import FeatureFlags from '@/constants/FeatureFlags';
import { grossEarnings } from '@bambeehr/payroll';
import launchDarkly from '@bambeehr/vue-launch-darkly';

// Will add and update types with auto generated typings here: PAY-1940
export interface PayItem {
  amount: number;
  description?: string;
  id: number;
  minutes: number;
  type: string;
}

export interface Tax {
  amount: number;
  description: string;
  payer: string;
  remittable: boolean;
  tax: string;
}

export interface Staff {
  id: string;
  active: boolean;
  paymentMethodPreference: string;
  firstName: string;
  lastName: string;
  fullName: string;
  payRate: number;
  payType: string;
  remainingOnboarding: string[];
  title: string;
  employeeType: string;
}

export interface StaffPayItemPayroll {
  id: string;
  version: number;
}

export interface Reimbursement {
  amount: number;
  id: number;
}

export interface PostTaxDeduction {
  amount: number;
  description: string;
  type: string;
}

export interface Benefit {
  benefit: string;
  companyContributionAmount: string;
  description: string;
  employeeContributionAmount: string;
}

export interface StaffPayItem {
  id: string;
  isContractor: boolean;
  pay: PayItem[];
  staff: Staff;
  note: string;
  noteVisible: boolean;
  payroll: StaffPayItemPayroll;
  taxes: Tax[];
  reimbursements: Reimbursement[];
  netPay: number;
  grossPay?: number;
  benefits: Benefit[];
  postTaxDeductions: PostTaxDeduction[];
  reimbursementsTotal: number;
  checkNumber: string;
  paymentMethod: string;
}

const standardize = ({
  benefits,
  contractor,
  basePayRate,
  checkNumber,
  earnings,
  employee,
  entries,
  netPay,
  note,
  noteVisible,
  paymentMethod,
  payroll,
  postTaxDeductions,
  reimbursements,
  taxes,
  payType,
  id,
}): StaffPayItem => {
  const person = employee || contractor;
  const shouldExcludeCashTipsFromOT =
    launchDarkly.getFlags()[FeatureFlags.EXCLUDE_CASH_TIPS_FROM_OT_BASE_RATE] ||
    false;
  const exclude = shouldExcludeCashTipsFromOT
    ? [EarningType.CASH_TIPS.value]
    : [];
  const staff: Staff = {
    ...person,
    profile: {
      ...person.profile,
      fullName: `${person.profile.firstName} ${person.profile.lastName}`,
    },
  };

  const pay = earnings || entries || [];
  const payForGross = pay.filter(
    (p) => p.type !== EarningType.REIMBURSEMENT.value
  );

  // If all earnings have an amount, we should just use that value instead of using the gross pay calculation
  const allEarningsHaveAmount = payForGross.every((i) => !!i.amount);
  let grossPay = 0;

  if (payForGross.length) {
    grossPay = allEarningsHaveAmount
      ? payForGross.reduce((acc, curr) => acc + curr.amount, 0)
      : grossEarnings(
          // basePayRate is a static value of the payRate when the payroll was created
          // staff.payRate is dynamic and updates when the employee has a payRate change
          basePayRate || staff?.payRate,
          payType || staff?.payType,
          payForGross,
          exclude
        );
  }

  const allReimbursements = contractor
    ? pay.filter((p) => p.type === EarningType.REIMBURSEMENT.value)
    : reimbursements;

  const reimbursementsTotal = allReimbursements.reduce(
    (acc, reimbursement) => reimbursement.amount + acc,
    0
  );

  return {
    id,
    paymentMethod,
    isContractor: !!contractor,
    pay,
    staff,
    note,
    noteVisible,
    payroll,
    taxes,
    benefits,
    checkNumber,
    postTaxDeductions,
    reimbursements: allReimbursements,
    reimbursementsTotal,
    netPay: contractor ? grossPay + reimbursementsTotal : netPay,
    grossPay,
  };
};

export const serializeList = (rawPayments: any[]): StaffPayItem[] => {
  if (!rawPayments || !Array.isArray(rawPayments)) {
    return [];
  }

  return rawPayments
    .filter((p) => !!p.contractor || !!p.employee)
    .map(standardize);
};
