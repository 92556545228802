<template>
  <div>
    <BaseTable>
      <template #header>
        <tr>
          <th>Worker</th>
          <th>Pay Method</th>
          <th>Gross Pay</th>
          <th v-if="totals.reimbursements.raw">Reimbursements</th>
          <th>Emp. Taxes</th>
          <th v-if="totals.postTaxDeductions.raw">Emp. Deductions</th>
          <th v-if="totals.companyBenefits.raw">Co. Contributions</th>
          <th>Co. Taxes</th>
          <th>Net Pay</th>
          <th />
        </tr>
      </template>

      <tr v-for="payItem in payItems" :key="payItem.id">
        <td>{{ payItem.staff.profile.fullName }}</td>
        <td>{{ payItem.payMethod }}</td>
        <td>{{ payItem.grossPay.value }}</td>

        <td v-if="totals.reimbursements.raw">
          {{ payItem.reimbursementsTotal.value }}
        </td>
        <td>
          {{ payItem.employeeTaxes.value }}
        </td>
        <td v-if="totals.postTaxDeductions.raw">
          {{ payItem.employeeDeductions.value }}
        </td>
        <td v-if="totals.companyBenefits.raw">
          {{ payItem.companyBenefits.value }}
        </td>
        <td>{{ payItem.companyTaxes.value }}</td>

        <td>{{ payItem.netPay.value }}</td>
        <td>
          <div class="flex items-center justify-end">
            <BaseLink
              v-if="payrollIsApproved"
              tag="a"
              @click="downloadPaystub(payItem, fileDownloader)"
            >
              <TypeBody
                tag="span"
                variant="caption"
                weight="strong"
                class="mr-3"
              >
                Pay Stub
              </TypeBody>
            </BaseLink>
            <EarningsModalTrigger
              v-if="getStaffPayDetails(payItem.staff.id)"
              class="mr-1"
              :title="getEarningsModalTitle(payItem.staff.profile.fullName)"
              :details="getStaffPayDetails(payItem.staff.id)"
            >
              <BaseBanner
                v-if="payItem.note"
                class="text-left mb-4"
                icon="commentSolid"
                :message="getNoteTitle(payItem.noteVisible)"
                :description="payItem.note"
                :show-close="false"
              />
            </EarningsModalTrigger>
          </div>
        </td>
      </tr>

      <template v-if="totals" #footer>
        <tr>
          <td>Total</td>
          <td></td>
          <td>{{ totals.gross.value }}</td>
          <td v-if="totals.reimbursements.raw">
            {{ totals.reimbursements.value }}
          </td>
          <td>{{ totals.employeeTaxes.value }}</td>
          <td v-if="totals.postTaxDeductions.raw">
            {{ totals.postTaxDeductions.value }}
          </td>
          <td v-if="totals.companyBenefits.raw">
            {{ totals.companyBenefits.value }}
          </td>
          <td>{{ totals.companyTaxes.value }}</td>
          <td>{{ totals.net.value }}</td>
          <td />
        </tr>
      </template>
    </BaseTable>
  </div>
</template>

<script>
import { BaseTable, BaseLink, BaseBanner, TypeBody } from '@bambeehr/pollen';
import { computed } from '@nuxtjs/composition-api';
import useFileDownloader from '@/composables/useFileDownloader';
import EarningsModalTrigger from '@/modules/payroll/components/EarningsModalTrigger/EarningsModalTrigger';
import useStaffPayTable from './useStaffPayTable';

export default {
  name: 'EmployeePayTable',
  components: {
    BaseLink,
    BaseBanner,
    BaseTable,
    EarningsModalTrigger,
    TypeBody,
  },
  setup() {
    const { fileDownloader } = useFileDownloader();
    const {
      downloadPaystub,
      formattedPayItems,
      formattedTotals,
      getEarningsModalTitle,
      getNoteTitle,
      getStaffPayDetails,
      payrollIsApproved,
    } = useStaffPayTable();

    const totals = computed(() => formattedTotals.value.employee);
    const payItems = computed(() => formattedPayItems.value.employees);

    return {
      downloadPaystub,
      getEarningsModalTitle,
      getNoteTitle,
      getStaffPayDetails,
      payItems,
      payrollIsApproved,
      totals,
      fileDownloader,
    };
  },
};
</script>
