<template>
  <div class="mt-4">
    <nuxt-child />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { trainingRouteGuard } from '@/modules/Training/middleware';
import PageTitle from '@/modules/Training/components/PageTitle';

export default defineComponent({
  name: 'EmployerTrainingRoot',
  components: {
    PageTitle,
  },
  middleware: [trainingRouteGuard],
});
</script>
