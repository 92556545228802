<template>
  <ErrorBoundary>
    <div>
      <div
        class="flex justify-center items-center w-auto h-16 bg-primary-default"
      >
        <img src="/assets/images/logo/bambee-white.svg" alt="bambee logo" />
      </div>
      <div class="text-center mt-10">
        <TypeBody variant="link-large-tight" weight="strong">
          Let's get you the perfect HR Solution for your company's needs
        </TypeBody>
        <TypeBody tag="p" variant="caption">
          Please give us a few details so that we can prepare a custom action
          plan for your business
        </TypeBody>
      </div>

      <nuxt />
    </div>
    <BaseNotifications />
  </ErrorBoundary>
</template>

<script>
import { TypeBody } from '@bambeehr/pollen';
import BaseNotifications from '@/components/BaseNotifications/BaseNotifications.vue';
import ErrorBoundary from '@/modules/payroll/components/ErrorBoundary/ErrorBoundary.vue';

export default {
  components: {
    TypeBody,
    BaseNotifications,
    ErrorBoundary,
  },
};
</script>
