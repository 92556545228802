




























import useAddStaffForm from '../hooks/useAddStaffForm';
import AddStaffFormGroup from './AddStaffFormGroup';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import useCheckPostTaxDeductions from '@/modules/payroll/hooks/useCheckPostTaxDeductions';
import { BaseButton, ModalDialog, AnnularThrobber } from '@bambeehr/pollen';

export default defineComponent({
  components: {
    AddStaffFormGroup,
    AnnularThrobber,
    BaseButton,
    ModalDialog,
  },
  setup() {
    const { workerId } = useAddStaffForm();

    const { openComponent, closeComponent, isLoading } =
      useCheckPostTaxDeductions();

    const shouldShowModal = ref(false);
    const showModal = () => {
      shouldShowModal.value = true;
      openComponent(workerId.value as string);
    };

    const hideModal = () => {
      shouldShowModal.value = false;
      closeComponent();
    };

    return {
      hideModal,
      isLoading,
      shouldShowModal,
      showModal,
    };
  },
});
