
































import {
  ref,
  useRoute,
  useRouter,
  watch,
  computed,
} from '@nuxtjs/composition-api';
import { TabControls, BaseButton, TypeDisplay } from '@bambeehr/pollen';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import FeatureFlags from '@/constants/FeatureFlags';
import BreadCrumbs from '@/components/BreadCrumbs';
import hasPurchasedTraining from '@/modules/Training/helpers/hasPurchasedTraining';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import ContentBanner from '@/components/ContentBanner/ContentBanner';

export enum TabPages {
  COMPANY = 'company',
  MY_COURSES = 'my-courses',
  OVERVIEW = 'overview',
  REQUESTS = 'requests',
}

interface TabOption {
  label: string;
  value: TabPages;
}

export const tabOptions = [
  {
    label: 'Overview',
    value: TabPages.OVERVIEW,
  },
  {
    label: 'Company Courses',
    value: TabPages.COMPANY,
  },
  {
    label: 'My Courses',
    value: TabPages.MY_COURSES,
  },
  {
    label: 'Requests',
    value: TabPages.REQUESTS,
  },
];

export const getCurrentTab = (route): TabOption =>
  tabOptions.find((tab) => route.path.includes(tab.value)) as TabOption;

export default {
  components: {
    TabControls,
    BaseButton,
    BreadCrumbs,
    TypeDisplay,
    ContentBanner,
  },
  setup() {
    const { companyId } = useCurrentCompany();
    const route = useRoute();
    const router = useRouter();
    const currentPage = ref<TabOption>();
    const isRoot = computed(() => route.value.path === '/training');
    const isOverview = computed(
      () => currentPage.value?.value === TabPages.OVERVIEW
    );

    watch(
      isRoot,
      (shouldRedirect) => {
        if (shouldRedirect) {
          router.push({ path: `/training/${TabPages.COMPANY}` });
        }
      },
      { immediate: true }
    );

    watch(
      route,
      (newRoute) => {
        currentPage.value = getCurrentTab(newRoute);
      },
      { immediate: true }
    );

    const handleTabUpdate = (newPage) => {
      router.push({ path: `/training/${newPage}` });
    };

    const canViewCatalog = computed(
      () =>
        launchDarkly.getFlags()[FeatureFlags.TRAINING_CATALOG] ||
        hasPurchasedTraining(companyId.value)
    );

    const crumbs = computed(() => [
      {
        label: 'Training',
        to: '/training/overview',
      },
      {
        label: currentPage.value?.label,
      },
    ]);

    return {
      currentPage,
      tabOptions,
      canViewCatalog,
      handleTabUpdate,
      crumbs,
      isOverview,
    };
  },
};
