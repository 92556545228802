























































import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
} from '@nuxtjs/composition-api';
import {
  BaseButton,
  TypeBody,
  BaseTag,
  BaseCard,
  CheckboxInput,
  BaseDivider,
} from '@bambeehr/pollen';
import { CORE_BUNDLE_ID } from '@/modules/Training/constants';

export default defineComponent({
  components: {
    BaseButton,
    TypeBody,
    BaseTag,
    BaseCard,
    CheckboxInput,
    BaseDivider,
  },
  props: {
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    bundleId: {
      type: String,
      default: '',
    },
    topicId: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    unfilledSeats: {
      type: Number as PropType<number>,
      default: 0,
    },
    isBundle: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    details: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const isSelected = ref(false);

    const handleSelection = () => {
      const { bundleId, name, unfilledSeats, topicId, price } = props;
      const selectedTraining = {
        bundleId,
        name,
        unfilledSeats,
        topicId,
        price,
      };
      if (isSelected.value) {
        emit('training-selected', selectedTraining);
      } else {
        emit('training-unselected', selectedTraining);
      }
    };

    // core bundle is recommended to be checked by default for customers
    const isCoreBundle = computed(() => props.bundleId === CORE_BUNDLE_ID);
    watch(
      isCoreBundle,
      (value) => {
        if (value) {
          isSelected.value = true;
          handleSelection();
        }
      },
      { immediate: true }
    );

    return {
      handleSelection,
      isSelected,
    };
  },
});
