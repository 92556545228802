/**
 * Created by foureight84 on 6/13/17.
 */
import Vue from 'vue';

function create(data) {
  return {
    _id: null,
    doc_type: null,
    doc_content: {},
    _user: {},
    _company: {},
    _created_by: {},
    created_at: null,
    modified_at: null,

    ...data,
  };
}

function set(state, payload) {
  const item = create(payload);

  if (!state._byId[item._id]) {
    state.ids.push(item._id);
  }

  Vue.set(state._byId, item._id, item);

  return item;
}

function setEmployee(state, employeeId, payload) {
  const item = create(payload);
  if (!state._byEmployee[employeeId]) {
    Vue.set(state._byEmployee, employeeId, {
      ids: [],
      _byId: {},
    });
  }
  if (!state._byEmployee[employeeId]._byId[item._id]) {
    state._byEmployee[employeeId].ids.push(item._id);
  }

  Vue.set(state._byEmployee[employeeId]._byId, item._id, item);

  return item;
}

export const state = () => ({
  ids: [],
  _byId: {},
  _byEmployee: {},
});

export const mutations = {
  set(state, payload) {
    set(state, payload);
  },
  setAll(state, payload) {
    state.ids = [];
    state._byId = {};
    payload.forEach((i) => set(state, i));
  },
  setEmployee(state, { employee_id: employeeId, payload }) {
    setEmployee(state, employeeId, payload);
  },
  setEmployeeAll(state, { employee_id: employeeId, payload }) {
    payload.forEach((i) => setEmployee(state, employeeId, i));
  },
};

export const getters = {
  all(state) {
    return state.ids.map((i) => state._byId[i]);
  },

  employee: (state, getters) => (employeeId) => {
    return getters.all.filter(
      (f) => f._user === employeeId || f._user._id === employeeId
    );
  },
};

export const actions = {
  getMy({ commit }) {
    return this.$axios.get('/v0/app/my/folder').then((res) => {
      commit('setAll', res.data);

      return res.data;
    });
  },

  getApproval({ commit }) {
    return this.$axios.get('/v0/app/my/pending-approval').then((res) => {
      commit('setAll', res.data);

      return res.data;
    });
  },

  getMyCompanyFolder({ commit }) {
    return this.$axios.get('/v0/app/my/company-folder').then((res) => {
      commit('setAll', res.data);

      return res.data;
    });
  },

  getMyCompanyFolderSimplified({ commit }) {
    return this.$axios
      .get('/v0/app/my/company-folder-simplified')
      .then((res) => {
        commit('setAll', res.data);

        return res.data;
      });
  },

  async getBothMyAndCompanyFolder({ commit }) {
    const [my, company] = await Promise.all([
      this.$axios.get('/v0/app/my/folder').then((res) => {
        return res.data;
      }),
      this.$axios.get('/v0/app/my/company-folder-simplified').then((res) => {
        return res.data;
      }),
    ]);
    const res = [...my, ...company];
    commit('setAll', res);

    return res;
  },

  // eslint-disable-next-line
  getEmployee({ commit }, employeeId) {
    if (employeeId && typeof employeeId === 'string') {
      return this.$axios
        .get(`/v0/app/folder/${employeeId}`)
        .then((res) => {
          commit('setAll', res.data);

          return res.data;
        })
        .catch((error) => {
          window.DD_RUM?.addError(error);
        });
    }
  },

  /**
   * creates incident
   */
  createIncident({ commit }, { wizard, employee_id: employeeId }) {
    return this.$axios
      .post(`/v0/app/folder/create-incident`, {
        wizard,
        employee_i: employeeId,
      })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  /**
   * updates incident
   */
  updateIncident({ commit }, { incident, wizard }) {
    return this.$axios
      .post(`/v0/app/folder/update-incident/${incident._id}`, { wizard })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  /**
   * upload files to folder
   */
  uploadEmployeeFiles({ commit }, { files, employee_id: employeeId }) {
    return this.$axios
      .post('/v0/app/folder/upload-employee-files', {
        files,
        employee_id: employeeId,
      })
      .then((res) => {
        res.data.forEach((f) => {
          commit('set', f);
        });
      });
  },

  updatePermissionById({ commit }, { _id, visibility }) {
    return this.$axios
      .post(`/v0/app/folder/byId/${_id}/update-permission`, { visibility })
      .then((res) => {
        [res.data].forEach((f) => {
          commit('set', f);
        });
      });
  },

  /**
   * upload files to company folder
   */
  uploadCompanyFiles({ commit }, { files }) {
    return this.$axios
      .post('/v0/app/folder/upload-company-files', { files })
      .then((res) => {
        res.data.forEach((f) => {
          commit('set', f);
        });
      });
  },
};
