import { DefaultLandingRoutes } from '@/lib/globals/routes';
import useMiddlewareContext from './hooks/useMiddlewareContext';

export default () => {
  const { route, redirect, store } = useMiddlewareContext();

  const aboutBambee = '/about-bambee';

  const paid = ['trial', 'paid', 'paying'].includes(
    store.state.current_user._company.status
  );

  const newPath = route.path;

  if (paid && route.path.includes(aboutBambee)) {
    redirect(DefaultLandingRoutes.USER.path);
  }

  const whitelistedRoutes = [
    '/login',
    '/about-bambee',
    '/settings',
    '/activate-account',
    'welcome',
    'terms',
    'privacy',
    '/accounts',
    '/reset-password',
    '/insurance',
    '/qna',
    '/get-bambee',
    '/company-setup',
    '/payment',
  ];

  const allowed = whitelistedRoutes.some((white) => newPath.includes(white));

  if (allowed) {
    return;
  }

  if (paid) {
    return;
  }

  const unpaid =
    store.state.current_user._company.status === 'inactive' &&
    store.state.current_user.role !== 'user';

  if (unpaid) {
    redirect(`/get-bambee`);
  }

  if (
    route.path === '/payment' &&
    unpaid &&
    (!store.state.current_user.role === 'user' ||
      !store.state.current_user.role === 'company-owner')
  ) {
    redirect(`/about-bambee/get-bambee`);
  }

  if (route.query['immediate-request'] === 'true') {
    redirect('/about-bambee/welcome?immediate-request=true');

    return;
  }

  redirect(`/about-bambee/welcome`);
};
