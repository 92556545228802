









import { defineComponent } from '@nuxtjs/composition-api';
import { canAccessEmployerBenefits } from '@/modules/Benefits';

export default defineComponent({
  name: 'EmployerPayrollRoot',
  middleware: [canAccessEmployerBenefits],
});
