



























































import { TypeBody, BaseButton, BaseIcon } from '@bambeehr/pollen';
import { defineComponent, PropType } from '@nuxtjs/composition-api';

export interface TimelineItem {
  title: string;
  description?: string;
  isCompleted: string;
  buttonText?: string;
  id: string;
  // Optional handler for button click
  // If no handler is provided, the action event will be emitted
  handler?: () => void;
}

export default defineComponent({
  components: {
    TypeBody,
    BaseButton,
    BaseIcon,
  },
  props: {
    items: {
      type: Array as PropType<TimelineItem[]>,
      required: true,
    },
  },
});
