const HrRoles = Object.freeze({
  AC: 'ac',
  AE: 'ae',
  BM: 'bm',
  HBS: 'hbs',
  HRM: 'hrm',
  HRC: 'hrc',
  HROPS: 'hrops',
  INSURANCE: 'insurance',
  OBS: 'obs',
  OC: 'oc',
  PYM: 'pym',
} as const);

export const HrRoleLongNames = Object.freeze({
  [HrRoles.AC]: 'HR Strategist',
  [HrRoles.AE]: 'Account Executive',
  [HrRoles.BM]: 'Business Manager',
  [HrRoles.HBS]: 'Handbook Specialist',
  [HrRoles.HRM]: 'HR Manager',
  [HrRoles.HRC]: 'HR Coordinator',
  [HrRoles.HROPS]: 'Operations Manager',
  [HrRoles.INSURANCE]: 'Insurance',
  [HrRoles.OBS]: 'HR Onboarding Specialist',
  [HrRoles.OC]: 'Business Coordinator',
  [HrRoles.PYM]: 'Payroll Manager',
} as const);

export default HrRoles;
