import isBefore from 'date-fns/isBefore';
import subDays from 'date-fns/subDays';
import LocalStorageKey from '@/constants/LocalStorage';
import { getFromLocalStorage } from '@/utils/localStorage';

declare global {
  interface Window {
    __needsAppUpdate: boolean;
  }
}

// Check for app version updates
// Solution originally from: https://github.com/nuxt-community/pwa-module/issues/239
export default async function checkForUpdate(context, err, firstTime = true) {
  try {
    let isFlagSet;
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.getRegistration();

      if (registration) {
        registration.update();
        if (firstTime) {
          registration.addEventListener('updatefound', () => {
            window.__needsAppUpdate = true;
            isFlagSet = true;

            if (window?.DD_RUM?.setGlobalContextProperty) {
              window.DD_RUM?.setGlobalContextProperty('versionUpdater', {
                needsVersionUpdate: true,
              });
            }
          });
        }
      }
    }
    if (!isFlagSet) {
      const versionUpdated = getFromLocalStorage(
        LocalStorageKey.VERSION_UPDATED
      );
      const versionUpdatedOverADayAgo =
        versionUpdated &&
        isBefore(new Date(versionUpdated), subDays(new Date(), 1));

      // 1000 * 60 * 60 = 1 hour
      // Check every hour if the app needs to be updated to reduce service worker network load
      setTimeout(() => {
        if (!versionUpdated || versionUpdatedOverADayAgo) {
          checkForUpdate(context, err, false);
        }
      }, 1000 * 60 * 60);
    }
  } catch (error) {
    window.DD_RUM?.setGlobalContextProperty('versionUpdater', {
      versionUpdaterDisabled: true,
      error,
    });
  }
}
