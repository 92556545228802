































import { defineComponent, computed } from '@nuxtjs/composition-api';
import {
  TypeDisplay,
  TypeBody,
  BaseButton,
  AnnularThrobber,
} from '@bambeehr/pollen';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';
import CheckList from '@/components/CheckList/CheckList';

const features = [
  'Easy-to-use Payroll for Small Businesses',
  'Access to Time and Attendance',
  'Chat Support',
];

const premiumFeatures = [
  ...features,
  'Dedicated Payroll Manager',
  'Phone Support',
];

export default defineComponent({
  name: 'ConfirmationScreen',
  components: {
    TypeDisplay,
    TypeBody,
    BaseButton,
    CheckList,
    AnnularThrobber,
  },
  emits: ['close', 'next'],
  setup() {
    const {
      isPayrollBasic,
      isPayrollPremium,
      isLoading: isBillingLoading,
    } = useCompanyBillingInfo(true);

    const currentPlan = computed(() => {
      if (isPayrollBasic.value) {
        return 'Basic Payroll';
      }
      if (isPayrollPremium.value) {
        return 'Premium Payroll';
      }
    });

    const title = computed(() =>
      isPayrollBasic.value
        ? `Thanks! You're now on the ${currentPlan.value} Plan`
        : `Great! You are now on the ${currentPlan.value} Plan`
    );

    const details = computed(() =>
      isPayrollBasic.value
        ? `The next step is to set up your payroll account. Let's go to the dashboard
      and get started. If you have any questions in the meantime, you can contact the support team on your team page.`
        : `
      The next step is to set up your payroll account. Let's go to the dashboard
      and get started. If you have any questions in the meantime, you can can contact your Dedicated Payroll Manager on your team page.
      `
    );

    const featureList = computed(() => {
      return isPayrollPremium.value ? premiumFeatures : features;
    });

    const isReady = computed(
      () =>
        !isBillingLoading.value &&
        (isPayrollPremium.value || isPayrollBasic.value)
    );

    return {
      currentPlan,
      title,
      isPayrollPremium,
      details,
      features,
      premiumFeatures,
      featureList,
      isReady,
    };
  },
});
