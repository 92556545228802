import {
  ContractorType,
  EmployeeType,
  StaffType,
} from '@/modules/payroll/constants/payroll';
import { computed, reactive, ref, watch } from '@nuxtjs/composition-api';
import useVuelidate from '@vuelidate/core';
import { email, minLength, required, requiredIf } from '@vuelidate/validators';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import addYears from 'date-fns/addYears';
import subYears from 'date-fns/subYears';

export interface AddStaffFormModel {
  onboardToPayroll: boolean;
  beginOnboarding: boolean;
  employeeType?: string;
  contractorType?: string;
  contractorBusinessName?: string;
  email?: string;
  firstName?: string;
  isContractor?: boolean;
  lastName?: string;
  payAmount: string;
  payType?: string;
  startDate?: string;
  state?: string;
  title?: string;
  phoneNumber?: string;
  id?: string;
  workplace?: string;
  dob?: string;
  staffType: string;
  address?: {
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
}

const staffOptions = [
  {
    label: 'Employee (W-2)',
    value: StaffType.EMPLOYEE,
  },
  {
    label: 'Contractor (1099)',
    value: StaffType.CONTRACTOR,
  },
];

const contractorOptions = [
  {
    label: 'Individual',
    value: ContractorType.INDIVIDUAL,
  },
  {
    label: 'Business',
    value: ContractorType.BUSINESS,
  },
];

const employeeOptions = [
  {
    label: 'Full Time',
    value: EmployeeType.FULLTIME,
  },
  {
    label: 'Part Time',
    value: EmployeeType.PARTTIME,
  },
];

const v$ = ref();
const alreadyInPayroll = ref(false);
const hasTimeAndAttendance = ref(false);
const staffType = ref(staffOptions[0].value);
const isSimplifiedForm = ref(false);

const defaultRules = {
  beginOnboarding: {
    required,
  },
  firstName: {
    required,
  },
  isContractor: {
    required,
  },
  lastName: {
    required,
  },
  payAmount: {
    required: false,
  },
  payType: {
    required: false,
  },
  title: {
    required: false,
  },
  onboardToPayroll: {
    required,
  },

  contractorBusinessName: {
    required: false,
  },
};

const initialState = {
  beginOnboarding: true,
  onboardToPayroll: false,
  employeeType: '',
  email: '',
  firstName: '',
  isContractor: false,
  lastName: '',
  payAmount: '',
  payType: 'hourly',
  startDate: '',
  state: '',
  title: '',
  phoneNumber: '',
  workplace: '',
  dob: '',
  id: '',
  contractorType: '',
  contractorBusinessName: '',
};
const currentEmail = ref<string>('');

const isInvalid = (input: any) => {
  return input.$dirty && input.$invalid;
};

// Purpose of this validation is to verify date format and surface anachronistic errors
const isValidDate = (date: string | undefined | null) => {
  if (!date) {
    return {
      $valid: true,
    };
  }

  // Verify that string date is in the format MM/DD/YYYY
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
    return {
      $valid: false,
    };
  }

  const dateObj = new Date(date);
  const isValidFormat = dateObj instanceof Date;

  // Verify that it's a valid date
  if (!isValidFormat) {
    return {
      $valid: false,
    };
  }

  // User can add a date that is at most 1 year in the future
  const futureDate = addYears(new Date(), 1);
  // Oldest date is 120 years in the past
  const pastDate = subYears(new Date(), 120);

  // Is the date between our sensible bounds?
  return {
    $valid: isAfter(dateObj, pastDate) && isBefore(dateObj, futureDate),
  };
};

const state = reactive({ ...initialState } as AddStaffFormModel);

// Handles condition where existing date is removed and value ends up being '//'
watch(state, () => {
  if (state.dob === '//') {
    state.dob = '';
  }

  if (state.startDate === '//') {
    state.startDate = '';
  }
});

const rules = computed(() => ({
  ...defaultRules,
  email: {
    required: requiredIf(() => !state.phoneNumber || !!state.onboardToPayroll),
    email,
  },
  phoneNumber: {
    required: requiredIf(() => !state.email),
    minLength: minLength(10),
  },
  dob: {
    required: requiredIf(() => {
      return (
        isSimplifiedForm.value ||
        ((alreadyInPayroll.value || state.onboardToPayroll) &&
          !state.isContractor)
      );
    }),
    isValidDate,
  },
  workplace: {
    required: requiredIf(
      () =>
        state.onboardToPayroll ||
        alreadyInPayroll.value ||
        hasTimeAndAttendance.value
    ),
  },
  state: {
    required: requiredIf(
      () =>
        !state.onboardToPayroll &&
        !alreadyInPayroll.value &&
        !hasTimeAndAttendance.value
    ),
  },
  employeeType: {
    required: requiredIf(() => !state.isContractor),
  },
  contractorType: {
    required: requiredIf(() => !!state.isContractor),
  },
  startDate: {
    required,
    isValidDate,
  },
}));

const isBusinessContractor = computed(
  () => state.isContractor && state.contractorType === ContractorType.BUSINESS
);

const needsPayrollInfo = computed(
  () => alreadyInPayroll.value || state.onboardToPayroll
);

watch(isBusinessContractor, (businessContractor) => {
  if (!businessContractor) {
    state.contractorBusinessName = '';
  }
});

// Addressing interdependent states
watch(
  staffType,
  (val) => {
    const isContractor = val === StaffType.CONTRACTOR;
    state.isContractor = isContractor;

    if (state.isContractor) {
      state.employeeType = '';
      if (!state.contractorType) {
        state.contractorType = contractorOptions[0].value;
      }
    } else {
      state.contractorType = '';
      if (!state.employeeType) {
        state.employeeType = employeeOptions[0].value;
      }
    }
  },
  { immediate: true }
);

const useAddStaffFormState = (
  propState?,
  isPayrollUser: boolean = false,
  simplifiedForm: boolean = false,
  isTimeAndAttendance: boolean = false
) => {
  if (propState) {
    Object.assign(state, {
      ...initialState,
      ...propState,
    });

    // Depending on thte original source of the phone number, sometimes they include +1 at the beginning
    // Stripping that here as it causes some issues with the form
    state.phoneNumber = state.phoneNumber?.replace('+1', '') || '';

    // In core gql we use isContractor instead of staffType (enum).
    // We're checking if they're a contractor and setting the correct staffType.
    if (propState.staffType) {
      staffType.value = propState.staffType;
    } else {
      staffType.value = staffOptions[state.isContractor ? 1 : 0].value;
    }

    alreadyInPayroll.value = isPayrollUser;
    isSimplifiedForm.value = simplifiedForm;
    hasTimeAndAttendance.value = isTimeAndAttendance;

    watch(
      state,
      () => {
        // @ts-ignore: Unreachable code error
        v$.value = useVuelidate(rules, state).value;
      },
      { immediate: true }
    );

    // hold Current Email for update validation for specifically Email Update
    if (state.email) {
      currentEmail.value = state.email as string;
    }
  }

  return {
    currentEmail,
    formState: state,
    v$,
    isInvalid,
    staffOptions,
    contractorOptions,
    employeeOptions,
    staffType,
    isBusinessContractor,
    needsPayrollInfo,
  };
};

export default useAddStaffFormState;
