





















































import { TypeDisplay, AnnularThrobber, BaseBanner } from '@bambeehr/pollen';
import {
  defineComponent,
  computed,
  ref,
  PropType,
} from '@nuxtjs/composition-api';
import PaySettings from '@/modules/payroll/components/PaySettings';
import PayScheduleList from '@/modules/payroll/components/PaySchedule/PayScheduleList.vue';
import PayScheduleDetails from '@/modules/payroll/components/PaySchedule/PayScheduleDetails.vue';
import {
  formatPayScheduleDays,
  formatPaySchedule,
} from '@/modules/payroll/components/PaySchedule/payScheduleHelpers';
import { getCompanyInfoRows } from '@/modules/payroll/components/PaySettings/getInfoRows';
import {
  PayFrequency,
  ProcessingPeriod,
} from '@/modules/payroll/constants/payroll';
import useCurrentUser from '@/hooks/useCurrentUser';
import {
  useGetCompanyDefaultPayScheduleDaysQuery,
  useGenerateCompanyOnboardingLinkMutation,
  CheckOnboardSteps,
  DefaultPayScheduleDays,
  Company,
  PaySchedule,
  GetCompanyDefaultPayScheduleDaysQuery,
} from '@/gql/generated';

import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import Workplaces from '@/components/Workplaces/Workplaces.vue';

export enum ProcessingPeriodLabel {
  FOUR_DAY = '4 days',
  TWO_DAY = '2 days',
}

export default defineComponent({
  name: 'PayrollSettings',
  components: {
    AnnularThrobber,
    BaseBanner,
    PayScheduleDetails,
    PayScheduleList,
    PaySettings,
    TypeDisplay,
    Workplaces,
  },
  props: {
    companyId: {
      type: String as PropType<string>,
      required: true,
    },
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
  },
  setup(props) {
    const { mutate: getOnboardingLink, onDone: onOnboardingLinkClick } =
      useApolloMutation(useGenerateCompanyOnboardingLinkMutation);

    const company = computed(() => props.company);

    const { currentUser } = useCurrentUser();

    const companyIsBlocked = computed(
      () =>
        company.value?.checkOnboarding?.status === CheckOnboardSteps.Blocking
    );

    const paymentInfoRows = computed(() => {
      const bankAccount = company.value?.bankAccounts?.[0];

      if (bankAccount) {
        const normalizedSubType =
          bankAccount?.subType?.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          ) || '';

        return getCompanyInfoRows(
          `${bankAccount.institutionName} - ${bankAccount.name}`,
          `${normalizedSubType} (${bankAccount.mask})`
        );
      }

      return undefined;
    });
    const paymentInfoLink = computed(() => ({
      label: 'Update company financial information',
    }));

    const payScheduleDays = computed(
      () => company.value.defaultPayScheduleDays as DefaultPayScheduleDays[]
    );
    const paySchedule = computed<PaySchedule | null>(
      () => company.value.paySchedules?.find((s) => s?.default) || null
    );

    const hasFourDayProcessing = computed<boolean>(
      () => company.value.processingPeriod === ProcessingPeriod.FOUR_DAY
    );
    const processingPeriodLabel = computed<string>(() =>
      hasFourDayProcessing.value
        ? ProcessingPeriodLabel.FOUR_DAY
        : ProcessingPeriodLabel.TWO_DAY
    );

    const payFrequencyLabel = computed<string>(
      () =>
        Object.values(PayFrequency).find(
          (f) => f.value === paySchedule.value?.payFrequency
        )?.label || ''
    );

    const payScheduleCompany = ref<GetCompanyDefaultPayScheduleDaysQuery>();
    useApolloQuery(
      useGetCompanyDefaultPayScheduleDaysQuery,
      { id: props.companyId },
      { data: payScheduleCompany },
      { handleErrors: false }
    );

    const allPayScheduleDays = computed(
      () => payScheduleCompany.value?.getCompany?.defaultPayScheduleDays || []
    );

    const showAllDays = ref(false);
    function showAllScheduledPaydays() {
      showAllDays.value = true;
    }

    const formattedPayScheduleDays = computed<string[][]>(() =>
      formatPayScheduleDays(
        payScheduleDays.value,
        (showAllDays.value
          ? allPayScheduleDays.value
          : []) as DefaultPayScheduleDays[]
      )
    );

    const formattedPaySchedule = computed<PaySchedule>(() =>
      formatPaySchedule(paySchedule.value)
    );

    const goToOnboarding = () => {
      if (currentUser.value) {
        getOnboardingLink({
          data: {
            companyId: company.value.id,
            email: currentUser.value?._auth.email,
            signerName: currentUser.value?.profile.full_name,
            // Our system defaults to "Business Owner" if the role is not set
            signerTitle: currentUser.value?.profile.role || 'Business Owner',
            sendEmail: false,
          },
        });
      }
    };

    onOnboardingLinkClick((res) => {
      const link = res.generateCompanyOnboardingLink;
      if (link) {
        window.open(link, '_blank')?.focus();
      }
    });

    return {
      goToOnboarding,
      allPayScheduleDays,
      paymentInfoRows,
      paymentInfoLink,
      formattedPayScheduleDays,
      formattedPaySchedule,
      showAllScheduledPaydays,
      payFrequencyLabel,
      processingPeriodLabel,
      showAllDays,
      companyIsBlocked,
    };
  },
});
