




































































import {
  ref,
  computed,
  defineComponent,
  PropType,
  useStore,
} from '@nuxtjs/composition-api';
import { ModalDialog, BaseButton, TypeBody, BaseTag } from '@bambeehr/pollen';
import PrimaryAdvisorAvatar from '@/components/profile-image/PrimaryAdvisorAvatar.vue';
import currency from '@bambeehr/currency';
import ManualSeatInput from '@/modules/Training/components/PurchaseTraining/ManualSeatInput/ManualSeatInput.vue';
import usePurchaseTrainingContent from '@/modules/Training/components/PurchaseTraining/usePurchaseTrainingContent';
import { useAdvisorsStore } from '@/store/advisors';

export default defineComponent({
  name: 'PurchaseTrainingCheckoutModal',
  components: {
    ModalDialog,
    BaseButton,
    TypeBody,
    BaseTag,
    PrimaryAdvisorAvatar,
    ManualSeatInput,
  },
  props: {
    employeeCount: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { pricePerBundleCourse, pending } = usePurchaseTrainingContent();
    const advisorStore = useAdvisorsStore();
    const hrManagerAvatar = computed(() => advisorStore.hrManagerAvatar);

    const seatsCount = ref(props.employeeCount);
    const isAdjustSeats = ref(false);

    const finalPrice = computed(() => {
      const coursesInBundle = 4;
      const total: number =
        seatsCount.value * coursesInBundle * pricePerBundleCourse.value;

      return currency(total).format({
        precision: 0,
      });
    });

    return {
      isLoading: pending,
      finalPrice,
      hrManagerAvatar,
      isAdjustSeats,
      seatsCount,
    };
  },
});
