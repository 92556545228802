export const getFromLocalStorage = (key) => {
  if (window?.localStorage) {
    return localStorage[key];
  }

  return null;
};

export const saveInLocalStorage = (key, value) => {
  if (window?.localStorage) {
    localStorage.setItem(key, value);
  }
};

export const removeFromLocalStorage = (key) => {
  if (window?.localStorage) {
    localStorage.removeItem(key);
  }
};

export default null;
