<template>
  <div class="flex flex-col">
    <TypeBody
      variant="link-large-tight"
      weight="strong"
      class="mb-2 text-gray-0"
      tag="div"
    >
      Set withholdings
    </TypeBody>
    <TypeBody
      class="text-gray-2"
      tag="div"
      variant="text-x-small-tight"
      weight="regular"
    >
      Select from the options below. You'll be able to change this on the next
      page.
    </TypeBody>
    <BlockCheckGroup
      v-model="formSelections"
      class="mx-0 mt-6 w-full text-left"
      :options="formOptions"
      vertical
      label-position="left"
      name="withholdings-form"
    />
    <div class="flex justify-between mt-6">
      <BaseButton
        variant="white"
        size="large"
        class="mr-4"
        @click="$emit('close')"
      >
        Cancel
      </BaseButton>

      <BaseButton
        size="large"
        class="mr-4"
        @click="$emit('next', offCycleOptions)"
      >
        {{ saveBtnLabel }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@nuxtjs/composition-api';
import { Withholdings } from '@/modules/payroll/constants/payroll';
import { BaseButton, TypeBody, BlockCheckGroup } from '@bambeehr/pollen';

export default {
  name: 'ExpertWithholdingsForm',
  components: {
    BlockCheckGroup,
    BaseButton,
    TypeBody,
  },
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    saveBtnLabel: {
      type: String,
      required: false,
      default: 'Start Payroll',
    },
  },
  setup(props) {
    const formSelections = ref(props.selectedOptions);

    const forceSupplementalWithholding = computed(() =>
      formSelections.value.includes(Withholdings.FORCE_SUPPLEMENTAL_WITHHOLDING)
    );
    const applyBenefits = computed(() =>
      formSelections.value.includes(Withholdings.APPLY_BENEFITS)
    );
    const applyPostTaxDeductions = computed(() =>
      formSelections.value.includes(Withholdings.APPLY_POST_TAX_DEDUCTIONS)
    );

    const formOptions = [
      {
        label: 'Tax all earnings for this payroll as supplemental earnings',
        description: '',
        value: Withholdings.FORCE_SUPPLEMENTAL_WITHHOLDING,
      },
      {
        label: 'Apply benefit deductions and contributions to this payroll',
        description: 'Medical, retirement, HSA, dental, vision, etc.',
        value: Withholdings.APPLY_BENEFITS,
      },
      {
        label: 'Apply post-tax deductions to this payroll',
        description:
          'Child support garnishment or miscellaneous deduction type that doesn’t fall under employee benefits.',
        value: Withholdings.APPLY_POST_TAX_DEDUCTIONS,
      },
    ];

    const offCycleOptions = computed(() => ({
      forceSupplementalWithholding: forceSupplementalWithholding.value,
      applyBenefits: applyBenefits.value,
      applyPostTaxDeductions: applyPostTaxDeductions.value,
    }));

    return {
      applyBenefits,
      applyPostTaxDeductions,
      forceSupplementalWithholding,
      formOptions,
      formSelections,
      offCycleOptions,
    };
  },
};
</script>

<style scoped>
>>> .toggle-group--normal .toggle-input {
  @apply mx-0;
}
</style>
