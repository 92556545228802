








import { TabControls } from '@bambeehr/pollen';
import {
  defineComponent,
  computed,
  ref,
  useRoute,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import useStaff from '@/modules/payroll/pages/staff/useStaff';
import { CheckOnboardingStatus } from '@/modules/payroll/constants/company';

const defaultNavItems = [
  {
    label: 'Tax Docs',
    value: '/my-pay/tax-documents',
  },
  {
    label: 'Pay Settings',
    value: '/my-pay/pay-settings',
  },
];

export default defineComponent({
  components: {
    TabControls,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { currentStaff, pending, isContractor } = useStaff();

    const navigationItems = computed(() => [
      isContractor
        ? {
            label: 'Payments',
            value: '/my-pay/payments',
          }
        : {
            label: 'Pay Stubs',
            value: '/my-pay/paystubs',
          },
      ...defaultNavItems,
    ]);

    const currentTab = navigationItems.value.find(
      (item) => route?.value?.path === item.value
    );

    const isOnboarded = computed<boolean>(() => {
      return pending.value
        ? false
        : currentStaff.value?.checkOnboarding?.status &&
            currentStaff.value.checkOnboarding.status !==
              CheckOnboardingStatus.BLOCKING;
    });

    const tabSelected = ref<string>(
      currentTab ? currentTab.label : navigationItems.value[0].label
    );

    watch(tabSelected, (tabName) => {
      const targetRoute = navigationItems.value.find(
        (item) => item.label === tabName
      );
      router.push(targetRoute?.value || '/');
    });

    return {
      isOnboarded,
      navItems: navigationItems.value.map((item) => item.label),
      path: route?.value?.path,
      tabSelected,
    };
  },
});
