import {
  Exact,
  GetBillingInfoInput,
  GetBillingInfoPaymentMethodsQuery,
  useGetBillingInfoPaymentMethodsQuery,
} from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';

import { UseQueryReturn } from '@vue/apollo-composable';

let cachedUseQuery: UseQueryReturn<
  GetBillingInfoPaymentMethodsQuery,
  Exact<{ input: GetBillingInfoInput }>
> | null = null;

export default function usePaymentMethods(force: boolean = false) {
  const { companyId } = useCurrentCompany();

  // If force is true or no cache exists, fetch a new query
  cachedUseQuery =
    force || !cachedUseQuery
      ? useGetBillingInfoPaymentMethodsQuery({
          input: { companyId: companyId.value },
        })
      : cachedUseQuery;

  return cachedUseQuery;
}
