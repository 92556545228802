import {
  reactive,
  UnwrapNestedRefs,
  watch,
  computed,
} from '@nuxtjs/composition-api';
import {
  getFromLocalStorage,
  saveInLocalStorage,
  removeFromLocalStorage,
} from '@/utils/localStorage.js';
import useCurrentCompany from '../useCurrentCompany';

// Map to store the existing states, using the storageKey as the key
const existingStateMap = new Map<string, object>();

const existingState = reactive(existingStateMap);

/**
 * This function is used when we want to tie state and localStorage together.
 *
 * @param stateProps - The initial state object.
 * @param storageKey - The key to use for storing the state in localStorage (optional).
 * @param companySpecific - Boolean indicating whether the state should be company specific.
 * @returns An object containing the reactive state and a function to hydrate the state from localStorage.
 */
export const useState = <T extends object>(
  stateProps: T,
  storageKey?: string,
  companySpecific = true
) => {
  const { companyId } = useCurrentCompany();

  const companyStateKey = computed(() => {
    if (!companySpecific) {
      return storageKey;
    }

    return [storageKey, companyId.value].filter(Boolean).join('-');
  });

  let state: UnwrapNestedRefs<T>;

  if (companyStateKey.value && existingStateMap.has(companyStateKey.value)) {
    // Use the pre-existing state if it exists.
    state = existingStateMap.get(companyStateKey.value) as UnwrapNestedRefs<T>;
  } else {
    // If the state does not exist, create a new reactive state
    state = reactive<T>(stateProps) as UnwrapNestedRefs<T>;

    if (companyStateKey.value) {
      existingStateMap.set(companyStateKey.value, state);
      // Reflect this change in 'existingState' as well
      existingState[companyStateKey.value] = state;
    }
    const stateInStorage = getFromLocalStorage(companyStateKey.value);
    if (!stateInStorage && storageKey) {
      saveInLocalStorage(companyStateKey.value, JSON.stringify(state));
    }
  }

  /**
   * Set the state with new values, and optionally save the changes to localStorage.
   *
   * @param newState - Partial state object containing the values to update.
   * @param saveToLocalStorage - Boolean indicating whether to update localStorage or not.
   */
  const setState = (
    newState: Partial<T> = {},
    saveToLocalStorage: boolean = true
  ): void => {
    Object.entries(newState).forEach(([key, val]) => {
      if (key in state) {
        const stateKey = key as keyof typeof state;
        state[stateKey] = val as UnwrapNestedRefs<T>[typeof stateKey];
      }
    });

    if (saveToLocalStorage && companyStateKey.value) {
      try {
        saveInLocalStorage(companyStateKey.value, JSON.stringify(state));
      } catch (error) {
        console.log('Error saving state to localStorage', error);
      }
    }
  };

  // Watch for changes in the state and update localStorage accordingly (if storageKey is provided)
  watch(
    state,
    (newVal) => {
      if (storageKey) {
        setState(newVal, true);
      }
    },
    {
      deep: true,
    }
  );

  /**
   * Hydrate the state from localStorage if storageKey is provided.
   */
  const hydrateFromLocalStorage = (): void => {
    if (!companyStateKey.value) {
      return;
    }

    const stateInStorage = getFromLocalStorage(companyStateKey.value);

    if (stateInStorage === null) {
      return;
    }

    try {
      const newState: T =
        (stateInStorage && JSON.parse(stateInStorage as string)) || {};
      setState(newState, false); // Don't save to localStorage when hydrating
    } catch (error) {
      console.log('Error hydrating state from localStorage', error);
    }
  };

  /**
   * Clear the state and localStorage (if storageKey is provided).
   */
  const clearState = (): void => {
    if (companyStateKey.value) {
      removeFromLocalStorage(companyStateKey.value);
      existingState[companyStateKey.value] = undefined;
      existingStateMap.delete(companyStateKey.value);
    }
    setState(stateProps, false);
  };

  return {
    state,
    hydrateFromLocalStorage,
    clearState,
  };
};

export default useState;
