import ContentfulEntryIDs from '@/lib/globals/ContentfulEntryIDs';
import Contentful from '@/services/Contentful';

export const state = () => ({
  notifications: {},

  holidays: {
    dates: [],
    bannerDates: [],
    message: '',
  },
});

export const getters = {
  holidays(state) {
    return {
      dates: [],
      bannerDates: [],
      message: '',
      ...state.holidays,
    };
  },
};

export const mutations = {
  setHolidays(state, holidays) {
    if (holidays) {
      state.holidays = holidays;
    }
  },

  setNotifications(state, data) {
    state.notifications = data;
  },
};

export const actions = {
  fetchHolidays({ commit }) {
    return Contentful.getEntry(ContentfulEntryIDs.HOLIDAYS).then((data) => {
      commit('setHolidays', data.fields);
    });
  },

  fetchNotifications({ commit }) {
    return this.$axios.get('/v0/app/my/notifications').then((res) => {
      commit('setNotifications', res.data);
    });
  },
};
