import {
  ContractorPayment,
  GetTimesheetsByRoleQuery,
  PaymentType,
  PayrollItem,
  ContractorPayType,
} from '@/gql/generated';

import { UserAvatar } from '@bambeehr/pollen';
import { WorkerItem } from '../types';
import currency from '@bambeehr/currency';
import getHoursAndMinsFromHours from './getHoursAndMinsFromHours';

export default (res: GetTimesheetsByRoleQuery): WorkerItem[] => {
  const contractors = res?.payroll?.contractorPaymentsPage?.results || [];
  const employees = res?.payroll?.itemsPage?.results || [];

  if (!contractors.length && !employees.length) {
    return [];
  }
  const result = [...contractors, ...employees]
    .filter((i) => !!i.timesheetHoursByRole?.length)
    .map((worker) => {
      const info =
        (worker as ContractorPayment).contractor ||
        (worker as PayrollItem).employee ||
        {};

      const profile = info?.profile;
      const image = info?.user?.profile?.avatarUrl;
      const name = `${profile?.firstName} ${profile?.lastName}`;
      const timesheets = worker?.timesheetHoursByRole ?? [];

      return timesheets
        .filter((t) => t?.totalHours)
        .map((timesheet) => {
          const payFrequency =
            info.payType ===
            (PaymentType.Hourly as PaymentType | ContractorPayType)
              ? 'hr'
              : 'yr';
          const rate = timesheet?.role?.payRate ?? info.payRate;

          return {
            id: worker.id,
            worker: {
              component: UserAvatar,
              label: name as string,
              attrs: {
                name: name as string,
                image: image as string,
                title: (timesheet?.role?.title ?? profile?.title) as string,
              },
            },
            rate: `${currency(rate).format()}/${payFrequency}`,
            regularHours: getHoursAndMinsFromHours(timesheet?.regularHours),
            otHours: getHoursAndMinsFromHours(timesheet?.overtimeHours),
            holidayHours: getHoursAndMinsFromHours(timesheet?.holidayHours),
            ptoHours: getHoursAndMinsFromHours(timesheet?.ptoHours),
            sickHours: getHoursAndMinsFromHours(timesheet?.sickHours),
            total: getHoursAndMinsFromHours(timesheet?.totalHours),
          };
        });
    })
    .flat();

  return result;
};
