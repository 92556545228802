import { CompanyStatuses } from '@/modules/payroll/constants/company';
import {
  getCompanyStatus,
  getContractorStatus,
  getEmployeeStatus,
} from '@/gql/services/payroll.service';
import {
  getHasValidCompanyStatus,
  getHasValidEmployeeStatus,
  setHasValidCompanyStatus,
  setHasValidEmployeeStatus,
} from './employeePayrollGuard.state';

const validateCompanyStatus = async (store, force) => {
  const hasAccess = getHasValidCompanyStatus();
  // If access has already been verified, no need to verify again
  if (hasAccess && !force) {
    return hasAccess;
  }

  // Get Company ID, Check Company's Payroll Status, return if falsey
  const { companyId } = store.getters;
  let { data: company } = await getCompanyStatus(companyId);
  company = company || {};
  const { status: companyStatus } = company;
  const allowedStatuses = [
    CompanyStatuses.LIVE,
    CompanyStatuses.SETUP_COMPLETE,
    CompanyStatuses.INITIALIZED,
    CompanyStatuses.CANCELLED,
  ];

  const isCompanySetupInPayroll = allowedStatuses.includes(companyStatus);

  if (!isCompanySetupInPayroll) {
    setHasValidCompanyStatus();

    return false;
  }

  return true;
};

const validateEmployeeStatus = async (store, force) => {
  const hasAccess = getHasValidEmployeeStatus();

  if (hasAccess && !force) {
    return hasAccess;
  }

  // Get Employee ID, check the employee's payroll status, return if falsey
  const { currentUser } = store.getters;
  const { data: employee } = await getEmployeeStatus(currentUser._id);
  const { data: contractor } = await getContractorStatus(currentUser._id);

  const isEmployeeInitializedInPayroll = !!employee || !!contractor;

  if (!isEmployeeInitializedInPayroll) {
    setHasValidEmployeeStatus();

    return false;
  }

  setHasValidEmployeeStatus(true);

  return true;
};

/**
 * Determines if the employee payroll feature can be accessed
 * @param {Object} store Application state object
 * @param {Boolean} force forces a new validation run, even if previous validation was successful
 * @returns Boolean Whether or not the validation passed
 */
export const canAccess = async (store, force = false) => {
  const hasValidCompanyStatus = await validateCompanyStatus(store, force);
  const hasValidEmployeeStatus = await validateEmployeeStatus(store, force);

  return hasValidCompanyStatus && hasValidEmployeeStatus;
};

export default async ({ store, redirect }) => {
  const hasValidCompanyStatus = await validateCompanyStatus(store);
  const hasValidEmployeeStatus = await validateEmployeeStatus(store);

  if (!(hasValidCompanyStatus && hasValidEmployeeStatus)) {
    redirect('/');
  }
};
