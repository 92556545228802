import { provide } from '@nuxtjs/composition-api';
import { ApolloClients } from '@vue/apollo-composable';
import publicClient from '@/gql/apolloClient';

export default ({ app }) => {
  app.setup = () => {
    provide(ApolloClients, {
      default: publicClient,
    });
  };
};
