import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import { isActivated } from '@/lib/activationStatus';
import { OnboardingMilestoneTag } from '@/modules/OnboardingWizard/constants/OnboardingMilestoneTag';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import { Milestones } from '@/modules/TaskCenter/types/Milestones';
import { watch } from '@nuxtjs/composition-api';
import useMiddlewareContext from './hooks/useMiddlewareContext';
import getPlanPackage from '@/helpers/accessControl/getPlanPackage';
import { getEventScheduledStatus } from '@/gql/services/payroll.service';
import { hrIntroCallEvent } from '@/lib/globals/calendly';
import useCompanyStore from '@/store/companies';

export const ONBOARDING_WIZARD_PATH = '/get-started';
const PAYROLL_BETA_TOS_PATH = '/beta-tos';
const PAYROLL_ONBOARDING_PATH = '/payroll-setup';

const GET_STARTED_ABOUT = '/get-started/about-your-company';
const GET_STARTED_LETS_GO = '/get-started/lets-go';

const filterSkippableMilestones = (onboardingMilestones) => {
  return onboardingMilestones.filter(
    (m) =>
      // remove the milestones that are skippable
      m.milestone !== Milestones.WELCOME_CALL &&
      m.milestone !== Milestones.ACKNOWLEDGE_HRM &&
      m.milestone !== Milestones.SCHEDULE_ONBOARDING_WEBINAR
  );
};

const filterSkippableT0Milestones = (onboardingMilestones) => {
  return onboardingMilestones.filter(
    (m) =>
      // remove the milestones that are skippable
      m.milestone !== Milestones.WELCOME_CALL &&
      m.milestone !== Milestones.ACKNOWLEDGE_HRM &&
      m.milestone !== Milestones.ADD_STAFF &&
      m.milestone !== Milestones.SCHEDULE_ONBOARDING_WEBINAR
  );
};

const filterRoadmapMilestone = (onboardingMilestones) => {
  return onboardingMilestones.filter(
    (m) => m.milestone !== Milestones.WELCOME_CALL
  );
};

const filterHrPracticeMilestones = (onboardingMilestones) => {
  return onboardingMilestones.filter(
    (m) => m.milestone !== Milestones.HR_PRACTICE
  );
};

let isEventScheduled;

export default async () => {
  const { store, route, redirect } = useMiddlewareContext();
  const { currentUser } = store.getters;

  if (!isActivated(currentUser)) {
    return;
  }

  const isAlreadyInOnboardingWizard = route.path.includes(
    ONBOARDING_WIZARD_PATH
  );

  const { isBambeeLite } = await getPlanPackage(
    currentUser?._company?.stripe?.customer?.id
  );

  if (isEventScheduled === undefined) {
    const { data: eventData } = await getEventScheduledStatus({
      email: currentUser._auth.email,
      eventSlug: hrIntroCallEvent,
    });

    isEventScheduled = !!eventData?.isScheduled;
  }

  if (isAlreadyInOnboardingWizard) {
    if (
      (isBambeeLite || isEventScheduled) &&
      route.path.includes('hr-roadmap')
    ) {
      redirect(GET_STARTED_ABOUT);

      return;
    }

    if (isBambeeLite && route.path.includes('add-staff')) {
      redirect(GET_STARTED_LETS_GO);

      return;
    }

    return;
  }

  let onboardingMilestones = useCompanyStore().onboardingMilestonesByTag(
    OnboardingMilestoneTag.INTRO
  );

  if (!onboardingMilestones) {
    return;
  }

  const { currentPlan, isTierZero } = useCurrentPlan({ store });

  // if current plan is chatOnly
  // filter out the milestones that are skippable
  // they should not be considered when checking if onboarding is completed
  // othewise, check if all onboarding milestones are completed
  if (currentPlan.value?.chatOnly) {
    onboardingMilestones = filterSkippableMilestones(onboardingMilestones);
  }

  if (isTierZero || isBambeeLite) {
    onboardingMilestones = filterSkippableT0Milestones(onboardingMilestones);
  }

  if (isEventScheduled) {
    onboardingMilestones = filterRoadmapMilestone(onboardingMilestones);
  }

  // This shouldn't be evaluated in the onboarding wizard flow.
  onboardingMilestones = filterHrPracticeMilestones(onboardingMilestones);

  const onboardingCompleted = onboardingMilestones.every((m) => m.status);

  if (onboardingCompleted) {
    return;
  }

  if (
    route.path.includes(PAYROLL_BETA_TOS_PATH) ||
    route.path.includes(PAYROLL_ONBOARDING_PATH)
  ) {
    if (!onboardingCompleted) {
      const { isOnboarding, hasPayroll, isLoading } =
        usePayrollOnboardingStatus();

      const unwatch = watch(
        isLoading,
        (value) => {
          if (hasPayroll.value) {
            isOnboarding.value = true;
          }

          if (!value && unwatch) {
            unwatch();
          }
        },
        { immediate: true }
      );
    }

    return;
  }

  redirect(ONBOARDING_WIZARD_PATH);
};
