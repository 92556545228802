




























import { defineComponent } from '@nuxtjs/composition-api';
import CalendlyEvent from '@/components/scheduler/CalendlyEvent';
import {
  TypeDisplay,
  ModalDialog,
  BaseCard,
  BaseButton,
} from '@bambeehr/pollen';

export default defineComponent({
  name: 'ScheduleOnboardingCallConfirmation',
  components: {
    ModalDialog,
    TypeDisplay,
    BaseCard,
    BaseButton,
    CalendlyEvent,
  },
  props: {
    meetingId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
