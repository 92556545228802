






























import { defineComponent } from '@nuxtjs/composition-api';
import {
  TypeDisplay,
  ModalDialog,
  BaseCard,
  BaseButton,
} from '@bambeehr/pollen';
import CalendlyCard from '@/components/scheduler/CalendlyCard';
import CalendlyEvent from '@/components/scheduler/CalendlyEvent';
import FancyCheck from './imgs/FancyCheck';

export default defineComponent({
  name: 'ScheduleBenefitsCallModal',
  components: {
    ModalDialog,
    TypeDisplay,
    CalendlyCard,
    CalendlyEvent,
    BaseCard,
    FancyCheck,
    BaseButton,
  },
  props: {
    meetingId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
