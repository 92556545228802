<template>
  <TypeBody
    class="no-payrolls rounded-md border border-gray-5 flex items-center justify-center p-4"
    variant="text-small"
  >
    <slot />
  </TypeBody>
</template>
<script>
import { TypeBody } from '@bambeehr/pollen';

export default {
  name: 'EmptyDashboardList',
  components: { TypeBody },
};
</script>
