export default function filterPayItemsWithNoIncome(payItems = []) {
  return payItems.filter((payItem) => {
    const hasPay = (
      payItem?.pay ||
      payItem?.earnings ||
      payItem?.entries
    )?.find((pay) => pay.amount > 0);
    if (hasPay) {
      return true;
    }
    const reimbursementPresent = payItem?.reimbursements?.find(
      (reimbursement) => reimbursement.amount > 0
    );
    if (reimbursementPresent) {
      return true;
    }

    return false;
  });
}
