









































































import { defineComponent, onBeforeUnmount } from '@nuxtjs/composition-api';
import GlowModal from '@/components/GlowModal.vue';
import FancyButton from './FancyButton.vue';
import useAIUpsell, { defaultCopy } from '@/modules/AI/hooks/useAIUpsell';
import { AIUpsellEvents } from '@/modules/AI/constants/events';
import bam from '@/lib/bam';

export default defineComponent({
  name: 'AiModal',
  components: {
    GlowModal,
    FancyButton,
  },
  emits: ['primary', 'secondary'],
  setup(_, { emit }) {
    const { currentCopy } = useAIUpsell();

    bam.trackEvent(AIUpsellEvents.AI_OPT_IN_INFO_VIEW, {
      context: currentCopy.value.eventContext,
    });

    onBeforeUnmount(() => {
      currentCopy.value = defaultCopy;
    });

    const handlePrimary = () => {
      emit('primary');
    };

    const handleSecondary = () => {
      emit('secondary');
    };

    return {
      currentCopy,
      handlePrimary,
      handleSecondary,
    };
  },
});
