<template>
  <MeerkatNotifications
    class="z-50"
    :notifications="meerkatNotifications"
    position="bottom-center"
    @close="removeNotification"
  />
</template>

<script>
import { computed, watch, defineComponent } from '@nuxtjs/composition-api';
import { MeerkatNotifications } from '@bambeehr/pollen';
import { Timer } from '@/modules/payroll/constants/payroll';
import useNotifications, {
  NotificationTypes,
} from '@bambeehr/use-notifications';
import useErrorHandler, { ErrorCategories } from '@/hooks/useErrorHandler';
import { surfaceErrorToUser } from '@/gql/apolloWrapper/apolloWrapper';
import { isProd } from '@/utils/env-helper';

const logToDD = (err, surfacedToUser = false, transformedError) => {
  window?.DD_RUM?.addError(err, {
    source: 'baseNotifications',
    surfacedToUser,
    transformedError,
  });
};

const transformMap = [
  {
    errors: [`Unexpected token '<'`, 'socket hang up', 'timeout exceeded'],
    message: `We're having trouble connecting to the server. Please reload and try again.`,
  },
];

const transformErrorForUser = (message) =>
  transformMap.find((e) => e.errors.includes(message))?.message || message;

export default defineComponent({
  name: 'BaseNotifications',
  components: {
    MeerkatNotifications,
  },
  setup() {
    const {
      notifications,
      addError,
      remove: removeNotification,
    } = useNotifications();
    const errorHandler = useErrorHandler();

    const promoteErrorTypeToNotifications = (errors, errorType) => {
      const filteredErrors = errors.filter((err) => err.category === errorType);

      filteredErrors.forEach((err) => {
        const transformedError = transformErrorForUser(err.message);
        // List of blacklisted errors that we don't want to show to the user
        const notBlacklisted = surfaceErrorToUser(transformedError);
        const existingError = notifications.value.find((n) =>
          n.message.includes(transformedError)
        );

        // When we have duplicate errors we don't want to render multiple notifications
        // We instead want to update the existing notification with a count
        if (existingError) {
          // If count extists, increment
          // If not, start at 2 because we already have one in the list
          existingError.meta.count = existingError.meta.count
            ? existingError.meta.count + 1
            : 2;

          existingError.message = `${transformedError} (${existingError.meta.count})`;
        } else if (notBlacklisted) {
          addError(transformedError);
        }

        logToDD(err, notBlacklisted, transformedError);
        errorHandler.resolve(err.id);
      });
    };

    watch(errorHandler.errors, (newVal) => {
      if (!newVal?.length) {
        return;
      }

      promoteErrorTypeToNotifications(newVal, ErrorCategories.API);

      // Present all errors as notification when not in production
      if (isProd) {
        const allUncaughtErrs = newVal.filter(
          (err) => err.category === ErrorCategories.UNCAUGHT
        );

        allUncaughtErrs.forEach((err) => {
          logToDD(err, false);
          errorHandler.resolve(err.id);
        });
      } else {
        promoteErrorTypeToNotifications(newVal, ErrorCategories.UNCAUGHT);
      }
    });

    const meerkatNotifications = computed(() => {
      return notifications.value
        .map(({ id, message = '', title = '', type, meta }) => {
          if (!message && !title) {
            return null;
          }

          const finalMsg = [title, message].filter((x) => !!x).join('\n');
          let { timer = Timer.MEDIUM } = meta;

          let variant;
          switch (type) {
            case NotificationTypes.FATAL:
            case NotificationTypes.ERROR:
              variant = 'error';
              timer = 7000;
              break;

            case NotificationTypes.SUCCESS:
              variant = 'secondary';
              break;

            default:
              variant = 'default';
              break;
          }

          return {
            id,
            message: finalMsg,
            timer,
            variant,
            actions: [
              {
                label: 'Close',
                handler: () => removeNotification(id),
              },
            ],
          };
        })
        .filter((x) => !!x);
    });

    return {
      meerkatNotifications,
      removeNotification,
    };
  },
});
</script>
