import { StaffMember, StaffStates } from '@/modules/StaffManagement/models';

export const getNotInvitedStaffIds = (staffList: StaffMember[]): string[] =>
  staffList
    ?.filter((staff) => {
      return staff.staffState === StaffStates.NOT_INVITED;
    })
    .map((staff) => staff.id) || [];

export const getNeverLoggedInStaffIds = (staffList: StaffMember[]): string[] =>
  staffList
    ?.filter((staff) => {
      // if user state is invited they have not logged in
      return staff.staffState === StaffStates.INVITED;
    })
    .map((staff) => staff.id) || [];

export const getNotSignedStaffIds = (staffList: StaffMember[]): string[] =>
  staffList
    ?.filter((staff) => {
      const docs = staff.documents || [];

      return (
        // has docs
        !!docs.length &&
        // not all docs signed
        !docs.every((doc) => doc.signed) &&
        // exclude not invited and former staff
        ![StaffStates.NOT_INVITED, StaffStates.FORMER].includes(
          staff.staffState
        )
      );
    })
    .map((staff) => staff.id) || [];

export default null;
