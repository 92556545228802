





























import { TextInput, TypeBody, TypeDisplay } from '@bambeehr/pollen';
import useAddWorkerPolicies from '@/modules/StaffManagement/components/AddStaffWizard/Policies/useAddWorkerPolicies';

export default {
  components: {
    TextInput,
    TypeBody,
    TypeDisplay,
  },
  setup() {
    const { searchQuery } = useAddWorkerPolicies();

    return {
      searchQuery,
    };
  },
};
