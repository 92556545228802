// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';

interface Auth {
  _id: string;
  username: string;
  email: string;
  active: boolean;
  TOSAccepted: boolean;
  activation_status: string;
  created_at: string;
}

export interface MiddlewareContext extends Context {
  currentUser: any;
  role: string;
  _auth: Auth;
}

let context: MiddlewareContext;

const useMiddlewareContext = (middlewareContext?: MiddlewareContext) => {
  if (middlewareContext) {
    context = middlewareContext;
  }

  return context;
};

export default useMiddlewareContext;
