import useMiddlewareContext from './hooks/useMiddlewareContext';

const getFeatId = (feat: string) => `FEATURE_USAGE_${feat}`;

const addAction = (eventName: string, eventData?: any) => {
  if (window?.DD_RUM) {
    window.DD_RUM?.addAction(eventName, eventData);
  }
};

enum FEAT {
  BENEFITS = 'BENEFITS',
  BULK_ADD_STAFF = 'BULK_ADD_STAFF',
  EE_FEEDBACK = 'EE_FEEDBACK',
  EE_VOICES = 'EE_VOICES',
  END_OF_EMPLOYMENT = 'END_OF_EMPLOYMENT',
  HR_REQUESTS = 'HR_REQUESTS',
  JOB_DESCRIPTIONS = 'JOB_DESCRIPTIONS',
  MANAGED_SERVICES = 'MANAGED_SERVICES',
  PAYROLL = 'PAYROLL',
  POLICIES = 'POLICIES',
  OFFERS = 'OFFERS',
  TASK_CENTER = 'TASK_CENTER',
  STAFF_MANAGEMENT = 'STAFF_MANAGEMENT',
  TIME_AND_ATTENDANCE = 'TIME_AND_ATTENDANCE',
  TRAINING = 'TRAINING',
  CABINET = 'CABINET',
  INSURANCE = 'INSURANCE',
  REPORT_CARDS = 'REPORT_CARDS',
  PIPS = 'PIPS',
  CAPS = 'CAPS',
  TEAM_PAGE = 'TEAM_PAGE',
  GOALS_TASKS = 'GOALS_TASKS',
}

interface Group {
  feature: FEAT;
  routes: string[];
}

const loggingGroups = [
  {
    feature: FEAT.BENEFITS,
    routes: ['/benefits', '/my-benefits', '/get-benefits'],
  },
  {
    feature: FEAT.BULK_ADD_STAFF,
    routes: ['/bulk-add'],
  },
  {
    feature: FEAT.EE_FEEDBACK,
    routes: ['/report-cards'],
  },
  {
    feature: FEAT.EE_VOICES,
    routes: ['/voices'],
  },
  {
    feature: FEAT.END_OF_EMPLOYMENT,
    routes: ['/end-of-employment'],
  },
  {
    feature: FEAT.HR_REQUESTS,
    routes: ['/requests'],
  },
  {
    feature: FEAT.JOB_DESCRIPTIONS,
    routes: ['/job-description', '/create-job-description'],
  },
  {
    feature: FEAT.JOB_DESCRIPTIONS,
    routes: ['/job-description', '/create-job-description'],
  },
  {
    feature: FEAT.MANAGED_SERVICES,
    routes: ['/managed-services'],
  },
  {
    feature: FEAT.PAYROLL,
    routes: [
      '/payroll',
      '/get-payroll',
      '/my-pay',
      '/payroll-onboarding',
      '/payroll-activation',
    ],
  },
  {
    feature: FEAT.POLICIES,
    routes: ['/managed-services'],
  },
  {
    feature: FEAT.OFFERS,
    routes: ['/partner-offers'],
  },
  {
    feature: FEAT.STAFF_MANAGEMENT,
    routes: ['/employee'],
  },
  {
    feature: FEAT.TIME_AND_ATTENDANCE,
    routes: ['/verbal-warning', '/written-warning'],
  },
  {
    feature: FEAT.TRAINING,
    routes: ['/training', '/my-training'],
  },
  {
    feature: FEAT.CABINET,
    routes: ['/cabinet'],
  },
  {
    feature: FEAT.INSURANCE,
    routes: ['/insurance', '/qna/by-type/InsuranceOverview'],
  },
  {
    feature: FEAT.REPORT_CARDS,
    routes: ['/report-cards'],
  },
  {
    feature: FEAT.TEAM_PAGE,
    routes: ['/my-team'],
  },
  {
    feature: FEAT.GOALS_TASKS,
    routes: ['/goal', '/task'],
  },
];
export default async () => {
  const { route } = useMiddlewareContext();

  const checkGroup = (group: Group) => {
    if (group.routes.some((i) => route.path.includes(i))) {
      addAction(getFeatId(group.feature));
    }
  };

  loggingGroups.forEach(checkGroup);
};
