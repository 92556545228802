import serializeObject from '@/utils/serializeObject';

export type ScheduledCalendlyEvent = {
  event: string;
  payload: {
    event: {
      uri: string;
    };
    invitee: {
      uri: string;
    };
  };
};

/**
 * Calendly constants
 */

/*
  Slugs

  Usage: <calendy team slug>/<calendly event slug>

  For team slugs, see envs
 */
// Sales Event Slug
export const calendlySalesEvent = 'lead-overview-call';
// HR General Overview Call Slug
export const calendlyHrOverviewEvent = '30min';
// Insurance
export const calendlyInsuranceOverviewEvent = 'jw';
// Personal Insurance
export const calendlyPersonalInsuranceEvent = 'personal-insurance-overview';
// Essential Upgrade
export const calendlyEssentialUpgradeEvent = 'hr-essential-upgrade-call';

export const hrIntroCallEvent = 'bambee-hr-intro-call';
export const newCustomerIntroductionEvent = 'new-customer-introduction';
export const businessManagerPlanAssistanceEvent =
  'business-manager-plan-assistance';

export const workplaceViolenceWebinarEvent =
  'webinar-workplace-violence-prevention';
export const workplaceViolenceHRMFollowUpEvent =
  'bambee-hr-group-wvpp-strategy-session';
export const workplaceViolenceWebinarNonCaEvent =
  'webinar-workplace-violence-prevention-nonca';

/*
  Magic constants
 */
// Cookie name used after Calendly sales event fired
export const calendlySalesCookie = 'lead-event';

/*
  Helper functions
 */

export const calendlyReschedulingUrl = (inviteeId, utmContent) => {
  const params = {
    hide_event_type_details: 1,
    primary_color: '68478d',
    utm_source: 'app',
    utm_content: utmContent,
  };
  const qs = serializeObject(params);

  return `https://calendly.com/reschedulings/${inviteeId}?${qs}`;
};
