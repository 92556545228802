




























































import { defineComponent, Ref, ref } from '@nuxtjs/composition-api';
import { ModalDialog, TypeBody, DataTable, BaseButton } from '@bambeehr/pollen';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import {
  GetTimesheetsByRoleQuery,
  useGetTimesheetsByRoleQuery,
  useSyncTimesheetHoursMutation,
  ContractorPayment,
  PayrollItem,
  useImportTimesheetByWorkerListMutation,
  ImportTimesheetByWorkerListMutation,
} from '@/gql/generated';
import CachePolicy from '@/gql/CachePolicy';
import {
  useApolloMutation,
  useApolloQuery,
  WrapperError,
} from '@/gql/apolloWrapper';
import { formatDate, formatTime } from '@/utils/date';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal.vue';
import { WorkerItem } from './types';
import mapPayrollToWorkerList from './utils/mapPayrollToWorkerList';
import getSelectedWorkerList from './utils/getSelectedWorkerList';

const getTimesheetData = (
  companyId: string,
  payrollId: string,
  isLoading: Ref<boolean>,
  lastImportedDate: Ref<string>,
  callback?: (res: GetTimesheetsByRoleQuery) => void
) => {
  isLoading.value = true;
  const { onDone: synced, mutate: sync } = useApolloMutation(
    useSyncTimesheetHoursMutation
  );
  sync({
    data: {
      companyId,
      id: +payrollId,
      import: false,
    },
  });

  synced((res) => {
    const updatedAt = res.syncTimesheets.lastUpdate
      ? new Date(res.syncTimesheets.lastUpdate)
      : new Date();
    lastImportedDate.value = `${formatDate(updatedAt, '')} at ${formatTime(
      updatedAt,
      ''
    )}`;

    const { onResult } = useApolloQuery(
      useGetTimesheetsByRoleQuery,
      { payrollId },
      { pending: isLoading },
      undefined,
      // This should always pull fresh -- we don't want to cache this data
      { fetchPolicy: CachePolicy.NETWORK_ONLY }
    );

    onResult((result) => {
      callback?.(result);
    });
  });
};

const importTimesheetsIntoPayroll = (
  companyId: string,
  payrollId: string,
  employeeIds: string[],
  contractorIds: string[],
  callback?: Function
) => {
  const {
    mutate: importTimesheets,
    onDone,
    onError,
  } = useApolloMutation(useImportTimesheetByWorkerListMutation);

  onDone((res) => {
    callback?.(res);
  });

  onError((err) => {
    callback?.(null, err);
  });

  importTimesheets({
    data: {
      companyId,
      id: +payrollId,
      employeeIds,
      contractorIds,
    },
  });
};

const tableHeader = {
  worker: {
    label: 'Worker',
    sortable: true,
    priority: true,
  },
  rate: {
    label: 'Pay Rate',
  },
  regularHours: {
    label: 'Regular',
    alignment: 'right',
  },
  otHours: {
    label: 'Overtime',
    alignment: 'right',
  },
  holidayHours: {
    label: 'Holiday',
    alignment: 'right',
  },
  ptoHours: {
    label: 'PTO',
    alignment: 'right',
  },
  sickHours: {
    label: 'Sick',
    alignment: 'right',
  },
  total: {
    label: 'Total',
    alignment: 'right',
    bold: true,
  },
};

const getHeaderConfig = (header, screen) => {
  switch (screen.breakpoint) {
    case 'xs':
    case 'sm':
      header.regularHours.visible = false;
      header.otHours.visible = false;
      header.holidayHours.visible = false;
      header.rate.visible = false;
      break;

    case 'md':
    default:
      header.regularHours.visible = true;
      header.otHours.visible = true;
      header.holidayHours.visible = true;
      header.rate.visible = true;
      break;
  }

  return header;
};

export default defineComponent({
  components: {
    ModalDialog,
    TypeBody,
    DataTable,
    BaseButton,
    LoadingModal,
  },
  props: {
    payrollId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { companyId } = useCurrentCompany();
    const isLoading = ref(false);
    const isImporting = ref(false);
    const workerList = ref<WorkerItem[]>([]);
    const lastImportedDate = ref('');

    const contractorList = ref<ContractorPayment[]>([]);
    const employeeList = ref<PayrollItem[]>([]);

    const getData = () => {
      const onResult = (result: GetTimesheetsByRoleQuery) => {
        workerList.value = mapPayrollToWorkerList(result);
        contractorList.value = (result.payroll?.contractorPaymentsPage
          .results || []) as ContractorPayment[];
        employeeList.value = (result.payroll?.itemsPage.results ||
          []) as PayrollItem[];
      };
      getTimesheetData(
        companyId.value,
        props.payrollId,
        isLoading,
        lastImportedDate,
        onResult
      );
    };

    getData();

    const handleImportDone = (
      res: ImportTimesheetByWorkerListMutation,
      error?: WrapperError
    ) => {
      if (error?.hasErrors) {
        emit(
          'error',
          error.networkError?.message || error.responseErrors?.[0].error.message
        );

        isImporting.value = false;

        return;
      }

      const imported = res.importTimesheets;
      if (imported) {
        emit('imported', imported.contractors + imported.employees);
        isImporting.value = false;
      }
    };

    const actions = [
      {
        attrs: {
          label: 'Import Selected',
        },
        handler: (model: { [key: string]: boolean }) => {
          const modelList = Object.entries(model);
          const employees = getSelectedWorkerList(
            employeeList.value,
            modelList,
            'employee'
          );
          const contractors = getSelectedWorkerList(
            contractorList.value,
            modelList,
            'contractor'
          );

          isImporting.value = true;
          importTimesheetsIntoPayroll(
            companyId.value,
            props.payrollId,
            employees,
            contractors,
            handleImportDone
          );
        },
      },
    ];

    return {
      workerList,
      actions,
      lastImportedDate,
      isLoading,
      getData,
      isImporting,
    };
  },
  computed: {
    tableHeader() {
      // vue-screen isn't available in setup
      // Setting just the header config updates here
      // @ts-ignore, ignoring type check on this.$screen
      return getHeaderConfig(tableHeader, this.$screen);
    },
  },
});
