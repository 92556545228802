<template>
  <div>
    <div v-if="normalizedPayrolls.length" class="progress-tile-container">
      <PayrollTile
        v-for="payroll in normalizedPayrolls"
        :id="payroll.id"
        :key="payroll.id"
        class="payroll-progress-tile"
        :total-gross="payroll.employeeGross"
        :staff-count="getStaffCount(payroll)"
        :payroll-status="payroll.status"
        :payroll-start-date="payroll.periodStart"
        :payroll-end-date="payroll.periodEnd"
        :payroll-status-date="payroll.statusChangedAt"
        @click="handlePayrollTileClick(payroll)"
      />
    </div>
    <EmptyDashboardList v-if="emptyMsg && !normalizedPayrolls.length">
      {{ emptyMsg }}
    </EmptyDashboardList>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import PayrollTile from '@/modules/payroll/components/PayrollTile/PayrollTile';
import EmptyDashboardList from '@/modules/payroll/components/DashboardTile/EmptyDashboardList';

export default {
  name: 'PayrollTileList',
  components: {
    EmptyDashboardList,
    PayrollTile,
  },
  props: {
    payrolls: {
      type: Array, // Payroll[]. Matches the Payroll Object from the API response
      required: true,
    },
    emptyMsg: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const normalizedPayrolls = computed(() =>
      props.payrolls.map((payroll) => ({
        ...payroll,
        employeeGross: payroll.totals?.liability || 0,
      }))
    );

    function handlePayrollTileClick(payroll) {
      emit('click', payroll);
    }

    const getStaffCount = (payroll) =>
      payroll ? payroll.employeeCount + payroll.contractorCount : 0;

    return {
      getStaffCount,
      handlePayrollTileClick,
      normalizedPayrolls,
    };
  },
};
</script>

<style scoped>
.payroll-progress-tile + .payroll-progress-tile {
  @apply mt-3;
}
</style>
