import {
  featuredInsuranceLineIds,
  featuredPersonaInsuranceLineIds,
  insuranceLines,
  personalInsuranceLines
} from '@/lib/globals/insurance';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import Vue from 'vue';

const insuranceLinesKeys = Object.keys(insuranceLines);

const createPlanPayload = function (plan) {
  const payload = {
    ...plan,
    carrierId: plan.carrierId || plan.carrier.id || undefined,
    effectiveDate: dayjs(plan.effectiveDate).toISOString(),
    expirationDate: dayjs(plan.expirationDate).toISOString(),
    premium: +plan.premium || undefined,
    deductible: +plan.deductible || undefined,
  };

  if (!payload.carrierId) {
    payload.unassociatedCarrierName = payload.carrier.name;
  }
  delete payload.carrier;

  return payload;
};

const formatPlan = function (rawPlan) {
  const plan = cloneDeep(rawPlan);
  const line = insuranceLines[plan.lineType];

  if (line) {
    plan.lineTypeName = line.name;
    plan.lineTypeShortName = line.shortName;
    plan.order = line.featuredOrder || 1000;
    plan.icon = line.icon || 'type-5.png';
  }
  if (rawPlan.effectiveDate) {
    plan.effectiveDate = dayjs(rawPlan.effectiveDate).toDate();
  }
  if (rawPlan.expirationDate) {
    plan.expirationDate = dayjs(rawPlan.expirationDate).toDate();
  }

  return plan;
};

export const state = () => ({
  addPlanData: '',

  carriers: {},
  insuranceLines: {},

  plans: {},
  agentByPlan: {},
  filesByPlan: {},

  existingPlansByCompany: {},
  draftPlansByCompany: {},

  signBORPlan: {
    plan: null,
    previewId: null,
  },

  showUpSell: false,

  getPlansRequestStatus: false,
});

export const getters = {
  // Generic getters
  carriers: (state) => sortBy(state.carriers, ['name']),
  carrierById: (state) => (carrierId) => state.carriers[carrierId],

  planById: (state) => (planId) => state.plans[planId],
  filesByPlanId: (state) => (planId) => {
    return state.filesByPlan[planId];
  },
  existingPlansByCompany: (state) => (companyId) =>
    state.existingPlansByCompany[companyId],
  draftsByCompany: (state) => (companyId) =>
    state.draftPlansByCompany[companyId],

  lines: (state) => state.insuranceLines,
  personalLines: (state) => state.personalInsuranceLines,
  sortedLines: (state) =>
    sortBy(state.insuranceLines, ['featuredOrder', 'name']),
  featuredLines: (state) =>
    sortBy(
      featuredInsuranceLineIds.map((id) => insuranceLines[id]),
      ['featuredOrder']
    ),

  // Getting for company in scope
  existingPlans: (state, getters, rootState, rootGetters) => {
    const existingPlans = (
      getters.existingPlansByCompany(rootGetters.companyId) || []
    ).map((id) => {
      return state.plans[id];
    });

    return sortBy(existingPlans, ['order']);
  },
  transferablePlans: (state, getters) => {
    return (getters.existingPlans || []).filter(
      (p) => p.bambeePlanStatus === 'none'
    );
  },
  draftPlans: (state, getters, rootState, rootGetters) => {
    const drafts = (getters.draftsByCompany(rootGetters.companyId) || []).map(
      (id) => {
        return state.plans[id];
      }
    );

    return sortBy(drafts, ['order']);
  },
  companyAvailablePlans: (state, getters) => {
    const existingLines = []
      .concat(getters.existingPlans, getters.draftPlans)
      .map((p) => p.lineType);

    const availableLineIds = difference(
      featuredInsuranceLineIds,
      existingLines
    );
    const availablePlans = availableLineIds.map((lineId) => {
      const line = insuranceLines[lineId];

      return {
        lineType: line.id,
        lineTypeName: line.name,
        lineTypeShortName: line.shortName,
        description: line.description,
        order: line.featuredOrder,
        icon: line.icon || 'type-5.png',
      };
    });

    return sortBy(availablePlans, ['order']);
  },
  personalAvailablePlans: () => {
    const plans = featuredPersonaInsuranceLineIds.map((lineId) => {
      const line = personalInsuranceLines[lineId];

      return {
        lineType: line.id,
        lineTypeName: line.name,
        lineTypeShortName: line.shortName,
        description: line.description,
        order: line.featuredOrder,
        icon: `/line-type/${line.icon}`,
      };
    });

    return sortBy(plans, ['order']);
  },

  showUpSell: (
    state,
    { companyAvailablePlans },
    { current_user },
    { insuranceEnabled }
  ) => {
    const { self_reported_insurance, profile } = current_user._company;

    const { epli } = self_reported_insurance;
    const { insuranceInterest } = profile;

    const lacksEPLI =
      companyAvailablePlans.some(
        (p) => p.lineType === 'employment-practices-liability-insurance'
      ) && epli !== true;

    return (
      state.showUpSell &&
      lacksEPLI &&
      insuranceInterest === 'unknown' &&
      insuranceEnabled
    );
  },

  plansRequestStatus: (state) => {
    return state.getPlansRequestStatus;
  },
};

export const mutations = {
  toggleShowUpSell(state, boolean) {
    state.showUpSell = boolean;
  },

  // Insurance Lines
  setInsuranceLines(state, lineIds) {
    lineIds.forEach((lineId) => {
      const line = insuranceLines[lineId];
      if (line) {
        Vue.set(state.insuranceLines, line.id, line);
      }
    });
  },

  // Carriers
  setCarriers(state, carriers) {
    carriers.forEach((c) => {
      Vue.set(state.carriers, c.id, c);
    });
  },

  // Plans
  setPlans(state, { companyId, plans = [] }) {
    const existingPlansIds = [];
    const draftIds = [];

    if (plans.length) {
      plans.forEach((plan) => {
        const currPlan = plan.borPlan || plan.currentPlan || plan.previousPlan;
        if (currPlan != null) {
          existingPlansIds.push(currPlan.id);

          Vue.set(state.plans, currPlan.id, formatPlan(currPlan));
        } else if (plan.draftPlan != null) {
          draftIds.push(plan.draftPlan.id);

          Vue.set(state.plans, plan.draftPlan.id, formatPlan(plan.draftPlan));
        }
      });
    }

    Vue.set(state.existingPlansByCompany, companyId, existingPlansIds);
    Vue.set(state.draftPlansByCompany, companyId, draftIds);
    state.getPlansRequestStatus = true;
  },

  // Plan CRUD
  putPlan(state, { plan }) {
    Vue.set(state.plans, plan.id, plan);
  },
  deletePlan(state, { companyId, planId, isDraft }) {
    const companyStorage = isDraft
      ? 'draftPlansByCompany'
      : 'existingPlansByCompany';

    const index = state[companyStorage][companyId].indexOf(planId);
    if (index > -1) {
      state[companyStorage][companyId].splice(index, 1);
    }

    Vue.delete(state.plans, planId);
  },
  patchPlan(state, { plan }) {
    const updateFields = ['carrier', 'policyNumber'];

    updateFields.forEach((field) => {
      if (Object.prototype.hasOwnProperty.call(plan, field)) {
        Vue.set(state.plans[plan.id], field, plan[field]);
      }
    });
  },

  // Agent Extension
  setPlanAgent(state, { planId, agent }) {
    Vue.set(state.agentByPlan, planId, agent);
  },

  // Files Extension
  setPlanFilesInBulk(state, { planId, files }) {
    Vue.set(state.filesByPlan, planId, files);
  },
  setPlanFile(state, { planId, file }) {
    state.filesByPlan[planId] = state.filesByPlan[planId] || [];
    state.filesByPlan[planId].push(file);
  },
  deletePlanFile(state, { planId, fileId }) {
    const index = state.filesByPlan[planId].findIndex((f) => f.id === fileId);
    if (index > -1) {
      Vue.delete(state.filesByPlan[planId], index);
    }
  },

  // Add Plan temp data
  setAddPlanData(state, data) {
    state.addPlanData = data;
  },

  // BOR Plan
  setBORPlan(state, plan) {
    state.signBORPlan.plan = plan;
  },

  setBORPreviewId(state, id) {
    state.signBORPlan.previewId = id;
  },
};

export const actions = {
  // Insurance Lines
  fetchInsuranceLines({ state, dispatch }) {
    // avoid getting the list from backend every time
    if (Object.keys(state.insuranceLines).length) {
      return Promise.resolve(state.insuranceLines);
    }

    return dispatch('insurance/getInsuranceLines');
  },
  getInsuranceLines({ commit, state }) {
    return this.$axios.get(`/v0/insurance/v1/lines`).then((res) => {
      commit('setInsuranceLines', res.data);

      return state.insuranceLines;
    });
  },

  // Carriers
  getCarrierById({ commit }, carrierId) {
    return this.$axios
      .get(`/v0/insurance/v1/carriers/carrier/${carrierId}`)
      .then((res) => {
        commit('setCarriers', [res.data]);
      });
  },
  searchCarriersByName({ commit }, name) {
    return this.$axios
      .post(`/v0/insurance/v1/carriers/search`, { searchTerms: name })
      .then((res) => {
        commit('setCarriers', res.data);

        return res.data;
      });
  },

  // Plans
  getPlans({ commit, rootGetters: { companyId } }) {
    return this.$axios
      .get(
        `/v0/companies/v1/company/${companyId}/insurance/plans/most-recently-effective-by-line-type`
      )
      .then((res) => {
        commit('setPlans', {
          companyId,
          plans: res.data,
        });
      });
  },

  // Plan CRUD
  createPlan({ commit, rootGetters: { companyId } }, plan) {
    const payload = createPlanPayload(plan);

    return this.$axios
      .post(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan`,
        payload
      )
      .then((res) => {
        commit('setPlans', {
          companyId,
          plans: res ? res.data : [],
        });
      });
  },
  updatePlan({ commit, rootGetters: { companyId } }, { plan }) {
    const payload = createPlanPayload(plan);

    return this.$axios
      .patch(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${plan.id}`,
        payload
      )
      .then(() => {
        commit('putPlan', { plan });
      });
  },
  deletePlan(
    { commit, rootGetters: { companyId } },
    { planId, isDraft = false }
  ) {
    return this.$axios
      .delete(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}`
      )
      .then(() => {
        commit('deletePlan', {
          companyId,
          planId,
          isDraft,
        });
      });
  },

  // Draft
  createDraftPlan({ commit, rootGetters: { companyId } }, { type }) {
    return this.$axios.post(
      `/v0/companies/v1/company/${companyId}/insurance/plans/draft`,
      {
        lineType: type,
      }
    );
  },
  updateDraft({ commit, rootGetters: { companyId } }, { draft }) {
    const payload = createPlanPayload(draft);

    return this.$axios
      .patch(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${draft.id}/draft-details`,
        payload
      )
      .then(() => {
        commit('patchPlan', { plan: draft });
      });
  },

  // Agent Extension
  getPlanAgent({ commit, rootGetters: { companyId } }, planId) {
    return this.$axios
      .get(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/agent`
      )
      .then((res) => {
        commit('setPlanAgent', {
          planId,
          agent: res.data,
        });
      });
  },
  updatePlanAgent({ commit, rootGetters: { companyId } }, { planId, agent }) {
    return this.$axios
      .put(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/agent`,
        agent
      )
      .then(() => {
        commit('setPlanAgent', {
          planId,
          agent,
        });
      });
  },

  // Files Extension
  getPlanFiles({ commit, rootGetters: { companyId } }, planId) {
    return this.$axios
      .get(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/files`
      )
      .then((res) => {
        commit('setPlanFilesInBulk', {
          planId,
          files: res.data,
        });
      });
  },
  addPlanFile({ commit, rootGetters: { companyId } }, { planId, file }) {
    return this.$axios
      .post(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/files`,
        file
      )
      .then((res) => {
        file.id = res.data.fileId;
        file.createdAt = res.data.createdAt;
        commit('setPlanFile', {
          planId,
          file,
        });
      });
  },
  deletePlanFile({ commit, rootGetters: { companyId } }, { planId, fileId }) {
    return this.$axios
      .delete(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/files/file/${fileId}`
      )
      .then(() => {
        commit('deletePlanFile', {
          planId,
          fileId,
        });
      });
  },

  // BOR Plan
  createPlanFromBOR({ commit, rootGetters: { companyId } }, { plan }) {
    const payload = createPlanPayload(plan);

    return this.$axios
      .post(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/from-bor`,
        payload
      )
      .then((res) => {
        return res.data;
      });
  },
  bulkTransferToBOR({ commit, rootGetters: { companyId } }, { planIds = [] }) {
    return this.$axios
      .post(
        `/v0/companies/v1/company/${companyId}/insurance/plans/bulk-transfer`,
        { planIds }
      )
      .then((res) => {
        return res.data.map((p) => p.id);
      });
  },
  transferPlanToBOR({ commit, rootGetters: { companyId } }, { planId }) {
    return this.$axios
      .post(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/transfer`,
        null
      )
      .then((res) => res.data);
  },

  getBORPlanStatus({ commit, rootGetters: { companyId } }, { planId }) {
    return this.$axios
      .get(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/bor-letter/status`
      )
      .then((res) => {
        return res.data;
      });
  },
  getBORPlan({ commit, rootGetters: { companyId } }, { planId }) {
    commit('setBORPlan', null);

    return this.$axios
      .get(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}`
      )
      .then((res) => {
        const plan = res.data;
        commit('setBORPlan', plan);

        return plan;
      });
  },
  getBORSigningSession({ commit, rootGetters: { companyId } }, { planId }) {
    this.$axios
      .get(
        `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/bor-letter/signing-session`
      )
      .then((res) => {
        const session = res.data;

        commit('setBORPreviewId', session.sessionId);

        return session;
      });
  },
  bulkMarkBORSigned({ commit, rootGetters: { companyId } }, { planIds }) {
    return this.$axios.post(
      `/v0/companies/v1/company/${companyId}/insurance/plans/bulk-sign`,
      { planIds }
    );
  },
  markBORSigned({ commit, rootGetters: { companyId } }, { planId }) {
    return this.$axios.post(
      `/v0/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/bor-letter/sign`,
      null
    );
  },
};
