

































































































import { defineComponent, computed } from '@nuxtjs/composition-api';
import {
  TypeDisplay,
  TypeBody,
  TypeOverline,
} from '@bambeehr/pollen';
import DocX from './imgs/DocX';
import DocDone from './imgs/DocDone';
import RadialChart from '@/modules/Training/components/RadialChart';
import useEmployerTrainingDetails from './useEmployerTrainingDetail';

const getChartInfo = (notStarted, inProgress, completed) => ({
  backgroundColor: ['#F0BB02', '#00AB82', '#C2C2C2'],
  data: [inProgress, completed, notStarted],
});

export default defineComponent({
  components: {
    TypeDisplay,
    TypeBody,
    TypeOverline,
    DocX,
    DocDone,
    RadialChart,
  },
  setup() {
    const { currentContent, currentUserList, contentCounts } =
      useEmployerTrainingDetails();

    const chartInfo = computed(() => {
      if (!currentContent.value.counts) {
        return {};
      }

      // const { notStarted, inProgress, completed } = currentContent.value.counts;

      return getChartInfo(
        contentCounts.value.notStarted,
        contentCounts.value.started,
        contentCounts.value.completed
      );
    });

    const totalCount = computed(() => currentUserList.value.length);

    return {
      currentContent,
      currentUserList,
      chartInfo,
      totalCount,
      contentCounts,
    };
  },
});
