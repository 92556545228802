// downloads provided string content as given filename/mime type
// e.g. download a string as a .csv or .txt file
export const downloadStringToFile = (
  content: string,
  filename: string,
  mimeType: string
): void => {
  if (!content || !filename || !mimeType) {
    return;
  }
  const blob = new Blob([content], { type: mimeType });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
};

export default downloadStringToFile;
