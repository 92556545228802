// Service util to get the Bambee Plus status of a company
// Provides relevant onboarding statuses as well enrollment and eligibility

import { getBambeePlusInfo } from '@/gql/services/payroll.service';
import { GetBambeePlusInfoQuery, BambeePlusStatuses, GoalName } from '@/gql/generated';
import { VerificationStatus } from '@/modules/payroll/components/VerifyFeinForm/checkIsInvalid';

interface BambeePlusStatus {
  hasBambeePlus: boolean;
  isEligible: boolean;
  hasLegalAddress: boolean;
  hasAnsweredCurrentPayrollQustion: boolean;
  hasOnboardedEmployees: boolean;
  hasEmployeesOnPayroll: boolean;
  hasCompletedFeinVerification: boolean;
  hasSignatoryInfo: boolean;
  hasCompletedBambeePlusOnboarding: boolean;
  bambeePlusTaskId: string | undefined;
}

let hasFetched;

let hasBambeePlus;
let isEligible;

let hasLegalAddress;
let hasAnsweredCurrentPayrollQustion;
let hasOnboardedEmployees;
let hasEmployeesOnPayroll;
let hasCompletedFeinVerification;
let hasSignatoryInfo;
let hasCompletedBambeePlusOnboarding;
let bambeePlusTaskId;

const getBambeePlusStatus = async (
  companyId: string,
  force: boolean = false
): Promise<BambeePlusStatus> =>
  new Promise((resolve) => {
    if (!hasFetched || force) {
      getBambeePlusInfo({ companyId }).then(({ data }) => {
        if (data) {
          const {
            payrollCompany,
            coreCompany,
            getBambeePlusInfo: bambeePlus,
            getMyCompanyGoals: goals,
          } = data as GetBambeePlusInfoQuery;

          hasBambeePlus =
            bambeePlus?.status === BambeePlusStatuses.Active || false;
          isEligible = bambeePlus?.eligible || false;

          // If any part of the legal address exists, they've completed the legal address step
          hasLegalAddress = !!coreCompany?.legalAddress?.line1 || false;
          // Check if they've answered the "how are you currently using payroll" question
          hasAnsweredCurrentPayrollQustion = !!payrollCompany?.pastPayrollUsage;
          // Check if they have any core employees (1 ee is the admin)
          hasOnboardedEmployees = coreCompany.employees.length > 1;
          // Check if any of the employees have checkIds (which means they're on payroll)
          hasEmployeesOnPayroll = !!coreCompany?.employees.some(
            (e) => !!e?.employee?.checkId
          );
          // Check if they've completed the FEIN verification step (verified or final failed)
          hasCompletedFeinVerification = [
            VerificationStatus.VERIFIED,
            VerificationStatus.FINAL_REJECTED,
          ].includes(
            payrollCompany?.feinVerification?.status as VerificationStatus
          );
          // Check if they've completed the signatory information step
          hasSignatoryInfo = !!payrollCompany?.signatoryEmail;

          // Check if Bambee Plus Setup goal is completed.
          const bambeePlusGoal = goals.find(
            (g) => g.name === GoalName.BambeePlusSetup
          );
          // There's only one task in this goal.
          // To leverage existing business logic, we'll complete the single task inside this goal.
          // That will mark the goal as completed.
          bambeePlusTaskId = bambeePlusGoal?.tasks[0]?.id;
          hasCompletedBambeePlusOnboarding = !!bambeePlusGoal?.completedAt;

          hasFetched = true;
        } else {
          hasBambeePlus = false;
          isEligible = false;
        }

        resolve({
          hasBambeePlus,
          isEligible,
          hasLegalAddress,
          hasAnsweredCurrentPayrollQustion,
          hasOnboardedEmployees,
          hasEmployeesOnPayroll,
          hasCompletedFeinVerification,
          hasSignatoryInfo,
          hasCompletedBambeePlusOnboarding,
          bambeePlusTaskId,
        });
      });
    } else {
      resolve({
        hasBambeePlus,
        isEligible,
        hasLegalAddress,
        hasAnsweredCurrentPayrollQustion,
        hasOnboardedEmployees,
        hasEmployeesOnPayroll,
        hasCompletedFeinVerification,
        hasSignatoryInfo,
        hasCompletedBambeePlusOnboarding,
        bambeePlusTaskId,
      });
    }
  });

export default getBambeePlusStatus;
