/* eslint-disable @typescript-eslint/no-shadow */
import { dateForServer } from '@/lib/normalization';
import Vue from 'vue';

function mapUserForStaff(user) {
  return {
    _id: user._id,
    manager: user.permissions.manager,
    approver: user.permissions.approver,
    avatar_url: user.profile.avatar_url || null,
    name: user.profile.full_name,
    firstName: user.profile.first_name,
    lastName: user.profile.last_name,
    email: user._auth?.email || '',
    phone: user.profile.phone || '',
    role: user.role || null,
    title: user.profile.role || user.role,
    contractor: user.profile.contractor,
    payRate: user.profile.pay_rate,
    resignation: user._resignation,
    termination: user._termination,
    states: user.states,
    stateWorkIn: user.profile.state_work_in,
    activation_status: user._auth ? user._auth.activation_status : null,
    active: user.active,
    _resignation: user._resignation || null,
    _termination: user._termination || null,
    documents: user._documents,
    created_at: user.created_at,
  };
}

function normalizeUser(user) {
  // Need code below so that full_name gets updated, or else (first_name/last_name) won't be changed
  delete user.profile.full_name;
  // normalize dates
  user.profile.dob = user.profile.dob ? dateForServer(user.profile.dob) : '';
  user.profile.start_date = user.profile.start_date
    ? dateForServer(user.profile.start_date)
    : '';

  return user;
}

function notifyBillingServiceUpdateEmployeeCount(axiosClient, companyId) {
  return axiosClient.post(
    `/billing/billing/v2/employee-count-update/${companyId}`
  );
}

const users = {
  state: () => ({
    users: [],
    staff: [],
    current_employee: null,
    _byId: {},
  }),
  mutations: {
    setUsers(state, { users }) {
      users.forEach((user) => {
        const index = state.users.findIndex((u) => u._id === user._id);

        if (index === -1) {
          return state.users.push(user);
        }

        return Vue.set(state.users, index, user);
      });

      state._byId = state.users.reduce((byId, u) => {
        byId[u._id] = u;

        return byId;
      }, {});

      users.forEach((user) => {
        const mappedUser = mapUserForStaff(user);

        const staffIndex = state.staff.findIndex((s) => s._id === user._id);

        if (staffIndex === -1) {
          return state.staff.push(mappedUser);
        }

        return Vue.set(state.staff, staffIndex, mappedUser);
      });
    },

    setStaff(state, { users }) {
      users.forEach((user) => {
        const index = state.staff.findIndex((s) => s._id === user._id);

        if (index === -1) {
          return state.staff.push(user);
        }

        return Vue.set(state.staff, index, user);
      });

      state._byId = state.staff.reduce((byId, s) => {
        byId[s._id] = s;

        return byId;
      }, {});
    },
    /* eslint-disable */
    setUser(state, { user }) {
      if (user.active) {
        const index = state.users.findIndex((u) => u._id === user._id);

        if (state.current_employee) {
          if (state.current_employee._id === user._id) {
            state.current_employee = user;
          }
        } else {
          state.current_employee = user;
        }

        if (index === -1) {
          state.users.push(user);
        } else {
          Vue.set(state.users, index, user);
        }

        const mappedUser = mapUserForStaff(user);

        const staffIndex = state.staff.findIndex((s) => s._id === user._id);
        if (staffIndex === -1) {
          return state.staff.push(mappedUser);
        }

        Vue.set(state.staff, staffIndex, mappedUser);
      }
    },
    setEmployee(state, employee) {
      state.current_employee = employee;
    },

    userSearch(state, users) {
      state.users = users;
    },
    removeUser(state, { user }) {
      const index = state.users.findIndex((u) => u.id === user._id);

      if (index !== -1) {
        state.users.splice(index, 1);
        Vue.set(state.users);
      }
    },
  },
  getters: {
    employee: (state) => (employee_id) => {
      return state._byId[employee_id];
    },
  },
  actions: {
    getEmployee({ commit }, employee_id) {
      return this.$axios.get(`/v0/app/user/${employee_id}`).then((res) => {
        commit('setEmployee', res.data);

        return res.data;
      });
    },

    /**
     * Try to use getSimplifiedMyEmployees() instead. Use this only if you need information like 'hourly' vs 'salary'
     * The returned user objects have the following properties
     * ["__v", "_auth", "_company", "_documents", "_id", "_manager", "_resignation", "_team_members", "_termination", "active", "created_at", "directReportCount", "id", "overtimePay", "permissions", "profile", "role", "settings", "states"]
     */
    getMyEmployees({ commit }, query) {
      return this.$axios
        .get(`/v0/app/my/employees`, {
          params: query,
        })
        .then((res) => {
          commit('setUsers', { users: res.data });

          return res.data;
        });
    },

    /**
     * Like getMyEmployees(), this will return a list of employees. However, this will not have the same properties.
     * The original intent was to have a simplified response for the Staff List Page
     * The returned user objects have the following properties
     * ["_id", "_resignation", "_termination", "activation_status", "active", "approver", "avatar_url", "contractor", "documents", "firstName", "lastName", "manager", "name", "onboarded", "payRate", "resignation", "role", "stateWorkIn", "states", "termination", "title"]
     */
    getSimplifiedMyEmployees({ commit, state }, force = false) {
      if (state?.staff.length && !force) {
        return Promise.resolve(state.staff);
      }

      return this.$axios.get(`/v0/app/my/employees/simplified`).then((res) => {
        commit('setStaff', { users: res.data });

        return res.data;
      });
    },

    triggerMultipleEmployeesOnboard({ dispatch }, employeeIds) {
      return this.$axios
        .post(`/v0/app/user/start-onboard`, employeeIds)
        .then(() => {
          employeeIds.forEach((employeeId) => {
            dispatch('sendWelcomeEmail', { user_id: employeeId });
          });
        });
    },

    triggerEmployeeOnboard({ commit, dispatch }, employee_id) {
      return this.$axios
        .post(`/v0/app/user/${employee_id}/start-onboard`)
        .then((res) => {
          const { user, ticket } = res.data;

          commit('setUser', { user });
          commit('tickets/set', ticket, { root: true });
          dispatch('refreshSelf', null, { root: true });
          dispatch('sendWelcomeEmail', { user_id: employee_id });

          return user;
        });
    },

    selectUser({ commit }, user) {
      commit('selectUser', user);
    },

    // Bulk Users
    bulkPreviewCreateUsers({ commit, rootGetters: { companyId } }, { users }) {
      return this.$axios
        .post(`/v0/companies/v1/company/${companyId}/employees/preview`, users)
        .then((res) => {
          const { data } = res;
          return data;
        });
    },
    async bulkCreateUsers({ commit, rootGetters: { companyId } }, { users }) {
      const {
        data: { employees: employeesAdded },
      } = await this.$axios.post(
        `/v0/companies/v1/company/${companyId}/employees`,
        users
      );
      if (employeesAdded.length > 0) {
        try {
          await notifyBillingServiceUpdateEmployeeCount(this.$axios, companyId);
        } catch (e) {
          window.DD_RUM?.addError(e);
        }
      }

      return employeesAdded;
    },

    async createUser({ rootGetters: { companyId } }, { user }) {
      user.startDate = dateForServer(user.startDate);

      const { data: userCreated } = await this.$axios.post(
        `/v0/companies/v1/company/${companyId}/employees/employee`,
        user
      );

      if (userCreated) {
        try {
          await notifyBillingServiceUpdateEmployeeCount(this.$axios, companyId);
        } catch (e) {
          window.DD_RUM?.addError(e);
        }
      }

      return userCreated;
    },

    async updateEmployeeCount(_, { companyId }) {
      const { data: updatedEmployeeCount } = await this.$axios.post(
        `/billing/billing/v2/employee-count-update/${companyId}`
      );

      return updatedEmployeeCount;
    },

    updateUser({ commit }, user) {
      normalizeUser(user);

      return this.$axios
        .post(`/v0/app/user/${user._id}/update`, { user })
        .then((res) => {
          commit('setUser', { user: res.data });

          return res.data;
        });
    },
    activateEmployee({ commit }, user) {
      normalizeUser(user);

      return this.$axios
        .post(`/v0/app/user/${user._id}/activate-employee`, { user })
        .then((res) => {
          commit('setUser', { user: res.data });

          return res.data;
        });
    },
    setAsApprover({ commit }, { user }) {
      this.$axios
        .post(`/v0/app/user/${user._id}/set-as-approver`)
        .then((res) => {
          commit('setUser', { user: res.data });
        });
    },
    unsetAsApprover({ commit }, { user }) {
      this.$axios
        .post(`/v0/app/user/${user._id}/unset-as-approver`)
        .then((res) => {
          commit('setUser', { user: res.data });
        });
    },
    setAsManager({ commit }, { user }) {
      return this.$axios
        .post(`/v0/app/user/${user._id}/set-as-manager`)
        .then((res) => {
          commit('setUser', { user: res.data });
        });
    },
    unsetAsManager({ commit }, { user }) {
      return this.$axios
        .post(`/v0/app/user/${user._id}/unset-as-manager`)
        .then((res) => {
          commit('setUser', { user: res.data });
        });
    },
    unAssignManager({ commit }, { user }) {
      return this.$axios
        .post(`/v0/app/user/${user._id}/unassign-manager`)
        .then((res) => {
          commit('setUser', { user: res.data });
        });
    },

    setTeamMembers({ commit }, { user_id, employees }) {
      return this.$axios
        .post(`/v0/app/user/${user_id}/assign-employees`, employees)
        .then((res) => {
          commit('setUser', { user: res.data });
          commit('setEmployee', res.data);
        });
    },
    assignManager({ commit }, { user_id, manager_id }) {
      return this.$axios
        .post(`/v0/app/user/${user_id}/assign-manager`, { manager_id })
        .then((res) => {
          commit('setUser', { user: res.data });
          commit('setEmployee', res.data);
        });
    },

    inactivate({ commit }, user_id) {
      return this.$axios
        .post(`/v0/app/user/${user_id}/inactivate`)
        .then((res) => {
          if (res.status === 200) {
            commit('removeUser', { user: res.data });
          }
        });
    },

    addCoreDocs({ commit }, { user_id, doc_type, files }) {
      return this.$axios
        .post(`/v0/app/user/${user_id}/add-core-docs`, {
          doc_type,
          files,
        })
        .then((res) => {
          const { user, cabinet } = res.data;

          commit('setUser', { user });
          commit('cabinet/set', cabinet, { root: true });
        });
    },

    sendWelcomeEmail({ commit }, { user_id }) {
      return this.$axios
        .post(`/v0/app/user/${user_id}/send-welcome-email`)
        .then((res) => {
          commit('setUser', { user: res.data });

          return res.data;
        })
        .catch((error) => {
          window.DD_RUM?.addError(error, {
            user_id: `${user_id}`,
          });
        });
    },

    resignEmployee({ commit }, { payload, employeeId }) {
      return this.$axios
        .post(`/v0/app/resignation/${employeeId}/create-resignation`, {
          payload,
        })
        .then((res) => {
          commit('setUser', { user: res.data });

          return res;
        });
    },

    resignEmployeeUpdate({ commit }, { payload, employeeId }) {
      return this.$axios
        .post(`/v0/app/resignation/${employeeId}/update`, { payload })
        .then((res) => {
          commit('setUser', { user: res.data });
        });
    },

    makeEmployeeAdmin(_, employeeId) {
      return this.$axios.patch(
        `/v0/employees/v1/employee/${employeeId}/make-admin`,
        null
      );
    },
  },
};

export default users;
