<template>
  <div><slot /></div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import useErrorHandler from '@/hooks/useErrorHandler';
import { surfaceErrorToUser } from '@/gql/apolloWrapper/apolloWrapper';

const { handle } = useErrorHandler();

// Catches any error thrown in a decendent component's vue lifecycle and bubbles it up to this component
// This is the place where we would standardize those errors
export default defineComponent({
  name: 'ErrorBoundary',
  errorCaptured(err) {
    if (!err) {
      return false;
    }

    handle(err);

    return false;
  },
});
</script>
