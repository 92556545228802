export const QnaTypes = Object.freeze({
  BASIC_AUDIT: 'BasicAudit',
  HR_AUDIT: 'Audit',
  COMPANY_OVERVIEW: 'CompanyOverview',
  INSURANCE_OVERVIEW: 'InsuranceOverview',
  PAYROLL_ELIGIBILITY: 'PayrollEligibility',
  WELCOME_AUDIT: 'WelcomeAudit',
});

export default {
  QnaTypes,
};
