





























import { computed, defineComponent, PropType } from '@vue/composition-api';
import { BaseBanner, BaseButton } from '@bambeehr/pollen';
import currency from '@bambeehr/currency';

export default defineComponent({
  components: {
    BaseBanner,
    BaseButton,
  },
  props: {
    deletedRole: {
      // TODO assign proper interface here when GQL is ready
      type: Object,
      required: true,
    },
    newRole: {
      // TODO assign proper interface here when GQL is ready
      type: Object,
      required: true,
    },
    hasMadePayRateSelection: {
      type: Boolean,
      default: false,
    },
    payRate: {
      type: Number,
      default: '',
    },
    workerName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const newRate = computed(() => currency(props.newRole.payRate).format());
    const oldRate = computed(() =>
      currency(props.deletedRole.payRate).format()
    );
    const formattedPayRate = computed(() => currency(props.payRate).format());

    return {
      newRate,
      oldRate,
      formattedPayRate,
    };
  },
});
