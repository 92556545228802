



































import { defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, TypeOverline } from '@bambeehr/pollen';

export default defineComponent({
  name: 'Calendar',
  components: {
    TypeBody,
    TypeOverline,
  },
  props: {
    day: {
      type: String,
      required: true,
    },
    dayNumber: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
});
