<template>
  <div class="bambee-card">
    <card-header
      v-if="head"
      :class="[{ 'has-only-head': !body && !foot }, header]"
    >
      <slot name="header" />
    </card-header>
    <div v-if="body" class="body" :class="{ 'has-no-footer': !foot }">
      <slot />
    </div>
    <card-footer v-if="foot" :class="footer">
      <slot name="footer" />
    </card-footer>
  </div>
</template>

<script>
import cardHeader from './card-header.vue';
import cardFooter from './card-footer.vue';

export default {
  name: 'Card',

  components: {
    cardHeader,
    cardFooter,
  },

  props: {
    header: {
      type: String,
      default: '',
    },
    footer: {
      type: String,
      default: '',
    },
  },

  computed: {
    head() {
      return this.$slots.header;
    },
    body() {
      return this.$slots.default;
    },
    foot() {
      return this.$slots.footer;
    },
  },
};
</script>

<style lang="scss" scoped>
//@import '../../../styles/app/shared.scss';
@import '~assets/css/shared.scss';

$bambee-card-border: 1px solid;
$bambee-card-border-color: $white-grey-4;
$bambee-card-border-radius: 5px;
$bambee-card-background-color: $white;
$bambee-card-box-shadow-raised: 0 0 10px 0 rgba(0, 0, 0, 0.05);
$bambee-card-width: remy(848);

$body-background-color: $white;
$body-padding: 1.5rem;

$head-foot-tiny: remy(44);
$head-foot-small: remy(56);
$head-foot-large: remy(108);
$head-foot-auto: auto;

$colors: (
  'white': (
    $white,
    $black,
  ),
  'black': (
    $black,
    $white,
  ),
  'light': (
    $light,
    $light-invert,
  ),
  'dark': (
    $dark,
    $dark-invert,
  ),
  'primary': (
    $primary,
    $primary-invert,
  ),
  'link': (
    $link,
    $link-invert,
  ),
  'info': (
    $info,
    $info-invert,
  ),
  'success': (
    $success,
    $success-invert,
  ),
  'warning': (
    $warning,
    $white,
  ),
  'danger': (
    $danger,
    $danger-invert,
  ),
  'default': (
    $white-grey-1,
    $black,
  ),
);

$head-foot-heights: (
  'tiny': (
    $head-foot-tiny,
  ),
  'small': (
    $head-foot-small,
  ),
  'large': (
    $head-foot-large,
  ),
  'auto': (
    $head-foot-auto,
  ),
);

.bambee-card {
  background-color: $bambee-card-background-color;
  border: $bambee-card-border;
  border-color: $bambee-card-border-color;
  border-radius: $bambee-card-border-radius;
  //default elevation is "raised" with 1dp box-shadow
  box-shadow: $bambee-card-box-shadow-raised;
  display: block;
  width: 100%;

  &.is-flat {
    box-shadow: none;
  }

  &.is-elevated {
    @include shadow-8dp();
  }

  &.is-singleCard {
    .header {
      border-bottom: 0;
    }

    .footer {
      border-top: 0;
    }
  }
}

.body {
  background-color: $body-background-color;
  flex: 1 1;
  min-height: remy(54);
  padding: $body-padding;
}

.side-bar {
  flex: 0 0;
}

//BAMBEE CARD - Head/Foot Modification

.bambee-card {
  @each $name, $size in $head-foot-heights {
    $height: $size;
    &.head-is-#{$name} {
      .header {
        height: $height;
      }
    }
    &.foot-is-#{$name} {
      .footer {
        height: $height;
      }
    }
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.head-is-#{$name} {
      .header {
        background-color: $color;
        color: $color-invert;
      }
    }
    &.foot-is-#{$name} {
      .footer {
        background-color: $color;
        color: $color-invert;
      }
    }
  }

  &.is-inside-card {
    @each $name, $size in $head-foot-heights {
      $height: $size;
      &.head-is-#{$name} {
        .header {
          height: $height;
        }
      }
      &.foot-is-#{$name} {
        .footer {
          height: $height;
        }
      }
    }
    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      $color-invert: nth($pair, 2);
      &.head-is-#{$name} {
        .header {
          background-color: $color;
          color: $color-invert;
        }
      }
      &.foot-is-#{$name} {
        .footer {
          background-color: $color;
          color: $color-invert;
        }
      }
    }
  }

  &.head-is-success {
    border: 0;
  }
}

//BAMBEE CARD FLUSH FOR HEAD/BODY/FOOT

.bambee-card.head-is-flush .header,
.bambee-card.body-is-flush .body,
.bambee-card.foot-is-flush .footer {
  padding: 0;
}

.bambee-card.all-flush {
  .header,
  .body,
  .footer {
    padding: 0;
  }
}

.bambee-card:last-child {
  margin-bottom: 1rem;
}

//BAMBEE CARD TYPE(S)

.bambee-card.is-collapsed {
  .body,
  .footer {
    display: none;
  }

  .header {
    background-color: $white;
    height: remy(108);
  }
}

.bambee-card.head-is-cut {
  .header {
    display: none;
  }
}

.bambee-card .body.has-no-footer {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media print {
  .bambee-card.is-printable {
    border-radius: 0;
    border-width: 0;
    box-shadow: none;
    max-width: 100%;

    .header {
      background-color: white;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
</style>
