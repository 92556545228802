import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import useStaffMessaging from '@/modules/StaffManagement/hooks/useStaffMessaging';
import useStaffSelection from '@/modules/StaffManagement/hooks/useStaffSelection';
import {
  BulkMessageScopes,
  BulkMessageScopesConfig,
  StaffStates
} from '@/modules/StaffManagement/models';
import {
  getNeverLoggedInStaffIds,
  getNotInvitedStaffIds,
  getNotSignedStaffIds
} from '@/utils/getStaffIds';
import useNotifications from '@bambeehr/use-notifications';
import { computed, ComputedRef, ref, useStore } from '@nuxtjs/composition-api';
import useStaffComplianceStatusMessage from './useStaffComplianceStatusMessage';

interface ComplianceSection {
  title: string;
  actions: {
    disabled: boolean;
    flat: boolean;
    label: string;
    class: string;
    preIcon: string;
    variant: string;
    handler: () => void;
  }[];
}

const useStaffComplianceStatusCard = (allStaff) => {
  const { startSendMessage } = useStaffMessaging();
  const { reloadEmployees } = useStaffManagement();
  const { selectedStaffIds, selectStaffGroup, clearStaffSelections } =
    useStaffSelection();
  const { addSuccess } = useNotifications();
  const store = useStore();

  const isAllStaffLoading = computed(() => !allStaff.value?.length);

  const isInviteNowButtonDisabled = computed(() =>
    allStaff.value
      .filter((staff) => staff.staffState === StaffStates.NOT_INVITED)
      .every((staff) => staff.onboardInviteSent)
  );

  const allStaffHaveSignedDocuemnts = computed(() =>
    allStaff?.value?.every((user) => {
      const docs = user.documents || [];

      return docs.every((doc) => doc.signed);
    })
  );

  const notInvitedStaffIds = computed(() =>
    getNotInvitedStaffIds(allStaff.value)
  );

  const neverLoggedInStaffIds = computed(() =>
    getNeverLoggedInStaffIds(allStaff.value)
  );

  const notSignedStaffIds = computed(() =>
    getNotSignedStaffIds(allStaff.value)
  );

  // get the staff count for each section
  const complianceStaffCount = computed(() => ({
    needInviteCount: notInvitedStaffIds.value.length,
    neverLoggedInCount: neverLoggedInStaffIds.value.length,
    signPolicyCount: notSignedStaffIds.value.length,
  }));

  const isComplianceStepComplete = computed(() => ({
    step1Complete: complianceStaffCount.value.needInviteCount === 0,
    step2Complete: complianceStaffCount.value.neverLoggedInCount === 0,
    step3Complete: complianceStaffCount.value.signPolicyCount === 0,
    allComplete:
      // no staff need an invite
      complianceStaffCount.value.needInviteCount === 0 &&
      // all staff are active
      complianceStaffCount.value.neverLoggedInCount === 0 &&
      // all staff have signed their documents
      allStaffHaveSignedDocuemnts.value,
  }));

  const complianceDetails = computed(() => [
    {
      step: 1,
      complete: !!isComplianceStepComplete.value.step1Complete,
      staffCount: complianceStaffCount.value.needInviteCount,
    },
    {
      step: 2,
      complete: !!isComplianceStepComplete.value.step2Complete,
      staffCount: complianceStaffCount.value.neverLoggedInCount,
    },
    {
      step: 3,
      complete: !!isComplianceStepComplete.value.step3Complete,
      staffCount: complianceStaffCount.value.signPolicyCount,
    },
  ]);

  const { step1Content, step2Content, step3Content } =
    useStaffComplianceStatusMessage(complianceDetails);

  const bulkOnboard = async () => {
    selectStaffGroup(StaffStates.NOT_INVITED);
    const calls = selectedStaffIds.value.map((staffId) =>
      store.dispatch('users/triggerEmployeeOnboard', staffId)
    );

    Promise.all(calls)
      .catch(() => {})
      .finally(() => {
        addSuccess('Onboarding Requested');
        clearStaffSelections();
        reloadEmployees();
      });
  };

  const complianceSectionOne = computed(() => ({
    title: step1Content.value.message,
    actions: [
      {
        disabled:
          isComplianceStepComplete.value.step1Complete ||
          isInviteNowButtonDisabled.value,
        flat: isComplianceStepComplete.value.step1Complete,
        label: step1Content.value.ctaText,
        class: 'text-14',
        preIcon: step1Content.value.ctaIcon,
        variant: isComplianceStepComplete.value.step1Complete
          ? 'secondary'
          : 'inverted',
        handler: () => {
          bulkOnboard();
        },
      },
    ],
  }));

  const complianceSectionTwo = computed(() => ({
    title: step2Content.value.message,
    actions: [
      {
        disabled: isComplianceStepComplete.value.step2Complete,
        flat: isComplianceStepComplete.value.step2Complete,
        label: step2Content.value.ctaText,
        class: 'text-14',
        preIcon: step2Content.value.ctaIcon,
        variant: isComplianceStepComplete.value.step2Complete
          ? 'secondary'
          : 'inverted',
        handler: () => {
          startSendMessage(
            neverLoggedInStaffIds.value,
            BulkMessageScopesConfig[BulkMessageScopes.NEVER_LOGGED_IN]
              .messageTitleDecorator,
            BulkMessageScopesConfig[BulkMessageScopes.NEVER_LOGGED_IN]
              .messageGroupType
          );
        },
      },
    ],
  }));

  const complianceSectionThree = computed(() => ({
    title: step3Content.value.message,
    actions: [
      {
        disabled: false,
        flat: false,
        label: step3Content.value.ctaText,
        class: 'text-14',
        variant: isComplianceStepComplete.value.step3Complete
          ? 'secondary'
          : 'inverted',
        preIcon: step3Content.value.ctaIcon,
        handler: () => {
          startSendMessage(
            notSignedStaffIds.value,
            BulkMessageScopesConfig[BulkMessageScopes.HAVENT_SIGNED]
              .messageTitleDecorator,
            BulkMessageScopesConfig[BulkMessageScopes.HAVENT_SIGNED]
              .messageGroupType
          );
        },
      },
    ],
  }));

  const complianceSteps: ComputedRef<ComplianceSection[]> = computed(() => {
    const complianceSectionsToShow = ref<ComplianceSection[]>([]);

    if (notInvitedStaffIds.value.length) {
      complianceSectionsToShow.value = [complianceSectionOne.value];
    }

    if (neverLoggedInStaffIds.value.length) {
      complianceSectionsToShow.value = [
        complianceSectionOne.value,
        complianceSectionTwo.value,
      ];
    }

    if (notSignedStaffIds.value.length) {
      complianceSectionsToShow.value = [
        complianceSectionOne.value,
        complianceSectionTwo.value,
        complianceSectionThree.value,
      ];
    }

    return complianceSectionsToShow.value;
  });

  const isAllComplete = computed(
    () => isComplianceStepComplete.value.allComplete
  );

  return {
    complianceSteps,
    isAllStaffLoading,
    isAllComplete,
  };
};

export default useStaffComplianceStatusCard;
