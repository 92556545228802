/* eslint-disable no-underscore-dangle */
import launchDarklyPlugin from '@bambeehr/vue-launch-darkly';
import Vue from 'vue';

const toLDUser = (user = {}, currentPlan = {}) => {
  // If the user is undefined at runtime, we'll report an anonymous user instead
  const ldUser = {};

  if (!user?._id) {
    return {
      ...ldUser,
      anonymous: true,
    };
  }

  // Identify the user by setting the key to their user id
  ldUser.key = user._id;

  // Set user auth attributes
  ldUser.email = user._auth?.email;

  // Set user profile attributes
  ldUser.name = user.profile?.full_name;
  ldUser.avatar = user.profile?.avatarUrl;

  // If company is set, add it to the custom LaunchDarkly attributes
  // https://docs.launchdarkly.com/home/users/custom-attributes#creating-custom-user-attributes
  const { _company: company } = user;
  if (company?._id) {
    ldUser.custom = {};

    // Set high level company attributes
    ldUser.custom.companyId = company._id;
    ldUser.custom.companyCreatedAt = company.created_at;
    ldUser.custom.companyConvertedAt = company.converted_at;
    ldUser.custom.companyIsTest = company.test;

    // Set settings attributes
    ldUser.custom.primaryAdvisorId = company.settings?.advisor;

    // Set Marketing attributes
    const utm = company.marketing?.query_params || {};
    ldUser.custom.utmSource = utm.utm_source;
    ldUser.custom.utmMedium = utm.utm_medium;
    ldUser.custom.utmCampaign = utm.utm_campaign;
    ldUser.custom.utmContent = utm.utm_content;

    // set plan attributes
    if (currentPlan) {
      ldUser.custom.tierNumber = currentPlan.tierNumber;
    }
  }

  return ldUser;
};

export default ({ store }) => {
  try {
    const onReady = () => {
      store.subscribe((mutation) => {
        // If the user or company is updated, update the LaunchDarkly user
        if (
          [
            'setCurrentCompany',
            'setCurrentUser',
            'pricePlans/setPricePlans',
          ].includes(mutation.type)
        ) {
          const planId = store.getters?.currentUser?._company?.plan;
          const pricePlans = store.getters['pricePlans/all'];

          const currentPlan =
            pricePlans.find(({ value }) => value === planId) || {};

          const updatedLDUser = toLDUser(
            store.getters.currentUser,
            currentPlan
          );
          launchDarklyPlugin.identify(updatedLDUser);
        }
      });
    };

    const ldUser = toLDUser(store.getters.currentUser);
    Vue.use(launchDarklyPlugin, {
      envKey: process.env.LAUNCH_DARKLY_CLIENT_ID,
      user: ldUser,
      subscribeAllFeatureFlags: true,
      onReady,
    });
  } catch (err) {
    console.error('Error installing Launch Darkly plugin', err);
  }
};
