<template>
  <div>
    <div class="w-full p-4 pb-6 border bg-base-200 border-base-300 sm:rounded">
      <div class="flex flex-col items-center justify-center w-full">
        <TypeOverline
          variant="x-small-tight"
          tag="div"
          class="text-center uppercase text-base-700"
        >
          your estimated cost per month for
        </TypeOverline>
        <div class="flex items-center justify-center my-2 align-middle">
          <IconButton
            class="minus-btn"
            icon="minus"
            variant="primary"
            size="small"
            :disabled="isMaxLow"
            data-qa="decrement"
            title="Decrease Employee Count"
            @click="decrementCount()"
          />
          <TypeDisplay
            variant="small"
            class="mx-3 count-num text-primary-default"
            weight="strong"
            tag="div"
          >
            {{ count }}
          </TypeDisplay>
          <IconButton
            class="plus-btn"
            icon="plus"
            variant="primary"
            size="small"
            :disabled="isMaxHigh"
            data-qa="increment"
            title="Increase Employee Count"
            @click="incrementCount()"
          />
        </div>
        <TypeOverline
          variant="x-small-tight"
          tag="div"
          class="uppercase text-base-700"
        >
          employees
        </TypeOverline>
      </div>
      <div class="flex flex-col mt-4">
        <div class="flex flex-row items-end py-2 row text-base-700">
          <div class="w-2/4">
            <TypeBody variant="link-tiny-tight" weight="strong" tag="div">
              Time Tracking
              <div>Tool</div>
            </TypeBody>
          </div>
          <div class="justify-center hidden w-1/4 sm:flex">
            <TypeBody
              variant="caption"
              weight="strong"
              tag="div"
              class="text-center"
            >
              Bambee Dedicated
              <div>HR Manager</div>
            </TypeBody>
          </div>
          <div class="w-1/2 text-right sm:w-1/4">
            <TypeBody variant="link-tiny-tight" weight="strong" tag="div">
              Price Per
              <div>Month</div>
            </TypeBody>
          </div>
        </div>
        <PriceToolRow
          variant="primary"
          :price="prices.bambee.formatted"
          title="Bambee Time and Attendance"
          :subtitle="`With ${advisorFirstName}`"
        >
          <BaseIcon icon="check" class="text-white type-display-mega" />
        </PriceToolRow>
        <PriceToolRow :price="prices.one.formatted" title="Clockify" />
        <PriceToolRow :price="prices.two.formatted" title="Toggl Track" />
        <PriceToolRow :price="prices.three.formatted" title="Quickbooks" />
        <!-- <PriceToolRow :price="prices.four.formatted" title="Timesheets" /> -->
      </div>
    </div>

    <div class="px-4 sm:px-0">
      <div
        class="flex w-full p-4 mt-6 border rounded price-total bg-primary-weak border-primary-weak"
      >
        <div class="flex flex-col w-1/2 pr-8">
          <TypeOverline
            variant="x-small-tight"
            tag="div"
            class="uppercase text-primary-light"
          >
            Total Savings Per Year
          </TypeOverline>
          <TypeDisplay
            class="mt-1 text-primary-default"
            variant="x-small"
            weight="strong"
            tag="div"
          >
            ${{ totalSavings.formatted }}
          </TypeDisplay>
        </div>
        <TypeBody
          variant="link-small"
          weight="strong"
          class="w-1/2 text-primary-default"
        >
          {{ savingsText }}
        </TypeBody>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@nuxtjs/composition-api';
import omit from 'lodash/omit';
import {
  TypeOverline,
  IconButton,
  TypeDisplay,
  BaseIcon,
  TypeBody,
} from '@bambeehr/pollen';
import PriceToolRow from '@/components/get-payroll/PriceTool/PriceToolRow.vue';
import bam from '@/lib/bam';
import { currencyConvert } from '@/utils/currency';

const priceMap = {
  // Clockify
  ONE: {
    EE: 10,
    EIN: 0,
  },
  // Toggl
  TWO: {
    EE: 9,
    EIN: 0,
  },
  // Quickbooks
  THREE: {
    EE: 8,
    EIN: 0,
  },
  // // Timesheets
  // FOUR: {
  //   EE: 5.5,
  //   EIN: 0,
  // },
  BAMBEE: {
    EE: 4,
    EIN: 19,
  },
};

export default {
  components: {
    TypeOverline,
    IconButton,
    TypeDisplay,
    PriceToolRow,
    BaseIcon,
    TypeBody,
  },
  props: {
    employeeCount: {
      type: Number,
      require: false,
      default: 1,
    },
    advisorFirstName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const count = ref(props.employeeCount);
    watch(
      () => props.employeeCount,
      (newValue) => {
        count.value = newValue;
      }
    );

    const isMaxLow = computed(() => count.value === 5);
    const isMaxHigh = computed(() => count.value === 99);

    const getPrice = (companyInfo) => {
      const eePrice =
        typeof companyInfo.EE === 'number'
          ? companyInfo.EE
          : companyInfo.EE.find(
              (i) => i.min <= count.value && i.max >= count.value
            )?.price;
      const totalPrice = companyInfo.EIN + count.value * eePrice;

      return {
        formatted: currencyConvert(totalPrice),
        raw: totalPrice,
      };
    };

    const prices = computed(() => {
      return {
        one: getPrice(priceMap.ONE),
        two: getPrice(priceMap.TWO),
        three: getPrice(priceMap.THREE),
        // four: getPrice(priceMap.FOUR),
        bambee: getPrice(priceMap.BAMBEE),
      };
    });

    const totalSavings = computed(() => {
      const priceList = Object.values(omit(prices.value, 'bambee'));
      const meanCompetition =
        priceList.reduce((a, c) => a + c.raw, 0) / priceList.length;
      // Monthly cost savings, by 12 months
      const savings = `${(meanCompetition - prices.value.bambee.raw) * 12}`;

      // The new currency convert will handle this for us (payroll branch) but this will take care of thousand separators for us
      return {
        formatted: new Intl.NumberFormat('en', {
          currency: 'USD',
        }).format(savings),
        raw: savings,
      };
    });

    const savingsText = computed(() => {
      // Divide by month by number of employees
      const total = totalSavings.value.raw;

      if (total <= 200) {
        return 'Treat your staff to a cup of coffee with the savings';
      }

      if (total <= 500) {
        return 'Take your staff out to lunch with the savings';
      }

      if (total <= 1000) {
        return 'Catch a movie with your team with the savings - popcorn and all';
      }

      if (total <= 1500) {
        return 'Cozy up with some team sweaters with the savings';
      }

      return 'Grab some matching track suits with the savings';
    });

    function incrementCount() {
      count.value += 1;
      emit('update-count', count.value);
      bam.track('employee-comparison-clicked', {
        type: 'increase',
        currentValue: count.value,
        newValue: count.value + 1,
      });
    }

    function decrementCount() {
      count.value -= 1;
      emit('update-count', count.value);
      bam.track('employee-comparison-clicked', {
        type: 'decrease',
        currentValue: count.value,
        newValue: count.value - 1,
      });
    }

    return {
      count,
      decrementCount,
      getPrice,
      incrementCount,
      isMaxHigh,
      isMaxLow,
      prices,
      savingsText,
      totalSavings,
    };
  },
};
</script>

<style scoped>
.price-total {
  min-height: 5.125rem;
}
</style>
