export const PayType = Object.freeze({
  CONTRACT: 'contractor',
  SALARY: 'salary',
  HOURLY: 'hourly',
});

export const PayTypeCaps = Object.freeze({
  CONTRACT: 'Contractor',
  SALARY: 'Salary',
  HOURLY: 'Hourly',
});

export const payTypes = [
  {
    value: PayType.HOURLY,
    name: 'Per Hour',
  },
  {
    value: PayType.SALARY,
    name: 'Salary',
  },
  {
    value: PayType.CONTRACT,
    name: 'Contract',
  },
];

export const payTypesCaps = [
  {
    value: PayTypeCaps.HOURLY,
    name: 'Per Hour',
  },
  {
    value: PayTypeCaps.SALARY,
    name: 'Salary',
  },
  {
    value: PayTypeCaps.CONTRACT,
    name: 'Contract',
  },
];

// Legacy forms just use the payTypes array above
export const getFilteredPayTypes = (isContractor, useCapsTypes = false) => {
  const list = useCapsTypes ? payTypesCaps : payTypes;
  const PayTypeEnum = useCapsTypes ? PayTypeCaps : PayType;

  return list.filter(
    ({ value }) =>
      isContractor || (!isContractor && value !== PayTypeEnum.CONTRACT)
  );
};

// Used for Pollen SelectInput options
export const getPayTypeOptions = (isContractor, useCapsTypes) =>
  getFilteredPayTypes(isContractor, useCapsTypes).map((item) => ({
    value: item.value,
    label: item.name,
  }));
