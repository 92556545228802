<template>
  <div>
    <BaseLink class="ledger-modal-btn" @click="toggleModal">
      <TypeBody tag="span" variant="caption" weight="strong">
        {{ detailLinkText }}
      </TypeBody>
    </BaseLink>

    <portal v-if="showModal" to="layout">
      <ModalDialog
        action-alignment="right"
        :actions="modalActions"
        overlay="dark"
        :overlay-close="true"
        size="medium"
        @close="toggleModal"
        @primary="toggleModal"
      >
        <template #header>
          {{ title }}
        </template>
        <slot />
        <EarningsTable
          :earnings="details.earnings"
          :total-label="details.totalLabel"
          :total="details.total"
          :gross="details.gross"
        />
      </ModalDialog>
    </portal>
  </div>
</template>

<script>
import { nextTick, ref } from '@nuxtjs/composition-api';
import { TypeBody, ModalDialog, BaseLink } from '@bambeehr/pollen';
import EarningsTable from '@/modules/payroll/components/EarningsTable/EarningsTable';

export default {
  name: 'EarningsModalTrigger',
  components: {
    TypeBody,
    ModalDialog,
    EarningsTable,
    BaseLink,
  },
  props: {
    /**
     * Details object contains:
     * @prop {{description: String, amount: Number}[]} earnings (required) Arr of earnings items
     * @prop {Number} total For total row in earnings table
     * @prop {String} totalLabel Label for total row in earnings table
     * @prop {String} title Modal title
     * */
    details: {
      type: Object,
      required: true,
      validator(val) {
        const requiredKeys = ['earnings', 'total', 'gross'];
        const valKeys = Object.keys(val);

        return requiredKeys.every((k) => valKeys.includes(k));
      },
    },
    detailLinkText: {
      type: String,
      required: false,
      default: 'Details',
    },
    title: {
      type: String,
      required: false,
      default: 'Pay Breakdown',
    },
  },
  setup() {
    const showModal = ref(false);
    const modalActions = { primary: { label: 'Close' } };

    function toggleModal() {
      showModal.value = !showModal.value;
      if (showModal.value) {
        nextTick(() => {
          // Hackish
          // This scroll functionality will break if
          // updates to Pollen Modal are made and the class used in
          // querySelector gets named something else
          const element = document.querySelector('.modal-header');
          element.scrollIntoView();
        });
      }
    }

    return {
      toggleModal,
      showModal,
      modalActions,
    };
  },
};
</script>
