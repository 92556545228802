






import { defineComponent } from '@nuxtjs/composition-api';
import PageTitle from '@/modules/Training/components/PageTitle';
import { trainingRouteGuard } from '@/modules/Training/middleware';

export default defineComponent({
  name: 'EmployeeTrainingRoot',
  components: {
    PageTitle,
  },
  middleware: [trainingRouteGuard],
});
