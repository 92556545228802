





























































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { TypeBody } from '@bambeehr/pollen';
import currency from '@bambeehr/currency';
import { formatDate } from '@/utils/date';
import EarningsTable from '@/modules/payroll/components/EarningsTable/EarningsTable.vue';
import {
  getCompanyEarningsSummary,
  getEmployeeEarningsSummary,
  getContractorEarningsSummary,
} from '@/modules/payroll/components/EarningsTable/earningsSummary';

export default defineComponent({
  name: 'PayrollOverviewCard',
  components: {
    EarningsTable,
    TypeBody,
  },
  props: {
    payroll: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const totalDebitedAmount = computed(() =>
      currency(props.payroll.totals.cashRequirement).format()
    );

    const payDate = computed(() => formatDate(props.payroll.payday, null));

    const companyEarningsSummary = computed(() =>
      getCompanyEarningsSummary(props.payroll)
    );

    const employeeEarningsSummary = computed(() =>
      getEmployeeEarningsSummary(props.payroll)
    );

    const contractorEarningsSummary = computed(() =>
      getContractorEarningsSummary(props.payroll)
    );

    return {
      companyEarningsSummary,
      employeeEarningsSummary,
      contractorEarningsSummary,
      totalDebitedAmount,
      payDate,
    };
  },
});
