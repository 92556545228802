import { asyncFactory, observableFactory } from '@/hooks/useGraphQlApi';
import {
  approvePayroll as approvePayrollService,
  bulkUploadTimeAttendance as bulkUploadTimeAttendanceService,
  createCompanyInCheck as createCompanyInCheckService,
  createPayroll as createPayrollService,
  createPaySchedule as createPayScheduleService,
  createWorkplace as createWorkplaceService,
  deletePayroll as deletePayrollService,
  generateCompanyOnboardingLink as generateCompanyOnboardingLinkService,
  generateContractorOnboardingLink as generateContractorOnboardingLinkService,
  generateEmployeeOnboardingLink as generateEmployeeOnboardingLinkService,
  getCompany as getCompanyService,
  getCompanyStaff as getCompanyStaffService,
  getCompanyStatus as getCompanyStatusService,
  getContractorTaxDocuments as getContractorTaxDocumentsService,
  getContractorWithPayments as getContractorWithPaymentsService,
  getDefaultPayScheduleDays as getDefaultPayScheduleDaysService,
  getEmployee as getEmployeeService,
  getEmployeeTaxDocuments as getEmployeeTaxDocumentsService,
  getEmployeeWithPayrollItems as getEmployeeWithPayrollItemsService,
  getPayroll as getPayrollService,
  initializePayroll as initializePayrollService,
  previewPayroll as previewPayrollService,
  sendStaffOnboardingInvite as sendStaffOnboardingInviteService,
  setupContractor as setupContractorService,
  setupEmployee as setupEmployeeService,
  updateChecks as updateChecksService,
  updateCompany as updateCompanyService,
  updateContractorPayment as updateContractorPaymentService,
  updateEmployeeWorkplace as updateEmployeeWorkplaceService,
  updateContractorWorkplace as updateContractorWorkplaceService,
  updatePayroll as updatePayrollService,
  updatePayrollItem as updatePayrollItemService,
  updateWorkplace as updateWorkplaceService,
  upgradeFinchCode as upgradeFinchCodeService,
  watchPayrollList as watchPayrollListService,
  watchTaxDocuments as watchTaxDocumentsService,
  watchWorkplaces as watchWorkplacesService,
} from '@/gql/services/payroll.service';

/**
 * Wraps the previewPayroll service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const previewPayroll = (id, placeholders) => {
  return asyncFactory(previewPayrollService, id, placeholders);
};

/**
 * Wraps the updateCompany service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const updateCompany = (id, placeholders) => {
  return asyncFactory(updateCompanyService, id, placeholders);
};

/**
 * Wraps the createPaySchedule service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const createPaySchedule = (payload, placeholders) => {
  return asyncFactory(createPayScheduleService, payload, placeholders);
};

/**
 * Wraps the createPayroll service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const createPayroll = (payload, placeholders) => {
  return asyncFactory(createPayrollService, payload, placeholders);
};

/**
 * Wraps the createCompanyInCheck service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const createCompanyInCheck = (payload, placeholders) => {
  return asyncFactory(createCompanyInCheckService, payload, placeholders);
};

/**
 * Wraps the sendStaffOnboardingInvite service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const sendStaffOnboardingInvite = (payload, placeholders) => {
  return asyncFactory(sendStaffOnboardingInviteService, payload, placeholders);
};

/**
 * Wraps the createPayroll service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const initializePayroll = (payrollId, placeholders) => {
  return asyncFactory(initializePayrollService, payrollId, placeholders);
};

/**
 * Wraps the updatePayrollItem service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const updatePayrollItem = (payload, placeholders) => {
  return asyncFactory(updatePayrollItemService, payload, placeholders);
};

/**
 * Wraps the updateContractorPayment service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const updateContractorPayment = (payload, placeholders) => {
  return asyncFactory(updateContractorPaymentService, payload, placeholders);
};

/**
 * Wraps the updateWorkplace service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const updateWorkplace = (payload, placeholders) => {
  return asyncFactory(updateWorkplaceService, payload, placeholders);
};

/**
 * Wraps the updateEmployeeWorkplace service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const updateEmployeeWorkplace = (payload, placeholders) => {
  return asyncFactory(updateEmployeeWorkplaceService, payload, placeholders);
};

/**
 * Wraps the updateEmployeeWorkplace service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const updateContractorWorkplace = (payload, placeholders) => {
  return asyncFactory(updateContractorWorkplaceService, payload, placeholders);
};

/**
 * Wraps the createWorkplace service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const createWorkplace = (payload, placeholders) => {
  return asyncFactory(createWorkplaceService, payload, placeholders);
};

/**
 * Wraps the updatePayroll service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const updatePayroll = (payload, placeholders) => {
  return asyncFactory(updatePayrollService, payload, placeholders);
};

/**
 * Wraps the updateChecks service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const updateChecks = (payload, placeholders) => {
  return asyncFactory(updateChecksService, payload, placeholders);
};

/**
 * Wraps the approvePayroll service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const approvePayroll = (payload, placeholders) => {
  return asyncFactory(approvePayrollService, payload, placeholders);
};

/**
 * Wraps the upgradeFinchCode service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const upgradeFinchCode = (payload, placeholders) => {
  return asyncFactory(upgradeFinchCodeService, payload, placeholders);
};

/**
 * Wraps the deletePayroll service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const deletePayroll = (payload, placeholders) => {
  return asyncFactory(deletePayrollService, payload, placeholders);
};

/**
 * Wraps the generateEmployeeOnboardingLink service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const generateEmployeeOnboardingLink = (payload, placeholders) => {
  return asyncFactory(
    generateEmployeeOnboardingLinkService,
    payload,
    placeholders
  );
};

/**
 * Wraps the generateContractorOnboardingLink service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const generateContractorOnboardingLink = (payload, placeholders) => {
  return asyncFactory(
    generateContractorOnboardingLinkService,
    payload,
    placeholders
  );
};

/**
 * Wraps the generateEmployeeOnboardingLink service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const generateCompanyOnboardingLink = (payload, placeholders) => {
  return asyncFactory(
    generateCompanyOnboardingLinkService,
    payload,
    placeholders
  );
};

/**
 * Wraps the setupEmployee service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const setupEmployee = (payload, placeholders) => {
  return asyncFactory(setupEmployeeService, payload, placeholders);
};

/**
 * Wraps the setupContractor service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const setupContractor = (payload, placeholders) => {
  return asyncFactory(setupContractorService, payload, placeholders);
};

/**
 * Wraps the getCompanyStatus service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getCompanyStatus = (payload, placeholders) => {
  return asyncFactory(getCompanyStatusService, payload, placeholders);
};

/**
 * Wraps the getCompany service method and returns reactive vue objects representing the service's response
 * @param {Number|String} companyId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getCompanyStaff = (companyId, placeholders) => {
  return observableFactory(getCompanyStaffService, companyId, placeholders);
};

/**
 * Wraps the getCompany service method and returns reactive vue objects representing the service's response
 * @param {Number|String} companyId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getCompany = (companyId, placeholders) => {
  return observableFactory(getCompanyService, companyId, placeholders);
};

/**
 * Wraps the getDefaultPayScheduleDays service method and returns reactive vue objects representing the service's response
 * @param {Number|String} companyId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getDefaultPayScheduleDays = (companyId, placeholders) => {
  return observableFactory(
    getDefaultPayScheduleDaysService,
    companyId,
    placeholders
  );
};

/**
 * Wraps the getEmployee service method and returns reactive vue objects representing the service's response
 * @param {Number|String} employeeId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getEmployee = (employeeId, placeholders) => {
  return observableFactory(getEmployeeService, employeeId, placeholders);
};

/**
 * Wraps the getEmployeeTaxDocuments service method and returns reactive vue objects representing the service's response
 * @param {Number|String} employeeId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getEmployeeTaxDocuments = (employeeId, placeholders) => {
  return observableFactory(
    getEmployeeTaxDocumentsService,
    employeeId,
    placeholders
  );
};

/**
 * Wraps the getEmployeeWithPayrollItems service method and returns reactive vue objects representing the service's response
 * @param {Number|String} employeeId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getEmployeeWithPayrollItems = (employeeId, placeholders) => {
  return observableFactory(
    getEmployeeWithPayrollItemsService,
    employeeId,
    placeholders
  );
};

/**
 * Wraps the getContractorTaxDocuments service method and returns reactive vue objects representing the service's response
 * @param {Number|String} employeeId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getContractorTaxDocuments = (contractorId, placeholders) => {
  return observableFactory(
    getContractorTaxDocumentsService,
    contractorId,
    placeholders
  );
};

/**
 * Wraps the getContractorWithPayments service method and returns reactive vue objects representing the service's response
 * @param {Number|String} contractorId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getContractorWithPayments = (contractorId, placeholders) => {
  return observableFactory(
    getContractorWithPaymentsService,
    contractorId,
    placeholders
  );
};

/**
 * Wraps the getPayroll service method and returns reactive vue objects representing the service's response
 * @param {Number|String} payrollId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const getPayroll = (payrollId, placeholders) => {
  return observableFactory(getPayrollService, payrollId, placeholders);
};

/**
 * Wraps the listWorkplace service method and returns reactive vue objects representing the service's response
 * @param {Number|String} payrollId The requested payroll's ID
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const listWorkplace = (companyId, placeholders) => {
  return observableFactory(watchWorkplacesService, companyId, placeholders);
};

/**
 * Wraps the watchPayrollList service method and returns reactive vue objects representing the service's response
 * @param {Number|String} companyId The company's ID for the requested list of payrolls
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const listPayroll = (companyId, placeholders) => {
  return observableFactory(
    watchPayrollListService,
    { companyId },
    placeholders
  );
};

/**
 * Wraps the watchTaxDocuments service method and returns reactive vue objects representing the service's response
 * @param {Object} payload Company ID along with filter options
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const listTaxDocuments = (payload, placeholders) => {
  return observableFactory(watchTaxDocumentsService, payload, placeholders);
};

/**
 * Wraps the bulkUploadTimeAttendance service method and returns reactive vue objects representing the service's response
 * @param {any} payload Data to forward to the payroll service method
 * @param {Object} placeholders Object with optional keys 'data' and 'error'. Each value is a vue reactive object to mutate when the service method has resolved. Useful for pre-instantiating reactive objects to use a template that will react to service methods. Note, this is an uncommon scenario typically reserved for avoiding additional watchers when nesting service calls.
 * @returns {Object} {data, error, pending} object where the values are all vue reactive objects
 */
const bulkUploadTimeAttendance = (payload, placeholders) => {
  return asyncFactory(bulkUploadTimeAttendanceService, payload, placeholders);
};

const usePayrollService = () => ({
  approvePayroll,
  bulkUploadTimeAttendance,
  createCompanyInCheck,
  createPayroll,
  createPaySchedule,
  createWorkplace,
  deletePayroll,
  generateCompanyOnboardingLink,
  generateContractorOnboardingLink,
  generateEmployeeOnboardingLink,
  getCompany,
  getCompanyStaff,
  getCompanyStatus,
  getContractorTaxDocuments,
  getContractorWithPayments,
  getDefaultPayScheduleDays,
  getEmployee,
  getEmployeeTaxDocuments,
  getEmployeeWithPayrollItems,
  getPayroll,
  initializePayroll,
  listPayroll,
  listTaxDocuments,
  listWorkplace,
  previewPayroll,
  sendStaffOnboardingInvite,
  setupContractor,
  setupEmployee,
  updateChecks,
  updateCompany,
  updateContractorPayment,
  updateContractorWorkplace,
  updateEmployeeWorkplace,
  updatePayroll,
  updatePayrollItem,
  updateWorkplace,
  upgradeFinchCode,
});

export default usePayrollService;
