






















































































import { ref, computed, useRouter, useRoute } from '@nuxtjs/composition-api';
import {
  TextInput,
  BaseDivider,
  AnnularThrobber,
  BaseBanner,
} from '@bambeehr/pollen';
import EmployerTrainingListItem from './EmployerTrainingListItem';
import partition from 'lodash/partition';
import TrainingEnrollmentModal from '@/modules/Training/components/TrainingEnrollmentModal';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import { useApolloQuery } from '@/gql/apolloWrapper';

import { useGetCompanyVerboseQuery } from '@/gql/generated';

const coreBundleId = 'core-bundle';

// Utils
export const prepString = (str: string) => str.toLowerCase().trim();

export const decorateTrainingLists = (originalList, query) => {
  const [recommended, all] = partition(
    originalList
      .filter(
        (i) =>
          prepString(i.name).includes(prepString(query)) ||
          i.topics?.some((t) => t.name.includes(query))
      )
      .map((i) => ({
        ...i,
        isBundle: !!i.topics?.length,
        completedSeats: i.counts.completed,
        totalSeats:
          i.counts.completed + i.counts.inProgress + i.counts.notStarted,
      })),
    (item) => item.bundleId === coreBundleId
  );

  return {
    recommended,
    all,
    hasBothGroups: !!recommended.length && !!all.length,
    hasNoResults: !recommended.length && !all.length,
    hasNoTrainings: !originalList.length,
  };
};

export const mapToContent = (i) => ({
  ...i,
  id: i.bundleId || i.topicId,
});

export default {
  components: {
    EmployerTrainingListItem,
    BaseDivider,
    TextInput,
    AnnularThrobber,
    BaseBanner,
    TrainingEnrollmentModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { companyId } = useCurrentCompany();
    const trainingCompany = ref();
    const isFetching = ref(false);

    useApolloQuery(
      useGetCompanyVerboseQuery,
      { data: { companyId: companyId.value } },
      {
        data: trainingCompany,
        pending: isFetching,
      },
      { placeholderPick: ({ company: res }) => res }
    );

    const trainingList = computed(() => {
      if (!trainingCompany.value) {
        return [];
      }

      const { bundles, topics } = trainingCompany.value.purchasedProducts;

      return [...bundles.map(mapToContent), ...topics.map(mapToContent)];
    });

    const searchQuery = ref('');

    const trainingLists = computed(() =>
      decorateTrainingLists(trainingList.value, searchQuery.value)
    );

    const showInitBanner = ref(route.value.query.init);
    const showAddSeatsModal = ref(false);
    const showAddSeatsSavingModal = ref(false);
    const showEnrollmentModal = ref(false);

    const bundleId = ref('');
    const topicId = ref('');
    const enrollmentName = ref('');

    const toggleEnrollmentModalOn = ({ bundleId: bId, topicId: tId, name }) => {
      bundleId.value = bId;
      topicId.value = tId;
      enrollmentName.value = name;

      showEnrollmentModal.value = !showEnrollmentModal.value;
    };

    const goToTraining = (id) => {
      router.push(`/training/${id}`);
    };

    return {
      trainingLists,
      searchQuery,
      showAddSeatsModal,
      showAddSeatsSavingModal,
      showEnrollmentModal,
      toggleEnrollmentModalOn,
      bundleId,
      topicId,
      goToTraining,
      enrollmentName,
      isFetching,
      showInitBanner,
    };
  },
};
