



















































































import {
  ref,
  useStore,
  computed,
  watch,
  Ref,
  PropType,
} from '@nuxtjs/composition-api';
import { TypeBody, BaseDivider } from '@bambeehr/pollen';
import TrainingBenefitCallout from '@/modules/Training/components/TrainingBenefitCallout';
import TrainingRegistrationCourseCallout from '@/modules/Training/components/TrainingRegistrationCourseCallout';
import StaggerEntranceTransitionGroup, {
  Speeds,
} from '@/components/StaggerEntranceTransitionGroup/StaggerEntranceTransitionGroup';
import useEmployees from '@/hooks/useEmployees';
import ContentfulRichText from '@/components/contentful/ContentfulRichText';
import usePurchaseTrainingContent from '@/modules/Training/components/PurchaseTraining/usePurchaseTrainingContent';
import ManualSeatInput from '@/modules/Training/components/PurchaseTraining/ManualSeatInput';
import PurchaseTrainingCta from '@/modules/Training/components/PurchaseTraining/PurchaseTrainingCta';
import { usePurchaseSeatsForBundleMutation } from '@/gql/generated';
import { useApolloMutation } from '@/gql/apolloWrapper';

import currency from '@bambeehr/currency';

interface ProductPrices {
  bundle: number;
  course: number;
}

const BUNDLE_COUNT = 4;

const coursesConfig = [
  {
    id: 1,
    title: 'Preventing Sexual Harassment',
    length: 75,
  },
  {
    id: 2,
    title: 'Preventing Workplace Violence',
    length: 30,
  },
  {
    id: 3,
    title: 'Mandatory Business Ethics',
    length: 30,
  },
  {
    id: 4,
    title: 'Workplace Safety for Employees',
    length: 30,
  },
];

export default {
  name: 'PurchaseTrainingForm',
  components: {
    BaseDivider,
    TypeBody,
    TrainingBenefitCallout,
    TrainingRegistrationCourseCallout,
    StaggerEntranceTransitionGroup,
    ContentfulRichText,
    ManualSeatInput,
    PurchaseTrainingCta,
  },
  props: {
    hideBenefits: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    manual: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    seats: {
      type: Number as PropType<number>,
      default: 0,
    },
    cta: {
      type: String as PropType<string>,
      default: '',
    },
    prices: {
      type: Object as PropType<ProductPrices>,
      default: () => ({
        bundle: 0,
        course: 0,
      }),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { companyId } = store.getters;

    // Get conetent from contentful
    const {
      purchaseAgreementRaw,
      purchaseButtonTextWithVariables,
      pending: trainingContentPending,
      trainingRegistrationContentMap,
      updateSeatCount,
    } = usePurchaseTrainingContent();

    const pricePerCourse = computed(
      () =>
        // 100 = convert to dollars
        (props.prices as ProductPrices).bundle / BUNDLE_COUNT / 100
    );

    // Configure Courses
    const courses = computed(() => {
      return coursesConfig.map((course) => ({
        ...course,
        price: currency(pricePerCourse.value).format(),
      }));
    });

    // Employees
    const { w2: w2Employees } = useEmployees();
    const seatsCount: Ref<number> = ref((props.seats as number) || 0);
    updateSeatCount(seatsCount.value);

    watch(
      w2Employees,
      (newVal: any[]) => {
        seatsCount.value = newVal?.length;
      },
      { immediate: true }
    );

    watch(
      seatsCount,
      (num: number) => {
        updateSeatCount(num);
      },
      { immediate: true }
    );

    const totalCost = computed(() =>
      currency(seatsCount.value * pricePerCourse.value * BUNDLE_COUNT).format()
    );

    const isSaving = ref(false);
    const {
      mutate: purchaseSeatsForBundle,
      onDone,
      onError,
    } = useApolloMutation(usePurchaseSeatsForBundleMutation, {
      pending: isSaving,
    });

    onError((error) => {
      console.error(error);
      emit('purchase-error', seatsCount.value);
    });

    onDone((res) => {
      if (res) {
        emit('purchase-success', seatsCount.value);
        isSaving.value = false;
      }
    });

    const purchaseTraining = () => {
      purchaseSeatsForBundle({
        data: {
          companyId,
          numSeats: seatsCount.value,
        },
      });
    };

    const handlePurchaseClick = async () => {
      isSaving.value = true;
      emit('saving', true);
      purchaseTraining();
    };

    const isManual: Boolean = !!props.manual; // Does not change

    const isLoading = computed(() => {
      return trainingContentPending.value;
    });

    return {
      courses,
      trainingRegistrationContentMap,
      purchaseAgreementRaw,
      seatsCount,
      handlePurchaseClick,
      purchaseButtonTextWithVariables,
      isLoading,
      isSaving,
      isManual,
      staggerSpeed: Speeds.STAGGER,
      totalCost,
    };
  },
};
