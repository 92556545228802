import fetchContactUsContent from '@/modules/ContactUs/services/fetchContent';
import {
  computed,
  ComputedRef,
  readonly,
  ref,
  Ref,
} from '@nuxtjs/composition-api';

// Cache
let isInit: Boolean = false;
const pending: Ref<Boolean> = ref(true);

const contactUsContent: Ref<any> = ref({});
const ENTRY_ID = '5n57QNveDXlYOOh6cRbF8H';
const fetchContent = async () => {
  const content = await fetchContactUsContent(ENTRY_ID);
  contactUsContent.value = content;
  pending.value = false;
};

// Call Modal
const newCallModalHeading: ComputedRef<string> = computed(() => {
  return contactUsContent.value.newCallModalHeading;
});

// Call Now
const callNowOptionTitle: ComputedRef<string> = computed(() => {
  return contactUsContent.value.callNowOptionTitle;
});

const callNowCta: ComputedRef<string> = computed(() => {
  return contactUsContent.value.callNowCta;
});
const callNowPhoneNumber: ComputedRef<string> = computed(() => {
  return contactUsContent.value.callNowPhoneNumber;
});

const callNowDescription: ComputedRef<string> = computed(() => {
  return contactUsContent.value.callNowDescription;
});

// Schedule Call
const scheduleDedicatedHrCallTitle: ComputedRef<string> = computed(() => {
  return contactUsContent.value.scheduleDedicatedHrCallTitle;
});

const scheduleACallCta: ComputedRef<string> = computed(() => {
  return contactUsContent.value.scheduleACallCta;
});

const scheduledCallResponseTime: ComputedRef<string> = computed(() => {
  return contactUsContent.value.scheduledCallResponseTime;
});

// New Request Modal
const newRequestModalHeading: ComputedRef<string> = computed(() => {
  return contactUsContent.value.newRequestModalHeading;
});

// Dedicated HR Request
const sendRequestCta: ComputedRef<string> = computed(() => {
  return contactUsContent.value.sendRequestCta;
});

const requestResponseTime: ComputedRef<string> = computed(() => {
  return contactUsContent.value.requestResponseTime;
});

const dedicatedHrRequestConfirmationText: ComputedRef<string> = computed(() => {
  return contactUsContent.value.dedicatedHrRequestConfirmationText;
});

const chatNowConfirmationModalTitle: ComputedRef<string> = computed(() => {
  return contactUsContent.value.chatNowConfirmationModalTitle;
});

const dedicatedHrRequestConfirmationModalTitle: ComputedRef<string> = computed(
  () => {
    return contactUsContent.value.dedicatedHrRequestConfirmationModalTitle;
  }
);

const dedicatedHrRequestDescription: ComputedRef<string> = computed(() => {
  return contactUsContent.value.dedicatedHrRequestDescription;
});

// Chat Now
const chatNowCta: ComputedRef<string> = computed(() => {
  return contactUsContent.value.chatNowCta;
});

const chatNowResponseTime: ComputedRef<string> = computed(() => {
  return contactUsContent.value.chatNowResponseTime;
});

const chatNowOptionTitle: ComputedRef<string> = computed(() => {
  return contactUsContent.value.chatNowOptionTitle;
});

const dedicatedHrOptionTitle: ComputedRef<string> = computed(() => {
  return contactUsContent.value.dedicatedHrOptionTitle;
});

const chatNowRequestConfirmationText: ComputedRef<string> = computed(() => {
  return contactUsContent.value.chatNowRequestConfirmationText;
});

// Hours
const businessHours: ComputedRef<string> = computed(() => {
  return contactUsContent.value.businessHours;
});

const employeeConfidentialityWarning: ComputedRef<string> = computed(() => {
  return contactUsContent.value.employeeConfidentialityWarning;
});

// Customer Support
const customerSupportCallCta: ComputedRef<string> = computed(() => {
  return contactUsContent.value.customerSupportCallCta;
});
const customerSupportPhoneNumber: ComputedRef<string> = computed(() => {
  return contactUsContent.value.customerSupportPhoneNumber;
});

const customerSupportCallText: ComputedRef<string> = computed(() => {
  return contactUsContent.value.customerSupportCallText;
});

const useContactUsContent = () => {
  if (!isInit) {
    fetchContent();
    isInit = true;
  }

  return {
    all: readonly(contactUsContent),
    newCallModalHeading,
    callNowOptionTitle,
    callNowCta,
    callNowDescription,
    callNowPhoneNumber,
    scheduleDedicatedHrCallTitle,
    scheduleACallCta,
    scheduledCallResponseTime,
    businessHours,
    employeeConfidentialityWarning,
    newRequestModalHeading,
    chatNowCta,
    chatNowResponseTime,
    sendRequestCta,
    requestResponseTime,
    dedicatedHrRequestDescription,
    pending,
    customerSupportPhoneNumber,
    customerSupportCallText,
    customerSupportCallCta,
    chatNowOptionTitle,
    dedicatedHrOptionTitle,
    chatNowRequestConfirmationText,
    dedicatedHrRequestConfirmationText,
    chatNowConfirmationModalTitle,
    dedicatedHrRequestConfirmationModalTitle,
  };
};

export default useContactUsContent;
