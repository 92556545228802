import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import {
  computed,
  ComputedRef,
  reactive,
  readonly,
  Ref,
  ref,
  useStore,
  watch,
} from '@nuxtjs/composition-api';

import {
  CompanyStatus,
  CoreGoal,
  CoreTask,
  GoalCompletionStatus,
  GoalName,
  PastPayrollUsage,
  TaskCompletionStatus,
  TaskName,
  useCompleteGoalMutation,
  useCompleteTaskMutation,
  useGetMyCompanyGoalsQuery,
  useStartTaskMutation,
} from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import useState from '@/hooks/useState/useState';
import { callScheduledKey } from '@/modules/Onboarding/hooks/useEmployerOnboarding';
import { getFromLocalStorage } from '@/utils/localStorage';
import useNotifications from '@bambeehr/use-notifications';
import cloneDeep from 'lodash/cloneDeep';
import useCompanyStore from '@/store/companies';

export interface ExtendedCoreGoal extends CoreGoal {
  loading: boolean;
}
export interface ExtendedCoreTask extends CoreTask {
  disabledText?: string;
}
let companyId;

const thirtyMinutesAgo = new Date(Date.now() - 30 * 60000).toISOString();

let initialized;
const isReady = ref(false);

let addError;
let store;
let dismissedGoals: { [key: string]: boolean | string };
let dismissedTasks;
let isBambeeLite;

const payrollCompany = ref();

const rawGoals: Ref<CoreGoal[]> = ref([]);
const goals: Ref<ExtendedCoreGoal[]> = ref([]);

const allGoalsMap: Ref<{ [key: string]: ExtendedCoreGoal }> = ref({});
const allTasksMap: Ref<{ [key: string]: ExtendedCoreTask }> = ref({});

const supportGoalNames = computed(() => {
  const unique = (values: any[]) => [...new Set(values)];

  return unique([
    ...Object.values(GoalName),
    // Only show Payroll Goals if the user has completed the BambeePlusSetup goal
    // Modify this when we want to start showing the payroll goal to non-BB+ customers
    !!rawGoals.value.find((g) => g.name === GoalName.BambeePlusSetup)
      ?.completedAt && GoalName.PayrollOnboarding,
  ]).filter(Boolean);
});

const addStaffTask = computed(() => allTasksMap.value[TaskName.AddStaff]);

const milestones = ref([] as any[]);
const isLearnMoreModalOpen = ref(false);

const hasRemainingTasks: Ref<boolean> = ref(false);

const currentGoalName = ref();
const currentGoal: ComputedRef<ExtendedCoreGoal | undefined> = computed(() =>
  currentGoalName.value
    ? goals.value?.find((goal) => goal.name === currentGoalName.value)
    : undefined
);

export const LOCAL_STORAGE_GOALS_KEY = 'dismissedGoals';

export const LOCAL_STORAGE_TASKS_KEY = 'dismissedTasks';

const dismissableGoalNames = computed(() =>
  Object.fromEntries(Object.values(GoalName).map((key) => [key, false]))
);

const dismissableTaskNames = computed(() =>
  Object.fromEntries(Object.values(TaskName).map((key) => [key, false]))
);

const doNowGoals = reactive({
  [GoalName.MeetYourTeam]: false,
  [GoalName.HrAudit]: false,
  [GoalName.IntroduceStaffToBambee]: false,
  [GoalName.ImproveStaffCommunication]: false,
  [GoalName.PayrollOnboarding]: false,
  [GoalName.BambeePlusSetup]: false,
});

const payrollCompanyNeedsToScheduleCall = computed(() => {
  if (
    payrollCompany.value?.pastPayrollUsage ===
    PastPayrollUsage.HaveCurrentPayrollProvider
  ) {
    return payrollCompany.value?.onboardingHasComplexScenario;
  }

  return (
    payrollCompany.value?.onboardingHasGarnishmentsOrDeductions ||
    payrollCompany.value?.onboardingOffersEmployeeBenefits ||
    payrollCompany.value?.onboardingHasComplexScenario
  );
});

const shouldShowPayrollMigration = computed(
  () =>
    payrollCompany.value?.pastPayrollUsage ===
    PastPayrollUsage.HaveCurrentPayrollProvider
);

const ignoreTasks = computed(() =>
  [
    TaskName.InviteAndEnrollEmployees,
    TaskName.AddCompanyEin,
    // Only include the "Schedule a Payroll Overview Call" task when certain conditions are met
    !payrollCompanyNeedsToScheduleCall.value &&
      TaskName.SchedulePayrollOverview,
    // Only include the "Ensure Employees are Ready for Payroll" task when they have an employee init'ed in payroll
    !payrollCompany.value?.total.payrollEmployees &&
      TaskName.EnsureEmployeesReadyForPayroll,
    // Only show the payroll migration task if the user has a past payroll provider
    !shouldShowPayrollMigration.value && TaskName.MigratePayrollData,
  ].filter(Boolean)
);

const sortedTasks: ComputedRef<ExtendedCoreTask[] | undefined> = computed(
  () => {
    if (!currentGoal.value) {
      return undefined;
    }
    const { taskNames, tasks } = currentGoal.value;

    const prerequisiteTasks: TaskName[] = [];
    if (
      currentGoal.value &&
      currentGoal.value?.name === GoalName.PayrollOnboarding
    ) {
      prerequisiteTasks.push(TaskName.PayrollBasicSetup);
    }

    const arePrerequisiteTasksCompleted = prerequisiteTasks.every(
      (taskName) => {
        return allTasksMap.value[taskName]?.completedAt;
      }
    );

    const sortedTaskList = tasks.reduce((acc, task: ExtendedCoreTask) => {
      const thisTask = cloneDeep(task);
      const index = taskNames.indexOf(task.name);

      if (
        thisTask.name === TaskName.InviteStaffToSignPolicies &&
        !thisTask.completedAt
      ) {
        if (addStaffTask.value && !addStaffTask.value.completedAt) {
          thisTask.disabledText = 'Add staff before inviting them to Bambee';
        }
      }

      const isPrerequisiteTask = prerequisiteTasks.includes(thisTask.name);

      if (
        prerequisiteTasks.length &&
        !arePrerequisiteTasksCompleted &&
        !isPrerequisiteTask
      ) {
        thisTask.disabledText = ' ';
        thisTask.copy.estimated = 'Locked';
      }

      if (
        thisTask.name === TaskName.RunFirstPayroll &&
        // If the company isn't live or has no onboarded employees, disable the task
        (payrollCompany.value?.status !== CompanyStatus.Live ||
          !payrollCompany.value?.total.onboardedEmployees)
      ) {
        // No disabled text, but should be disabled
        thisTask.disabledText = ' ';
        thisTask.copy.estimated = 'Locked';
      }

      acc[index] = thisTask;

      return acc;
    }, [] as CoreTask[]);

    return sortedTaskList;
  }
);

// NOTE: currentTasks is just sortedTasks...
const currentTasks: ComputedRef<CoreTask[] | undefined> = computed(() => {
  if (!currentGoal.value) {
    return undefined;
  }

  return sortedTasks.value;
});

const isGoalDismissed = (goal: { id: string; name: GoalName }) =>
  dismissedGoals[goal.name] === goal.id;

const dismissTask = (name: string): void => {
  dismissedTasks[name] = true;
};

const dismissGoal = (goalName: GoalName): void => {
  const goal = goals.value.find((goal) => goal.name === goalName);
  if (goal) {
    dismissedGoals[goalName] = goal.id;
  }
};

const setCurrentGoal = (name?: string): void => {
  currentGoalName.value = name;
};

const remainingGoals: ComputedRef<CoreGoal[]> = computed(() =>
  goals.value.filter((goal) => !goal.completedAt && !isGoalDismissed(goal))
);

const recentlyCompletedGoals: ComputedRef<CoreGoal[]> = computed(() =>
  goals.value.filter((goal) => {
    const { completedAt } = goal;

    return (
      completedAt && completedAt > thirtyMinutesAgo && !isGoalDismissed(goal)
    );
  })
);

const nextGoal: ComputedRef<CoreGoal | undefined> = computed(() => {
  return goals.value?.find((goal) => goal === remainingGoals.value[0]);
});

const setDoNowGoalValues = (nextGoal) => {
  const goalIndex = goals.value.findIndex((goal) => goal === nextGoal);

  // Create an array for each index up to the next goal
  [...Array(goalIndex + 1).keys()].forEach((index) => {
    // Set all goals up to the current index to true
    const goalName = goals.value[index].name;

    if (goalName) {
      doNowGoals[goalName] = true;
    }
  });
};

watch(
  nextGoal,
  (newGoal) => {
    if (newGoal) {
      setDoNowGoalValues(newGoal);
    }
  },
  {
    immediate: true,
  }
);

const currentGoalList: ComputedRef<(CoreGoal | undefined)[]> = computed(() => {
  if (nextGoal.value === undefined) {
    return [...recentlyCompletedGoals.value];
  }
  const previousDoNowGoalNames = new Set(
    Object.keys(doNowGoals).filter((goalName) => doNowGoals[goalName])
  );

  const previousDoNowGoals = goals.value.filter(
    (goal) =>
      previousDoNowGoalNames.has(goal.name) &&
      recentlyCompletedGoals.value.includes(goal) &&
      nextGoal.value?.name !== goal.name &&
      (!isGoalDismissed(goal) ||
        (doNowGoals[goal.name] && goal.completedAt > thirtyMinutesAgo))
  );

  return [nextGoal.value, ...previousDoNowGoals];
});

const remainingCompletedGoals: ComputedRef<(CoreGoal | undefined)[]> = computed(
  () => currentGoalList.value.filter((goal) => goal?.completedAt)
);

const doNextGoals: ComputedRef<CoreGoal[]> = computed(() =>
  goals.value.filter(
    (goal) =>
      goal !== nextGoal.value &&
      (!goal.completedAt ||
        (!doNowGoals[goal.name] && goal.completedAt > thirtyMinutesAgo)) &&
      !isGoalDismissed(goal)
  )
);

const completedGoals: ComputedRef<(CoreGoal | undefined)[]> = computed(() => {
  return goals.value.filter((goal) => goal.completedAt);
});

const incompleteGoals: ComputedRef<(CoreGoal | undefined)[]> = computed(() => {
  return goals.value.filter(
    (goal) => !goal.completedAt && !isGoalDismissed(goal)
  );
});

const remainingTasks: ComputedRef<CoreTask[] | undefined> = computed(() => {
  return (
    sortedTasks.value?.filter(
      (task) =>
        !dismissedTasks[task.name] &&
        (!task.completedAt ||
          new Date(task.completedAt) > new Date(Date.now() - 30 * 60000))
      // new Date(task.completedAt) > new Date(Date.now() - 30 * 60 * 1000)) // 30 minutes
    ) || []
  );
});

const LearnMoreModalTasks = [
  TaskName.LearnHrAudit,
  TaskName.LearnIntroduceStaffToBambee,
  TaskName.LearnImproveStaffCommunication,
  TaskName.LearnOnboardingKitBasics,
];

// eslint-disable-next-line consistent-return
const learnMoreProps = computed(() => {
  const remainingTaskNames = remainingTasks.value?.map((task) => task.name);

  // eslint-disable-next-line default-case
  switch (
    remainingTaskNames?.find((task) => LearnMoreModalTasks.includes(task))
  ) {
    case TaskName.LearnHrAudit:
      return {
        title: 'What is an HR Audit?',
        description:
          "Our HR Audit is used to diagnose the health of your business's HR practices and identify areas of risk. After completing the HR Audit, our HR experts will provide you with the following:",
        factoidList: [
          'Overview of high-risk business practices',
          'HR Guidance and Risk Mitigation Strategies',
        ],
        image: '/images/goals/teach.svg',
        imageClass: 'max-w-sm',
        ctaLabel: 'Got it!',
        id: TaskName.LearnHrAudit,
      };
    case TaskName.LearnIntroduceStaffToBambee:
      return {
        title: 'Getting Started',
        description:
          'Adding your staff to the platform is the first step in taking full advantage of our HR technology and tools. When all your staff are added to the platform you can:',
        factoidList: [
          'Send HR policies for signature',
          'Assign employee training',
          'Provide regular and structured feedback',
        ],
        image: '/images/goals/study.svg',
        imageClass: 'max-w-xs',
        ctaLabel: 'Done',
        id: TaskName.LearnIntroduceStaffToBambee,
        textClass: 'max-w-lg',
        layoutClass: 'justfiy-center place-content-center',
      };
    case TaskName.LearnImproveStaffCommunication:
      return {
        header: 'set you and your hires up for success',
        title: 'What is Staff Communication?',
        description:
          'Implement a system of two-way communication in your business to improve productivity and keep your team engaged.',
        factoidList: [
          'Provide regular and structured feedback to your employees ',
          'Create a space for your employees to share any concerns',
        ],
        image: '/images/goals/communication.svg',
        imageClass: 'max-w-sm',
        ctaLabel: 'Got it!',
        id: TaskName.LearnImproveStaffCommunication,
        textClass: 'max-w-lg',
      };
    case TaskName.LearnOnboardingKitBasics:
      return {
        header: 'set you and your hires up for success',
        title: 'What is an Onboarding Kit?',
        description:
          'Create a standardized onboarding kit to ensure all employees are provided with company HR policies and required training.',
        factoidList: [
          'Easily send policies and track signatures',
          'Assign training and track progress',
        ],
        image: '/images/goals/learn-onboarding-kit-basics.svg',
        imageClass: 'max-w-sm',
        ctaLabel: 'Got it!',
        id: TaskName.LearnOnboardingKitBasics,
        textClass: 'max-w-lg',
      };
  }

  return undefined;
});

const fetchGoals = (force = false) => {
  const welcomeCallMilestone = milestones.value?.find(
    ({ milestone }) => milestone === 'WelcomeCall'
  );

  const welcomeCallMilestoneCompletedAt = welcomeCallMilestone?.completedAt;

  const completedAfterGoalLaunch =
    welcomeCallMilestoneCompletedAt &&
    new Date(welcomeCallMilestoneCompletedAt) > new Date('2023-07-18');

  const hasCompletedInOnboardingWizard =
    getFromLocalStorage(callScheduledKey) === 'true';
  const shouldShowHrmGoal =
    !hasCompletedInOnboardingWizard &&
    (completedAfterGoalLaunch || !welcomeCallMilestone?.completedAt);

  if (!shouldShowHrmGoal) {
    dismissGoal(GoalName.MeetYourTeam);
  }

  const { onResult, onError, loading } = useApolloQuery(
    // @ts-ignore
    useGetMyCompanyGoalsQuery,
    { id: companyId.value },
    undefined,
    { force }
  );

  onResult((result) => {
    let onboardingGoals = result.getMyCompanyGoals;
    if (!onboardingGoals?.length) {
      return;
    }

    if (isBambeeLite.value) {
      const liteGoals = [GoalName.WorkplaceViolenceCaHrmFollowUp];
      onboardingGoals = onboardingGoals.filter((g) =>
        liteGoals.includes(g.name)
      );
    }

    payrollCompany.value = result.getCompany;
    rawGoals.value = onboardingGoals as CoreGoal[];
  });

  const currentGoals = computed<ExtendedCoreGoal[]>(() =>
    rawGoals.value
      .filter((goal) => supportGoalNames.value.includes(goal.name))
      .map((goal: any) => {
        return {
          ...goal,
          loading: false,
          tasks: goal.tasks
            ?.filter((t) => !ignoreTasks.value.includes(t.name))
            .map((task) => ({
              ...task,
              disabledText: null,
            })),
        };
      })
  );

  watch(
    currentGoals,
    (updatedGoals) => {
      updatedGoals.forEach((goal, index) => {
        const existingGoal = allGoalsMap.value[goal.name];
        // merge goal
        if (existingGoal) {
          Object.assign(existingGoal, goal);
        } else {
          // Insert the goal at the correct index
          goals.value.splice(index, 0, goal);
          allGoalsMap.value[goal.name] = goal;
        }
        // merge tasks
        goal.tasks.forEach((task) => {
          const existingTask = allTasksMap.value[task.name];
          if (existingTask) {
            Object.assign(existingTask, task);
          } else {
            allTasksMap.value[task.name] = task;
          }
        });
      });
    },
    {
      immediate: true,
    }
  );

  onError((error) => {
    window.DD_RUM?.addError({
      error,
      context: 'Unable to fetch goals',
    });
  });

  watch(
    loading,
    (newVal) => {
      if (!newVal) {
        isReady.value = !newVal;
      }
    },
    {
      immediate: true,
    }
  );

  // eslint-disable-next-line consistent-return
  return goals;
};

const hasStartedAnyGoal = computed(() =>
  rawGoals.value.some((goal) => goal.tasks.some((task) => task.startedAt))
);

const completeTaskByName = (
  taskName: TaskName,
  completionStatus = TaskCompletionStatus.Automatic
) => {
  const task = allTasksMap.value[taskName];
  if (!task || task.completedAt) {
    return;
  }

  const {
    mutate: complete,
    onDone,
    onError,
  } = useApolloMutation(useCompleteTaskMutation);
  complete({
    data: {
      id: task.id,
      completionStatus,
    },
  });

  onDone((result) => {
    task.completedAt =
      result.completeTask?.completedAt || new Date().toISOString();
    task.completionStatus = completionStatus;
    fetchGoals(true);
  });

  onError((error) => {
    addError('Unable to complete task, please try again later');
    window.DD_RUM?.addError({
      error,
      context: 'Unable to complete task',
    });
  });
};

const skipAllTasks = (
  goalName: GoalName,
  completionStatus = TaskCompletionStatus.Skipped
) => {
  const goal = allGoalsMap.value[goalName];

  if (!goal || goal.completedAt) {
    return;
  }

  goal.tasks.forEach((task) => {
    completeTaskByName(task.name, completionStatus);
  });
};

const completeGoalByName = (
  goalName: GoalName,
  completionStatus = GoalCompletionStatus.Automatic
) => {
  const goal = allGoalsMap.value[goalName];

  if (!goal || goal.completedAt) {
    return;
  }

  const {
    mutate: complete,
    onDone,
    onError,
  } = useApolloMutation(useCompleteGoalMutation);
  complete({
    data: {
      id: goal.id,
      completionStatus,
    },
  });

  onDone((result) => {
    goal.completedAt =
      result.completeGoal.completedAt || new Date().toISOString();
    goal.completionStatus = completionStatus;
    fetchGoals(true);
  });

  onError((error) => {
    addError('Unable to complete goal, please try again later');
    window.DD_RUM?.addError({
      error,
      context: 'Unable to complete goal',
    });
  });
};

const completeHrAuditTask = () => {
  return completeTaskByName(TaskName.TakeHrAudit);
};

const completeSetupReportCardTask = () => {
  return completeTaskByName(TaskName.SetupReportCards);
};

const completeSetupEmployeeVoicesTask = () => {
  return completeTaskByName(TaskName.SetupEmployeeVoices);
};

const payrollGoal = computed(() =>
  goals.value.find((goal) => goal.name === GoalName.PayrollOnboarding)
);
const getReadyToGetPaidGoal = computed(() =>
  goals.value.find((goal) => goal.name === GoalName.GetReadyToGetPaid)
);
const completeSetupCompanyPolicies = () => {
  return completeTaskByName(TaskName.SetupCompanyPolicies);
};

const startTask = (task) => {
  if (task?.startedAt || !task?.id) {
    return;
  }

  const startTaskId = task.id;

  const {
    mutate: start,
    onDone,
    onError,
  } = useApolloMutation(useStartTaskMutation);
  start({
    startTaskId,
  });

  onDone(() => {
    fetchGoals(true);
  });

  onError((error) => {
    addError('Unable to start task, please try again later');
    window.DD_RUM?.addError({
      error,
      context: 'Unable to start task',
    });
  });
};

const setupHook = async (force?: boolean): Promise<void> => {
  // If the store isn't ready, don't continue
  // Set initialized to false so that the hook can be re-initialized
  try {
    store = useStore();
  } catch (error) {
    initialized = false;

    return;
  }
  const { isBambeeLite: tmpIsBambeeLite } = usePlanAccess();
  isBambeeLite = tmpIsBambeeLite;

  const { companyId: cid } = useCurrentCompany();
  companyId = cid;
  const { hydrateFromLocalStorage, state: storedGoals } = useState(
    dismissableGoalNames.value,
    LOCAL_STORAGE_GOALS_KEY
  );
  dismissedGoals = storedGoals;
  // workaround for goals dismissed without ID, converts `true` to the current goal's ID
  Object.values(GoalName).forEach((goalName) => {
    if (dismissedGoals[goalName] === true) {
      dismissGoal(goalName);
    }
  });

  const {
    hydrateFromLocalStorage: hydrateDismissedTasksFromLocalStorage,
    state: tasks,
  } = useState(dismissableTaskNames.value, LOCAL_STORAGE_TASKS_KEY);
  dismissedTasks = tasks;

  milestones.value = await useCompanyStore().onboardingMilestonesGetter;
  const { addError: tmpAddError } = useNotifications();
  addError = tmpAddError;
  hydrateFromLocalStorage();
  hydrateDismissedTasksFromLocalStorage();
  fetchGoals(force);
};

const useGoals = (force = false) => {
  if (!initialized || force) {
    initialized = true;
    setupHook(force);
  }

  return {
    // States
    goals,
    remainingGoals: readonly(remainingGoals),
    currentGoal: readonly(currentGoal),
    nextGoal: readonly(nextGoal),
    doNextGoals: readonly(doNextGoals),
    currentGoalList: readonly(currentGoalList),
    hasRemainingTasks,
    currentTasks,
    allTasksMap,
    isReady,
    remainingTasks,
    remainingCompletedGoals,
    completedGoals,
    incompleteGoals,
    learnMoreProps,
    isLearnMoreModalOpen,
    LearnMoreModalTasks,
    dismissedGoals: readonly(dismissedGoals),
    dismissedTasks: readonly(dismissedTasks),
    payrollGoal,
    getReadyToGetPaidGoal,
    hasStartedAnyGoal,
    allGoalsMap,

    // Methods
    setCurrentGoal,
    completeTaskByName,
    startTask,
    dismissTask,
    dismissGoal,
    completeHrAuditTask,
    completeSetupReportCardTask,
    completeSetupEmployeeVoicesTask,
    completeGoalByName,
    skipAllTasks,
    fetchGoals,
    completeSetupCompanyPolicies,
  };
};

export default useGoals;
