import CookieNames from '@/constants/CookieNames';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import bam from '@/lib/bam';
import { getCookie, setCookie } from '@/lib/cookie-helpers';
import { ContentfulEntryIDs } from '@/lib/globals/ContentfulEntryIDs';
import fetchServiceUpdateContent from '@/modules/ContactUs/services/fetchContent';
import { useNectarFeatureFlags } from '@/modules/Nectar/hooks/useNectarFeatureFlags';
import { computed, ref } from '@nuxtjs/composition-api';
import useCurrentUser from '../useCurrentUser';

export enum ServiceUpdateEventTracking {
  UPGRADE_NOTIFICATION_SHOWN = 'nectar_upgrade_notification_shown',
  UPGRADE_NOTIFICATION_ACKNOWLEDGED = 'nectar_upgrade_notification_acknowledged',
}

const initialized = ref(false);
const isModalOpen = ref(false);
const isBannerVisible = ref(true);
const serviceUpdateContent = ref('');

let dismissedCookieName;

const { currentUser } = useCurrentUser();
const { company } = useCurrentCompany();
const dismissedCookie = ref();

const isOwnerAdmin = computed(
  () =>
    currentUser.value?.role === 'admin' || currentUser.value?.role === 'user'
);

const { isNectarServiceUpdateModalEnabled, isNectarEnabled } =
  useNectarFeatureFlags();

const fetchContent = async () => {
  // * link to content: https://app.contentful.com/spaces/tb3mx4vb7wqk/entries/7I6AF6HMn8sldx3ZsniDiv
  const content = await fetchServiceUpdateContent(
    ContentfulEntryIDs.SERVICE_UPDATE
  );
  serviceUpdateContent.value = content.modalCopy;
};

const shouldShowInitally = computed(
  () =>
    // is nectar feature enable
    isNectarEnabled.value &&
    // is the service update modal enabled
    isNectarServiceUpdateModalEnabled.value &&
    // cookie is not set
    !dismissedCookie.value &&
    // is the current user an admin or owner
    isOwnerAdmin.value
);

const logEvent = (event: ServiceUpdateEventTracking) => {
  bam.track(event, {
    // eslint-disable-next-line no-underscore-dangle
    companyId: currentUser.value?._company?._id,
    // eslint-disable-next-line no-underscore-dangle
    userId: currentUser.value?._id,
  });
};

const setupHook = () => {
  initialized.value = true;
  fetchContent();
};

const useServiceUpdate = () => {
  if (!initialized.value) {
    dismissedCookieName = `${company.value.id}_${CookieNames.DISSMISSED_SERVICE_UPDATE_MODAL}`;
    dismissedCookie.value = getCookie(dismissedCookieName);
    setupHook();
  }

  function handleModalClose() {
    isModalOpen.value = false;
    isBannerVisible.value = false;
    // if the user closes the modal we won't show them again.
    setCookie(dismissedCookieName, true);
    logEvent(ServiceUpdateEventTracking.UPGRADE_NOTIFICATION_ACKNOWLEDGED);
  }

  function openModal() {
    isModalOpen.value = true;
  }

  return {
    handleModalClose,
    isModalOpen,
    isBannerVisible,
    shouldShowInitally,
    openModal,
    logEvent,
    serviceUpdateContent,
  };
};

export default useServiceUpdate;
