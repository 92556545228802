import {
  RemainingOnboardingStepLabels,
  RemainingOnboardingSteps,
} from '@/modules/payroll/constants/employee';

export const convertToLabel = (remainingSteps: string[]) =>
  remainingSteps.map((step) => {
    switch (step) {
      case RemainingOnboardingSteps.SSN:
        return RemainingOnboardingStepLabels.SSN;

      case RemainingOnboardingSteps.EIN:
        return RemainingOnboardingStepLabels.EIN;

      case RemainingOnboardingSteps.PAYMENT_METHOD:
        return RemainingOnboardingStepLabels.PAYMENT_METHOD;

      case RemainingOnboardingSteps.WITHHOLDINGS:
        return RemainingOnboardingStepLabels.WITHHOLDINGS;

      case RemainingOnboardingSteps.BANK_ACCOUNT:
        return RemainingOnboardingStepLabels.BANK_ACCOUNT;

      case RemainingOnboardingSteps.SETUP_PARAMETERS:
        return RemainingOnboardingStepLabels.SETUP_PARAMETERS;

      case RemainingOnboardingSteps.COMPANY_DEFINED_ATTRIBUTES:
        return RemainingOnboardingStepLabels.COMPANY_DEFINED_ATTRIBUTES;

      default:
        return '';
    }
  });

export const normalizeSteps = (remainingSteps: string[]) => {
  const labelList = convertToLabel(remainingSteps);

  return labelList?.length ? labelList.join(', ') : '';
};
