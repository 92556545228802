





































import { defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, BaseIcon } from '@bambeehr/pollen';

export default defineComponent({
  name: 'VideoThumb',
  components: {
    TypeBody,
    BaseIcon,
  },
  props: {
    img: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
