import {
  ref,
  Ref,
  useStore,
  computed,
  reactive,
} from '@nuxtjs/composition-api';
import { useApolloQuery, useApolloMutation } from '@/gql/apolloWrapper';
import {
  useGetBambeePlusInfoQuery,
  usePurchaseBambeePlusMutation,
  PastPayrollUsage,
} from '@/gql/generated';
import { useAdvisorsStore } from '@/store/advisors';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useNotifications from '@bambeehr/use-notifications';

let initialized = false;
let companyId;
let addError;
let store: ReturnType<typeof useStore>;

export const nextYear = new Date('2024-01-01');

const isEligible: Ref<boolean | null> = ref(null);
const isReady = ref(false);
const isBambeePlus = ref(false);
const bambeePlusDueDate = ref('');
const bambeePlusPrice = ref(0);
const bambeePlusLateImplementationFee = ref(0);
const hasPastPayrollUsage = ref(false);
const isPastDueDate = computed(() => {
  const dueDate = new Date(bambeePlusDueDate.value);
  const now = new Date();

  return dueDate < now;
});

const shouldStartPayrollNextYear = computed(() => {
  return (
    // If it is past the deadline for the user to onboard and they have a
    // previous payroll provider we should defer their start date to next year.
    isPastDueDate.value &&
    hasPastPayrollUsage.value &&
    new Date().getTime() < nextYear?.getTime()
  );
});

const isAdmin = ref(false);

const getBambeePlusInfo = () => {
  if (!isAdmin.value) {
    return;
  }

  const { onResult, loading, onError } = useApolloQuery(
    useGetBambeePlusInfoQuery,
    // Gross arguments a result of unnecessary typing on the getBambeePlusInfo query
    {
      data: { companyId },
      companyId,
    }
  );

  onResult(({ getBambeePlusInfo, payrollCompany }) => {
    isEligible.value = getBambeePlusInfo?.eligible;
    isBambeePlus.value = getBambeePlusInfo?.status === 'ACTIVE';
    bambeePlusDueDate.value = getBambeePlusInfo?.onboardingDueDate ?? '';
    hasPastPayrollUsage.value =
      payrollCompany?.pastPayrollUsage ===
      PastPayrollUsage.HaveCurrentPayrollProvider;
    bambeePlusPrice.value = getBambeePlusInfo?.price ?? 0;
    bambeePlusLateImplementationFee.value =
      getBambeePlusInfo?.lateFeeImplementationPrice ?? 0;

    isReady.value = true;
  });

  onError(() => {
    isEligible.value = false;
    isBambeePlus.value = false;

    isReady.value = true;
  });

  // eslint-disable-next-line consistent-return
  return {
    loading,
  };
};

const purchaseBambeePlus = () => {
  if (!isAdmin.value) {
    return;
  }

  const { mutate, onDone, loading, onError } = useApolloMutation(
    usePurchaseBambeePlusMutation
  );

  mutate({
    data: {
      companyId: companyId.value,
    },
  });

  onError((error) => {
    // @ts-ignore
    addError(error.responseErrors[0].raw.message);
    window.DD_RUM?.addError('bambee-plus-upgrade-failure', error);
  });

  // eslint-disable-next-line consistent-return
  return {
    onDone,
    loading,
  };
};
const hrmAvatar = computed(() => useAdvisorsStore().hrManagerAvatar);
const hrmName = computed(() => useAdvisorsStore().hrManagerName);

const hrm = reactive({
  avatar: hrmAvatar,
  name: hrmName,
});

const setupHook = async () => {
  store = useStore();
  const { companyId: tempId } = useCurrentCompany();
  companyId = tempId;
  const { addError: tmpAddError } = useNotifications();
  addError = tmpAddError;
  isAdmin.value = await store.getters['auth/isCompanyAdmin'];
  getBambeePlusInfo();
};

const useBambeePlus = (force = false) => {
  if (!initialized || force) {
    initialized = true;
    setupHook();
  }

  return {
    isEligible,
    isBambeePlus,
    isReady,
    purchaseBambeePlus,
    hrm,
    bambeePlusPrice,
    bambeePlusLateImplementationFee,
    shouldStartPayrollNextYear,
  };
};

export default useBambeePlus;
