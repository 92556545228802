import {
  ContentfulContentTypes,
  ContentfulCopyTypes,
} from '@/lib/globals/ContentfulEntryIDs';
import TaskCenterAssetSlugs from '@/modules/TaskCenter/constants/TaskCenterAssetSlugs';
import Contentful from '@/services/Contentful';
import { convertContentfulRawCopyToMap } from '@/utils/contentfulHelpers';
import { reactive } from '@nuxtjs/composition-api';
import { EntryCollection } from 'contentful';

interface ContentfulCopyRaw {
  slug: string;
  text: string;
  type: string;
}

const state = reactive({
  taskCenterCardCopies: {},
  taskCenterCardImages: {},
});

async function fetchTaskCenterCopy() {
  if (Object.keys(state.taskCenterCardCopies).length) {
    return;
  }

  const content: EntryCollection<ContentfulCopyRaw> =
    await Contentful.getEntries({
      content_type: ContentfulContentTypes.COPY,
      'fields.type': ContentfulCopyTypes.TASK_CENTER,
    });

  state.taskCenterCardCopies = convertContentfulRawCopyToMap(content.items);
}

async function fetchTaskCenterAssets() {
  if (Object.keys(state.taskCenterCardImages).length) {
    return;
  }

  // TODO: optimize?
  const addStaffImage = await Contentful.getAsset(
    TaskCenterAssetSlugs.TASK_CENTER_CARD_STAFF_IMAGE
  );

  const hrPracticesImage = await Contentful.getAsset(
    TaskCenterAssetSlugs.TASK_CENTER_CARD_HR_PRACTICES_IMAGE
  );

  const hrAuditImage = await Contentful.getAsset(
    TaskCenterAssetSlugs.TASK_CENTER_CARD_AUDIT_IMAGE
  );

  const employeeVoicesImage = await Contentful.getAsset(
    TaskCenterAssetSlugs.TASK_CENTER_CARD_EMPLOYEE_VOICES
  );

  // TODO: store mapping
  state.taskCenterCardImages = {
    'add-staff': addStaffImage,
    'hr-practice': hrPracticesImage,
    'hr-audit': hrAuditImage,
    'employee-voices': employeeVoicesImage,
  };
}

const getTaskCenterCopy = (key: string) =>
  state.taskCenterCardCopies[key] || '';
const getTaskCenterImageUrl = (key: string) =>
  state.taskCenterCardImages[key]?.fields?.file?.url || '';

const useTaskCenterCopy = () => {
  fetchTaskCenterCopy();
  fetchTaskCenterAssets();

  return {
    getTaskCenterCopy,
    getTaskCenterImageUrl,
  };
};

export default useTaskCenterCopy;
