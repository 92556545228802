import { track } from '@/gql/services/payroll.service';
import shortId from 'shortid';
import useCurrentUser from '@/hooks/useCurrentUser';
import useCurrentCompany from '@/hooks/useCurrentCompany';

const userValidator = (user) => {
  if (!user) {
    return false;
  }
  if (!user._id) {
    return false;
  }
  if (!user.profile) {
    return false;
  }

  if (!Array.isArray(user._team_members)) {
    return false;
  }

  return true;
};
function initIntercom() {
  if (window.Intercom) {
    const options = {
      app_id: process.env.intercom,
      // to match option used with Segment
      // https://segment.com/docs/connections/destinations/catalog/intercom/#conditionally-show-the-intercom-chat-widget-browser-only
      // https://www.intercom.com/help/en/articles/189-turn-off-show-or-hide-the-intercom-messenger
      hide_default_launcher: true,
    };
    try {
      window.Intercom('boot', options);
    } catch (err) {
      window.DD_RUM.addError('INTERCOM_FAILED_BOOT', {
        err,
      });
    }
  }
}

function identifyIntercom(user) {
  if (!user || !window.Intercom) {
    return;
  }
  const data = {
    user_id: user._id,
    email: user.email,
    name: user.profile?.full_name,
    created_at: user.created_at,
    phone: user.profile?.phone,
    unsubscribed_from_emails: !user.states?.email_notification,
    custom_attributes: user.profile,
    user_hash: user.intercom_hash,
  };
  if (user._company) {
    const company = user._company;
    data.company = {
      company_id: company._id,
      name: company.name,
      dba: company.profile.dba,
      created_at: company.created_at,
      plan: company.profile.plan,
      size: company.profile.num_of_employees,
      industry: company.profile.industry,
    };
    data.companies = [data.company];
  }

  try {
    window.Intercom('update', data);
  } catch (err) {
    window.DD_RUM.addError('INTERCOM_FAILED_UPDATE', {
      err,
    });
    console.err(err);
  }
}

function identifySegment(user, data = {}) {
  if (!user || !window.analytics) {
    return;
  }

  try {
    window.analytics.identify(
      user._id,
      {
        name: user.profile.full_name,
        firstName: user.profile.first_name,
        lastName: user.profile.last_name,
        title: user.profile.role,
        role: user.role,
        phone: user.profile.phone,
        username: user.email,
        email: user.email,
        active: user.active,
        manager: user.permissions.manager,
        approver: user.permissions.approver,
        activation_status: user.activation_status,
        createdAt: user.created_at,
        environment: process.env.env,
        emailNotifications: user.states.email_notification,
        unsubscribed: !user.states.email_notification,
        termsOfService: user.TOSAccepted,
        ...data,
      },
      {
        Intercom: { hideDefaultLauncher: true },
      }
    );

    groupSegment(user._company);
  } catch (e) {
    // do nothing
  }
}

function groupSegment(company) {
  if (!company || !window.analytics) {
    return;
  }
  const { profile } = company;

  window.analytics.group(company._id, {
    name: company.name,
    dba: profile.dba,
    avatar: profile.logo_url,
    industry: profile.industry,
    employees: profile.num_of_employees,
    plan: profile.plan,
    address: {
      city: profile.city,
      state: profile.state,
    },
    phone: profile.phone,
    createdAt: company.created_at,
  });
}

const bam = {
  userValidator,
  initIntercom,
  identifySegment,
  identifyIntercom,

  identify(user, data) {
    if (user) {
      identifyIntercom(user);
      identifySegment(user, data);
    }
  },

  track(eventName, eventData, integrations) {
    if (window.analytics) {
      window.analytics.track(
        eventName,
        {
          ...eventData,
          site_host: process.env.host,
        },
        integrations && { integrations }
      );
    }
    if (window?.DD_RUM) {
      window.DD_RUM.addAction(eventName, eventData);
    }
  },

  // This method uses the BE as a proxy to send the event to the Segment API
  // This helps to eliminate any potential issues with the client-side ad blockers
  trackEvent(eventName, eventData, integrations) {
    const clientEventId = eventData?.id || shortId.generate();
    const { currentUserId } = useCurrentUser();
    const { companyId } = useCurrentCompany();

    track({
      // This id is only used for apollo
      id: clientEventId,
      event: eventName,
      properties: {
        ...eventData,
        clientEventId,
        site_host: process?.env?.host,
        integrations,
        meta: {
          userAgent: navigator?.userAgent,
          locale: navigator?.language,
          page: window.location.href,
          isMobile: navigator?.userAgentData?.mobile,
        },
        companyId: companyId.value,
      },
      userId: currentUserId.value,
    });

    if (window?.DD_RUM) {
      window.DD_RUM?.addAction(eventName, eventData);
    }
  },

  shutdown() {
    if (window.Intercom) {
      try {
        window.Intercom('shutdown');
      } catch (err) {
        console.err(err);
      }
    }
  },
};

export default bam;
export { userValidator };
