import { defineStore } from 'pinia';

export const useCalendlyStore = defineStore('calendly', {
  state: () => ({
    all: [] as any[],
  }),
  actions: {
    setCalendlyMeetings(meeting) {
      this.all = [...(this.all || []), meeting];
    },
    async getCalendlyEvent(meetingId) {
      const res = await this.$nuxt.$axios.get(
        `task/advisor/v1/public/calendly/event/${meetingId}`
      );
      this.setCalendlyMeetings(res.data);

      return res.data;
    },
    async getBusinessManagerCalendlyUrl(tierNumber) {
      const res = await this.$nuxt.$axios.get(
        `/task/scheduler/business-manager-url/${tierNumber}`
      );

      return res.data.url;
    },
  },
});

export default null;
