import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';
import useState from '@/hooks/useState/useState';
import { DASHBOARD } from '@/lib/globals/routes.js';
import {
  IJourney,
  IModal,
  PayrollJourneyKeys,
} from '@/modules/payroll/components/PayrollRootModal/constants/componentConfig';
import useGoals from '@/modules/TaskCenter/hooks/useGoals/useGoals';
import { computed, ref, Ref, useRouter } from '@nuxtjs/composition-api';

const PAYROLL_ROOT = 'payroll-root-modal';

const payrollSegmentEvents = {
  STARTED: `${PAYROLL_ROOT}-started`,
  CLOSED: `${PAYROLL_ROOT}-closed`,
  NEXT: `${PAYROLL_ROOT}-next-page-clicked`,
  LEARN_MORE: `${PAYROLL_ROOT}-learn-more-clicked`,
  COMPLETED: `${PAYROLL_ROOT}-completed`,
};

let route;
let router;
let isInitialized;
let payrollStatus;
let track;

// State
const componentList: Ref<IModal[]> = ref([] as IModal[]);
const journey = ref<IJourney>();
const isPayrollRootModalOpen = ref<boolean>(false);
const isLoading = ref<boolean>(false);

const currentComponent: Ref<IModal> = ref(componentList.value[0]);
const currentJourney = computed(() => journey.value);
const isFirstStep = computed(
  () => componentList.value.indexOf(currentComponent.value) === 0
);

const isMigrationExplainerJourney = computed(
  () =>
    currentJourney.value?.key === PayrollJourneyKeys.PAYROLL_MIGRATION_EXPLAINER
);
const currentStep = computed(() =>
  currentJourney.value?.screens.find(
    (step) => step.component === currentComponent.value.component
  )
);

const { hydrateFromLocalStorage, state } = useState(
  { hasSeenExplainer: false },
  'payrollPlanChangeExplainer',
  false
);
const hasSeenMigrationExplainer = computed(() => state.hasSeenExplainer);

const showPayrollMigrationExplainer = computed(
  () =>
    isFirstStep.value &&
    currentJourney.value?.key === PayrollJourneyKeys.PAYROLL_MIGRATION_EXPLAINER
);

// Methods
const handleClose = () => {
  isPayrollRootModalOpen.value = false;
  track(payrollSegmentEvents.CLOSED, currentStep.value);
  if (showPayrollMigrationExplainer.value) {
    state.hasSeenExplainer = true;
  }
  // Clear query params
  router?.push({ query: {} });
};

const handleComplete = () => {
  track(payrollSegmentEvents.COMPLETED);

  if (!payrollStatus?.allTasksMap.value?.PayrollBasicSetup?.completedAt) {
    router?.push('/payroll-activation/get-started/');
  } else if (
    !payrollStatus?.allGoalsMap.value?.PayrollOnboarding?.completedAt
  ) {
    router.push('/goal/payroll-onboarding');
  } else {
    router?.push(DASHBOARD.path);
  }
  isPayrollRootModalOpen.value = false;
};

const handleNext = () => {
  const currentIndex = componentList.value.indexOf(currentComponent.value);
  if (showPayrollMigrationExplainer.value) {
    state.hasSeenExplainer = true;
  }
  if (currentIndex < componentList.value.length - 1) {
    currentComponent.value = componentList.value[currentIndex + 1];
  }
  track(payrollSegmentEvents.NEXT, currentStep.value);

  if (currentIndex === componentList.value.length - 1) {
    handleComplete();
  }
};

const handleLearnMore = (route) => {
  track(payrollSegmentEvents.LEARN_MORE);
  isPayrollRootModalOpen.value = false;
  router?.push(route);
};

const loadJourney = (userJourney: IJourney, customProp) => {
  journey.value = userJourney;
  componentList.value = userJourney.screens;
  [currentComponent.value] = componentList.value;
  if (customProp) {
    currentComponent.value.componentProps = customProp;
  }
  track(payrollSegmentEvents.STARTED, journey.value);
  isPayrollRootModalOpen.value = true;
};

const setupHook = () => {
  router = useRouter();
  const payrollCompany = useGoals(true);
  const { payrollTrackEvent } = useCompanyBillingInfo();
  payrollStatus = payrollCompany;
  track = payrollTrackEvent;
  hydrateFromLocalStorage();
};

const usePayrollRootModal = () => {
  if (!isInitialized) {
    setupHook();
  }

  // remove this when payroll cancellation request is persisted in BE
  const {
    hydrateFromLocalStorage: hydratePayrollCancellation,
    state: payrollCancellationState,
  } = useState(
    { hasRequestedPayrollCancellation: false },
    'hasRequestedPayrollCancellation',
    true
  );

  hydratePayrollCancellation();

  const hasRequestedPayrollCancellation = computed(
    () => payrollCancellationState.hasRequestedPayrollCancellation
  );

  return {
    // Constants
    payrollSegmentEvents,
    // State
    isPayrollRootModalOpen,
    isLoading,
    componentList,
    currentComponent,
    state,
    currentJourney,
    hasSeenMigrationExplainer,
    isMigrationExplainerJourney,
    payrollCancellationState,
    hasRequestedPayrollCancellation,
    // Methods
    handleClose,
    handleNext,
    loadJourney,
    handleLearnMore,
  };
};

export default usePayrollRootModal;
