import Vue from 'vue';

function setPip(state, pip) {
  const index = state.pips.findIndex((pip_id) => pip_id == pip._id);

  if (index == -1) {
    state.pips.push(pip._id);
  } else {
    state.pips[pip._id] = pip;
  }

  Vue.set(state._byId, pip._id, pip);
}

export default {
  state: () => ({
    pips: [],
    my_pips: [],
    _byId: {},
    termination: {},
  }),
  mutations: {
    setPip,
    setPips(state, pips) {
      pips.forEach((p) => setPip(state, p));
    },
    deletePip(state, pip) {
      const index = state.pips.findIndex((pip_id) => pip_id == pip._id);

      if (index != -1) {
        state.pips.splice(index, 1);
      }

      Vue.set(state._byId, pip._id, pip);
    },

    setTermination(state, termination) {
      state.termination = termination;
    },
  },
  getters: {
    pips(state, getters) {
      return state.pips.map((pip_id) => getters.pip(pip_id));
    },

    employeePips(state, getters) {
      return function (employee_id) {
        return getters.pips.filter((pip) => pip.employee._id == employee_id);
      };
    },

    pip(state) {
      return function (id) {
        return state._byId[id];
      };
    },

    termination(state) {
      return state.termination;
    },
  },
  actions: {
    getUserPips({ commit, state }, user_id) {
      state.pips = [];

      return this.$axios
        .get(`/v0/app/cap/employee/${user_id}`)
        .then((res) => commit('setPips', res.data.pips));
    },
    getUserTermination({ commit, state }, user_id) {
      state.termination = {};

      return this.$axios
        .get(`/v0/app/termination/employee/${user_id}`)
        .then((res) => {
          commit('setTermination', res.data);
        })
        .catch((err) => {
          commit('setTermination', {});
        });
    },
    getMyPips({ commit }, { filter_type }) {
      return this.$axios
        .get('/v0/app/api/my/pips', { filter_type })
        .then((res) => {
          commit('setPips', { pips: res.body });
        });
    },
    getPip({ commit, state }, { type, id }) {
      return this.$axios.get(`/v0/app/cap/${type}/${id}`).then((res) => {
        commit('setPip', res.data);

        return res.data;
      });
    },
    createPip(
      { commit },
      {
        current_user,
        employee_id,
        type,
        text,
        incident_date,
        incident_documents,
      }
    ) {
      /*
        available types: pip, verbal_warning, written_warning, incident
       */
      const data = {
        type,
        text,
        incident_date,
        user: current_user._id,
        employee: employee_id,
        company: current_user._company._id,
        documents: incident_documents,
      };

      return this.$axios
        .post(`/v0/app/cap/${type}/create`, data)
        .then(async (res) => {
          const { cap, cabinet } = res.data;
          commit('setPip', cap);
          await commit('folder/set', cabinet, { root: true });

          return res.data;
        });
    },
    updatePip({ commit, dispatch }, { type, pip }) {
      this.$axios
        .post(`/v0/app/cap/${type}/${pip._id}`, pip)
        .then((res) => {
          commit('setPip', res.data);
          dispatch(
            'folder/setContentById',
            {
              id: res.data._id,
              content: res.data,
            },
            { root: true }
          );
        })
        .catch((err) => {
          console.log('Something wrong with updatePip', err);
        });
    },
    removePip({ commit }, { type, pip }) {
      this.$axios
        .delete(`/v0/app/cap/${type}/${pip._id}`)
        .then(async (res) => {
          commit('deletePip', pip);
          await commit('folder/deleteItem', res.data, { root: true });
        })
        .catch((err) => {
          console.log('Something wrong with deletePip', err);
        });
    },
    addNote({ commit }, { type, pip }) {
      this.$axios
        .post(`/v0/app/cap/${type}/${pip._id}/addNote`, pip)
        .then((res) => {
          commit('setPip', res.data);

          return res.data;
        })
        .catch((err) => {
          console.log('Something wrong with addNote', err);
        });
    },
    deleteNote({ commit }, { type, pip }) {
      this.$axios
        .post(`/v0/app/cap/${type}/${pip.pip._id}/deleteNote`, {
          pip: pip.pip,
          note_id: pip.note_id,
        })
        .then((res) => {
          commit('setPip', res.data);

          return res.data;
        })
        .catch((err) => {
          console.log('Something wrong with deleteNote', err);
        });
    },
    startPip({ commit }, { pip }) {
      this.$axios
        .post('/v0/app/api/pip/start')
        .send({ pip_id: pip.id })
        .then((res) => {
          pip.status = 'started';
          commit('setPip', { pip });
        });
    },
    approval({ commit }, { pip, handle_close }) {
      return this.$axios
        .post(`/v0/app/api/pip/${pip.id}/approvers/notify`)
        .then((res) => {
          pip.status = 'awaiting approval';
          commit('setPip', { pip: res.body.pip });
          commit('openModal', {
            name: 'approval_request',
            set: {
              pip,
              handle_close,
            },
          });

          return res.body.pip;
        });
    },
    denyPip({ commit, dispatch }, { type, pip }) {
      return this.$axios
        .post(`/v0/app/cap/${type}/${pip._id}/deny`)
        .then(async (res) => {
          commit('setPip', res.data);
          // await store.dispatch('folder/getEmployee', res.data.employee._id, {root : true})
          dispatch(
            'folder/setContentById',
            {
              content: res.data,
              id: res.data._id,
            },
            { root: true }
          );

          return res.data;
        });
    },
    approvePip({ commit, dispatch }, { type, pip }) {
      return this.$axios
        .post(`/v0/app/cap/${type}/${pip._id}/approve`)
        .then(async (res) => {
          commit('setPip', res.data);
          // await dispatch('folder/getEmployee', res.data.employee._id, {root : true})
          dispatch(
            'folder/setContentById',
            {
              content: res.data,
              id: res.data._id,
            },
            { root: true }
          );

          return res.data;
        });
    },
    sendEmployeeNotification({ commit, dispatch }, { pip }) {
      this.$axios
        .post(`/v0/app/api/pip/${pip.id}/employee/notify`)
        .then((res) => {
          commit('setPip', { pip: res.body.pip });
        });
    },
    signPip({ commit, rootState }, { type, payload }) {
      const { pip_id } = payload;

      return this.$axios
        .post(`/v0/app/cap/${type}/${pip_id}/sign`, payload)
        .then((res) => {
          commit('setPip', res.data);

          // if (rootState.current_user.role != 'employee') {
          //   bam.track('employee_pip_signature_notify', {
          //     pip: res.data
          //   })
          // }

          return res.data;
        });
    },

    finishDraftPip({ commit }, { type, pip }) {
      return this.$axios
        .post(`/v0/app/cap/${type}/${pip._id}/finish-draft`)
        .then((res) => {
          commit('setPip', { pip: res.data });

          return res.data;
        });
    },

    updateFollowups({ commit }, { payload }) {
      return this.$axios
        .post(`/v0/app/api/pip/${payload.pip_id}/followup/update`)
        .send(payload)
        .then((res) => {
          commit('setPip', { pip: res.body.pip });

          return res.body.pip;
        });
    },

    addSignedDocuments({ commit, dispatch }, { type, pip, documents }) {
      return this.$axios
        .post(`/v0/app/cap/${type}/${pip._id}/add-signed-document`, {
          documents,
        })
        .then((res) => {
          commit('setPip', res.data);
          dispatch(
            'folder/setContentById',
            {
              content: res.data,
              id: res.data._id,
            },
            { root: true }
          );

          return res.data;
        });
    },

    approvalRequest({ commit, dispatch }, { type, pip }) {
      return this.$axios
        .post(`/v0/app/cap/${type}/${pip._id}/notify-approvers`)
        .then((res) => {
          commit('setPip', res.data);

          dispatch(
            'folder/setContentById',
            {
              content: res.data,
              id: res.data._id,
            },
            { root: true }
          );

          return res.data;
        });
    },
  },
};
