














import { BaseCard, AnnularThrobber } from '@bambeehr/pollen';
import { defineComponent, PropType, onMounted } from '@nuxtjs/composition-api';
import { Company } from '@/gql/generated';
import useCheckPayrollReports from '@/modules/payroll/hooks/useCheckPayrollReports';

export default defineComponent({
  name: 'PayrollSettings',
  components: {
    AnnularThrobber,
    BaseCard,
  },
  props: {
    companyId: {
      type: String as PropType<string>,
      required: true,
    },
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
  },
  setup(props) {
    const { openComponent, isLoading } = useCheckPayrollReports();

    onMounted(() => {
      openComponent(props.companyId);
    });

    return {
      isLoading,
    };
  },
});
