


















































































































import {
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
  computed,
} from '@nuxtjs/composition-api';
import { TypeBody, BaseButton, BaseBanner } from '@bambeehr/pollen';
import DescriptionTextInput from '@/components/DescriptionTextInput/DescriptionTextInput.vue';
import ExplainerTooltipModal from '@/components/ExplainerTooltipModal';
import HexLoader from '@/components/HexLoader/HexLoader.vue';
import {
  useGetPayrollFeinStatusQuery,
  useVerifyFeinInCheckMutation,
} from '@/gql/generated';
import { useApolloQuery, useApolloMutation } from '@/gql/apolloWrapper';
import CachePolicy from '@/gql/CachePolicy';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import cloneDeep from 'lodash/cloneDeep';
import validateFein from './validateFein';
import { checkIsInvalid, VerificationStatus } from './checkIsInvalid';

enum Emit {
  FORM_SATISFIED = 'form-satisfied',
  INPUT = 'input',
}

export interface FeinForm {
  name: string;
  fein: string;
}

export default defineComponent({
  name: 'VerifyFeinForm',
  components: {
    DescriptionTextInput,
    ExplainerTooltipModal,
    TypeBody,
    BaseButton,
    HexLoader,
    BaseBanner,
  },
  props: {
    value: {
      type: Object as PropType<FeinForm>,
      required: true,
    },
  },
  emits: [Emit.FORM_SATISFIED, Emit.INPUT],
  setup(props, { emit }) {
    const { companyId } = useCurrentCompany();

    const form = reactive(props.value);
    const isVerifying = ref(false);
    const isValidating = ref(false);
    const feinStatus = ref('');
    const attemptCount = ref(0);
    const hasTimedOut = ref(false);

    // Used to track changes of dirty 'invalid' form.
    const rejectedForm = reactive(cloneDeep(props.value));

    const handleCompanyResult = (res) => {
      const company = res?.getCompany || res?.verifyFEINInCheck;
      if (!company) {
        return;
      }

      feinStatus.value = company.feinVerification?.status || '';
      attemptCount.value = company.feinVerificationAttempts || 0;

      if (checkIsInvalid(feinStatus.value as VerificationStatus)) {
        Object.assign(rejectedForm, form);
      } else if (feinStatus.value === VerificationStatus.PROCESSING) {
        // If the status returns as 'processing' we timed out with Check
        // We'll want to unblock the UI and rely on the Check webhook
        hasTimedOut.value = true;
      }
    };

    const { mutate: verify, onDone: onVerificationDone } = useApolloMutation(
      useVerifyFeinInCheckMutation,
      { pending: isVerifying }
    );
    const { onResult: onCompanyResult } = useApolloQuery(
      useGetPayrollFeinStatusQuery,
      { id: companyId.value }
    );

    onCompanyResult(handleCompanyResult);
    onVerificationDone(handleCompanyResult);

    const isValid = computed(
      () => feinStatus.value === VerificationStatus.VERIFIED
    );
    const isInvalid = computed(() =>
      checkIsInvalid(feinStatus.value as VerificationStatus)
    );
    const isLockedOut = computed(
      () =>
        (!isValid.value && attemptCount.value >= 2) ||
        hasTimedOut.value ||
        feinStatus.value === VerificationStatus.FINAL_REJECTED
    );
    const disableForm = computed(
      () => isValid.value || isLockedOut.value || isVerifying.value
    );

    watch(form, () => emit(Emit.INPUT, form), { deep: true });
    watch(
      [isValid, isLockedOut],
      ([valid, locked]) => emit(Emit.FORM_SATISFIED, valid || locked),
      { immediate: true }
    );

    const feinIsValid = computed(() => validateFein(form.fein));
    // 160 is the max length of a legal name
    const nameIsValid = computed(
      () => !!form.name.length && form.name.length <= 160
    );

    const missingRequiredInfo = computed(
      () => !feinIsValid.value || !nameIsValid.value
    );

    const showNameAsInvalid = computed(
      () =>
        isValidating.value &&
        !isLockedOut.value &&
        (!nameIsValid.value ||
          (isInvalid.value && rejectedForm.name === form.name))
    );

    const showFeinAsInvalid = computed(
      () =>
        isValidating.value &&
        !isLockedOut.value &&
        (!feinIsValid.value ||
          (isInvalid.value && rejectedForm.fein === form.fein))
    );

    const disableVerifyBtn = computed(
      () =>
        (showNameAsInvalid.value && showFeinAsInvalid.value) ||
        isVerifying.value
    );

    const verifyFein = () => {
      isValidating.value = true;

      if (missingRequiredInfo.value) {
        return;
      }

      verify({
        feinData: {
          id: companyId.value,
          fein: form.fein,
          name: form.name,
        },
        verifyData: {
          companyId: companyId.value,
          fein: form.fein,
          legalName: form.name,
        },
        syncCompanyData: {
          id: companyId.value,
        },
      });
    };

    return {
      disableForm,
      disableVerifyBtn,
      feinIsValid,
      form,
      isInvalid,
      isLockedOut,
      isValid,
      isValidating,
      isVerifying,
      missingRequiredInfo,
      nameIsValid,
      rejectedForm,
      showFeinAsInvalid,
      showNameAsInvalid,
      verifyFein,
    };
  },
});
