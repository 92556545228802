import { useApolloMutation } from '@/gql/apolloWrapper/apolloWrapper';
import bam from '@/lib/bam';

import { useUpdateCoreUserStateMutation } from '@/gql/generated';
import { ref, Ref } from '@nuxtjs/composition-api';

let initialized: boolean | undefined;

const showUpgradeModal: Ref<boolean> = ref(false);

const showDeclineModal: Ref<boolean> = ref(false);
const showAcceptModal: Ref<boolean> = ref(false);
const userStatesList = ref({});

// Modal handlers
const toggleUpgradeModal = () => {
  showUpgradeModal.value = !showUpgradeModal.value;
};

const toggleDeclineModal = () => {
  showDeclineModal.value = !showDeclineModal.value;
};

const toggleAcceptModal = () => {
  showAcceptModal.value = !showAcceptModal.value;
};

const toggleUserState = ({ key, value }) => {
  const { mutate: updateUserState, onDone: onUpdatedUserState } =
    useApolloMutation(useUpdateCoreUserStateMutation);
  updateUserState({
    input: {
      [key]: value,
    },
  });
  onUpdatedUserState((data) => {
    userStatesList.value = data.updateCoreUserState;
  });
};
// Flow handlers
const handleUpgradeAccepted = () => {
  toggleUpgradeModal();
  toggleAcceptModal();
  bam.track('upgrade_offer_accepted');
};

const handleUpgradeDeclined = () => {
  toggleUpgradeModal();
  toggleDeclineModal();
  bam.track('upgrade_offer_declined');
};

const handleDeclineAcknowledged = () => {
  toggleDeclineModal();
  toggleUserState({
    key: 'viewedInAppUpgradeModal',
    value: true,
  });
};

const handleAcceptAcknowledged = () => {
  toggleAcceptModal();
  toggleUserState({
    key: 'viewedInAppUpgradeModal',
    value: true,
  });
};

const dismissOffer = () => {
  toggleUpgradeModal();
  bam.track('upgrade_offer_dismissed');
  toggleUserState({
    key: 'viewedInAppUpgradeModal',
    value: true,
  });
};

const shouldShowUpgradeOffer = (userStates) => {
  let shouldShow = false;
  if (!userStates?.viewedInAppUpgradeModal || showUpgradeModal.value) {
    shouldShow = true;
  }

  return shouldShow;
};

const useIntervalUpgradeOffer = () => {
  if (!initialized) {
    initialized = true;
  }

  return {
    // States
    showUpgradeModal,
    showDeclineModal,
    showAcceptModal,
    // Methods
    handleUpgradeAccepted,
    handleUpgradeDeclined,
    handleDeclineAcknowledged,
    handleAcceptAcknowledged,
    toggleUpgradeModal,
    dismissOffer,
    shouldShowUpgradeOffer,
  };
};

export default useIntervalUpgradeOffer;
