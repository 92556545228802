import { ref } from '@nuxtjs/composition-api';
import bam from '@/lib/bam';
import { ProductKey } from '@/gql/generated';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';

const { isBambeePause } = usePlanAccess();

const shouldShowUpgradeModal = ref(false);
const recommendedPackage = ref('');

const toggleUpgradeModal = (
  val?: boolean,
  packageRecommendation?: ProductKey,
  eventPostfix?: string
) => {
  shouldShowUpgradeModal.value = val ?? !shouldShowUpgradeModal.value;
  recommendedPackage.value = packageRecommendation ?? '';

  if (shouldShowUpgradeModal.value) {
    // Legacy tracking
    bam.track(
      `bambee_lite_upgrade_modal_viewed${
        eventPostfix ? `_${eventPostfix}` : ''
      }}`
    );

    // New plan tracking
    bam.track(
      `plan_upgrade_modal_viewed${eventPostfix ? `_${eventPostfix}` : ''}}`
    );
  }
};

const shouldShowReactivateModal = ref(false);

const toggleReactivateModal = (val?: boolean) => {
  shouldShowReactivateModal.value = val ?? !shouldShowUpgradeModal.value;
  bam.track(`reactivation_modal_viewed`);
};

const togglePlanAction = (
  val?: boolean,
  packageRecommendation?: ProductKey,
  eventPostfix?: string
) => {
  if (isBambeePause.value) {
    toggleReactivateModal(val);
  } else {
    toggleUpgradeModal(val, packageRecommendation, eventPostfix);
  }
};

export const useBambeeUpsell = () => {
  return {
    // State
    shouldShowUpgradeModal,
    shouldShowReactivateModal,
    recommendedPackage,
    // Methods
    toggleUpgradeModal,
    toggleReactivateModal,
    togglePlanAction,
  };
};

export default useBambeeUpsell;
