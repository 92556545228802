













import { onMounted, ref } from '@nuxtjs/composition-api';

import useAddWorkerPolicies from '@/modules/StaffManagement/components/AddStaffWizard/Policies/useAddWorkerPolicies';
import PoliciesFooter from './PoliciesFooter';
import PoliciesList from './PoliciesList';
import PoliciesHeader from './PoliciesHeader';

export default {
  components: {
    PoliciesHeader,
    PoliciesFooter,
    PoliciesList,
  },
  props: {
    workerId: {
      type: String,
      required: true,
    },
    // Used to track events. Only necessary if tracking is enabled
    sessionId: {
      type: String,
      default: '',
    },
    workerIsContractor: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const shouldShowFooter = ref(false);

    useAddWorkerPolicies(
      emit,
      props.workerId as string,
      props.sessionId as string,
      props.workerIsContractor as boolean
    );

    onMounted(() => {
      window.scrollTo({ top: 0 });

      // Slight delay to sequence animations
      setTimeout(() => {
        shouldShowFooter.value = true;
      }, 300);
    });

    return {
      shouldShowFooter,
    };
  },
};
