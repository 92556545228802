import { StaffMember } from '@/modules/StaffManagement/models';

export enum Emit {
  CLICK = 'click',
  IMPLEMENT = 'implement',
  NOT_SIGNED = 'not-signed',
  REAFFIRMATION_NEEDED = 'reaffirmation-needed',
  SIGNED = 'signed',
  TOTAL = 'total',
}

export enum PolicyTab {
  IMPLEMENTED = 'Implemented',
  PREVIEW = 'Preview',
}

export enum PolicyFilterTypes {
  TYPE = 'Type',
  STATUS = 'Status',
}

export const SortingKey = 'SortBy';

export const enum SortingTypes {
  STATUS = 'Status',
  ALPHA_ASC = 'A - Z',
  ALPHA_DESC = 'Z - A',
}

export enum PolicyStaffStates {
  SIGNED = 'Complete',
  NOT_SIGNED = 'Signature Needed',
  NOT_IMPLEMENTED = 'Not Implemented',
  REAFFIRMATION_NEEDED = 'Reaffirmation Needed',
}

export enum PolicyType {
  BAMBEE = 'bambee',
  PANDA = 'panda',
}

export const PolicyStateDescription = Object.freeze({
  [PolicyStaffStates.SIGNED]: 'Staff has signed the policy',
  [PolicyStaffStates.NOT_SIGNED]: 'Staff have not yet signed the policy',
});

export interface PolicyStaffFilterState {
  [PolicyFilterTypes.TYPE]: String | null;
  [PolicyFilterTypes.STATUS]: String | null;
  [SortingKey]: String | null;
}

export interface PolicyStaffMember extends StaffMember {
  policyState: PolicyStaffStates;
}

export type PolicyGroupedStaffMembers = Map<
  PolicyStaffStates,
  PolicyStaffMember[]
>;

export enum ImplementationStates {
  IMPLEMENTED = 'Implemented',
  NOT_IMPLEMENTED = 'Not Implemented',
}

export default null;
