import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';

import {
  useCreateCompanyRolesMutation,
  useDeleteCompanyRolesMutation,
  useGetCompanyRolesQuery,
  useUpdateCompanyRolesMutation,
} from '@/gql/generated';
import useNotifications from '@bambeehr/use-notifications';
import { ref, Ref, watch } from '@nuxtjs/composition-api';
import shortId from 'shortid';

const { companyId } = useCurrentCompany();
const { addSuccess } = useNotifications();

let emit;
const companyRoleList = ref();

const getNewRole = () => ({
  title: '',
  isNew: true,
  id: shortId.generate(),
});

const addCompanyRole = () => {
  companyRoleList.value?.push(getNewRole());
};

const getCompanyRoles = () => {
  useApolloQuery(
    useGetCompanyRolesQuery,
    { companyId: companyId.value },
    // @ts-ignore, ignoring type error bc of pick
    { data: companyRoleList },
    {
      placeholderPick: ({ getCoreCompany: res }) =>
        res.companyRoles
          .filter((r) => !r.deletedAt)
          .sort((a, b) => {
            if (b.workerRoles.length === a.workerRoles.length) {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            }

            return b.workerRoles.length - a.workerRoles.length;
          }),
    }
  );
};

const createCompanyRole = (role, tempId) => {
  const { mutate, onDone } = useApolloMutation(useCreateCompanyRolesMutation);

  mutate({
    data: {
      companyId: companyId.value,
      titles: [role.title],
    },
  });

  onDone(({ createCompanyRoles: res }) => {
    if (res) {
      const tempRole = companyRoleList.value.find((r) => r.id === tempId);
      tempRole.isNew = false;
      Object.assign(tempRole, res[0]);

      addSuccess(`Role "${role.title}" has been created`);
      emit('updated');
    }
  });
};

const updateCompanyRole = ({ id, title }) => {
  const { mutate, onDone } = useApolloMutation(useUpdateCompanyRolesMutation);

  mutate({
    data: {
      companyId: companyId.value,
      roles: [
        {
          id,
          title,
        },
      ],
    },
  });

  onDone(({ updateCompanyRoles: res }) => {
    if (res) {
      // Refetch from cache
      getCompanyRoles();
      addSuccess(`Role "${title}" has been updated`);
      emit('updated');
    }
  });
};

const saveRole = (role, title, errorRef: Ref<string>) => {
  if (companyRoleList.value.some((r) => r.title.trim() === title.trim())) {
    errorRef.value = `Role "${title}" already exists.`;

    return;
  }

  const updatedRole = {
    ...(role.isNew ? {} : role),
    title,
  };

  if (role.isNew) {
    createCompanyRole(updatedRole, role.id);

    return;
  }

  updateCompanyRole(updatedRole);

  // Save role here, will be done in BAMBO-2978
  console.log('save', updatedRole);
};

const deleteRole = (role) => {
  const { mutate, onDone } = useApolloMutation(useDeleteCompanyRolesMutation);

  mutate({
    data: {
      companyId: companyId.value,
      roleIds: [role.id],
    },
  });

  onDone((res) => {
    if (res) {
      getCompanyRoles();
      addSuccess(`Role "${role.title}" has been deleted`);
      emit('updated');
    }
  });
  // Delete role here, will be done in BAMBO-2978
  console.log('delete', role.id);
};

const setup = () => {
  const unwatch = watch(
    companyId,
    () => {
      getCompanyRoles();

      if (unwatch) {
        unwatch();
      }
    },
    { immediate: true }
  );
};

const useManageCompanyRoles = (instEmit?) => {
  if (instEmit) {
    emit = instEmit;
    setup();
  }

  return {
    companyRoleList,
    addCompanyRole,
    saveRole,
    deleteRole,
  };
};

export default useManageCompanyRoles;
