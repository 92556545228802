import { Practice } from '@/gql/services/company.interface';
import {
  mutateBulkMessageStaff,
  mutateCompleteCompanyPractice,
  mutateCompleteSurvey,
  mutateSaveAnswer,
  watchGetHrScore,
  watchGetPractice,
  watchGetSurvey,
  watchPracticeList,
} from '@/gql/services/company.service';
import { asyncFactory, observableFactory } from '@/hooks/useGraphQlApi';
import { AsyncFactoryResponse } from '@/hooks/useGraphQlApi/wrapperFactories';
import {
  BulkMessageStaff,
  MessageGroupType,
  MessageType,
} from '@/modules/StaffManagement/models';
import { Survey } from '@@/types/survey';

interface GetPractice {
  companyId: string;
  practiceId: string;
}

interface GetSurvey {
  companyId: string;
  type: string;
}

const listPractice = (companyId: string, placeholder) => {
  return observableFactory(watchPracticeList, companyId, placeholder);
};

const getPractice = ({ companyId, practiceId }: GetPractice, placeholder) => {
  return observableFactory(
    watchGetPractice,
    {
      companyId,
      practiceId,
    },
    placeholder
  );
};

const getHrScore = (companyId: string, placeholder) => {
  return observableFactory(watchGetHrScore, companyId, placeholder);
};

const getSurvey = ({ companyId, type }: GetSurvey, placeholder) => {
  return observableFactory(
    watchGetSurvey,
    {
      companyId,
      type,
    },
    placeholder
  );
};

interface SaveSurveyAnswerPayload {
  companyId: string;
  key: string;
  surveyId: Survey['id'];
  value: string;
}

const saveSurveyAnswer = (
  { companyId, surveyId, key, value }: SaveSurveyAnswerPayload,
  placeholder?: AsyncFactoryResponse
) => {
  return asyncFactory(
    mutateSaveAnswer,
    {
      companyId,
      surveyId,
      key,
      value,
    },
    placeholder
  );
};

interface BulkMessageStaffPayload {
  messageBody: string;
  messageGroupType: MessageGroupType;
  messageType: MessageType;
  pageSource: string;
  staff: BulkMessageStaff[];
}
const bulkMessageStaff = (
  {
    messageBody,
    messageGroupType,
    messageType,
    pageSource,
    staff,
  }: BulkMessageStaffPayload,
  placeholder?
) => {
  return asyncFactory(
    mutateBulkMessageStaff,
    {
      messageBody,
      messageGroupType,
      messageType,
      pageSource,
      staff,
    },
    placeholder
  );
};

interface CompleteCompanySurveyPayload {
  companyId: string;
  surveyId: Survey['id'];
}
const completeCompanySurvey = (
  { companyId, surveyId }: CompleteCompanySurveyPayload,
  placeholder?: AsyncFactoryResponse
) => {
  return asyncFactory(
    mutateCompleteSurvey,
    {
      companyId,
      surveyId,
    },
    placeholder
  );
};

interface CompleteCompanyPracticePayload {
  companyId: string;
  practiceId: Practice['id'];
}

const completeCompanyPractice = (
  { companyId, practiceId }: CompleteCompanyPracticePayload,
  placeholder?
) => {
  return asyncFactory(
    mutateCompleteCompanyPractice,
    {
      companyId,
      practiceId,
    },
    placeholder
  );
};

const useCompanyService = () => ({
  bulkMessageStaff,
  listPractice,
  getPractice,
  getHrScore,
  getSurvey,
  saveSurveyAnswer,
  completeCompanySurvey,
  completeCompanyPractice,
});

export default useCompanyService;
