









import { defineComponent, ref, watch } from '@nuxtjs/composition-api';

// how long to wait before fading text back to it's original color
const ANIMATION_DELAY = 1000;

export default defineComponent({
  name: 'FlashingText',
  props: {
    displayText: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const showAnimation = ref(false);
    watch(
      () => props.displayText,
      () => {
        showAnimation.value = true;
        setTimeout(() => {
          showAnimation.value = false;
        }, ANIMATION_DELAY);
      }
    );

    return {
      showAnimation,
    };
  },
});
