import { IField, IFieldBase, IValidator } from '@flatfile/v2-shims';

export const employeeFields = [
  {
    label: 'Regular Hours',
    key: 'regularHours',
  },
  {
    label: 'Overtime Hours',
    key: 'overtimeHours',
  },
  {
    label: 'Double Overtime Hours',
    key: 'doubleOvertimeHours',
  },
  {
    label: 'Salary Hours',
    key: 'salaryHours',
  },
  {
    label: 'Paycheck Tips',
    key: 'paycheckTips',
  },
  {
    label: 'Cash Tips',
    key: 'cashTips',
  },
  {
    label: 'PTO Hours',
    key: 'ptoHours',
  },
  {
    label: 'Sick Hours',
    key: 'sickHours',
  },
  {
    label: 'Holiday Hours (1x)',
    key: 'holiday1x',
  },
  {
    label: 'Holiday Hours (1.5x)',
    key: 'holiday1point5x',
  },
  {
    label: 'Regular Non-Hourly',
    key: 'nonHourlyRegular',
  },
  {
    label: 'Bonus Amount',
    key: 'bonusAmount',
  },
  {
    label: 'Commission Amount',
    key: 'commissionAmount',
  },
  {
    label: 'Reimbursement Amount',
    key: 'reimbursementAmount',
  },
  {
    label: 'Severance Amount',
    key: 'severanceAmount',
  },
];

export const contractorFields = [
  {
    label: 'Contractor Payments (Hours)',
    key: 'contractorHours',
  },
  {
    label: 'Contractor Payments (Dollars)',
    key: 'contractorDollars',
  },
  {
    label: 'Contractor Reimbursement Amount',
    key: 'contractorReimbursement',
  },
];

export const nonProfitFields = [
  {
    label: 'Allowances',
    key: 'allowances',
  },
  {
    label: 'Reported Allowances',
    key: 'reportedAllowances',
  },
];

export const religiousNonProfitFields = [
  {
    label: 'Clergy Housing',
    key: 'clergyHousing',
  },
  {
    label: 'Reported Clergy Housing',
    key: 'reportedClergyHousing',
  },
];

export const numericalFields = [
  ...employeeFields,
  ...contractorFields,
  ...nonProfitFields,
  ...religiousNonProfitFields,
].map((f) => ({
  ...f,
  validators: [
    {
      validate: 'regex_matches',
      regex: '^(?=.+)(?:[1-9]\\d*|0)?(?:\\.\\d+)?$',
      error: 'Must be a positive numerical value',
    } as IValidator,
  ],
  type: 'string' as IFieldBase['type'],
}));

export const allFields: IField[] = [
  {
    label: 'First Name',
    key: 'firstName',
    validators: [{ validate: 'required' } as IValidator],
    type: 'string' as IFieldBase['type'],
  },
  {
    label: 'Last Name',
    key: 'lastName',
    validators: [{ validate: 'required' } as IValidator],
    type: 'string' as IFieldBase['type'],
  },
].concat(numericalFields);
