import { useGetGeneralPayrollCompanyQuery, PaymentType } from '@/gql/generated';
import type { Total, CoreCompany, Workplace } from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';

import { ref, computed } from '@nuxtjs/composition-api';
import useCurrentCompany from '@/hooks/useCurrentCompany';

let init;

const isLoading = ref(false);
const payrollCompany = ref();
const coreCompany = ref();
const workplaces = ref<Workplace[]>([]);

const setupHook = (force) => {
  const { companyId } = useCurrentCompany();
  init = true;
  const { onResult } = useApolloQuery(
    useGetGeneralPayrollCompanyQuery,
    { companyId: companyId.value },
    { pending: isLoading },
    { force }
  );

  onResult((result) => {
    payrollCompany.value = result?.getCompany;
    coreCompany.value = result?.coreCompany;
    workplaces.value = result?.listWorkplace as Workplace[];
  });
};

const isPayrollCompany = computed<boolean>(() => !!payrollCompany.value);

const staffOnboardingTotals = computed<Total>(
  () => payrollCompany.value?.total || {}
);

const getWorkerCount = (
  company: CoreCompany,
  key: string,
  payType?: PaymentType
) =>
  company?.employees?.filter(
    (i) => i?.[key]?.checkId && (!payType || i?.[key]?.payType === payType)
  )?.length || 1;

const hourlyPayrollWorkerCount = computed(() =>
  getWorkerCount(coreCompany.value, 'employee', PaymentType.Hourly)
);

const salaryPayrollWorkerCount = computed(() =>
  getWorkerCount(coreCompany.value, 'employee', PaymentType.Salary)
);

const contractPayrollWorkerCount = computed(() =>
  getWorkerCount(coreCompany.value, 'contractor')
);

const workplaceCount = computed(() => workplaces.value?.length || 0);

const usePayrollCompany = (force = false) => {
  if (!init || force) {
    setupHook(force);
  }

  return {
    payrollCompany,
    staffOnboardingTotals,
    isLoading,
    isPayrollCompany,
    hourlyPayrollWorkerCount,
    contractPayrollWorkerCount,
    salaryPayrollWorkerCount,
    workplaceCount,
  };
};

export default usePayrollCompany;
