

































import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import { TypeBody, BaseButton } from '@bambeehr/pollen';
import WizardInfoLayout from '@/modules/BambeePlus/components/WizardInfoLayout.vue';
import bam from '@/lib/bam';
import { trainingPerEmployee } from '@/modules/BambeePlus/hooks/useBambeePlusPricing';

const itemDefault = {
  isComplete: false,
  bullet: '&check;',
  shouldPulse: false,
  variant: 'primary',
};

const generalFeaturesList = [
  {
    ...itemDefault,
    text: 'Easily choose from 600+ available training courses',
  },
  {
    ...itemDefault,
    text: 'Interactive Training Experience',
  },
  {
    ...itemDefault,
    text: 'Custom Training Programs',
  },
  {
    ...itemDefault,
    text: 'Track Training Progress and Completion',
  },
];

const teamRoute = '/my-team';

export default defineComponent({
  name: 'TrainingUpsell',
  components: {
    TypeBody,
    BaseButton,
    WizardInfoLayout,
  },
  emits: ['browse-training'],
  setup(_, { emit }) {
    const router = useRouter();

    const handleBrowseTraining = (location) => {
      bam.track(`training_overview`, {
        action: 'training-upsell-browse-clicked',
        location,
      });
      emit('browse-training');
    };

    const handleChatWithExpert = () => {
      bam.track(`training_overview`, {
        action: 'training-upsell-chat-clicked',
      });
      router.push(teamRoute);
    };

    bam.track('training_overview', {
      action: 'training-upsell-viewed',
    });

    return {
      trainingPerEmployee,
      generalFeaturesList,
      handleBrowseTraining,
      handleChatWithExpert,
    };
  },
});
