import { ref } from '@nuxtjs/composition-api';

const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const useCreditCardFailedModal = () => {
  return {
    openModal,
    isModalOpen,
    closeModal,
  };
};

export default useCreditCardFailedModal;
