

















import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { TypeOverline } from '@bambeehr/pollen';

export default defineComponent({
  name: 'CallOutCard',
  components: {
    TypeOverline,
  },
  props: {
    variant: {
      type: String as PropType<'primary' | 'neutral'>,
      default: 'neutral',
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const variantClasses = {
      primary: 'bg-primary-default text-white ',
      neutral: 'bg-base-400 text-base-800',
    };

    const variantClass = computed(() => variantClasses[props.variant]);

    return {
      variantClass,
    };
  },
});
