<template>
  <portal v-if="active" to="layout">
    <b-modal
      :active="active"
      :can-cancel="canCancel"
      class="bam-modal"
      :class="{ 'is-fullpage': fullPage }"
      width="auto"
      @update:active="$emit('update:active', $event)"
      @close="$emit('close', $event)"
    >
      <slot />
    </b-modal>
  </portal>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    canCancel: {
      type: Boolean,
      default: true
    },
    fullPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
//@import '../../../styles/app/variables';
@import '~assets/css/shared.scss';

.bam-modal {
  z-index: 30;
}

.bam-modal ::v-deep .modal-content {
  margin-top: 1rem;
  overflow-y: auto;
  width: auto;
}

@media screen and (max-width: 768px) {
  .bam-modal.is-fullpage {
    position: absolute;
  }

  .bam-modal.is-fullpage ::v-deep .modal-content {
    bottom: 0;
    height: 100vh;
    left: 0;
    margin: 0;
    max-height: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
  }

  .bam-modal.is-fullpage ::v-deep .modal-close {
    right: 0;
    top: 0;
  }

  .bam-modal.is-fullpage ::v-deep .modal-close:before,
  .bam-modal.is-fullpage ::v-deep .modal-close:after {
    background-color: $black-grey-4;
  }
}

@media screen and (max-height: 768px) {
  .bam-modal.is-fullpage {
    position: absolute;
  }

  .bam-modal.is-fullpage ::v-deep .modal-content {
    bottom: 0;
    height: 100vh;
    left: 0;
    margin: 0;
    max-height: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
  }

  .bam-modal.is-fullpage ::v-deep .modal-close {
    right: 0;
    top: 0;
  }

  .bam-modal.is-fullpage ::v-deep .modal-close:before,
  .bam-modal.is-fullpage ::v-deep .modal-close:after {
    background-color: $black-grey-4;
  }
}

@media screen and (min-width: 769px) {
  .bam-modal.is-fullpage ::v-deep .animation-content.modal-content {
    width: auto;
  }
}
</style>
