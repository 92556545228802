<template>
  <TermsModal
    v-if="!hideModal"
    :updated-date="updatedDate"
    :header="header"
    :agree-label="agreeLabel"
    :show-close="showClose"
    @close="closeModal"
    @submit="submitTermsAgreement"
  >
    <div class="text-left" v-html="body"></div>
  </TermsModal>
</template>
<script>
import { ref, useRouter, useStore } from '@nuxtjs/composition-api';
import format from 'date-fns/format';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import TermsModal from '@/components/TermsModal/TermsModal.vue';
import Contentful from '@/services/Contentful';
import ContentfulEntryIDs from '@/lib/globals/ContentfulEntryIDs';
import useNotifications from '@bambeehr/use-notifications';
import bam from '@/lib/bam';
import { useUpdateCompanyMutation } from '@/gql/generated';
import { useApolloMutation } from '@/gql/apolloWrapper';
import useCompanyStore from '@/store/companies';

export default {
  name: 'PayrollTOS',
  components: { TermsModal },
  props: {
    closeOnSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { companyId, currentUser } = store.getters;

    const { mutate: updateCompany } = useApolloMutation(
      useUpdateCompanyMutation
    );

    const updatedDate = ref('');
    const header = ref('');
    const body = ref('');
    const version = ref('');
    const agreeLabel = ref('');

    (async () => {
      const content = await Contentful.getEntry(
        ContentfulEntryIDs.PAYROLL_SERVICE_AGREEMENT
      );

      updatedDate.value = format(
        new Date(content.fields.updatedDate),
        'MMMM dd, yyyy'
      );
      header.value = content.fields.header;
      body.value = documentToHtmlString(content.fields.body);
      version.value = content.fields.version;
      agreeLabel.value = content.fields.agreeCheckText;
    })();

    function sendSegmentEvent() {
      const { username, email } = currentUser._auth;
      bam.track('payroll_beta_terms_agreed', {
        username,
        email,
        fullName: currentUser.profile.full_name,
        companyName: currentUser._company.name,
        dba: currentUser._company?.profile?.dba,
        timestamp: new Date(),
      });
    }

    function sendSalesforceTask() {
      useCompanyStore().signPayrollTOS(companyId);
    }

    // Closing this beta terms modal will take them to task-center (home)
    function closeModal(agreedToTOS) {
      if (agreedToTOS) {
        sendSalesforceTask();
        sendSegmentEvent();
      }

      if (props.closeOnSubmit) {
        if (props.showClose || agreedToTOS) {
          emit('hide-modal', agreedToTOS);
        }

        return;
      }

      router.push('/');
    }

    function submitTermsAgreement() {
      const { add } = useNotifications();

      add({
        id: 'PayrollTOS',
        message: `You've agreed to the Terms and Conditions`,
      });

      sendSegmentEvent();
      updateCompany({
        data: {
          tosVersion: version.value,
          id: companyId,
        },
      });

      closeModal(version.value);
    }

    return {
      updatedDate,
      header,
      body,
      agreeLabel,
      closeModal,
      submitTermsAgreement,
    };
  },
};
</script>
