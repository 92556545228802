export enum VerificationStatus {
  PROCESSING = 'processing',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
  FINAL_REJECTED = 'final_rejected',
}

export const checkIsInvalid = (status: VerificationStatus): boolean =>
  [VerificationStatus.REJECTED, VerificationStatus.FINAL_REJECTED].includes(
    status
  );

export default checkIsInvalid;
