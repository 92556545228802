














import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { wizardActionState } from '@/modules/payroll/pages/employer/onboarding/OnboardingRoot.vue';
import ExpectationSetter from '@/components/ExpectationSetter';
import {
  ExpectationCopyId,
  fetchContent,
  formatMediaInfo,
} from '@/modules/OnboardingWizard/utils/copy';
import { TypeDisplay } from '@bambeehr/pollen';

function setWizardActionState() {
  wizardActionState.value = {
    complete: {
      label: 'Continue',
      action: (complete) => complete(),
    },
    disableNext: false,
  };
}

export default defineComponent({
  name: 'Confirmation',
  components: {
    TypeDisplay,
    ExpectationSetter,
  },
  setup() {
    setWizardActionState();

    const content = ref();

    (async () => {
      content.value = await fetchContent(
        ExpectationCopyId.PAYROLL_ONBOARDING_CONFIRMATION
      );
    })();

    const media = computed(() => formatMediaInfo(content.value));

    return {
      content,
      media,
    };
  },
});
