

























import { defineComponent } from '@nuxtjs/composition-api';
import {
  BaseButton,
  BaseCard,
  ModalDialog,
  TypeBody,
  TypeDisplay,
} from '@bambeehr/pollen';
import HrPracticePreview from '@/modules/OnLoginTasks/components/images/HrPracticePreview.vue';
import {
  EmitCloseCurrentModal,
  EmitSendTo,
  PreviewModalComponents,
} from '@/modules/OnLoginTasks/constants';
import HrComplianceRoutes, {
  HR_COMPLIANCE_DASHBOARD,
} from '@/modules/HrCompliance/constants/HrComplianceRoutes';

export default defineComponent({
  name: 'HrPracticePreviewModal',
  components: {
    BaseButton,
    BaseCard,
    HrPracticePreview,
    ModalDialog,
    TypeBody,
    TypeDisplay,
  },
  emits: [EmitCloseCurrentModal, EmitSendTo],
  setup(_, { emit }) {
    const toggleModalOff = () => {
      // Passing the Component Name of the Modal being closed out
      emit(
        EmitCloseCurrentModal,
        PreviewModalComponents.HR_PRACTICE_PREVIEW_MODAL
      );
    };

    const handleRedirect = () => {
      emit(EmitSendTo, HrComplianceRoutes.HR_COMPLIANCE_WIZARD);
      toggleModalOff();
    };

    const handleLearnMore = () => {
      emit(EmitSendTo, HR_COMPLIANCE_DASHBOARD);
      toggleModalOff();
    };

    return {
      handleRedirect,
      handleLearnMore,
    };
  },
});
