
























































































import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'MailTruck',
  setup() {
    const isVisible = ref(false);

    // Timeout allows nuxt transition to finish so the transition will show
    setTimeout(() => {
      isVisible.value = true;
    }, 300);

    return {
      isVisible,
    };
  },
});
