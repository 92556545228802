






import { defineComponent } from '@nuxtjs/composition-api';
import SquareTag from '@/components/SquareTag/SquareTag.vue';

export default defineComponent({
  name: 'CatalogRecommendedTag',
  components: {
    SquareTag,
  },
});
