import {
  computed,
  readonly,
  ref,
  Ref,
  useContext,
  useStore,
} from '@nuxtjs/composition-api';

let isInitialized;
let context;
let store;
const isReady = ref(false);
let currentFetchingFn;
const employees: Ref<null | any[]> = ref(null);

const activeEmployees = computed(() => {
  return employees.value?.filter((ee) => !!ee.active);
});

// All active W2 employees
const w2 = computed(() => {
  return activeEmployees.value?.filter((ee) => !ee.profile?.contractor) || [];
});

const inactiveEmployees = computed(() => {
  return employees.value?.filter((ee) => !ee.active);
});

// All active contractors
const contractors = computed(() => {
  return activeEmployees.value?.filter((ee) => !!ee.profile?.contractor) || [];
});

// All active hourly employees
const hourly = computed(() => {
  return (
    activeEmployees.value?.filter((ee) => ee.profile?.type === 'hourly') || []
  );
});

// All active salaried employees
const salaried = computed(() => {
  return (
    activeEmployees.value?.filter((ee) => ee.profile?.type === 'salary') || []
  );
});

// All active hourly W2 employees
const hourlyW2 = computed(() => {
  return w2.value.filter((ee) => ee.profile?.type === 'hourly') || [];
});

// All active managers
const managers = computed(() => {
  return activeEmployees.value?.filter((ee) => !!ee.permissions?.manager) || [];
});

const fetchEmployees = async (force: boolean = false): Promise<any> => {
  // If we already have employees cached, return the cache unless explicitly asked to force a new fetch
  if (!force && employees.value) {
    return employees.value;
  }

  // If there is already an active fetch happening, return that promise. No need to kick off a new network call
  if (currentFetchingFn) {
    return currentFetchingFn;
  }

  const { $axios } = context;

  // Create and set a promise representing the fetch that's about to happen
  currentFetchingFn = async () => {
    const { data } = await $axios.get(
      '/v0/app/my/employees?role=all&self=true'
    );

    return data;
  };

  const employeesRes = await currentFetchingFn();

  currentFetchingFn = null;

  if (employeesRes) {
    employees.value = employeesRes;

    store.commit('users/setUsers', { users: employeesRes }); // Until we move off of using the store, commit so that the store stays up to date
  }

  return employeesRes;
};

const setupHook = async (): Promise<void> => {
  isInitialized = true;
  context = useContext();
  await fetchEmployees();
  isReady.value = true;
};

// this will eventually replace the useEmployees hook
// useEmployees hook is being used in a number of places
// next step will be to update all of those places to use this hook
const useEmployees = () => {
  if (!isInitialized) {
    store = useStore();
    setupHook();
  }

  return {
    isReady,
    fetchEmployees,
    activeEmployees,
    active: activeEmployees,
    contractors,
    hourly,
    hourlyW2,
    salaried,
    managers,
    w2,
    all: readonly(employees),
    inactive: inactiveEmployees,
    employees,
  };
};

export default useEmployees;
