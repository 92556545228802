
















































































import { computed } from '@nuxtjs/composition-api';
import HexNum from '@/components/utility/HexNum/hex-num.vue';
import { TypeDisplay, BaseDivider, TypeBody, BaseCard } from '@bambeehr/pollen';
import MediumCheck from './imgs/MediumCheck';

const getTaskNumber = (index: string): number => Number(index) + 1;

export default {
  components: {
    BaseDivider,
    BaseCard,
    HexNum,
    MediumCheck,
    TypeBody,
    TypeDisplay,
  },
  inheritAttrs: false,
  props: {
    media: {
      type: Object,
      default: () => ({}),
    },
    headline: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    nextList: {
      type: Array,
      default: () => [],
    },
    prepList: {
      type: Array,
      default: () => [],
    },
  },
  setup: (props) => {
    const formattedNextList = computed(() =>
      Object.entries(props.nextList as Object[])
    );

    return {
      getTaskNumber,
      formattedNextList,
    };
  },
};
