import Vue from 'vue';

function create(data) {
  const {
    _id,
    _employee,
    _created_by,
    type,
    text,
    attachments,
    flag,
    log_date,
    created_at,
    updated_at,
  } = data;

  return {
    _id,
    _employee,
    _created_by,
    flag,
    text,
    attachments,
    type,
    log_date,
    created_at,
    updated_at,
  };
}

function set(state, payload) {
  const item = create(payload);

  if (!state._byId[item._id]) {
    state.ids.push(item._id);
  }

  Vue.set(state._byId, item._id, item);

  return item;
}

export const state = () => ({
  ids: [],
  _byId: {},
});

export const mutations = {
  set(state, payload) {
    set(state, payload);
  },

  setAll(state, payload) {
    state.ids = [];
    state._byId = {};

    payload.forEach((i) => set(state, i));
  },

  remove(state, payload) {
    const { _id } = payload;
    state.ids = state.ids.filter((i) => i != _id);
    Vue.delete(state._byId, _id);
  },
};

export const getters = {
  all(state) {
    return state.ids.map((i) => state._byId[i]);
  },
  get(state) {
    return function (id) {
      return state._byId[id];
    };
  },
};

export const actions = {
  async getEmployee({ commit }, employeeId) {
    return await this.$axios
      .get(`/v0/app/user/${employeeId}/notes`)
      .then((res) => {
        commit('setAll', res.data);

        return res.data;
      });
  },

  async create(
    { commit },
    { employeeId, text, type, logDate, attachments, flag = false }
  ) {
    return await this.$axios
      .post(`/v0/app/user/${employeeId}/note/create`, {
        text,
        type,
        flag,
        attachments,
        log_date: logDate,
      })
      .then((res) => {
        commit('set', res.data);
      });
  },

  async update(
    { commit },
    { employeeId, noteId, text, type, logDate, attachments, flag }
  ) {
    return await this.$axios
      .post(`/v0/app/user/${employeeId}/note/${noteId}/update`, {
        text,
        type,
        flag,
        attachments,
        log_date: logDate,
      })
      .then((res) => {
        commit('set', res.data);
      });
  },

  async delete({ commit }, { employeeId, noteId }) {
    return await this.$axios
      .post(`/v0/app/user/${employeeId}/note/${noteId}/delete`)
      .then((res) => {
        commit('remove', res.data);
      });
  },
};
