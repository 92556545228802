

































import { defineComponent, useRoute, computed } from '@nuxtjs/composition-api';
import EmployerTrainingDetailHeader from './EmployerTrainingDetailHeader';
import EmployerTrainingDetailList from './EmployerTrainingDetailList';
import EmployerTrainingDetailOverview from './EmployerTrainingDetailOverview';
import useEmployerTrainingDetails from './useEmployerTrainingDetail';
import TrainingEnrollmentModal from '@/modules/Training/components/TrainingEnrollmentModal';
import useEmployees from '@/hooks/useEmployees';
import useCurrentUser from '@/hooks/useCurrentUser';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal';
import { AnnularThrobber } from '@bambeehr/pollen';
import BreadCrumbs from '@/components/BreadCrumbs';

const baseCrumbs = [
  {
    label: 'Training',
    to: '/training',
  },
  {
    label: 'Company Courses',
    to: '/training/company',
  },
];

export default defineComponent({
  components: {
    EmployerTrainingDetailHeader,
    EmployerTrainingDetailList,
    EmployerTrainingDetailOverview,
    TrainingEnrollmentModal,
    AnnularThrobber,
    LoadingModal,
    BreadCrumbs,
  },
  setup() {
    const route = useRoute();
    const { id } = route.value.params;
    const { w2, contractors } = useEmployees();
    const { currentUser } = useCurrentUser();

    const {
      currentContent,
      currentUserList,
      showEnrollmentModal,
      topicId,
      bundleId,
      toggleEnrollmentModal,
      isLoading,
      isUnenrolling,
      unenrollingName,
    } = useEmployerTrainingDetails(id, w2, contractors, currentUser);

    const crumbs = computed(() => [
      ...baseCrumbs,
      {
        label: currentContent.value?.name,
      },
    ]);

    return {
      id,
      currentContent,
      currentUserList,
      showEnrollmentModal,
      topicId,
      bundleId,
      toggleEnrollmentModal,
      isLoading,
      isUnenrolling,
      unenrollingName,
      crumbs,
    };
  },
});
