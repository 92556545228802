



































import {
  ref,
  computed,
  defineComponent,
  useRoute,
  useRouter,
} from '@nuxtjs/composition-api';
import useCurrentUser from '@/hooks/useCurrentUser';
import BreadCrumbs from '@/components/BreadCrumbs';
import CourseCard from '@/modules/Training/components/CourseCard/CourseCard';
import { TypeDisplay, AnnularThrobber } from '@bambeehr/pollen';
import useTrainingCatalog from '@/modules/Training/hooks/useTrainingCatalog';
import TrainingEnrollmentModal from '@/modules/Training/components/TrainingEnrollmentModal';
import bam from '@/lib/bam';
import useEmployeeTrainingRequests from '@/modules/Training/hooks/useEmployeeTrainingRequests';
import { CoreRequest, RequestStatus } from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useNotifications from '@bambeehr/use-notifications';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import useBambeeUpsell from '@/hooks/useBambeeUpsell/useBambeeUpsell';

export interface ModalInfo {
  bundleId: string;
  topicId: string;
  title: string;
  selectedIds: string[];
}

const getCrumbs = (courseName: string, isOwnerAdmin: boolean) => [
  {
    label: 'Training',
    to: '/training',
  },
  {
    label: 'Browse',
    to: `/${isOwnerAdmin ? 'training' : 'my-training'}/catalog`,
  },
  {
    label: courseName,
  },
];

let handlePurchase;
let handleRequest;

const getDisplayInfo = (
  topicId: string,
  isOwnerAdmin: boolean,
  hasRequested: boolean,
  hasPurchased: boolean
) => {
  const router = useRouter();

  if (isOwnerAdmin) {
    if (hasPurchased) {
      return {
        button: {
          label: 'View Course Progress',
          disabled: false,
          onClick: () => {
            router.push(`/training/${topicId}`);
          },
        },
        postText: 'You have purchased this course.',
      };
    }

    return {
      button: {
        label: 'Enroll Staff',
        disabled: false,
        onClick: () => {
          handlePurchase?.();
        },
      },
      postText: '',
    };
  }
  if (hasRequested) {
    return {
      button: {
        label: 'Requested',
        disabled: true,
        onClick: () => {},
      },
      postText: `We've notified your employer of your interest in this content.`,
    };
  }

  return {
    button: {
      label: 'Request',
      disabled: false,
      onClick: () => {
        handleRequest?.();
      },
    },
    postText: `We'll notify your employer of your interest in this content.`,
  };
};

const getHasRequested = (
  topicId: string,
  isOwnerAdmin: boolean,
  myRequests: CoreRequest[]
) => {
  if (isOwnerAdmin) {
    return false;
  }

  return myRequests.some(
    (r) =>
      r.resourceId === topicId &&
      [RequestStatus.Pending, RequestStatus.Approved].includes(r.status)
  );
};

export default defineComponent({
  name: 'CourseInfo',
  components: {
    TypeDisplay,
    BreadCrumbs,
    CourseCard,
    AnnularThrobber,
    TrainingEnrollmentModal,
  },
  setup() {
    const route = useRoute();
    const { isOwnerAdmin } = useCurrentUser();
    const topicId = route.value.params.course;
    const { getTopicInfo, isReady } = useTrainingCatalog();
    const { myRequests, makeRequest, refreshUserRequests } =
      useEmployeeTrainingRequests();

    const courseInfo = computed(() => getTopicInfo(topicId));

    const crumbs = computed(() =>
      getCrumbs(courseInfo.value?.name || '', isOwnerAdmin.value)
    );

    const hasPurchased = computed(() => courseInfo.value?.isPurchased);

    const hasRequested = computed(() =>
      getHasRequested(topicId, isOwnerAdmin.value, myRequests.value)
    );

    const buttonAndPostText = computed(() =>
      getDisplayInfo(
        topicId,
        isOwnerAdmin.value,
        hasRequested.value,
        hasPurchased.value
      )
    );

    const showPurchaseModal = ref(false);
    const purchaseModalInfo = ref({} as ModalInfo);
    const triggerPurchaseModal = (
      isBundle,
      content,
      selectedIds: string[] = []
    ) => {
      showPurchaseModal.value = true;
      purchaseModalInfo.value = {
        bundleId: isBundle ? content.id : '',
        topicId: isBundle ? '' : content.id,
        title: content.name,
        selectedIds,
      };

      bam.track('course_catalog_opened_purchase_modal', {
        content: purchaseModalInfo.value,
      });
    };

    handlePurchase = () => {
      const { isBambeeLite } = usePlanAccess();
      const { togglePlanAction } = useBambeeUpsell();

      if (isBambeeLite.value) {
        togglePlanAction(true, undefined, 'training');
      } else {
        triggerPurchaseModal(false, {
          id: topicId,
          name: courseInfo.value?.name,
        });
      }
    };

    handleRequest = () => {
      const { addSuccess } = useNotifications();
      const { companyId } = useCurrentCompany();
      const { currentUserId } = useCurrentUser();
      const { onDone } = makeRequest({
        topicId,
        companyId: companyId.value,
        userId: currentUserId.value,
      });

      onDone(() => {
        refreshUserRequests(true);
        addSuccess(
          `A request for "${courseInfo.value.name}" has been sent to your admin.`
        );
      });
    };

    return {
      crumbs,
      courseInfo,
      hasRequested,
      isReady,
      purchaseModalInfo,
      showPurchaseModal,
      triggerPurchaseModal,
      buttonAndPostText,
    };
  },
});
