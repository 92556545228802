
























































import {
  ref,
  computed,
  useRoute,
  useRouter,
  defineComponent,
  useStore,
  watch,
} from '@nuxtjs/composition-api';
import WizardFlow, {
  ProgressStep,
} from '@/components/WizardFlow/WizardFlow.vue';
import WizardFlowFooter from '@/components/WizardFlow/WizardFlowFooter.vue';
import OnboardingWizardActions from '@/modules/payroll/components/OnboardingWizardActions/OnboardingWizardActions.vue';
import employerPayrollOnboardingGuard, {
  Routes,
} from '@/modules/payroll/middleware/employerPayrollOnboardingGuard';
import {
  BaseButton,
  BaseDivider,
  ModalDialog,
  TypeBody,
  TypeDisplay,
} from '@bambeehr/pollen';
import isEmpty from 'lodash/isEmpty';

import { hrRequestFullSlug } from '@/modules/HrRequest/constants/RequestTypesData';
import { HrRequestGroupKey, HrRequestSlug } from '@/gql/generated';

interface WizardAction {
  label: string;
  action: Function;
}

export interface WizardActionMap {
  next?: WizardAction;
  finishLater?: WizardAction;
  back?: WizardAction;
  complete?: WizardAction;
  skip?: WizardAction;
  disableNext?: boolean;
  loadingNext?: boolean;
}

export const wizardActionState = ref<WizardActionMap>({});

export const steps: Array<ProgressStep> = [
  {
    label: 'Company Info',
    value: Routes.COMPANY_INFO,
  },
  {
    label: 'Locations',
    value: Routes.LOCATIONS,
  },
  {
    label: 'Pay Schedule',
    value: Routes.PAY_SCHEDULE,
  },
  {
    label: 'Bank & Tax Info',
    value: Routes.BANK_TAX,
  },
];

export default defineComponent({
  name: 'EmployerPayrollOnboardingRoot',
  components: {
    BaseButton,
    BaseDivider,
    ModalDialog,
    OnboardingWizardActions,
    TypeBody,
    TypeDisplay,
    WizardFlow,
    WizardFlowFooter,
  },
  middleware: [employerPayrollOnboardingGuard],
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const slug = hrRequestFullSlug({
      groupKey: HrRequestGroupKey.Support,
      slug: HrRequestSlug.AdminSupport,
    });

    const { companyId } = store.getters;
    const signatoryName = ref('');
    const showHelpModal = ref(false);

    const wizardMapFactory = (key: string): WizardAction | null => {
      const state = wizardActionState.value;

      return isEmpty(state[key]) ? null : <WizardAction>state[key];
    };

    // Helpers to make template cleaner
    const wizardNext = computed(() => wizardMapFactory('next'));
    const wizardBack = computed(() => wizardMapFactory('back'));
    const wizardLater = computed(() => wizardMapFactory('finishLater'));
    const wizardComplete = computed(() => wizardMapFactory('complete'));
    const wizardSkip = computed(() => wizardMapFactory('skip'));

    const currentIndex = computed<number>(() => {
      return steps.findIndex((s) => route.value.fullPath.includes(s.value));
    });
    const currentOption = computed<ProgressStep | undefined>(() => {
      return currentIndex.value > -1 ? steps[currentIndex.value] : undefined;
    });

    function nextPage(url?: string) {
      const thisURL = url || steps[currentIndex.value + 1]?.value;

      if (thisURL) {
        router.push(thisURL);
      }
    }

    function backPage(url?: string) {
      router.push(url || steps[currentIndex.value - 1].value);
    }

    function finishLater(url?: string) {
      router.push(url || '/dashboard');
    }

    function complete(url?: string) {
      router.push(url || '/dashboard');
    }

    function setSignatoryName(name: string) {
      signatoryName.value = name;
    }

    const OnboardingHelpComponent = ref();
    const getOnboardingHelp = () =>
      showHelpModal.value && import('./OnboardingHelp');
    watch(showHelpModal, async (shouldShow: boolean) => {
      if (shouldShow && !OnboardingHelpComponent.value) {
        const component = await getOnboardingHelp();

        OnboardingHelpComponent.value = component.default;
      }
    });

    function toggleHelpModal() {
      showHelpModal.value = !showHelpModal.value;
    }

    return {
      backPage,
      companyId,
      complete,
      currentOption,
      finishLater,
      nextPage,
      OnboardingHelpComponent,
      setSignatoryName,
      showHelpModal,
      signatoryName,
      slug,
      steps,
      toggleHelpModal,
      wizardActionState,
      wizardBack,
      wizardComplete,
      wizardLater,
      wizardNext,
      wizardSkip,
    };
  },
  layout: 'wizard',
});
