import { CompanyBenefitOnboardStatus } from '@/gql/generated';
import { getCompanyStatus } from '@/gql/services/payroll.service';

export enum Routes {
  // Overview is our marketing landing page
  OVERVIEW = '/benefits',
  MANAGE = '/benefits/manage',
  CONNECT = '/benefits/connect',
  SHOP = '/benefits/shop',
}

const completedSteps = [
  CompanyBenefitOnboardStatus.Completed,
  CompanyBenefitOnboardStatus.PendingEmployeeEnrollment,
  CompanyBenefitOnboardStatus.FinishedEmployeeEnrollment,
  CompanyBenefitOnboardStatus.BorCompleted,
  CompanyBenefitOnboardStatus.BorSubmitted,
  CompanyBenefitOnboardStatus.Submitted,
];

const validateCompanyStatus = async (store) => {
  const { companyId } = store.getters;

  // State is cached via apollo-client
  const { data: company } = await getCompanyStatus(companyId, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const canAccessBenefits = !!company;
  const hasBenefits = completedSteps.includes(
    company?.benefitOnboarding?.status
  );

  return {
    canAccessBenefits,
    hasBenefits,
  };
};

export default async ({ redirect, store, route }) => {
  const { currentUser } = store.getters;
  if (!currentUser) {
    return;
  }

  const { canAccessBenefits, hasBenefits } = await validateCompanyStatus(store);

  if (!canAccessBenefits) {
    redirect('/');
  }

  // Customer is already benefits customer, direct them to manage page
  if (hasBenefits && route.path === Routes.OVERVIEW) {
    redirect(Routes.MANAGE);
  }
};
