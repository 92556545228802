const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const convertBytesToUnits = (bytes: number, decimals: number = 2): string => {
  if (!bytes) {
    return `${bytes} ${units[0]}`;
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${units[i]}`;
};

export default convertBytesToUnits;
