
































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { TypeBody, BaseCard } from '@bambeehr/pollen';

export default defineComponent({
  name: 'FaqItem',
  components: {
    TypeBody,
    BaseCard,
  },
  props: {
    img: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      // Optional as this can be a slot
      default: '',
    },
    description: {
      type: String,
      // Optional as this can be a slot
      default: '',
    },
  },
  setup() {
    const isOpen = ref(false);

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      toggleOpen,
    };
  },
});
