import FeatureFlags from '@/constants/FeatureFlags';
import { getLaunchDarklyFlag } from '@/utils/launchDarklyFlags';

const hasBulkStaffMessagingFlag = async () => {
  return !!(await getLaunchDarklyFlag(
    FeatureFlags.ENABLE_BULK_STAFF_MESSAGING
  ));
};

export default hasBulkStaffMessagingFlag;
