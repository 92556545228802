



































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { BaseButton, TypeBody, BaseTag, BaseCard } from '@bambeehr/pollen';
import RadialChart from '@/modules/Training/components/RadialChart';

export default defineComponent({
  components: {
    BaseButton,
    TypeBody,
    BaseTag,
    RadialChart,
    BaseCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    status: {
      type: String as PropType<string>,
      required: true,
    },
    assigned: {
      type: String as PropType<string>,
      required: true,
    },
    // Mapped helpers
    isBundle: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isInProgress: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isCompleted: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isNotStarted: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
});
