import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import bam from '@/lib/bam';
import ScheduleOnboardingCall from '@/modules/TaskCenter/components/TierZeroGroup/ScheduleOnboardingCall.vue';
import {
  Milestone,
  Milestones,
  MilestoneStatuses,
  MilestoneTags,
  TierZeroMilestones,
} from '@/modules/TaskCenter/types/Milestones';
import { BaseButton } from '@bambeehr/pollen';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';
import {
  computed,
  reactive,
  readonly,
  ref,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import useCompanyStore from '@/store/companies';

let initialized: boolean | undefined;
let router: ReturnType<typeof useRouter> | undefined;

const trackEvent = (description: string) => {
  bam.track('button-click', {
    description,
  });
};

const tierZeroMilestones = ref<Milestone[]>([]);
const welcomeCallMilestone = ref<Milestone>();

const completedMilestones = computed<Milestones[]>(
  () =>
    tierZeroMilestones.value
      .filter((m) => m.status === MilestoneStatuses.COMPLETED)
      .map((m) => m.milestone) || []
);

const isMilestoneComplete = (
  milestone: typeof TierZeroMilestones[keyof typeof TierZeroMilestones],
  completedMilestones: Array<Milestones | TierZeroMilestones>
) => {
  return completedMilestones?.includes(milestone) || false;
};

const isReviewBusinessStructureComplete = computed(() =>
  isMilestoneComplete(
    TierZeroMilestones.REVIEW_BUSINESS_STRUCTURE,
    completedMilestones.value
  )
);

const isReviewStaffTypeComplete = computed(() =>
  isMilestoneComplete(
    TierZeroMilestones.REVIEW_STAFF_TYPE,
    completedMilestones.value
  )
);

const isReviewEmployeeClassificationComplete = computed(() =>
  isMilestoneComplete(
    TierZeroMilestones.REVIEW_EMPLOYEE_CLASSIFICATION,
    completedMilestones.value
  )
);

const isReviewPoliciesComplete = computed(() =>
  isMilestoneComplete(
    TierZeroMilestones.REVIEW_POLICIES,
    completedMilestones.value
  )
);

const isSetupJobDescriptionsComplete = computed(() =>
  isMilestoneComplete(
    TierZeroMilestones.SETUP_JOB_DESCRIPTIONS,
    completedMilestones.value
  )
);

const isSetupOfferLetterComplete = computed(() =>
  isMilestoneComplete(
    TierZeroMilestones.SETUP_OFFER_LETTER,
    completedMilestones.value
  )
);

const isTakeTrainingComplete = computed(() =>
  isMilestoneComplete(
    TierZeroMilestones.TAKE_TRAINING,
    completedMilestones.value
  )
);

const isWelcomeCallMilestoneComplete = computed(
  () => !!welcomeCallMilestone.value?.status
);

const incompleteTasksCount = computed(() => {
  if (!tierZeroMilestones.value.length) {
    return 0;
  }

  const completedTasks = [
    ...completedMilestones.value,
    isWelcomeCallMilestoneComplete.value,
  ];

  const completeCount = completedTasks.length;
  const total = Object.keys(TierZeroMilestones).length;

  return total - completeCount;
});

const fetchMilestones = () => {
  tierZeroMilestones.value =
    useCompanyStore().onboardingMilestonesByTag(MilestoneTags.TIER_ZERO) || [];

  welcomeCallMilestone.value = useCompanyStore()
    .onboardingMilestonesByTag(MilestoneTags.INTRO)
    ?.find((m) => m.milestone === Milestones.WELCOME_CALL);
};

const TaskPaths = {
  POLICY: '/policy',
  JOB_DESCRIPTION: '/requests/hiring-onboarding-job-description',
  OFFER_LETTER: '/requests/hiring-onboarding-pre-employment',
  TRAINING: '/training/company',
  MY_REQUESTS: '/requests?tab=MyRequest',
} as const;

const dataQaPrefix = 'tier-zero-task';

const initialTasks = reactive([
  {
    id: '1',
    parentId: '',
    title: 'Take care of your regulatory obligations',
    description:
      'It is important to ensure compliance with laws and regulations, avoid fines and penalties, and maintain a good reputation. These obligations can be complex and time-consuming, but fortunately, Bambee is handling the following for you.',
    component: ScheduleOnboardingCall,
    props: {
      beforeComplete: {},
      afterComplete: {},
    },
    completed: false,
  },
  {
    id: '2',
    parentId: '1',
    title: 'Review your business structure',
    milestone: TierZeroMilestones.REVIEW_BUSINESS_STRUCTURE,
    description:
      'Your business formation type will affect your taxes, your ability to raise money, and the protection of your personal assets. Modifying your business structure can be challenging once you’re established and open for business, so getting it right from the start is important. ',
    completed: false,
  },
  {
    id: '3',
    parentId: '1',
    title: 'Plan your staff (1099 vs W2)',
    milestone: TierZeroMilestones.REVIEW_STAFF_TYPE,
    description:
      'The US Department of Labor requires every employer covered by the Fair Labor Standards Act (FLSA) must keep certain records for each covered, nonexempt worker. Bambee’s Cabinet lets you store employee records',
    completed: false,
  },
  {
    id: '4',
    parentId: '1',
    title: 'Classify your employees (exempt vs non-exempt)',
    milestone: TierZeroMilestones.REVIEW_EMPLOYEE_CLASSIFICATION,
    description:
      'The US Department of Labor governs employee exemption from certain protections offered by the Fair Labor Standards Act (FLSA), including minimum wage and overtime pay. A vast majority of lawsuits against small businesses come from wage & hour and classification disputes, but the rules aren’t always simple to understand. Your advisor will guide you through making the best choice for your business. ',
    completed: false,
  },
  {
    id: '5',
    parentId: '',
    title: 'Establish your company culture',
    description: 'Description',
    completed: true,
  },
  {
    id: '6',
    parentId: '5',
    title: 'Review your Core Protective Document',
    milestone: TierZeroMilestones.REVIEW_POLICIES,
    description:
      'These are the essential policies that set expectations of conduct, establish culture, and protect your business from the risk of ambiguity. Having clear written policies, signed and dated by your employee, lays the groundwork to compliant HR. ',
    completed: false,
    component: BaseButton,
    props: {
      beforeComplete: {
        'data-qa': `${dataQaPrefix}-review-policies`,
        class: 'h-full self-center',
        label: 'Review',
        onClick: async () => {
          trackEvent('Clicked to review Core Protective Documents');
          router?.push(TaskPaths.POLICY);
        },
      },
      afterComplete: {
        'data-qa': `${dataQaPrefix}-view-policies`,
        class: 'h-full self-center',
        label: 'view',
        variant: 'white',
        onClick: async () => {
          trackEvent('Clicked to view Core Protective Documents');
          router?.push(TaskPaths.POLICY);

          fetchMilestones();
        },
      },
    },
  },
  {
    id: '7',
    parentId: '',
    title: 'Make your first hire',
    completed: true,
  },
  {
    id: '8',
    parentId: '7',
    title: 'Define your job description',
    milestone: TierZeroMilestones.SETUP_JOB_DESCRIPTIONS,
    description:
      'Job descriptions (JD) are not mandated by law, but they are a crucial tool for making the right hire and getting the most out of them. We believe that every employee and business benefits from a well-crafted job description - it’s the most straightforward way to communicate expectations and evaluate employee performance. Hit “start” to request your first JD, and we’ll take care of the rest. ',
    completed: false,
    component: BaseButton,
    props: {
      beforeComplete: {
        'data-qa': `${dataQaPrefix}-request-job-description`,
        class: 'h-full self-center',
        label: 'Request',
        onClick: async () => {
          trackEvent('Clicked to request job description');
          router?.push(TaskPaths.JOB_DESCRIPTION);
        },
      },
      afterComplete: {
        'data-qa': `${dataQaPrefix}-view-job-description`,
        class: 'h-full self-center',
        label: 'View Job Descriptions',
        variant: 'white',
        onClick: async () => {
          trackEvent('Clicked to view job description request');
          router?.push(TaskPaths.MY_REQUESTS);
        },
      },
    },
  },
  {
    id: '9',
    parentId: '7',
    title: 'Prepare for the interview',
    milestone: TierZeroMilestones.TAKE_TRAINING,
    description:
      'Interviewing can be daunting for both candidates and employers. For example, certain questions are illegal to ask, yet asking enough of the right questions is key to making the right hire, especially in certain niche industries. We’ve hand-selected a few training videos that will prepare you for that first interview, and eventually your first hire! ',
    completed: false,
    component: BaseButton,
    props: {
      beforeComplete: {
        'data-qa': `${dataQaPrefix}-start-training`,
        class: 'h-full self-center',
        label: 'Start Training',
        onClick: async () => {
          trackEvent('Clicked to start training');
          router?.push(TaskPaths.TRAINING);

          await useCompanyStore().updateOnboardingMilestone({
            milestone: TierZeroMilestones.TAKE_TRAINING,
            completed: true,
          });

          fetchMilestones();
        },
      },
      afterComplete: {
        'data-qa': `${dataQaPrefix}-review-training`,
        class: 'h-full self-center',
        label: 'Review Training',
        variant: 'white',
        onClick: () => {
          trackEvent('Clicked to review training');
          router?.push(TaskPaths.TRAINING);
        },
      },
    },
  },
  {
    id: '10',
    parentId: '7',
    title: 'Send an offer letter',
    milestone: TierZeroMilestones.SETUP_OFFER_LETTER,
    description:
      'Once you’ve identified a candidate you want to hire, don’t wait. Candidates may be sitting on other offers or actively interviewing with other companies. Start your request for Bambee to help you craft your offer letter and send it out in a timely manner.',
    completed: false,
    component: BaseButton,
    props: {
      beforeComplete: {
        'data-qa': `${dataQaPrefix}-request-offer-letter`,
        class: 'h-full self-center',
        label: 'Request',
        onClick: () => {
          trackEvent('Clicked to request offer letter');
          router?.push(TaskPaths.OFFER_LETTER);
        },
      },
      afterComplete: {
        'data-qa': `${dataQaPrefix}-view-offer-letter`,
        class: 'h-full self-center',
        label: 'View Offer Letter',
        variant: 'white',
        onClick: () => {
          trackEvent('Clicked to view offer letter request');
          router?.push(TaskPaths.MY_REQUESTS);
        },
      },
    },
  },
]);

const isEveryChildComplete = (tasks, parentId) => {
  return tasks
    .filter((task) => task.parentId === parentId)
    .reduce((result, child) => {
      if (!child.completed) {
        return false; // Child is incomplete
      }

      // Recursively check if the child's children are complete
      return result && isEveryChildComplete(tasks, child.id);
    }, true);
};

let isT0;

watch(
  completedMilestones,
  (milestones) => {
    initialTasks.forEach((task, i) => {
      if (isMilestoneComplete(task.milestone, milestones)) {
        // update reactive variable when milestone is complete
        task.completed = true;

        // if the current plan is not a tier zero we will not show the milestone at all in the Task Card
        if (!isT0) {
          initialTasks[i] = {};

          // if every child is complete we will not show the parent
          if (isEveryChildComplete(initialTasks, task.parentId)) {
            const parentIndex = initialTasks.findIndex(
              (t) => t.id === task.parentId
            );

            if (parentIndex !== -1) {
              // Remove the parent task from the array
              initialTasks.splice(parentIndex, 1);
            }
          }
        }
      }
    });
  },
  {
    immediate: true,
  }
);

const tasks = computed(() =>
  initialTasks.filter((value) => Object.keys(value).length !== 0)
);

const isAllTasksComplete = computed(() =>
  tasks.value.every((task) => task.completed)
);

const setupHook = () => {
  router = useRouter();
  const { isTierZero } = useCurrentPlan();

  isT0 = isTierZero.value;

  fetchMilestones();

  initialized = true;
};

const useTierZeroTasks = () => {
  if (!initialized) {
    setupHook();
  }

  return {
    tasks,
    completedMilestones: readonly(completedMilestones),
    isAllTasksComplete,
    isReviewBusinessStructureComplete,
    isReviewStaffTypeComplete,
    isReviewEmployeeClassificationComplete,
    isReviewPoliciesComplete,
    isSetupJobDescriptionsComplete,
    isSetupOfferLetterComplete,
    isWelcomeCallMilestoneComplete,
    tierZeroMilestones,
    isTakeTrainingComplete,
    incompleteTasksCount,
    fetchMilestones,
  };
};

export default useTierZeroTasks;
