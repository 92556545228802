

































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { BaseCard, AnnularThrobber, TypeBody } from '@bambeehr/pollen';
import { ProductKey } from '@/gql/generated';

import useProductSelector from './useProductSelector';
import ProductDetails from './ProductDetails';
import ProductCheckout from './ProductCheckout';
import useConfetti from '@/hooks/useConfetti/useConfetti';

export default defineComponent({
  name: 'PlanSelector',
  components: {
    ProductDetails,
    ProductCheckout,
    BaseCard,
    AnnularThrobber,
    TypeBody,
  },
  props: {
    product: {
      type: String as PropType<ProductKey>,
      required: true,
    },
    isSubscription: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  emits: ['subscribed', 'banner-clicked', 'option-update', 'next'],
  setup(props, { emit, root }) {
    const { isLoading, onSubscribed, currentCopy } = useProductSelector(
      props.product,
      props.isSubscription
    );
    // @ts-ignore
    const { celebrate } = useConfetti(root.$confetti);

    onSubscribed.value = () => {
      emit('subscribed');
      setTimeout(() => {
        celebrate();
      }, 400);
    };

    return {
      isLoading,
      currentCopy,
    };
  },
});
