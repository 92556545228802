import { DateFormat } from '@/modules/payroll/constants/payroll';
import { DefaultPayScheduleDay } from '@/modules/payroll/types/company';
import format from 'date-fns/format';

export const formatPayScheduleDays = (
  payScheduleDays: DefaultPayScheduleDay[],
  allPayScheduleDays: DefaultPayScheduleDay[]
) => {
  const days = allPayScheduleDays?.length
    ? allPayScheduleDays
    : payScheduleDays;

  return days
    ? days.map((p) => [
        format(new Date(p.periodStart), DateFormat.MM_DD_YYYY),
        format(new Date(p.periodEnd), DateFormat.MM_DD_YYYY),
        format(new Date(p.approvalDeadline), DateFormat.WD_MM_DD_YYYY),
        format(new Date(p.payday), DateFormat.WD_MM_DD_YYYY),
      ])
    : [];
};

export const formatPaySchedule = (paySchedule) => {
  return paySchedule
    ? {
        ...paySchedule,
        firstPeriodEnd: format(
          new Date(paySchedule.firstPeriodEnd),
          DateFormat.MM_DD_YYYY
        ),
        firstPayday: format(
          new Date(paySchedule.firstPayday),
          DateFormat.MM_DD_YYYY
        ),
      }
    : false;
};
