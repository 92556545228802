import redirectToOnboardingWizardMiddleware from '@/middleware/redirectToOnboardingWizard';
import redirectToNewEmployerOnboarding from '@/modules/Onboarding/middleware/redirectToNewEmployerOnboarding';
import hasStartedNewOnboarding from '@/helpers/accessControl/hasStartedNewOnboarding';
import hasCompletedSomeMilestones from '@/helpers/accessControl/hasCompletedSomeMilestones';
import useMiddlewareContext from './hooks/useMiddlewareContext';
import useCompanyStore from '@/store/companies';

// TODO move to a separate file
const newOnboardingBlackList: string[] = [
  '6477714bb3df703e44a73174',
  '65cb8ed5cbce6d2be93bfc35',
  '646820c326262e065f86680f',
  '65ba6be9f25ba83e09648ed7',
  '5f6131f91833dddd068442a9',
  '60c7f5ab34bb9b2c91ec15ca',
  '6421867773e59fbc76ad5d5b',
  '636fdec8e6f0bf3d437a1a35',
  '61cb598630344e88ed16cbee',
  '62c30e80b15536f80ee0aa7a',
  '645d4a328d50e417e129f495',
  '62b466aed9db31849301bf32',
  '63486a0780647f54c0ddc4d4',
  '6331df291b33197529078155',
  '60e77bb69808f47ba4abc749',
  '6050bb69db0e643f4705e7e7',
  '5e2a41b7ba7d810012e53d4d',
  '63093a3616f03f18b0b48c15',
  '63d07e0bb91b10944bcbc219',
  '5e260551e5c8c10027c648d5',
  '63136fd28dc30f2d16219b1c',
  '6445581fa3b18bffb9770ea0',
  '63543946e9862376702923da',
  '63d9720227ee57cd6ddef137',
  '63e63d742ed444b068467f9d',
  '6307d364b420051dd4ea69a2',
  '62ffdb90d284bd57e7e974bb',
  '6587ab8b2d927741c0cedc42',
  '609bdbcd5b8919221381fc69',
  '631b57c47610254ddcb09ee1',
  '6463e91b42dae63836d00a3b',
  '62a79b8d17afcf56e1c0cfd1',
  '63f5361a4e45bf4e68184c53',
  '5d9c2ca9d67da90027ce4d3e',
  '62a8cf58b6db671ff6cabe02',
  '5e2b3762ba7d810012ea62fd',
  '63211e1cf18f94f9e50fb3e1',
  '63d97ca0cff1958f355ef0e6',
  '630c9d817e6505672da1d5e3',
  '63fd28ef58d7581f1571a3c3',
  '61474b58c82922656afc9b8a',
  '6390d61c1bf114564c36ad62',
  '645e74a9d5d7ddd71bd9dc90',
  '62cb2e5fca9571c337262369',
  '63a25a4e3d06cdfe2d1d8b63',
  '64839d6cb9d9ac3f151d1de5',
  '6542b2dc393d546a62f3fdbf',
  '63347fed651a306d0c1f33f8',
  '62fe466b5c823468ab59717c',
  '64de629702740416735f1a34',
  '5fb7feef14dfc781cf1f4ed1',
  '61a6916e792ccb7109d96a65',
  '605e6fb6a08312c2aa089a93',
  '6468c9559230ec009ada0b36',
  '6455061304f6a938edf61742',
  '60f9e6e6a4d3dc7874134160',
  '60a1be61294ab944501863b9',
  '645943ea3fc58c763066b0e5',
  '64ff1a39648e924a480086be',
  '62f4306da126f21b945ddf36',
  '64639a9f4f533b8531cdb913',
  '5ed00681659f06001befa914',
  '6059143fd8caa1d4b2e682a5',
  '6357ebe6ef021a8e75118e2a',
  '6450922996796560196701c1',
  '61524f9bd288e05c12cfd518',
  '642b0e4c0da2f27c7a415180',
  '64dadef547781a46429a90f7',
  '63ff9b3903565a3bd1ac398f',
  '63aeffb9b8653d7e960c2cfb',
  '62f97c934448e2102216de16',
  '5cb9d9e9f82636002c28e331',
  '635be2d64aced4b324003773',
  '63f8e55ad0bef180231c79a7',
  '63b5e83d8c987f28edcfc354',
  '63aca5523d06cd3bf95f1e0e',
  '5cc720df5b7c11001e57e39d',
  '63c03ebd0681b0da95e5e1fc',
  '62ba3ed03b9aa97392b86c67',
  '5f54e50d7b03103fb49b43d6',
  '64833fd6c9fbadd61bee02dc',
  '63b523c922dba6cb8760ed15',
  '60be30958502739e437fa5bb',
  '5ec3552b1667e1001bc1d52f',
  '6240d16360a6c30642c5dfd0',
  '5f92e6b7ea0ef3dc024eaff5',
  '6376bb3cbb2ee53a00b03f65',
  '5efe8face0ed84244b2f072b',
  '63858577b974b75cd0b60db9',
  '649622d2026689b4887ac50b',
  '6393914aabeee7641dbf1ddf',
  '60edd271f3b72f1612dc3cf2',
  '640c59a134d63e8184311268',
  '64d416d966052d0029f72e97',
  '5abd1f71d28652003040aead',
  '62b4de823eefa1d501941fa5',
  '60c0b3ccd41f993f6cb5307e',
  '613a3b1892b8b25350a83fff',
  '5c58e2870ae95d0011aaeae9',
  '65e251f7d45b378f078b3f00',
  '6049022306c2bccdf1b586fc',
  '63d12fa8b91b105c19cf4dff',
  '5c9baf912ef1e20023b91694',
  '63ea7a1165cf402b467ee9ad',
  '5ca4c3fb1c39d0001d58156a',
  '637d69b3278fc41b8431da45',
  '64b172c2afc44804ac86577a',
  '603031b39e5f55f07ad64c5c',
  '631777e4d198da18febf4410',
  '6407a97b290b2528444c1224',
  '6373fff3bb2ee577208ec34a',
  '6430a87a21fe55a76e9eec49',
  '6408d2a864f61b206cb910ab',
  '63246308c1bfcb36f7bfcb57',
  '648b366891944a9f10198e36',
  '62cf0396bd0efa4b559de2d3',
  '65a7fba1bc3a9bea9224bfdc',
  '63f951410c209fc34953dc80',
  '62ffc0de90d53a829d655b2b',
  '5ee3b6a668359e001c3984d6',
  '640a25fba5d3c756c922d7d2',
  '6455c4e0c6a9346c72a5c564',
  '658da40a4c369244770b53e2',
  '632388011fffdc785fa93b30',
  '645fa2df0ae1a719dd5b58be',
  '624b0c8bcf88c456cece2505',
  '65205553b763c037c79bd15a',
  '606245967f5c9229783ed50a',
  '63dcfef284eac61d6521c6d7',
  '5f1095ba7de2ba4968d05c12',
  '603d0090870b4e12c2ecf9d7',
  '65fa07b4fbfccf2db3cf29b6',
  '6311fbccdcd9baec5b01b139',
  '647f5cfaae34de2ccef451c1',
  '6482612474fbd9cdb0e440c1',
  '65e8c8d3bcc493f44e6bbe23',
  '6434924fe49a22e22da1cf63',
  '6581e604a666cb7716a57930',
  '65bd1a944ace9e4e22ec1ad5',
  '6541456975c5646352a84003',
  '65652f90ba88d01c95421f7e',
  '64a6f50d18d5dc0c840c8972',
  '64a5c46d1d9dfa08e84d4a4b',
  '64370c695fb764cd4b400cb1',
  '6516dccbe5d107026bfa9c9f',
  '64b8194176ca602913c2f3c5',
  '64cbb68dd5a4b52c31141901',
  '64ca67e0a0cfcc0d9b21d9d8',
  '6363fbf48088be401e8088ce',
  '619bdb98eeebb407b915f955',
  '6499ea6f4e08904ed29cb715',
  '649d94529da7b563f6fb1f36',
  '65552fbe4d6f8ca8c356f1fd',
  '645bda95dad1987117b9620e',
  '653057fb3658978e1e0ee019',
  '65160788417b18628332d666',
  '65f0a3c7b8928a0abacecab4',
  '656e0e2ae5f118a790a64d02',
  '6490abf042e6ddfefe2a5cd5',
  '5dce1bd59d069900126bf5b7',
  '660db116b3a7db8069f1362c',
  '6419e0a59141d7aba63715fe',
  '65de640e8d7d1c0d32df3002',
  '63fd0356f7f216023819087b',
  '6411f8f1e7daacdfab041846',
  '62dbd537eb0f1861ba858392',
  '65a9420abf3de801e6f6a2cc',
  '630614c337c7d36e5b2f80ad',
  '658c4aa99ace26c99e656e40',
  '62d4a2b8302ab251adcbdc31',
  '62f2639d2cbf9923ef8bfc4a',
  '6255b377cf88c4299243835e',
  '60186dcabb37ef1f60279c9e',
  '6297b5441f5c0e355157393f',
  '606608458cfe3d897df17272',
  '644185b8a99900159ff19fa5',
  '64de3c559590d2fd5af4bea4',
  '645953afd6e303604986f19d',
  '6494b8e4f2d4ee69fc188c1f',
  '65a0539b7e532a18da95935c',
  '644808c5eadc723f6985c572',
  '64498cb50d7c02053289523a',
  '64da6b3e8ec4a732d6a259d7',
  '64bf587716d3d273c36a13cb',
  '65c14df2be18226fdd3f12d9',
  '64e54b3c985c218169bc3757',
  '65bbea0d2176ceb38ce01893',
  '60240ef16c377ff4b486d18d',
  '62765a9917eac2443b297d02',
  '6445a2cee6ac28cb7a7d7a30',
  '644abb6260de0e18e256febe',
  '64500382fa513da72524f8ed',
  '6400b4d6b9220383904d9935',
  '644297fe22ea0b74fc98c416',
  '64c821de58c87b4c6677da88',
  '6489c153de0c104bcf03441f',
  '63e53f0e08f71042715677ea',
  '64f1203990151678b46242ee',
  '65cbd1337dd807953d645d15',
  '64da75c68ec4a74316a3ae85',
  '65f10a1ea5c53f27268b4d29',
  '613196a54102854aa79050a9',
  '5fe995c0103f0c09d749b3fc',
  '6470c25793780eee767bccc9',
  '6312cce87be1f8db00a503d9',
  '6006dc647874083ded442146',
  '6524819c2ba3d96be4db54db',
  '65a18ac7fe1cdff328a07319',
  '610748c8037e3e38dad979f2',
  '654d00bbcce5514f3ffd6b7f',
  '6545370fc40f3e22533524bf',
  '65ab234ee6c9db8d98f6e7cb',
  '6595c2574f6d7d8d9ac25c63',
  '6307a6d137c7d371a2405a6f',
  '6542aca5740d9a2fd1140e3b',
  '6583a8a7a8ff5c9f73c1a6c2',
  '657a0e10dc60d5658ebf4c27',
  '6590583d83d2f3cbac92e371',
  '6564e6da0305ef8530caeea6',
  '6558ef1f4d49bbdacfbc7d4e',
  '6505c524ffa0e7dcb0b9ab57',
  '62d6dbf2302ab25b62e1fa95',
  '63f90edc38ea4f5f58d1ea7f',
  '6446bf1dd45a986695fdaf92',
  '5f57e361647d4f3fc7cb2d54',
  '6568c0b4df95bc217d522c08',
  '62fe8980fcb5ed29aef47d7f',
  '65dd235fc6822ebd3f2f7304',
  '62eaa2886baa237ba845ed2c',
  '6332d0e005c73d78f427e59b',
  '653fdeb646e0552f01f0f419',
  '6511d965cc58d99b2165ffa3',
  '659d8d6e34f1e10d0860c9ba',
  '648b748582b0422b3e781200',
  '64639f97888c7a9c2d74185a',
  '6432a7857c2f12262af8daa5',
  '649cb736f5a9cf667b874d4a',
  '64f79f5991b9e215ac81f2d7',
  '64f93318afb7967c9dee70ed',
  '65fdd28980be7f415f3eba31',
  '648e21926b540523e3753f01',
  '645564127b08ec195c64843f',
  '6508721f0d2a6672e9ee50cd',
  '65a044a6f1718b0f7d4f7a07',
  '64550d4c45322105fb56b0d9',
  '647e36de5f39f509094b7793',
  '65cf8d733cfc2913774f77e1',
  '6532cfde53f5d3200062b118',
  '642efa1a471e1b09820b3e4c',
  '6501c91df32c313aa8f74cff',
  '65f1e0ddc0f5655f77ae83f3',
  '65e4e398430cd3d8d6ada728',
  '65e0a4df36a38f9aac61870a',
  '6596cb5a5a81f05af8fdfa81',
  '64b49cc2e8f75e322f6404ea',
  '6437fbfa83e0625e0ce75e0b',
  '65e77e68b8be6d5d61d029dd',
  '5e68edaa24eaf00020b1eb41',
  '65ea0c42a589554428965552',
  '64c69d7ff0d33b31cbbdca1f',
  '6439e1d8ef46af61efa93d61',
  '656a1ddf9a82d8c88c00a447',
  '60abced5e2811b799e3adb4d',
  '656f3d9a1660af03b094ca5d',
  '656e3dd0bd8eb8674697e184',
  '64ecd830a054ff2847335717',
  '65131753c806b7ed2791ea22',
  '6580eee2cbbac11014468848',
  '64e914059b91b53fcdc57f05',
  '652d561ad4cc76223f3de07e',
  '65419328d2b4dfe60534c575',
  '62f278cdae102448a0400294',
  '65a565e2515945b537f1e102',
  '6196b8d7be268a36e3ef5f24',
  '62d585b8d03daf84e10108a3',
  '62d6ffac0088d97a278b30fd',
  '64f2614ee60adb94f71e89c1',
  '652edb17946555c1ef7ce228',
  '6545496a9dd196b109a50b06',
  '62013418c121d6a89f5b0d76',
  '656e481c3fe2c2a0c8706ce8',
  '62c9a383699bff7222e7e4cd',
  '642226b8b8acfc4cb0d6f851',
  '6500bfb2a5c91e0c67f28c46',
  '64cbb2f251070b5983b0d731',
  '65b40e9db65bf62ec8f8200c',
  '639b7751bcc6650b418f8b66',
  '64ac1e4a6d44d4635409a818',
  '6509dc739b078c671552d48d',
  '65d694b07087eaadbf5903e2',
  '62cced0f699bff3818fa8ea8',
  '65dd0eb2cc17fd38a0181d8d',
  '6582357bfc35d5c84d3bf4d0',
  '646fceebde85d835c4f9edc7',
  '65fe3ae5c49089c7b1540ed8',
  '6451352709b6ac111ea751f3',
  '64a5fab81007703ed4cd32f3',
  '66043387a0ce5349fc0fe034',
  '6363ca3d8735617d9e5f2bed',
  '64f0886a2828ad3af1e87553',
];

const handleOnboardingRouting = async () => {
  // @ts-ignore, ignoring store missing from type
  const { store, currentUser } = useMiddlewareContext();
  const { _company: company } = currentUser;

  const companyIsLead = company.status === 'lead';
  if (companyIsLead) {
    return;
  }

  // We only want to show this onboarding form to THE owner of the company.
  // Admins can be upgraded to owners / admins, but this causes them to see the onboarding flow.
  const isTheOwner = company._owner === currentUser._id;

  if (!isTheOwner) {
    return;
  }

  // Has the customer already began our 'legacy' onboarding process.
  await useCompanyStore().getOnboardingMilestones();
  const someMilestonesCompleted = hasCompletedSomeMilestones(store);

  // Has the customer started our new onboarding process.
  const hasOnboardingForm = await hasStartedNewOnboarding(currentUser._id);

  if (
    (!hasOnboardingForm && someMilestonesCompleted) ||
    newOnboardingBlackList.includes(company._id)
  ) {
    // If customer has either started our legacy onboarding or is blacklisted from it

    // redirectToOnboardingWizardMiddleware: handles the onboarding wizard flow based on milestone completeness
    // * if the current user is NOT activated then do NOT run the redirectToOnboardingWizardMiddleware
    // * if the current path is on the onboarding wizard path then do NOT run the redirectToOnboardingWizardMiddleware
    // * if the onboarding milestones does NOT exist then do NOT run the redirectToOnboardingWizardMiddleware

    // * if every milestone is complete then do NOT run the redirectToOnboardingWizardMiddleware

    // * if the current path includes "/beta-tos" or "/payroll-setup"
    // * and if every milestone is NOT complete
    // * and if current company has payroll
    // * then set the isOnboarding on the usePayrollOnboardingStatus hook to true

    // * otherwize redirect to the "/get-started" page
    await redirectToOnboardingWizardMiddleware();
  } else {
    // If customers have already begin the new onboarding process send them back to it.

    // redirectToNewEmployerOnboarding:
    // * if the current user has not completed the employer onboarding then redirect to the "/onboarding/employer" page
    // OR
    // If a new customer that doesn't match the above conditions, send them to the new onboarding process.

    await redirectToNewEmployerOnboarding();
  }
};

export default handleOnboardingRouting;
