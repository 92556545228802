













































import { defineComponent, ref } from '@nuxtjs/composition-api';
import useProductSelector from './useProductSelector';
import { TypeDisplay, TypeBody, BaseBanner, BaseLink } from '@bambeehr/pollen';
import CheckList from '@/components/CheckList/CheckList';

export default defineComponent({
  name: 'PlanDetails',
  components: {
    TypeDisplay,
    TypeBody,
    CheckList,
    BaseBanner,
    BaseLink,
  },
  props: {},
  emits: ['banner-clicked'],
  setup() {
    const { isLoading, currentCopy, productAllowance } = useProductSelector();

    // TODO Remove when query is working
    isLoading.value = false;

    return {
      isLoading,
      currentCopy,
      productAllowance,
    };
  },
});
