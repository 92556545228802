import cloneDeepWith from 'lodash/cloneDeepWith';

const omitDeep = <T>(collection: T, excludeKeys: string[]): T =>
  cloneDeepWith(collection, (value: any) => {
    if (value && typeof value === 'object') {
      excludeKeys.forEach((key) => {
        delete value[key];
      });
    }
  });

export default omitDeep;
