import Vue from 'vue';

export const state = () => ({
  reportCards: {},
  currentCardId: '',
  current: {},
  companyReportCards: [],
  employeeReviewGradeSheet: {},
});

export const getters = {
  byId: (state) => (cardId) => state.reportCards[cardId],
  currentCard: (state) => state.reportCards[state.currentCardId],
  companyReportCards: (state) => state.companyReportCards,
  employeeReport: (state) => (employeeId) =>
    state.employeeReviewGradeSheet[employeeId],
};

export const mutations = {
  setCurrent(state, reportCard) {
    Vue.set(state, 'current', reportCard);
    Vue.set(state.reportCards, reportCard._id, reportCard);

    const currentIndex = state.companyReportCards.findIndex(
      (card) => card._id === reportCard._id
    );
    if (currentIndex > -1) {
      state.companyReportCards = [
        ...state.companyReportCards.slice(0, currentIndex),
        reportCard,
        ...state.companyReportCards.slice(currentIndex + 1),
      ];
    }
  },
  setCurrentCardId(state, cardId) {
    state.currentCardId = cardId;
  },
  setCompanyReportCards(state, { reportCards }) {
    state.companyReportCards = reportCards;
  },
  setEmployeeReviewGradeSheet(state, { employeeId, reviewData }) {
    Vue.set(state.employeeReviewGradeSheet, employeeId, reviewData);
  },
};

export const actions = {
  create({ commit }, { company_id }) {
    return this.$axios
      .post('/v0/app/report-card/create', { company_id })
      .then((res) => {
        commit('setCurrent', res.data);

        return res.data;
      });
  },

  getByCompanyId({ commit }, { companyId }) {
    return this.$axios
      .get(`/v0/app/report-card/getBy/${companyId}`)
      .then((res) => {
        commit('setCompanyReportCards', {
          reportCards: res.data,
          companyId,
        });

        return res.data;
      });
  },

  getById({ commit }, { reportCard_id }) {
    return this.$axios
      .get(`/v0/app/report-card/byId/${reportCard_id}`)
      .then((res) => {
        commit('setCurrent', res.data);

        return res.data;
      });
  },

  saveReportCard({ commit }, { reportCard_id, payload }) {
    return this.$axios
      .post(`/v0/app/report-card/byId/${reportCard_id}/save`, { payload })
      .then((res) => {
        commit('setCurrent', res.data);

        return res.data;
      });
  },

  completeReportCard({ commit }, { reportCard_id }) {
    return this.$axios
      .post(`/v0/app/report-card/byId/${reportCard_id}/complete`)
      .then((res) => {
        commit('setCurrent', res.data);

        return res.data;
      });
  },

  getReportByEmployeeId({ commit }, { employeeId, companyId }) {
    return this.$axios
      .get(`/v0/app/report-card/getBy/${companyId}/byEmployeeId/${employeeId}`)
      .then((res) => {
        commit('setEmployeeReviewGradeSheet', {
          employeeId,
          reviewData: res.data,
        });

        return res.data;
      });
  },
};
