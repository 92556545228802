











import { defineComponent } from '@nuxtjs/composition-api';
import BenefitsMarketingCard from '@/modules/Benefits/components/BenefitsMarketingCard';
import { useApolloMutation } from '@/gql/apolloWrapper';

import { useTrackProductInterestMutation } from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';

export default defineComponent({
  name: 'GetBenefits',
  components: {
    BenefitsMarketingCard,
  },
  setup() {
    const { companyId } = useCurrentCompany();
    const { mutate: trackInterest } = useApolloMutation(
      useTrackProductInterestMutation,
      {},
      // Silent fail
      { handleErrors: false }
    );

    trackInterest({
      data: {
        companyId: companyId.value,
        interests: {
          benefits: 'Interested',
        },
        origin: '/get-benefits',
      },
    });
  },
});
