












































import {
  BaseCard,
  CheckboxInput,
  TypeBody,
  UserAvatar,
} from '@bambeehr/pollen';
import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'StaffCard',
  components: {
    BaseCard,
    CheckboxInput,
    TypeBody,
    UserAvatar,
  },
  props: {
    avatarUrl: {
      type: String as PropType<string>,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    jobTitle: {
      type: String as PropType<string>,
      default: '',
    },
    selectable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    selectionDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    selected: {
      type: Boolean as PropType<boolean | null>,
      default: false,
    },
    staffName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(_, { emit }) {
    const handleSelectionToggle = () => {
      emit('toggle-selection');
    };

    return {
      handleSelectionToggle,
    };
  },
});
