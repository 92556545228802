


























































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { BaseButton, TypeBody, BaseTag, BaseCard } from '@bambeehr/pollen';
import RadialChart from '@/modules/Training/components/RadialChart';

export const getChartInfo = (completedSeats, totalSeats) => ({
  backgroundColor: [
    // secondary
    '#16A7A7',
    // base-400
    '#E0E0E0',
  ],
  // If the course has no seats, we shouldn't show fully completed.
  data: [completedSeats, totalSeats ? totalSeats - completedSeats : 1],
});

export default defineComponent({
  components: {
    BaseButton,
    TypeBody,
    BaseTag,
    RadialChart,
    BaseCard,
  },
  props: {
    bundleId: {
      type: String,
      default: '',
    },
    topicId: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    completedSeats: {
      type: Number as PropType<number>,
      default: 0,
    },
    totalSeats: {
      type: Number as PropType<number>,
      required: true,
    },
    isBundle: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props) {
    const percentCompleted = computed(
      () =>
        `${
          props.totalSeats
            ? ((props.completedSeats / props.totalSeats) * 100).toFixed()
            : 0
        }%`
    );

    const chartInfo = computed(() =>
      getChartInfo(props.completedSeats, props.totalSeats)
    );

    return {
      percentCompleted,
      chartInfo,
    };
  },
});
