export const HR_COMPLIANCE_DASHBOARD = Object.freeze({
  path: '/hr-compliance',
  label: 'HR Compliance',
});

const ROOT_PATH = HR_COMPLIANCE_DASHBOARD.path;

const HrComplianceRoutes = Object.freeze({
  HR_COMPLIANCE_DASHBOARD: ROOT_PATH,
  HR_COMPLIANCE_WIZARD: `${ROOT_PATH}/wizard`,
  HR_AUDIT: `${ROOT_PATH}/audit`,
  BULK_ADD_STAFF: '/employee/bulk-add',
  ADD_STAFF: '/employee',
  EMPLOYEE_VOICES: `${ROOT_PATH}/wizard`,
  REPORT_CARDS: `${ROOT_PATH}/report-cards`,
});

export default HrComplianceRoutes;
