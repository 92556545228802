





































import useAddStaffFormState from '../hooks/useAddStaffFormState';
import useAddStaffForm from '../hooks/useAddStaffForm';
import { defineComponent } from '@nuxtjs/composition-api';
import { TextInput, PhoneInput } from '@bambeehr/pollen';
import AddStaffFormGroup from './AddStaffFormGroup';

export default defineComponent({
  components: {
    TextInput,
    PhoneInput,
    AddStaffFormGroup,
  },
  setup() {
    const { v$, formState, isInvalid } = useAddStaffFormState();
    const { emailLabel, phoneLabel } = useAddStaffForm();

    return {
      formState,
      isInvalid,
      v$,
      emailLabel,
      phoneLabel,
    };
  },
});
