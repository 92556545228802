import useCurrentUser from '@/hooks/useCurrentUser';
import { UserRoles } from '@/constants/users';

const hasAdminPermissions = () => {
  const { currentUser } = useCurrentUser();

  return [UserRoles.ADMIN, UserRoles.USER].includes(
    currentUser.value?.role || ''
  );
};

export default hasAdminPermissions;
