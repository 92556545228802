import Contentful from '@/services/Contentful';

let content: any;

const fetchContent = async (entryId: string) => {
  const response = await Contentful.getEntry(entryId);
  content = response.fields;

  return content;
};

export default fetchContent;
