


















































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { AvatarImage, BaseTag, TypeBody } from '@bambeehr/pollen';
import useTrainingUser from '@/modules/Training/hooks/useTrainingUser';

export default defineComponent({
  name: 'UserInfo',
  components: {
    AvatarImage,
    TypeBody,
    BaseTag,
  },
  props: {
    id: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const { getUser } = useTrainingUser();
    const {
      exists,
      avatarUrl,
      firstName,
      lastName,
      role,
      isManager,
      initials,
    } = getUser(props.id);

    return {
      exists,
      avatarUrl,
      firstName,
      lastName,
      role,
      isManager,
      initials,
    };
  },
});
