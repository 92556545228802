







































import ContentfulRichText from '@/components/contentful/ContentfulRichText';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, ModalDialog } from '@bambeehr/pollen';
import useServiceUpdates from '@/hooks/useServiceUpdates/useServiceUpdates';

export default defineComponent({
  name: 'ServiceUpdateModal',
  components: {
    ContentfulRichText,
    TypeBody,
    ModalDialog,
  },
  setup() {
    const { isModalOpen, handleModalClose, serviceUpdateContent } =
      useServiceUpdates();

    const actions = computed(() => ({
      primary: {
        label: 'Ok, Got It',
        handler: () => handleModalClose(),
        class: 'mr-8 mb-6',
      },
    }));

    return {
      serviceUpdateContent,
      actions,
      isModalOpen,
      handleModalClose,
    };
  },
});
