

















import { BaseDivider, BaseIcon, PopoverMenu } from '@bambeehr/pollen';
import { defineComponent, PropType } from '@nuxtjs/composition-api';

export type MenuAlignment = 'left' | 'right';

export interface MenuItems {
  label: string;
  handler: any;
  icon?: string;
}

export default defineComponent({
  name: 'DotMenuPopout',
  components: {
    BaseDivider,
    BaseIcon,
    PopoverMenu,
  },
  props: {
    alignment: {
      type: String as PropType<MenuAlignment>,
      default: 'left',
    },
    items: {
      type: Array as PropType<MenuItems[]>,
      required: true,
    },
  },
});
