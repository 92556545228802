

















import { BaseIcon, BaseTag, ToolTip } from '@bambeehr/pollen';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { formatDate } from '@/utils/date';

export enum QuickbooksSyncStatus {
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export interface QuickbooksSyncAttempt {
  attemptedAt?: string;
  createdAt: string;
  id: string;
  status: QuickbooksSyncStatus;
  failureReason?: string;
}

export default defineComponent({
  components: {
    BaseIcon,
    BaseTag,
    ToolTip,
  },
  props: {
    size: {
      type: String,
      required: false,
      default: 'small',
    },
    syncAttempt: {
      type: Object as () => QuickbooksSyncAttempt,
      required: true,
    },
    mainText: {
      type: String,
      required: false,
      default: 'Quickbooks',
    },
  },
  setup(props) {
    const tag = computed(() => {
      switch (props.syncAttempt?.status) {
        case QuickbooksSyncStatus.PENDING:
          return {
            id: props.syncAttempt.id,
            icon: 'arrowCircle',
            color: 'gray',
            text: 'Payroll syncing with QuickBooks Online',
          };
        case QuickbooksSyncStatus.SUCCESS:
          return {
            id: props.syncAttempt.id,
            icon: 'check',
            color: 'caribbean',
            text: `Payroll synced with QuickBooks Online ${
              props.syncAttempt.attemptedAt
                ? `on ${formatDate(props.syncAttempt.attemptedAt!, null)}`
                : ''
            }`,
          };
        case QuickbooksSyncStatus.FAILURE:
          return {
            id: props.syncAttempt.id,
            icon: 'alertTriangle',
            color: 'rose',
            text: 'Failed to sync with QuickBooks Online, contact the Payroll Support Team for assistance',
          };
        default:
          return null;
      }
    });

    return {
      tag,
    };
  },
});
