// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';
import { computed, useStore, ref, Ref } from '@nuxtjs/composition-api';
import useCurrentCompany from '../useCurrentCompany';
import IPlan from '@@/types/plans';

let initialized;
let company;
let isTrial: Ref<boolean>;
const plans: Ref<IPlan[]> = ref([] as IPlan[]);

const isReady: Ref<boolean> = ref(false);

const currentPlan = computed<IPlan | undefined>(() =>
  plans.value?.find(
    (plan: { value: string | undefined }) => plan.value === company.value.plan
  )
);

const isTierZero = computed(() => currentPlan.value?.tierNumber === 0);

const tierNumber = computed(() => currentPlan.value?.tierNumber);

const isMonthly = computed(() => currentPlan.value?.billingPeriod === 'month');
const isAnnual = computed(() => currentPlan.value?.billingPeriod === 'year');

const setupHook = (context?: Partial<Context>) => {
  const store = context?.store || useStore();
  plans.value = store?.getters?.['pricePlans/all'];
  const { company: tmpCompany, isTrial: tmpIsTrial } = useCurrentCompany();
  company = tmpCompany;
  isTrial = tmpIsTrial;
};

const useCurrentPlan = (context?: Partial<Context>) => {
  if (!initialized) {
    setupHook(context);
    initialized = true;
    isReady.value = true;
  }

  return {
    isTierZero,
    isTrial,
    currentPlan,
    tierNumber,
    isReady,
    isMonthly,
    isAnnual,
  };
};

export default useCurrentPlan;
