<template>
  <AvatarImage
    :image="primaryAdvisorAvatar"
    class="bg-white"
    :size="size"
    v-bind="$attrs"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { AvatarImage } from '@bambeehr/pollen';
import { useAdvisorsStore } from '@/store/advisors';
import { computed } from '@nuxtjs/composition-api';

export default {
  components: {
    AvatarImage,
  },

  props: {
    size: {
      type: Number,
      default: 64,
    },
  },

  setup() {
    const advisorStore = useAdvisorsStore();
    const primaryAdvisorAvatar = computed(
      () => advisorStore.primaryAdvisorAvatar
    );

    return {
      primaryAdvisorAvatar,
    };
  },
};
</script>
