export enum PreviewModalComponents {
  HR_PRACTICE_PREVIEW_MODAL = 'HrPracticePreviewModal',
  REPORT_CARD_PREVIEW_MODAL = 'ReportCardPreviewModal',
}

export const UserStateKeys = Object.freeze({
  [PreviewModalComponents.HR_PRACTICE_PREVIEW_MODAL]: `viewedBciToHrPracticeModal`,
  [PreviewModalComponents.REPORT_CARD_PREVIEW_MODAL]: `viewedBciToHrPracticeReportCardModal`,
});

export const EmitCloseCurrentModal = 'close-current-modal';

export const EmitSendTo = 'send-to';

export default null;
