<template>
  <div>
    <div class="space-y-1">
      <ManageCompanyRoleInput
        v-for="role in companyRoleList"
        :key="role.id"
        :role="role"
      />
    </div>
    <div class="mt-6 text-left">
      <BaseButton
        variant="inverted-primary"
        size="small"
        @click.prevent="addCompanyRole"
      >
        Add New Role
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import useManageCompanyRoles from './useManageCompanyRoles';
import ManageCompanyRoleInput from './ManageCompanyRoleInput';
import { BaseButton } from '@bambeehr/pollen';

export default defineComponent({
  components: {
    ManageCompanyRoleInput,
    BaseButton,
  },
  setup(_, { emit }) {
    const { companyRoleList, addCompanyRole } = useManageCompanyRoles(emit);

    return {
      companyRoleList,
      addCompanyRole,
    };
  },
});
</script>
