































import {
  AnnularThrobber,
  TypeBody,
  TypeDisplay,
  BaseButton,
} from '@bambeehr/pollen';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { PaymentMethod } from '@/modules/payroll/constants/payroll';
import PaySettings from '@/modules/payroll/components/PaySettings';
import { getStaffInfoRows } from '@/modules/payroll/components/PaySettings/getInfoRows';
import useStaff from './useStaff';
import useMfa from '@/modules/Settings/hooks/useMfa';

export const getPaymentMethodLabel = (paymentMethodPreference: string) => {
  switch (paymentMethodPreference) {
    case PaymentMethod.DIRECT_DEPOSIT.value:
      return PaymentMethod.DIRECT_DEPOSIT.label;
    case PaymentMethod.MANUAL.value:
      return PaymentMethod.MANUAL.label;
    default:
      return 'Missing';
  }
};

export const getPrimaryBankAccountLabel = (
  bankAccounts: { institutionName: string; mask: string }[]
) => {
  const firstBankAccount = bankAccounts[0];
  if (!bankAccounts.length) {
    return 'Missing';
  }

  return `${firstBankAccount.institutionName} (${firstBankAccount.mask})`;
};

export default defineComponent({
  components: {
    AnnularThrobber,
    TypeBody,
    TypeDisplay,
    PaySettings,
    BaseButton,
  },
  setup() {
    const { currentStaff, pending, onboardingLink } = useStaff();

    const isDirectDepositEmployee = computed(
      () =>
        currentStaff.value?.paymentMethodPreference ===
        PaymentMethod.DIRECT_DEPOSIT.value
    );

    const paymentMethod = computed(() =>
      getPaymentMethodLabel(currentStaff.value?.paymentMethodPreference)
    );

    const bankAccount = computed(() =>
      getPrimaryBankAccountLabel(currentStaff.value?.bankAccounts)
    );

    const infoRows = computed(() =>
      getStaffInfoRows(paymentMethod.value, bankAccount.value)
    );

    const link = computed(() => ({
      label: 'Update your payment information or tax withholdings',
    }));

    const goToOnboardingLink = () => {
      window.open(onboardingLink.value, '_blank')?.focus();
    };

    const { isMfaEnabled, getMfaStatus } = useMfa();
    getMfaStatus(true);

    return {
      bankAccount,
      currentStaff,
      goToOnboardingLink,
      infoRows,
      isDirectDepositEmployee,
      isLoading: pending,
      link,
      onboardingLink,
      paymentMethod,
      isMfaEnabled,
    };
  },
});
