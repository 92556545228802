


























import { defineComponent, computed } from '@nuxtjs/composition-api';
import { TypeBody } from '@bambeehr/pollen';

export enum PulseVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export default defineComponent({
  name: 'AnimatedBullet',
  components: {
    TypeBody,
  },
  props: {
    variant: {
      type: String as () => PulseVariant,
      default: PulseVariant.PRIMARY,
    },
    pulse: {
      type: Boolean,
      default: false,
    },
    bullet: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const oppositeVariant = computed(() => {
      return props.variant === PulseVariant.PRIMARY
        ? PulseVariant.SECONDARY
        : PulseVariant.PRIMARY;
    });

    return {
      oppositeVariant,
    };
  },
});
