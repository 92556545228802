import useErrorHandler from '@/hooks/useErrorHandler';
import { ref } from '@nuxtjs/composition-api';

const initializeReactiveData = ({
  onError,
  data: dataRef,
  error: errorRef,
  pending: pendingRef,
} = {}) => {
  const data = dataRef || ref(null);
  const error = errorRef || ref(null);
  const pending = pendingRef || ref(true);
  pending.value = true;

  return {
    data,
    error,
    pending,
    update({ data: apiData, errors: apiError } = {}) {
      const errorHandler = useErrorHandler();

      if (apiError) {
        const toFormat = Array.isArray(apiError) ? apiError[0] : apiError;
        const formattedErr = errorHandler.format(toFormat, true);
        error.value = formattedErr;

        if (onError) {
          onError(formattedErr);
        }
      }

      if (apiData) {
        data.value = apiData;
      }

      pending.value = false;
    },
  };
};

export default initializeReactiveData;
