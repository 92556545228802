<template>
  <div>
    <TrainingRegistrationCard>
      <PurchaseTrainingForm
        v-if="prices"
        :prices="prices"
        @purchase-success="onSuccess"
        @purchase-error="onPurchaseError"
        @saving="onSaving"
      >
        <template #cta>
          <PurchaseTrainingCta
            class="block mb-3"
            :disabled="!seatsCount || isSaving || !prices"
            @click="activateCheckout"
          >
            Pay {{ totalCost }} &amp; Sign Up
          </PurchaseTrainingCta>
        </template>
      </PurchaseTrainingForm>
    </TrainingRegistrationCard>

    <PurchaseTrainingLegalText v-if="seatsCount" />

    <PurchaseTrainingPendingModal v-if="showPurchasePendingModal" />

    <PurchaseTrainingCheckoutModal
      v-if="showPurchaseCheckoutModal"
      :employee-count="seatsCount"
      @close="isCheckout = false"
      @purchase="handleCheckoutPurchase"
    />

    <PurchaseTrainingConfirmationModal
      v-if="showPurchaseConfirmationModal"
      @close="handlePurchaseConfirmationClose"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  useRouter,
  useStore,
  watch,
} from '@nuxtjs/composition-api';
import TrainingRegistrationCard from '@/modules/Training/components/TrainingRegistrationCard';
import PurchaseTrainingForm from '@/modules/Training/components/PurchaseTraining/PurchaseTrainingForm';
import PurchaseTrainingPendingModal from '@/modules/Training/components/PurchaseTraining/PurchaseTrainingPendingModal';
import PurchaseTrainingCheckoutModal from '@/modules/Training/components/PurchaseTraining/PurchaseTrainingCheckoutModal';
import PurchaseTrainingConfirmationModal from '@/modules/Training/components/PurchaseTraining/PurchaseTrainingConfirmationModal';
import PurchaseTrainingLegalText from '@/modules/Training/components/PurchaseTraining/PurchaseTrainingLegalText';
import useNotifications from '@bambeehr/use-notifications';
import useEmployees from '@/hooks/useEmployees';
import PurchaseTrainingCta from '@/modules/Training/components/PurchaseTraining/PurchaseTrainingCta';
import { usePurchaseSeatsForBundleMutation } from '@/gql/generated';
import { useApolloMutation } from '@/gql/apolloWrapper';

import usePurchaseTrainingContent from '@/modules/Training/components/PurchaseTraining/usePurchaseTrainingContent';
import currency from '@bambeehr/currency';

export default {
  name: 'TrainingRegistration',
  components: {
    PurchaseTrainingForm,
    PurchaseTrainingPendingModal,
    PurchaseTrainingLegalText,
    TrainingRegistrationCard,
    PurchaseTrainingConfirmationModal,
    PurchaseTrainingCheckoutModal,
    PurchaseTrainingCta,
  },
  setup() {
    const router = useRouter();
    const { addError } = useNotifications();

    const store = useStore();
    const { companyId } = store.getters;

    const isSaving = ref(false);
    const isCheckout = ref(false);
    const isConfirmation = ref(false);

    const onPurchaseError = () => {
      addError(
        'Your payment method has failed. Please go to your settings and verify that your card is up-to-date.'
      );
      isSaving.value = false;
    };

    const onSuccess = () => {
      isSaving.value = false;
      isConfirmation.value = true;
    };

    const onSaving = (savingState) => {
      isSaving.value = savingState;
    };

    const activateCheckout = () => {
      isCheckout.value = true;
    };

    const {
      mutate: purchaseSeatsForBundle,
      onDone,
      onError,
    } = useApolloMutation(usePurchaseSeatsForBundleMutation, {
      pending: isSaving,
    });

    onError(() => {
      onPurchaseError();
    });

    onDone((res) => {
      if (res) {
        onSuccess();
      }
    });

    const handleCheckoutPurchase = async (seats) => {
      isCheckout.value = false;
      purchaseSeatsForBundle({
        data: {
          numSeats: seats,
          companyId,
        },
      });
    };

    const handlePurchaseConfirmationClose = () => {
      router.push(`/training/company?init=true`);
    };

    const showPurchasePendingModal = computed(() => {
      return isSaving.value;
    });

    const showPurchaseCheckoutModal = computed(() => {
      return isCheckout.value && !isSaving.value;
    });

    const showPurchaseConfirmationModal = computed(() => {
      return isConfirmation.value && !isSaving.value;
    });

    const { w2: w2Employees } = useEmployees();
    const { updateSeatCount, pricePerBundle, prices } =
      usePurchaseTrainingContent();

    const seatsCount = computed(() => {
      const eeCount = w2Employees.value?.length || 0;

      return eeCount;
    });

    const totalCost = computed(() =>
      currency(
        // 100 = convert to dollars
        pricePerBundle ? seatsCount.value * pricePerBundle.value : 0
      ).format()
    );

    watch(
      seatsCount,
      (seats) => {
        if (seats) {
          updateSeatCount(seats);
        }
      },
      { immediate: true }
    );

    const requireCheckout = computed(() => {
      return seatsCount.value >= 20;
    });

    return {
      activateCheckout,
      isCheckout,
      isSaving,
      handleCheckoutPurchase,
      handlePurchaseConfirmationClose,
      onSaving,
      onSuccess,
      onPurchaseError,
      requireCheckout,
      seatsCount,
      showPurchaseCheckoutModal,
      showPurchaseConfirmationModal,
      showPurchasePendingModal,
      totalCost,
      prices,
    };
  },
};
</script>
