var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-6",class:{ 'fein-invalid': _vm.isInvalid }},[_c('DescriptionTextInput',{attrs:{"id":"companyFein","name":"companyFein","placeholder":"XX-XXXXXXX","label":"Federal Employer Identification Number (EIN)","description":"The nine-digit tax ID number used for filing your federal payroll taxes found on your EIN confirmation letter.","should-show-text-invalid":false,"is-disabled":_vm.disableForm,"invalid":_vm.showFeinAsInvalid,"error":(_vm.isValidating &&
        !_vm.feinIsValid &&
        'Invalid Federal Employer Identification Number (EIN)') ||
      '',"mask":{
      blocks: [2, 7],
      delimiters: ['-'],
    }},scopedSlots:_vm._u([{key:"post-description",fn:function(){return [_c('ExplainerTooltipModal',{attrs:{"entry-id":"V5kJin7H2BqAGHspzfGWU"}})]},proxy:true}]),model:{value:(_vm.form.fein),callback:function ($$v) {_vm.$set(_vm.form, "fein", $$v)},expression:"form.fein"}}),_vm._v(" "),_c('DescriptionTextInput',{attrs:{"id":"companyName","name":"companyName","label":"Legal Company Name","description":"The legal name on your EIN confirmation letter.","should-show-text-invalid":false,"is-disabled":_vm.disableForm,"invalid":_vm.showNameAsInvalid,"error":(_vm.isValidating && !_vm.nameIsValid && 'Legal Company Name is required') || ''},scopedSlots:_vm._u([{key:"post-description",fn:function(){return [_c('ExplainerTooltipModal',{attrs:{"entry-id":"36YfpWjOB9h5UCERG8uGIt"}})]},proxy:true}]),model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_vm._v(" "),(_vm.isValid)?_c('BaseBanner',{attrs:{"message":"Great job! Your Federal EIN and legal name have been successfully verified.","variant":"confirmation","show-close":false}}):_c('div',{staticClass:"rounded border border-gray-5 p-3"},[_c('TypeBody',{staticClass:"gray-0",attrs:{"tag":"div","variant":"link-x-small-tight","weight":"strong"}},[_vm._v("\n      Verify your Federal Employer Identification Number (EIN)\n    ")]),_vm._v(" "),_c('TypeBody',{attrs:{"tag":"div","variant":"text-x-small-tight"}},[_vm._v("\n      Ensure your tax information is accurate by verifying your Legal Company\n      Name and Federal Employer Identification Number (EIN).\n    ")]),_vm._v(" "),(_vm.isInvalid || _vm.isLockedOut || (_vm.missingRequiredInfo && _vm.isValidating))?_c('BaseBanner',{staticClass:"mt-2",attrs:{"message":_vm.missingRequiredInfo
          ? 'Missing Information'
          : 'Unable to verify your EIN',"variant":_vm.missingRequiredInfo ? 'error' : 'warning',"show-close":false},scopedSlots:_vm._u([{key:"description",fn:function(){return [(_vm.isLockedOut)?_c('div',[_vm._v("\n          Don't worry. We'll ask you for some documents that will help us\n          verify your tax information later.\n        ")]):_c('div',[(_vm.missingRequiredInfo)?_c('div',[_vm._v("\n            To verify your Federal Employer Identification Number (EIN), enter\n            the following information:\n          ")]):_c('div',[_vm._v("\n            Please ensure that the following information matches what is\n            displayed on your Federal Tax Forms:\n          ")]),_vm._v(" "),_c('ul',{staticClass:"pl-6 list-disc mt-2"},[_c('li',[_vm._v("Legal Company Name")]),_vm._v(" "),_c('li',[_vm._v("Federal Employer Identification Number (EIN)")])])])]},proxy:true}],null,false,3214932257)}):_vm._e(),_vm._v(" "),(!_vm.isLockedOut)?_c('BaseButton',{staticClass:"mt-3",attrs:{"disabled":_vm.disableVerifyBtn},on:{"click":_vm.verifyFein}},[(_vm.isVerifying)?_c('div',{staticClass:"flex items-center justify-center"},[_c('HexLoader'),_vm._v(" "),_c('span',{staticClass:"ml-2"},[_vm._v("Verifying")])],1):_c('div',[_vm._v("Verify")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }