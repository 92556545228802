import { getModuleKeys, StoreModules } from '@/constants/Store';
import videoTours from '@/constants/videoTours';
import Contentful from '@/services/Contentful';
import get from 'lodash/get';
import Vue from 'vue';

const { Getters, Actions } = getModuleKeys(StoreModules.VIDEO_TOURS);

// mutations
const SET_CONTENT = 'SET_CONTENT';

const getUserStates = (rootState) => {
  return get(rootState, 'current_user.states');
};

export const state = () => ({
  content: {},
  dismissed: {},
});

export const getters = {
  [Getters.BY_ID]: (state) => (id) => {
    return state.content[id];
  },

  [Getters.DISMISSED]: (state, getters, rootState) => {
    const dismissed = {};
    const userStates = getUserStates(rootState);
    videoTours.forEach((t) => {
      dismissed[t.contentfulId] = get(userStates, t.userStateKey, false);
    });

    return dismissed;
  },
};

export const mutations = {
  [SET_CONTENT]: (state, { videoTourId, content }) => {
    Vue.set(state.content, videoTourId, content);
  },
};

export const actions = {
  [Actions.FETCH_TOUR]: async ({ commit, getters }, videoTourId) => {
    // if we already have the content, don't fetch it again
    if (getters[Getters.BY_ID](videoTourId) !== undefined) {
      return;
    }
    const response = await Contentful.getEntry(videoTourId);
    commit(SET_CONTENT, {
      videoTourId,
      content: response.fields,
    });
  },

  [Actions.DISMISS]: async ({ dispatch, getters }, videoTourId) => {
    // if user has already dismissed this video, exit
    if (getters[Getters.DISMISSED][videoTourId] === true) {
      return;
    }
    const whichTour = videoTours.find((t) => t.contentfulId === videoTourId);
    if (whichTour) {
      await dispatch(
        'updateState',
        {
          key: whichTour.userStateKey,
          value: true,
        },
        { root: true }
      );
    } else {
      throw new Error('Invalid video tour');
    }
  },
};
