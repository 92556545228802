








































import { PropType } from '@nuxtjs/composition-api';
import { BaseCard, TypeBody, BaseButton } from '@bambeehr/pollen';

export default {
  name: 'BenefitsOverviewActionTile',
  components: {
    BaseCard,
    TypeBody,
    BaseButton,
  },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    description: {
      type: String as PropType<string>,
      required: true,
    },
    btnVariant: {
      type: String as PropType<string>,
      default: 'primary',
    },
    btnText: {
      type: String as PropType<string>,
      required: true,
    },
    costPerEmployeeText: {
      type: String as PropType<string>,
      default: '',
    },
  },
};
