<template functional>
  <transition name="fade">
    <div
      v-if="props.isNavOpen"
      class="nav-overlay absolute lg:hidden inset-0"
    />
  </transition>
</template>

<script>
export default {
  props: {
    isNavOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.nav-overlay {
  backdrop-filter: blur(0.05rem);
  background: rgba(255, 255, 255, 0.9);
}
</style>
