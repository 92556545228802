import FeatureFlags from '@/constants/FeatureFlags';
import { getLaunchDarklyFlag } from '@/utils/launchDarklyFlags';
import isTrainingEmployerRole from './isTrainingEmployerRole';

const canPurchaseTraining = async (store: any): Promise<Boolean> => {
  // First: Check if the training flag is turned on
  const hasTrainingFlag: Boolean = !!(await getLaunchDarklyFlag(
    FeatureFlags.ENABLE_TRAINING
  ));

  // Second: Check if the user has permissions
  const isCorrectRole: Boolean = isTrainingEmployerRole(store);

  return hasTrainingFlag && isCorrectRole;
};

export default canPurchaseTraining;
