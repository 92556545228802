






























import { defineComponent, PropType, onMounted } from '@nuxtjs/composition-api';
import { wizardActionState } from '@/modules/payroll/pages/employer/onboarding/OnboardingRoot.vue';

import { TypeDisplay, TypeBody, BaseCard } from '@bambeehr/pollen';
import Workplaces from '@/components/Workplaces/Workplaces.vue';

import { trackEvent } from '@/modules/payroll/utils/track-apa';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';

function setWizardActionState() {
  wizardActionState.value = {
    next: {
      label: 'Continue',
      action: (next) => {
        next();
      },
    },
    finishLater: {
      label: 'Finish Later',
      action: (finishLater) => {
        finishLater();
      },
    },
    back: {
      label: 'Back',
      action: (back) => {
        back();
      },
    },
    disableNext: false,
  };
}

export default defineComponent({
  name: 'Locations',
  components: {
    BaseCard,
    TypeDisplay,
    TypeBody,
    Workplaces,
  },
  props: {
    companyId: {
      type: String as PropType<string>,
    },
  },
  setup() {
    setWizardActionState();
    // Reset top position
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    // APA Tracking
    const { isOnboarding } = usePayrollOnboardingStatus();

    if (isOnboarding.value) {
      trackEvent('payroll-onboarding-locations');
    }
  },
});
