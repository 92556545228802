













import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { BaseButton, BaseBanner } from '@bambeehr/pollen';

export enum BannerTypes {
  ERROR = 'error',
  WARNING = 'warning',
}

export interface BannerMessage {
  title?: string;
  body?: string;
}

export default defineComponent({
  components: {
    BaseButton,
    BaseBanner,
  },

  props: {
    bannerTitle: {
      type: String,
      required: true,
    },
    bannerMessage: {
      type: String,
      required: true,
    },
    bannerType: {
      type: String as PropType<BannerTypes>,
      required: true,
    },
  },

  setup(props) {
    const buttonVariant = computed<string>(() =>
      props.bannerType === BannerTypes.WARNING
        ? 'inverted-primary'
        : 'inverted-tertiary'
    );

    return {
      buttonVariant,
    };
  },
});
