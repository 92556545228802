



















import {
  defineComponent,
  PropType,
  onMounted,
  watchEffect,
} from '@nuxtjs/composition-api';
import { BaseIcon, BaseButton } from '@bambeehr/pollen';
import ProductSelector from '@/components/ProductSelector';
import { ProductKey, BillingPeriod } from '@/gql/generated';
import useProductSelector from '@/components/ProductSelector/useProductSelector';

export default defineComponent({
  name: 'PlanSelectorModal',
  components: {
    BaseIcon,
    BaseButton,
    ProductSelector,
  },
  props: {
    recommendedPackage: {
      type: String as PropType<ProductKey>,
      default: ProductKey.Payroll,
    },
  },
  emits: ['close', 'next'],
  setup(props) {
    const {
      showAddOns,
      currentTermModel,
      basicPayrollTier,
      premiumPayrollTier,
      isSubscription,
    } = useProductSelector();
    showAddOns.value = true;
    onMounted(() => {
      switch (props.recommendedPackage) {
        case ProductKey.PayrollBasic:
          currentTermModel.value = BillingPeriod.Month;
          break;
        case ProductKey.PayrollPremium:
          currentTermModel.value = BillingPeriod.Year;
          break;
        default:
          currentTermModel.value = BillingPeriod.Month;
      }
      isSubscription.value = false;
    });
    watchEffect(() => {
      if (!basicPayrollTier.value?.value && premiumPayrollTier.value?.value) {
        currentTermModel.value = BillingPeriod.Year;
      } else if (
        basicPayrollTier.value?.value &&
        !premiumPayrollTier.value?.value
      ) {
        currentTermModel.value = BillingPeriod.Month;
      }
    });

    return { ProductKey };
  },
});
