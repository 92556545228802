













import {
  defineComponent,
  PropType,
  useRoute,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { BaseButton } from '@bambeehr/pollen';
import useAddStaffFormRole, { ClientWorkerRole } from './useAddStaffFormRole';
import AddStaffFormRole from './AddStaffFormRole';
import SalaryWarningModal from './SalaryWarningModal';
import ManageCompanyRolesModal from './ManageCompanyRolesModal';

export default defineComponent({
  components: {
    BaseButton,
    AddStaffFormRole,
    SalaryWarningModal,
    ManageCompanyRolesModal,
  },
  props: {
    value: {
      type: Array as PropType<ClientWorkerRole[]>,
      required: true,
    },
    isContractor: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    onlyPrimaryRoles: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    workerId: {
      type: String as PropType<string>,
      default: '',
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const route = useRoute();
    const workerId = props.workerId || route.value.params.id;
    const {
      rolesModel,
      createRole,
      showManageCompanyRolesModal,
      toggleManageCompanyRolesModal,
      isSalaryWorker,
      showSalaryRolesModal,
      dismissSalaryRolesModal,
      acceptSalaryRolesModal,
      resetState,
    } = useAddStaffFormRole(props, emit, workerId);

    onBeforeUnmount(() => {
      resetState();

      if (!rolesModel.value.length) {
        createRole(true);
      }
    });

    return {
      createRole,
      rolesModel,
      showManageCompanyRolesModal,
      toggleManageCompanyRolesModal,
      isSalaryWorker,
      showSalaryRolesModal,
      dismissSalaryRolesModal,
      acceptSalaryRolesModal,
    };
  },
});
