import { ContractorPayment, PayrollItem } from '@/gql/generated';

export default (
  list: (PayrollItem | ContractorPayment)[],
  modelList: [string, boolean][],
  key: string
): string[] =>
  modelList.reduce((acc: string[], [wId, val]) => {
    const eeItem = list.find(
      (e: PayrollItem | ContractorPayment) => e.id === wId
    );

    return (eeItem && val ? [...acc, eeItem[key]?.id] : acc).filter(Boolean);
  }, []);
