




























import {
  computed,
  defineComponent,
  PropType,
  ComputedRef,
} from '@nuxtjs/composition-api';
import { TypeBody } from '@bambeehr/pollen';

export enum Statuses {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  PENDING,
  ERRED,
  WARNING,
  SUCCESS,
  ACTION_REQUIRED,
}

const StatusLabels = new Map<number, string>([
  [Statuses.NOT_STARTED, 'Not Started'],
  [Statuses.IN_PROGRESS, 'In Progress'],
  [Statuses.COMPLETED, 'Completed'],
  [Statuses.PENDING, 'Pending'],
  [Statuses.ERRED, 'Error'],
  [Statuses.WARNING, 'Warning'],
  [Statuses.SUCCESS, 'Success'],
  [Statuses.ACTION_REQUIRED, 'Action Required'],
]);

export default defineComponent({
  name: 'StatusIndicator',
  components: {
    TypeBody,
  },
  props: {
    customStatusColor: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    status: {
      type: Number as PropType<Statuses>,
      required: false,
      default: Statuses.PENDING,
    },
    hideLabel: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    padBackground: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const label: ComputedRef<string> = computed(() => {
      if (props.hideLabel) {
        return '';
      }

      return props.label || StatusLabels.get(props.status) || '';
    });

    const statusClass: ComputedRef<{ primary: string; bg: string }> = computed(
      () => {
        const sc = {
          primary: '',
          bg: '',
        };

        switch (props.status) {
          case Statuses.COMPLETED:
          case Statuses.SUCCESS:
            sc.primary = 'bg-success-default';
            sc.bg = 'bg-success-weak';
            break;
          case Statuses.IN_PROGRESS:
          case Statuses.PENDING:
            sc.primary = 'bg-primary-light';
            sc.bg = 'bg-primary-weak';
            break;
          case Statuses.ERRED:
            sc.primary = 'bg-error-default';
            sc.bg = 'bg-error-weak';
            break;
          case Statuses.ACTION_REQUIRED:
          case Statuses.WARNING:
            sc.primary = 'bg-warning-default';
            sc.bg = 'bg-warning-weak';
            break;

          default:
            sc.primary = 'bg-base-500';
            sc.bg = 'bg-base-100';
            break;
        }

        return sc;
      }
    );

    return {
      statusLabel: label,
      statusClass,
    };
  },
});
