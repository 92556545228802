<template>
  <div class="flex flex-col items-center">
    <TypeBody
      variant="link-medium-tight"
      weight="strong"
      class="mb-4 text-center flex-grow"
      tag="div"
    >
      {{ text }}
    </TypeBody>

    <div class="flex items-center">
      <img :src="image" alt="benefit image" />
    </div>
  </div>
</template>

<script>
import { TypeBody } from '@bambeehr/pollen';

export default {
  name: 'TrainingBenefitCallout',
  components: {
    TypeBody,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
