<template>
  <BaseCard full-bleed class="p-4">
    <slot />
  </BaseCard>
</template>
<script>
import { BaseCard } from '@bambeehr/pollen';

export default {
  name: 'PayrollCard',
  components: { BaseCard },
};
</script>
