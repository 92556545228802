import FeatureFlags from '@/constants/FeatureFlags';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import { computed } from '@nuxtjs/composition-api';

const useCreateJDFeatureFlag = () => {
  const isCreateJobDescriptionsEnabled = computed<boolean>(
    () => launchDarkly.getFlags()[FeatureFlags.ENABLE_CREATE_JOB_DESCRIPTIONS]
  );

  return {
    isCreateJobDescriptionsEnabled,
  };
};

export default useCreateJDFeatureFlag;
