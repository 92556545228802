<template>
  <transition name="banner">
    <div
      class="flex items-center px-6 py-2 text-center banner"
      :class="[`banner--${variant}`]"
    >
      <BaseIcon v-if="icon" class="text-23 banner__icon" :icon="icon" />
      <TypeBody
        variant="link-tiny"
        class="flex-grow banner__content"
      >
        <slot />
      </TypeBody>
      <button
        v-if="showClose"
        class="p-1 bg-transparent border-none cursor-pointer banner-close text-12 opacity-30"
        type="button"
        title="Close"
        @click="$emit('close')"
      >
        <BaseIcon icon="close" />
        <div class="sr-only banner-close__accessible-label">
          Click or press escape to close
        </div>
      </button>
    </div>
  </transition>
</template>

<script>
import { BaseIcon, TypeBody } from '@bambeehr/pollen';

export const BannerVariants = Object.freeze({
  ERROR: 'error',
  EXPLAINER: 'explainer',
});

export default {
  name: 'MarketingBanner',
  components: {
    BaseIcon,
    TypeBody,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: BannerVariants.EXPLAINER,
      validator: (value) =>
        [BannerVariants.EXPLAINER, BannerVariants.ERROR].includes(value),
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped>
.banner-enter-active,
.banner-leave-active {
  transition: opacity 0.3s;
}

.banner-enter-to {
  opacity: 1;
}

.banner-enter,
.banner-leave-to /* .banner-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.banner--explainer {
  @apply bg-primary-light text-white;
}

.banner--explainer .banner__icon {
  @apply text-primary-light;
}

.banner--error {
  @apply bg-error-light text-error-default;
}

.banner--error .banner__icon {
  @apply text-error-light;
}
</style>
