<template>
  <div class="px-4 py-3 mb-3 bg-white rounded-md check-tile shadow-1">
    <div class="grid grid-cols-12 grid-rows-2 xs:grid-rows-1 gap-y-3">
      <div class="flex flex-row col-span-8 xs:col-span-6">
        <div>
          <div class="flex mr-3">
            <AvatarImage :image="avatarUrl" :initials="initials" />
          </div>
        </div>
        <div class="flex flex-col">
          <TypeBody
            variant="link-x-small-tight"
            weight="strong"
            tag="div"
            class="text-base-800"
          >
            {{ fullName }}
          </TypeBody>
          <TypeBody
            variant="text-x-small-tight"
            weight="medium"
            tag="div"
            class="mt-px text-base-600"
          >
            {{ position }}
          </TypeBody>
        </div>
      </div>
      <div
        class="flex flex-col col-span-4 text-right xs:col-span-3 xs:text-left"
      >
        <TypeBody
          variant="text-x-small-tight"
          weight="medium"
          tag="div"
          class="text-base-600"
        >
          Net Pay
        </TypeBody>
        <TypeBody
          variant="link-x-small-tight"
          weight="strong"
          tag="div"
          class="text-gray-0"
        >
          {{ formattedNetPay }}
        </TypeBody>
      </div>
      <div
        class="flex flex-col items-end justify-center col-span-12 xs:col-span-3"
      >
        <TextInput
          :id="`check-${id}`"
          data-qa="check-number-input"
          class="w-full sm:w-2/3"
          size="dense"
          placeholder="Check #"
          :maxlength="maxLength"
          :value="checkNumber"
          :invalid="hasErr"
          @input="updateCheckNumber($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TypeBody, AvatarImage, TextInput } from '@bambeehr/pollen';
import { getInitials } from '@/utils/nameFormatter';
import currency from '@bambeehr/currency';

export default {
  components: {
    AvatarImage,
    TypeBody,
    TextInput,
  },

  props: {
    avatarUrl: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: String,
      require: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    netPay: {
      type: Number,
      required: true,
    },
    checkNumber: {
      type: String,
      required: false,
      default: '',
    },
    hasErr: {
      type: Boolean,
      required: true,
    },
    maxLength: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const initials = getInitials(props.firstName, props.lastName);
    const formattedNetPay = currency(props.netPay).format();

    const updateCheckNumber = (val) => {
      emit('update-check-number', {
        val,
        id: props.id,
      });
    };

    return {
      initials,
      formattedNetPay,
      updateCheckNumber,
    };
  },
};
</script>

<style scoped>
.check-tile + .check-tile {
  @apply mt-3;
}
</style>
