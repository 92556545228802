import { Statuses } from '@/components/StatusIndicator';

const getStatus = (startedAt, completedAt) => {
  if (completedAt) {
    return Statuses.COMPLETED;
  }
  if (startedAt) {
    return Statuses.IN_PROGRESS;
  }

  return Statuses.NOT_STARTED;
};

export default getStatus;
