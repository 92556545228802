import { reactive, ref, Ref } from '@nuxtjs/composition-api';

interface GlobalPlugin {
  script: Ref<any>;
  availableScripts: string[];
}

export const availableScripts = reactive<string[]>([]);
export const useGlobalScript = (src: string, name: string): GlobalPlugin => {
  const script = ref();

  if (availableScripts.includes(name)) {
    script.value = window[name];
  } else {
    const headEl = document.getElementsByTagName('head')[0];
    const scriptEl = document.createElement('script');
    scriptEl.type = 'text/javascript';
    scriptEl.onload = () => {
      script.value = window[name];
      availableScripts.push(name);
    };
    scriptEl.src = src;
    headEl.appendChild(scriptEl);
  }

  return {
    script,
    availableScripts,
  };
};
