





































import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import {
  ModalDialog,
  TypeBody,
  TypeDisplay,
  BaseButton,
} from '@bambeehr/pollen';
import getStatus from '@/modules/Training/utils/getStatus';
import StatusIndicator from '@/components/StatusIndicator';
import { PurchasedTopic } from '@/gql/generated';

export default defineComponent({
  name: 'CoreBundleTag',
  components: {
    ModalDialog,
    TypeDisplay,
    TypeBody,
    BaseButton,
    StatusIndicator,
  },
  props: {
    title: {
      type: String,
      default: 'Program Status',
    },
    topics: {
      type: Array as PropType<PurchasedTopic[]>,
      default: () => [],
    },
  },
  setup(props) {
    const mappedTopics = computed(() =>
      props.topics.map((topic) => ({
        ...topic,
        status: getStatus(
          topic.enrollments?.[0]?.startedAt,
          topic.enrollments?.[0]?.completedAt
        ),
      }))
    );

    return {
      mappedTopics,
    };
  },
});
