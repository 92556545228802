









































import { defineComponent } from '@nuxtjs/composition-api';
import VideoEmbed from '@/components/common/VideoEmbed';
import { ModalDialog, BaseButton, TypeDisplay } from '@bambeehr/pollen';

enum Emits {
  SUBMIT = 'submit',
  CANCEL = 'cancel',
  CLOSE = 'close',
}

export default defineComponent({
  name: 'WelcomeVideoModal',
  components: {
    ModalDialog,
    BaseButton,
    TypeDisplay,
    VideoEmbed,
  },
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      default: '',
    },
    submitLabel: {
      type: String,
      default: 'Continue',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  emits: [Emits.SUBMIT, Emits.CANCEL, Emits.CLOSE],
  setup: () => ({ Emits }),
});
