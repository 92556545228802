import { ref, readonly } from '@nuxtjs/composition-api';
import shortId from 'shortid';

const sessionId = ref('');

const startNewSession = () => {
  sessionId.value = shortId.generate();
};

const closeSession = () => {
  sessionId.value = '';
};

const useHrRequestTracking = () => {
  return {
    startNewSession,
    closeSession,
    sessionId: readonly(sessionId),
  };
};

export default useHrRequestTracking;
