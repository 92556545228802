<template>
  <BaseCard class="overflow-hidden wrapper__card" :full-bleed="true">
    <div class="flex flex-col lg:flex-row">
      <div
        class="flex flex-col items-center p-5 pt-10 border-r bg-primary-weak lg:w-1/2 border-primary-weak sm:items-start sm:justify-between lg:items-center sm:flex-row lg:flex-col sm:mb-0"
      >
        <div>
          <TypeDisplay
            variant="small"
            weight="strong"
            tag="div"
            class="text-primary-default w-full text-left"
          >
            Let's get you paid
          </TypeDisplay>

          <div class="text-left w-full mt-6">
            <div class="flex items-center">
              <HighlightedIcon icon="moneySolid" size="medium" />
              <TypeBody
                variant="text-small"
                weight="regular"
                tag="div"
                class="ml-3 text-base-800"
              >
                View your digital {{ payStubLabel }} and year-end tax forms
              </TypeBody>
            </div>
            <div class="flex items-center mt-5">
              <HighlightedIcon icon="govtBuilding" size="medium" />
              <TypeBody
                variant="text-small"
                weight="regular"
                tag="div"
                class="ml-3 text-base-800"
              >
                Update your bank account information and withholdings
              </TypeBody>
            </div>
          </div>
        </div>

        <GetPaid
          class="my-6 sm:mt-0 lg:mt-20 xl:mt-24 w-72 sm:w-48 md:w-56 lg:w-80"
        />
      </div>
      <div
        class="flex flex-col justify-center px-6 py-6 sm:px-16 md:px-20 bg-white lg:w-1/2 lg:py-10 lg:px-16"
      >
        <TypeBody
          variant="link-large-tight"
          weight="strong"
          tag="div"
          class="text-base-800"
        >
          We'll need the following:
        </TypeBody>
        <div class="mt-6">
          <TypeBody class="flex mb-6 -ml-3" variant="text-small" tag="div">
            <div>
              <HexNum class="w-12"> 1 </HexNum>
            </div>
            <span class="mt-1">Pay preference and bank information</span>
          </TypeBody>

          <TypeBody class="flex mb-6 -ml-3" variant="text-small" tag="div">
            <div>
              <HexNum class="w-12"> 2 </HexNum>
            </div>
            <span class="mt-1">Your {{ ssnLabel }}</span>
          </TypeBody>

          <TypeBody class="flex mb-6 -ml-3" variant="text-small" tag="div">
            <div>
              <HexNum class="w-12"> 3 </HexNum>
            </div>
            <span class="mt-1">Tax withholding information</span>
          </TypeBody>
        </div>
        <BaseBanner
          class="mt-6"
          message="Bambee uses Check Technologies, Inc. to securely connect your information to the payroll service."
          :show-close="false"
        />
        <div>
          <BaseButton
            class="mt-6"
            variant="secondary"
            size="giant"
            :disabled="isLoading"
            @click="$emit('open-onboarding')"
          >
            <LoadingButtonLabel :loading="isLoading">
              Get started
            </LoadingButtonLabel>
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script>
import {
  BaseBanner,
  BaseButton,
  BaseCard,
  HighlightedIcon,
  TypeBody,
  TypeDisplay,
} from '@bambeehr/pollen';
import { computed } from '@nuxtjs/composition-api';
import GetPaid from '@/modules/payroll/components/GetPaid/GetPaid.vue';
import HexNum from '@/components/utility/HexNum/hex-num.vue';
import LoadingButtonLabel from '@/components/LoadingButtonLabel/LoadingButtonLabel.vue';

export default {
  name: 'EmployeeSetup',
  components: {
    BaseBanner,
    BaseButton,
    BaseCard,
    GetPaid,
    HexNum,
    HighlightedIcon,
    TypeBody,
    TypeDisplay,
    LoadingButtonLabel,
  },
  props: {
    onboardingLink: {
      type: String,
      required: false,
      default: '',
    },
    isContractor: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const payStubLabel = computed(() =>
      props.isContractor ? 'pay statements' : 'pay stubs'
    );
    const ssnLabel = computed(() =>
      props.isContractor ? 'SSN or EIN' : 'SSN or ITIN'
    );

    return {
      payStubLabel,
      ssnLabel,
    };
  },
};
</script>
