/**
 * Specific for Policy Center / Different from StaffManagement Mapper:
 * It takes in the functions: onlyUnique, getUserRoles, getStaffState from
 * the StaffManagement Module mapper: mapUsersToStaffMembers.
 
 * getPolicyState() is to set the Staff's current policy Status:
 *  Signed, Not Signed, Not Implemented (All).
 * Used for Grouping and Filtering in Policy Center
 */

import usePolicyReaffirmationFlags from '@/hooks/usePolicyReaffirmationFlags/usePolicyReaffirmationFlags';
import { ActivationStatus } from '@/lib/activationStatus';
import {
  PolicyStaffMember,
  PolicyStaffStates,
} from '@/modules/PolicyCenter/models';
import { StaffMember } from '@/modules/StaffManagement/models';
import {
  getStaffState,
  getUserRoles,
  onlyUnique,
} from '@/modules/StaffManagement/utils/mapUsersToStaffMembers';
import usePolicyReaffirmations from '@/modules/TaskCenter/hooks/usePolicyReaffirmations/usePolicyReaffirmations';

const { shouldPolicyDocumentReaffirm, reaffirmInterval } =
  usePolicyReaffirmations();
const { isPolicyReaffirmationsEnabled } = usePolicyReaffirmationFlags();
/**
 * Looks for documents in EmployEE to determine whether the current policy
 * is applicable to this individual user.
 */
function getPolicyState(user, documentIds) {
  let policyState: PolicyStaffStates = PolicyStaffStates.NOT_IMPLEMENTED;
  const { documents } = user;
  const currentDocument = documents.find((doc) =>
    documentIds?.includes(doc.id)
  );

  const lastReaffirmation: Date | null =
    currentDocument?.reaffirmations?.[
      currentDocument.reaffirmations?.length - 1
    ] || null;

  const signedAtDate: Date | null = currentDocument?.signature?.created_at;

  const shouldReaffirm = shouldPolicyDocumentReaffirm(
    lastReaffirmation,
    signedAtDate,
    reaffirmInterval.value
  );
  // Complete: Signed and latest reaffirmation is NOT past the given interval OR signed
  if (
    (currentDocument && currentDocument.signed && !shouldReaffirm) ||
    (currentDocument && currentDocument.signed)
  ) {
    policyState = PolicyStaffStates.SIGNED;
  }

  // Not Signed: signed is false and should not need to reaffirm if they have not signed.
  if (currentDocument && !currentDocument.signed) {
    policyState = PolicyStaffStates.NOT_SIGNED;
  }
  // Needs Reaffirmation: Signed and latest reaffirmation date is past the given interval
  if (
    currentDocument &&
    currentDocument.signed &&
    shouldReaffirm &&
    isPolicyReaffirmationsEnabled.value
  ) {
    policyState = PolicyStaffStates.REAFFIRMATION_NEEDED;
  }

  return policyState;
}

/**
 * Main mapper that sets the PolicyStaffMember object.
 * Adding a new state: policyState
 * This is used to filter and group the PolicyStaffList.
 */
function mapEachUserToPolicyStaffMember(user, docIds) {
  const staffState = getStaffState(user);
  const roles = getUserRoles(user);
  const policyStaffState = getPolicyState(user, docIds);
  const active =
    user.active && user?.activation_status === ActivationStatus.ACTIVATED;
  const signatureRequired = user.documents.some((doc: any) => !doc.signed);
  const currentDocument = user.documents.find((doc) =>
    docIds?.includes(doc.id)
  );
  const lastReaffirmation: Date | null =
    currentDocument?.reaffirmations?.[
      currentDocument.reaffirmations?.length - 1
    ] || null;

  const signedAtDate: Date | null = currentDocument?.signature?.created_at;

  const shouldReaffirm = shouldPolicyDocumentReaffirm(
    lastReaffirmation,
    signedAtDate,
    reaffirmInterval.value
  );

  return {
    id: user._id,
    contractor: user.contractor || false,
    documents: user.documents,
    flags: user.states,
    onboardInviteSent: user.states.onboard_request,
    reaffirmations: currentDocument?.reaffirmations || null,
    hasReaffirmed:
      currentDocument?.signed &&
      !!currentDocument?.reaffirmations?.length &&
      !shouldReaffirm,
    profile: {
      avatarUrl: user.avatar_url,
      staffName: user.name,
      jobTitle: user.title,
    },
    roles,
    staffState,
    policyState: policyStaffState,
    active,
    signatureRequired,
  };
}

/**
 * takes in a list of users according to response from https://publicapi.dev.bambee.com/v0/app/my/employees/simplified
 * * and translates them into PolicyStaffMember objects
 */
export default function mapUsersToPolicyStaffMembers(
  users: StaffMember[] = [],
  documentIds: Array<string> | undefined
): PolicyStaffMember[] {
  return onlyUnique(users).map(
    (user) => mapEachUserToPolicyStaffMember(user, documentIds) || []
  );
}

/**
 * Add Employee Variables into Policy Document
 */
export const addEmployeeVariablesToDocument = (
  policy,
  company,
  selectedEmployee
) => {
  return {
    ...policy,
    variables: {
      employee: {
        _user: {
          overtimePay: selectedEmployee?.overtimePay || '',
        },
        profile: {
          ...selectedEmployee?.profile,
        },
        email: selectedEmployee?._auth?.email,
        states: {
          _resignation: {
            last_date: '',
          },
        },
        ...selectedEmployee?.value,
      },
      company,
      manager: selectedEmployee?._manager,
    },
  };
};
