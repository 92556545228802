const CookieNames = Object.freeze({
  DISMISSED_HR_REQUEST_GREETING: 'DISMISSED_HR_REQUEST_GREETING',
  DISSMISSED_TIER_UPGRADE_MODAL: 'DISSMISSED_TIER_UPGRADE_MODAL',
  DISMISSED_GET_PAYROLL_BANNER_BASE: 'DISMISSED_GET_PAYROLL_BANNER', // Use with company ID prefix ({{companyId}}_DISMISSED_GET_PAYROLL_BANNER)
  VIEWED_AD_BLOCKER_MODAL: 'VIEWED_AD_BLOCKER_MODAL',
  DISSMISSED_SERVICE_UPDATE_MODAL: 'DISSMISSED_SERVICE_UPDATE_MODAL',
  NEW_CUSTOMER_MEETING_ID: 'newCustomerMeetingId',
  PAYROLL_OV_MEETING_ID: 'PAYROLL_OV_MEETING_ID',
  SCHEDULE_TRAINING_MEETING_ID: 'scheduleTrainingMeetingId',
});

export default CookieNames;
