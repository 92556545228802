import Vue from 'vue';

export const state = () => ({
  state: () => ({
    loading: false,
    data: null,
    current_step: null,
  }),
});

export const getters = {
  get(state) {
    return state.data;
  },

  id(state) {
    if (!state.data) {
      return false;
    }

    return state.data._id;
  },
};

export const mutations = {
  set(state, pip) {
    state.loading = false;
    Vue.set(state, 'data', pip);
  },

  setStatus(state, status) {
    state.data.status = status;
  },

  setWizard(state, wizard) {
    state.data.loading = false;
    state.data.wizard = wizard;
  },

  setNotes(state, notes) {
    state.data.loading = false;
    state.data.notes = notes;
  },

  loading(state) {
    state.loading = true;
  },

  setCurrentStep(state, route) {
    Vue.set(state, 'current_step', route);
  },
};

export const actions = {
  get({ commit }, pip_id) {
    commit('loading');

    return this.$axios.get(`/v0/app/pip/${pip_id}`).then((res) => {
      commit('set', res.data);

      return res.data;
    });
  },

  updateWizard({ commit, getters }, wizard) {
    commit('loading');

    return this.$axios
      .post(`/v0/app/pip/${getters.id}/update`, { wizard })
      .then((res) => {
        commit('setWizard', res.data.wizard);
        commit('setStatus', res.data.status);

        return res.data.wizard;
      });
  },

  completePip({ commit, getters }) {
    commit('loading');

    return this.$axios
      .post(`/v0/app/pip/${getters.id}/complete`)
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  addSectionNote({ commit, getters }, note) {
    commit('loading');

    return this.$axios
      .post(`/v0/app/pip/${getters.id}/add-note`, { note })
      .then((res) => {
        commit('setNotes', res.data.notes);

        return res.data.notes;
      });
  },

  deleteSectionNote({ commit, getters }, note_id) {
    return this.$axios
      .post(`/v0/app/pip/${getters.id}/delete-note/${note_id}`)
      .then((res) => {
        commit('setNotes', res.data.notes);

        return res.data.notes;
      });
  },
};
