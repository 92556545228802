export const UserPreference = Object.freeze({
  SHOW_ANNUAL_AS_HOURLY: 'SHOW_ANNUAL_AS_HOURLY',
  INPUT_EARNINGS_AS_DECIMAL: 'INPUT_EARNINGS_AS_DECIMAL',
  UPDATED_WORKER_INFO: 'UPDATED_WORKER_INFO',
});

export const PayrollTypes = Object.freeze({
  REGULAR: 'REGULAR',
  OFF_CYCLE: 'OFF_CYCLE',
});

export const PayrollStatus = Object.freeze({
  DRAFT: 'DRAFT',
  INITIAL: 'INITIAL',
  PAID: 'PAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
});

export const PayrollActionState = Object.freeze({
  ...PayrollStatus,
  DUE_SOON: 'DUE_SOON',
  MISSED_DIRECT_DEPOSIT: 'MISSED_DIRECT_DEPOSIT',
  OVERDUE: 'OVERDUE',
  MANUAL_CHECKS: 'MANUAL_CHECKS',
});

export const PayerType = Object.freeze({
  COMPANY: 'COMPANY',
  EMPLOYEE: 'EMPLOYEE',
});

export const PayTypes = Object.freeze({
  HOURLY: 'HOURLY',
  SALARY: 'SALARY',
  CONTRACT: 'CONTRACT',
  CONTRACTOR: 'CONTRACTOR',
});

export const PayTypeLabels = Object.freeze({
  HOURLY: 'Hourly',
  SALARY: 'Salary',
});

// TODO: (kevin.lee) refactor these constants
// Let's find a way to get rid of either
// EmployeeType or EmploymentTypes
export const EmployeeType = Object.freeze({
  FULLTIME: 'fulltime',
  PARTTIME: 'parttime',
});

export const ContractorType = Object.freeze({
  INDIVIDUAL: 'individual',
  BUSINESS: 'business',
});

export const EmploymentTypes = Object.freeze({
  CONTRACTOR: 'Contractor',
  FULL_TIME: 'Full Time',
  PART_TIME: 'Part Time',
});

export const ContractorTypes = Object.freeze({
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
});

export const PaymentMethod = Object.freeze({
  MANUAL: Object.freeze({
    label: 'Manual Check',
    value: 'MANUAL',
  }),
  DIRECT_DEPOSIT: Object.freeze({
    label: 'Direct Deposit',
    value: 'DIRECT_DEPOSIT',
  }),
});

export const Withholdings = Object.freeze({
  FORCE_SUPPLEMENTAL_WITHHOLDING: 'FORCE_SUPPLEMENTAL_WITHHOLDING',
  APPLY_BENEFITS: 'APPLY_BENEFITS',
  APPLY_POST_TAX_DEDUCTIONS: 'APPLY_POST_TAX_DEDUCTIONS',
});

export const Timer = Object.freeze({
  SHORT: 1000,
  MEDIUM: 3000,
  LONG: 5000,
  XLONG: 10000,
});

export const ProcessingPeriod = Object.freeze({
  FOUR_DAY: 'FOUR_DAY',
  TWO_DAY: 'TWO_DAY',
});

export const PayFrequency = Object.freeze({
  WEEKLY: {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  BIWEEKLY: {
    label: 'Every other week (Biweekly)',
    value: 'BIWEEKLY',
  },
  SEMIMONTHLY: {
    label: 'Twice per month (Semimonthly)',
    value: 'SEMIMONTHLY',
  },
  MONTHLY: {
    label: 'Monthly',
    value: 'MONTHLY',
  },
});

// Using stringed INT values to match JS getDay() spec
export const PayDayOfWeek = Object.freeze({
  MONDAY: {
    label: 'Monday',
    value: '1',
  },
  TUESDAY: {
    label: 'Tuesday',
    value: '2',
  },
  WEDNESDAY: {
    label: 'Wednesday',
    value: '3',
  },
  THURSDAY: {
    label: 'Thursday',
    value: '4',
  },
  FRIDAY: {
    label: 'Friday',
    value: '5',
  },
});

export const PayDayOfSemiMonth = Object.freeze({
  FIRST_AND_MID: {
    label: '1st and 15th of the month',
    value: '1',
  },
  MID_AND_LAST: {
    label: '15th and last day of the month',
    value: '15',
  },
});

export const DateFormat = Object.freeze({
  MM_DD_YYYY: 'MM/dd/yyyy',
  WD_MM_DD_YYYY: 'E MM/dd/yyyy',
  YYYY_MM_DD: 'yyyy-MM-dd',
});

export const StaffType = Object.freeze({
  EMPLOYEE: 'EMPLOYEE',
  CONTRACTOR: 'CONTRACTOR',
});

export const Route = Object.freeze({
  PAYROLL: '/payroll',
  PAYROLL_SETUP: '/payroll-setup',
  PAYROLL_SETUP_CONFIRMATION: '/payroll-setup/signatory-confirmation',
  PAYROLL_SETUP_WORKERS: '/payroll/worker-pay',

  MY_PAY: '/my-pay',
  MY_PAY_SETUP: '/my-pay/setup',
});
