<template>
  <div class="grid grid-cols-2 gap-4">
    <NumberInput
      :id="`${baseId}-h`"
      :value="hours"
      postfix="H"
      placeholder="0"
      min="0"
      :disabled="disabled"
      @input="handleHoursUpdate"
    />

    <NumberInput
      :id="`${baseId}-m`"
      :value="minutes"
      postfix="M"
      placeholder="0"
      min="0"
      max="59"
      :disabled="disabled"
      @input="handleMinutesUpdate"
    />
  </div>
</template>

<script>
import shortid from 'shortid';
import { NumberInput } from '@bambeehr/pollen';
import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'HoursMinutesInput',
  components: { NumberInput },
  props: {
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { attrs }) {
    const hours = computed(() => Math.floor(props.value / 60));
    const minutes = computed(() => props.value % 60);
    const baseId = computed(() => attrs.id || shortid.generate());

    function updateValue(newHours, newMinutes) {
      const newTotal = newHours * 60 + newMinutes;
      this.$emit('input', newTotal);
    }

    function handleHoursUpdate(newHours) {
      this.updateValue(Math.floor(newHours), minutes.value);
    }

    function handleMinutesUpdate(newMinutes) {
      this.updateValue(hours.value, Math.floor(newMinutes));
    }

    return {
      hours,
      minutes,
      baseId,
      updateValue,
      handleHoursUpdate,
      handleMinutesUpdate,
    };
  },
};
</script>
