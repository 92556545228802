import { computed, ref, watchEffect } from '@nuxtjs/composition-api';
import useCredits from '@/modules/Nectar/hooks/useCredits/useCredits';
import startOfMonth from 'date-fns/startOfMonth';
import addMonths from 'date-fns/addMonths';
import { formatDate } from '@/utils/date';
import DateFormat from '@/constants/DateFormat';
import { serviceList } from '@/modules/ManagedServices/consts/managedServices';
import { managedServicesEvents } from '@/modules/ManagedServices/consts/events';
import bam from '@/lib/bam';

const selectedService = ref();
const showScheduleModal = ref(false);

const GET_STARTED = 'get-started';
const handlers = serviceList.reduce(
  (acc, service) => ({
    ...acc,
    [`${GET_STARTED}-${service.slug}`]: () => {
      selectedService.value = service;
      showScheduleModal.value = true;
      bam.trackEvent(managedServicesEvents.SCHEDULE_SERVICE_MODAL_LAUNCHED, {
        service: service.slug,
      });
    },
  }),
  {}
);

enum Directory {
  PURCHASED = 'purchased',
  MARKETING = 'marketing',
}

const showPurchaseModal = ref(false);
const handlePurchase = () => {
  showPurchaseModal.value = true;
  bam.trackEvent(managedServicesEvents.PURCHASE_SUBSCRIPTION_MODAL_LAUNCHED);
};

const showPurchasedInterstitial = ref(false);

const handleSubscribed = () => {
  showPurchaseModal.value = false;
  showPurchasedInterstitial.value = true;
  useCredits(true);
  bam.trackEvent(managedServicesEvents.PURCHASED_SUBSCRIPTION);
};

const useManagedServices = (force = false) => {
  const { all, isLoading } = useCredits(force);

  const mangedServicesSub = computed(() =>
    all.value?.find((s) => s.key === 'MANAGED_HR_SERVICES')
  );
  const hasActiveSubscription = computed(
    () => mangedServicesSub.value?.activeSubscription
  );
  // Customers who were given a credit grant but do not have an active subscription
  const hasCreditGrant = computed(
    () =>
      !!mangedServicesSub.value?.creditBalance && !hasActiveSubscription.value
  );
  const totalCredits = computed(
    () => mangedServicesSub.value?.creditBalance ?? 0
  );

  const renewsOnDate = formatDate(
    startOfMonth(addMonths(new Date(), 1)),
    '',
    DateFormat.EEEE_MMMM_DO
  );

  const rootUrl = ref('');
  watchEffect(() => {
    rootUrl.value =
      !rootUrl.value &&
      (isLoading.value || hasActiveSubscription.value === undefined)
        ? ''
        : `${process.env.FRAMER_URL}/managed-services/${
            process.env.MANAGED_SERVICES_VERSION
          }/${
            hasActiveSubscription.value || hasCreditGrant.value
              ? Directory.PURCHASED
              : Directory.MARKETING
          }`;
  });

  const getFullUrl = (page?: string) => {
    if (!page) {
      return rootUrl.value;
    }

    return rootUrl.value ? `${rootUrl.value}/${page}` : '';
  };

  return {
    hasActiveSubscription,
    totalCredits,
    renewsOnDate,
    isLoading,
    rootUrl,
    getFullUrl,
    handlers,
    showPurchaseModal,
    handlePurchase,
    showPurchasedInterstitial,
    handleSubscribed,
    showScheduleModal,
    selectedService,
    hasCreditGrant,
  };
};

export default useManagedServices;
