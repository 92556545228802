import { Address, Workplace } from '@/gql/generated';
import { getFullAddress } from '@/utils/address';

export interface WorkplaceOption {
  label: string;
  value: string;
  default: boolean;
  address: Address;
  id: string;
}

// Formats a workplace object into a WorkplaceOption for the Pollen Select
export const formatWorkplaceAsOption = (
  workplace: Workplace
): WorkplaceOption => ({
  label: `${workplace?.name} - ${getFullAddress(
    workplace?.address
  )?.toUpperCase()}`,
  value: String(workplace?.id),
  default: workplace?.default,
  address: workplace?.address,
  id: workplace?.id,
});
