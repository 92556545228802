/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum BundleStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum Language {
  ENGLISH = 'EN',
  SPANISH = 'ES',
}

export enum LanguageLabels {
  ENGLISH = 'English',
  SPANISH = 'Spanish',
}

export enum UserRole {
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
}

export enum State {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export interface EnrolledUser {
  completedAt?: Date;
  enrolledAt?: Date;
  language: Language;
  role: UserRole;
  startedAt?: Date;
  state?: State;
  userId: String;
  userMngId: String;
}

export interface User {
  language?: Language;
  role?: UserRole;
  state?: State;
  userId: String;
  userMngId?: String;
}

export interface UserCourse {
  completedAt?: Date;
  description?: String;
  doceboCourseId: String;
  enrolledAt?: Date;
  id: String;
  languages: String[];
  name: String;
  refundedAt?: Date;
  startedAt?: Date;
  state: String[];
}

export interface BundleUser {
  courses: UserCourse[];
  userId: String;
  language: Language;
}

export interface BundleTopic {
  name: String;
  topicId: String;
  enrollments?: EnrolledUser[];
}

export interface CompanyBundle {
  bundleId: String;
  description?: String;
  name: String;
  totalSeats: Number;
  unfilledSeats: Number;
  topics: BundleTopic[];
}

export interface GetCompanyResponse {
  companyId: String;
  users?: User[];
  purchasedProducts?: {
    bundles: CompanyBundle[];
  };
}

export interface GetCompanyInput {
  data: {
    companyId: String;
  };
}

export interface ListTicketsInput {
  data: {
    companyId: String;
  };
}

export interface GetUserCoursesInput {
  data: {
    userId: String;
  };
}

export interface PurchaseBundleSeatsInput {
  data: {
    numSeats: Number;
    companyId: String;
    skipPayment?: boolean;
  };
}

export interface RemoveBundleSeatsInput {
  data: {
    numSeats: Number;
    companyId: String;
    skipRefund?: boolean;
  };
}

export interface PurchaseSeatsResponse {
  error?: String;
  numPurchased: Number;
  totalSeats: Number;
}

export interface RemoveSeatsResponse {
  error?: String;
  numRemoved: Number;
  totalSeats: Number;
}
export interface EnrollUsersResponse {
  companyId: String;
  enrolled: Boolean;
  errors?: String[];
}

export interface EnrollUsersInput {
  data: {
    companyId: String;
    users: EnrolledUser[];
  };
}

export interface TopicEnrolledTicket {
  certificateDownloadUrl?: string;
  completedAt?: string;
  enrolledAt?: string;
  language?: string;
  role?: string;
  startedAt?: string;
  state?: string;
  userId: string;
  userMngId: string;
}
