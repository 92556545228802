import useCurrentUser from '@/hooks/useCurrentUser';
import useEmployees from '@/hooks/useEmployees';
import { getInitials } from '@/utils/nameFormatter';
import {
  computed,
  ComputedRef,
  Ref,
  ref,
  watch
} from '@nuxtjs/composition-api';
import { User } from './user';

const initialized: Ref<Boolean> = ref(false);
const pending: ComputedRef<boolean> = computed(() => {
  return !initialized.value; // If we ever needed to add more values here we can.
});

const allEmployees: Ref<any[]> = ref([]);
const decoratedUsers: ComputedRef<User[]> = computed(() => {
  return allEmployees.value.map((ee) => {
    const firstName = ee.profile?.first_name || '';
    const lastName = ee.profile?.last_name || '';

    return {
      id: ee.id,
      firstName,
      lastName,
      avatarUrl: ee.profile?.avatar_url || '',
      initials: getInitials(firstName, lastName),
      role: ee.profile?.role || '',
      isManager: !!ee.permissions?.manager,
    };
  });
});

const { currentUser } = useCurrentUser();

// TODO: the innards of this becomes obsolete because we are now going to loop through everyone and add their properties. Would just need to clean up spots in the code that is calling `getUser`
const getUser = (id: string) => {
  const user = computed(() => {
    return currentUser.value?._id === id
      ? {
          ...currentUser.value,
          id: currentUser.value._id,
        }
      : allEmployees.value.find((ee) => ee.id === id);
  });

  const exists: ComputedRef<boolean> = computed(() => {
    return !!user.value;
  });

  const avatarUrl: ComputedRef<string> = computed(() => {
    return user.value?.profile?.avatar_url || '';
  });

  const firstName: ComputedRef<string> = computed(() => {
    return user.value?.profile?.first_name || '';
  });

  const lastName: ComputedRef<string> = computed(() => {
    return user.value?.profile?.last_name || '';
  });

  const initials = computed(() => {
    return getInitials(firstName.value, lastName.value);
  });

  const role: ComputedRef<string> = computed(() => {
    return user.value?.profile?.role || '';
  });

  const isManager: ComputedRef<boolean> = computed(() => {
    return !!user.value?.permissions?.manager;
  });

  return {
    exists,
    avatarUrl,
    firstName,
    lastName,
    role,
    isManager,
    initials,
  };
};

const useTrainingUser = () => {
  // if not initialized, initialize
  if (!initialized.value) {
    const { all: allEEs } = useEmployees();
    watch(
      allEEs,
      () => {
        allEmployees.value = [...(allEEs?.value || [])];
      },
      { immediate: true }
    );

    // Cache initialized value
    initialized.value = true;
  }

  return {
    pending,
    decoratedUsers,
    getUser,
  };
};

export default useTrainingUser;
