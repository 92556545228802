import useEmployerTrainingList from '@/modules/Training/hooks/useEmployerTrainingList/useEmployerTrainingList';
import useTrainingEnrollment from '@/modules/Training/hooks/useTrainingEnrollment/useTrainingEnrollment';
import { Language, UserRole } from '@/gql/generated';
import { computed, ref } from '@nuxtjs/composition-api';
import currency from '@bambeehr/currency';

export interface SelectedTraining {
  bundleId: string | undefined;
  topicId: string | undefined;
  name: string;
  unfilledSeats: number;
  price: number;
}

let isInitialized;

const { bulkEnrollStaff, bulkPurchaseSeats, shouldEnroll, isPending } =
  useTrainingEnrollment();

const {
  fetchCompanyTrainings,
  isFetching,
  availableAndPurchasedTrainingLists,
} = useEmployerTrainingList();

const currentUserId = ref('');
const currentWorkerName = ref('');

const isLoading = ref(isFetching || false);
const isSaving = ref(isPending || false);

const selectedTrainingCourses = ref<SelectedTraining[]>([]);

const handleTrainingCourseSelected = (training: SelectedTraining) => {
  selectedTrainingCourses.value = [...selectedTrainingCourses.value, training];
};

const handleTrainingCourseUnselected = (training: SelectedTraining) => {
  selectedTrainingCourses.value = selectedTrainingCourses.value.filter(
    (item) =>
      item.bundleId !== training.bundleId || item.topicId !== training.topicId
  );
};

// seats to be purchased are the ones with unfilled seats
const selectedCoursesToBePurchased = computed(() =>
  selectedTrainingCourses.value.filter((item) => item.unfilledSeats === 0)
);

// only calculated on courses with unfilled seats
export const getCoursesToBePurchasedInfo = (
  coursesToBePurchased: SelectedTraining[]
) => {
  const bundles = coursesToBePurchased.filter((item) => !!item.bundleId);
  const topics = coursesToBePurchased.filter((item) => !!item.topicId);

  const allCoursesPricesMap = [
    ...availableAndPurchasedTrainingLists.value.all,
    ...availableAndPurchasedTrainingLists.value.recommended,
  ]
    .filter((course) => !!course.price)
    .reduce((acc, cur) => {
      acc.set(cur.id, cur.price);

      return acc;
    }, new Map());

  const calculateCost = (courses: SelectedTraining[]) =>
    courses.reduce((sum, course) => {
      const identifier = course.bundleId || course.topicId;
      const coursePrice = allCoursesPricesMap.get(identifier);

      const total = sum + coursePrice;

      return total;
    }, 0);

  const programCost = calculateCost(bundles);
  const topicCost = calculateCost(topics);

  const formatCostTotal = (cost: number) =>
    currency(Number(cost)).format({ fromCents: true });

  const programCostTotal = formatCostTotal(programCost);
  const topicCostTotal = formatCostTotal(topicCost);
  const totalCost = formatCostTotal(programCost + topicCost);

  return {
    programCostTotal,
    topicCostTotal,
    totalCost,
    programCount: bundles.length,
    topicCount: topics.length,
  };
};

// Info used to display the total cost of the courses to be purchased
// getting information from trainings that have unfilled seats
const selectedCoursesToBePurchasedInfo = computed(() =>
  getCoursesToBePurchasedInfo(selectedCoursesToBePurchased.value)
);

const getBundleIds = (courses) =>
  courses.filter((item) => item.bundleId !== '').map((item) => item.bundleId);

const getTopicIds = (courses) =>
  courses.filter((item) => item.topicId !== '').map((item) => item.topicId);

const selectedTopicIds = computed(() =>
  getTopicIds(selectedTrainingCourses.value)
);

const selectedBundleIds = computed(() =>
  getBundleIds(selectedTrainingCourses.value)
);

const topicIdsToBePurchased = computed(() =>
  getTopicIds(selectedCoursesToBePurchased.value)
);

const bundleIdsToBePurchased = computed(() =>
  getBundleIds(selectedCoursesToBePurchased.value)
);

let emit;

const resetTrainingState = () => {
  currentUserId.value = '';
  isLoading.value = false;
  isSaving.value = false;
  selectedTrainingCourses.value = [];
};

const handleSkipEnroll = () => {
  emit('next');
};

const handleEnroll = async () => {
  await bulkEnrollStaff(
    {
      userId: currentUserId.value,
      language: Language.En,
      role: UserRole.Employee,
    },
    selectedTopicIds.value,
    selectedBundleIds.value,
    currentWorkerName.value
  );
  resetTrainingState();
  emit('next');
};

// TODO - https://bambee.atlassian.net/browse/RAP-1195
// bug where purchase and enroll is called multiple times
const handlePurchaseAndEnroll = async () => {
  // Purchase a single seat for each course that has been selected and has unfilled seats
  await bulkPurchaseSeats(
    topicIdsToBePurchased.value,
    bundleIdsToBePurchased.value
  );

  if (shouldEnroll.value) {
    // Enroll the user in the courses that have been selected
    await bulkEnrollStaff(
      {
        userId: currentUserId.value,
        language: Language.En,
        role: UserRole.Employee,
      },
      selectedTopicIds.value,
      selectedBundleIds.value,
      currentWorkerName.value
    );
    emit('next');
  }
  resetTrainingState();
};

const save = async () => {
  if (selectedCoursesToBePurchased.value.length) {
    await handlePurchaseAndEnroll();
  } else {
    await handleEnroll();
  }

  fetchCompanyTrainings();
};

const useAddWorkerTraining = (
  emitHandler?,
  userId?: string,
  workerName?: string
) => {
  if (emitHandler) {
    emit = emitHandler;
  }
  if (userId) {
    currentUserId.value = userId;
  }

  if (workerName) {
    currentWorkerName.value = workerName;
  }

  if (!isInitialized) {
    fetchCompanyTrainings();
    isInitialized = true;
  }

  return {
    isLoading,
    isSaving,
    selectedTrainingCourses,
    selectedCoursesToBePurchasedInfo,
    selectedCoursesToBePurchased,
    save,
    handleTrainingCourseSelected,
    handleTrainingCourseUnselected,
    handleEnroll,
    handlePurchaseAndEnroll,
    handleSkipEnroll,
  };
};

export default useAddWorkerTraining;
