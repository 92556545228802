








































import { TypeDisplay, ModalDialog, BaseCard } from '@bambeehr/pollen';
import CalendlyEvent from '@/components/scheduler/CalendlyEvent';
import CalendlyCard from '@/components/scheduler/CalendlyCard';
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import useCurrentUser from '@/hooks/useCurrentUser';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import { CallKinds } from '@/constants/calendly';
import { generateCalendlyURL } from '@/utils/calendly-helpers';
import ScheduleBenefitsConfirmation from './ScheduleBenefitsConfirmation';
import { setUserPreference } from '@/utils/userPreference';
import LocalStorageKey from '@/modules/Benefits/const/localStorageKey';
import useContentful from '@/hooks/useContentful/useContentful';
import { useApolloMutation } from '@/gql/apolloWrapper';

import { useTrackProductInterestMutation } from '@/gql/generated';

export const getCalendlyEventId = (uri: string) => {
  return uri.split('/').pop();
};

export const getCalendlyUrl = (companyName, user) =>
  generateCalendlyURL({
    answers: ['', `1${user?.profile?.phone}`],
    guest: user,
    callKind: CallKinds.BENEFITS_OVERVIEW,
    slug: `${process.env.CALENDLY_BENEFITS_TEAM}/benefits-overview`,
  });

const CONTENT_ID = '2KQkcSadAMaNmtHe2nKAt3';

export default defineComponent({
  name: 'ScheduleBenefitsCallModal',
  components: {
    ModalDialog,
    TypeDisplay,
    CalendlyCard,
    CalendlyEvent,
    BaseCard,
    ScheduleBenefitsConfirmation,
  },
  setup() {
    const { mutate: trackInterest } = useApolloMutation(
      useTrackProductInterestMutation,
      {},
      // Silent fail
      { handleErrors: false }
    );

    const { currentUser } = useCurrentUser();
    const { company, companyId } = useCurrentCompany();
    const benefitsMeetingId = ref('');

    const { fetchContent } = useContentful();
    const content = ref();

    (async () => {
      content.value = await fetchContent(CONTENT_ID);
    })();

    const calendlyUrl = computed(() => getCalendlyUrl('', currentUser.value));

    const handleScheduled = (data) => {
      const meetingId = data.payload.event.uri || '';

      if (meetingId) {
        benefitsMeetingId.value = getCalendlyEventId(meetingId) || '';
        setUserPreference(
          `${LocalStorageKey.BENEFITS_OVERVIEW}-${companyId.value}`,
          benefitsMeetingId.value
        );

        trackInterest({
          data: {
            companyId: companyId.value,
            interests: {
              benefits: 'Very Interested',
            },
            origin: 'Schedule Benefits Call Modal',
          },
        });
      }
    };

    return {
      calendlyUrl,
      handleScheduled,
      benefitsMeetingId,
      content,
    };
  },
});
