import { hasPurchasedTraining as hasPurchasedTrainingService } from '@/gql/services/trainingService';

let hasPurchased: Boolean;

const hasPurchasedTraining = async (companyId: String): Promise<Boolean> => {
  if (hasPurchased) {
    return true;
  }

  hasPurchased = await hasPurchasedTrainingService(companyId);

  return hasPurchased;
};

export default hasPurchasedTraining;
