import { UserRoles } from '@/constants/users';

export const reservationValues = [
  'interested - reserved slot for january',
  'interested - beta tester candidate before january',
  'very interested',
];

export const hasReserved = (company) => {
  const interestLevel = company?.states?.payrollReservation?.outcome;

  return (
    !!interestLevel && reservationValues.includes(interestLevel.toLowerCase())
  );
};

export const canReserve = (user) => {
  /**
   * Users can reserve a payroll slot if:
   * 1. They are NOT employees
   * 2. They are NOT Square leads/customers <-- may need to revisit
   * this in the future
   */
  if (!user) {
    return false;
  }

  if (
    user._company?.marketing?.howDidYouHear === 'square' ||
    user.role === UserRoles.EMPLOYEE
  ) {
    return false;
  }

  return true;
};

export default null;
