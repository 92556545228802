










import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import TourCard from '@/components/TourCard';

interface Btn {
  label: string;
  onClick?: () => void;
  buttonType?: string;
  level?: string;
  class?: string;
  preventDefault?: boolean;
}

export interface FloatingTourStep {
  imgSrc: string;
  nextBtn: Btn;
  prevBtn: Btn;
  title: string;
  description: string;
}

enum Emits {
  NEXT = 'next',
  PREV = 'prev',
  COMPLETE = 'complete',
}

export default defineComponent({
  name: 'FloatingTour',
  components: {
    TourCard,
  },
  props: {
    tour: {
      type: Array as () => Array<FloatingTourStep>,
      default: () => [],
    },
    step: {
      type: Number,
      default: 0,
    },
  },
  emits: [Emits.NEXT, Emits.PREV, Emits.COMPLETE],
  setup(props, { emit }) {
    const currentStepNum = ref(props.step);
    const tourLength = computed(() => props.tour.length);

    const goNext = () => {
      if (currentStepNum.value < tourLength.value - 1) {
        currentStepNum.value += 1;
        emit(Emits.NEXT, currentStepNum.value);
      }
    };

    const goPrev = () => {
      if (currentStepNum.value >= 1) {
        currentStepNum.value -= 1;
        emit(Emits.PREV, currentStepNum.value);
      }
    };

    const decoratedTour = computed(() =>
      props.tour.map((tour, index) => ({
        ...tour,
        nextBtn: {
          ...tour.nextBtn,
          onClick: () => {
            if (!tour.nextBtn.preventDefault) {
              goNext();
            }
            // +1 to send next step number
            tour.nextBtn.onClick?.();

            if (index === tourLength.value - 1) {
              emit(Emits.COMPLETE);
            }
          },
        },
        prevBtn: {
          ...tour.prevBtn,
          onClick: () => {
            if (!tour.prevBtn.preventDefault) {
              goPrev();
            }

            tour.prevBtn.onClick?.();
          },
        },
      }))
    );

    const currentStep = computed(
      () => decoratedTour.value[currentStepNum.value]
    );

    // Delay to enable animation on render
    const shouldAnimate = ref(false);
    onMounted(() => {
      setTimeout(() => {
        shouldAnimate.value = true;
      }, 200);
    });

    return {
      decoratedTour,
      currentStep,
      shouldAnimate,
    };
  },
});
