import {
  ADDRESS_FRAGMENT,
  BANK_FRAGMENT,
  COMPANY_GOALS_FRAGMENT,
  COMPANY_STATUS_FRAGMENT,
  ITEM_FRAGMENT,
  ONBOARDING_FRAGMENT,
  PAYMENT_FRAGMENT,
  PAYROLL_FRAGMENT,
  PAYROLL_PREVIEW_FRAGMENT,
  PAYROLL_WITHOUT_NESTED_PAYMENTS_FRAGMENT,
} from '@/gql/queries/payroll_queries.gql';

import gql from 'graphql-tag';

export const ASSIGN_POLICIES_MUTATION = gql`
  mutation AssignUserToPolicies($data: AssignUserToPoliciesInput!) {
    assignUserToPolicies(data: $data) {
      id
    }
  }
`;

export const ENROLL_MFA_DEVICE = gql`
  mutation EnrollMFADevice($input: AuthMfaSmsEnrollmentInput!) {
    enrollMfaDevice(input: $input) {
      nonce
      expiration
    }
  }
`;

export const CREATE_MFA_CHALLENGE = gql`
  mutation createMfaChallenge($reason: String!, $type: AuthMfaType!) {
    createChallenge(reason: $reason, type: $type) {
      nonce
      metadata {
        phoneLast4
      }
    }
  }
`;

export const RESEND_CHALLENGE = gql`
  mutation resendChallenge(
    $nonceId: String!
    $reason: String!
    $type: AuthMfaType!
  ) {
    resendChallenge(nonceId: $nonceId, reason: $reason, type: $type) {
      nonce
      metadata {
        phoneLast4
      }
    }
  }
`;

export const CONFIRM_USER_MFA_ENROLLMENT = gql`
  mutation ConfirmUserMfaEnrollment($input: AuthMfaChallengeResponseInput!) {
    confirmUserMfaEnrollment(input: $input) {
      mfaType
      createdAt
      lastChallengedAt
      lastDevice
      backupCode
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const APPROVE_PAYROLL = gql`
  mutation approvePayroll($data: ApprovePayrollInput!) {
    approvePayroll(data: $data) {
      ...PayrollWithoutNestedPaymentsParts
    }
  }
  ${PAYROLL_WITHOUT_NESTED_PAYMENTS_FRAGMENT}
`;

export const DELETE_PAYROLL = gql`
  mutation DeletePayroll($data: DeletePayrollInput!) {
    deletePayroll(data: $data) {
      id
      deletedAt
    }
  }
`;

export const CREATE_PAYROLL = gql`
  mutation CreatePayroll($data: CreatePayrollInput!) {
    createPayroll(data: $data) {
      id
    }
  }
`;

export const INITIALIZE_PAYROLL = gql`
  mutation InitPayroll($data: InitializePayrollInput!) {
    initializePayroll(data: $data) {
      id
    }
  }
`;

export const UPDATE_PAYROLL = gql`
  mutation UpdatePayroll($data: UpdatePayrollInput!) {
    updatePayroll(data: $data) {
      ...PayrollParts
    }
  }
  ${PAYROLL_FRAGMENT}
`;

export const UPDATE_PAYROLL_ITEM = gql`
  mutation UpdatePayrollItem($data: UpdatePayrollItemInput!) {
    updatePayrollItem(data: $data) {
      ...ItemParts
    }
  }
  ${ITEM_FRAGMENT}
`;

export const UPDATE_CONTRACTOR_PAYMENT = gql`
  mutation UpdateContractorPayment($data: UpdateContractorPaymentInput!) {
    updateContractorPayment(data: $data) {
      ...PaymentParts
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const UPDATE_CHECKS = gql`
  mutation UpdatePayrollChecks($data: UpdatePayrollChecksInput!) {
    updatePayrollChecks(data: $data) {
      id
      items {
        id
        checkNumber
      }
      contractorPayments {
        id
        checkNumber
      }
      needsChecksEntered
    }
  }
`;

export const PREVIEW_PAYROLL = gql`
  mutation PreviewPayroll($id: ID!) {
    previewPayroll(id: $id) {
      ...PayrollPreviewParts
    }
  }
  ${PAYROLL_PREVIEW_FRAGMENT}
`;

export const GENERATE_EMPLOYEE_ONBOARDING_LINK = gql`
  mutation GenerateEmployeeOnboardingLink($id: ID!) {
    generateEmployeeOnboardingLink(id: $id)
  }
`;

export const GENERATE_CONTRACTOR_ONBOARDING_LINK = gql`
  mutation GenerateContractorOnboardingLink($id: ID!) {
    generateContractorOnboardingLink(id: $id)
  }
`;

export const GENERATE_COMPANY_ONBOARDING_LINK = gql`
  mutation GenerateCompanyOnboardingLink(
    $data: GenerateCompanyOnboardingLinkInput!
  ) {
    generateCompanyOnboardingLink(data: $data)
  }
`;

export const GENERATE_EMPLOYEE_POST_TAX_DEDUCTIONS_LINK = gql`
  mutation GenerateEmployeePostTaxDeductionsLink($id: ID!) {
    generateEmployeePostTaxDeductionsLink(id: $id)
  }
`;

export const GENERATE_COMPANY_PAYROLL_REPORTS_LINK = gql`
  mutation GenerateCompanyPayrollReportLink($id: ID!) {
    generateCompanyPayrollReportLink(id: $id)
  }
`;

export const CREATE_PAY_SCHEDULE = gql`
  mutation createPaySchedule(
    $data: CreatePayScheduleInput!
    $coreData: UpdateCoreCompanyInput!
  ) {
    createPaySchedule(data: $data) {
      id
    }
    updateCoreCompany(data: $coreData) {
      id
      payDayOfWeek
      payDayOfMonth
    }
  }
`;

export const UPDATE_WORKPLACE = gql`
  mutation UpdateWorkplace($data: UpdateWorkplaceInput!) {
    updateWorkplace(data: $data) {
      active
      address {
        ...AddressParts
      }
      default
      id
      name
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const CREATE_WORKPLACE = gql`
  mutation CreateWorkplace($data: CreateWorkplaceInput!) {
    createWorkplace(data: $data) {
      id
      name
      default
      address {
        ...AddressParts
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const CREATE_COMPANY_CHECK = gql`
  mutation CreateCompanyInCheck($data: CreateCompanyInCheckInput!) {
    createCompanyInCheck(data: $data) {
      id
      checkId
    }
  }
`;

export const UPGRADE_FINCH_CODE = gql`
  mutation upgradeFinchCode($data: UserFinchCodeInput!) {
    upgradeFinchCode(data: $data) {
      id
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($data: UpdateCompanyInput!) {
    updateCompany(data: $data) {
      id
      tosVersion
      needsPayScheduleAssistance
      excludedStaffIds
      deputyTosAcceptedAt
    }
  }
`;

export const SETUP_EMPLOYEE = gql`
  mutation SetupEmployee($data: SetupEmployeeInput!) {
    setupEmployee(data: $data) {
      bankAccounts {
        ...BankAccountParts
      }
      checkId
      checkOnboarding {
        id
        remainingSteps
        status
      }
      id
      payRate
      paymentMethodPreference
      payType
      profile {
        id
        firstName
        lastName
        title
        homeAddress {
          city
          line1
          state
          zip
        }
      }
      workplaces {
        id
        active
        address {
          ...AddressParts
        }
        default
        name
      }
    }
  }
  ${ADDRESS_FRAGMENT}
  ${BANK_FRAGMENT}
`;

export const SETUP_CONTRACTOR = gql`
  mutation SetupContractor($data: SetupContractorInput!) {
    setupContractor(data: $data) {
      bankAccounts {
        institutionName
        mask
      }
      checkId
      checkOnboarding {
        id
        remainingSteps
        status
      }
      id
      payRate
      paymentMethodPreference
      payType
      profile {
        id
        contractorBusinessName
        firstName
        lastName
        title
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_WORKPLACE = gql`
  mutation UpdateEmployeeWorkplace($data: UpdateEmployeeWorkplaceInput!) {
    updateEmployeeWorkplace(data: $data)
  }
`;

export const UPDATE_CONTRACTOR_WORKPLACE = gql`
  mutation UpdateContractorWorkplace($data: UpdateContractorWorkplaceInput!) {
    updateContractorWorkplace(data: $data)
  }
`;

export const SEND_STAFF_ONBOARDING_INVITE = gql`
  mutation SendStaffOnboardInvite($data: SendStaffOnboardInviteInput!) {
    sendStaffOnboardInvite(data: $data)
  }
`;

export const BULK_UPLOAD_TIME_ATTENDANCE = gql`
  mutation BulkEarningsImport($data: BulkEarningsImportInput!) {
    bulkEarningsImport(data: $data) {
      errors {
        errorMsg
        staffId
      }
      payroll {
        ...PayrollParts
      }
      successfulStaffIds
    }
  }
  ${PAYROLL_FRAGMENT}
`;

export const FINCH_MANUAL_SETUP_COMPLETED = gql`
  mutation FinchManualSetupCompleted($data: FinchManualSetupCompletedInput!) {
    finchManualSetupCompleted(data: $data)
  }
`;

export const CHECK_SYNC_COMPANY = gql`
  mutation CheckSyncCompany($data: CheckSyncCompanyInput!) {
    checkSyncCompany(data: $data) {
      ...CompanyStatusParts
    }
  }
  ${COMPANY_STATUS_FRAGMENT}
`;

export const DELETE_COMPANY_ROLE = gql`
  mutation DeleteCompanyRoles($data: DeleteCompanyRolesInput!) {
    deleteCompanyRolesById(data: $data) {
      id
      title
      companyId
      deletedAt
    }
  }
`;

export const CREATE_COMPANY_ROLE = gql`
  mutation CreateCompanyRoles($data: CreateMultipleRolesForCompanyInput!) {
    createCompanyRoles(data: $data) {
      companyId
      title
      id
      deletedAt
    }
  }
`;

export const UPDATE_COMPANY_ROLE = gql`
  mutation UpdateCompanyRoles($data: UpdateMultipleRolesForCompanyInput!) {
    updateCompanyRoles(data: $data) {
      companyId
      title
      id
      deletedAt
      createdAt
      workerRoles {
        id
        deletedAt
      }
    }
  }
`;

export const ASSIGN_WORKER_ROLES = gql`
  mutation AssignWorkerRoles($data: AssignWorkerRolesInput!) {
    assignWorkerRoles(input: $data) {
      id
      employment {
        id
        workerRoles {
          id
          isPrimary
          payRate
          deletedAt
          companyRole {
            id
            title
            deletedAt
            workerRoles {
              id
            }
          }
        }
      }
    }
  }
`;

export const SUBMIT_PAYROLL_ONBOARDING_FORM = gql`
  mutation SubmitPayrollOnboardingForm(
    $coreData: UpdateCoreCompanyInput!
    $payrollData: UpdateCompanyInput!
    $syncCompanyData: SyncCompanyInput!
    $enrollmentProfile: CreateEnrollmentProfileRequest!
    $checkCompanyInfo: CreateCompanyInCheckInput!
    $shouldCreateCompanyInCheck: Boolean!
  ) {
    updateCoreCompany(data: $coreData) {
      address {
        city
        line1
        line2
        state
        zipCode
      }
      fein
      name
      id
      numOfContractors
      numOfFullTimeHourlyEmployees
      numOfFullTimeSalaryEmployees
      numOfPartTimeEmployees
      website
    }
    updateCompany(data: $payrollData) {
      id
      checkIndustry
      processedPayrollsThisYear
      signatoryFirstName
      signatoryLastName
      signatoryTitle
      signatoryEmail
      onboardingOffersEmployeeBenefits
      onboardingHasGarnishmentsOrDeductions
      onboardingGarnishmentsOrDeductions
      onboardingGarnishmentsOrDeductionsOther
    }
    createCompanyInCheck(data: $checkCompanyInfo)
      @include(if: $shouldCreateCompanyInCheck) {
      id
      checkId
    }
    syncCompany(data: $syncCompanyData) {
      id
    }
    createEnrollmentProfile(data: $enrollmentProfile)
  }
`;

export const UPDATE_PAYROLL_SIGNATORY = gql`
  mutation UpdatePayrollSignatory($data: UpdateCompanyInput!) {
    updateCompany(data: $data) {
      id
      signatoryFirstName
      signatoryLastName
      signatoryTitle
      signatoryEmail
    }
  }
`;

export const SUBMIT_FEIN_VERIFICATION = gql`
  mutation VerifyFEINInCheck(
    $feinData: UpdateCoreCompanyInput!
    $verifyData: VerifyFEINInCheckInput!
    $syncCompanyData: SyncCompanyInput!
  ) {
    updateCoreCompany(data: $feinData) {
      fein
      name
      id
    }
    syncCompany(data: $syncCompanyData) {
      id
    }
    verifyFEINInCheck(data: $verifyData) {
      id
      feinVerification {
        id
        legalName
        federalEin
        status
        company
      }
      feinVerificationAttempts
    }
  }
`;

export const UPLOAD_REQUIREMENT_FILE = gql`
  mutation UploadRequirementFile($data: UploadFileInput!) {
    uploadFile(data: $data) {
      ...CompanyGoalParts
    }
  }
  ${COMPANY_GOALS_FRAGMENT}
`;

export const IMPORT_HOURS = gql`
  mutation ImportHours($data: SyncTimesheetsInput!) {
    syncTimesheets(data: $data) {
      contractors
      earnings
      employees
      # errors
      id
      lastUpdate
      paymentEntries
      synced
    }
  }
`;

export const SYNC_TIMESHEET_HOURS = gql`
  mutation SyncTimesheetHours($data: SyncTimesheetsInput!) {
    syncTimesheets(data: $data) {
      id
      lastUpdate
      synced
    }
  }
`;

export const IMPORT_TIMESHEETS_BY_WORKER_LIST = gql`
  mutation ImportTimesheetByWorkerList($data: ImportTimesheetsInput!) {
    importTimesheets(data: $data) {
      id
      synced
      imported
      syncErrors {
        id
        message
      }
      lastUpdate
      employees
      earnings
      contractors
      paymentEntries
    }
  }
`;

export const COMPLETE_TASK = gql`
  mutation CompleteTask($data: CompleteTaskInput!) {
    completeTask(data: $data) {
      id
      completedAt
      name
    }
  }
`;

export const COMPLETE_GOAL = gql`
  mutation CompleteGoal($data: CompleteGoalInput!) {
    completeGoal(data: $data) {
      id
      completedAt
      name
    }
  }
`;

export const START_TASK = gql`
  mutation StartTask($startTaskId: String!) {
    startTask(id: $startTaskId) {
      id
      name
      startedAt
    }
  }
`;

export const BULK_SETUP_STAFF_IN_PAYROLL = gql`
  mutation BulkSetupStaffInPayroll($data: BulkSetupStaffInput!) {
    bulkSetupStaff(data: $data)
  }
`;

export const SAVE_PAST_PAYROLL_USAGE = gql`
  mutation SavePastPayrollUsage($data: UpdateCompanyInput!) {
    updateCompany(data: $data) {
      id
      pastPayrollUsage
    }
  }
`;

export const UPDATE_COMPANY_ADDRESSES = gql`
  mutation UpdateCompanyAddresses($data: UpdateCoreCompanyInput!) {
    updateCoreCompany(data: $data) {
      id
      address {
        city
        line1
        line2
        state
        zip: zipCode
      }
      legalAddress {
        city
        line1
        line2
        state
        zip: zipCode
      }
    }
  }
`;

export const SAVE_BASIC_COMPANY_INFO = gql`
  mutation UpdateBasicCompanyInfo($data: UpdateCoreCompanyInput!) {
    updateCoreCompany(data: $data) {
      id
      name
      phone
      industry
    }
  }
`;

export const PURCHASE_BAMBEE_PLUS = gql`
  mutation PurchaseBambeePlus($data: PurchaseBambeePlusInput!) {
    purchaseBambeePlus(input: $data) {
      id
      status
      eligible
    }
  }
`;

export const SEND_ENROLLMENT_PROFILE = gql`
  mutation SendEnrollmentProfile(
    $syncCompanyData: SyncCompanyInput!
    $enrollmentProfile: CreateEnrollmentProfileRequest!
  ) {
    syncCompany(data: $syncCompanyData) {
      id
    }
    createEnrollmentProfile(data: $enrollmentProfile)
  }
`;

export const SAVE_COMPANY_SETTING = gql`
  mutation SaveCompanySetting($input: SaveCompanySettingInput!) {
    saveCompanySetting(input: $input) {
      id
      companyId
      type
      key
      value
    }
  }
`;

export const CancelBambeePlusDocument = gql`
  mutation CancelBambeePlus($data: PurchaseBambeePlusInput!) {
    cancelBambeePlus(input: $data) {
      id
      status
      eligible
      onboardingDueDate
    }
  }
`;

export const APPROVE_TRAINING_REQUEST = gql`
  mutation ApproveTrainingRequest($data: RequestApprovalInput!) {
    approveRequest(data: $data) {
      id
      status
      resourceId
    }
  }
`;

export const REJECT_TRAINING_REQUEST = gql`
  mutation RejectTrainingRequest($data: RequestRejectionInput!) {
    rejectRequest(data: $data) {
      id
      status
      resourceId
    }
  }
`;

export const MAKE_TRAINING_REQUEST = gql`
  mutation MakeTrainingRequest($data: TrainingRequestInput!) {
    trainingRequest(data: $data) {
      id
      status
      resourceId
    }
  }
`;

export const CANCEL_TRAINING_REQUEST = gql`
  mutation CancelTrainingRequest($data: RequestCancelInput!) {
    cancelRequest(data: $data) {
      id
      status
      resourceId
    }
  }
`;

export const GenerateCheckComponentLink = gql`
  mutation GenerateCheckComponentLink($data: CheckComponentInput!) {
    generateCheckComponentLink(data: $data) {
      id
      url
    }
  }
`;

export const JOIN_CALL_QUEUE = gql`
  mutation JoinCallQueue($data: EnqueueHotlineCallInput!) {
    enqueueHotlineCall(data: $data)
  }
`;

export const CANCEL_PENDING_HOTLINE_CALL = gql`
  mutation CancelPendingHotlineCall {
    cancelPendingHotlineCall
  }
`;

export const UPGRADE_CORE_PLAN = gql`
  mutation UpgradeCorePlan($data: UpgradeCorePlanInput!) {
    upgradeCorePlan(input: $data) {
      stripeId
    }
  }
`;

export const SaveComplexPayrollOnboarding = gql`
  mutation SaveComplexPayrollOnboarding($data: ComplexPayrollOnboardingInput!) {
    saveComplexPayrollOnboarding(data: $data)
  }
`;

export const PURCHASE_TIME_AND_ATTENDANCE = gql`
  mutation PurchaseTimeAndAttendance($data: PurchaseTimeAttendanceInput!) {
    purchaseTimeAttendance(data: $data) {
      id
      subscriptionId
    }
  }
`;

export const ACCEPT_OFFER = gql`
  mutation AcceptOffer($input: AcceptOfferInput!) {
    acceptOffer(input: $input)
  }
`;

export const SUBMIT_CANCEL_REQUEST = gql`
  mutation SubmitCancelRequest($input: CreateCancellationRequestInput!) {
    createCancellationRequest(input: $input) {
      id
      status
    }
  }
`;

export const TRACK = gql`
  mutation Track($data: AnalyticEvent!) {
    track(data: $data) {
      id
      success
      event
    }
  }
`;

export const REACTIVATE_COMPANY = gql`
  mutation ReactivateCompany($input: ReactivatePausedCompanyInput!) {
    reactivatePausedCompany(input: $input)
  }
`;

export const CREATE_PRODUCT_INTEREST = gql`
  mutation CreateProductInterest($input: CreateProductInterestInput!) {
    createProductInterest(input: $input) {
      id
      productKey
      productSubKey
    }
  }
`;

export const SET_DEFAULT_BILLING_PAYMENT_METHOD = gql`
  mutation SetDefaultBillingPaymentMethod(
    $input: SetDefaultPaymentMethodInput!
  ) {
    setDefaultPaymentMethod(input: $input) {
      id
      default
      expirationDate
      name
      nextAction
      status
      type
    }
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod($input: RemovePaymentMethodInput!) {
    removePaymentMethod(input: $input)
  }
`;

export const CONFIRM_MICRO_DEPOSIT = gql`
  mutation ConfirmMicroDeposit($input: ConfirmMicroDepositInput!) {
    confirmMicroDeposit(input: $input) {
      id
      default
      expirationDate
      name
      nextAction
      status
      type
    }
  }
`;

export const UPSERT_EMPLOYER_ONBOARDING = gql`
  mutation UpsertEmployerOnboarding($data: CoreOnboardingInput!) {
    upsertOnboarding(data: $data) {
      data {
        ...OnboardingParts
      }
      status
      id
      updatedAt
      createdAt
    }
  }
  ${ONBOARDING_FRAGMENT}
`;
export const ACCEPT_TRIAL_EXTENSION = gql`
  mutation AcceptTrialExtension($input: AcceptTrialOfferInput!) {
    acceptTrialOffer(input: $input)
  }
`;

export const ADD_SUBSCRIPTION = gql`
  mutation AddSubscription($data: PurchaseSubscriptionInput!) {
    purchaseSubscription(data: $data) {
      createdAt
      creditsPurchased
      id
      productKey
      status
      totalAmount
    }
  }
`;

export const REQUEST_PAYROLL_CANCELLATION = gql`
  mutation RequestPayrollCancellation($data: PayrollCancellationRequestInput!) {
    requestPayrollCancellation(data: $data)
  }
`;

export const CONSUME_NECTAR_CREDITS = gql`
  mutation ConsumeNectarCredits($data: RemoveCreditsInput!) {
    removeActiveCredits(data: $data) {
      id
      consumedAt
    }
  }
`;

export const PURCHASE_CREDITS = gql`
  mutation PurchaseNectarCredits($data: PurchaseInput!) {
    purchaseCredits(data: $data) {
      id
      createdAt
      status
      totalAmount
    }
  }
`;

export const PROVISION_TIME_AND_ATTENDANCE = gql`
  mutation ProvisionDeputyInstall($data: ProvisionDeputyInstallInput!) {
    provisionDeputyInstall(data: $data) {
      id
      installId
      hasAccessToken
    }
  }
`;
export const CREATE_PAYROLL_TIER_SUBSCRIPTION = gql`
  mutation CreatePayrollTierSubscription(
    $data: CreatePayrollSubscriptionInput!
  ) {
    createPayrollSubscription(data: $data) {
      name
      stripeId
      productCode
      serviceKey
      planValue
      amount
      createdAt
      startDate
      endDate
      status
      isDrp
    }
  }
`;

export const UPGRADE_DEPUTY_INSTALL = gql`
  mutation UpgradeInstall($data: UpgradeDeputyInstallInput!) {
    upgradeInstall(data: $data) {
      edition
      id
      installId
    }
  }
`;

export const CANCEL_TIME_AND_ATTENDANCE = gql`
  mutation CancelTimeAttendance($data: CancelTimeAttendanceInput!) {
    cancelTimeAttendance(data: $data)
  }
`;

export const CHAT_WITH_ASSISTANT = gql`
  mutation ChatWithAssistant($data: AIAssistantInput!) {
    chatWithAssistant(data: $data) {
      assistantId
      threadId
      next {
        context
        sample
      }
      messages {
        id
        role
        content {
          text {
            value
          }
        }
        metadata {
          artifact
          hidden
          type
          value
        }
      }
      artifact {
        jobDescription {
          html
          id
          status
        }
        summary {
          html
          status
        }
        policy {
          html
          title
          id
          status
        }
      }
    }
  }
`;

export const OPT_IN_AI = gql`
  mutation OptInAi($data: UpdateCoreCompanyInput!) {
    updateCoreCompany(data: $data) {
      id
      ai {
        optIn
      }
    }
  }
`;
