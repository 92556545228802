





































import { BaseButton } from '@bambeehr/pollen';
import useAddWorkerTraining from '@/modules/StaffManagement/components/AddStaffWizard/Training/useAddWorkerTraining';
import TrainingPurchaseSummary from './TrainingPurchaseSummary.vue';
import HexLoader from '@/components/HexLoader/HexLoader.vue';
import { computed } from '@nuxtjs/composition-api';

export default {
  components: {
    TrainingPurchaseSummary,
    BaseButton,
    HexLoader,
  },
  setup() {
    const {
      handleSkipEnroll,
      save,
      isSaving,
      isLoading,
      selectedCoursesToBePurchased,
      selectedTrainingCourses,
      selectedCoursesToBePurchasedInfo,
    } = useAddWorkerTraining();

    const numberOfCoursesToPurchase = computed(
      () => selectedCoursesToBePurchased.value.length
    );
    const numberOfTrainingCoursesSelected = computed(
      () => selectedTrainingCourses.value.length
    );

    const totalCost = computed(
      () => selectedCoursesToBePurchasedInfo.value.totalCost
    );

    return {
      isSaving,
      isLoading,
      numberOfCoursesToPurchase,
      totalCost,
      numberOfTrainingCoursesSelected,
      save,
      handleSkipEnroll,
    };
  },
};
