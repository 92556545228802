import { readonly, ref } from '@nuxtjs/composition-api';
import shortId from 'shortid';

export const ErrorCategories = Object.freeze({
  UNCAUGHT: 'UNCAUGHT',
  API: 'API',
});

class ErrorDetails {
  message = 'Something went wrong';

  trace = [];

  category = ErrorCategories.UNCAUGHT;

  code = null;

  raw = null;

  tags = [];
}

const formatApiErr = (errorObj = {}, formatted = {}) => {
  const { extensions, message } = errorObj;

  formatted.message = message;
  formatted.code = extensions?.code;
  formatted.trace = extensions?.exception?.stacktrace || [];
  formatted.raw = errorObj;
  formatted.category = ErrorCategories.API;

  return formatted;
};

const format = (error = {}, fromApi) => {
  const formatted = new ErrorDetails();

  if (fromApi) {
    return formatApiErr(error, formatted);
  }

  if (error.message) {
    formatted.message = error.message;
    formatted.stacktrace = [error.stack];
  }

  if (typeof error === 'string') {
    formatted.message = error;

    return formatted;
  }

  // Or do something else
  formatted.raw = error;

  return formatted;
};

const history = ref([]);
const errors = ref([]);

const logUnresolved = () => {
  console.info(
    `Current unresolved errors: ${errors.value.length}`,
    errors.value
  );
};

const logError = (error) => {
  const msgParts = [
    `Error Occurred!`,
    `Message: ${error.message}`,
    `Type: ${error.category}`,
    `Raw:`,
  ];
  console.error(msgParts.join('\n'), error.raw);
  logUnresolved();
};

const handleError = (error) => {
  let err = error;
  if (!(err instanceof ErrorDetails)) {
    err = format(error);
  }

  err.id = shortId.generate();
  errors.value.push(err);
  history.value.push(err);
  logError(err);

  return err;
};

const resolveError = (id) => {
  errors.value = errors.value.filter((n) => n.id !== id);
  logUnresolved();
};

const useErrorHandler = () => {
  return {
    format,
    errors: readonly(errors),
    history: readonly(history),
    handle: handleError,
    resolve: resolveError,
  };
};

export default useErrorHandler;
