import Cookies from '@/constants/Cookies';
import { WhiteListedRoutes } from '@/constants/Routes';
import SessionStorage from '@/constants/SessionStorage';
import { deleteCookie } from '@/lib/cookie-helpers';
import toRedirString from '@/utils/toRedirString';
import useMiddlewareContext from './hooks/useMiddlewareContext';

type WhitelistedPath = typeof WhiteListedRoutes[keyof typeof WhiteListedRoutes];

const authMiddleware: () => Promise<void> = () => {
  return new Promise((resolve) => {
    const { route, redirect, store, app } = useMiddlewareContext();
    const { path } = route;

    const whitelistedPaths = Object.values(WhiteListedRoutes);

    const pathIsWhitelisted = whitelistedPaths.includes(
      path as WhitelistedPath
    );

    const company = store.state?.current_user?._company;

    const expiredActivationToken = sessionStorage.getItem(
      SessionStorage.EXPIRED_ACTIVATION_TOKEN
    );

    if (company) {
      return resolve();
    }

    if (pathIsWhitelisted) {
      if (
        path.includes(WhiteListedRoutes.ACTIVATION_EXPIRED) &&
        !expiredActivationToken
      ) {
        return redirect(WhiteListedRoutes.LOGIN);
      }

      return resolve();
    }

    if (expiredActivationToken) {
      return redirect(WhiteListedRoutes.ACTIVATION_EXPIRED);
    }

    if (store.state.current_user) {
      if (!company) {
        return redirect(WhiteListedRoutes.REGISTRATION);
      }

      return resolve();
    }

    deleteCookie(Cookies.ACCESS_TOKEN);

    const redirString = toRedirString(path, route.query);

    return redirect(`${WhiteListedRoutes.LOGIN}?redir=${redirString}`);
  });
};

export default authMiddleware;
