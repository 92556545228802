import {
  useGetTrainingRequestsQuery,
  RequestStatus,
  useApproveTrainingRequestMutation,
  useRejectTrainingRequestMutation,
  CoreRequest,
  RequestResourceType,
} from '@/gql/generated';
import bam from '@/lib/bam';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';

import { ref } from '@nuxtjs/composition-api';

let init;

const requests = ref<CoreRequest[]>([]);
const isLoading = ref(true);

const getCompanyRequests = (force = false) => {
  const { onResult } = useApolloQuery(
    useGetTrainingRequestsQuery,
    { data: { status: RequestStatus.Pending } },
    undefined,
    {
      handleErrors: false,
      force,
    }
  );

  onResult(({ requests: res }) => {
    requests.value = res?.filter((r) =>
      [
        RequestResourceType.TrainingBundle,
        RequestResourceType.TrainingTopic,
      ].includes(r.resourceType)
    ) as CoreRequest[];
    isLoading.value = false;
  });
};

const approveRequest = (requestId: string) => {
  const { mutate, onDone, onError } = useApolloMutation(
    useApproveTrainingRequestMutation
  );

  mutate({ data: { requestId } });

  bam.track('course_catalog_er_approve_request', {
    action: '',
    content: {
      requestId,
    },
  });

  return {
    onDone,
    onError,
  };
};

const rejectRequest = (requestId: string) => {
  const { mutate, onDone, onError } = useApolloMutation(
    useRejectTrainingRequestMutation
  );

  mutate({ data: { requestId } });

  bam.track('course_catalog_er_reject_request', {
    content: {
      requestId,
    },
  });

  return {
    onDone,
    onError,
  };
};

const setupHook = async () => {
  getCompanyRequests();
  init = true;
};

const refreshCompanyRequests = (force = false) => {
  getCompanyRequests(force);
};

const useEmployerTrainingRequests = () => {
  if (!init) {
    setupHook();
  }

  return {
    requests,
    isLoading,
    refreshCompanyRequests,
    approveRequest,
    rejectRequest,
  };
};

export default useEmployerTrainingRequests;
