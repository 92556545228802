import sortBy from 'lodash/sortBy';

export const personalInsuranceLines = {
  'car-insurance': {
    id: 'car-insurance',
    name: 'Car Insurance',
    shortName: 'Car',
    description: '',
    featuredOrder: 1,
    icon: 'car.png',
  },
  'renters-insurance': {
    id: 'renters-insurance',
    name: "Renter's Insurance",
    shortName: 'Rent',
    description: '',
    featuredOrder: 2,
    icon: 'rent.png',
  },
  'homeowner-insurance': {
    id: 'homeowner-insurance',
    name: "Homeowner's Insurance",
    shortName: 'Home',
    description: '',
    featuredOrder: 3,
    icon: 'home.png',
  },
};

export const insuranceLines = {
  'workers-compensation-insurance': {
    id: 'workers-compensation-insurance',
    name: 'Workers Compensation',
    shortName: 'WC',
    description:
      "Workers Compensation covers an employee's lost wages and the cost of resulting medical treatment for a work-related injury or disease, as well as services needed to help the employee recover. WC includes coverage for Employer’s Liability.",
    featuredOrder: 1,
    icon: 'type-4.png',
  },
  'employment-practices-liability-insurance': {
    id: 'employment-practices-liability-insurance',
    name: 'Employment Practices Liability',
    shortName: 'EPLI',
    description:
      'EPLI protects your business in the case of a variety of employment-related issues, such as wrongful termination, discrimination, and sexual harassment.',
    featuredOrder: 2,
    icon: 'type-3.png',
  },
  'general-liability-insurance': {
    id: 'general-liability-insurance',
    name: 'General Liability',
    shortName: 'General',
    description:
      'General Liability is a foundational business insurance policy. It protects your business from claims of third party injury or property damage, a tenant’s legal liability to their landlord, products liability, advertising injury and more.',
    featuredOrder: 3,
    icon: 'type-6.png',
  },
  'professional-liability-insurance': {
    id: 'professional-liability-insurance',
    name: 'Professional Liability',
    shortName: 'Prof',
    description:
      "Synonymous with Errors & Omissions, this coverage protects you against claims of negligence and financial loss arising from mistaken services. It's carried by businesses who provide professional services, such as lawyers, architects, and many others.",
    featuredOrder: 4,
    icon: 'type-7.png',
  },
  'cyber-insurance': {
    id: 'cyber-insurance',
    name: 'Cyber',
    shortName: 'Cyber',
    description:
      'Cyber insurance protects your business from claims that important information has been released to the public or that incendiary material was published, and also pays for extortion costs, lost business data, or lost income due to digital disruption.',
    featuredOrder: 5,
    icon: 'type-8.png',
  },
  'pollution-liability-insurance': {
    id: 'pollution-liability-insurance',
    name: 'Pollution Liability',
  },
  'environmental-liability-insurance': {
    id: 'environmental-liability-insurance',
    name: 'Environmental Liability',
  },
  'product-liability': {
    id: 'product-liability',
    name: 'Product Liability',
  },
  'business-owners-policy': {
    id: 'business-owners-policy',
    name: 'Business Owners',
  },
  'commercial-property': {
    id: 'commercial-property',
    name: 'Commercial Property',
  },
  'commercial-auto': {
    id: 'commercial-auto',
    name: 'Commercial Auto',
  },
  'directors-and-officers-insurance': {
    id: 'directors-and-officers-insurance',
    name: 'Directors & Officers',
  },
  'liquor-liability': {
    id: 'liquor-liability',
    name: 'Liquor Liability',
  },
  'special-events': {
    id: 'special-events',
    name: 'Special Events',
  },
  'inland-marine': {
    id: 'inland-marine',
    name: 'Inland Marine',
  },
  'ocean-marine': {
    id: 'ocean-marine',
    name: 'Ocean Marine',
  },
  'stock-through-put': {
    id: 'stock-through-put',
    name: 'Stock Through Put',
  },
  'medical-malpractice': {
    id: 'medical-malpractice',
    name: 'Medical Malpractice',
  },
  'equipment-breakdown': {
    id: 'equipment-breakdown',
    name: 'Equipment Breakdown',
  },
  'business-interruption': {
    id: 'business-interruption',
    name: 'Business Interruption',
  },
  'garage-liability': {
    id: 'garage-liability',
    name: 'Garage Liability',
  },
  'dealers-open-lot': {
    id: 'dealers-open-lot',
    name: 'Dealers Open Lot',
  },
  'hired-and-non-owned-auto': {
    id: 'hired-and-non-owned-auto',
    name: 'Hired and Non Owned Auto',
  },
  'fiduciary-bond': {
    id: 'fiduciary-bond',
    name: 'Fiduciary Bond',
  },
  'fidelity-bond': {
    id: 'fidelity-bond',
    name: 'Fidelity Bond',
  },
  'surety-bond': {
    id: 'surety-bond',
    name: 'Surety Bond',
  },
  'excess-property': {
    id: 'excess-property',
    name: 'Excess Property',
  },
  'excess-casualty': {
    id: 'excess-casualty',
    name: 'Excess Casualty',
  },
  umbrella: {
    id: 'umbrella',
    name: 'Umbrella',
  },
  'difference-in-conditions': {
    id: 'difference-in-conditions',
    name: 'Difference In Conditions',
  },
  'personal-lines': {
    id: 'personal-lines',
    name: 'Personal Lines',
  },
  'program-administrator': {
    id: 'program-administrator',
    name: 'Program Administrator',
  },
};
export const featuredInsuranceLineIds = Object.values(insuranceLines)
  .filter((line) => line.featuredOrder)
  .map((line) => line.id);

export const featuredPersonaInsuranceLineIds = Object.values(
  personalInsuranceLines
)
  .filter((line) => line.featuredOrder)
  .map((line) => line.id);
