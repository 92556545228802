import useCurrentUser from '@/hooks/useCurrentUser';
import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import bam from '@/lib/bam';
import { ref, watch, computed } from '@nuxtjs/composition-api';
import shortid from 'shortid';

import AddStaffInfo from '@/modules/StaffManagement/components/AddStaffWizard/Info';
import AddStaffPolicies from '@/modules/StaffManagement/components/AddStaffWizard/Policies/index';
import AddStaffReview from '@/modules/StaffManagement/components/AddStaffWizard/Review';
import AddStaffTraining from '@/modules/StaffManagement/components/AddStaffWizard/Training/index';
import useHrComplianceMilestones from '@/modules/TaskCenter/hooks/useHrComplianceMilestones';
import useReportedStaff from '@/hooks/useReportedStaff';
import useEmployees from '@/hooks/useEmployees';

let initialized = false;
let completeAddStaffMilestone;
let reportedStaffCount;
let allStaff;

const { currentUser } = useCurrentUser();

let staffManagementHook: ReturnType<typeof useStaffManagement> | undefined;

export enum StepId {
  INFO,
  TRAINING,
  POLICIES,
  REVIEW,
}

export interface Step {
  id: string;
  label: string;
  completed: boolean;
  trackingId?: string;
}

export interface WizardUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  beginOnboarding: boolean;
  isContractor: boolean;
}

enum AddStaffWizardTracking {
  STARTED = 'started',
  ENROLL_STAFF_IN_TRAINING = 'enroll_staff_in_training',
  BASIC_INFO_SUBMITTED = 'basic_info_submitted',
  COMPLETED = 'completed',
}

const defaultSteps = [
  {
    id: String(StepId.INFO),
    label: 'Setup Profile',
    completed: false,
    component: AddStaffInfo,
    trackingId: AddStaffWizardTracking.STARTED,
  },
  {
    id: String(StepId.POLICIES),
    label: 'Assign Policies',
    completed: false,
    component: AddStaffPolicies,
    // Landing on the "Assign Policies" step signifies that the user has added a user, aka submitted basic info.
    trackingId: AddStaffWizardTracking.BASIC_INFO_SUBMITTED,
  },
  {
    id: String(StepId.TRAINING),
    label: 'Enroll in Training',
    completed: false,
    component: AddStaffTraining,
    trackingId: AddStaffWizardTracking.ENROLL_STAFF_IN_TRAINING,
  },
  {
    id: String(StepId.REVIEW),
    label: 'Review',
    completed: false,
    component: AddStaffReview,
    trackingId: AddStaffWizardTracking.COMPLETED,
  },
];

const steps = ref(defaultSteps);

export const getCurrentStepIndex = (step: Step) =>
  steps.value.findIndex(({ id }) => id === step.id);

export const getStep = (currentStep: Step, moveIndex: number = 0) =>
  steps.value[getCurrentStepIndex(currentStep) + moveIndex];

const currentIndex = ref(0);
const currentStep = computed(() => steps.value[currentIndex.value]);

const currentWorker = ref<null | WizardUserInfo>(null);
const shouldShowWizard = ref(false);
const sessionId = ref('');

// watch the currentWorker value to see if they are a contractor
// if they are remove the training step
watch(
  currentWorker,
  (worker) => {
    if (worker?.isContractor) {
      const currentStepsStateWithoutTraining = steps.value.filter(
        (step) =>
          step.trackingId !== AddStaffWizardTracking.ENROLL_STAFF_IN_TRAINING
      );
      steps.value = currentStepsStateWithoutTraining;
    }
  },
  { immediate: true }
);

const logStep = (info: Object = {}, trackingId?) => {
  // Tracking Add Staff Wizard step completion
  bam.track(`add_staff_${trackingId || currentStep.value.trackingId}`, {
    activeUserId: currentUser.value?._id,
    companyId: currentUser.value?._company?._id,
    companyName: currentUser.value?._company?.name,
    sessionId: sessionId.value,
    ...(currentWorker.value ? { createdUserId: currentWorker.value.id } : {}),
    ...info,
  });
};

const showWizard = () => {
  sessionId.value = shortid.generate();
  shouldShowWizard.value = true;

  // Logging first step of the wizard manually
  logStep();
};

const resetWizardState = () => {
  currentIndex.value = 0;
  currentWorker.value = null;

  steps.value = defaultSteps.map((step) => ({
    ...step,
    completed: false,
  }));

  sessionId.value = '';
};

const hideWizard = () => {
  if (staffManagementHook) {
    const { reloadEmployees } = staffManagementHook;

    // Refreshing the worker list in the background to show updated info
    reloadEmployees();

    // Try to complete the add Staff milestone if all staff have been reported.
    // At the time this function is called, the allStaff count has NOT been
    // updated, so we are checking the length plus 1 since that will be the new count.
    if (allStaff?.value?.length + 1 >= reportedStaffCount.value) {
      completeAddStaffMilestone();
    }

    shouldShowWizard.value = false;
    resetWizardState();
  }
};

const handleNext = (userInfo?: WizardUserInfo) => {
  if (userInfo) {
    if (!userInfo.beginOnboarding) {
      logStep(
        {
          deferredOnboarding: true,
        },
        AddStaffWizardTracking.BASIC_INFO_SUBMITTED
      );
      hideWizard();

      return;
    }

    currentWorker.value = userInfo;
  }

  // Step validation is handled by the consumed components so if we go to the next step, the previous is now completed
  if (currentIndex.value >= 0 && currentIndex.value <= steps.value.length - 1) {
    steps.value[getCurrentStepIndex(currentStep.value)].completed = true;
    currentIndex.value += 1;
    logStep();
  }
};

const handlePrevious = () => {
  if (currentIndex.value >= 0 && currentIndex.value <= steps.value.length - 1) {
    currentIndex.value -= 1;
  }
};

const setup = () => {
  if (!initialized) {
    staffManagementHook = useStaffManagement();
    completeAddStaffMilestone =
      useHrComplianceMilestones()?.completeAddStaffMilestone;
    reportedStaffCount = useReportedStaff().reportedStaffCount;
    allStaff = useEmployees()?.all;
    initialized = true;
  }
};

const useAddStaffWizard = () => {
  setup();

  return {
    currentStep,
    currentWorker,
    sessionId,
    shouldShowWizard,
    steps,
    handleNext,
    handlePrevious,
    hideWizard,
    resetWizardState,
    showWizard,
  };
};

export default useAddStaffWizard;
