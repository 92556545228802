



























import useFileDownloader from '@/composables/useFileDownloader';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentUser from '@/hooks/useCurrentUser';
import EmptyDashboardList from '@/modules/payroll/components/DashboardTile/EmptyDashboardList';
import StaffTaxTile, {
  TaxDoc,
} from '@/modules/payroll/components/TaxDocuments/StaffTaxTile.vue';
import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import { FILE_SERVER_URL } from '@/plugins/apollo/config/createApolloClient';
import { formatDate } from '@/utils/date';
import { AnnularThrobber, BaseBanner } from '@bambeehr/pollen';
import useNotifications from '@bambeehr/use-notifications';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import useContentful from '@/hooks/useContentful/useContentful';
import { ContentfulEntryIDs } from '@/lib/globals/ContentfulEntryIDs';

// Will replace with autogen
interface Employee {
  id: string;
  taxDocuments: TaxDoc[];
}

export const getStaffInfo = (currentUser) => {
  if (!currentUser?.profile) {
    return {};
  }

  const {
    contractor: isContractor,
    first_name: firstName,
    last_name: lastName,
    contractor_business_name: contractorBusinessName,
  } = currentUser.profile;

  return {
    id: currentUser._id,
    isContractor,
    firstName,
    lastName,
    contractorBusinessName,
  };
};

export default defineComponent({
  components: {
    AnnularThrobber,
    EmptyDashboardList,
    StaffTaxTile,
    BaseBanner,
  },
  setup() {
    const { fileDownloader } = useFileDownloader();
    const { currentUser } = useCurrentUser();
    const { company } = useCurrentCompany();
    const { getEmployeeTaxDocuments, getContractorTaxDocuments } =
      usePayrollService();
    // Prepare to fetch Contentful content
    const contentIsReady = ref(false);
    const { fetchContent } = useContentful();
    const contentfulContent = ref({
      body: '',
      title: '',
      show: false,
    });

    const isLoading = ref(true);
    const employee = ref<Employee>();

    const staffInfo = computed(() => getStaffInfo(currentUser.value));

    const taxDocService = staffInfo.value.isContractor
      ? getContractorTaxDocuments
      : getEmployeeTaxDocuments;

    taxDocService(staffInfo.value?.id as string, {
      pending: isLoading,
      data: employee,
    });

    const taxDocs = computed(
      () =>
        employee.value?.taxDocuments?.map((doc) => ({
          ...doc,
          formatted: {
            filedOn: formatDate(doc.filedOn, ''),
          },
          staffInfo: staffInfo.value,
          companyInfo: company.value,
        })) || []
    );
    const handleTileClick = (doc: TaxDoc) => {
      const { addInfo, addError } = useNotifications();

      if (!doc?.staffInfo) {
        addError(`Unable to download ${doc.label}`);

        return;
      }

      const { contractorBusinessName, firstName, lastName, id, isContractor } =
        doc.staffInfo;

      const entityName = contractorBusinessName || `${firstName}-${lastName}`;
      let filename = `${entityName}-${doc.companyInfo?.name}-${doc.label}-${doc.year}`;

      if (doc.quarter) {
        filename += `-${doc.quarter}`;
      }

      addInfo(`Downloading ${doc.label}`);

      fileDownloader(
        `${FILE_SERVER_URL}/document-secure/${
          isContractor ? 'contractor' : 'employee'
        }/${id}/tax-document/${doc.id}?filename=${filename}.pdf`,
        `${filename}.pdf`
      );
    };

    // Fetch the content when component is mounted
    (async () => {
      const content = await fetchContent(
        ContentfulEntryIDs.TAX_DOCUMENTS_BANNER
      );
      contentfulContent.value = content || {};
      contentIsReady.value = true;
    })();

    return {
      handleTileClick,
      taxDocs,
      isLoading,
      contentIsReady,
      contentfulContent,
    };
  },
});
