import gql from 'graphql-tag';
import { ENROLLMENT_RESPONSE_FRAGMENT } from './fragments';

export const ENROLL_USERS = gql`
  mutation enrollUsers($data: EnrollUsersInput!) {
    enrollUsers(data: $data) {
      ...EnrollmentResponseParts
    }
  }
  ${ENROLLMENT_RESPONSE_FRAGMENT}
`;

export const PURCHASE_SEATS_FOR_BUNDLE = gql`
  mutation purchaseSeatsForBundle($data: PurchaseBundleSeatsInput!) {
    purchaseSeatsForBundle(data: $data) {
      numPurchased
      totalSeats
    }
  }
`;

export const REMOVE_SEATS_FROM_BUNDLE = gql`
  mutation removeSeatsFromBundle($data: RemoveBundleSeatsInput!) {
    removeSeatsFromBundle(data: $data) {
      numRemoved
      totalSeats
    }
  }
`;

export const GET_USER_DOCEBO_TOKEN = gql`
  mutation GetTrainingUserToken {
    userToken {
      expiresAt
      token
      id
    }
  }
`;
