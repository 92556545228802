import {
  BULK_MESSAGE_STAFF,
  COMPLETE_COMPANY_PRACTICE_MUTATE,
  COMPLETE_COMPANY_SURVEY_MUTATE,
  GET_COMPANY_SETTINGS,
  GET_HR_SCORE_QUERY,
  GET_PRACTICE_QUERY,
  GET_SURVEY_QUERY,
  LIST_PRACTICE_QUERY,
  SAVE_COMPANY_ANSWER_MUTATE,
} from '@/gql/queries/company.gql';
import apolloClient from '@/gql/apolloClient';

/*
  Generic service functions
 */

/**
 * Generic function. Does a GraphQL watchQuery
 *
 * TODO: switch to global decorator
 *
 * @param {Object} q GraphQL query to request
 * @param {Object} variables GraphQL variables for the query
 * @param {String} resultKey The property to drill down on in the GraphQL result object
 * @returns {Observable}
 */
const watchQuery = (q, variables, resultKey) => {
  return apolloClient
    .watchQuery({
      // @ts-ignore
      query: q,
      variables,
    })
    .map((res) => {
      const { data, errors, loading } = res;

      return {
        errors,
        loading,
        pending: loading,
        data: data?.[resultKey],
      };
    });
};

// TODO: switch to global decorator
const mutate = async (mutation, variables, resultKey, options?) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation,
      variables,
      ...options,
    })
    .catch((err) => ({
      errors: err,
      data: {},
    }));

  return {
    errors,
    data: data && data[resultKey],
  };
};

/*
  Business logic
 */
export const watchGetCompanySettings = (companyId) =>
  watchQuery(GET_COMPANY_SETTINGS, { data: companyId }, 'getCompanySettings');

export const watchPracticeList = (companyId) =>
  watchQuery(LIST_PRACTICE_QUERY, { companyId }, 'listPractice');

export const watchGetPractice = ({ companyId, practiceId }) =>
  watchQuery(
    GET_PRACTICE_QUERY,
    {
      input: {
        companyId,
        practiceId,
      },
    },
    'getPractice'
  );

export const watchGetHrScore = (companyId) =>
  watchQuery(
    GET_HR_SCORE_QUERY,
    {
      companyId,
    },
    'getHrScore'
  );

export const watchGetSurvey = ({ companyId, type }) =>
  watchQuery(
    GET_SURVEY_QUERY,
    {
      data: {
        companyId,
        type,
      },
    },
    'companySurvey'
  );

export const mutateSaveAnswer = ({ companyId, surveyId, key, value }) =>
  mutate(
    SAVE_COMPANY_ANSWER_MUTATE,
    {
      data: {
        companyId,
        surveyId,
        key,
        value,
      },
    },
    'saveCompanyAnswer'
  );

export const mutateCompleteSurvey = ({ companyId, surveyId }) =>
  mutate(
    COMPLETE_COMPANY_SURVEY_MUTATE,
    {
      data: {
        companyId,
        surveyId,
      },
    },
    'completeCompanySurvey'
  );

export const mutateCompleteCompanyPractice = ({ companyId, practiceId }) =>
  mutate(
    COMPLETE_COMPANY_PRACTICE_MUTATE,
    {
      data: {
        companyId,
        practiceId,
      },
    },
    'completeCompanyPractice'
  );

export const mutateBulkMessageStaff = ({
  messageBody,
  messageGroupType,
  messageType,
  pageSource,
  staff,
}) =>
  mutate(
    BULK_MESSAGE_STAFF,
    {
      data: {
        messageBody,
        messageGroupType,
        messageType,
        pageSource,
        staff,
      },
    },
    'bulkMessageStaff'
  );
