// A vue composition api hook for using the driver.js library
// Usage:
// 1. Import the hook
// 2. Assign your steps to steps: Ref<Step[]>
// 3. Assign the attr :tour-step="tourMap[index]" to the element you want to highlight
//    ex: :tour-step="tourMap[0]", :tour-step="tourMap[1]", etc...
// 4. Call start() to start the tour

import { driver } from 'driver.js';
import type { Popover, DriveStep, Driver } from 'driver.js';
import {
  watch,
  onUpdated,
  ref,
  onMounted,
  computed,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import shortid from 'shortid';
import { omit } from 'lodash';

// All available options: https://driverjs.com/docs/configuration
const defaultOptions = {
  showProgress: true,
  smoothScroll: true,
  disableActiveInteraction: true,
  overlayOpacity: 0.75,
  allowClose: true,
  stagePadding: 10,
  nextBtnText: 'Next',
  prevBtnText: 'Previous',
};

export interface TourInstance {
  id: string;
  tour: Driver;
  hook: any;
}

export interface Step extends Popover {
  isActive?: boolean;
  isGlobalStep?: boolean;
  id?: string;
}

// We keep a global list of tour instances so that we can pull up the correct instance when we need to
// This allows us to use the same tour between multiple pages (if we want).
const tours = ref<TourInstance[]>([]);
export const getTourInst = (id: string) =>
  id ? tours.value.find((tour) => tour.id === id) : null;

const getTourSteps = (steps: Step[], tourId: string) => {
  return steps.reduce(
    (tourMap, popover, index) => {
      const hasId = popover.id;
      const id = popover.id || shortid.generate();

      if (popover.isActive) {
        tourMap.steps.push({
          element: hasId ? `#${id}` : `[tour-step="${id}"]`,
          popover: omit(popover, ['isActive']),
        });
      }

      tourMap.idMap[index] = id;

      return tourMap;
    },
    {
      steps: [] as DriveStep[],
      idMap: {},
    }
  );
};

const checkIfElementExists = (step: DriveStep) => {
  const el = step?.element && document.querySelector(step.element as string);

  return !!el;
};

const runTour = (inst: Driver, steps: Step[], startAt: number = 0) => {
  if (!inst) {
    return;
  }

  const maxAttempts = 30;
  const waitTime = 250;

  // To make sure that we don't load the tour before the DOM is ready, we need to check for the existence of the first step's element
  // If we don't do this, the tour will load in a global modal state and then highlight the element, which looks weird
  let x = 0;
  const intervalID = setInterval(() => {
    const startStep = inst?.getConfig()?.steps?.[startAt] as DriveStep;

    // If the first tour step is intended to be global (i.e. not tied to a specific element), then we don't need to wait for the DOM to load
    const isStartStepGlobal = steps[startAt]?.isGlobalStep;

    if (isStartStepGlobal || checkIfElementExists(startStep)) {
      // Because driver isn't vue-aware, we need to make sure that it's getting retriggered on data updates
      // Call it first in case all lifecycle hooks have already been called
      inst.drive(startAt);

      // Call it again on mount and update to make sure highlighting is updating to latest content
      onMounted(() => inst.drive());
      onUpdated(() => inst.drive());

      // Make sure that we remove the instance when the calling component is unmounted
      onBeforeUnmount(() => inst.destroy());

      window.clearInterval(intervalID);
    } else if (x === maxAttempts) {
      // If we don't have the element after maxAttempts x waitTime, we should just give up on rendering the tour as the necessary elements don't exist.
      window.clearInterval(intervalID);
    }
    x += 1;
  }, waitTime);
};

const useTour = (id: string = shortid.generate()) => {
  const existingTour = getTourInst(id);
  if (existingTour) {
    return existingTour.hook;
  }

  const steps = ref<Step[]>([]);
  const tourSteps = computed(() => getTourSteps(steps.value, id));

  const onCompleted = ref();
  const onDismissed = ref();

  const tourInst = computed<Driver>(() =>
    driver({
      ...defaultOptions,
      steps: tourSteps.value.steps,
      onDestroyed: () => {
        onCompleted.value?.();
      },
      onCloseClick: () => {
        onDismissed.value?.();
      },
    })
  );

  // --- Tour Actions --- //
  const start = (step?: number) => {
    runTour(tourInst.value, steps.value, step);
  };
  const stop = () => {
    // For some reason, driver.js doesn't have a "stop" method.
    // So we will...

    // Destroy original instance to close it
    tourInst.value?.destroy();

    // Create a new instance to allow the consuming context to start the tour again
  };

  const goToStep = (step: number) => tourInst.value?.moveTo(step);
  const goToNextStep = () => tourInst.value?.moveNext();
  const goToPreviousStep = () => tourInst.value?.movePrevious();
  const refresh = () => tourInst.value?.refresh();

  // --- Tour State Helpers --- //
  const isActive = computed(() => tourInst.value.isActive());
  const tourMap = computed(() => tourSteps.value.idMap);

  const hookReturn = {
    id,
    isActive,
    steps,
    tourInst,
    tourMap,
    onCompleted,
    onDismissed,

    refresh,
    getTourInst,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    start,
    stop,
  };

  // --- Global Driver List --- //
  // Add instance to the global list

  // Keep the tour list up to date
  watch(tourInst, (inst) => {
    tours.value = tours.value.map((tour) => {
      if (tour.id === id) {
        return {
          ...tour,
          tour: inst,
        };
      }

      return tour;
    });
  });

  tours.value.push({
    id,
    tour: tourInst.value,
    hook: hookReturn,
  });

  return hookReturn;
};

export default useTour;
