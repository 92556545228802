<template>
  <TypeBody
    v-if="legalText"
    variant="caption"
    weight="medium"
    class="mt-6 text-center text-base-700"
    tag="div"
  >
    {{ legalText }}
  </TypeBody>
</template>

<script>
import { TypeBody } from '@bambeehr/pollen';
import usePurchaseTrainingContent from '@/modules/Training/components/PurchaseTraining/usePurchaseTrainingContent';

export default {
  name: 'TrainingRegistration',
  components: {
    TypeBody,
  },
  setup() {
    const { legalText } = usePurchaseTrainingContent();

    return {
      legalText,
    };
  },
};
</script>
