






























































import { defineComponent } from '@nuxtjs/composition-api';
import { ModalDialog, BaseButton, TypeBody } from '@bambeehr/pollen';
import FileUpload from '@/components/FileUpload/';
import LoadingButtonLabel from '@/components/LoadingButtonLabel';

export default defineComponent({
  name: 'FileUploadModal',
  components: {
    ModalDialog,
    FileUpload,
    BaseButton,
    LoadingButtonLabel,
    TypeBody,
  },
  props: {
    title: {
      type: String,
      default: 'Upload File',
    },
    error: {
      type: String,
      default: '',
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    savingLabel: {
      type: String,
      default: 'Saving...',
    },
    maxCount: {
      type: Number,
      default: 1,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'submit', 'input'],
});
