import DateFormat from '@/constants/DateFormat';
import { computed, ref } from '@nuxtjs/composition-api';
import format from 'date-fns/format';
import { useCalendlyStore } from '@/store/calendly';

// Utils
export const fetchCalendlyData = async (_, meetingId) => {
  if (!meetingId) {
    return false;
  }

  let data;
  try {
    const calendlyStore = useCalendlyStore();
    data = await calendlyStore.getCalendlyEvent(meetingId);
  } catch (error: any) {
    if (window?.DD_RUM) {
      window.DD_RUM.addError('STORE_ERROR_CALENDLY', {
        error,
      });
    }

    return false;
  }

  return data;
};

export const getTimeZone = (date, type) =>
  new Date(date)
    // Get full date with TZ string
    .toLocaleTimeString('en-US', {
      timeZoneName: type,
    })
    // Take full date and get TZ string
    .split(/(AM|PM)/)
    ?.pop();

// Formatting of Calendly data to be used in the component
export const formatMeetingInfo = (data) => {
  if (!data) {
    return null;
  }

  return {
    name: data.name,
    raw: {
      startTime: data.startTime,
      endTime: data.endTime,
    },
    date: format(new Date(data.startTime), DateFormat.EEEE_MMMM_DO_YYYYY),
    verboseTime: `${format(
      new Date(data.startTime),
      DateFormat.TIME
    )} - ${format(new Date(data.endTime), DateFormat.TIME)}, ${getTimeZone(
      data.endTime,
      'long'
    )}`,
    startTime: `${format(
      new Date(data.startTime),
      DateFormat.TIME
    )} ${getTimeZone(data.endTime, 'short')}`,
    day: format(new Date(data.startTime), DateFormat.DAY_OF_WEEK),
    dayNumber: format(new Date(data.startTime), DateFormat.DAY_OF_MONTH),
    month: format(new Date(data.startTime), DateFormat.MONTH),
    status: data.status,
  };
};

const useCalendlyEvent = (vueStore, eId) => {
  const isLoading = ref(true);
  const calendlyData = ref();

  if (vueStore && eId) {
    (async () => {
      calendlyData.value = await fetchCalendlyData(vueStore, eId);
      isLoading.value = false;
    })();
  }

  const meetingInfo = computed(() => formatMeetingInfo(calendlyData.value));
  const callIsCancelled = computed(
    () => meetingInfo.value?.status === 'canceled'
  );

  return {
    meetingInfo,
    isLoading,
    callIsCancelled,
  };
};

export default useCalendlyEvent;
