import { useContext } from '@nuxtjs/composition-api';
import { parse } from 'content-disposition';
import useNotifications from '@bambeehr/use-notifications';
import { AxiosRequestConfig } from 'axios';

export const useFileDownloader = () => {
  const { app } = useContext();
  const { addError } = useNotifications();
  const DEFAULT_FILE_DOWNLOAD_ERROR_MSG =
    'There was an error in downloading your file. Please try again later.';

  function fileDownloader(
    url: string,
    name: string = '',
    options: Partial<AxiosRequestConfig> = {},
    customErrorNotification?: string
  ): void {
    if (!url) {
      return;
    }
    let downloadUrl;
    if (url.match(/training\/v1\/certificates/)) {
      downloadUrl = url.replace('/download', '/download-secure');
    } else {
      downloadUrl = url;
    }

    app.$axios
      .get(downloadUrl, {
        ...options,
        responseType: 'blob',
        headers: {
          'access-control-request-headers':
            'content-type, content-disposition, x-suggested-file-name',
        },
      })
      .then(async (response) => {
        if (response.status !== 200) {
          addError(DEFAULT_FILE_DOWNLOAD_ERROR_MSG);

          return;
        }
        let fileName;
        const suggestedFileName = response.headers.get('x-suggested-file-name');
        const contentDisposition = response.headers.get('content-disposition');
        if (suggestedFileName) {
          fileName = suggestedFileName;
        } else if (contentDisposition) {
          fileName = parse(contentDisposition)?.parameters?.fileName ?? name;
        } else {
          fileName = name;
        }
        const mimeType = response.headers.get('content-type');

        const downloadUrl = window.URL.createObjectURL(await response.data);

        const link = document.createElement('a');

        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        addError(customErrorNotification || DEFAULT_FILE_DOWNLOAD_ERROR_MSG);
        console.error(e);
      });
  }

  return { fileDownloader };
};

export default useFileDownloader;
