import { useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import {
  PurchasedBundle,
  PurchasedTopic,
  useGetCompanyVerboseQuery,
} from '@/gql/generated';
import { computed, ref } from '@nuxtjs/composition-api';
import isBefore from 'date-fns/isBefore';
import subYears from 'date-fns/subYears';

let init;

interface DecoratedBundleItem extends PurchasedBundle {
  isBundle: boolean;
  overdueCount: number;
}

interface DecoratedTopicItem extends PurchasedTopic {
  isBundle: boolean;
  overdueCount: number;
}

const defaultVisible = 2;

// Utils
export const getOverdueTrainingCount = (topic) => {
  if (!topic || !topic.enrollments.length) {
    return 0;
  }

  const { enrollments } = topic;
  const olderThanYearCount = enrollments.filter(
    (e) =>
      // is the startedAt date older than a year (current date, minus one year)
      e?.startedAt && isBefore(new Date(e.startedAt), subYears(new Date(), 1))
  ).length;

  return olderThanYearCount;
};

export const getOverdueList = (
  purchased
): DecoratedBundleItem[] | DecoratedTopicItem[] => {
  const topics = purchased?.topics || [];
  const bundles = purchased?.bundles || [];

  if (!topics && !bundles) {
    return [];
  }

  const overdueTopics = topics.reduce((acc, topic) => {
    const overdueCount = getOverdueTrainingCount(topic);

    if (overdueCount) {
      acc.push({
        ...topic,
        overdueCount,
      });
    }

    return acc;
  }, []);

  const overdueBundles = bundles.reduce((accBundles, bundle) => {
    const overdueCount = bundle.topics.reduce((accTopics, topic) => {
      const overdueCountTopics = getOverdueTrainingCount(topic);

      if (overdueCountTopics > accTopics) {
        return overdueCountTopics;
      }

      return accTopics;
    }, 0);

    if (overdueCount) {
      accBundles.push({
        ...bundle,
        overdueCount,
        isBundle: true,
      });
    }

    return accBundles;
  }, []);

  return [...overdueBundles, ...overdueTopics];
};

export const getFilteredOverdueList = (
  list: (DecoratedBundleItem | DecoratedTopicItem)[] = [],
  showAll: boolean
) =>
  showAll || list.length < defaultVisible
    ? list
    : list.slice(0, defaultVisible);

// Ref
const trainingCompany = ref();
const isFetching = ref(false);
const showAllOverdueItems = ref(false);

// Computed
const overdueList = computed(() =>
  getOverdueList(trainingCompany.value?.purchasedProducts)
);

const overdueCourseCount = computed(() => overdueList.value.length);

const filteredOverdueList = computed(() =>
  getFilteredOverdueList(overdueList.value, showAllOverdueItems.value)
);

const getTrainingData = () => {
  const { companyId } = useCurrentCompany();

  useApolloQuery(
    useGetCompanyVerboseQuery,
    { data: { companyId: companyId.value } },
    {
      data: trainingCompany,
      pending: isFetching,
    },
    {
      placeholderPick: ({ company: res }) => res,
      handleErrors: false,
    }
  );
};

const toggleShowAllOverdueItems = () => {
  showAllOverdueItems.value = !showAllOverdueItems.value;
};

const useTrainingGroup = () => {
  if (!init) {
    getTrainingData();
    init = true;
  }

  return {
    trainingCompany,
    overdueCourseCount,
    isFetching,
    overdueList,
    filteredOverdueList,
    toggleShowAllOverdueItems,
    showAllOverdueItems,
  };
};

export default useTrainingGroup;
