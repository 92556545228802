

























import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import {
  ModalDialog,
  TypeDisplay,
  BaseButton,
  AnnularThrobber,
} from '@bambeehr/pollen';
import Timeline from '@/components/Timeline/Timeline.vue';
import makePossessive from '@/utils/makePossessive';
import formatTrainingHistoryItems from './formatTrainingHistoryItems';
import downloadCertificates from '@/modules/Training/utils/downloadCertificates';
import useFileDownloader from '@/composables/useFileDownloader';
import { useGetTrainingUserHistoryQuery } from '@/gql/generated';
import { useApolloQuery } from '@/gql/apolloWrapper';
import mapCoursesToBundles from '@/modules/Training/utils/mapCoursesToBundles';

export default defineComponent({
  components: {
    AnnularThrobber,
    ModalDialog,
    TypeDisplay,
    BaseButton,
    Timeline,
  },
  props: {
    staffId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    topicId: {
      type: String,
      default: '',
    },
    bundleId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const trainingUser = ref();
    const isLoading = ref(false);
    const { fileDownloader } = useFileDownloader();

    useApolloQuery(
      useGetTrainingUserHistoryQuery,
      {
        data: { id: props.staffId },
        filter: {
          topicId: props.topicId,
          bundleId: props.bundleId,
        },
      },
      {
        // @ts-ignore, known limitation with wrapper typecasting
        data: trainingUser,
        pending: isLoading,
      }
    );

    const timelineItems = computed(() =>
      formatTrainingHistoryItems(
        mapCoursesToBundles(trainingUser.value?.user?.training?.history || [])
      )
    );

    const title = computed(
      () => `${makePossessive(props.name)} Training History`
    );

    return {
      title,
      timelineItems,
      downloadCertificates,
      isLoading,
      fileDownloader,
    };
  },
});
