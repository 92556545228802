import bam from '@/lib/bam';
import { AIUpsellEvents } from '@/modules/AI/constants/events';
import { ref, computed, useRouter, useRoute } from '@nuxtjs/composition-api';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import { useApolloMutation } from '@/gql/apolloWrapper';
import { useOptInAiMutation } from '@/gql/generated';
import useState from '@/hooks/useState/useState';

const LOCAL_STORAGE_AI_UPSELL = 'aiUpsell';
let isInitialized = false;
let company;
let router;
let route;

const features = [
  {
    feature: 'HR Policies',
    when: 'Nov 2024',
  },
  {
    feature: 'Job Descriptions',
    when: 'Nov 2024',
  },
  {
    feature: 'Corrective Actions',
    when: 'Nov 2024',
  },
  {
    feature: 'Performance Improvement Plans',
    when: 'Nov 2024',
  },

  {
    feature: 'HR Action Plans',
    when: 'Spring 2025',
  },
];

export const defaultCopy = {
  primaryBtnLabel: 'Try the New HR Intelligence Now ',
  secondaryBtnLabel: 'Learn More',
  preText: 'Introducing the New Bambee',
  title: 'Welcome to intelligent HR, powered by Bambee',
  firstBody: `We've trained our AI on tens of thousands of real HR situations to
    help you navigate the nuances of HR every time. From detailed job
    descriptions to thorough corrective actions, we'll help you get it
    right in minutes.`,
  secondBody: `In the next few months, we’re rolling out massive improvements to the
    Bambee platform. All powered by Bambee intelligence.`,
  featureHeader: 'Upcoming Features:',
  img: '/assets/images/ai/ai-preview.png',
  features,
  primaryBtnHandler: null,
  secondaryBtnHandler: null,
  openEvent: 'VIEWED_MODAL',
  primaryEvent: 'OPT_IN',
  secondaryEvent: 'LEARN_MORE',
  eventContext: 'GLOBAL',
};

const currentCopy = ref(defaultCopy);

const { hydrateFromLocalStorage, state: aiUpsellState } = useState(
  {
    hasSeenUpsellModal: false,
    hasDismissedUpsellBanner: false,
  },
  LOCAL_STORAGE_AI_UPSELL
);

const showAIUpsellModal = ref(false);
const isLoading = ref(false);
const hasOptedIn = ref(false);

const isAIOptedIn = computed(
  () => company.value?.profile?.ai?.opt_in || hasOptedIn.value || false
);
const closeAIUpsellModal = () => {
  bam.trackEvent(AIUpsellEvents.CLOSE);
  showAIUpsellModal.value = false;
};

const openAIUpsellModal = (copy?) => {
  if (copy) {
    currentCopy.value = {
      ...defaultCopy,
      ...copy,
    };
  }
  bam.trackEvent(AIUpsellEvents.OPEN);
  showAIUpsellModal.value = true;
};

const optInAi = () => {
  isLoading.value = true;
  const {
    mutate: optInAi,
    onDone,
    onError,
  } = useApolloMutation(useOptInAiMutation);

  bam.trackEvent(AIUpsellEvents.AI_OPT_IN_START, {
    source: currentCopy.value.eventContext,
  });

  optInAi({
    data: {
      id: company?.value?.id,
      aiOptIn: true,
    },
  });

  onDone(() => {
    bam.trackEvent(AIUpsellEvents.AI_OPT_IN_SUCCESS, {
      source: currentCopy.value.eventContext,
    });

    setTimeout(() => {
      isLoading.value = false;
      hasOptedIn.value = true;
    }, 16000);
  });

  onError(() => {
    bam.trackEvent(AIUpsellEvents.AI_OPT_IN_ERROR, {
      source: currentCopy.value.eventContext,
    });

    showAIUpsellModal.value = false;
  });
};

// Really only used for development purposes
const optOutAI = () => {
  const { mutate: optInAi } = useApolloMutation(useOptInAiMutation);

  optInAi({
    data: {
      id: company?.value?.id,
      aiOptIn: false,
    },
  });
};

const setupHook = () => {
  company = useCurrentCompany().company;
  router = useRouter();
  route = useRoute();
  hydrateFromLocalStorage();
};

const useAIUpsell = () => {
  if (!isInitialized) {
    setupHook();
    isInitialized = true;
  }

  // For Dev purposes
  // Remove before release
  // optOutAI();

  return {
    showAIUpsellModal,
    closeAIUpsellModal,
    openAIUpsellModal,
    optInAi,
    optOutAI,
    isAIOptedIn,
    isLoading,
    aiUpsellState,
    currentCopy,
  };
};

export default useAIUpsell;
