import { useApolloMutation } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';

import {
  PurchaseSeatsInput,
  EnrollmentInput,
  useBulkEnrollUsersMutation,
  useBulkPurchaseSeatsMutation,
  UserInput,
} from '@/gql/generated';
import useNotifications from '@bambeehr/use-notifications';
import { ref } from '@nuxtjs/composition-api';

const { companyId } = useCurrentCompany();
const isPending = ref(false);
const shouldEnroll = ref(true);

const { addSuccess, addError } = useNotifications();

// TODO - https://bambee.atlassian.net/browse/RAP-1185
// update this hook to handle all use cases for training enrollment

// TODO - https://bambee.atlassian.net/browse/RAP-1195
// bug being called multiple times when it should only be called once
const bulkPurchaseSeats = async (topicIds: string[], bundleIds: string[]) => {
  const {
    onDone: onBulkPurchaseSeatsDone,
    mutate: bulkPurchase,
    onError: onBulkPurchaseError,
  } = useApolloMutation(useBulkPurchaseSeatsMutation, { pending: isPending });

  onBulkPurchaseError(({ responseErrors }) => {
    shouldEnroll.value = false;
    // swalling errors for now
    // TODO: https://bambee.atlassian.net/browse/RAP-1193
    // if (responseErrors) {
    //   responseErrors.forEach((err) => {
    //     addError(err.error.message);
    //   });
    // }
  });

  onBulkPurchaseSeatsDone(({ bulkPurchaseSeats: res }) => {
    shouldEnroll.value = true;
    if (res) {
      addSuccess(
        'You have successfully purchased training seats for your staff'
      );
    }
  });

  const topicsToEnroll: PurchaseSeatsInput[] = topicIds.map((tId) => {
    return {
      skipPayment: false,
      companyId: companyId.value,
      numSeats: 1,
      bundleId: undefined,
      topicId: tId,
    };
  });

  const bundlesToEnroll: PurchaseSeatsInput[] = bundleIds.map((bId) => {
    return {
      skipPayment: false,
      companyId: companyId.value,
      numSeats: 1,
      bundleId: bId,
      topicId: undefined,
    };
  });

  await bulkPurchase({
    data: {
      purchases: [...topicsToEnroll, ...bundlesToEnroll],
    },
  });
};

// TODO - https://bambee.atlassian.net/browse/RAP-1195
// bug being called multiple times when it should only be called once
const bulkEnrollStaff = async (
  toEnroll: UserInput,
  tIds: string[],
  bIds: string[],
  workerName?: string
) => {
  const { onDone: onBulkEnrollDone, mutate: bulkEnroll } = useApolloMutation(
    useBulkEnrollUsersMutation,
    { pending: isPending }
  );

  onBulkEnrollDone(({ bulkEnrollUsers: res }) => {
    if (res) {
      if (workerName) {
        addSuccess(`${workerName} has been enrolled in training`);
      } else {
        addSuccess('You have successfully enrolled your staff in training');
      }

      // swalling errors for now
      // TODO: https://bambee.atlassian.net/browse/RAP-1193
      // if (res.errors) {
      //   res.errors.forEach((err) => {
      //     addError(err.message);
      //   });
      // }
    }
  });

  // create an array of obejcts for each topic and bundle
  const topicsToEnroll: EnrollmentInput[] = tIds.map((tId) => {
    return {
      users: [toEnroll],
      topicId: tId,
    };
  });

  const bundlesToEnroll: EnrollmentInput[] = bIds.map((bId) => {
    return {
      users: [toEnroll],
      bundleId: bId,
    };
  });

  await bulkEnroll({
    data: {
      companyId: companyId.value,
      enrollments: [...topicsToEnroll, ...bundlesToEnroll],
    },
  });
};

const useTrainingEnrollment = () => {
  return {
    isPending,
    shouldEnroll,
    bulkEnrollStaff,
    bulkPurchaseSeats,
  };
};

export default useTrainingEnrollment;
