import { PayrollStatus } from '@/modules/payroll/constants/payroll';

function payrollRedirect(payroll, router, statuses) {
  if (!payroll || !router) {
    return false;
  }

  const { id, status } = payroll;

  if (statuses.includes(status)) {
    router.push(`/payroll/${id}/summary`);

    return true;
  }

  return false;
}

export const payrollRedirectConfirmation = (payroll, router) => {
  const statuses = [PayrollStatus.PAID];

  return payrollRedirect(payroll, router, statuses);
};

export const payrollRedirectEditing = (payroll, router) => {
  const statuses = [
    PayrollStatus.PAID,
    PayrollStatus.PROCESSING,
    PayrollStatus.PENDING,
  ];

  return payrollRedirect(payroll, router, statuses);
};
