







































import LoadingButtonLabel from '@/components/LoadingButtonLabel';
import AddStaffFormBasic from './components/AddStaffFormBasic';
import AddStaffFormSimplified from './components/AddStaffFormSimplified';
import AddStaffFormPersonal from './components/AddStaffFormPersonal';
import AddStaffFormEmployment from './components/AddStaffFormEmployment';
import AddStaffFormDeductions from './components/AddStaffFormDeductions';
import AddStaffPayrollStatus from './components/AddStaffPayrollStatus';
import { BaseButton } from '@bambeehr/pollen';

import {
  defineComponent,
  PropType,
  useStore,
  onMounted,
  useRoute,
} from '@nuxtjs/composition-api';
import { AddStaffFormModel } from './hooks/useAddStaffFormState';
import useAddStaffForm from './hooks/useAddStaffForm';

export default defineComponent({
  components: {
    BaseButton,
    LoadingButtonLabel,
    AddStaffFormBasic,
    AddStaffFormPersonal,
    AddStaffFormEmployment,
    AddStaffPayrollStatus,
    AddStaffFormDeductions,
    AddStaffFormSimplified,
  },
  props: {
    initialState: {
      type: Object as PropType<AddStaffFormModel>,
      required: false,
      default: () => ({}),
    },
    showBeginOnboarding: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isEditing: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isPayrollUser: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isCard: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    shouldSave: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showSave: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showHeader: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    inviteAsAdmin: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    simplified: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    const {
      workerIsContractor,
      isSavingWorker,
      handleSubmit,
      saveBtnLabel,
      workerId,
    } = useAddStaffForm(props, emit, store);

    // For direct linking sections of the edit form
    onMounted(() => {
      if (route.value?.hash) {
        const el = document?.querySelector(route.value?.hash);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });

    return {
      isSavingWorker,
      handleSubmit,
      saveBtnLabel,
      workerId,
      workerIsContractor,
    };
  },
});
