





















































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  TypeBody,
  BaseDivider,
  AnnularThrobber,
  ToolTip,
} from '@bambeehr/pollen';
import CallOutCard from '@/components/CallOutCard/CallOutCard.vue';
import { ProductKey, ServiceKey } from '@/gql/generated';
import useUpcomingBillingChanges from '@/hooks/useUpcomingBillingChanges/useUpcomingBillingChanges';
import { formatPrice } from '@/components/ProductSelector/useProductSelector';

export default defineComponent({
  name: 'TierUpgradeNoticePanel',
  components: {
    AnnularThrobber,
    TypeBody,
    BaseDivider,
    CallOutCard,
    ToolTip,
  },
  props: {
    variant: {
      type: String as PropType<'previous' | 'updated'>,
      default: 'previous',
    },
    tierNumber: {
      // panel will display the billing info for this tier
      type: Number,
      required: true,
    },
    minSize: {
      // minimum employee count at this tier
      type: String,
      required: true,
    },
    maxSize: {
      // maximum employee count at this tier
      type: String,
      required: true,
    },
    currentEmployeeCount: {
      // current employee count of the company
      type: Number,
      required: false,
      default: 0,
    },
    planIntervalPrice: {
      // price of the plan at this tier
      type: String,
      required: true,
    },
    payrollType: {
      // payroll type of the company (basic or premium)
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { upcomingBillingChanges, isLoading } = useUpcomingBillingChanges(
      props.tierNumber
    );

    const payrollAddOnPrice = computed(() => {
      const payrollAddOn = upcomingBillingChanges.value.find(
        (addon) => addon.serviceKey === ServiceKey.Payroll
      );

      return payrollAddOn?.upcomingMonthlyPrice;
    });

    const payrollLabel = computed(() => {
      if (props.payrollType === ProductKey.PayrollBasic) {
        return 'Payroll Basic';
      }
      if (props.payrollType === ProductKey.PayrollPremium) {
        return 'Payroll Premium';
      }

      return 'Payroll';
    });

    const formatNectarPrice = (sub) => {
      if (sub?.upcomingMonthlyPrice) {
        return `${formatPrice(sub?.upcomingMonthlyPrice)}/mo`;
      }
      if (sub?.upcomingYearlyPrice) {
        return `${formatPrice(sub?.upcomingYearlyPrice)}/yr`;
      }

      return undefined;
    };

    const newPolicySubPriceLabel = computed(() => {
      const newPolicySub = upcomingBillingChanges.value.find(
        (sub) => sub.productKey === ProductKey.NewPolicy
      );

      return formatNectarPrice(newPolicySub);
    });

    const jobDescriptionSubPriceLabel = computed(() => {
      const jobDescriptionSub = upcomingBillingChanges.value.find(
        (sub) => sub.productKey === ProductKey.JobDescription
      );

      return formatNectarPrice(jobDescriptionSub);
    });

    const managedHrServicesSubPriceLabel = computed(() => {
      const managedHrServicesSub = upcomingBillingChanges.value.find(
        (sub) => sub.productKey === ProductKey.ManagedHrServices
      );

      return formatNectarPrice(managedHrServicesSub);
    });

    const webinarPriceLabel = computed(() => {
      const wvppSub = upcomingBillingChanges.value.find(
        (sub) => sub.productKey === ProductKey.WebinarWorkplaceViolence
      );

      return formatNectarPrice(wvppSub);
    });

    return {
      isLoading,
      payrollAddOnPrice,
      payrollLabel,
      newPolicySubPriceLabel,
      jobDescriptionSubPriceLabel,
      managedHrServicesSubPriceLabel,
      webinarPriceLabel,
    };
  },
});
