import gql from 'graphql-tag';
import { DocumentNode } from 'graphql/language/ast';
import {
  FULL_COMPANY_FRAGMENT,
  FULL_USER_COURSE_FRAGMENT,
  SHORT_COMPANY_FRAGMENT,
} from './fragments';

const buildCompanyQuery = (fragment: DocumentNode): DocumentNode => {
  return gql`
    query company($data: GetCompanyInput!) {
      company(data: $data) {
        ...CompanyParts
      }
    }
    ${fragment}
  `;
};

export const GET_COMPANY = buildCompanyQuery(SHORT_COMPANY_FRAGMENT);
export const GET_COMPANY_DETAILED = buildCompanyQuery(FULL_COMPANY_FRAGMENT);

export const GET_USER_DOCEBO_TOKEN = gql`
  query getUserToken {
    getUserToken {
      expiresAt
      token
    }
  }
`;

export const USER_COURSES = gql`
  query userCourses($data: UserCoursesInput!) {
    userCourses(data: $data) {
      ...UserCourseParts
    }
  }
  ${FULL_USER_COURSE_FRAGMENT}
`;

export const GET_TOPICS = gql`
  query getTopics {
    getTopics {
      id
      name
      searchTag
    }
  }
`;

export const GET_COMPANY_TICKETS = gql`
  query getTickets {
    getTickets {
      totalTickets
      tickets {
        id
        topicId
        bundleId
        courseId
        userId
        stripeInvoiceId
        enrolledAt
        startedAt
        completedAt
      }
    }
  }
`;
