




















import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import { BaseButton, TaskCard } from '@bambeehr/pollen';
import { defineComponent } from '@nuxtjs/composition-api';

import { useStaffComplianceStatusCard } from '../StaffComplianceStatusCard';

export default defineComponent({
  name: 'StaffComplianceStatusCard',
  components: {
    BaseButton,
    TaskCard,
  },
  setup() {
    const { allStaff } = useStaffManagement();
    const { complianceSteps, isAllStaffLoading, isAllComplete } =
      useStaffComplianceStatusCard(allStaff);

    return {
      isAllStaffLoading,
      isAllComplete,
      complianceSteps,
    };
  },
});
