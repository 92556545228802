import { toPromise } from '@/utils/observables';
import {
  watchPracticeList,
  watchGetCompanySettings,
} from '@/gql/services/company.service';
import { CompanySettingKey } from '@/gql/generated';
import { PracticeState } from '@/gql/services/company.interface';
import { HrPractices } from '@/modules/HrCompliance/constants/HrPractices';

export interface CanAccessVoices {
  isActivated: boolean;
  isManuallyDisabled: boolean;
}

let voiceState: CanAccessVoices;
let isActivated: boolean;
export const canAccessVoices = async (companyId: string): Promise<boolean> => {
  if (isActivated) {
    return true;
  }

  // TODO: replace with Company Settings
  const practiceList = await toPromise(() => {
    return watchPracticeList(companyId);
  });

  const { data: practicesData } = practiceList;

  isActivated = !!practicesData?.find(
    (practice) =>
      practice.id === HrPractices.VOICES &&
      practice.state === PracticeState.ACTIVATED
  );

  return isActivated;
};

export const getEmployeeVoicesState = async (
  companyId: string
): Promise<CanAccessVoices> => {
  if (voiceState) {
    return voiceState;
  }

  const companySettings = await toPromise(() =>
    watchGetCompanySettings(companyId)
  );

  const { data: getCompanySettings } = companySettings;

  const voicesActivationState = getCompanySettings?.find(
    (setting) => setting.key === CompanySettingKey.HrPracticeVoicesActivated
  );

  const voicesManuallyDisabledState = getCompanySettings?.find(
    (setting) =>
      setting.key === CompanySettingKey.HrPracticeVoicesManuallyDisabled
  );

  voiceState = {
    isActivated: voicesActivationState?.value === 'true',
    isManuallyDisabled: voicesManuallyDisabledState?.value === 'true',
  };

  return voiceState;
};

export default null;
