<template>
  <portal to="layout">
    <ModalDialog
      id="off-cycle-wizard"
      action-alignment="center"
      overlay="dark"
      size="regular"
      @close="$emit('close')"
    >
      <ExpertWithholdingsForm
        :selected-options="selectedOptions"
        :save-btn-label="saveBtnLabel"
        @close="$emit('close')"
        @next="$emit('expert-withholding-click', $event)"
      />
    </ModalDialog>
  </portal>
</template>

<script>
import { ModalDialog } from '@bambeehr/pollen';
import ExpertWithholdingsForm from '@/modules/payroll/components/OffCycleOptionsForms/ExpertWithholdingsForm.vue';

export default {
  components: {
    ExpertWithholdingsForm,
    ModalDialog,
  },
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    saveBtnLabel: {
      type: String,
      required: false,
    },
  },
};
</script>
