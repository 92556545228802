import FeatureFlags from '@/constants/FeatureFlags';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import { computed } from '@nuxtjs/composition-api';

const usePolicyReaffirmationFlags = () => {
  const isPolicyReaffirmationsEnabled = computed<boolean>(
    () => launchDarkly.getFlags()[FeatureFlags.ENABLE_POLICY_REAFFIRMATIONS]
  );

  return {
    isPolicyReaffirmationsEnabled,
  };
};

export default usePolicyReaffirmationFlags;
