import { addDays, format, isSameDay, isValid, startOfMonth } from 'date-fns';

const getDateObj = (val) => {
  let date;
  if (val instanceof Date) {
    date = val;
  } else if (typeof val === 'string') {
    date = new Date(val);

    if (!isValid(date)) {
      date = null;
    }
  }

  return date;
};

const formatWithDefault = (val, formatting, defaultVal = 'N/A') => {
  const date = getDateObj(val);

  return date ? format(date, formatting) : defaultVal;
};

/**
 * Takes a Date (or date string) and applies standard date formatting
 * @param {Date|String|Object} val Date (or date string) to format
 * @param {*} defaultVal Any value to be returned if formatting wasn't possible
 * @returns {String} String representing the formatted date
 */
export const formatDate = (val, defaultVal, format = 'MM/dd/yyyy') =>
  formatWithDefault(val, format, defaultVal);

/**
 * Takes a Date (or date string) and applies standard time formatting
 * @param {Date|String} val Date (or date string) to format
 * @param {*} defaultVal Any value to be returned if formatting wasn't possible
 * @returns {String} String representing the formatted time
 */
export const formatTime = (val, defaultVal) =>
  formatWithDefault(val, 'h:mma', defaultVal);

export const getFifteenthOfMonth = (val) =>
  addDays(startOfMonth(getDateObj(val)), 14);

export const isFifteenthOfMonth = (val) =>
  isSameDay(getFifteenthOfMonth(val), getDateObj(val));

export const getDayOfMonth = (val) => Number(formatDate(val).substring(3, 5));

export default {
  formatDate,
  formatTime,
};
