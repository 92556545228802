import CookieNames from '@/constants/Cookies';
import { WhiteListedRoutes, APIRoutes } from '@/constants/Routes';
import SessionStorage from '@/constants/SessionStorage';
import {
  deleteAccessTokenCookies,
  deleteCookie,
  getCookie,
  setCookie,
} from '@/lib/cookie-helpers';
import jwtDecode from 'jwt-decode';
import bam from '@/lib/bam';
import axiosSrc from 'axios';
import axiosRetry from 'axios-retry';
import useCurrentUser from '@/hooks/useCurrentUser';
import toRedirString from '@/utils/toRedirString';

export interface BaseUser {
  email: string;
  name: string;
  authId: string;
  userId: string;
  companyId: string;
  roles: string[];
  avatarUrl: string;
}

export interface MasqUser extends BaseUser {
  masquerade?: BaseUser;
}

let isRefreshing = false;
let refreshQueue: Array<any> = [];

const allowedWithExpiredToken = [APIRoutes.REFRESH_TOKEN];

// Nuxt's redirect is failing to trigger properly and keeps sending the user back into the app
// This is a workaround to force the redirect to happen
const redirect = (path, queryParams = '') => {
  if (!window.location.href.includes(path)) {
    window.location.replace(`${path}${queryParams}`);
  }
};

export function isJwtValid(token) {
  try {
    return !!jwtDecode(token) as any;
  } catch (e) {
    return false;
  }
}

export function jwtExpired(token, buffer = 0) {
  try {
    const decoded = jwtDecode(token) as any;

    /**
     * if the token does not decode, does not have an expiration, or has expired, it is considered
     * invalid/expired
     */
    return decoded && decoded.exp && decoded.exp * 1000 - buffer < Date.now();
  } catch (e) {
    // consider the jwt as expired if the decode fails
    return true;
  }
}

export async function consumeToken(
  consumableToken,
  { axios, redirect },
  domain = 'activation'
) {
  try {
    const response = await axios.post(
      APIRoutes.CONSUME_TOKEN,
      {
        id: consumableToken,
        domain,
      },
      {
        baseURL: process.env.PUBLICAPI_URL,
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    if (hasStatus(error)) {
      if (error?.response?.status === 403 || error.status === 403) {
        sessionStorage.removeItem(SessionStorage.EXPIRED_ACTIVATION_TOKEN);

        // Used to redirect users to the /activation-expired page in middleware/auth.js
        return sessionStorage.setItem(
          SessionStorage.EXPIRED_ACTIVATION_TOKEN,
          consumableToken
        );
      }
    }

    return redirect(WhiteListedRoutes.LOGIN);
  }
}

const unathErrMsg =
  'You have been logged out due to inactivity or an expired session. Please log in again.';

function logUserOut(msg = unathErrMsg, route?) {
  let queryParams = msg ? `?warnMsg=${msg}` : '';
  const redirString = route && toRedirString(route.path, route.query);

  deleteAccessTokenCookies();

  const isWhiteListedRoute = Object.values(WhiteListedRoutes).some((path) =>
    route.path.includes(path)
  );

  if (!isWhiteListedRoute && redirString) {
    queryParams = queryParams
      ? `${queryParams}&redir=${redirString}`
      : `?redir=${redirString}`;
  }

  return redirect(WhiteListedRoutes.LOGIN, queryParams);
}

function hasStatus(
  e
): e is { status?: number; response?: { status?: number } } {
  return (
    Object.prototype.hasOwnProperty.call(e, 'status') ||
    (Object.prototype.hasOwnProperty.call(e, 'response') &&
      Object.prototype.hasOwnProperty.call(e.response, 'status'))
  );
}

export async function handleToken(axios, route, redirect) {
  const { token: queryToken, redir } = route.query;
  const { switch_token } = route.query;
  let { path } = route;
  const { token: maybeMasqToken } = route.query;
  let { access_token: consumableToken } = route.query;

  if (route.path.indexOf('/activation/') === 0) {
    consumableToken = route.path.replace('/activation/', '');
    // Reset path to pass control to activation/auth/index
    path = '/';
  }

  /**
   * For User security, Masquerade tokens are not refreshable, and should not
   * be stored as cookies.
   *
   * To allow HRM workflows, they have a slightly longer expiration time.
   */
  if (maybeMasqToken) {
    deleteAccessTokenCookies();
    setCookie(CookieNames.ACCESS_TOKEN, maybeMasqToken);
    setCookie(CookieNames.MASQUERADE, maybeMasqToken); // set masq cookie for tracking purposes

    return redirect(redir || path || '/');
  }

  const cookieToken = getCookie(CookieNames.ACCESS_TOKEN);
  let refreshToken;
  let accessToken;

  if (consumableToken) {
    const consumeTokenResults = await consumeToken(consumableToken, {
      axios,
      redirect,
    });
    if (consumeTokenResults) {
      refreshToken = consumeTokenResults?.refreshToken;
      accessToken = consumeTokenResults?.accessToken;
    }
  } else if (switch_token) {
    const consumeTokenResults = await consumeToken(
      switch_token,
      {
        axios,
        redirect,
      },
      'switch-user'
    );
    if (consumeTokenResults) {
      refreshToken = consumeTokenResults?.refreshToken;
      accessToken = consumeTokenResults?.accessToken;
    }
  } else {
    accessToken = queryToken || cookieToken;
    refreshToken = getCookie(CookieNames.REFRESH_TOKEN);
  }

  // both tokens are required.  If neither is present, the user is not logged in.
  // If the user is hitting a whitelisted page, allow them to continue
  if (
    (!accessToken || !isJwtValid(accessToken)) &&
    !path.includes(WhiteListedRoutes.RESET_PASSWORD)
  ) {
    bam.shutdown();
    bam.initIntercom();

    return logUserOut('', route);
  }

  // set tokens
  if (accessToken) {
    setCookie(CookieNames.ACCESS_TOKEN, accessToken);
  }

  if (isJwtValid(refreshToken)) {
    setCookie(CookieNames.REFRESH_TOKEN, refreshToken);
  }

  return redirect(redir || path || '/');
}

export default async ({ route }, inject) => {
  // Create the axios instance
  const $axios = axiosSrc.create({
    baseURL: process.env.PUBLICAPI_URL,
    withCredentials: true,
  });

  // Make $axios available to the app context
  inject('axios', $axios);

  // Default retry attempts: 3
  axiosRetry($axios, {
    retryDelay: axiosRetry.exponentialDelay,
  });

  $axios.interceptors.request.use(async (config) => {
    const masqToken = getCookie(CookieNames.MASQUERADE);
    if (masqToken) {
      const { currentUser } = useCurrentUser();
      if (currentUser.value) {
        // we need the current user to be loaded before we can track the masquerade event
        const decoded = jwtDecode(masqToken) as MasqUser;
        bam.track('user-impersonated', decoded); // track masquerade
        deleteCookie(CookieNames.MASQUERADE);
      }
    }

    /**
     * This interceptor is responsible for settings the Auth header
     * in all cases.
     */
    const existingAccessToken = getCookie(CookieNames.ACCESS_TOKEN);
    const existingRefreshToken = getCookie(CookieNames.REFRESH_TOKEN);
    if (existingAccessToken) {
      config.headers.Authorization = `Bearer ${existingAccessToken}`;
    }

    // If the request is to a list of allowed routes, return the config directly
    // This allows the refresh request to be made with an expired refresh token
    if (
      !!config.url &&
      (allowedWithExpiredToken as string[]).includes(config.url as string)
    ) {
      return config;
    }

    if (isRefreshing) {
      return new Promise<string>((resolve, reject) => {
        refreshQueue.push({
          resolve,
          reject,
        });
      }).then((newToken) => {
        config.headers.Authorization = `Bearer ${newToken}`;

        return config;
      });
    }

    if (existingAccessToken && existingRefreshToken) {
      if (jwtExpired(existingAccessToken, 2000)) {
        // This isRefreshing sentinel puts events into the stalled queue until the refresh is complete
        isRefreshing = true;
        const response = await $axios.post(
          APIRoutes.REFRESH_TOKEN,
          { refreshToken: existingRefreshToken },
          { validateStatus: () => true }
        );
        let isRefreshError = false;
        if (response.status !== 201) {
          isRefreshError = true;
        }

        const { accessToken, refreshToken } = response.data;
        if (!accessToken || !refreshToken) {
          isRefreshError = true;
        }

        if (isRefreshError) {
          // Reject and empty the stalled queue
          await Promise.all(refreshQueue.map((i) => i.reject(response)));
          refreshQueue = [];

          // Clear the tokens and redirect to login
          logUserOut();
          throw new Error(unathErrMsg);
        }

        setCookie(CookieNames.ACCESS_TOKEN, accessToken);
        setCookie(CookieNames.REFRESH_TOKEN, refreshToken);
        config.headers.Authorization = `Bearer ${accessToken}`;
        isRefreshing = false;
        await Promise.all(refreshQueue.map((i) => i.resolve(accessToken)));
        refreshQueue = [];
      }
    }

    return config;
  });

  $axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const reject = () => Promise.reject(error);
      // If the request is made with a valid access token (from the client's perspective), but the server returns a 401.
      // We should return the user to the login page to prevent further erronous requests
      switch (error?.response?.status) {
        case 401:
          if (window.location.pathname.includes(WhiteListedRoutes.LOGIN)) {
            return reject();
          }

          logUserOut();
          throw new Error(unathErrMsg);

        default:
          console.log('response error:', error);

          return reject();
      }
    }
  );

  await handleToken($axios, route, redirect);
};
