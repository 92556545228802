





































































import { ref, computed, useRouter, useStore } from '@nuxtjs/composition-api';
import ContentBanner from '@/components/ContentBanner/ContentBanner';

import {
  TextInput,
  SelectInput,
  AnnularThrobber,
  TypeBody,
  TypeDisplay,
  BaseButton,
} from '@bambeehr/pollen';
import EmployeeTrainingListItem from './EmployeeTrainingListItem';
import { useApolloQuery } from '@/gql/apolloWrapper';

import { useGetUserCoursesQuery, UserCourse } from '@/gql/generated';
import useCurrentUser from '@/hooks/useCurrentUser';
import format from 'date-fns/format';
import mapCoursesToBundles from '@/modules/Training/utils/mapCoursesToBundles';
import downloadCertificate from '@/modules/Training/utils/downloadCertificates';
import useFileDownloader from '@/composables/useFileDownloader';
import Store from '@/constants/Store';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import FeatureFlags from '@/constants/FeatureFlags';
import hasPurchasedTraining from '@/modules/Training/helpers/hasPurchasedTraining';
import useCurrentCompany from '@/hooks/useCurrentCompany';

enum TrainingStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
}

const statusOptions = [
  {
    label: 'Not Started',
    value: TrainingStatus.NOT_STARTED,
  },
  {
    label: 'In Progress',
    value: TrainingStatus.IN_PROGRESS,
  },
  {
    label: 'Completed',
    value: TrainingStatus.COMPLETED,
  },
];

// Utils
export const prepString = (str: string = '') => str.toLowerCase().trim();

export const decorateAndFilterList = (
  list,
  query: string = '',
  status: string = ''
) =>
  list
    .filter((item) => prepString(item.name).includes(prepString(query)))
    .filter((item) => !status || item.status === status)
    .map((item) => ({
      ...item,
      assigned: format(new Date(item.enrolledAt), 'MM/dd/yyyy'),
      isBundle: item.isBundle || false,
      isCompleted: !!item.completedAt,
      isInProgress: !!item.startedAt && !item.completedAt,
      isNotStarted: !item.completedAt && !item.startedAt,
    }));

export default {
  components: {
    EmployeeTrainingListItem,
    SelectInput,
    TextInput,
    AnnularThrobber,
    TypeBody,
    TypeDisplay,
    BaseButton,
    ContentBanner,
  },
  setup() {
    const { companyId } = useCurrentCompany();
    const { fileDownloader } = useFileDownloader();
    const { currentUserId, isOwnerAdmin } = useCurrentUser();
    const store = useStore();
    const isCompanyAdmin = store.getters[Store.auth.Getters.IS_COMPANY_ADMIN];

    const router = useRouter();

    const searchQuery = ref('');
    const statusFilter = ref('');
    const trainingItems = ref<UserCourse[]>([]);
    const isLoading = ref(false);

    useApolloQuery(
      useGetUserCoursesQuery,
      { data: { userId: currentUserId.value } },
      {
        // @ts-ignore, known type err with pick
        data: trainingItems,
        pending: isLoading,
      },
      { placeholderPick: ({ userCourses: res }) => res }
    );

    const filteredList = computed(() =>
      decorateAndFilterList(
        mapCoursesToBundles(trainingItems.value),
        searchQuery.value,
        statusFilter.value
      )
    );

    const goToItem = (id: string, param: string = '') => {
      router.push(`/my-training/${id}${param ? `?${param}` : ''}`);
    };

    const handleItemAction = (item) => {
      if (item.isCompleted) {
        downloadCertificate(item, fileDownloader);

        return;
      }

      goToItem(item.id, 'play=1');
    };

    const canViewCatalog = computed(
      () =>
        launchDarkly.getFlags()[FeatureFlags.TRAINING_CATALOG] ||
        hasPurchasedTraining(companyId.value)
    );

    return {
      handleItemAction,
      goToItem,
      searchQuery,
      statusOptions,
      statusFilter,
      filteredList,
      isLoading,
      trainingItems,
      isCompanyAdmin,
      canViewCatalog,
      isOwnerAdmin,
    };
  },
};
