

















































































import { TypeBody } from '@bambeehr/pollen';
import { defineComponent } from '@nuxtjs/composition-api';
import currency from '@bambeehr/currency';
import Accordion from '@/components/Accordion/Accordion.vue';

/* eslint-disable */
export enum AmountType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}
/* eslint-enable */

export default defineComponent({
  name: 'EarningsTable',
  components: {
    Accordion,
    TypeBody,
  },
  props: {
    earnings: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    totalLabel: {
      type: String,
      required: false,
      default: 'Total Pay',
    },
    totalDescription: {
      type: String,
      required: false,
      default: '',
    },
    grossLabel: {
      type: String,
      required: false,
      default: 'Gross Pay',
    },
    gross: {
      type: Number,
      required: false,
      default: null,
    },
    isAccordion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const wrapper = props.isAccordion ? Accordion : 'div';

    function getTextVariant(index) {
      return index ? 'text' : 'medium';
    }

    function getAmountSign(type: AmountType, index: number) {
      // We only care about the amount sign on the first row where index = 0;
      if (index || !type) {
        return '';
      }

      if (type === AmountType.NEGATIVE) {
        return '-';
      }

      return '+';
    }

    return {
      currency,
      getAmountSign,
      getTextVariant,
      wrapper,
    };
  },
});
