<template>
  <div>
    <OfferModal
      v-if="showUpgradeModal"
      :user-savings="userSavings"
      @offerAccepted="handleUpgrade"
      @offerDeclined="handleUpgradeDeclined"
      @offerDismissed="dismissOffer"
    />
    <OfferUpgradeAccepted
      v-if="showAcceptModal"
      :card="defaultCard"
      :order-details="userSavings"
      @paymentAcknowledged="handleAcceptAcknowledged"
    />
    <OfferUpgradeDeclined
      v-if="showDeclineModal"
      @declineAcknowledged="handleDeclineAcknowledged"
    />
  </div>
</template>

<script>
import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import useCurrentUser from '@/hooks/useCurrentUser/useCurrentUser';
import bam from '@/lib/bam';
import usePayment from '@/modules/Settings/hooks/usePayment';
import OfferUpgradeAccepted from '@/modules/TaskCenter/components/IntervalUpgradeOffer/OfferAcceptedModal.vue';
import OfferUpgradeDeclined from '@/modules/TaskCenter/components/IntervalUpgradeOffer/OfferDeclinedModal.vue';
import OfferModal from '@/modules/TaskCenter/components/IntervalUpgradeOffer/OfferModal.vue';
import useIntervalUpgradeOffer from '@/modules/TaskCenter/hooks/useIntervalUpgradeOffer/useIntervalUpgradeOffer';
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { formatCurrency } from '@/utils/currency';
import useNotifications from '@/hooks/useNotifications';

export default defineComponent({
  name: 'IntervalUpgradeOffer',
  components: {
    OfferModal,
    OfferUpgradeAccepted,
    OfferUpgradeDeclined,
  },
  props: {
    userSavings: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { currentPlan } = useCurrentPlan();
    const { currentUser } = useCurrentUser();
    const monthlyCost = computed(() => currentPlan.value?.monthlyPrice * 12);

    const {
      showUpgradeModal,
      showAcceptModal,
      showDeclineModal,
      handleUpgradeAccepted,
      handleUpgradeDeclined,
      handleAcceptAcknowledged,
      handleDeclineAcknowledged,
      dismissOffer,
    } = useIntervalUpgradeOffer();
    const { addError } = useNotifications();

    const { $axios: axios } = useContext();
    const { billingInfo } = usePayment();
    const sources = computed(() => billingInfo.value.sources || []);

    const defaultCard = computed(() =>
      sources.value.find((source) => source.default)
    );

    const handleUpgrade = async () => {
      try {
        await axios?.post(
          'billing/billing/v2/upgrade-to-next-interval',
          { companyId: currentUser.value?._company?._id },
          { baseURL: process.env.PUBLICAPI_URL }
        );
        handleUpgradeAccepted();
      } catch (e) {
        addError(
          'Payment failed. Try updating your payment method in your settings.'
        );
        bam.track('annual-upgrade-failed', {
          error: e,
          user: currentUser.value,
        });
        window.DD_RUM?.addError(e);
        dismissOffer();
      }
    };

    if (props.userSavings.doesCustomerQualify) {
      showUpgradeModal.value = true;
    }

    return {
      showUpgradeModal,
      showAcceptModal,
      showDeclineModal,
      handleUpgradeAccepted,
      handleUpgradeDeclined,
      handleAcceptAcknowledged,
      handleDeclineAcknowledged,
      dismissOffer,
      handleUpgrade,
      defaultCard,
      monthlyCost,
      formatCurrency,
    };
  },
});
</script>
