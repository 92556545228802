export enum StaffPayrollStatus {
  MISSING_INFO = 'MISSING_INFO',
  OFF_PAYROLL = 'OFF_PAYROLL',
  ON_PAYROLL = 'ON_PAYROLL',
  REMAINING_STEPS = 'REMAINING_STEPS',
}

export const getPayrollStatus = (staffItem): StaffPayrollStatus => {
  if (!staffItem?.includesPayrollInfo) {
    return StaffPayrollStatus.OFF_PAYROLL;
  }

  if (!staffItem.checkId) {
    if (staffItem.isExcludedFromPayroll) {
      return StaffPayrollStatus.OFF_PAYROLL;
    }

    return StaffPayrollStatus.MISSING_INFO;
  }

  if (staffItem.checkOnboarding?.remainingSteps?.length) {
    return StaffPayrollStatus.REMAINING_STEPS;
  }

  return StaffPayrollStatus.ON_PAYROLL;
};
