import { MilestoneIds } from './Milestones';

const rootSlug = `/about-bambee`;
export const Slugs = Object.freeze({
  [MilestoneIds.ABOUT]: `${rootSlug}/welcome`,
  [MilestoneIds.ONBOARDING_TERMINATIONS]: `${rootSlug}/onboarding-termination`,
  [MilestoneIds.AUDITS]: `${rootSlug}/audits`,
  [MilestoneIds.POLICIES]: `${rootSlug}/policies`,
  [MilestoneIds.HRM]: `${rootSlug}/hr-manager`,
  [MilestoneIds.PAYROLL]: `${rootSlug}/payroll`,
  [MilestoneIds.TRAINING]: `${rootSlug}/training`,
  [MilestoneIds.EE_FEEDBACK]: `${rootSlug}/employee-feedback`,
  [MilestoneIds.CABINET]: `${rootSlug}/document-retention`,
  [MilestoneIds.GET_BAMBEE]: `/payment`,
  [MilestoneIds.COMPANY_SETUP]: '/company-setup',
});

export default null;
