<template>
  <div>
    <PageTitle>My Pay</PageTitle>
    <NavBar />
    <nuxt-child :key="$route.path" class="mt-8" />
  </div>
</template>

<script>
import employeePayrollGuard from '@/modules/payroll/middleware/employeePayrollGuard';
import employeePayrollSetupGuard from '@/modules/payroll/middleware/employeePayrollSetupGuard';
import NavBar from '@/modules/payroll/components/MyPay/NavBar.vue';
import PageTitle from '@/modules/payroll/components/PageTitle/PageTitle';

export default {
  name: 'StaffPayrollRoot',
  components: {
    NavBar,
    PageTitle,
  },
  middleware: [employeePayrollGuard, employeePayrollSetupGuard],
};
</script>
