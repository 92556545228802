import {
  ref,
  Ref,
  useContext,
  useStore,
  computed,
} from '@nuxtjs/composition-api';

let isInitialized;
let context;
let store;
const isReady = ref(false);
let currentFetchingFn;

const policies: Ref<null | any[]> = ref(null);
const policyInScopeId: Ref<null | string> = ref(null);
export const policyInScope = computed(() =>
  policies.value?.find((p) => p._id === policyInScopeId.value)
);

const fetchPolicies = async (force: boolean = false): Promise<any> => {
  // If we already have policies cached, return the cache unless explicitly asked to force a new fetch
  if (!force && policies.value) {
    return policies.value;
  }

  // If there is already an active fetch happening, return that promise. No need to kick off a new network call
  if (currentFetchingFn) {
    return currentFetchingFn;
  }

  const { $axios } = context;

  // Create and set a promise representing the fetch that's about to happen
  currentFetchingFn = async () => {
    const { data } = await $axios.get('/v0/app/my/policiesSimplified');

    return data;
  };

  const policiesRes = await currentFetchingFn();
  currentFetchingFn = null;

  if (policiesRes) {
    policies.value = policiesRes;
    store.commit('policies/setAll', policiesRes); // Until we move off of using the store, commit so that the store stays up to date
  }

  return policiesRes;
};

const setupHook = async (): Promise<void> => {
  isInitialized = true;
  context = useContext();
  await fetchPolicies();
  isReady.value = true;
};

const usePolicies = () => {
  if (!isInitialized) {
    store = useStore();
    setupHook();
  }

  return {
    isReady,
    fetchPolicies,
    policies: computed(() => {
      return store.getters['policies/my']; // Until we move completely off of the store, return the store value instead of our local state
    }),
    policyInScope,
    policyInScopeId,
  };
};

export default usePolicies;
