




































import {
  defineComponent,
  ref,
  PropType,
  useRouter,
} from '@nuxtjs/composition-api';
import { wizardActionState } from '@/modules/payroll/pages/employer/onboarding/OnboardingRoot.vue';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import { TypeDisplay, TypeBody, BaseButton } from '@bambeehr/pollen';
import MailTruck from '@/modules/payroll/components/MailTruck/MailTruck.vue';
import { useApolloQuery, useApolloMutation } from '@/gql/apolloWrapper';

import {
  useGetPayrollOnboardingInfoQuery,
  useGenerateCompanyOnboardingLinkMutation,
} from '@/gql/generated';
import useNotifications from '@bambeehr/use-notifications';
import { trackEvent } from '@/modules/payroll/utils/track-apa';
import { setUserPreference } from '@/utils/userPreference';

function setWizardActionState() {
  wizardActionState.value = {
    complete: {
      label: 'Return to Bambee Home',
      action: (complete) => {
        complete();
      },
    },
    disableNext: false,
  };
}

export default defineComponent({
  name: 'SignatoryConfirmation',
  components: {
    TypeDisplay,
    TypeBody,
    MailTruck,
    BaseButton,
  },
  props: {
    signatoryName: {
      type: String as PropType<string>,
    },
    companyId: {
      type: String as PropType<string>,
    },
  },
  setup(props) {
    setWizardActionState();

    const router = useRouter();
    const { addSuccess } = useNotifications();
    const onboardingCompany = ref();
    const { isOnboarding } = usePayrollOnboardingStatus();

    useApolloQuery(
      useGetPayrollOnboardingInfoQuery,
      { id: props.companyId as string },
      { data: onboardingCompany },
      { placeholderPick: ({ getCompany: res }) => res }
    );

    const { onDone, mutate: resendOnboardingEmail } = useApolloMutation(
      useGenerateCompanyOnboardingLinkMutation
    );

    onDone(() => {
      addSuccess(`Email sent to ${onboardingCompany.value.signatoryEmail}!`);
    });

    const resendEmail = () => {
      resendOnboardingEmail({
        data: {
          companyId: props.companyId as string,
          signerName: `${onboardingCompany.value.signatoryFirstName} ${onboardingCompany.value.signatoryLastName}`,
          email: onboardingCompany.value.signatoryEmail,
          signerTitle: onboardingCompany.value.signatoryTitle,
          sendEmail: true,
        },
      });
    };

    if (isOnboarding.value) {
      setUserPreference(`skip-check-onboarding-${props.companyId}`, true);
      trackEvent('check-onboarding-skipped');
      router.push('/');
    }

    return {
      resendEmail,
    };
  },
});
