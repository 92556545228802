import registerFetchFn from '@/utils/serviceCallManager';

let axios;

export const getPricePlanById = async (id: string, force: boolean = false) => {
  if (!axios) {
    console.error('axios is not defined');

    return null;
  }

  // Register the fetch function with the apiCache helper to help manage cache and concurrent requests
  // Pass the ID as a parameter to the anonymous function so that the cache is unique for each ID
  const registered = registerFetchFn(async (toFetchId) => {
    const { data } = await axios.get(
      `/billing/billing/v2/price-plans/${toFetchId}`
    );

    return data;
  });

  // Configure the fetch function to force (bust cache) according to the input, then execute the fetch function
  return registered?.config({ force })?.execute(id);
};

export const calculateSalesTax = async (
  planId,
  zip,
  setupFee
): Promise<number | undefined> => {
  if (!axios) {
    console.error('axios is not defined');

    return undefined;
  }
  try {
    const { data } = await axios.post(
      `/billing/billing/v2/preview/setup-by-plan-id/${planId}`,
      {
        zipCode: zip,
        setupFee,
      }
    );

    const { totalTax = 0 }: { totalTax: number } = data;

    return totalTax / 100;
  } catch (err) {
    // Return 0 if there is an error so the user is not blocked from purchasing.
    return 0;
  }
};

export default ($axios) => {
  if (!axios) {
    axios = $axios;
  }
};
