









import { defineComponent } from '@nuxtjs/composition-api';
import { canAccessStaffBenefits } from '@/modules/Benefits';

export default defineComponent({
  name: 'StaffBenefitsRoot',
  middleware: [canAccessStaffBenefits],
});
