import ContentfulEntryIDs from '@/lib/globals/ContentfulEntryIDs';

const videoTours = [
  {
    userStateKey: 'viewedVideoTourCabinet',
    contentfulId: ContentfulEntryIDs.VIDEO_TOUR_CABINET,
  },
  {
    userStateKey: 'viewedVideoTourPolicy',
    contentfulId: ContentfulEntryIDs.VIDEO_TOUR_POLICY,
  },
  {
    userStateKey: 'viewedVideoTourReportCards',
    contentfulId: ContentfulEntryIDs.VIDEO_TOUR_REPORT_CARDS,
  },
  {
    userStateKey: 'viewedVideoTourStaffFolder',
    contentfulId: ContentfulEntryIDs.VIDEO_TOUR_STAFF_FOLDER,
  },
];

export default videoTours;
