const OnboardingPages = Object.freeze([
  {
    title: 'Intro',
    slug: 'intro',
  },
  {
    title: 'HR Roadmapping',
    slug: 'hr-roadmap',
  },
  {
    title: 'About your company',
    slug: 'about-your-company',
  },
  {
    title: 'Add your staff',
    slug: 'add-staff',
  },
  {
    title: 'Confirmation',
    slug: 'lets-go',
  },
]);

export default OnboardingPages;
