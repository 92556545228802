
















































































import { defineComponent } from '@nuxtjs/composition-api';
import AnimatedBullet from '@/components/AnimatedBullet';
import { PulseVariant } from '@/components/AnimatedBullet/AnimatedBullet.vue';
import { TypeDisplay, TypeBody, HighlightedIcon } from '@bambeehr/pollen';

export enum CheckType {
  PULSE = 'PULSE',
  DEFAULT = 'DEFAULT',
}

export type ListItem = {
  isComplete: boolean;
  text: string;
  bullet: string;
  shouldPulse?: boolean;
  variant?: PulseVariant;
  classes?: string;
};

export default defineComponent({
  name: 'WizardLayout',
  components: {
    AnimatedBullet,
    TypeDisplay,
    TypeBody,
    HighlightedIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: true,
    },
    checkType: {
      type: String,
      required: false,
      default: CheckType.PULSE,
    },
    listItems: {
      type: Array as () => Array<ListItem>,
      required: false,
    },
    preImage: {
      type: String,
      required: false,
    },
    postImage: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {
      PulseVariant,
      CheckType,
    };
  },
});
