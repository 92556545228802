import format from 'date-fns/format';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ContentfulMarketing from '@/services/ContentfulMarketing';
import { ContentfulMarketingEntryIds } from '@/lib/globals/ContentfulEntryIDs';
import { getCookie, setCookie } from '@/lib/cookie-helpers';
import bam from '@/lib/bam';
import useCurrentUser from '@/hooks/useCurrentUser/useCurrentUser';
import { computed, reactive, ref } from '@nuxtjs/composition-api';

let initialized;

const dependencyReadiness = reactive({
  contentful: false,
  userHook: false,
  ip: false,
});

const dependencies = reactive({
  companyId: '',
  userId: '',
  ip: '',
});

const policy = reactive<{
  header: string;
  body: string;
  version: string;
  updatedDate: string;
  formattedUpdatedDate: string;
  bannerCopy: string;
  agreeCta: string;
}>({
  header: '',
  body: '',
  version: '',
  updatedDate: '',
  formattedUpdatedDate: '',
  bannerCopy: '',
  agreeCta: '',
});

const cookieStr = computed(() => `cookie-policy-v-${policy.version}`);

const hasAcknowledged = ref(false);

const acknowledge = () => {
  hasAcknowledged.value = true;
  setCookie(cookieStr.value, 'true', {
    // Cookie expires in 1 year
    expires: 365,
  });
};

const isReady = computed(() => {
  return Object.values(dependencyReadiness).every((v) => !!v);
});

const trackCookieEvent = (eventName, additionalContext = {}) => {
  bam.track(eventName, {
    ...additionalContext,
    lastUpdated: policy.updatedDate,
    userId: dependencies.userId || '',
    companyId: dependencies.companyId || '',
    ip: dependencies.ip,
  });
};

const setupHook = async () => {
  // Fetches the IP address of the current device
  fetch('https://api.ipify.org?format=json')
    .then((response) => response.json())
    .then((data) => {
      dependencies.ip = data.ip;
    })
    .finally(() => {
      dependencyReadiness.ip = true;
    });

  const content = await ContentfulMarketing.getEntry(
    ContentfulMarketingEntryIds.COOKIE_POLICY_AGREEMENT
  );

  if (content) {
    policy.header = content.fields.header;
    policy.body = documentToHtmlString(content.fields.body);
    policy.bannerCopy = documentToHtmlString(content.fields.bannerCopy);
    policy.updatedDate = content.fields.updatedDate;
    policy.agreeCta = content.fields.agreeCta || 'Acknowledge';
    policy.version = format(
      new Date(content.fields.updatedDate),
      'MMddyyyyHHmm'
    );

    policy.formattedUpdatedDate = format(
      new Date(content.fields.updatedDate),
      'MMMM dd, yyyy'
    );

    const cookie = getCookie(cookieStr.value);
    hasAcknowledged.value = cookie === 'true';
    dependencyReadiness.contentful = true;
  }

  const { currentUserId, companyId } = useCurrentUser();
  // @ts-ignore
  dependencies.companyId = companyId;
  // @ts-ignore
  dependencies.userId = currentUserId;
  dependencyReadiness.userHook = true;
};

const useCookiePolicy = (forceTest = false) => {
  if (!initialized || forceTest) {
    initialized = true;
    setupHook();
  }

  return {
    isReady,
    hasAcknowledged,
    acknowledge,
    policy,
    trackCookieEvent,
  };
};

export default useCookiePolicy;
