
































































import useFileDownloader from '@/composables/useFileDownloader';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useEmployees from '@/hooks/useEmployees';
import UsStates from '@/lib/globals/UsStates.json';
import EmptyDashboardList from '@/modules/payroll/components/DashboardTile/EmptyDashboardList';
import FileDownloadTile from '@/modules/payroll/components/FileDownloadTile/FileDownloadTile.vue';
import MobileTaxDocumentFilters from '@/modules/payroll/components/TaxDocuments/MobileTaxDocumentFilters.vue';
import TaxDocumentFilters, {
  ClassFilters,
  JurisdictionFilters,
  typeOptions,
} from '@/modules/payroll/components/TaxDocuments/TaxDocumentFilters.vue';
import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import { FILE_SERVER_URL } from '@/plugins/apollo/config/createApolloClient';
import { formatDate } from '@/utils/date';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import bam from '@/lib/bam';
import {
  AnnularThrobber,
  BaseButton,
  DrawerPanel,
  RadioGroup,
  TextInput,
  TypeBody,
  BaseBanner,
} from '@bambeehr/pollen';
import useNotifications from '@bambeehr/use-notifications';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  useContext,
  useStore,
  watch,
} from '@nuxtjs/composition-api';
import getYear from 'date-fns/getYear';
import orderBy from 'lodash/orderBy';
import FeatureFlags from '@/constants/FeatureFlags';
import { AxiosRequestConfig } from 'axios';
import useContentful from '@/hooks/useContentful/useContentful';
import { ContentfulEntryIDs } from '@/lib/globals/ContentfulEntryIDs';

export interface TaxDoc {
  description: string;
  filedOn: string;
  id: string;
  jurisdiction: string;
  label: string;
  quarter: string;
  documentClass: string;
  year: number;
  staffId?: string;
  staffName?: string;
  employeeId?: string;
  contractorId?: string;
  companyName?: string;
  staffNameUrl?: string;
}

interface CoreStaff {
  id: string;
  profile: {
    full_name: string;
  };
}

export const getTileTitle = (doc: TaxDoc) => {
  const isFed = doc.jurisdiction === JurisdictionFilters.FED;
  const isStaff = !!doc.employeeId || !!doc.contractorId;
  const getFullStateName = (abbreviation) =>
    UsStates.find((s) => s.abbreviation === abbreviation)?.name || abbreviation;

  if (isStaff) {
    return {
      title: doc.staffName,
      subTitle: doc.label,
    };
  }

  return {
    title: isFed
      ? doc.label
      : getFullStateName(doc.jurisdiction?.toUpperCase()),
    subTitle: isFed ? '' : doc.label,
  };
};

export const handleDashboardTileClick = (
  doc: TaxDoc,
  companyId: string,
  filters: { type: string; year: number },
  fileDownloader: (url: string, name?: string) => void
) => {
  if (!doc) {
    return;
  }

  const isFed = filters.type === JurisdictionFilters.FED;
  const isStaff =
    filters.type === ClassFilters.EMPLOYEE ||
    filters.type === ClassFilters.CONTRACTOR;
  const entityId = isFed ? companyId : doc.employeeId || doc.contractorId;
  let entityName;

  if (isStaff) {
    entityName = filters.type.toLowerCase();
  } else {
    entityName = ClassFilters.COMPANY.toLowerCase();
  }

  const { addInfo } = useNotifications();
  let filename = `${doc.companyName}-${doc.label}-${doc.year}`
    // Remove any non suppored chars
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase();

  if (!isFed) {
    filename = `${doc.staffNameUrl}-${filename}`;
  }

  if (doc.quarter) {
    filename += `-${doc.quarter}`;
  }

  addInfo(`Downloading ${doc.label}`);

  fileDownloader(
    `${FILE_SERVER_URL}/document-secure/${entityName}/${entityId}/tax-document/${doc.id}?filename=${filename}.pdf`,
    `${filename}.pdf`
  );
};

export const handleW2PreviewTileClick = (
  companyId: string,
  year: string,
  fileDownloader: (
    url: string,
    name?: string,
    options?: Partial<AxiosRequestConfig>,
    customErrorNotification?: string
  ) => void
) => {
  const { addInfo } = useNotifications();
  addInfo('Downloading Employee W-2 Previews');

  fileDownloader(
    `${FILE_SERVER_URL}/document-secure/company/${companyId}/w2-preview/${year}`,
    '',
    {},
    "We couldn't find any W-2 previews for the selected year. Please check back later or choose a different year."
  );
};

export const getYearOptions = (companyStartDate?: string) => {
  const currentYear = getYear(new Date());
  const yearList = [String(currentYear)];
  if (companyStartDate) {
    let movingYear = getYear(new Date(companyStartDate));
    while (movingYear !== currentYear) {
      yearList.push(String(movingYear));
      movingYear += 1;
    }
  }

  return yearList.sort().reverse();
};

enum SortOrder {
  ASC = 'asc',
  DEC = 'desc',
}

export const filterTaxDocs = (
  taxDocs: TaxDoc[],
  searchFilter: string,
  typeFilter?: string,
  companyName?: string
): TaxDoc[] => {
  const baseSortList = ['year', 'quarter', 'filedOn'];
  const baseSortOrder = [SortOrder.ASC, SortOrder.DEC, SortOrder.ASC];
  const isFed = typeFilter === JurisdictionFilters.FED;
  const sortList = isFed ? baseSortList : ['staffNameSorter', ...baseSortList];
  const sortOrder = isFed ? baseSortOrder : [SortOrder.ASC, ...baseSortList];
  const filteredDocs = taxDocs
    .map((doc) => ({
      ...doc,
      formatted: {
        filedOn: formatDate(doc.filedOn.replace('-', '/'), ''),
      },
      companyName,
    }))
    .filter(
      (d) =>
        d?.label?.toLowerCase().includes(searchFilter.trim().toLowerCase()) ||
        d?.staffName?.toLowerCase().includes(searchFilter.trim().toLowerCase())
    );

  return orderBy(filteredDocs, sortList, sortOrder as any);
};

export default defineComponent({
  name: 'TaxDocuments',
  components: {
    AnnularThrobber,
    EmptyDashboardList,
    FileDownloadTile,
    TextInput,
    TaxDocumentFilters,
    MobileTaxDocumentFilters,
    BaseBanner,
  },
  props: {
    companyId: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { all: allCoreStaff } = useEmployees();
    const { fileDownloader } = useFileDownloader();
    // Prepare to fetch Contentful content
    const contentIsReady = ref(false);
    const { fetchContent } = useContentful();
    const contentfulContent = ref({
      body: '',
      title: '',
      show: false,
    });

    const { listTaxDocuments, getCompany } = usePayrollService();
    const { data: company } = getCompany(props.companyId, {});
    const { company: coreCompany } = useCurrentCompany();

    const isLoading = ref(true);
    const taxDocCompany = ref();

    const searchFilter = ref('');

    const yearOptions = computed(() =>
      company.value?.taxDocYears.length
        ? company.value.taxDocYears
        : getYearOptions(company.value?.startDate)
    );

    const filters = reactive({
      type: typeOptions[0].value,
      year: yearOptions.value?.[0],
    });

    const filteredOnW2 = computed(() => filters.type === ClassFilters.EMPLOYEE);
    const showW2PreviewReportFlagOn = computed(
      () => launchDarkly.getFlags()[FeatureFlags.W2_PREVIEW_REPORT]
    );

    function fetchTaxDocs() {
      let documentClass = ClassFilters.COMPANY;
      let jurisdiction = JurisdictionFilters.ALL;

      switch (filters.type) {
        case ClassFilters.EMPLOYEE:
        case ClassFilters.CONTRACTOR:
          documentClass = filters.type;
          break;

        case JurisdictionFilters.FED:
        case JurisdictionFilters.LOCAL:
          jurisdiction = filters.type;
          break;

        default:
          break;
      }

      listTaxDocuments(
        {
          id: props.companyId,
          year: Number(filters.year),
          documentClass,
          jurisdiction,
        },
        {
          pending: isLoading,
          data: taxDocCompany,
        }
      );
    }

    const taxDocs = computed(() => taxDocCompany.value?.taxDocuments || []);

    watch(
      filters,
      () => {
        fetchTaxDocs();
      },
      { immediate: true }
    );

    // Bit of a hack to address missing users in this list.
    const appendedStaffItems = ref<CoreStaff[]>([]);

    const decoratedTaxDocs = computed(() =>
      filters.type === ClassFilters.EMPLOYEE ||
      filters.type === ClassFilters.CONTRACTOR
        ? taxDocs.value.map((doc) => {
            // @ts-ignore -- 'never' conversion failure from useEmployees
            const staffId = doc.employeeId || doc.contractorId;
            const staffItem = (
              [
                ...(allCoreStaff.value || []),
                ...appendedStaffItems.value,
              ] as CoreStaff[]
            )?.find((s) => s.id === staffId);

            if (!staffItem && staffId) {
              // We don't always get every staff member in the useEmployees list (specifically the admin / owner)
              // If we're missing a staffId, we'll get it here.
              (async () => {
                const fetchedStaffItem = await store.dispatch(
                  'users/getEmployee',
                  staffId
                );

                if (fetchedStaffItem) {
                  appendedStaffItems.value = [
                    ...appendedStaffItems.value,
                    fetchedStaffItem,
                  ];
                }
              })();
            }

            const staffName = staffItem?.profile
              ? staffItem?.profile.full_name
              : '';

            return {
              ...doc,
              staffName,
              staffNameUrl: staffName.split(' ').join('-'),
              staffNameSorter: staffName.toLowerCase().split(' ').join(''),
            };
          })
        : taxDocs.value
    );

    const filteredDocs = computed(() =>
      filterTaxDocs(
        decoratedTaxDocs.value,
        searchFilter.value,
        filters.type,
        coreCompany.value?.name || ''
      )
    );

    const sendSegmentEvent = async () => {
      const { currentUser } = store.getters;
      const { username, email } = currentUser._auth;
      bam.track('w2_previews_downloaded', {
        username,
        email,
        fullName: currentUser.profile.full_name,
        companyName: currentUser._company.name,
        dba: currentUser._company?.profile?.dba,
        timestamp: new Date(),
      });
    };

    // Fetch the content when component is mounted
    (async () => {
      const content = await fetchContent(
        ContentfulEntryIDs.TAX_DOCUMENTS_BANNER
      );
      contentfulContent.value = content || {};
      contentIsReady.value = true;
    })();

    return {
      fileDownloader,
      filteredDocs,
      yearOptions,
      handleDashboardTileClick,
      handleW2PreviewTileClick,
      sendSegmentEvent,
      isLoading,
      typeOptions,
      searchFilter,
      filteredOnW2,
      showW2PreviewReportFlagOn,
      getTileTitle,
      filters,
      contentIsReady,
      contentfulContent,
    };
  },
});
