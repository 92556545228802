/* eslint-disable no-unused-vars */
import SvgIcon from '@/components/common/images/SvgIcon';
// TODO(jon.jandoc): Remove and replace with Pollen AnnularThrobber.
import LoaderIcon from '@/components/common/LoaderIcon';
import Vue from 'vue';
import wizardCard from '~/components/cap/wizard-card.vue';
import bamButton from '~/shared/components/utility/bam-button.vue';
import card from '~/shared/components/utility/card-components/card.vue';
import crumbs from '~/shared/components/utility/crumbs/crumbs.vue';
import fileUpload from '~/shared/components/utility/file-upload.vue';
import bamModal from '~/shared/components/utility/modals/bam-modal.vue';
import topBar from '~/shared/components/utility/nav/top-bar.vue';

Vue.component('top-bar', topBar);
Vue.component('crumbs', crumbs);
Vue.component('file-upload', fileUpload);
Vue.component('wizard-card', wizardCard);
Vue.component('bam-modal', bamModal);
Vue.component('card', card);
Vue.component('bam-button', bamButton);

Vue.component('svg-icon', SvgIcon);
Vue.component('loader-icon', LoaderIcon);
