<template>
  <div id="earnings-header" class="w-full h-full flex flex-row items-center">
    <div class="ml-7 md:hidden">
      <BaseIcon
        icon="chevronLeft"
        class="text-base-600 cursor-pointer"
        @click="$emit('backIconClick')"
      />
    </div>
    <UserAvatar image="" :name="fullName" :title="role" class="md:ml-6 ml-5" />
    <div class="ml-auto right-header-block mr-1">
      <TypeBody
        v-if="hourlyRateStr"
        tag="div"
        variant="link-x-small-tight"
        weight="strong"
        :class="{
          'border-b border-dashed leading-3 cursor-pointer':
            !isHourly && !isContract,
        }"
        class="mb-0.5 text-base-800 text-right"
        @click="toggleRateType"
      >
        {{ hourlyRateStr }}
      </TypeBody>
      <TypeBody
        v-if="employmentType"
        tag="div"
        variant="text-x-small-tight"
        weight="medium"
        class="text-base-600 text-right"
      >
        {{ employmentTypeLabel }}
      </TypeBody>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@nuxtjs/composition-api';
import { TypeBody, BaseIcon, UserAvatar } from '@bambeehr/pollen';
import currency from '@bambeehr/currency';
import toHourlyRate from '@/modules/payroll/utils/toHourlyRate';
import { PayTypes, UserPreference } from '@/modules/payroll/constants/payroll';
import { getUserPreference, setUserPreference } from '~/utils/userPreference';

export default {
  components: {
    TypeBody,
    BaseIcon,
    UserAvatar,
  },
  props: {
    fullName: {
      type: String,
      default: 'Full Name',
      required: true,
    },
    role: {
      type: String,
      default: 'employee',
      required: true,
    },
    employmentType: {
      type: String,
      required: true,
    },
    payRate: {
      type: Number,
      default: 0,
      required: true,
    },
    payType: {
      type: String,
      required: true,
    },
    isContractor: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const hasHourlyPreference = ref(
      getUserPreference(UserPreference.SHOW_ANNUAL_AS_HOURLY)
    );
    const { payType } = props;
    const isHourly = payType === PayTypes.HOURLY;
    const isContract = payType === PayTypes.CONTRACT;

    const payRate = computed(() => props.payRate);

    const employmentTypeLabel = computed(
      () => `${props.employmentType} ${props.isContractor ? '' : ' Employee'}`
    );

    const hourlyRateStr = computed(() => {
      const rate =
        isHourly || hasHourlyPreference.value
          ? toHourlyRate(payType, payRate.value)
          : payRate.value;

      const payTypeLabel = (() => {
        if (isContract) {
          if (payRate.value) {
            return 'contract';
          }

          return null;
        }

        return isHourly || hasHourlyPreference.value ? 'hour' : 'year';
      })();

      return payTypeLabel ? `${currency(rate).format()}/${payTypeLabel}` : '';
    });

    function toggleRateType() {
      hasHourlyPreference.value = !hasHourlyPreference.value;
      setUserPreference(
        UserPreference.SHOW_ANNUAL_AS_HOURLY,
        hasHourlyPreference.value
      );
    }

    return {
      employmentTypeLabel,
      hasHourlyPreference,
      hourlyRateStr,
      isContract,
      isHourly,
      toggleRateType,
    };
  },
};
</script>
