import gql from 'graphql-tag';

export const ENROLLMENT_RESPONSE_FRAGMENT = gql`
  fragment EnrollmentResponseParts on EnrollUsersResponse {
    companyId
    enrolled
    errors
  }
`;

export const FULL_USER_COURSE_FRAGMENT = gql`
  fragment UserCourseParts on UserCourse {
    name
    searchTag
    language
    state
    userRole
    status
    enrolledAt
    startedAt
    completedAt
    certificateDownloadUrl
  }
`;

export const SHORT_COMPANY_FRAGMENT = gql`
  fragment CompanyParts on Company {
    companyId
    companyMngId
    purchasedProducts {
      bundles {
        bundleId
        name
        totalSeats
        unfilledSeats
      }
      topics {
        topicId
        name
        totalSeats
        unfilledSeats
      }
    }
  }
`;

export const FULL_COMPANY_FRAGMENT = gql`
  fragment CompanyParts on Company {
    companyId
    companyMngId
    users {
      language
      role
      state
      userId
      userMngId
    }
    purchasedProducts {
      bundles {
        bundleId
        description
        name
        topics {
          name
          topicId
          enrollments {
            certificateDownloadUrl
            completedAt
            enrolledAt
            language
            role
            startedAt
            state
            userId
            userMngId
          }
        }
        totalSeats
        unfilledSeats
      }
    }
  }
`;
