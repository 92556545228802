import Vue from 'vue';
import { defineStore } from 'pinia';

const useConversationsStore = defineStore('conversations', {
  state: () => ({
    conversations: [] as any[],
    active_conversation: null,
  }),
  actions: {
    setConversations(conversations) {
      this.conversations = conversations;
    },
    addConversation(conversation) {
      this.conversations.unshift(conversation);
    },
    setActiveConversation(conversation) {
      Vue.set(this.$state, 'active_conversation', conversation);
    },

    setConversation(conversation) {
      const index = this.conversations.findIndex(
        (c) => c.id === conversation.id
      );

      if (index === -1) {
        return;
      }

      this.conversations.splice(index, 1, conversation);

      this.active_conversation = conversation;
    },
    createConversation({ type, ticket_id, text, chat_now }: any) {
      return this.$nuxt.$axios
        .post('/v0/app/conversation/create', {
          message: {
            type,
            ticket_id,
            text,
            chat_now,
          },
        })
        .then((res) => {
          this.addConversation(res.data);

          return res.data;
        });
    },

    getMyConversations() {
      return this.$nuxt.$axios.get('/v0/app/conversation/my').then((res) => {
        this.setConversations(res.data);

        return res.data;
      });
    },

    getConversation(conversation_id) {
      return this.$nuxt.$axios
        .get(`/v0/app/conversation/${conversation_id}`)
        .then((res) => {
          this.setActiveConversation(res.data);

          return res.data;
        });
    },

    createReply(payload) {
      return this.$nuxt.$axios
        .post(
          `/v0/app/conversation/${this.active_conversation_getter.id}/add-message`,
          payload
        )
        .then((res) => {
          this.setConversation(res.data);

          return res.data;
        });
    },
  },

  getters: {
    all(state): any {
      return state.conversations;
    },
    active_conversation_getter(state): any {
      return state.active_conversation || null;
    },
  },
});

export default useConversationsStore;
