import { useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import CachePolicy from '@/gql/CachePolicy';
import { CompanyStatuses } from '@/modules/payroll/constants/company';
import { useGetCompanyOnboardingStatusQuery } from '@/gql/generated';
import { computed, ref, watch } from '@nuxtjs/composition-api';

let initialized = false;

const { companyId, company: coreCompany } = useCurrentCompany();

const payrollCompany = ref();
const isLoadingPayrollCompany = ref(true);
const isOnboarding = ref(false);

const getPayrollCompany = () => {
  const unwatch = watch(
    companyId,
    (id) => {
      if (id) {
        isLoadingPayrollCompany.value = true;
        const { onResult: onCompanyResult, onError } = useApolloQuery(
          useGetCompanyOnboardingStatusQuery,
          { id },
          undefined,
          { handleErrors: false },
          { fetchPolicy: CachePolicy.NETWORK_ONLY }
        );

        onCompanyResult(({ getCompany: res }) => {
          payrollCompany.value = res;
          isLoadingPayrollCompany.value = false;
        });

        onError((err) => {
          window?.DD_RUM?.addError('error fetching payroll company', err);
        });

        if (unwatch) {
          unwatch();
        }
      }
    },
    { immediate: true }
  );
};

// Payroll status helpers
const invalidStatuses = [CompanyStatuses.BACKED_OUT, CompanyStatuses.CANCELLED];
const hasPayroll = computed(
  () =>
    !!payrollCompany.value?.id &&
    !invalidStatuses.includes(payrollCompany.value.status)
);
const hasStartedPayroll = computed(
  () =>
    hasPayroll.value &&
    payrollCompany.value?.status !== CompanyStatuses.PROVISIONED
);
const hasCompletedPayrollOnboarding = computed(
  () =>
    hasStartedPayroll.value &&
    !payrollCompany.value?.checkOnboarding?.blockingSteps?.length
);

const payrollCompanyStatus = computed(() => payrollCompany.value?.status);
const payrollCompanyStatusIsLive = computed(() => {
  return payrollCompany.value?.status === CompanyStatuses.LIVE;
});

const isLoading = computed(() => isLoadingPayrollCompany.value);

const excludedIdList = computed(() => payrollCompany.value.excludedStaffIds);

const coreTotalCount = computed(() => {
  if (!coreCompany.value?.profile) {
    return 0;
  }
  const {
    num_of_contractors,
    num_of_full_time_hourly_employees,
    num_of_full_time_salary_employees,
    num_of_part_time_employees,
  } = coreCompany.value.profile;

  return (
    num_of_contractors +
    num_of_full_time_hourly_employees +
    num_of_full_time_salary_employees +
    num_of_part_time_employees
  );
});

const hasRemainingWorkerOnboarding = computed(
  () =>
    payrollCompany.value?.employees?.length +
      payrollCompany.value?.contractors?.length <
    coreTotalCount.value
);

const usePayrollOnboardingStatus = (force = false) => {
  if (!initialized || force) {
    getPayrollCompany();
    initialized = true;
  }

  return {
    excludedIdList,
    hasCompletedPayrollOnboarding,
    hasPayroll,
    hasRemainingWorkerOnboarding,
    hasStartedPayroll,
    isLoading,
    isLoadingPayrollCompany,
    isOnboarding,
    payrollCompany,
    payrollCompanyStatus,
    payrollCompanyStatusIsLive,
    refreshPayrollCompany: getPayrollCompany,
  };
};

export default usePayrollOnboardingStatus;
