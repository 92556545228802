






































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TypeBody, TextInput, BaseButton } from '@bambeehr/pollen';

export interface Signatory {
  email: string;
  signerTitle: string;
  signerName: string;
}

export default defineComponent({
  name: 'SignatoryForm',
  components: {
    TypeBody,
    TextInput,
    BaseButton,
  },
  props: {
    signatoryForm: {
      type: Object as PropType<Signatory>,
      required: true,
    },
    isValid: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isSendingEmail: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
});
