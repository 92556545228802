


















import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { BaseButton, TypeBody } from '@bambeehr/pollen';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import useBambeeUpsell from '@/hooks/useBambeeUpsell/useBambeeUpsell';
import differenceindays from 'date-fns/differenceInDays';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import { useGetBillingInfoQuery } from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';

export default defineComponent({
  components: {
    BaseButton,
    TypeBody,
  },

  setup() {
    const { isBambeePause } = usePlanAccess();
    const { togglePlanAction } = useBambeeUpsell();
    const { companyId } = useCurrentCompany();

    const { onResult } = useApolloQuery(useGetBillingInfoQuery, {
      input: {
        companyId: companyId.value,
      },
    });

    const reactivationDate = ref();
    onResult(({ getBillingInfo: res }) => {
      reactivationDate.value = res?.pause.reactivatesOn;
    });

    const daysUntilReactivation = computed(() =>
      reactivationDate.value
        ? differenceindays(new Date(reactivationDate.value), new Date())
        : ''
    );

    const daysCopy = computed(() => {
      if (!daysUntilReactivation.value) {
        return '';
      }

      if (daysUntilReactivation.value === 0) {
        return 'today';
      }

      if (daysUntilReactivation.value === 1) {
        return 'tomorrow';
      }

      return `in ${daysUntilReactivation.value} days`;
    });

    return {
      isBambeePause,
      togglePlanAction,
      daysCopy,
    };
  },
});
