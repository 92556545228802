import { PayerType, PaymentMethod } from '@/modules/payroll/constants/payroll';
import {
  getCompanyContributionList,
  getEmployeeContributionList,
} from '@/modules/payroll/utils/benefits';
import { StaffPayItem } from '@/modules/payroll/utils/payFactory';
import {
  getCompanyTaxList,
  getEmployeeTaxList,
  totalTaxesByType,
} from '@/modules/payroll/utils/tax';
import { FILE_SERVER_URL } from '@/plugins/apollo/config/createApolloClient';
import currency from '@bambeehr/currency';
import useNotifications from '@bambeehr/use-notifications';
import { computed, ref } from '@nuxtjs/composition-api';

export const formatMoney = (val: number) => ({
  value: currency(val).format(),
  raw: val,
});

const payrollIsApproved = ref(false);

// Will add and update types with auto generated typings here: PAY-1940
const totals = ref<any>(null);
const formattedTotals = computed(() => {
  if (!totals.value) {
    return null;
  }

  const postTaxTotal = computed(() =>
    totals.value
      ? totals.value?.postTaxDeductions + totals.value?.employeeBenefits
      : 0
  );

  return {
    employee: {
      gross: formatMoney(totals.value.employeeGross),
      net: formatMoney(totals.value.employeeNet),
      employeeTaxes: formatMoney(totals.value.employeeTaxes),
      reimbursements: formatMoney(totals.value.employeeReimbursements),
      postTaxDeductions: formatMoney(postTaxTotal.value),
      employeeBenefits: formatMoney(totals.value.employeeBenefits),
      companyTaxes: formatMoney(totals.value.companyTaxes),
      companyBenefits: formatMoney(totals.value.companyBenefits),
    },
    contractor: {
      gross: formatMoney(totals.value.contractorGross),
      net: formatMoney(totals.value.contractorNet),
      reimbursements: formatMoney(totals.value.contractorReimbursements),
    },
  };
});

export const getPayListTotal = (list) =>
  list.reduce((acc, item) => (item.amount || 0) + acc, 0);

function getEmployeePayDetails(staffPayItem) {
  const payDetails: { description: string; amount: number }[][] = [];
  const employeeTaxes = totalTaxesByType(getEmployeeTaxList([staffPayItem]));
  const employerTaxes = totalTaxesByType(getCompanyTaxList([staffPayItem]));
  const companyContributions = getCompanyContributionList([staffPayItem]);
  const employeeContributions = getEmployeeContributionList([staffPayItem]);
  const deductionsList = staffPayItem.postTaxDeductions;

  if (staffPayItem.reimbursementsTotal) {
    payDetails.push([
      {
        description: 'Reimbursements',
        amount: staffPayItem.reimbursementsTotal,
      },
    ]);
  }

  if (employeeTaxes?.length) {
    payDetails.push([
      {
        description: 'Employee Taxes',
        amount: getPayListTotal(employeeTaxes),
      },
      ...employeeTaxes,
    ]);
  }

  if (employeeContributions?.length || deductionsList?.length) {
    payDetails.push([
      {
        description: 'Employee Deductions',
        amount: getPayListTotal([...employeeContributions, ...deductionsList]),
      },
      ...employeeContributions,
      ...deductionsList,
    ]);
  }

  if (companyContributions?.length) {
    payDetails.push([
      {
        description: 'Company Contributions',
        amount: getPayListTotal(companyContributions),
      },
      ...companyContributions,
    ]);
  }

  if (employerTaxes?.length) {
    payDetails.push([
      {
        description: 'Company Taxes',
        amount: getPayListTotal(employerTaxes),
      },
      ...employerTaxes,
    ]);
  }

  return {
    earnings: payDetails,
    gross: staffPayItem.grossPay,
    total: staffPayItem.netPay,
    totalLabel: 'Net Pay',
  };
}

function getContractorPayDetails(staffPayItem) {
  const payDetails: { description: string; amount: number }[][] = [];

  if (staffPayItem.reimbursementsTotal) {
    payDetails.push([
      {
        description: 'Reimbursements',
        amount: staffPayItem.reimbursementsTotal,
      },
    ]);
  }

  return {
    earnings: payDetails,
    gross: staffPayItem.grossPay,
    grossLabel: 'Payment Amount',
    total: staffPayItem.netPay,
    totalLabel: 'Total Contractor Pay',
  };
}

const allPayItems = ref<StaffPayItem[]>([]);
const formattedPayItems = computed(() => {
  const payItems = allPayItems.value
    .filter((p) => p.grossPay || p.netPay)
    .map((item) => {
      let normalizedPayMethod = PaymentMethod.DIRECT_DEPOSIT.label;

      if (item.paymentMethod.toUpperCase() === PaymentMethod.MANUAL.value) {
        normalizedPayMethod = 'Check';

        if (item.checkNumber) {
          normalizedPayMethod += ` (${item.checkNumber})`;
        }
      }

      return {
        ...item,
        grossPay: formatMoney(<number>item.grossPay),
        netPay: formatMoney(item.netPay),
        reimbursementsTotal: formatMoney(item.reimbursementsTotal),
        payMethod: normalizedPayMethod,
      };
    });

  const contractors = payItems.filter((p) => p.isContractor);
  const employees = payItems
    .filter((p) => !p.isContractor)
    .map((item) => {
      const employeeTaxes =
        item?.taxes
          ?.filter((tax) => tax.payer === PayerType.EMPLOYEE)
          ?.reduce((currentAmount, tax) => currentAmount + tax.amount, 0) ?? 0;

      const companyTaxes =
        item.taxes
          ?.filter((tax) => tax.payer === PayerType.COMPANY)
          ?.reduce((currentAmount, tax) => currentAmount + tax.amount, 0) ?? 0;

      const employeeBenefits =
        getEmployeeContributionList([item])?.reduce(
          (currentAmount, benefit) => currentAmount + benefit.amount,
          0
        ) ?? 0;

      const companyBenefits =
        getCompanyContributionList([item])?.reduce(
          (currentAmount, benefit) => currentAmount + benefit.amount,
          0
        ) ?? 0;

      const employeeDeductions =
        item.postTaxDeductions?.reduce(
          (currentAmount, deduction) => currentAmount + deduction.amount,
          employeeBenefits
        ) ?? 0;

      return {
        ...item,
        companyBenefits: formatMoney(companyBenefits),
        companyTaxes: formatMoney(companyTaxes),
        employeeBenefits: formatMoney(employeeBenefits),
        employeeDeductions: formatMoney(employeeDeductions),
        employeeTaxes: formatMoney(employeeTaxes),
      };
    });

  return {
    contractors,
    employees,
  };
});

function getStaffPayDetails(staffId: string) {
  const staffPayItem = allPayItems.value.find((i) => i.staff.id === staffId);
  const detailsFn = staffPayItem?.isContractor
    ? getContractorPayDetails
    : getEmployeePayDetails;

  return detailsFn(staffPayItem);
}

const hasContractors = computed(
  () => !!formattedPayItems.value.contractors?.length
);
const hasEmployees = computed(
  () => !!formattedPayItems.value.employees?.length
);

const isNoteModalOpen = ref(false);
const note = ref('');
function toggleNoteModal(thisNote = '') {
  isNoteModalOpen.value = !isNoteModalOpen.value;
  note.value = thisNote;
}

const { addInfo } = useNotifications();
function showDownloadNotification(payDetail) {
  const payLabel = payDetail.isContractor ? 'payment' : 'pay stub';

  addInfo(`Downloading ${payLabel} for ${payDetail.staff.profile.fullName}`);
}

function downloadPaystub(payItem, downloader) {
  downloader(
    `${FILE_SERVER_URL}/document-secure/${
      payItem.isContractor ? 'payment' : 'paystub'
    }/${payItem.id}`,
    `${payItem.isContractor ? 'payment' : 'paystub'}-${payItem.id}`
  );
  showDownloadNotification(payItem);
}

function getEarningsModalTitle(fullName: string) {
  return `${fullName} Pay Breakdown`;
}

function getNoteTitle(isVisible: boolean) {
  return `Note ${isVisible ? '(visible to workers)' : ''}`;
}

const useStaffPayTable = () => ({
  allPayItems,
  formattedPayItems,
  downloadPaystub,
  formattedTotals,
  getStaffPayDetails,
  hasContractors,
  hasEmployees,
  payrollIsApproved,
  totals,
  isNoteModalOpen,
  note,
  toggleNoteModal,
  getEarningsModalTitle,
  getNoteTitle,
});

export default useStaffPayTable;
