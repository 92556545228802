import Contentful from '@/services/Contentful';

export enum ExpectationCopyId {
  PAYROLL = 'uysPz6ipLGD8e15sFlI3Z',
  CORE = '7sY2Zv5JzMemu4LqSBZPRx',
  CORE_AFTER_PAYROLL = '4ce7eyswZUIbHe3ikwZvbI',
  FINISHED_WITH_CORE_ONLY = '1bZcJKr9RMolHEnAcXa1qJ',
  FINISHED_WITH_CORE_AND_PAYROLL = '4o8TFoiBtnt92QXjF4Hb2w',
  FINISHED_WITH_CORE_NEEDS_WORKER_INFO = 'OFrAph6coGFeyChG4eNDy',
  PAYROLL_ONBOARDING_CONFIRMATION = 'AGPNj5OvAuxBJG2Xy7Mer',
}

export const fetchContent = async (entryId: string) => {
  const response = await Contentful.getEntry(entryId);

  return response.fields;
};

export const formatMediaInfo = (content) =>
  content?.media?.fields?.file
    ? {
        src: content?.media?.fields?.file?.url,
        alt: content?.media?.fields?.description,
      }
    : {};

export default null;
