










































































import { BaseButton } from '@bambeehr/pollen';
import { computed, useRoute } from '@nuxtjs/composition-api';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import HexLoader from '@/components/HexLoader/HexLoader.vue';

export default {
  name: 'OnboardingWizardActions',
  components: {
    BaseButton,
    HexLoader,
  },
  props: {
    next: {
      type: Object,
      required: false,
      default: null,
    },
    disableNext: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadingNext: {
      type: Boolean,
      required: false,
      default: false,
    },
    finishLater: {
      type: Object,
      required: false,
      default: null,
    },
    back: {
      type: Object,
      required: false,
      default: null,
    },
    complete: {
      type: Object,
      required: false,
      default: null,
    },
    skip: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const route = useRoute();
    const loadingClass = computed(() => (props.loadingNext ? 'loading' : ''));
    const { isOnboarding } = usePayrollOnboardingStatus();

    // If on the get-started version of this flow, we don't want to show some UI
    const isGetStartedPage = computed(
      () =>
        route.value.path.includes('get-started') ||
        route.value.path.includes('confirmation')
    );

    // If no back or finishLater button, center the next button
    const centerNextBtn = computed(
      () => !(props.finishLater || props.skip || props.back)
    );

    return {
      loadingClass,
      isOnboarding,
      isGetStartedPage,
      centerNextBtn,
    };
  },
};
