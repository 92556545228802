const appEnv = process.env.APP_ENV;

export const CookieNames = Object.freeze({
  ACCESS_TOKEN: `bambee-${appEnv}-access-token`,
  REFRESH_TOKEN: `bambee-${appEnv}-refresh-token`,
  MASQUERADE: `bambee-${appEnv}-masquerade`,
  UPGRADE_DISMISSED: `bambee-${appEnv}-upgrade-dismissed`,
});

export default CookieNames;
