export const CompanyStatuses = Object.freeze({
  LIVE: 'LIVE',
  SETUP_COMPLETE: 'SETUP_COMPLETE',
  INITIALIZED: 'INITIALIZED',
  PROVISIONED: 'PROVISIONED',
  BACKED_OUT: 'BACKED_OUT',
  CANCELLED: 'CANCELLED',
});

export const OnboardingSteps = Object.freeze({
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  FILING_AUTHORIZATION: 'FILING_AUTHORIZATION',
  SETUP_PARAMETERS: 'SETUP_PARAMETERS',
});

export const ProcessingPeriod = Object.freeze({
  TWO_DAY: 'TWO_DAY',
  FOUR_DAY: 'FOUR_DAY',
});

export const CheckOnboardingStatus = Object.freeze({
  BLOCKING: 'BLOCKING',
  COMPLETED: 'COMPLETED',
  NEEDS_ATTENTION: 'NEEDS_ATTENTION',
});
