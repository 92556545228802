


























































































































import {
  defineComponent,
  PropType,
  computed,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { Address } from '@@/types/company';
import pick from 'lodash/pick';
import { SelectInput, TypeBody } from '@bambeehr/pollen';
import UsStates from '@/lib/globals/UsStates.json';
import useAddressForm from '@/components/AddressForm/useAddressForm';

import DescriptionTextInput from '@/components/DescriptionTextInput/DescriptionTextInput.vue';
import AddressFormMessaging from '@/components/AddressForm/AddressFormMessaging.vue';

export interface FormLabels extends Address {
  line1Description?: string;
  line2Description?: string;
}

const defaultLabels = {
  line1: 'Address',
  line1Description: '',
  line2: 'Address Line 2 (optional)',
  line2Description: '',
  zip: 'Zip',
  city: 'City',
  state: 'State',
};

export default defineComponent({
  name: 'AddressForm',
  components: {
    DescriptionTextInput,
    SelectInput,
    TypeBody,
    AddressFormMessaging,
  },
  props: {
    form: {
      type: Object as PropType<Address>,
      required: true,
    },
    formLabels: {
      type: Object as PropType<FormLabels>,
      required: false,
    },
    showLabels: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isValidating: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    size: {
      type: String as PropType<'normal' | 'large'>,
      required: false,
      default: 'normal',
    },
    gridView: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const states = UsStates.map((s) => s.abbreviation);
    const {
      addressCandidate,
      addressIsValid,
      addressKey,
      handleAddressEvent,
      hideSuggestions,
      isDisabled,
      selectSuggestion,
      showSuggestions,
      suggestions,
      toggleSuggestion,
      updateAddressSuggestions,
      workingForm,
    } = useAddressForm();

    // Set initial data from DB
    Object.assign(workingForm, pick(props.form, Object.keys(defaultLabels)));

    // eslint-disable-next-line
    isDisabled.value = props.isDisabled;

    // Setting defaults for labels if some are unset
    const labels = computed<FormLabels>(() => {
      return Object.assign(defaultLabels, props.formLabels);
    });

    // Handles the clicking out of active address suggestions input
    // There's a sequencing issue with the click and blur event so they can't be used in this context
    const handleUnfocusEvents = (event) => {
      const addressHolder = document.getElementById('address-select');
      const addressInput = document.getElementById('address-input');
      const clickedEl = event.target as Node;

      if (
        !addressHolder?.contains(clickedEl) &&
        !addressInput?.contains(clickedEl)
      ) {
        hideSuggestions();
      }
    };

    const errorMessage = computed(() =>
      props.isValidating && !!addressCandidate.value && !addressIsValid.value
        ? 'Please use the suggested address below'
        : ''
    );

    onMounted(() => {
      window.addEventListener('click', handleUnfocusEvents);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('click', handleUnfocusEvents);
    });

    return {
      addressCandidate,
      addressIsValid,
      addressKey,
      errorMessage,
      labels,
      showSuggestions,
      states,
      suggestions,
      workingForm,

      handleAddressEvent,
      selectSuggestion,
      toggleSuggestion,
      updateAddressSuggestions,
    };
  },
});
