import useMiddlewareContext from './hooks/useMiddlewareContext';
import { removeTrailingSlash } from '@/utils/routes';

export default () => {
  const { currentUser, route, redirect } = useMiddlewareContext();

  const { role, _auth } = currentUser;
  const { activation_status } = _auth;

  if (['created', 'email-sent'].includes(activation_status)) {
    if (
      [
        '/privacy/privacy',
        '/terms/terms',
        '/employee-welcome/',
        '/activate-account',
      ].includes(removeTrailingSlash(route.path))
    ) {
      return;
    }

    if (route.path.includes('/employee-welcome/')) {
      return;
    }

    if (role === 'user') {
      redirect('/activate-account');

      return;
    }

    redirect('/employee-welcome/');
  }
};
