























import { ProgressStepper } from '@bambeehr/pollen';
import {
  ref,
  computed,
  useRoute,
  watch,
  PropType,
  defineComponent,
} from '@nuxtjs/composition-api';

export interface ProgressStep {
  label: string;
  value: string;
}

export enum TransitionClass {
  FORWARDS = 'forwards',
  BACKWARDS = 'backwards',
  UNSET = '',
}

export const overrideTransitionClass = ref<string>('');

export default defineComponent({
  name: 'WizardFlow',
  components: { ProgressStepper },
  props: {
    showStepper: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    steps: {
      type: Array as PropType<Array<ProgressStep>>,
      required: true,
    },
  },
  setup(props) {
    const transitionClass = ref<string>(TransitionClass.FORWARDS);
    const route = useRoute();

    const currentStep = computed<ProgressStep | undefined>(() =>
      props.steps.find((s) => route.value.fullPath.includes(s.value))
    );

    const currentStepValue = computed<string | null>(() =>
      currentStep.value ? currentStep.value.value : null
    );

    function handleTransition(toOption, fromOption): void {
      if (toOption && fromOption) {
        const fromStep = props.steps.findIndex(
          (p) => p.value === fromOption.value
        );
        const toStep = props.steps.findIndex((p) => p.value === toOption.value);

        if (overrideTransitionClass.value) {
          transitionClass.value = overrideTransitionClass.value;
          overrideTransitionClass.value = TransitionClass.UNSET;
        } else {
          transitionClass.value =
            fromStep > toStep
              ? TransitionClass.BACKWARDS
              : TransitionClass.FORWARDS;
        }
      }
    }

    watch(
      () => currentStep.value,
      (toOption, fromOption) => {
        handleTransition(toOption, fromOption);
      }
    );

    return {
      currentStep,
      currentStepValue,
      handleTransition,
      transitionClass,
    };
  },
});
