/**
 * Takes a string with tokens wrapped in double curly braces and swaps those
 * tokens out according to a provided content map.
 * i.e. given the string 'Hello {{WORLD}}, my name is {{ FIRST_NAME }},
 * {{WORLD  }}' and the content map { WORLD: 'world', FIRST_NAME: 'Jon' } this
 * should return 'Hello world, my name is Jon, world'.
 * @param {string} string
 * @param {object} contentMap
 * @returns {string}
 */
const interpolateContent = (string, contentMap = {}) => {
  let newString = string;
  Object.entries(contentMap).forEach(([key, value]) => {
    const reStr = `{{(\\s*)+(${key})(\\s*)+}}`;
    const re = new RegExp(reStr, 'g');
    // .replace() is choking on strings that include special characters like "$"
    // To get around that, we get the literal string value and replace on that
    const match = newString.match(re);
    if (match) {
      match.forEach((m) => {
        newString = newString.replace(m, value);
      });
    }
  });

  return newString;
};

export default interpolateContent;
