import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCompanyService from '@/hooks/useCompanyService';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import usePolicyReaffirmationFlags from '@/hooks/usePolicyReaffirmationFlags/usePolicyReaffirmationFlags';

import {
  CoreDocument,
  useGetMyCoreUserQuery,
  useReaffirmEmployeeDocumentMutation,
} from '@/gql/generated';
import useNotifications from '@bambeehr/use-notifications';
import { Ref, computed, ref, watch } from '@nuxtjs/composition-api';

const { addSuccess } = useNotifications();
const { isPolicyReaffirmationsEnabled } = usePolicyReaffirmationFlags();

let isInitialized;
let companyId;

export const Interval = {
  SEMI_ANNUALLY: 'semi-annual',
  ANNUALLY: 'annual',
} as const;

// 1000 = 1 second
// 60 = 1 minute
// 60 = 1 hour
// 24 = 1 day
// 30 = 1 month
// 6 = 6 months
const sixMonthsInMilliseconds = 6 * 30 * 24 * 60 * 60 * 1000;

// 1000 = 1 second
// 60 = 1 minute
// 60 = 1 hour
// 24 = 1 day
// 365 = 1 year
const yearInMilliseconds = 365 * 24 * 60 * 60 * 1000;

export const hrPracticeCadenceKey = 'hr-practice-policies-cadence';

const translateIntervalToMilliseconds = (interval: string | null) =>
  interval === Interval.ANNUALLY ? yearInMilliseconds : sixMonthsInMilliseconds;

const reaffirmInterval: Ref<number> = ref(0);

const getInterval = () => {
  const { getSurvey } = useCompanyService();

  const res = ref();
  getSurvey(
    {
      companyId,
      type: 'hr-practice-policy',
    },
    {
      data: res,
    }
  );

  watch(
    res,
    (val) => {
      if (val && val.answers) {
        reaffirmInterval.value = val.answers[hrPracticeCadenceKey]
          ? translateIntervalToMilliseconds(val.answers[hrPracticeCadenceKey])
          : // if there is no cadence set
            // don't show the task card
            0;
      }
    },
    {
      immediate: true,
    }
  );
};

export const shouldPolicyDocumentReaffirm = (
  latestReaffirmationDate: Date | null,
  latestSignedDate: Date | null,
  interval: number | null,
  today: Date = new Date()
): boolean => {
  if (!interval) {
    return false;
  }

  const todayInMilliseconds = today.getTime();

  if (latestReaffirmationDate) {
    const lastReaffirmationDate = new Date(latestReaffirmationDate).getTime();

    return todayInMilliseconds >= lastReaffirmationDate + interval;
  }

  if (latestSignedDate) {
    const lastSignedDate = new Date(latestSignedDate).getTime();

    return todayInMilliseconds >= lastSignedDate + interval;
  }

  return false;
};

// for employees only
// returns an array of policies that should be reaffirmed
// powers the EmployeePolicyReaffirmationTaskCard
const getEmployeePoliciesToReaffirm = () => {
  const documents = ref<CoreDocument[]>([]);

  const activeDocuments = computed<CoreDocument[]>(() =>
    documents.value.filter((doc) => !doc.policy?.archivedAt)
  );
  const { onResult } = useApolloQuery(
    // @ts-ignore
    useGetMyCoreUserQuery,
    undefined,
    undefined,
    undefined,
    {
      enabled: !!isPolicyReaffirmationsEnabled.value,
    }
  );

  onResult((result) => {
    const { getMyCoreUser } = result;

    documents.value =
      (getMyCoreUser?.documents as unknown as CoreDocument[]) || [];
  });

  const interval = computed(() => reaffirmInterval.value);

  // if there is no interval set, we won't show the task card
  if (!interval.value) {
    return [];
  }

  const toReaffirm =
    activeDocuments.value?.filter((item) => {
      const lastReaffirmation: Date | null =
        item.reaffirmations &&
        item.reaffirmations[item.reaffirmations.length - 1];

      const lastSignedOn: Date | null = item.signature?.signedAt;

      const shouldReaffirm = shouldPolicyDocumentReaffirm(
        lastReaffirmation,
        lastSignedOn,
        // Change this to test the task card
        interval.value
      );

      return shouldReaffirm;
    }) || [];

  return toReaffirm;
};

export interface ReaffirmedDocument {
  id: string;
  name: string;
  reaffirmations?: Date[];
}

const reaffirmEmployeeDocumentLoading = ref(false);
const reaffirmEmployeeDocument = (documentId: string) => {
  reaffirmEmployeeDocumentLoading.value = true;
  const { mutate: reaffirm, onDone } = useApolloMutation(
    useReaffirmEmployeeDocumentMutation
  );

  reaffirm({ documentId });

  return new Promise<ReaffirmedDocument>((resolve) => {
    onDone(({ reaffirmDocument }) => {
      resolve({
        id: reaffirmDocument.id,
        name: reaffirmDocument.name,
        reaffirmations: reaffirmDocument.reaffirmations as Date[],
      });
      reaffirmEmployeeDocumentLoading.value = false;
      addSuccess('Reaffirmation completed');
    });
  });
};

const policiesToReaffirm = computed(() => getEmployeePoliciesToReaffirm());

const usePolicyReaffirmations = () => {
  const { companyId: cID } = useCurrentCompany();

  watch(
    cID,
    (val) => {
      if (val) {
        companyId = val;
        // get interval only when the company id is available
        getInterval();
      }
    },
    {
      immediate: true,
    }
  );

  if (!isInitialized) {
    isInitialized = true;
    getEmployeePoliciesToReaffirm();
  }

  return {
    reaffirmInterval,
    policiesToReaffirm,
    getEmployeePoliciesToReaffirm,
    reaffirmEmployeeDocument,
    shouldPolicyDocumentReaffirm,
    reaffirmEmployeeDocumentLoading,
  };
};

export default usePolicyReaffirmations;
