import currency from '@bambeehr/currency';
import formatISO from 'date-fns/formatISO';
import parse from 'date-fns/parse';
import { mapHrRequestSlugToSubType } from '../constants/RequestTypesData';
import { HrRequestOption } from '@/gql/generated';

const InternalPrefix = 'bambee_internal_';

/**
 * Format date for the purpose of EOE (end of employment)
 * @param date string date
 * @param standardEndOfDayTime We set this to 5pm EOD
 */
export function formatDate(date, standardEndOfDayTime = 12 + 5): string {
  // should be in this format: YYYY-MM-DDTHH:MM:SS-{TZ}:00
  let parsedDate;
  if (date instanceof Date) {
    parsedDate = parse(date as unknown as string, 'yyyy-MM-dd', new Date());
  } else {
    parsedDate = new Date(date);
  }
  parsedDate.setHours(standardEndOfDayTime, 0, 0, 0);

  return formatISO(parsedDate, { format: 'extended' });
}

const DateFields = [
  // Terminations
  'When_EE_terminated_1099',
  'When_EE_terminated_w2',
  'When_EE_let_go_w2',
  'End_date_contract',
  'When_expect_rehire',
  // Resignations
  'When_EE_resign_W2',
  'When_EE_reported_no_show',
  'When_worker_end_contract',
  'When_worker_reported_no_show',
];

export function injectScript(
  src: string,
  cb: (provide: any) => void,
  provide: string
) {
  const s = document.createElement('script');
  s.setAttribute('src', src);

  if (cb) {
    s.onload = () => {
      // @ts-ignore
      cb(window[provide]);
    };
  }

  document.body.appendChild(s);
}

export const parseSurveyResponseNoBoolean = (val) => {
  switch (typeof val) {
    case 'object':
      return (Array.isArray(val) ? val : Object.values(val)).join(', ');
    default:
      return val;
  }
};

export const parseSurveyResponse = (val) => {
  switch (typeof val) {
    case 'boolean':
      return val ? 'Yes' : 'No';
    case 'object':
      return (Array.isArray(val) ? val : Object.values(val)).join(', ');
    default:
      return val;
  }
};

export function surveyResponsesToPlainText(questions: any[], answers: object) {
  return questions
    .reduce(
      (arr, { name, title }) =>
        name in answers && !name.includes(InternalPrefix)
          ? [
              ...arr,
              `${title || name} - ${parseSurveyResponse(answers[name])}${
                // Add description for "other"
                answers[`${name}-Comment`]
                  ? `: ${answers[`${name}-Comment`]}`
                  : ''
              }`,
            ]
          : arr,
      ['\n']
    )
    .join('\n----------------\n');
}

export function surveyResponsesToFlatJson(answers: object) {
  const flat = {};

  Object.keys(answers).forEach((key) => {
    if (DateFields.includes(key)) {
      answers[key] = formatDate(answers[key]);
    }
    if (answers[key]) {
      flat[key] = parseSurveyResponse(answers[key]);
    }
  });

  return JSON.stringify(flat);
}
/**
 * HrRequests prices are retrieved in cents. This function will parse them into the dollar amount */
export function parseHrRequestPricing(cents: number) {
  const dollars = cents !== 0 ? cents / 100 : cents;

  return currency(dollars).format();
}

const hiddenPayrollBannerAnswerKey = 'has_payroll';
export function getRequestData(
  surveyData: any,
  hrRequestOption: HrRequestOption | undefined,
  surveyQuestions: any
) {
  if (!hrRequestOption) {
    return null;
  }

  /**
   * Filter out Hidden Payroll Question from Question/Answer Key/Description
   */
  const filteredData = surveyData;
  if (hiddenPayrollBannerAnswerKey in surveyData) {
    delete filteredData.has_payroll;
  }
  const filteredQuestions = surveyQuestions.filter(
    (quest) => quest.name !== hiddenPayrollBannerAnswerKey
  );

  const { title, type, subType, slug } = hrRequestOption;
  const slugSubType = mapHrRequestSlugToSubType(slug, filteredData);
  const description = surveyResponsesToPlainText(
    filteredQuestions,
    filteredData
  );

  const answers = surveyResponsesToFlatJson(filteredData);

  return {
    type,
    subType: slugSubType || subType,
    subject: title,
    description,
    answers,
    chatNow: false,
  };
}

export default null;
