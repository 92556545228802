import { render, staticRenderFns } from "./VideoTourStrip.vue?vue&type=template&id=52e5a975&"
import script from "./VideoTourStrip.vue?vue&type=script&lang=ts&"
export * from "./VideoTourStrip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports