import apolloDecorator from '@/lib/apollo-decorator';
import publicClient from '@/gql/apolloClient';
import {
  EnrolledUser,
  EnrollUsersInput,
  EnrollUsersResponse,
  GetCompanyInput,
  GetCompanyResponse,
  GetUserCoursesInput,
  ListTicketsInput,
  PurchaseBundleSeatsInput,
  RemoveBundleSeatsInput,
} from '@/modules/Training/types/TrainingApi';
import {
  ENROLL_USERS,
  PURCHASE_SEATS_FOR_BUNDLE,
  REMOVE_SEATS_FROM_BUNDLE,
  GET_USER_DOCEBO_TOKEN,
} from './mutations';
import {
  GET_COMPANY,
  GET_COMPANY_DETAILED,
  GET_COMPANY_TICKETS,
  GET_TOPICS,
  USER_COURSES,
} from './queries';

import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import { PurchasedBundle, PurchasedTopic } from '@/gql/generated';

const trainingApolloClient = apolloDecorator(publicClient);

interface ApolloRes {
  data: any;
  errors: String[];
  pending: Boolean;
}

const enum AmericanPlanTopics {
  HIRING_LEGALLY = 'Hiring Legally',
  INTERVIEWING_SKILLS_FOR_SUPERVISORS = 'Recruiting: Interviewing Skills for Supervisors',
}

export const hasPurchasedTraining = async (
  companyId: String
): Promise<Boolean> => {
  const data: GetCompanyInput = {
    data: {
      companyId,
    },
  };

  const res = await trainingApolloClient.query(GET_COMPANY, data, 'company');

  const { isTierZero } = useCurrentPlan();

  // Hiring Legally(T0 Topic)
  // Interviewing Skills for Supervisors(T0 Topic)
  const excludedTraining = [
    String(AmericanPlanTopics.INTERVIEWING_SKILLS_FOR_SUPERVISORS),
    String(AmericanPlanTopics.HIRING_LEGALLY),
  ];

  // T0s will have some topics by default
  // these are "gifted" topics therefore this condition will be true
  // this allows T0 users to see the training portal
  let hasPurchasedContent: Boolean =
    res.data.purchasedProducts.topics?.length ||
    res.data.purchasedProducts.bundles?.length;

  // if the user is not a T0, we need to filter out the topics that are gifted
  // because they carry over from the previous plan
  // this will make it so when a T1+ goes to the training portal they will be redirected to the `/get-training` page(paywall)
  if (!isTierZero.value) {
    const filteredTopics: number =
      res.data.purchasedProducts.topics?.filter(
        (topic: PurchasedTopic) => !excludedTraining.includes(topic.name)
      )?.length || 0;

    const filteredBundles =
      res.data.purchasedProducts.bundles?.filter(
        (bundle: PurchasedBundle) => !excludedTraining.includes(bundle.name)
      )?.length || 0;

    hasPurchasedContent = !!filteredTopics || !!filteredBundles;
  }

  return hasPurchasedContent;
};

interface GetCompanyApolloRes extends ApolloRes {
  data: GetCompanyResponse;
}

export const purchaseSeatsForBundle = async (
  numSeats: Number,
  companyId: String
) => {
  const data: PurchaseBundleSeatsInput = {
    data: {
      numSeats,
      companyId,
    },
  };

  return trainingApolloClient.mutate(
    PURCHASE_SEATS_FOR_BUNDLE,
    data,
    'purchaseSeatsForBundle'
  );
};

export const removeSeatsFromBundle = async (
  numSeats: Number,
  companyId: String
) => {
  const data: RemoveBundleSeatsInput = {
    data: {
      numSeats,
      companyId,
    },
  };

  return trainingApolloClient.mutate(
    REMOVE_SEATS_FROM_BUNDLE,
    data,
    'removeSeatsFromBundle'
  );
};

export const getCompany = (
  companyId: String,
  detailed: boolean = false
): { subscribe: () => GetCompanyApolloRes } => {
  const data: GetCompanyInput = {
    data: {
      companyId,
    },
  };
  const query = detailed ? GET_COMPANY_DETAILED : GET_COMPANY;

  return trainingApolloClient.watchQuery(query, data, 'company');
};

interface EnrollUsersApolloRes extends ApolloRes {
  data: EnrollUsersResponse;
}

/**
 * Enrolls multiple users at once
 */
export const enrollUsers = async (
  companyId: String,
  users: EnrolledUser[]
): Promise<EnrollUsersApolloRes> => {
  const data: EnrollUsersInput = {
    data: {
      companyId,
      users,
    },
  };

  return trainingApolloClient.mutate(ENROLL_USERS, data, 'enrollUsers');
};

interface GetUserCoursesApolloRes extends ApolloRes {
  data: GetCompanyResponse;
}
/**
 * Get assigned user courses
 */
export const getUserCourses = (
  userId: String
): { subscribe: () => GetUserCoursesApolloRes } => {
  const data: GetUserCoursesInput = {
    data: {
      userId,
    },
  };

  return trainingApolloClient.watchQuery(USER_COURSES, data, 'userCourses');
};

export const getCompanyTickets = (companyId: String) => {
  const data: ListTicketsInput = {
    data: { companyId },
  };

  return trainingApolloClient.query(GET_COMPANY_TICKETS, data, 'getTickets');
};

export const getTopics = () => {
  return trainingApolloClient.watchQuery(GET_TOPICS, null, 'getTopics');
};

/**
 * Get the current user's Docebo access token
 */
export const getUserDoceboToken = () => {
  return trainingApolloClient.mutate(GET_USER_DOCEBO_TOKEN, null, 'userToken');
};
