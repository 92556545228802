import { computed, ComputedRef, reactive } from '@nuxtjs/composition-api';
/**
 * Imports the post-Search Result from usePolicyStaffList
 */
import { searchedEmployees } from './usePolicyStaffList';
/**
 * Import Models/Definitions
 */
import {
  PolicyFilterTypes,
  PolicyGroupedStaffMembers,
  PolicyStaffFilterState,
  PolicyStaffMember,
  PolicyStaffStates,
  SortingKey,
  SortingTypes
} from '@/modules/PolicyCenter/models';
// Get StaffRoles Enum from StaffManagement
import { StaffRoles } from '@/modules/StaffManagement/models/index';
/**
 * Import Libraries
 */
import usePolicyReaffirmationFlags from '@/hooks/usePolicyReaffirmationFlags/usePolicyReaffirmationFlags';
import orderBy from 'lodash/orderBy';

interface PolicyStaffFilter {
  label: string;
  name: string;
  options: Array<string>;
  placeholder: string;
  class?: string;
  type?: string;
}

// Policy Staff States: set default state
const defaultState = Object.freeze({
  [PolicyFilterTypes.TYPE]: null,
  [PolicyFilterTypes.STATUS]: null,
  [SortingKey]: SortingTypes.STATUS,
});
const policyFilterState = reactive<PolicyStaffFilterState>({
  ...defaultState,
});

const { isPolicyReaffirmationsEnabled } = usePolicyReaffirmationFlags();
// Staff State Options for filter
const stateOptions = [
  PolicyStaffStates.SIGNED,
  PolicyStaffStates.NOT_SIGNED,
  ...(isPolicyReaffirmationsEnabled.value
    ? [PolicyStaffStates.REAFFIRMATION_NEEDED]
    : []),
];

// Staff Type/Sort Options for filter
const typeOptions = [
  StaffRoles.EMPLOYEE,
  StaffRoles.MANAGER,
  StaffRoles.CONTRACTOR,
];
const sortOptions = [
  SortingTypes.STATUS,
  SortingTypes.ALPHA_ASC,
  SortingTypes.ALPHA_DESC,
];

/**
 * Filter Object for Policy Staff Filter
 * This is used to render the Filters UI
 */
const policyStaffFilters: PolicyStaffFilter[] = [
  {
    label: PolicyFilterTypes.TYPE,
    name: PolicyFilterTypes.TYPE,
    options: typeOptions,
    placeholder: 'Any',
  },
  {
    label: PolicyFilterTypes.STATUS,
    name: PolicyFilterTypes.STATUS,
    options: stateOptions,
    placeholder: 'All',
  },
];

/**
 * Filtered and Grouped Policy Staff Members
 */
export const filteredPolicyStaffMembers: ComputedRef<PolicyStaffMember[]> =
  computed(() => filterAndSortStaff(searchedEmployees.value));

export const groupedFilteredStaff: ComputedRef<PolicyGroupedStaffMembers> =
  computed(() => groupUsers(filteredPolicyStaffMembers.value));

const groupedStaff = computed(() => groupUsers(searchedEmployees.value));

// check SortBy state to toggle grouped[] or simple[]
const isGroupedList = computed<boolean>(
  () => policyFilterState[SortingKey] === SortingTypes.STATUS
);
/**
 * Functions/Methods
 */
/**
 * Almost a direct copy of filterAndSortStaff(StaffMember[]) from
 * useStaffFilter.ts from StaffManagement Module
 */
function filterAndSortStaff(staffList: PolicyStaffMember[]) {
  let staffArr: PolicyStaffMember[] = staffList;

  // Copy of Staff Filter by Type/Role
  if (policyFilterState[PolicyFilterTypes.TYPE]) {
    switch (policyFilterState[PolicyFilterTypes.TYPE]) {
      case StaffRoles.MANAGER:
        staffArr = staffList.filter((m: PolicyStaffMember) =>
          m.roles.includes(StaffRoles.MANAGER)
        );
        break;
      case StaffRoles.ADMIN:
        staffArr = staffList.filter((m: PolicyStaffMember) =>
          m.roles.includes(StaffRoles.ADMIN)
        );
        break;
      case StaffRoles.APPROVER:
        staffArr = staffList.filter((m: PolicyStaffMember) =>
          m.roles.includes(StaffRoles.APPROVER)
        );
        break;
      case StaffRoles.CONTRACTOR:
        staffArr = staffList.filter((m: PolicyStaffMember) => m.contractor);
        break;
      case StaffRoles.EMPLOYEE:
      default:
        staffArr = staffList.filter(
          (m: PolicyStaffMember) =>
            !m.roles.includes(StaffRoles.MANAGER) && !m.contractor
        );
        break;
    }
  }

  // Filter by Staff Status
  if (policyFilterState[PolicyFilterTypes.STATUS]) {
    staffArr = staffArr.filter(
      (m: PolicyStaffMember) =>
        m.policyState ===
        policyFilterState[PolicyFilterTypes.STATUS]?.toString()
    );
  }

  // Sort By
  if (policyFilterState[SortingKey]) {
    switch (policyFilterState[SortingKey]) {
      case SortingTypes.ALPHA_ASC:
        staffArr = orderBy(
          staffArr,
          (m: PolicyStaffMember) => m.profile.staffName.toLowerCase(),
          ['asc']
        );
        break;
      case SortingTypes.ALPHA_DESC:
      default:
        staffArr = orderBy(
          staffArr,
          (m: PolicyStaffMember) => m.profile.staffName.toLowerCase(),
          ['desc']
        );
    }
  }

  return staffArr;
}

// Group Staff By Policy States
function groupUsers(staffList: PolicyStaffMember[]): PolicyGroupedStaffMembers {
  const mapped = {};
  Object.keys(PolicyStaffStates).forEach((key) => {
    mapped[PolicyStaffStates[key]] = [];
  });
  staffList.forEach((user: PolicyStaffMember) => {
    mapped[user.policyState].push(user);
  });
  const grouped = {};
  delete mapped[PolicyStaffStates.NOT_IMPLEMENTED];
  Object.keys(mapped).forEach((key) => {
    if (mapped[key].length) {
      grouped[key] = mapped[key];
    }
  });

  return grouped as PolicyGroupedStaffMembers;
}

// Sets Policy Filter State
const setState = (newState): void => {
  Object.assign(policyFilterState, { ...newState });
};

// Set Policy Sort State
const setSorting = (value: string): void => {
  policyFilterState[SortingKey] = value;
};

export const resetFilters = (): void => {
  Object.assign(policyFilterState, defaultState);
};

const usePolicyStaffFilter = () => {
  return {
    currentFilters: policyFilterState,
    filteredPolicyStaffMembers,
    groupedFilteredStaff,
    groupedStaff,
    isGroupedList,
    policyFilterState,
    policyStaffFilters,
    resetFilters,
    setSorting,
    setState,
    sortOptions,
    stateOptions,
    typeOptions,
  };
};

export default usePolicyStaffFilter;
