import FeatureFlags from '@/constants/FeatureFlags';
import { getLaunchDarklyFlag } from '@/utils/launchDarklyFlags';

const hasPayrollBulkuploadFeatureFlagEnabled = async () => {
  return !!(await getLaunchDarklyFlag(
    FeatureFlags.ENABLE_PAYROLL_IMPORT_HOURS
  ));
};

export default hasPayrollBulkuploadFeatureFlagEnabled;
