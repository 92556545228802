















import { defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, TypeDisplay, BaseDivider } from '@bambeehr/pollen';

export default defineComponent({
  components: {
    TypeBody,
    TypeDisplay,
    BaseDivider,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
  },
});
