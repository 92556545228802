





























import {
  defineComponent,
  computed,
  useRouter,
  ref,
} from '@nuxtjs/composition-api';
import useCurrentUser from '@/hooks/useCurrentUser';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import {
  CourseTopic,
  BundleResponse,
  CoreRequest,
  RequestStatus,
} from '@/gql/generated';

import useTrainingCatalog from '@/modules/Training/hooks/useTrainingCatalog';
import useEmployerTrainingRequests from '@/modules/Training/hooks/useEmployerTrainingRequests';
import useEmployeeTrainingRequests from '@/modules/Training/hooks/useEmployeeTrainingRequests';
import useNotifications from '@bambeehr/use-notifications';
import TrainingEnrollmentModal from '@/modules/Training/components/TrainingEnrollmentModal';

import { formatDate } from '@/utils/date';
import {
  BaseButton,
  BaseTag,
  DataTable,
  UserAvatar,
  AnnularThrobber,
} from '@bambeehr/pollen';

import bam from '@/lib/bam';

const requestTableDefaults = {
  selectable: false,
  denseView: false,
  hideFiltersWhenEmpty: true,
  maxItemsPerPage: 50,
};

interface TrainingRequestHelpers {
  contentId: string;
  requestId: string;
}

type TrainingRequest =
  | (CourseTopic & CoreRequest & TrainingRequestHelpers)
  | (BundleResponse & CoreRequest & TrainingRequestHelpers);

let triggerPurchaseModal;

const isContentBundle = (content: CourseTopic | BundleResponse) =>
  !!(content as BundleResponse)?.topics?.length;

const getStatusTagInfo = (requestStatus: RequestStatus) => {
  switch (requestStatus) {
    case RequestStatus.Approved:
      return {
        label: 'Approved',
        color: 'evergreen',
      };
    case RequestStatus.Rejected:
      return {
        label: 'Denied',
        color: 'rose',
      };
    case RequestStatus.Pending:
    default:
      return {
        label: 'Requested',
        color: 'gray',
      };
  }
};

const getEERequestAction = (
  request: TrainingRequest,
  cancelHandler: Function
) => {
  const router = useRouter();

  switch (request.status) {
    case RequestStatus.Pending:
      return {
        component: BaseButton,
        attrs: {
          label: 'Cancel',
          size: 'small',
          buttonType: 'tertiary',
          level: '2',
        },
        handlers: {
          click() {
            cancelHandler([request.requestId], request.name);
          },
        },
      };

    // We need to provide the client with the course ID before we can directly link them
    // Coming after the MVP
    // case RequestStatus.Approved:
    //   return {
    //     component: BaseButton,
    //     attrs: {
    //       label: 'View',
    //       size: 'small',
    //       level: '2',
    //     },
    //     handlers: {
    //       click() {
    //         console.log('view', request);
    //         router.push(`/my-training/${request.contentId}`);
    //       },
    //     },
    //   };

    default:
      return '';
  }
};

const mapRequestsToTrainingItem = (
  trainingItems: (CourseTopic | BundleResponse)[],
  requests: CoreRequest[]
): TrainingRequest[] =>
  requests.map((request) => {
    const item = trainingItems.find((i) => i.id === request.resourceId);

    return {
      ...request,
      ...item,
      requestId: request.id,
      contentId: item?.id,
    } as TrainingRequest;
  });

const mapRequestToRow = (
  request: TrainingRequest,
  isOwnerAdmin: boolean,
  approveHandler: (
    idsToApprove: string[],
    isBundle,
    content: CourseTopic | BundleResponse
  ) => void,
  denyHandler: (idsToReject: string[], contentName: string) => void,
  cancelHandler: (idsToCancel: string[], contentName: string) => void
) => {
  const { id, name = '', requestedAt, status, requestId, user } = request;

  let contextualInfo = {};

  const requestedDate = formatDate(requestedAt[0], '');

  const requesterName = `${user?.profile?.firstName} ${user?.profile?.lastName}`;
  const requesterTitle = user?.employment?.title;
  const requestorAvatar = user?.profile?.avatarUrl;

  if (isOwnerAdmin) {
    contextualInfo = {
      requester: {
        component: UserAvatar,
        label: requesterName,
        attrs: {
          name: requesterName,
          image: requestorAvatar,
        },
      },
      requesterRole: requesterTitle,
      requestedDate,
      action: {
        component: BaseButton,
        attrs: {
          label: 'Approve',
          size: 'small',
        },
        handlers: {
          click() {
            const isBundle = isContentBundle(request);
            approveHandler([requestId], isBundle, request);
          },
        },
      },
      denyAction: {
        component: BaseButton,
        attrs: {
          label: 'Decline',
          buttonType: 'tertiary',
          size: 'small',
          level: '3',
        },
        handlers: {
          click() {
            denyHandler([requestId], name);
          },
        },
      },
    };
  } else {
    const tagAttrs = {
      ...getStatusTagInfo(status),
      size: 'small',
      light: true,
    };

    contextualInfo = {
      requestedDate,
      status: {
        component: BaseTag,
        attrs: tagAttrs,
      },
      action: getEERequestAction(request, cancelHandler),
    };
  }

  const isBundle = !!(request as BundleResponse)?.topics?.length;

  return {
    id,
    meta: {
      isBundle,
    },
    name,
    ...contextualInfo,
  };
};

const getRequestHeader = (isOwnerAdmin: boolean) => ({
  name: {
    label: 'Name',
    sortable: true,
    priority: true,
    allowOverflow: true,
    bold: true,
  },
  ...(isOwnerAdmin
    ? {
        requester: {
          label: 'Staff Member',
          sortable: true,
        },
        requesterRole: {
          label: 'Role',
          sortable: true,
        },
        requestedDate: {
          label: 'Requested Date',
          sortable: true,
        },
        action: {
          label: '',
          width: 90,
        },
        denyAction: {
          label: '',
          width: 90,
        },
      }
    : {
        requestedDate: {
          label: 'Requested Date',
          sortable: true,
        },
        status: {
          label: 'Status',
          sortable: true,
        },
        action: {
          label: '',
          width: 90,
        },
      }),
});

export interface ModalInfo {
  bundleId: string;
  topicId: string;
  title: string;
  selectedIds: string[];
}

const requestActions = [
  {
    id: 'PRIMARY',
    attrs: {
      label: 'Approve',
      buttonType: 'primary',
    },
    handler: (idModel) => console.log('Primary', idModel),
  },
  {
    id: 'SECONDARY',
    attrs: {
      label: 'Decline',
      buttonType: 'tertiary',
      level: '3',
    },
    handler: (idModel) => console.log('Secondary', idModel),
  },
];

export default defineComponent({
  name: 'TrainingRequests',
  // Your component options here
  components: {
    DataTable,
    TrainingEnrollmentModal,
    AnnularThrobber,
  },
  setup() {
    const { isOwnerAdmin, currentUserId } = useCurrentUser();
    const router = useRouter();
    const { companyId } = useCurrentCompany();
    const { availableBundles, availableCourses, isReady } =
      useTrainingCatalog();
    const {
      requests,
      rejectRequest,
      approveRequest,
      refreshCompanyRequests,
      isLoading: isLoadingERRequests,
    } = useEmployerTrainingRequests();

    const {
      myRequests,
      makeRequest,
      cancelRequest,
      refreshUserRequests,
      isLoading: isLoadingEERequests,
    } = useEmployeeTrainingRequests();

    // Request table data
    const requestTableHeader = computed(() =>
      getRequestHeader(isOwnerAdmin.value)
    );

    const showPurchaseModal = ref(false);
    const purchaseModalInfo = ref({} as ModalInfo);

    triggerPurchaseModal = (isBundle, content, selectedIds: string[] = []) => {
      showPurchaseModal.value = true;
      purchaseModalInfo.value = {
        bundleId: isBundle ? content.id : '',
        topicId: isBundle ? '' : content.id,
        title: content.name,
        selectedIds,
      };

      bam.track('course_catalog_opened_purchase_modal', {
        content: purchaseModalInfo.value,
      });
    };

    const { addError, addSuccess, addInfo } = useNotifications();
    const onRequestEnrollmentComplete = ref();

    const approve = (idsToApprove: string[], isBundle, content) => {
      if (idsToApprove.length === 1) {
        triggerPurchaseModal(isBundle, content, [content.userId]);
      } else {
        // TODO trigger new bulk purchase modal
        // Will not be released in MVP
      }

      onRequestEnrollmentComplete.value = () => {
        // Only supporting approving single requests in MVP
        const { onDone } = approveRequest(idsToApprove[0]);

        onDone(() => {
          addSuccess(`Request approved for ${content.name}`);
          refreshCompanyRequests();
        });
      };
    };

    // ER Rejections
    const reject = (idsToReject: string[], contentName: string) => {
      // Only supporting rejecting single requests in MVP
      const { onDone } = rejectRequest(idsToReject[0]);

      onDone(() => {
        addInfo(`Request rejected for ${contentName}`);
        refreshCompanyRequests();
      });
    };

    const cancel = (idsToCancel: string[], contentName: string) => {
      // Only supporting rejecting single requests in MVP
      const { onDone } = cancelRequest({ requestId: idsToCancel[0] });

      onDone(() => {
        addInfo(`Request cancelled for ${contentName}`);
        refreshUserRequests();
      });
    };

    const requestTableRows = computed(() => {
      const userRequests = isOwnerAdmin.value
        ? requests.value.filter((r) => r.status === RequestStatus.Pending)
        : myRequests.value.filter((r) =>
            [
              RequestStatus.Pending,
              RequestStatus.Approved,
              RequestStatus.Rejected,
            ].includes(r.status)
          );

      const mapped = mapRequestsToTrainingItem(
        availableCourses.value,
        userRequests
      );

      console.log(
        'mapped',
        mapped.map((i) =>
          mapRequestToRow(i, isOwnerAdmin.value, approve, reject, cancel)
        )
      );

      return mapped.map((i) =>
        mapRequestToRow(i, isOwnerAdmin.value, approve, reject, cancel)
      );
    });

    return {
      requestTableDefaults,
      requestTableRows,
      requestTableHeader,
      requestActions,
      purchaseModalInfo,
      onRequestEnrollmentComplete,
      showPurchaseModal,
      isReady,
    };
  },
});
