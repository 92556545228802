





















































































































import useAddStaffFormState from '../hooks/useAddStaffFormState';
import useAddStaffForm from '../hooks/useAddStaffForm';
import AddStaffTypeInfoModal from './AddStaffTypeInfoModal';
import { defineComponent, watch, ref } from '@nuxtjs/composition-api';

import usStateSelect from '@/shared/components/form-components/us-state-select.vue';
import AddStaffFormGroup from './AddStaffFormGroup';
import AddStaffFormRoles from './AddStaffFormRoles';
import AddStaffInviteChecks from './AddStaffInviteChecks';

import {
  TextInput,
  DateInput,
  RadioGroup,
  BaseIcon,
  SelectInput,
  BaseDivider,
  TypeBody,
} from '@bambeehr/pollen';

export default defineComponent({
  components: {
    AddStaffTypeInfoModal,
    AddStaffFormGroup,
    DateInput,
    RadioGroup,
    SelectInput,
    TextInput,
    usStateSelect,
    BaseIcon,
    BaseDivider,
    TypeBody,
    AddStaffFormRoles,
    AddStaffInviteChecks,
  },
  setup() {
    const showStaffTypeModal = ref(false);
    const postponeOnboardEmail = ref(false);

    const {
      isEditing,
      showBeginOnboarding,
      isPayrollUser,
      hasPayroll,
      hasTimeAndAttendance,
      filteredPayTypes,
      hasMultipleWorkplaces,
      workplaceOptions,
      inviteAsAdmin,
      workerRoles,
    } = useAddStaffForm();

    const {
      v$,
      formState,
      isInvalid,
      staffOptions,
      staffType,
      isBusinessContractor,
      contractorOptions,
      employeeOptions,
    } = useAddStaffFormState();

    const toggleStaffTypeModal = () => {
      showStaffTypeModal.value = !showStaffTypeModal.value;
    };

    if (!formState.beginOnboarding) {
      postponeOnboardEmail.value = true;
    }

    watch(postponeOnboardEmail, (val) => {
      formState.beginOnboarding = !val;
    });

    return {
      contractorOptions,
      employeeOptions,
      filteredPayTypes,
      formState,
      hasMultipleWorkplaces,
      hasPayroll,
      inviteAsAdmin,
      isBusinessContractor,
      isEditing,
      isInvalid,
      isPayrollUser,
      postponeOnboardEmail,
      showBeginOnboarding,
      showStaffTypeModal,
      staffOptions,
      staffType,
      toggleStaffTypeModal,
      v$,
      workplaceOptions,
      workerRoles,
      hasTimeAndAttendance,
    };
  },
});
