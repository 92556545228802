import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentUser from '@/hooks/useCurrentUser';

import bam from '@/lib/bam';

import {
  useAssignUserToPoliciesMutation,
  useGetCompanyPoliciesQuery,
} from '@/gql/generated';
import useAddStaffWizard from '@/modules/StaffManagement/components/AddStaffWizard/useAddStaffWizard';
import useNotifications from '@bambeehr/use-notifications';
import { computed, reactive, ref } from '@nuxtjs/composition-api';
import partition from 'lodash/partition';

// Remove when we can import from GQL schema
export enum PolicyIcon {
  DISCLAIMER = 'disclaimer',
  TARGET = 'icon1',
  BUILDING = 'icon2',
  BEACH_CHAIR = 'icon3',
  HAND = 'icon4',
  HEART_RATE = 'icon5',
  CANNABIS = 'icon6',
  MEDKIT = 'icon7',
  TIE = 'icon8',
  CAMERA = 'icon9',
  PAW = 'icon10',
  DOG = 'icon11',
  ROAD = 'icon12',
  MUSTACHE = 'icon13',
  BICYCLE = 'icon14',
  WINE_GLASS = 'icon15',
  WHEELCHAIR = 'icon16',
  POLICY_CLIPBOARD = 'policy-clipboard',
  POLICY_SEND = 'policy-send',
}

export enum StaticDocs {
  CPD = 'CPD',
}

export enum RecommendedMatchers {
  W4 = 'W4',
  CONTRACT = '1099',
}

interface PolicyDocument {
  id: string;
  name: string;
  icon?: string;
  helper?: string;
  value?: boolean;
  disabled?: boolean;
}

interface DocumentGroup {
  label: string;
  documents: PolicyDocument[];
}

export interface DocumentGroupConfig {
  required: DocumentGroup;
  recommended: DocumentGroup;
  all: DocumentGroup;
}

// Functional utils
export const getFilteredDocumentGroups = (
  docGroups: DocumentGroupConfig,
  showOnlySelected: boolean,
  searchQuery: string
): DocumentGroupConfig =>
  Object.fromEntries(
    Object.entries(docGroups).map(([key, val]) => [
      key,
      {
        ...val,
        documents: val.documents.filter(
          (document) =>
            (showOnlySelected ? document.value : true) &&
            document.name.toLowerCase().includes(searchQuery.toLowerCase())
        ),
      },
    ])
  ) as DocumentGroupConfig;

export const getHasMatchingDocuments = (
  docGroups: DocumentGroupConfig
): boolean =>
  !!docGroups.required.documents.length ||
  !!docGroups.recommended.documents.length ||
  !!docGroups.all.documents.length;

export const getSelectedDocumentsCount = (
  docGroups: DocumentGroupConfig,
  isLoading = false
): number =>
  isLoading
    ? 0
    : Object.values(docGroups).reduce(
        (acc, val) =>
          acc + val.documents.filter((document) => document.value).length,
        0
      );

export const getSelectedDocumentsLabel = (signedDocCount: number): string =>
  `${signedDocCount} Polic${
    signedDocCount > 1 || !signedDocCount ? 'ies' : 'y'
  } Selected`;

export const getSelectedDocuments = (
  docGroups: DocumentGroupConfig
): string[] =>
  Object.values(docGroups).reduce(
    (acc: string[], val: DocumentGroup) => [
      ...acc,
      ...val.documents
        .filter((document) => document.value && document.id !== StaticDocs.CPD)
        .map((document) => document.id as string),
    ],
    []
  );

export const getPolicyIcon = (icon: string = '') => {
  if (icon.includes(PolicyIcon.BUILDING)) {
    return 'building';
  }

  if (icon.includes(PolicyIcon.POLICY_CLIPBOARD)) {
    return 'clipboardCheckSolid';
  }

  if (
    icon.includes(PolicyIcon.HEART_RATE) ||
    icon.includes(PolicyIcon.MEDKIT)
  ) {
    return 'heartSolid';
  }

  return 'fileLines';
};

export const decorateDocument = (
  document: Partial<PolicyDocument>
): PolicyDocument =>
  ({
    disabled: false,
    value: false,
    ...document,
    icon: getPolicyIcon(document.icon),
  } as PolicyDocument);

export const handlePolicyCompanyResult = (
  { getCoreCompany: res },
  isContractor: boolean,
  documentGroups
) => {
  const [recommendedDocs, generalDocs] = partition(
    res.policies,
    ({ name }) =>
      name.toUpperCase().includes(RecommendedMatchers.W4) ||
      name.includes(RecommendedMatchers.CONTRACT)
  );

  // Reset document groups
  documentGroups.all.documents = [];
  documentGroups.recommended.documents = [];

  documentGroups.all.documents.push(
    ...generalDocs?.map((d) => decorateDocument(d))
  );
  documentGroups.recommended.documents.push(
    ...recommendedDocs
      // Filtering out W4 or 1099 if we know the worker type
      ?.filter(
        ({ name }) =>
          !name
            .toUpperCase()
            .includes(
              isContractor
                ? RecommendedMatchers.W4
                : RecommendedMatchers.CONTRACT
            )
      )
      .map((d) => decorateDocument(d))
  );
};

let initialized = false;

// Reactive data
const { currentUser } = useCurrentUser();
const searchQuery = ref('');
const showOnlySelected = ref(false);
const currentUserId = ref('');
const sessionId = ref('');
const allCompanyPolicies = ref();
const isLoading = ref(false);
const isSaving = ref(false);

let emit;

const documentGroups = reactive({
  required: {
    label: 'Required',
    documents: [
      // CPDs aren't actually selectable, just shown for the user to see that we send them
      {
        name: 'Core Protective Documents',
        icon: 'umbrellaSolid',
        helper:
          'Core Protective Documents are foundational policies required to keep your business compliant. These include Anti-Harassment, Equal Employment, Workplace Safety, and Workplace Violence.',
        disabled: true,
        value: true,
        id: StaticDocs.CPD,
      },
    ],
  },
  recommended: {
    label: 'Recommended',
    documents: [] as PolicyDocument[],
  },
  all: {
    label: 'All Policies',
    documents: [] as PolicyDocument[],
  },
});

// Filtering the documents based on the search query + the selected policies state
const filteredDocumentGroups = computed(() =>
  getFilteredDocumentGroups(
    documentGroups,
    showOnlySelected.value,
    searchQuery.value
  )
);

const hasMatchingDocuments = computed(() =>
  getHasMatchingDocuments(filteredDocumentGroups.value as DocumentGroupConfig)
);

const selectedDocumentsCount = computed(() =>
  getSelectedDocumentsCount(documentGroups, isLoading.value)
);

const selectedDocumentsLabel = computed(() =>
  getSelectedDocumentsLabel(selectedDocumentsCount.value)
);

const selectedDocuments = computed(() => getSelectedDocuments(documentGroups));

const toggleViewLabel = computed(() =>
  showOnlySelected.value ? 'Show All' : 'View'
);

const toggleSelectedView = () => {
  showOnlySelected.value = !showOnlySelected.value;
};

const resetPoliciesState = () => {
  [
    ...documentGroups.all.documents,
    ...documentGroups.recommended.documents,
  ].forEach((doc) => {
    doc.value = false;
  });

  initialized = false;
};

const handleSave = () => {
  const { addError, addSuccess } = useNotifications();
  const { currentWorker } = useAddStaffWizard();

  const {
    mutate: assignPolicies,
    onDone: onPoliciesAssigned,
    onError: onPoliciesError,
  } = useApolloMutation(useAssignUserToPoliciesMutation, { pending: isSaving });

  // Tracking Add Staff Wizard policy selection
  bam.track(`add_staff_assigned_policies`, {
    activeUserId: currentUser.value?._id,
    assignedToUserId: currentUserId.value,
    companyId: currentUser.value?._company?._id,
    companyName: currentUser.value?._company?.name,
    sessionId: sessionId.value,
    // Policy specific info
    selectedPolicyCount: selectedDocumentsCount.value - 1, // -1 for CPD
    totalPolicyCount: allCompanyPolicies.value?.length,
    selectedPoliciesIDList: selectedDocuments.value,
    recommendedPoliciesIdList: documentGroups.recommended.documents.map(
      (doc) => doc.id
    ),
    selectedRecommendedPoliciesIdList: getSelectedDocuments({
      recommended: documentGroups.recommended,
    } as DocumentGroupConfig),
  });

  if (selectedDocuments.value.length) {
    assignPolicies({
      data: {
        companyId: currentUser.value?._company?._id as string,
        employeeId: currentUserId.value,
        policyIds: selectedDocuments.value,
      },
    });

    onPoliciesAssigned(() => {
      emit('next');

      addSuccess(
        `You have sent ${
          currentWorker.value?.firstName || ''
        } their company documents and policies`
      );
      resetPoliciesState();
    });

    onPoliciesError((error) => {
      if (error.networkError) {
        addError(error.networkError.message);

        return;
      }

      error.responseErrors.forEach((err) => addError(err.error));
    });

    return;
  }

  emit('next');
};

const setup = (isContractor: boolean) => {
  const { onResult } = useApolloQuery(
    useGetCompanyPoliciesQuery,
    { id: currentUser.value?._company?._id as string },
    {
      data: allCompanyPolicies,
      pending: isLoading,
    },
    { placeholderPick: ({ getCoreCompany: res }) => res.policies }
  );

  onResult((res) => {
    // remove policies that are archived
    const activePolicies = res.getCoreCompany.policies.filter(
      (policy) => !policy.archivedAt
    );

    const newRes = {
      ...res,
      getCoreCompany: {
        ...res.getCoreCompany,
        policies: activePolicies,
      },
    };

    return handlePolicyCompanyResult(newRes, isContractor, documentGroups);
  });

  showOnlySelected.value = false;
};

const useAddWorkerPolicies = (
  emitHandler?,
  userId?: string,
  uuId?: string,
  isContractor: boolean = false
) => {
  if (emitHandler) {
    emit = emitHandler;
  }

  if (userId) {
    currentUserId.value = userId;
  }

  if (!initialized) {
    if (uuId) {
      sessionId.value = uuId;
    }

    initialized = true;
    setup(isContractor);
  }

  return {
    allCompanyPolicies,
    filteredDocumentGroups,
    hasMatchingDocuments,
    isLoading,
    isSaving,
    searchQuery,
    selectedDocuments,
    selectedDocumentsLabel,
    showOnlySelected,
    toggleViewLabel,
    handleSave,
    toggleSelectedView,
  };
};

export default useAddWorkerPolicies;
