import { Ref } from '@nuxtjs/composition-api';
import initializeReactiveData from './initializeReactiveData';

export interface AsyncFactoryResponse {
  error?: object;
  pending?: Ref<boolean>;
  data?: any;
}

/**
 * A helper function that creates/stores vue reactive objects, executes a PROMISE type service method,
 * and returns an object of vue reactive objects as well as an update function to mutate.
 * Note that the reactive object for "pending" starts as true and will resolve as false when update is called.
 * @param {function} serviceFn The payroll service method to call
 * @param  {...any} args Any arguments to forward to the serviceFn. Last arg is designated for any placeholder references
 * @returns {Object} Standardized reactive objects for data, error, and pending. Also includes an update() to mutate the reactive objects as needed.
 */
export const asyncFactory = <Type>(
  serviceFn,
  ...args
): AsyncFactoryResponse => {
  const factoryConfig = args.pop();
  const { data, error, pending, update } =
    initializeReactiveData(factoryConfig);

  (async () => {
    const returnVal = await serviceFn(...args);
    update(returnVal);
  })();

  return {
    error,
    pending,
    data: data as Type,
  };
};

/**
 * A helper function that creates/stores vue reactive objects, executes a OBSERVABLE type service method,
 * and returns an object of vue reactive objects as well as an update function to mutate.
 * Note that the reactive object for "pending" starts as true and will resolve as false when update is called.
 * @param {function} serviceFn The payroll service method to call
 * @param  {...any} args Any arguments to forward to the serviceFn. Last arg is designated for any placeholder references
 * @returns {Object} Standardized reactive objects for data, error, and pending. Also includes an update() to mutate the reactive objects as needed.
 */
export const observableFactory = <Type>(serviceFn, ...args) => {
  const factoryConfig = args.pop();
  const { data, error, pending, update } =
    initializeReactiveData(factoryConfig);

  serviceFn(...args).subscribe(update);

  return {
    pending,
    error,
    data: data as Type,
  };
};
