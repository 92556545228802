const getBase64File = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      // reader.result is a data url, so we split it to get the base64 string
      resolve((reader.result as string)?.split(',')?.[1] as string);
    reader.onerror = (error) => reject(error);
  });

export default getBase64File;
