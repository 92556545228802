




















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import useWorkplaces from './useWorkplaces';

import { BaseBanner } from '@bambeehr/pollen';
import WorkplaceList from '@/components/Workplaces/WorkplaceList.vue';
import WorkplaceModal from '@/components/Workplaces/WorkplaceModal.vue';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal.vue';

export default defineComponent({
  name: 'Workplaces',
  components: {
    BaseBanner,
    WorkplaceList,
    WorkplaceModal,
    LoadingModal,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    modalPortalDestination: {
      type: String as PropType<string>,
      default: 'layout',
    },
    canDeleteWorkplaces: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const {
      allowDelete,
      deleteWorkplace,
      getWorkplaces,
      isSaving,
      isSavingEdit,
      portalDestination,
      saveNewWorkplace,
      saveWorkplaceEdit,
      savingHeaderLabel,
      showRemoteBanner,
      sortedWorkplaces,
      toggleRemoteBanner,
      workplaces,
    } = useWorkplaces();

    getWorkplaces(props.companyId);
    allowDelete.value = props.canDeleteWorkplaces;

    return {
      deleteWorkplace,
      isSaving,
      isSavingEdit,
      portalDestination,
      saveNewWorkplace,
      saveWorkplaceEdit,
      savingHeaderLabel,
      showRemoteBanner,
      sortedWorkplaces,
      toggleRemoteBanner,
      workplaces,
    };
  },
});
