














import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { TypeBody } from '@bambeehr/pollen';

export enum Size {
  XS = 'micro',
  SM = 'small',
  MD = 'medium',
  LG = 'large',
}

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GRAY = 'gray',
  ORANGE = 'orange',
  RED = 'red',
}

const getSizeInfo = (size: Size) => {
  switch (size) {
    case Size.XS:
      return {
        classes: 'px-1.5 py-0.5',
        type: 'text-x-tiny',
      };

    case Size.SM:
      return {
        classes: 'px-2 py-1',
        type: 'text-x-tiny',
      };

    case Size.LG:
      return {
        classes: 'px-3 py-2',
        type: 'text-small-tight',
      };

    default:
    case Size.MD:
      return {
        classes: 'px-2 py-1',
        type: 'text-x-small',
      };
  }
};

const getVariantClasses = (variant: Variant, light: boolean) => {
  switch (variant) {
    case Variant.PRIMARY:
    default:
      return light
        ? 'bg-primary-lighter text-primary-default'
        : 'bg-primary-default text-white';

    case Variant.SECONDARY:
      return light
        ? 'bg-secondary-lighter text-secondary-default'
        : 'bg-secondary-default text-white';

    case Variant.GRAY:
      return light ? 'bg-base-300 text-base-900' : 'bg-base-400 text-base-900';

    case Variant.ORANGE:
      return light
        ? 'bg-warning-lighter text-warning-strong'
        : 'bg-warning-default text-base-900';

    case Variant.RED:
      return light
        ? 'bg-error-lighter text-error-strong'
        : 'bg-error-default text-white';
  }
};

export default defineComponent({
  name: 'SquareTag',
  components: {
    TypeBody,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<Size>,
      default: Size.MD,
    },
    preClose: {
      type: Boolean,
      default: false,
    },
    postClose: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String as PropType<Variant>,
      default: Variant.PRIMARY,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const variantClasses = computed(() =>
      getVariantClasses(props.variant, props.light)
    );

    const sizeClasses = computed(() => getSizeInfo(props.size));

    return {
      variantClasses,
      sizeClasses,
    };
  },
});
