















import { defineComponent } from '@nuxtjs/composition-api';
import { ModalDialog } from '@bambeehr/pollen';

export default defineComponent({
  name: 'GlowModal',
  components: {
    ModalDialog,
  },
});
