export const MilestoneGroups = Object.freeze({
  BCI: 'bci-setup',
});

export const MilestoneNames = Object.freeze({
  COMPANY_INFORMATION: 'company-information',
  ADD_STAFF: 'add-staff',
  INSURANCE_INFORMATION: 'insurance-information',
  HR_AUDIT: 'hr-audit',
  BASIC_AUDIT: 'basic-audit',
});

export const MilestoneDescriptions = Object.freeze({
  [MilestoneNames.COMPANY_INFORMATION]:
    'This will give us more information about your company needs and will allow us to personalize your policies.',
  [MilestoneNames.ADD_STAFF]:
    'Get your workers into Bambee so you can start your record keeping.',
  [MilestoneNames.INSURANCE_INFORMATION]:
    'How are you currently handling your insurance needs within your company so we can help identify any potential issues and solutions',
  [MilestoneNames.HR_AUDIT]:
    "You're ready to go, schedule time with your HR Manager to get an in depth conversation of your Business Health and solutions.",
  [MilestoneNames.BASIC_AUDIT]:
    'Fill out this questionnaire so we can quickly identify your needs and give you a personalized action plan.',
});

export default {
  MilestoneNames,
  MilestoneDescriptions,
  MilestoneGroups,
};
