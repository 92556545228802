import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentUser from '@/hooks/useCurrentUser';
import useEmployees from '@/hooks/useEmployees';

import {
  Language as TrainingLanguage,
  useEnrollUsersMutation,
  useGetCompanyVerboseQuery,
  useGetTrainingPricesQuery,
  usePurchaseSeatsMutation,
  UserInput,
} from '@/gql/generated';
import {
  EnrolledUser,
  Language,
  State,
  UserRole,
} from '@/modules/Training/types/TrainingApi';
import { equal as equalArrays } from '@/utils/arrays';
import currency from '@bambeehr/currency';
import useNotifications from '@bambeehr/use-notifications';
import {
  computed,
  ComputedRef,
  reactive,
  readonly,
  Ref,
  ref,
  watch,
} from '@nuxtjs/composition-api';

import { cloneDeep } from 'lodash';

interface TrainingEnrollmentFormUser {
  id: String;
  firstName: String;
  lastName: String;
  language: Language;
  avatarUrl: String;
  enrolled: Boolean;
  role: String;
  employeeType: UserRole;
  selected: Boolean;
  state: State;
}

enum TrainingEnrollmentFormTab {
  EMPLOYEE = 'w2',
  CONTRACTOR = '1099',
}

const tabOptions = [
  {
    label: 'Employees (w2)',
    value: TrainingEnrollmentFormTab.EMPLOYEE,
  },
  {
    label: 'Contractors (1099)',
    value: TrainingEnrollmentFormTab.CONTRACTOR,
  },
];

let initialized: Boolean = false;

const { addError } = useNotifications();

const { companyId } = useCurrentCompany();
const bundleId = ref('');
const topicId = ref('');
const tabModel = ref(TrainingEnrollmentFormTab.EMPLOYEE);

// Training Service Data
const fetchingEnrolled = ref(false);
const companyInTraining: Ref<any> = ref({});
const syncWithTrainingDB = () => {
  fetchingEnrolled.value = true;
  useApolloQuery(
    useGetCompanyVerboseQuery,
    { data: { companyId: companyId.value } },
    {
      data: companyInTraining,
      pending: fetchingEnrolled,
    },
    { placeholderPick: ({ company: res }) => res }
  );
};

const prices = ref();

const getPricing = () => {
  // @ts-ignore, ignoring param error
  const { onResult: onTrainingPriceResult } = useApolloQuery(
    useGetTrainingPricesQuery
  );

  onTrainingPriceResult(({ getTrainingPrices: res }) => {
    prices.value = res;
  });
};

const bundlePrice = computed(() =>
  prices.value?.bundle ? prices.value?.bundle / 100 : 0
);

const coursePrice = computed(() =>
  prices.value?.topic ? prices.value?.topic / 100 : 0
);

const currentPurchasedBundle = computed(() =>
  companyInTraining.value?.purchasedProducts?.bundles?.find(
    (b) => b.bundleId === bundleId.value
  )
);

const currentPurchasedTopic = computed(() =>
  companyInTraining.value?.purchasedProducts?.topics?.find(
    (t) => t.topicId === topicId.value
  )
);

const isNewPurchase = computed(
  () => !currentPurchasedBundle.value && !currentPurchasedTopic.value
);

const currentIdList = computed(
  () =>
    (
      currentPurchasedTopic.value || currentPurchasedBundle.value?.topics?.[0]
    )?.enrollments?.reduce((acc, cur) => [...acc, cur.userId], []) || []
);

const trainingUsers: ComputedRef<EnrolledUser[]> = computed(() => {
  const users: EnrolledUser[] = companyInTraining.value?.users || [];

  return users
    .filter((u) => currentIdList.value.includes(u.userId))
    .map((u) => {
      return {
        ...u,
        userId: u.userMngId,
      };
    });
});

const totalSeats = computed<number>(
  () =>
    currentPurchasedTopic.value?.totalSeats ||
    currentPurchasedBundle.value?.totalSeats ||
    0
);

const unfilledSeats = computed<number>(
  () =>
    currentPurchasedTopic.value?.unfilledSeats ||
    currentPurchasedBundle.value?.unfilledSeats ||
    0
);

// App Data
const w2Employees: Ref<any[] | null> = ref(null);
const contractWorkers: Ref<any[] | null> = ref(null);
const original: Ref<TrainingEnrollmentFormUser[] | null> = ref(null);
const originalContractors: Ref<TrainingEnrollmentFormUser[] | null> = ref(null);

// Form Data
const allUsers: Ref<TrainingEnrollmentFormUser[] | null> = ref([]);
const allContractorUsers: Ref<TrainingEnrollmentFormUser[] | null> = ref([]);
const filter: Ref<String> = ref('');
const saving = ref(false);
const loadingText = ref('');

const loading: ComputedRef<Boolean> = computed(
  () => !(trainingUsers.value && w2Employees.value)
);

const setOriginal = (
  w2Values: TrainingEnrollmentFormUser[],
  contractorValues: TrainingEnrollmentFormUser[]
): void => {
  original.value = cloneDeep(w2Values);
  originalContractors.value = cloneDeep(contractorValues);
};

const deferredSelectionList = ref([] as String[]);
const mapToTrainingFormat = (ee) => {
  return {
    id: ee.id,
    firstName: ee.profile?.first_name,
    lastName: ee.profile?.last_name,
    avatarUrl: ee.profile?.avatar_url,
    role: ee.profile?.role,
    employeeType: ee.permissions?.manager
      ? UserRole.MANAGER
      : UserRole.EMPLOYEE,
    state: ee.profile?.state_work_in || ee.profile?.state,
    selected: deferredSelectionList.value.includes(ee.id),
    enrolled: false,
    language: Language.ENGLISH,
  };
};

const isEmployeeTab = computed(
  () => tabModel.value === TrainingEnrollmentFormTab.EMPLOYEE
);

const toForm = (): void => {
  if (!loading.value) {
    // Map w2 employees to interface
    const employeeBase: any[] = w2Employees.value || [];
    const contractorBase: any[] = contractWorkers.value || [];

    const w2: TrainingEnrollmentFormUser[] =
      employeeBase.map(mapToTrainingFormat);
    const contractors: TrainingEnrollmentFormUser[] =
      contractorBase.map(mapToTrainingFormat);

    // Extend with any already enrolled employee information
    trainingUsers.value.forEach((user: EnrolledUser) => {
      const found =
        w2.find((w2ee) => w2ee.id === user.userId) ||
        contractors.find((cont) => cont.id === user.userId);

      if (found) {
        found.enrolled = true;

        if (user.language) {
          found.language = user.language;
        }

        if (user.state) {
          found.state = user.state;
        }
      }
    });

    allUsers.value = isEmployeeTab.value ? w2 : contractors;
    setOriginal(w2, contractors);
  }
};

watch([w2Employees, trainingUsers, contractWorkers, tabModel], () => {
  toForm();
});

const updateFilter = (filterTerm: String): void => {
  filter.value = filterTerm;
};

const enrolled: ComputedRef<TrainingEnrollmentFormUser[] | undefined> =
  computed(() => allUsers.value?.filter((ee) => ee.enrolled));

const notEnrolled: ComputedRef<TrainingEnrollmentFormUser[] | undefined> =
  computed(() => allUsers.value?.filter((ee) => !ee.enrolled));

const selected: ComputedRef<TrainingEnrollmentFormUser[] | undefined> =
  computed(() => allUsers.value?.filter((ee) => ee.selected));

const normalizeArrToCheckEquality = (
  arr: TrainingEnrollmentFormUser[]
): any[] => {
  const toCheck: string[] = ['selected'];

  return arr
    .map((x: TrainingEnrollmentFormUser) => {
      return toCheck.map((prop: string) => x[prop]);
    })
    .flat();
};

const dirty = computed(() => {
  const originalGroup = normalizeArrToCheckEquality(
    (isEmployeeTab.value ? original.value : originalContractors.value) || []
  );
  const allUsersGroup = normalizeArrToCheckEquality(allUsers.value || []);

  const isClean: Boolean =
    !original.value || equalArrays(originalGroup, allUsersGroup);

  return !isClean;
});

const overAllocated = computed<number>(() => {
  const count = (selected.value?.length || 0) - unfilledSeats.value;

  return count > 0 ? count : 0;
});

// Replace with SS calculation
const overAllocatedCost = computed(() =>
  currency(
    overAllocated.value *
      (bundleId.value ? bundlePrice.value : coursePrice.value)
  ).format()
);

const state = reactive({
  allUsers,
  enrolled,
  notEnrolled,
  selected,
  original,
  dirty,
  filter,
  saving,
  loading,
  totalSeats,
  unfilledSeats,
  overAllocated,
  overAllocatedCost,
  loadingText,
});

const findUser = (userId: String): TrainingEnrollmentFormUser => {
  const found: TrainingEnrollmentFormUser = allUsers.value?.find(
    (ee) => ee.id === userId
  ) as TrainingEnrollmentFormUser;

  return found;
};

const setSelectedState = (userId: String, isSelected: Boolean): void => {
  const found: TrainingEnrollmentFormUser = findUser(userId);

  // If the user ID isn't found, we'll add it to a deferred selection list
  // This helps to prevent race conditions when the user list is still loading
  if (found) {
    found.selected = isSelected;
  } else {
    deferredSelectionList.value.push(userId);
  }
};

const select = (userIds: String[] = []): void => {
  userIds.forEach((userId) => setSelectedState(userId, true));
};

const deselect = (userIds: String[] = []): void => {
  userIds.forEach((userId) => setSelectedState(userId, false));
};

const setLanguage = (userId, langPref: Language): void => {
  const found: TrainingEnrollmentFormUser = findUser(userId);

  if (found) {
    found.language = langPref;
  }
};

const updateLanguage = (
  userIds: String[] = [],
  langPref: Language = Language.ENGLISH
) => {
  userIds.forEach((userId) => setLanguage(userId, langPref));
};

const purchaseSeats = (
  numSeats: number,
  bId: string,
  tId: string,
  callback?: Function
) => {
  const {
    onDone,
    mutate: enrollUsers,
    onError: onSaveError,
  } = useApolloMutation(usePurchaseSeatsMutation, { pending: saving });

  onSaveError(() => {
    state.saving = false;
    // Unknown error should be surfaced to user
    addError(
      'There was an error purchasing seats, please try again or contact support.'
    );
  });

  onDone(() => {
    if (callback) {
      callback();
    }
  });

  loadingText.value = 'Purchasing';

  enrollUsers({
    data: {
      companyId: companyId.value,
      numSeats,
      topicId: tId,
      bundleId: bId,
    },
  });
};

const enroll = (input: {
  toEnroll: UserInput[];
  bId: string;
  tId: string;
  onSave?: Function;
  onError?: Function;
}) => {
  const { toEnroll, bId, tId, onSave, onError } = input;
  const {
    onDone,
    mutate: enrollUsers,
    onError: onSaveError,
  } = useApolloMutation(useEnrollUsersMutation, { pending: saving });

  onSaveError((error) => {
    state.saving = false;

    // Targeting a specific error message, if we have it.
    // Otherwise
    const message =
      // @ts-ignore
      error.responseErrors?.[0]?.raw?.extensions?.exception?.message?.message;

    if (message) {
      const { addError } = useNotifications();

      addError(message);

      return;
    }

    if (onError) {
      onError();
    }
  });

  onDone((data) => {
    if (data) {
      updateFilter('');
      syncWithTrainingDB();
      deferredSelectionList.value = [];
      state.saving = false;

      if (onSave) {
        onSave();
      }
    }
  });

  loadingText.value = 'Enrolling';
  enrollUsers({
    data: {
      companyId: companyId.value,
      users: toEnroll,
      topicId: tId || undefined,
      bundleId: bId || undefined,
    },
  });
};

const save = async (onSave?: Function, onError?: Function): Promise<void> => {
  state.saving = true;

  // On successful enrollment of employees:
  // * update the new "original" state with the currently saved state
  // * make sure no employee is selected
  // * reset the filter
  // * fire any callback supplied

  // @ts-ignore
  const toEnroll: UserInput[] =
    selected.value?.map((s) => ({
      // @ts-ignore
      language: s.language as TrainingLanguage,
      role: s.employeeType,
      state: s.state,
      userId: s.id,
      userMngId: s.id,
    })) || [];

  if (overAllocated.value) {
    // If we're over allocated, we need to add additional seats here
    purchaseSeats(overAllocated.value, bundleId.value, topicId.value, () => {
      enroll({
        toEnroll,
        bId: bundleId.value,
        tId: topicId.value,
        onSave,
        onError,
      });
    });
  } else {
    enroll({
      toEnroll,
      bId: bundleId.value,
      tId: topicId.value,
      onSave,
      onError,
    });
  }
};

const cancel = () => {
  if (state.dirty) {
    allUsers.value = cloneDeep(original.value);
    updateFilter('');
  }
};

const useTrainingEnrollmentForm = (bId: string = '', tId: string = '') => {
  const shouldSetState = bId || tId;

  if (!initialized) {
    // Get W2 employees
    const { w2: rawW2, contractors: rawContractors } = useEmployees();
    const { currentUser } = useCurrentUser();
    watch(
      [rawW2, currentUser, rawContractors],
      () => {
        w2Employees.value = [
          ...rawW2.value,
          // In order to enroll a user, we need to have their address/state.
          // If this isn't present, we'll exclude them from the list
          ...(currentUser.value?.profile?.state
            ? [
                {
                  ...currentUser.value,
                  id: currentUser.value?._id,
                },
              ]
            : []),
        ];

        contractWorkers.value = rawContractors.value;
      },
      { immediate: true }
    );

    getPricing();

    // Cache initialized value
    initialized = true;
  }

  if (shouldSetState) {
    syncWithTrainingDB();
    bundleId.value = bId;
    topicId.value = tId;

    tabModel.value = TrainingEnrollmentFormTab.EMPLOYEE;
  }

  // Return the hook's API
  return {
    state: readonly(state),
    select,
    deselect,
    updateLanguage,
    save,
    cancel,
    updateFilter,
    isFetching: fetchingEnrolled,
    tabOptions,
    tabModel,
    bundlePrice,
    isNewPurchase,
  };
};

export default useTrainingEnrollmentForm;
