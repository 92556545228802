<template>
  <div class="video-embed h-0 relative" :style="{ paddingBottom }">
    <iframe
      class="absolute inset-0 w-full h-full"
      :src="src"
      v-bind="iframeAttrs"
    />
  </div>
</template>

<script>
export const getPadding = (aspectRatio) => {
  const [w, h] = aspectRatio.split(':').map((val) => parseInt(val, 10));

  return `${(h / w) * 100}%`;
};

export const validateAspectRatio = (value) =>
  value.split(':').every((val) => !Number.isNaN(parseInt(val, 10)));

export default {
  props: {
    aspectRatio: {
      type: String,
      default: '16:9',
      validator: validateAspectRatio,
    },
    src: {
      type: String,
      required: true,
    },
    iframeAttrs: {
      type: Object,
      default: () => ({
        frameborder: 0,
        webkitallowfullscreen: true,
        mozallowfullscreen: true,
        allowfullscreen: true,
      }),
    },
  },
  computed: {
    paddingBottom() {
      return getPadding(this.aspectRatio);
    },
  },
};
</script>
