import { render, staticRenderFns } from "./AddStaffPayrollStatus.vue?vue&type=template&id=50e4f8cb&"
import script from "./AddStaffPayrollStatus.vue?vue&type=script&lang=ts&"
export * from "./AddStaffPayrollStatus.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports