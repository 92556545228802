<template>
  <TermsModal
    v-if="showModal"
    header="Bambee, Inc. Terms and Conditions of Use"
    agree-label="I have read and agree to the terms and conditions of use"
    :show-close="showClose"
    @submit="agreeToCoreTerms"
  >
    <tosBody class="text-left" />
  </TermsModal>
</template>
<script>
import { ref, useRouter, useStore } from '@nuxtjs/composition-api';
import TermsModal from '@/components/TermsModal/TermsModal.vue';
import useNotifications from '@bambeehr/use-notifications';
import tosBody from '@/components/tos/tos-body';

export default {
  name: 'CoreTOS',
  components: {
    TermsModal,
    tosBody,
  },
  props: {
    closeOnSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    function closeModal(agreedToTOS) {
      if (props.showClose || agreedToTOS) {
        emit('hide-modal', agreedToTOS);
      }
    }

    function agreeToCoreTerms() {
      const { add } = useNotifications();

      store.dispatch('acceptTermsOfService');

      if (props.closeOnSubmit) {
        closeModal(true);
      }

      add({
        id: 'coreTOS',
        message: `You've agreed to the Terms and Conditions`,
      });
    }

    return {
      closeModal,
      agreeToCoreTerms,
    };
  },
};
</script>
