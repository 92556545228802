import {
  getCompanyContributionList,
  getEmployeeContributionList,
} from '@/modules/payroll/utils/benefits';
import {
  getCompanyTaxList,
  getEmployeeTaxList,
  getPostTaxDeductionsList,
  totalTaxesByType,
} from '@/modules/payroll/utils/tax';
import { AmountType } from './EarningsTable.vue';

export interface EarningSummaryItem {
  description: string;
  amount: number;
  type: string;
}

const addEarning = (payroll, total, payData, filterFn?) => {
  const earningCollection: EarningSummaryItem[] = [];

  if (total.amount) {
    earningCollection.push({
      description: total.description,
      amount: total.amount,
      type: total.type,
    });
  }

  if (filterFn) {
    earningCollection.push(...totalTaxesByType(filterFn(payroll.items)));
  }

  if (earningCollection.length) {
    payData.push(earningCollection);
  }
};

export const getCompanyEarningsSummary = (payroll) => {
  if (!payroll) {
    return false;
  }
  const payData: EarningSummaryItem[] = [];

  const {
    companyTaxes,
    employeeReimbursements,
    contractorReimbursements,
    companyBenefits,
    contractorGross,
  } = payroll.totals;

  addEarning(
    payroll,
    {
      description: 'Reimbursements',
      amount: employeeReimbursements + contractorReimbursements,
      type: AmountType.POSITIVE,
    },
    payData
  );

  addEarning(
    payroll,
    {
      description: 'Company Taxes',
      amount: companyTaxes,
      type: AmountType.POSITIVE,
    },
    payData,
    getCompanyTaxList
  );

  addEarning(
    payroll,
    {
      description: 'Company Contributions',
      amount: companyBenefits,
      type: AmountType.POSITIVE,
    },
    payData,
    getCompanyContributionList
  );

  addEarning(
    payroll,
    {
      description: 'Contractor Payment Amount',
      amount: contractorGross,
      type: AmountType.POSITIVE,
    },
    payData
  );

  return payData;
};

export const getEmployeeEarningsSummary = (payroll) => {
  if (!payroll) {
    return false;
  }
  const payData: EarningSummaryItem[] = [];

  const {
    employeeTaxes,
    postTaxDeductions,
    employeeReimbursements,
    employeeBenefits,
  } = payroll.totals;

  addEarning(
    payroll,
    {
      description: 'Reimbursements',
      amount: employeeReimbursements,
      type: AmountType.POSITIVE,
    },
    payData
  );

  addEarning(
    payroll,
    {
      description: 'Employee Taxes',
      amount: employeeTaxes,
      type: AmountType.NEGATIVE,
    },
    payData,
    getEmployeeTaxList
  );

  addEarning(
    payroll,
    {
      description: 'Employee Contributions',
      amount: employeeBenefits,
      type: AmountType.NEGATIVE,
    },
    payData,
    getEmployeeContributionList
  );

  addEarning(
    payroll,
    {
      description: 'Employee Post-Tax Deductions',
      amount: postTaxDeductions,
      type: AmountType.NEGATIVE,
    },
    payData,
    getPostTaxDeductionsList
  );

  return payData;
};

export const getContractorEarningsSummary = (payroll) => {
  if (!payroll) {
    return false;
  }
  const payData: EarningSummaryItem[] = [];

  const { contractorGross, contractorReimbursements } = payroll.totals;

  addEarning(
    payroll,
    {
      description: 'Payment Amount',
      amount: contractorGross,
      type: AmountType.POSITIVE,
    },
    payData
  );

  addEarning(
    payroll,
    {
      description: 'Reimbursements',
      amount: contractorReimbursements,
      type: AmountType.POSITIVE,
    },
    payData
  );

  return payData;
};
