import { CompanyStatuses } from '@/modules/payroll/constants/company';
import { getCompanyStatus } from '@/gql/services/payroll.service';
import {
  getHasRolePermissions,
  getHasValidCompanyStatus,
  setHasRolePermissions,
  setHasValidCompanyStatus,
} from './employerPayrollGuard.state';

export const validateRolePermissions = (store, allowManagers = false) => {
  const hasRolePermissions = getHasRolePermissions();

  if (hasRolePermissions) {
    return hasRolePermissions;
  }

  const isAdmin = store.getters['auth/isCompanyAdmin'];
  const isOwner = store.getters['auth/isOwner'];
  const isManager = store.getters['auth/isManager'];
  const hasPermissions = isAdmin || isOwner || (allowManagers && isManager);

  if (!hasPermissions) {
    setHasRolePermissions(false);
  } else {
    setHasRolePermissions(true);
  }

  return getHasRolePermissions();
};

const validateCompanyStatus = async (store) => {
  const hasValidCompanyStatus = getHasValidCompanyStatus();

  if (getHasValidCompanyStatus()) {
    return hasValidCompanyStatus;
  }

  // Get Initialization Status
  const { companyId } = store.getters;
  const { data: company } = await getCompanyStatus(companyId, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  // Provisioned is not a valid state for an actual payroll subscriber
  // We create a provisioned customer for the purpose of enrolling the company in T&A as they use a shared DB (payroll)
  const isCompanyInitializedInPayroll =
    !!company && company.status !== CompanyStatuses.PROVISIONED;
  const hasBackedOut = company?.status === CompanyStatuses.BACKED_OUT;

  if (!isCompanyInitializedInPayroll || hasBackedOut) {
    setHasValidCompanyStatus(false);
  } else {
    setHasValidCompanyStatus(true);
  }

  return getHasValidCompanyStatus();
};

export const canAccess = async (store) => {
  const hasValidRoles = validateRolePermissions(store);
  const hasValidCompanyStatus = await validateCompanyStatus(store);

  return hasValidRoles && hasValidCompanyStatus;
};

export const Routes = Object.freeze({
  ROOT: '/',
  RESERVE_PAYROLL: '/get-payroll',
});

export default async ({ store, redirect }) => {
  const hasRolePermissions = validateRolePermissions(store);

  if (!hasRolePermissions) {
    redirect(Routes.ROOT);

    return;
  }

  const hasValidCompanyStatus = await validateCompanyStatus(store);

  // Given the user has permissions, if they have the permission to reserve a slot and they company has not been initialized in payroll, redirect to the slot reservation flow
  if (!hasValidCompanyStatus) {
    redirect(Routes.RESERVE_PAYROLL);
  }

  // Given the user has permissions, but has not been initialized in payroll and does not have permission to reserve a slot, redirect home
  if (!hasValidCompanyStatus) {
    redirect(Routes.ROOT);
  }
};
