






































import { defineComponent } from '@nuxtjs/composition-api';
import { BaseButton, ModalDialog, TypeBody } from '@bambeehr/pollen';
import useAddStaffFormRole from './useAddStaffFormRole';

export default defineComponent({
  components: {
    BaseButton,
    ModalDialog,
    TypeBody,
  },
  setup() {
    const {
      showSalaryRolesModal,
      dismissSalaryRolesModal,
      acceptSalaryRolesModal,
    } = useAddStaffFormRole();

    return {
      showSalaryRolesModal,
      dismissSalaryRolesModal,
      acceptSalaryRolesModal,
    };
  },
});
