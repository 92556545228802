export const BillingPeriod = Object.freeze({
  MONTH: 'month',
  QUARTER: 'quarter',
  HALF_YEAR: 'halfYear',
  NINE_MONTHS: 'nineMonths',
  YEAR: 'year',
  TWO_YEARS: 'twoYears',
  THREE_YEARS: 'threeYears',
});

export const ChargeCycleRate = {
  [BillingPeriod.MONTH]: 'Per Month',
  [BillingPeriod.QUARTER]: 'Every Three Months',
  [BillingPeriod.HALF_YEAR]: 'Every Six Months',
  [BillingPeriod.NINE_MONTHS]: 'Every Nine Months',
  [BillingPeriod.YEAR]: 'Per Year',
  [BillingPeriod.TWO_YEARS]: 'Every Two Years',
  [BillingPeriod.THREE_YEARS]: 'Every Three Years',
};

export default {
  BillingPeriod,
};
