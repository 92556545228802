import { Company } from '@@/types/company';

// eslint-disable-next-line no-shadow
export enum Milestones {
  ABOUT_COMPANY = 'AboutCompany',
  ACKNOWLEDGE_HRM = 'AcknowledgeHrm',
  ADD_STAFF = 'AddStaff',
  WELCOME_CALL = 'WelcomeCall',
  HR_AUDIT = 'HrAudit',
  HR_REAUDIT = 'HrReaudit',
  HR_PRACTICE = 'HrPractice',
  EMPLOYEE_VOICES = 'EmployeeVoices',
  HR_PRACTICE_MIGRATED = 'HrPracticeMigrated',
  REVIEW_BUSINESS_STRUCTURE = 'ReviewBusinessStructure',
  REVIEW_STAFF_TYPE = 'ReviewStaffType',
  REVIEW_EMPLOYEE_CLASSIFICATION = 'ReviewEmployeeClassification',
  REVIEW_POLICIES = 'ReviewPolicies',
  SCHEDULE_ONBOARDING_WEBINAR = 'ScheduleOnboardingWebinar',
  SETUP_JOB_DESCRIPTIONS = 'SetupJobDescriptions',
  SETUP_OFFER_LETTER = 'SetupOfferLetter',
  TAKE_TRAINING = 'TakeTraining',
}

export enum TierZeroMilestones {
  REVIEW_BUSINESS_STRUCTURE = Milestones.REVIEW_BUSINESS_STRUCTURE,
  REVIEW_STAFF_TYPE = Milestones.REVIEW_STAFF_TYPE,
  REVIEW_EMPLOYEE_CLASSIFICATION = Milestones.REVIEW_EMPLOYEE_CLASSIFICATION,
  REVIEW_POLICIES = Milestones.REVIEW_POLICIES,
  SETUP_JOB_DESCRIPTIONS = Milestones.SETUP_JOB_DESCRIPTIONS,
  TAKE_TRAINING = Milestones.TAKE_TRAINING,
  SETUP_OFFER_LETTER = Milestones.SETUP_OFFER_LETTER,
  // Schedule a call with a onboarding specialist
  WELCOME_CALL = Milestones.WELCOME_CALL,
}

// eslint-disable-next-line no-shadow
export enum MilestoneTags {
  INTRO = 'intro',
  HR_PRACTICE = 'hr-practice',
  TIER_ZERO = 'tier-zero',
}

// eslint-disable-next-line no-shadow
export enum MilestoneStatuses {
  // null is "not started"
  COMPLETED = 'Complete',
  SKIPPED = 'Skipped',
}

export interface Milestone {
  companyId: string; // UUID
  companyMongoId: Company['id'];
  milestone: Milestones;
  tags: Array<MilestoneTags>;
  completedAt: string;
  completedBy: string;
  status: MilestoneStatuses;
}
