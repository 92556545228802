import { computed, ref, reactive, Ref } from '@nuxtjs/composition-api';
import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import { StaffMember } from '@/modules/StaffManagement/models';

enum TierNumber {
  TIER_0 = 0,
  TIER_1 = 1,
  TIER_2 = 2,
  TIER_3 = 3,
  TIER_4 = 4,
  TIER_5 = 5,
  TIER_6 = 6,
  TIER_7 = 7,
}

let isInitialized = false;

const plan = ref();
const isReady = ref(false);
const employees: Ref<StaffMember[]> = ref([]);
const employeeCount = computed(() => employees.value.length);
const currentTierNumber = computed(() => plan.value?.tierNumber);

const averageEmployeeCountPerTier = computed(() => {
  const range = (plan.value?.maxSize || 0) + (plan.value?.minSize || 0);

  const average = range / 2;

  return Number(Math.ceil(average));
});

/**
 * Time and Attendance
 */

const TimeAndAttendancePricingPerEmployeeByTier: Record<TierNumber, number> = {
  [TierNumber.TIER_0]: 7,
  [TierNumber.TIER_1]: 7,
  [TierNumber.TIER_2]: 7,
  [TierNumber.TIER_3]: 7,
  [TierNumber.TIER_4]: 7,
  [TierNumber.TIER_5]: 7,
  [TierNumber.TIER_6]: 7,
  [TierNumber.TIER_7]: 7,
} as const;

const timeAndAttendanceTierPricePerEmployee = computed(() => {
  return TimeAndAttendancePricingPerEmployeeByTier[plan.value?.tierNumber];
})

const timeAndAttendanceMonthlyEstimate = computed(() => {
  const eeCount = employeeCount.value > averageEmployeeCountPerTier.value ? employeeCount.value : averageEmployeeCountPerTier.value

  return eeCount * timeAndAttendanceTierPricePerEmployee.value;
});

const timeAndAttendanceYearlyEstimate = computed(() => {
  return timeAndAttendanceMonthlyEstimate.value * 12
});


/**
 * Payroll
 */
const payrollPricing = 19;
const payrollPerEmployee = computed(() =>
  // Tier 3+ is $4 per employee
  currentTierNumber.value < TierNumber.TIER_3 ? 5 : 4
);

const corePayrollPrice = computed(() => {
  if (employeeCount.value > averageEmployeeCountPerTier.value) {
    return employeeCount.value * payrollPerEmployee.value + payrollPricing;
  }

  return (
    averageEmployeeCountPerTier.value * payrollPerEmployee.value +
    payrollPricing
  );
});

const monthlyPayrollPrice = computed(() => {
  const count =
    employeeCount.value > averageEmployeeCountPerTier.value
      ? employeeCount.value
      : averageEmployeeCountPerTier.value;

  return payrollPricing + productPriceByTier.payroll.perEmployee * count;
});

const yearlyPayrollPrice = computed(() => monthlyPayrollPrice.value * 12);

/**
 * Training
 */
export const trainingPerEmployee = 24; // Current SINGLE course without bundle discount

const trainingPrice = computed(() => {
  if (employeeCount.value > averageEmployeeCountPerTier.value) {
    return employeeCount.value * trainingPerEmployee;
  }

  return averageEmployeeCountPerTier.value * trainingPerEmployee;
});

const yearlyTrainingPrice = computed(
  () =>
    employeeCount.value * productPriceByTier.training ||
    productPriceByTier.training
);

/**
 * Job Descriptions
 */

const JDPricing: Record<TierNumber, number> = {
  [TierNumber.TIER_0]: 69,
  [TierNumber.TIER_1]: 69,
  [TierNumber.TIER_2]: 119,
  [TierNumber.TIER_3]: 259,
  [TierNumber.TIER_4]: 299,
  [TierNumber.TIER_5]: 299,
  [TierNumber.TIER_6]: 299,
  [TierNumber.TIER_7]: 299,
} as const;

const currentJDPricing = computed(
  () => plan.value?.tierNumber && JDPricing[plan.value?.tierNumber]
);

const jobDescriptionsMonthlyEstimate = computed(
  () => currentJDPricing.value
); // Currently this is an unlimited per month, so it's just the same as the price

const jobDescriptionsYearlyEstimate = computed(()=>jobDescriptionsMonthlyEstimate.value * 12)


/**
 * Aggregate State
 */


const totalMonthlyPrice = computed(() => {
  return Math.round(
    corePayrollPrice.value +
    trainingPrice.value / 12 +
    productPriceByTier.jobDescriptions +
    plan?.value?.monthlyPrice + 
    timeAndAttendanceMonthlyEstimate.value
  );
});

// enum Products  {
//   PAYROLL = 'payroll',
//   TIME_AND_ATTENDANCE = 'timeAndAttendance',
//   TRAINING = 'training',
//   JD = 'jobDescriptions',
//   TOTAL = 'total'
// } 

// type ProductPricingEstimate = {
//   base?: number,
//   perEmployee?: number,
//   monthly: number,
//   yearly: number,
// }

// type PricingEstimateState = Record<Products, ProductPricingEstimate>;

// const state: PricingEstimateState = reactive({
//   [Products.PAYROLL]: {
//     base: payrollPricing,
//     perEmployee: payrollPerEmployee,
//     monthly: monthlyPayrollPrice,
//     yearly: yearlyPayrollPrice,
//   },
//   [Products.TIME_AND_ATTENDANCE]: {
//     perEmployee: timeAndAttendanceTierPricePerEmployee,
//     monthly: timeAndAttendanceMonthlyEstimate,
//     yearly: timeAndAttendanceYearlyEstimate,
//   },
//   [Products.TRAINING]: {
//     perEmployee: trainingPerEmployee,
//     monthly: trainingPrice,
//     yearly: yearlyTrainingPrice,
//   },
//   [Products.JD]: {
//     perEmployee: currentJDPricing,
//     monthly: jobDescriptionsMonthlyEstimate, // it's unlimited per month
//     yearly: jobDescriptionsYearlyEstimate,
//   },
//   [Products.TOTAL]: {
//     perEmployee: _____,
//     monthly: _____, // it's unlimited per month
//     yearly: _____,
//   },

// })

const productPriceByTier = reactive({
  payroll: {
    core: payrollPricing,
    perEmployee: payrollPerEmployee,
  },
  training: trainingPerEmployee,
  jobDescriptions: currentJDPricing,
  timeAndAttendance: timeAndAttendanceMonthlyEstimate,
});

const productPriceByEmployeeCount = reactive({
  payroll: {
    yearly: yearlyPayrollPrice,
    monthly: monthlyPayrollPrice,
  },
  training: { yearly: yearlyTrainingPrice },
  jobDescriptions: {
    monthly: currentJDPricing,
  },
});


const setupHook = async () => {
  const { allStaff, fetchEmployees } = useStaffManagement();
  await fetchEmployees(true);
  employees.value = allStaff.value;
  const { currentPlan } = useCurrentPlan();
  plan.value = currentPlan.value;
  isReady.value = true;
};

export const useBambeePlusPricing = () => {
  if (!isInitialized) {
    setupHook();
    isInitialized = true;
  }

  return {
    productPriceByTier,
    productPriceByEmployeeCount,
    totalMonthlyPrice,
    employeeCount,
    isReady,
    payrollPricing,
    trainingPerEmployee,
    payrollPerEmployee,
    monthlyPayrollPrice,
    yearlyPayrollPrice,
    trainingPrice,
    timeAndAttendanceTierPricePerEmployee,
    timeAndAttendanceMonthlyEstimate,
    timeAndAttendanceYearlyEstimate,    
  };
};

export default useBambeePlusPricing;
