





































































































































import { defineComponent } from '@nuxtjs/composition-api';
import GlowModal from '@/components/GlowModal.vue';
import FancyButton from './FancyButton.vue';
import { Assistants } from '@/modules/AI/hooks/useAIConversation';

export default defineComponent({
  name: 'NewBambeeModal',
  components: {
    GlowModal,
    FancyButton,
  },
  props: {},
  emits: ['start-chat', 'exit'],
  setup(props) {
    return {
      Assistants,
    };
  },
});
