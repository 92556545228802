<template>
  <div>
    <ErrorBoundary>
      <div class="head">
        <header-nav
          v-if="!isSso"
          :toggle_nav="toggle_nav"
          @handle_native_toggle_nav="do_toggle_nav"
          @handle_toggle_nav="do_toggle_nav"
        />
      </div>
      <div
        class="body_content"
        :class="{ 'overflow-hidden': toggle_nav == true }"
      >
        <nuxt />
      </div>
      <div class="frame_footer" />
      <portal-target name="layout" />
      <BaseNotifications />
    </ErrorBoundary>
  </div>
</template>

<script>
import miniToastr from 'mini-toastr';
import headerNav from '~/shared/components/utility/nav/header.vue';
import BaseNotifications from '@/components/BaseNotifications/BaseNotifications.vue';
import ErrorBoundary from '@/modules/payroll/components/ErrorBoundary/ErrorBoundary.vue';

export default {
  components: {
    headerNav,
    BaseNotifications,
    ErrorBoundary,
  },
  data() {
    return {
      toggle_nav: false,
    };
  },
  computed: {
    isSso() {
      return !!this.$route.query.sso;
    },
  },
  mounted() {
    miniToastr.init();
  },
  methods: {
    do_toggle_nav(value) {
      this.toggle_nav = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~assets/css/shared.scss';

.body_content {
  min-height: 100vh;
  overflow: hidden;
}

.frame_footer {
  background-color: $background;
  overflow: hidden;
}
</style>
<style>
.mini-toastr {
  bottom: 60px !important;
  position: fixed;
  right: 12px;
  top: auto !important;
  z-index: 99999;
}

.mini-toastr__notification {
  background-color: #000;
  border-left-style: solid;
  border-left-width: 4px;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: #fff;
  cursor: pointer;
  margin: 0 0 6px 0;
  opacity: 0.8;
  padding: 12px 18px;
  width: 300px;
}

.mini-toastr__notification.-error {
  background-color: #e54d42 !important;
  border-left-color: #b82e24 !important;
}

.mini-toastr__notification.-warn {
  background-color: #ffb648 !important;
  border-left-color: #f48a06 !important;
}

.mini-toastr__notification.-success {
  background-color: #68cd86 !important;
  border-left-color: #42a85f !important;
}

.mini-toastr__notification.-info {
  background-color: #694981 !important;
  border-left-color: #463056 !important;
}

.mini-toastr__notification:hover {
  box-shadow: none !important;
  opacity: 1;
}

.mini-toastr-notification__title {
  font-weight: 500;
}

.mini-toastr-notification__message {
  display: inline-block;
  padding: 0 12px;
  vertical-align: middle;
  width: 240px;
}
</style>
