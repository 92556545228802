<template>
  <div>
    <div v-if="isLoading" class="flex justify-center items-center p-10">
      <AnnularThrobber />
    </div>
    <PayrollFlowLayout v-else-if="!!payroll" :payroll="payroll">
      <template #title>
        Payroll Submitted for {{ periodStart }} -
        {{ periodEnd }}
      </template>

      <section>
        <nav class="mb-10 flex items-center">
          <BaseButton
            pre-icon="chevronLeft"
            type="button"
            variant="inverted"
            @click="goBack(backRoute.route)"
          >
            {{ backRoute.text }}
          </BaseButton>
          <BaseButton
            class="ml-2"
            flat
            pre-icon="download"
            @click="downloadPayrollReport"
          >
            Payroll Report
          </BaseButton>
          <QuickbooksSyncTag
            v-if="payroll.quickbooksSyncAttempt"
            class="ml-auto"
            :sync-attempt="payroll.quickbooksSyncAttempt"
          />
        </nav>

        <TypeDisplay
          variant="x-small"
          weight="strong"
          tag="h3"
          class="mb-4 text-black"
        >
          Payroll Summary
        </TypeDisplay>

        <PayrollOverviewCard :payroll="payroll" />

        <StaffPayTable
          title="Worker Breakdown"
          :payroll-id="payroll.id"
          class="mt-6"
        />
      </section>
    </PayrollFlowLayout>
  </div>
</template>

<script>
import {
  ref,
  computed,
  useRoute,
  useStore,
  useRouter,
} from '@nuxtjs/composition-api';
import { BaseButton, AnnularThrobber, TypeDisplay } from '@bambeehr/pollen';
import { formatDate } from '@/utils/date';
import PayrollFlowLayout from '@/modules/payroll/layouts/PayrollFlowLayout';
import useNotifications from '@bambeehr/use-notifications';
import PayrollOverviewCard from '@/modules/payroll/components/PayrollOverviewCard/PayrollOverviewCard';
import { PaymentMethod } from '@/modules/payroll/constants/payroll';
import StaffPayTable from '@/modules/payroll/components/StaffPayTable/StaffPayTable';
import QuickbooksSyncTag from '@/modules/payroll/components/Quickbooks/QuickbooksSyncTag';
import { useGetPayrollQuery } from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';
import { FILE_SERVER_URL } from '@/plugins/apollo/config/createApolloClient';
import useFileDownloader from '@/composables/useFileDownloader';

let previousRoute = {};

export default {
  components: {
    BaseButton,
    PayrollFlowLayout,
    StaffPayTable,
    TypeDisplay,
    PayrollOverviewCard,
    QuickbooksSyncTag,
    AnnularThrobber,
  },

  beforeRouteEnter(_, from, next) {
    previousRoute = from;
    next();
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { addInfo } = useNotifications();
    const { fileDownloader } = useFileDownloader();
    const isLoading = ref(false);
    const payrollResult = ref(null);
    const payrollId = computed(() => +route.value.params?.payroll_id);

    const { companyId } = store.getters;

    useApolloQuery(
      useGetPayrollQuery,
      {
        id: payrollId,
      },
      {
        pending: isLoading,
        data: payrollResult,
      }
    );

    const payroll = computed(() => payrollResult.value?.getPayroll || null);

    const prevRoute = ref(previousRoute);
    const backRoute = computed(() => {
      const { path: prevPath = '' } = prevRoute.value;

      if (prevPath.includes('past-payrolls')) {
        return {
          text: 'Back to Past Payrolls',
        };
      }

      if (prevPath.includes('preview')) {
        return {
          text: 'Back to Payroll',
          route: `/payroll/${payrollId.value}/preview`,
        };
      }

      return {
        text: 'Back to Payroll Overview',
        route: '/payroll/overview',
      };
    });

    const periodStart = computed(() => formatDate(payroll.value.periodStart));
    const periodEnd = computed(() => formatDate(payroll.value.periodEnd));

    function downloadPayrollReport() {
      const url = `${FILE_SERVER_URL}/document-secure/company/${companyId}/payroll-journal?payrollId=${payrollId.value}`;

      addInfo('Downloading Payroll Report');
      fileDownloader(
        url,
        `${periodStart.value}-${periodEnd.value} Payroll Report`
      );
    }

    const goBack = (backRoute = '') => {
      if (backRoute) {
        router.push(backRoute);

        return;
      }

      router.back();
    };

    return {
      backRoute,
      downloadPayrollReport,
      goBack,
      isLoading,
      payroll,
      payrollId,
      periodEnd,
      periodStart,
      prevRoute,
    };
  },
};
</script>

<style scoped>
>>> .earnings__table__group:not(.earnings__table__group--header):last-child {
  @apply border-b-0;
}
</style>
