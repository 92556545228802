<template>
  <PayrollCard>
    <div
      class="flex items-center cursor-pointer dashboard-tile text-base-800"
      @click="handleClick"
    >
      <BaseIcon
        v-if="preIcon"
        class="mr-5 text-28 text-base-600"
        :icon="preIcon"
      />

      <div class="items-center flex-1 mr-5 body md:flex">
        <div class="flex-1">
          <TypeBody
            v-if="$slots.heading"
            class="mb-1"
            variant="text-small"
            tag="h6"
          >
            <slot name="heading" />
          </TypeBody>

          <TypeBody
            variant="text-x-small-tight"
            class="text-base-600"
            tag="div"
          >
            <slot name="default" />
          </TypeBody>
        </div>

        <TypeBody
          v-if="$slots.secondary"
          weight="strong"
          variant="link-small"
          class="flex-1 mt-2 md:mt-0"
        >
          <slot name="secondary" />
        </TypeBody>
      </div>

      <BaseIcon class="ml-auto text-12 text-base-600" :icon="postIcon" />
    </div>
  </PayrollCard>
</template>
<script>
import '@nuxtjs/composition-api';
import { BaseIcon, TypeBody } from '@bambeehr/pollen';

import PayrollCard from '@/modules/payroll/components/PayrollCard/PayrollCard';

export default {
  name: 'DashboardTile',
  components: {
    BaseIcon,
    PayrollCard,
    TypeBody,
  },
  props: {
    preIcon: {
      type: String,
      default: '',
    },
    postIcon: {
      type: String,
      default: 'chevronRight',
    },
  },
  setup(props, { emit }) {
    function handleClick($event) {
      emit('click', $event);
    }

    return {
      handleClick,
    };
  },
};
</script>
