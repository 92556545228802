







































































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { TypeDisplay, TypeBody, BaseCard } from '@bambeehr/pollen';
import SquareTag from '@/components/SquareTag/SquareTag.vue';
import CatalogRecommendedTag from './CatalogRecommendedTag.vue';

export default defineComponent({
  name: 'CatalogCard',
  components: {
    TypeDisplay,
    BaseCard,
    SquareTag,
    TypeBody,
    CatalogRecommendedTag,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    duration: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => ({}),
    },
    purchase: {
      type: Object,
      default: () => ({}),
    },
    recommended: {
      type: Object,
      default: () => ({}),
    },
  },
  setup: (props) => {
    const img = computed(
      () => props.image || '/images/training/default-thumb.jpg'
    );

    return {
      img,
    };
  },
});
