






import { defineComponent } from '@nuxtjs/composition-api';
import { BaseButton } from '@bambeehr/pollen';
import ContentfulRichText from '@/components/contentful/ContentfulRichText';
import usePurchaseTrainingContent from '@/modules/Training/components/PurchaseTraining/usePurchaseTrainingContent';

export default defineComponent({
  name: 'PurchaseTrainingCta',
  components: {
    BaseButton,
    ContentfulRichText,
  },
  setup() {
    const {
      purchaseButtonTextWithVariables,
      pending: trainingContentPending,
      trainingRegistrationContentMap,
    } = usePurchaseTrainingContent();

    return {
      purchaseButtonTextWithVariables,
      trainingRegistrationContentMap,
      isLoading: trainingContentPending,
    };
  },
});
