




































































































































































































































































































































































































import AddressForm from '@/components/AddressForm';
import DescriptionTextInput from '@/components/DescriptionTextInput/DescriptionTextInput.vue';
import ExplainerTooltipModal from '@/components/ExplainerTooltipModal';
import useFileDownloader from '@/composables/useFileDownloader';
import VerifyFeinForm from '@/modules/payroll/components/VerifyFeinForm';
import { IndustryType, OnboardingGarnishmentOrDeduction } from '@/gql/generated';
import {
  BaseBanner,
  BaseButton,
  BaseCard,
  BaseChip,
  BaseIcon,
  BaseLink,
  CheckboxGroup,
  NumberInput,
  PhoneInput,
  RadioGroup,
  SelectInput,
  TextareaInput,
  TypeBody,
  TypeDisplay,
} from '@bambeehr/pollen';
import {
  computed,
  defineComponent,
  PropType,
  reactive,
} from '@nuxtjs/composition-api';

export interface Form {
  name: string;
  dba: string;
  phone: string;
  line1: string;
  line2: string;
  zip: string;
  city: string;
  state: string;
  fein: string;
  website: string;
  numOfFullTimeHourlyEmployees: number;
  numOfFullTimeSalaryEmployees: number;
  numOfPartTimeEmployees: number;
  numOfContractors: number;
  canSignForm: string;
  checkIndustry: IndustryType;
  signatoryFirstName: string;
  signatoryLastName: string;
  signatoryEmail: string;
  signatoryTitle: string;
  processedPayrollsThisYear: string;
  feinValidationSatisfied: boolean;
  onboardingOffersEmployeeBenefits: string | null;
  onboardingHasGarnishmentsOrDeductions: string | null;
  onboardingGarnishmentsOrDeductions: OnboardingGarnishmentOrDeduction[];
  onboardingGarnishmentsOrDeductionsOther: string;
}

const garnishmentOptions = Object.values(OnboardingGarnishmentOrDeduction).map(
  (item) => ({
    label: item
      .split('_')
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(' '),
    value: item,
  })
);

export enum RadioBool {
  TRUE = 'true',
  FALSE = 'false',
}

const boolOptions = [
  {
    label: 'Yes',
    value: RadioBool.TRUE,
  },
  {
    label: 'No',
    value: RadioBool.FALSE,
  },
];

const checkIndustryOptions = Object.entries(IndustryType).map(
  ([key, value]) => ({
    label: key.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '),
    value,
  })
);

export const validateEmail = (email: string) => {
  const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,63}$/;

  return emailRegex.test(email);
};

export const validateFein = (fein: string) =>
  fein && fein.length === 9 && !Number.isNaN(Number(fein));

export const validateWebsite = (website: string) => {
  // Simple (not exhaustive) domain format validation regex
  const webRegex = /(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?/;

  return webRegex.test(website);
};

export default defineComponent({
  name: 'CompanyInfoForm',
  components: {
    AddressForm,
    BaseBanner,
    BaseButton,
    BaseCard,
    BaseChip,
    BaseIcon,
    BaseLink,
    CheckboxGroup,
    DescriptionTextInput,
    ExplainerTooltipModal,
    NumberInput,
    PhoneInput,
    RadioGroup,
    SelectInput,
    TextareaInput,
    TypeBody,
    TypeDisplay,
    VerifyFeinForm,
  },
  props: {
    form: {
      type: Object as PropType<Form>,
      required: true,
    },
    isValidating: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    phoneIsValid: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    formIsValid: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    disableForm: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { fileDownloader } = useFileDownloader();
    const workingForm = reactive(props.form);

    const phoneErr = computed(() =>
      props.isValidating && !props.phoneIsValid
        ? 'Enter a 10-digit phone number'
        : ''
    );

    const nameError = computed(() =>
      props.isValidating && !workingForm.name
        ? 'Enter your legal company name'
        : ''
    );

    const feinIsValid = computed(
      () => props.isValidating && validateFein(workingForm.fein)
    );

    const emailIsValid = computed(
      () => props.isValidating && validateEmail(workingForm.signatoryEmail)
    );

    const websiteIsValid = computed(
      // Not a required field, so if it's empty it's 'valid'
      () =>
        props.isValidating &&
        (!workingForm.website || validateWebsite(workingForm.website))
    );

    const totalStaff = computed(
      () =>
        workingForm.numOfFullTimeHourlyEmployees +
        workingForm.numOfFullTimeSalaryEmployees +
        workingForm.numOfPartTimeEmployees +
        workingForm.numOfContractors
    );

    const showSignatory = computed(
      () => workingForm.canSignForm !== RadioBool.TRUE
    );

    const showBenefitsDownload = computed(
      () => workingForm.onboardingOffersEmployeeBenefits === RadioBool.TRUE
    );

    const showGarnishmentOptions = computed(
      () => workingForm.onboardingHasGarnishmentsOrDeductions === RadioBool.TRUE
    );

    const hasOtherGarnishments = computed(() =>
      workingForm.onboardingGarnishmentsOrDeductions.includes(
        OnboardingGarnishmentOrDeduction.Other
      )
    );

    const handleFeinForm = (status: boolean) => {
      workingForm.feinValidationSatisfied = status;
    };

    const downloadBenefitsSheet = () => {
      fileDownloader(
        'https://publicapi.stage.bambee.com/payroll/document-secure/benefit-contribution-worksheet',
        'benefit-contribution-worksheet'
      );
    };

    return {
      boolOptions,
      checkIndustryOptions,
      downloadBenefitsSheet,
      emailIsValid,
      feinIsValid,
      garnishmentOptions,
      handleFeinForm,
      hasOtherGarnishments,
      nameError,
      phoneErr,
      showBenefitsDownload,
      showGarnishmentOptions,
      showSignatory,
      totalStaff,
      workingForm,
      websiteIsValid,
    };
  },
});
