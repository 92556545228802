<template>
  <portal to="layout">
    <ModalDialog
      class="fixed inset-0 z-50"
      overlay="dark"
      size="medium"
      @close="$emit('close')"
    >
      <template #header>
        <TypeBody
          variant="link-large-tight"
          weight="strong"
          tag="h1"
          class="text-left"
        >
          What's the difference between an Employee and a Contractor?
        </TypeBody>
      </template>
      <div class="text-left">
        <TypeBody tag="p" variant="link-small" weight="strong">
          An Employee:
        </TypeBody>
        <ul class="mb-3 list-disc list-inside">
          <li>Works for just one employer</li>
          <li>Works at a specific time and location set by the employer</li>
          <li>Under the control and direction of the employer</li>
          <li>Can receive training from the employer</li>
        </ul>
        <TypeBody tag="p" variant="link-small" weight="strong">
          A Contractor:
        </TypeBody>
        <ul class="mb-3 list-disc list-inside">
          <li>Can work for multiple companies</li>
          <li>Sets their work hours and location</li>
          <li>Controls their direction of work</li>
          <li>Manages their own training</li>
        </ul>
        <p>
          If you have any questions, reach out to your HR Manager for
          assistance.
        </p>
      </div>
    </ModalDialog>
  </portal>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { ModalDialog, TypeBody } from '@bambeehr/pollen';

export default defineComponent({
  components: {
    ModalDialog,
    TypeBody,
  },
});
</script>
