/**
 * Takes a UserPreference key to check for locally set user preference in browser
 * @param {UserPreference} storeType - enum key of store type
 * @returns {string | boolean} - will return bool if true/false was set, otherwise will return string (numeric or otherwise) of initial set value
 */
export const getUserPreference = (storeType) => {
  const preference = localStorage.getItem(storeType);

  switch (preference) {
    case 'true':
      return true;

    case 'false':
      return false;

    default:
      return preference;
  }
};

/**
 * Takes a UserPreference key and value to set in the user's browser
 * @param {UserPreference} storeType - enum key of store type
 * @param {boolean | string | number} val - value to set with associated key
 */
export const setUserPreference = (storeType, val) => {
  localStorage.setItem(storeType, val);
};
