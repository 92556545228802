export const state = () => ({});

export const mutations = {};

export const getters = {};

export const actions = {
  /**
   * Documents
   */
  fetchDocument(_, { id }) {
    return this.$axios.get(`/v0/app/my/document/${id}`);
  },

  async signDocument(_, { id, typedName }) {
    return this.$axios.post(`/v0/app/my/document/${id}/sign`, {
      typed_name: typedName,
    });
  },

  /**
   * Panda Documents
   */
  completePandaDocument(_, { documentId, signer }) {
    return this.$axios.post('/v0/app/panda/document/complete', {
      documentId,
      signer,
    });
  },
};
