<template>
  <div>
    <div
      v-for="ledgerItem in list"
      :key="ledgerItem.id"
      class="flex justify-between ledger-item"
    >
      <TypeBody
        class="text-gray-0"
        tag="span"
        variant="text-x-small-tight"
        weight="regular"
      >
        {{ ledgerItem.description }}
      </TypeBody>

      <TypeBody
        class="text-gray-0"
        tag="span"
        variant="text-x-small-tight"
        weight="regular"
      >
        {{ currency(ledgerItem.amount).format() }}
      </TypeBody>
    </div>
    <div
      v-if="total"
      class="flex justify-between pt-3 mt-3 border-0 border-t border-solid border-base-400"
    >
      <TypeBody
        class="text-gray-0"
        tag="span"
        variant="link-x-small-tight"
        weight="strong"
      >
        {{ totalLabel }}
      </TypeBody>

      <TypeBody
        class="text-gray-0"
        tag="span"
        variant="link-x-small-tight"
        weight="strong"
      >
        {{ currency(total).format() }}
      </TypeBody>
    </div>
  </div>
</template>

<script>
import '@nuxtjs/composition-api';
import { TypeBody } from '@bambeehr/pollen';
import currency from '@bambeehr/currency';

export default {
  name: 'LedgerList',
  components: {
    TypeBody,
  },
  props: {
    list: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    totalLabel: {
      type: String,
      required: false,
      default: 'Total',
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup() {
    return {
      currency,
    };
  },
};
</script>

<style scoped>
.ledger-item + .ledger-item {
  @apply mt-2;
}
</style>
