// We're recieving bundle items as individual course.
// This will collect them into bundles and update the naming appropriately.
const mapCoursesToBundles = (list) => {
  return list.reduce((acc, cur) => {
    const matchedBundle = acc.find(
      (i) => !!cur.bundleId && i.bundleId === cur.bundleId
    );

    if (matchedBundle) {
      const topics = [...(matchedBundle.topics || [matchedBundle]), cur];

      return [
        ...acc.filter((i) => i.id !== matchedBundle.id),
        {
          ...matchedBundle,
          isBundle: true,
          id: matchedBundle.bundleId,
          name: matchedBundle.bundleName,
          // If the bundle has any startedAt date, the bundle is in progress
          startedAt: matchedBundle.startedAt || cur.startedAt,
          // If the bundle has any incomplete topics, the bundle is incomplete
          completedAt: !cur.completedAt
            ? cur.completedAt
            : matchedBundle.completedAt,
          topics,
          allCompleted: topics.every((t) => t.completedAt),
          certificateDownloadUrlList: [
            ...(matchedBundle.certificateDownloadUrlList || [
              matchedBundle.certificateDownloadUrl,
            ]),
            cur.certificateDownloadUrl,
          ].filter(Boolean),
        },
      ];
    }

    return [...acc, cur];
  }, []);
};

export default mapCoursesToBundles;
