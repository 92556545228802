




































































import {
  defineComponent,
  PropType,
  watch,
  reactive,
  nextTick,
  computed,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';
import { TextInput, SelectInput, BaseButton, BaseIcon } from '@bambeehr/pollen';
import { PayTypeCaps } from '@/lib/globals/users';
import isEqual from 'lodash/isEqual';

import useAddStaffFormRole, {
  ClientWorkerRole,
  RoleOptionAction,
} from './useAddStaffFormRole';

const mapToRoleForm = (role: ClientWorkerRole) => ({
  payRate: String(role.payRate),
  payType: role.payType || PayTypeCaps.HOURLY,
  id: role.id,
  isPrimary: role.isPrimary,
  isNew: role.isNew,
  isDeleted: role.isDeleted,
  companyRole: {
    id: role.companyRole?.id,
    title: role.companyRole?.title,
    deletedAt: role.companyRole?.deletedAt,
  },
});

export default defineComponent({
  components: {
    BaseButton,
    TextInput,
    BaseIcon,
    SelectInput,
  },
  props: {
    role: {
      type: Object as PropType<ClientWorkerRole>,
      required: true,
    },
  },
  setup(props) {
    const {
      roleSearchQuery,
      updateRoleSearchQuery,
      createCompanyRole,
      roleOptions,
      toggleManageCompanyRolesModal,
      payTypeOptions,
      toggleSalaryRolesModal,
      updateRoleModel,
      rolesModel,
      currentActiveRoles,
      isValidating,
    } = useAddStaffFormRole();

    const roleForm = reactive(mapToRoleForm(props.role));

    // Syncing upstream updates
    watch(props, ({ role }) => {
      if (!isEqual(mapToRoleForm(role), roleForm)) {
        Object.assign(roleForm, mapToRoleForm(role));
      }
    });

    watch(roleForm, (updatedRole) => {
      // The "manage roles" button is just an option in the select
      // This will trigger the modal when the user selects it and reset the model
      if (updatedRole?.companyRole?.id === RoleOptionAction.MANAGE) {
        const originalCompRoleId = props.role.companyRole?.id;

        toggleManageCompanyRolesModal();
        nextTick(() => {
          if (roleForm.companyRole) {
            if (originalCompRoleId) {
              roleForm.companyRole.id = originalCompRoleId;
            } else {
              roleForm.companyRole.id = '';
            }
          }
          updateRoleModel(updatedRole);
        });

        return;
      }

      if (
        updatedRole.payType === PayTypeCaps.SALARY &&
        currentActiveRoles.value.length > 1
      ) {
        toggleSalaryRolesModal(true);
      }

      updateRoleModel(updatedRole);
    });

    const makePrimary = () => {
      roleForm.isPrimary = true;
    };

    const removeRole = () => {
      roleForm.isDeleted = true;
    };

    // In order to retain the current value of the select in the filtered options list, we'll prepend it.
    // If we don't do this, the select input will not recognize the selection because it isn't the options array.
    const filteredRoleOptions = computed(() =>
      roleForm.companyRole?.id
        ? [
            {
              value: roleForm.companyRole.id,
              label: roleForm.companyRole.title,
            },
            ...roleOptions.value,
          ]
        : roleOptions.value
    );

    const showPrimaryBorder = computed(
      () =>
        roleForm.isPrimary &&
        rolesModel.value.filter((r) => !r.isDeleted).length > 1
    );

    // Workaround for pollen bug with the TextInput masks
    const currencyMask = ref();
    onMounted(() => {
      currencyMask.value = {
        numeral: true,
        numeralPositiveOnly: true,
      };
    });

    return {
      roleSearchQuery,
      updateRoleSearchQuery,
      removeRole,
      createCompanyRole,
      roleForm,
      roleOptions,
      filteredRoleOptions,
      payTypeOptions,
      makePrimary,
      showPrimaryBorder,
      isValidating,
      currencyMask,
    };
  },
});
