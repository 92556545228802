




































import useAddStaffFormState from '../hooks/useAddStaffFormState';
import { defineComponent } from '@nuxtjs/composition-api';
import {
  CheckboxInput,
  DateInput,
  PhoneInput,
  TypeDisplay,
} from '@bambeehr/pollen';
import AddressForm from '@/components/AddressForm/AddressForm.vue';
import useAddStaffForm from '../hooks/useAddStaffForm';
import AddStaffFormGroup from './AddStaffFormGroup';
import ToolTip from '@/components/ToolTip';

export default defineComponent({
  components: {
    AddressForm,
    AddStaffFormGroup,
    CheckboxInput,
    DateInput,
    PhoneInput,
    ToolTip,
    TypeDisplay,
  },
  setup() {
    const { addressForm, hasPayroll, isPayrollUser, addressLabels } =
      useAddStaffForm();
    const { v$, formState, isInvalid, needsPayrollInfo } =
      useAddStaffFormState();

    return {
      addressForm,
      formState,
      isInvalid,
      v$,
      hasPayroll,
      isPayrollUser,
      addressLabels,
      needsPayrollInfo,
    };
  },
});
