









































import { defineComponent } from '@nuxtjs/composition-api';
import { ModalDialog, BaseButton, TypeBody } from '@bambeehr/pollen';
import AnimatedCheckmark from '@/components/AnimatedCheckmark/AnimatedCheckmark';

export default defineComponent({
  name: 'PurchaseTrainingConfirmationModal',
  components: {
    AnimatedCheckmark,
    BaseButton,
    ModalDialog,
    TypeBody,
  },
});
