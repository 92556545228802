<template>
  <PayrollCard
    class="text-base-800"
    :class="{ 'bg-purple-gradient text-white': isCallout }"
  >
    <div class="flex flex-col sm:flex-row">
      <div class="relative w-full sm:w-1/2 text-left sm:pr-4">
        <div class="flex justify-between gap-3">
          <TypeBody tag="div" variant="text-x-small-tight" weight="medium">
            {{ statusText }}
          </TypeBody>

          <BaseTag
            v-if="tag"
            :class="{ 'bg-error-default': tag.error }"
            size="small"
          >
            {{ tag.message }}
          </BaseTag>
        </div>

        <TypeBody
          variant="link-large-tight"
          weight="strong"
          tag="div"
          class="mt-1"
        >
          {{ startDate }} - {{ endDate }}
        </TypeBody>

        <TypeBody
          v-if="description"
          tag="div"
          variant="text-x-small-tight"
          weight="medium"
          class="text-base-700 mt-1"
          :class="{ 'text-white': isCallout }"
        >
          {{ description }}
        </TypeBody>

        <BaseBanner
          v-if="banner"
          class="mt-2"
          :variant="banner.variant"
          :message="banner.message"
          :show-close="false"
        >
          <span v-html="banner.description"> </span>
        </BaseBanner>

        <div class="mt-4">
          <BaseButton
            :variant="isCallout ? 'white' : 'primary'"
            :disabled="disabled"
            @click.prevent.once="handleBtnClick(primaryBtn)"
          >
            {{ primaryBtn.label }}
          </BaseButton>
          <BaseButton
            v-if="secondaryBtn"
            :disabled="disabled"
            variant="white"
            @click.prevent="handleBtnClick(secondaryBtn)"
          >
            {{ secondaryBtn.label }}
          </BaseButton>
        </div>
        <div
          :class="isExpanded ? '-rotate-90' : 'rotate-90'"
          class="absolute right-4 bottom-1 rotate-90 transform block sm:hidden"
          @click="toggleExpandedState"
        >
          <BaseIcon icon="chevronRight" />
        </div>
      </div>
      <div
        :class="expandClasses"
        class="w-full sm:w-1/2 overflow-hidden transition-all duration-150 sm:transition-none sm:max-h-full sm:opacity-100"
      >
        <div
          class="mt-4 sm:mt-0 pt-4 sm:pt-0 sm:pl-4 border-t sm:border-t-0 sm:border-l h-full flex flex-col gap-1"
          :class="{
            'border-primary-light': isCallout,
            'border-base-400': !isCallout,
          }"
        >
          <div v-for="{ key, error, value, label } in breakdown" :key="key">
            <div v-if="!!value" class="breakdown-item flex justify-between">
              <TypeBody
                variant="text-x-small-tight"
                weight="medium"
                class="text-base-700"
                :class="{
                  'text-error-default': error,
                  'text-white': isCallout,
                }"
              >
                {{ label }}
              </TypeBody>
              <TypeBody
                variant="text-x-small-tight"
                weight="medium"
                class="text-base-700"
                :class="{
                  'text-error-default': error,
                  'text-white': isCallout,
                }"
              >
                {{ value }}
              </TypeBody>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingModal v-if="isInitializing">
      <template #header> Loading payroll </template>
      Please do not leave this page or close your browser, while we load your
      payroll. You will be redirected automatically once complete.
    </LoadingModal>
  </PayrollCard>
</template>

<script>
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal.vue';
import PayrollCard from '@/modules/payroll/components/PayrollCard/PayrollCard.vue';
import { formatDate } from '@/utils/date';
import {
  BaseBanner,
  BaseButton,
  BaseIcon,
  BaseTag,
  TypeBody,
} from '@bambeehr/pollen';
import { computed, ref, useRouter } from '@nuxtjs/composition-api';

export default {
  components: {
    BaseBanner,
    BaseButton,
    BaseIcon,
    BaseTag,
    LoadingModal,
    PayrollCard,
    TypeBody,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    banner: {
      type: Object,
      required: false,
      default: null,
    },
    breakdown: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    isCallout: {
      type: Boolean,
      required: false,
      default: false,
    },
    payrollStartDate: {
      type: [Date, String],
      required: true,
    },
    payrollEndDate: {
      type: [Date, String],
      required: true,
    },
    primaryBtn: {
      type: Object,
      required: true,
    },
    secondaryBtn: {
      type: Object,
      required: false,
      default: null,
    },
    statusText: {
      type: String,
      required: true,
    },
    tag: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const isInitializing = ref(false);
    const isExpanded = ref(false);
    const startDate = computed(() => formatDate(props.payrollStartDate));
    const endDate = computed(() => formatDate(props.payrollEndDate));
    const expandClasses = computed(() =>
      isExpanded.value ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0'
    );

    function toggleExpandedState() {
      isExpanded.value = !isExpanded.value;
    }

    const router = useRouter();
    function handleBtnClick(btnConfig) {
      if (btnConfig.link) {
        router.push(btnConfig.link);
      } else if (btnConfig.handler) {
        btnConfig.handler(isInitializing);
      }
    }

    return {
      endDate,
      expandClasses,
      handleBtnClick,
      isExpanded,
      isInitializing,
      startDate,
      toggleExpandedState,
    };
  },
};
</script>
