<template>
  <header
    class="flex-grow-0 bg-primary-weak pt-8 pb-10 text-center overflow-hidden relative"
  >
    <div>
      <TypeOverline
        v-if="headerIntro"
        variant="x-small-tight"
        tag="div"
        class="mb-2 text-base-700"
      >
        {{ headerIntro }}
      </TypeOverline>

      <TypeDisplay variant="small" class="text-primary-default">
        {{ header }}
      </TypeDisplay>

      <TypeBody
        v-if="subHeader"
        weight="medium"
        variant="text-small"
        tag="div"
        class="subheader mt-2 text-base-700"
      >
        <ContentfulRichText :content="subHeader" />
      </TypeBody>
    </div>
  </header>
</template>

<script>
import { ref, computed } from '@nuxtjs/composition-api';
import { TypeDisplay, TypeOverline, TypeBody } from '@bambeehr/pollen';
import fetchTrainingContent from '@/gql/services/trainingService/fetchContent';
import ContentfulRichText from '@/components/contentful/ContentfulRichText';

export default {
  name: 'TrainingRegistrationHeader',
  components: {
    TypeBody,
    TypeDisplay,
    TypeOverline,
    ContentfulRichText,
  },
  setup() {
    const trainingContent = ref({});
    const fetchContent = async () => {
      const content = await fetchTrainingContent();
      trainingContent.value = content;
    };
    fetchContent();

    const header = computed(() => {
      return (
        trainingContent.value.registrationHeader ||
        'The Bambee Core Training Bundle'
      );
    });

    const subHeader = computed(() => {
      return trainingContent.value.registrationSubheader;
    });

    const headerIntro = computed(() => {
      return trainingContent.value.registrationHeaderIntro;
    });

    return {
      header,
      headerIntro,
      subHeader,
    };
  },
};
</script>

<style scoped>
>>> .subheader p + p {
  margin: 0;
}
</style>
