import convertBytesToUnits from '@/utils/convertBytesToUnits';

const validateFile = (file: File, maxBytes: number, accept: string): string => {
  let validationError = '';

  if (file.size > maxBytes) {
    validationError = `${file.name} is larger than ${convertBytesToUnits(
      maxBytes,
      0
    )}`;
  }

  if (!accept.includes(file.type)) {
    validationError = `${file.name} is not an accepted file type`;
  }

  return validationError;
};

export default validateFile;
