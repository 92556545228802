<template>
  <div class="flex flex-row items-center py-3 row" :class="`row--${variant}`">
    <div class="flex flex-col w-2/4">
      <TypeBody v-if="isPrimary" variant="link-small" weight="strong">
        {{ title }}
      </TypeBody>
      <TypeBody v-else variant="text-small" weight="regular">
        {{ title }}
      </TypeBody>

      <TypeBody
        variant="text-x-small-tight"
        weight="regular"
        class="text-primary-lighter"
      >
        {{ subtitle }}
      </TypeBody>
    </div>
    <div class="justify-center hidden w-1/4 sm:flex">
      <TypeBody variant="text-small" weight="regular" class="text-center">
        <slot />
      </TypeBody>
    </div>
    <div class="flex flex-col w-1/2 sm:w-1/4">
      <div v-if="isPrimary && hasFreePeriod">
        <TypeBody
          v-if="freePeriodLabel"
          variant="link-small"
          weight="strong"
          class="block text-right mb-0"
        >
          {{ freePeriodLabel }}
        </TypeBody>
        <TypeBody variant="link-small" weight="strong" class="block text-right">
          ${{ price }}
        </TypeBody>
      </div>
      <div v-else-if="isPrimary">
        <TypeDisplay variant="x-small" weight="strong" class="block text-right">
          ${{ price }}
        </TypeDisplay>
      </div>
      <TypeBody v-else variant="link-small" weight="strong" class="text-right">
        *${{ price }}
      </TypeBody>
    </div>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { TypeBody, TypeDisplay } from '@bambeehr/pollen';

export default {
  components: {
    TypeBody,
    TypeDisplay,
  },
  props: {
    variant: {
      type: String,
      required: false,
      default: 'secondary',
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    price: {
      type: String,
      required: true,
    },
    freePeriodLabel: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const isPrimary = computed(() => props.variant === 'primary');
    const hasFreePeriod = computed(() => !!props.freePeriodLabel);

    return {
      isPrimary,
      hasFreePeriod,
    };
  },
};
</script>

<style scoped>
.row--primary {
  @apply text-white rounded px-4;
  background: linear-gradient(103.15deg, #6b4392 0%, #3d1266 100%);
}

.row--secondary {
  @apply border-b border-base-400 mx-4;
}
</style>
