import { computed, ComputedRef } from '@nuxtjs/composition-api';

export interface StepContent {
  message: string;
  ctaText: string;
  ctaIcon: string;
}

export interface useStaffComplianceStatusMessageReturn {
  step1Content: ComputedRef<StepContent>;
  step2Content: ComputedRef<StepContent>;
  step3Content: ComputedRef<StepContent>;
}

export interface ComplianceDetails {
  step: number;
  staffCount: number;
  complete: boolean;
}

/**
 * useStaffComplianceStatusMessage
 *
 * Will give you back a conditonal compliance message, button text, and button icons based on the completion of the sections
 */
const useStaffComplianceStatusMessage = (
  complianceDetails: ComputedRef<ComplianceDetails[]>
): useStaffComplianceStatusMessageReturn => {
  const invitedStep = computed(() => complianceDetails.value[0]);
  const loginStep = computed(() => complianceDetails.value[1]);
  const signPolicyStep = computed(() => complianceDetails.value[2]);

  const step1Content: ComputedRef<StepContent> = computed(() => {
    if (invitedStep.value.step === 1) {
      if (invitedStep.value.complete) {
        return {
          message: 'All Staff Have Been Invited',
          ctaText: 'Invite Sent',
          ctaIcon: 'check',
        };
      }

      return {
        message:
          invitedStep.value.staffCount > 1
            ? `${invitedStep.value.staffCount} Staff Need to Be Invited`
            : `${invitedStep.value.staffCount} Staff Needs to Be Invited`,
        ctaText: 'Invite Now',
        ctaIcon: 'envelopeOpenText',
      };
    }

    return {
      message: '',
      ctaText: '',
      ctaIcon: '',
    };
  });

  const step2Content = computed(() => {
    if (loginStep.value.step === 2) {
      if (loginStep.value.complete) {
        return {
          message:
            loginStep.value.staffCount > 1
              ? 'All Staff Are Active'
              : 'All Staff Are Active',
          ctaText: 'All Active',
          ctaIcon: 'check',
        };
      }

      return {
        message:
          loginStep.value.staffCount > 1
            ? `${loginStep.value.staffCount} Staff Need to Log In`
            : `${loginStep.value.staffCount} Staff Needs to Log In`,
        ctaText: 'Send Message',
        ctaIcon: 'comment',
      };
    }

    return {
      message: '',
      ctaText: '',
      ctaIcon: '',
    };
  });

  const step3Content = computed(() => {
    if (signPolicyStep.value.step === 3) {
      // complete state is never shown for step 3
      // card will be hidden if step is complete

      return {
        message:
          signPolicyStep.value.staffCount > 1
            ? `${signPolicyStep.value.staffCount} Staff Need to Sign Policies`
            : `${signPolicyStep.value.staffCount} Staff Needs to Sign Policies`,
        ctaText: 'Send Message',
        ctaIcon: 'comment',
      };
    }

    return {
      message: '',
      ctaText: '',
      ctaIcon: '',
    };
  });

  return {
    step1Content,
    step2Content,
    step3Content,
  };
};

export default useStaffComplianceStatusMessage;
