



































import { BaseButton, TypeBody } from '@bambeehr/pollen';
import LoadingButtonLabel from '@/components/LoadingButtonLabel';
import useAddWorkerPolicies from '@/modules/StaffManagement/components/AddStaffWizard/Policies/useAddWorkerPolicies';

export default {
  components: {
    BaseButton,
    LoadingButtonLabel,
    TypeBody,
  },
  setup() {
    const {
      handleSave,
      isSaving,
      selectedDocumentsLabel,
      toggleSelectedView,
      toggleViewLabel,
      isLoading,
    } = useAddWorkerPolicies();

    return {
      handleSave,
      isSaving,
      selectedDocumentsLabel,
      toggleSelectedView,
      toggleViewLabel,
      isLoading,
    };
  },
};
