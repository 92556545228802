import mutate from './mutate';
import watchQuery from './watchQuery';
import query from './query';

const decorateApolloClient = (apolloClient) => {
  return {
    ...apolloClient,
    watchQuery: watchQuery(apolloClient),
    mutate: mutate(apolloClient),
    query: query(apolloClient),
  };
};

export default decorateApolloClient;
