
















import { defineComponent, ref } from '@nuxtjs/composition-api';
import { TypeBody, AvatarImage, BaseIcon } from '@bambeehr/pollen';
import FancyCheck from '@/modules/AI/components/FancyCheck.vue';

export enum MessageType {
  AI = 'AI',
  USER = 'USER',
}

export default defineComponent({
  name: 'LoadingMessage',
  components: {
    TypeBody,
    FancyCheck,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  emits: ['resend'],
  setup() {
    const shouldShow = ref(false);

    setTimeout(() => {
      shouldShow.value = true;
      // Note: This is super tightly coupled to the parents loading context
      // This should be abstracted so it's more reusable
    }, 1200);

    return {
      shouldShow,
    };
  },
});
