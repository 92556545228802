import {
  ResignationStatus,
  ResignationStep,
  TerminationStatus,
  TerminationStep,
} from '@/gql/generated';
import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import usePayrollGroup from '@/modules/TaskCenter/components/PayrollGroup/usePayrollGroup';
import { useStaffComplianceStatusCard } from '@/modules/TaskCenter/components/StaffComplianceStatusCard';
import useTrainingGroup from '@/modules/TaskCenter/components/TrainingGroup/useTrainingGroup';
import {
  endOfEmploymentListRaw,
  fetchEndOfEmployment,
} from '@/modules/TaskCenter/services/task-center.service';
import { computed, Ref, ref, useStore } from '@nuxtjs/composition-api';
import useJobDescriptionGroup from '../components/JobDescriptionGroup/useJobDescriptionGroup';
import useCapsStore from '@/store/caps';

enum StoreGetter {
  BCI_SHOW_SETUP = 'bci/showSetup',
  TOTAL_FEED = 'feeds/totalFeedsCount',
  IS_COMPANY_ADMIN = 'auth/isCompanyAdmin',
}

enum StoreActions {
  FETCH_EMPLOYEES = 'users/getSimplifiedMyEmployees',
  FETCH_CAPS = 'caps/fetchCAPs',
  FETCH_POLICIES = 'policies/getMyPoliciesSimplified',
  FETCH_MILESTONES = 'milestones/getMilestones',
  FETCH_FEED = 'feeds/getFeeds',

  FETCH_NOTIFICATIONS = 'notifications/fetchNotifications',
  FETCH_TICKETS = 'tickets/getAll',
  FETCH_HR_EXPECTATIONS = 'getHRExpectations',
  FETCH_INSURANCE_PLANS = 'insurance/getPlans',
  FETCH_PRICE_PLANS = 'pricePlans/fetch',
}

let store;
const initialized: Ref<Boolean> = ref(false);
const isFeedLoading = ref<boolean>(true);
const isLoading = computed(() => !!isFeedLoading.value);
const isCompanyAdmin = ref<boolean>();
const hasBciMilestones = ref<boolean>();

// EoE => filter out EoE without action required
const endOfEmploymentList = computed(() =>
  endOfEmploymentListRaw.value?.filter((eoe) => {
    return (
      ![TerminationStep.UploadSigned, ResignationStep.UploadSigned].includes(
        eoe.step
      ) &&
      ![
        TerminationStatus.Canceled,
        TerminationStatus.Complete,
        ResignationStatus.Cancelled,
        ResignationStatus.Completed,
      ].includes(eoe.status)
    );
  })
);
const endOfEmploymentCount = computed(() => endOfEmploymentList.value.length);

// Store Feed Count = Documents + CAPs + Policies
const storeFeedCount = (value: boolean) =>
  store.getters[StoreGetter.TOTAL_FEED](value);

const setupHook = async (Store: any): Promise<void> => {
  const calls: Array<any> = [];
  isCompanyAdmin.value = Store.getters[StoreGetter.IS_COMPANY_ADMIN];

  const capsStore = useCapsStore();

  if (isCompanyAdmin.value) {
    calls.push(fetchEndOfEmployment());
    calls.push(capsStore.fetchCAPs());
    calls.push(Store.dispatch(StoreActions.FETCH_POLICIES));
    calls.push(Store.dispatch(StoreActions.FETCH_MILESTONES));

    await Promise.all(calls).then(async () => {
      isFeedLoading.value = false;
      hasBciMilestones.value = Store.getters[StoreGetter.BCI_SHOW_SETUP];
    });

    return;
  }
  await Store.dispatch(StoreActions.FETCH_FEED);

  isFeedLoading.value = false;
};

const useFeeds = () => {
  if (!initialized.value) {
    initialized.value = true;
    store = useStore() as any;
    setupHook(store);
  }

  const { jobDescriptionActionItemCount } = useJobDescriptionGroup();

  const { overdueCourseCount } = useTrainingGroup();

  // Staff Compliance Status required for Staff Compliance Task Count
  const { allStaff } = useStaffManagement();
  const { isAllComplete } = useStaffComplianceStatusCard(allStaff);

  /**
   * Total Feed Count = Acknowledgement Documents + Unsigned Documents + CAPs +
   * Awaiting Approval Policies + End of Employments +
   * HrComplianceStatus (cards) Count + Staff Compliance Status
   */
  const totalFeedCount = computed(
    () =>
      storeFeedCount(false) +
      endOfEmploymentCount.value +
      (overdueCourseCount.value ? 1 : 0) +
      jobDescriptionActionItemCount.value
  );

  const feedCount = computed(() => storeFeedCount(false));

  return {
    endOfEmploymentList,
    endOfEmploymentCount,
    feedCount,
    isAllComplete,
    isCompanyAdmin,
    isFeedLoading,
    isLoading,
    totalFeedCount,
  };
};

export default useFeeds;
