
































































import useCurrentCompany from '@/hooks/useCurrentCompany';
import VideoTourStrip from '@/modules/TimeAndAttendance/components/VideoTourStrip/VideoTourStrip.vue';
import { guard } from '@/modules/TimeAndAttendance/middleware/canAccessEmployerTimeAndAttendance';
import { getCompanyStatus } from '@/gql/services/payroll.service';
import {
  TypeBody,
  TypeDisplay,
  BaseCard,
  AnnularThrobber,
} from '@bambeehr/pollen';
import WorkplaceModal from '@/components/Workplaces/WorkplaceModal';
import useNotifications from '@bambeehr/use-notifications';
import {
  defineComponent,
  onMounted,
  ref,
  useRouter,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import usePayrollCompany from '@/hooks/usePayrollCompany';
import { MarketingEvents } from '@/modules/TimeAndAttendance/constants';
import bam from '@/lib/bam';

const defaultWorkplace = {
  default: true,
  address: {},
};

export default defineComponent({
  name: 'TimeAndAttendanceUpsell',
  components: {
    TypeDisplay,
    TypeBody,
    VideoTourStrip,
    BaseCard,
    WorkplaceModal,
    AnnularThrobber,
  },
  middleware: [guard],
  setup() {
    const router = useRouter();
    const { companyId } = useCurrentCompany();
    const currentHeartBeat = ref(0);
    const heartBeatWait = ref(5000);
    const { workplaceCount, isLoading: isLoadingPayrollCompany } =
      usePayrollCompany(true);

    const heartBeatDeputyStatus = async () => {
      if (workplaceCount.value) {
        const { data } = await getCompanyStatus(companyId.value, {
          fetchPolicy: 'network-only',
        });

        return !!data?.deputyConnection?.hasAccessToken;
      }

      return null;
    };

    let checkInterval;
    onMounted(() => {
      checkInterval = setInterval(async () => {
        const hasAccessToken = await heartBeatDeputyStatus();
        if (!hasAccessToken) {
          if (currentHeartBeat.value === 0) {
            currentHeartBeat.value += 1;

            return;
          }
          heartBeatWait.value = 10000;
        } else {
          clearInterval(checkInterval);
          bam.trackEvent(MarketingEvents.PROVISIONING_COMPLETED);
          setTimeout(() => {
            router.push('/time-and-attendance');
          }, 10000);
        }
      }, heartBeatWait.value);
    });

    onBeforeUnmount(() => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    });

    const hasAddedWorkplace = ref(false);
    const handleSaved = () => {
      const { addSuccess } = useNotifications();

      addSuccess('Workplace added successfully');
      hasAddedWorkplace.value = true;
      usePayrollCompany(true);
      bam.trackEvent(MarketingEvents.WORKPLACE_ADDED);
    };

    return {
      workplaceCount,
      defaultWorkplace,
      handleSaved,
      isLoadingPayrollCompany,
      hasAddedWorkplace,
    };
  },
});
