import useCreditCardFailedModal from '@/components/CreditCardFailedModal/useCreditCardFailedModal';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentUser from '@/hooks/useCurrentUser';
import bam from '@/lib/bam';
import {
  ProductKey,
  Product,
  PurchaseInput,
  PurchaseSubscriptionInput,
  useMyProductsQuery,
  usePurchaseCreditsMutation,
  usePurchaseSubscriptionMutation,
  ProductSubKey,
  useConsumeNectarCreditsMutation,
  ProductCreditConsumptionReason,
  useGetCompanyCreditsQuery,
  Credit,
} from '@/gql/generated';
import { PurchaseType } from '@/modules/HrRequest/components/Purchase/constants';
import useHrRequestTracking from '@/modules/HrRequest/hooks/useHrRequestTracking';
import { parseHrRequestPricing } from '@/modules/HrRequest/libs/utils';
import useNotifications from '@bambeehr/use-notifications';
import { computed, readonly, ref, watch } from '@nuxtjs/composition-api';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import isAdmin from '@/utils/isAdmin';

export type PurchaseEvent = {
  type: PurchaseType;
  name: string;
  productKey: ProductKey;
  // only for Single purchases
  quantity?: number;
  price: number;
  amount: number;
};

type CurrentProductOffering =
  | ProductKey.JobDescription
  | ProductKey.JobDescriptionAi
  | ProductKey.NewPolicy;

const getProductInformationByProductKey = (
  productKey: CurrentProductOffering,
  allCredits: Product[] = []
) => {
  const product = allCredits.find((credit) => credit.key === productKey);

  return {
    creditBalance: product?.creditBalance ?? 0,
    activeSubscription: product?.activeSubscription ?? false,
  };
};

// each product will have a creditBalance
const credits = ref<Product[]>();

const purchaseCreditsLoading = ref(false);
const purchaseSubscriptionLoading = ref(false);

const { addSuccess } = useNotifications();

const { sessionId } = useHrRequestTracking();
const { currentUser } = useCurrentUser();

const { openModal } = useCreditCardFailedModal();

const isLoading = ref(true);

const allPurchases = ref<Credit[]>([]);
const getPurchases = () => {
  const { companyId } = useCurrentCompany();
  const unwatch = watch(
    companyId,
    (id) => {
      if (id && isAdmin.value) {
        const { onResult } = useApolloQuery(
          useGetCompanyCreditsQuery,
          {
            data: {
              companyId: companyId.value,
              productKey: ProductKey.WebinarWorkplaceViolence,
            },
          },
          undefined,
          { force: true }
        );

        onResult(({ getCompanyCreditsV2: res }) => {
          allPurchases.value = res as Credit[];
        });

        unwatch?.();
      }
    },
    { immediate: true }
  );
};
const getCredits = () => {
  const { onResult: setMyProductsResult } =
    // weird type issues here ignoring for now

    useApolloQuery(
      // @ts-ignore
      useMyProductsQuery,
      undefined,
      { pending: isLoading },
      { force: true }
    );

  setMyProductsResult(({ myProducts }) => {
    credits.value = myProducts;
  });
};

const purchaseSubscriptionErrors = ref();

// resets the error initially
purchaseSubscriptionErrors.value = '';

const submitSubscriptionPurchase = async (
  // Mutation variables
  { productKey }: PurchaseSubscriptionInput,
  // event tracking variables
  { productName, amount }: { productName: string; amount: number }
) => {
  const { mutate: purchaseSubscription, onDone: onPurchaseSubscriptionDone } =
    useApolloMutation(usePurchaseSubscriptionMutation, {
      errors: purchaseSubscriptionErrors,
    });

  purchaseSubscriptionLoading.value = true;

  await purchaseSubscription({
    data: {
      productKey,
    },
  });

  getCredits();
  getPurchases();

  onPurchaseSubscriptionDone(({ purchaseSubscription: res }) => {
    if (res) {
      addSuccess('Subscription purchased');
    }
  });

  purchaseSubscriptionLoading.value = false;

  bam.track('subscription_offer_purchased', {
    // eslint-disable-next-line no-underscore-dangle
    companyId: currentUser.value?._company?._id,
    // eslint-disable-next-line no-underscore-dangle
    userId: currentUser.value?._id,
    sessionId: sessionId.value,
    offerName: productKey,
    offerType: PurchaseType.Single,
    offerTitle: productName,
    amount: parseHrRequestPricing(amount),
  });
};

const purchaseCreditsErrors = ref();

// resets the error initially
purchaseCreditsErrors.value = '';

const submitCreditPurchase = async (
  // Mutation variables
  { productKey, quantity }: PurchaseInput,
  // event tracking variables
  {
    productName,
    amount,
    unitPrice,
  }: { productName: string; amount: number; unitPrice: number }
) => {
  const { mutate: purchaseCredits, onDone: onPurchaseCreditsDone } =
    useApolloMutation(usePurchaseCreditsMutation, {
      errors: purchaseCreditsErrors,
    });

  purchaseCreditsLoading.value = true;

  await purchaseCredits({
    data: {
      productKey,
      quantity,
    },
  });

  getCredits();
  getPurchases();

  onPurchaseCreditsDone(({ purchaseCredits: res }) => {
    if (res) {
      addSuccess('Credits purchased');
    }
  });

  purchaseCreditsLoading.value = false;

  bam.track('offer_purchased', {
    // eslint-disable-next-line no-underscore-dangle
    companyId: currentUser.value?._company?._id,
    // eslint-disable-next-line no-underscore-dangle
    userId: currentUser.value?._id,
    sessionId: sessionId.value,
    offerName: productKey,
    // T-28 Update when we have subscription types
    offerType: PurchaseType.Single,
    offerTitle: productName,
    amount: `$${amount}`,
    quantity,
    unitPrice: `$${(unitPrice / 100).toFixed(2)}`,
  });
};

const submitPurchase = async (purchaseInput: PurchaseEvent) => {
  if (purchaseInput.type === PurchaseType.Single) {
    await submitCreditPurchase(
      {
        productKey: purchaseInput.productKey,
        quantity: purchaseInput.quantity || 1,
      },
      {
        amount: purchaseInput.amount,
        productName: purchaseInput.name,
        unitPrice: purchaseInput.price,
      }
    );
  } else {
    await submitSubscriptionPurchase(
      {
        productKey: purchaseInput.productKey,
      },
      {
        amount: purchaseInput.amount,
        productName: purchaseInput.name,
      }
    );
  }
};

const consumeCredits = (
  quantity: number,
  productKey: ProductKey,
  consumptionSubKey?: ProductSubKey
) => {
  const { companyId } = useCurrentCompany();
  const {
    mutate: consumeNectarCredits,
    onDone,
    loading,
  } = useApolloMutation(useConsumeNectarCreditsMutation);

  consumeNectarCredits({
    data: {
      companyId: companyId.value,
      consumptionDescription: 'APP',
      consumptionReason: ProductCreditConsumptionReason.Other,
      quantity,
      productKey,
      consumptionSubKey: consumptionSubKey ?? undefined,
    },
  });

  return {
    onDone,
    loading,
  };
};

watch(purchaseCreditsErrors, (errors) => {
  if (errors[0]) {
    if (errors[0].raw.message === 'Your card was declined.') {
      openModal();
    }
  }
});

watch(purchaseSubscriptionErrors, (errors) => {
  if (errors[0]) {
    if (errors[0].raw.message === 'Your card was declined.') {
      openModal();
    }
  }
});

const trackCreditConsumption = ({ productKey, productName, quantity = 1 }) => {
  bam.track('product_credit_consumed', {
    // eslint-disable-next-line no-underscore-dangle
    companyId: currentUser.value?._company?._id,
    // eslint-disable-next-line no-underscore-dangle
    userId: currentUser.value?._id,
    sessionId: sessionId.value,
    productKey,
    creditType: PurchaseType.Single,
    productName,
    quantity,
  });
};

const jobDescriptionProductInfo = computed(() =>
  getProductInformationByProductKey(ProductKey.JobDescription, credits.value)
);

const jobDescriptionAiProductInfo = computed(() =>
  getProductInformationByProductKey(ProductKey.JobDescriptionAi, credits.value)
);

const purchaseLoading = computed(
  () => purchaseSubscriptionLoading.value || purchaseCreditsLoading.value
);

let init;

const useCredits = (force = false) => {
  if (!init || force) {
    getCredits();
    getPurchases();
    init = true;
  }

  return {
    getCredits,
    submitCreditPurchase,
    trackCreditConsumption,
    submitSubscriptionPurchase,
    submitPurchase,
    purchaseLoading,
    purchaseCreditsLoading,
    jobDescriptionProductInfo,
    jobDescriptionAiProductInfo,
    all: readonly(credits),
    isLoading,
    consumeCredits,
    allPurchases,
  };
};

export default useCredits;
