interface MinimumObservable {
  subscribe: any;
}

export const toPromise = (
  observableFn: () => MinimumObservable
): Promise<any> => {
  let promiseResolver;
  const converted = new Promise((resolve) => {
    promiseResolver = resolve;
  });

  observableFn().subscribe((res) => {
    promiseResolver(res);
  });

  return converted;
};

export default {
  toPromise,
};
