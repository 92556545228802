export enum MilestoneIds {
  ABOUT = 'ABOUT',
  ONBOARDING_TERMINATIONS = 'ONBOARDING_TERMINATIONS',
  AUDITS = 'AUDITS',
  POLICIES = 'POLICIES',
  HRM = 'HRM',
  PAYROLL = 'PAYROLL',
  TRAINING = 'TRAINING',
  EE_FEEDBACK = 'EE_FEEDBACK',
  CABINET = 'CABINET',
  GET_BAMBEE = 'GET_BAMBEE',
  COMPANY_SETUP = 'COMPANY_SETUP',
}

export const LocalStorageKey = 'UNPAID_PROGRESS';

export default null;
