


























import {
  BaseButton,
  ModalDialog,
  TypeBody,
  TypeDisplay,
} from '@bambeehr/pollen';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'UpgradeDeclined',
  components: {
    ModalDialog,
    TypeBody,
    TypeDisplay,
    BaseButton,
  },
  setup() {
    return {};
  },
});
