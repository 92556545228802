import Vue from 'vue';
import Cleave from 'cleave.js';

export default () => {
  Vue.directive('focus', {
    inserted(el, binding) {
      if (el.tagName === 'INPUT') {
        el.focus();
      } else {
        el.querySelector('input').focus();
      }
    },
  });

  Vue.directive('shaky', {
    // When the bound element is inserted into the DOM...
    updated(el, binding) {
      if (binding.value) {
        const classes = element.className.split(' ');
        if (!classes.includes('shaky')) {
          element.className += ' shaky';
        }
      } else {
        const classes = element.className.split(' ');
        if (classes.includes('shaky')) {
          element.className = classes.filter((c) => c !== 'shaky').join(' ');
        }
      }
    },
  });
};

Vue.directive('cleave', (el, binding) => {
  const input = el.querySelector('input');
  new Cleave(input, binding ? binding.value : null);
});
