









import { defineComponent, computed, watch } from '@nuxtjs/composition-api';
import useDelinquency from '@/modules/Billing/hooks/useDelinquency';
import useContactUsContent from '@/modules/ContactUs/hooks/useContactUsContent';
import { format } from 'date-fns';
import { DelinquentStage } from '@/modules/Billing/hooks/useDelinquency.interfaces';
import bam from '@/lib/bam';
import DelinquentBanner, {
  BannerMessage,
  BannerTypes,
} from '@/components/banners/DelinquentBanner.vue';

export default defineComponent({
  components: {
    DelinquentBanner,
  },

  setup() {
    const { customerSupportPhoneNumber } = useContactUsContent();
    const {
      delinquent,
      delinquentStage,
      lockoutDate,
      isPayrollCustomer,
      isPayrollLocked,
      payrollLockoutDate,
      isAdmin,
      isCloseToCancellation,
      daysTillCancellation,
      cancellationDate,
    } = useDelinquency();

    const isDelinquent = computed<boolean>(
      () => !!delinquent.value?.delinquent
    );

    const isNewlyDelinquent = computed<boolean>(
      () => delinquentStage.value === DelinquentStage.NEW
    );

    const isLocked = computed<boolean>(
      () => delinquentStage.value === DelinquentStage.LOCKED
    );

    const bannerMessage = computed<BannerMessage>(() => {
      const dateFormatter = (date) => format(date, 'MMMM d');

      if (isNewlyDelinquent.value) {
        const formattedLockoutDate = lockoutDate.value
          ? dateFormatter(lockoutDate.value)
          : '';

        return {
          title: `We were unable to process your payment.`,
          body: `The charge for your subscription was declined. Update your payment method by ${formattedLockoutDate} to avoid interrupting access to your dedicated HR manager, essential HR services, compliant document retention${
            isPayrollCustomer.value ? `, and payroll` : ''
          }. Need assistance? Contact support ${
            customerSupportPhoneNumber.value
          }.`,
        };
      }

      if (isLocked.value) {
        const formattedCancellationDate = cancellationDate.value
          ? dateFormatter(cancellationDate.value)
          : '';

        const cancellationTitle = `Your account will be cancelled in ${daysTillCancellation.value} days.`;

        if (isPayrollCustomer.value) {
          const formattedPayrollLockoutDate = payrollLockoutDate.value
            ? dateFormatter(payrollLockoutDate.value)
            : '';

          return isPayrollLocked.value
            ? {
                title: cancellationTitle,
                body: `The charge for your subscription was declined. Update your payment method by ${formattedCancellationDate} to resume access to your dedicated HR manager, essential HR services, company’s important HR data, and running payroll. Need assistance? Contact support ${customerSupportPhoneNumber.value}.`,
              }
            : {
                title: `Your Bambee account has been restricted.`,
                body: `The charge for your subscription was declined. You are no longer able to access your dedicated HR manager, essential HR services, and compliant document retention. Update your payment method by ${formattedPayrollLockoutDate} to retain access to running on-time payrolls. Need assistance? Contact support ${customerSupportPhoneNumber.value}.`,
              };
        }

        return {
          title: isCloseToCancellation.value
            ? cancellationTitle
            : `Your Bambee account has been locked.`,
          body: `The charge for your subscription was declined. Update your payment method by ${formattedCancellationDate} to resume access to your dedicated HR manager, essential HR services, and company’s important HR data. Need assistance? Contact support ${customerSupportPhoneNumber.value}.`,
        };
      }

      return {};
    });

    const showBanner = computed<boolean>(
      () => isAdmin.value && isDelinquent.value
    );

    const bannerType = computed<string>(() =>
      isNewlyDelinquent.value ? BannerTypes.WARNING : BannerTypes.ERROR
    );

    const buttonVariant = computed<string>(() =>
      isNewlyDelinquent.value ? 'inverted-primary' : 'inverted-tertiary'
    );

    watch(showBanner, (show) => {
      if (show) {
        bam.track('global-delinquent-banner-shown', {
          newlyDelinquent: isNewlyDelinquent,
          closeToCancellation: isCloseToCancellation,
          locked: isLocked,
          payroll: isPayrollCustomer,
          payrollLocked: isPayrollLocked,
        });
      }
    });

    return {
      bannerMessage,
      showBanner,
      bannerType,
      buttonVariant,
    };
  },
});
