












































































import { TextInput, BaseDivider, AnnularThrobber } from '@bambeehr/pollen';
import TrainingListItem from './TrainingListItem.vue';
import useEmployerTrainingList from '@/modules/Training/hooks/useEmployerTrainingList/useEmployerTrainingList';
import useAddWorkerTraining from './useAddWorkerTraining';
import { computed } from '@nuxtjs/composition-api';

interface DisabledCourses {
  id: string;
  name: string;
  bundleId?: string;
  topicId?: string;
}

export function getDisabledCourses(selectedTraining, mergedTrainingList) {
  const disabledCourses: DisabledCourses[] = [];
  selectedTraining.forEach((selectedCourse) => {
    if (selectedCourse.bundleId) {
      // if a bundle is selected then find that bundle in the mergedTrainingList and push all the topics in that bundle to the disabledCourses array
      const bundle = mergedTrainingList.find(
        (course) => course.id === selectedCourse.bundleId
      );

      disabledCourses.push(...(bundle?.topics ?? []));
    } else {
      // if a topic is selected then loop through the bundles in the mergedTrainingList and check if the topic is in the bundle
      // if it is then push the bundle to the disabledCourses array
      const topic = mergedTrainingList.find((course) =>
        course?.topics?.find((topic) => topic.id === selectedCourse.topicId)
      );

      if (topic) {
        disabledCourses.push(topic);
      }
    }
  });

  return disabledCourses;
}

export default {
  components: {
    BaseDivider,
    TextInput,
    AnnularThrobber,
    TrainingListItem,
  },
  setup() {
    const {
      availableAndPurchasedTrainingLists,
      isFetching,
      updateSearchQuery,
      searchQuery,
    } = useEmployerTrainingList();

    const {
      handleTrainingCourseSelected,
      handleTrainingCourseUnselected,
      selectedTrainingCourses,
    } = useAddWorkerTraining();

    // merge the recommended and all training lists
    // used to check against the selected training courses to disable the correct courses
    const mergedTrainingList = computed(() => [
      ...availableAndPurchasedTrainingLists.value.all,
      ...availableAndPurchasedTrainingLists.value.recommended,
    ]);

    const disabledTraining = computed(() =>
      getDisabledCourses(
        selectedTrainingCourses.value,
        mergedTrainingList.value
      )
    );

    const shouldDisable = (course) =>
      disabledTraining.value.map((course) => course?.id).includes(course.id);

    return {
      availableAndPurchasedTrainingLists,
      searchQuery,
      isFetching,
      shouldDisable,
      selectedTrainingCourses,
      updateSearchQuery,
      handleTrainingCourseUnselected,
      handleTrainingCourseSelected,
    };
  },
};
