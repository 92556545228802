<template>
  <DashboardTile @click="handleProgressBarClick">
    <template #heading>
      Payroll for {{ formatDateStr(payrollStartDate) }} -
      {{ formatDateStr(payrollEndDate) }}
    </template>

    {{ currency(totalGross).format() }} - {{ staffCount }} {{ workerText }}

    <template #secondary>
      <div
        class="progress-status md:w-1/2 flex flex-row justify-start items-center"
      >
        <div class="progress-status__icon h-6 w-6 mr-2">
          <BaseIcon
            icon="checkCircleSolid"
            class="h-6 w-6 text-secondary-default"
          />
        </div>
        <TypeBody
          tag="div"
          class="progress-status__message text-secondary-default"
          variant="link-x-small-tight"
          weight="strong"
        >
          {{ paidLabel }} on {{ paidDate }}
        </TypeBody>
      </div>
    </template>
  </DashboardTile>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { BaseIcon, TypeBody } from '@bambeehr/pollen';
import currency from '@bambeehr/currency';
import { formatDate } from '@/utils/date';
import DashboardTile from '@/modules/payroll/components/DashboardTile/DashboardTile';
import { PayrollStatus } from '@/modules/payroll/constants/payroll';

export default {
  name: 'PayrollTile',
  components: {
    DashboardTile,
    TypeBody,
    BaseIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    totalGross: {
      type: Number,
      required: true,
    },
    staffCount: {
      type: Number,
      required: true,
    },
    payrollStatusDate: {
      type: [Date, String],
      required: false,
      default: null,
    },
    payrollStartDate: {
      type: [Date, String],
      required: true,
    },
    payrollEndDate: {
      type: [Date, String],
      required: true,
    },
    payrollStatus: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const paidDate = formatDate(props.payrollStatusDate);
    const paidLabel = computed(() =>
      props.payrollStatus === PayrollStatus.PARTIALLY_PAID
        ? 'Partially Paid'
        : 'Paid'
    );
    const workerText = props.staffCount === 1 ? 'Worker' : 'Workers';

    function handleProgressBarClick() {
      emit('click', props.id);
    }

    function formatDateStr(date) {
      return formatDate(date);
    }

    return {
      currency,
      formatDateStr,
      handleProgressBarClick,
      paidDate,
      paidLabel,
      workerText,
    };
  },
};
</script>
