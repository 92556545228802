


















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { DataTable, UserAvatar, BaseButton, BaseTag } from '@bambeehr/pollen';
import { formatCurrency } from '@/utils/currency';
import { PayTypes } from '@/modules/payroll/constants/payroll';
import FlashingText from '@/components/FlashingText';
import { getUserPreference } from '@/utils/userPreference';
import { EmployeeOnboardingSteps } from '@/gql/generated';
import { CheckOnboardingStatus } from '../../constants/company';

export const getStatusBadge = (
  isMissingSocial: boolean,
  hasDeletedWorkerRole: boolean,
  isMissingAddress: boolean,
  isBlocking: boolean
) => {
  const config = {
    component: BaseTag,
    label: '',
    attrs: {
      light: true,
      size: 'small',
      class: 'cursor-default',
    },
  };

  if (isMissingAddress) {
    const label = 'Missing Address';

    return {
      ...config,
      label,
      attrs: {
        ...config.attrs,
        label,
        color: 'tangerine',
        title: `You ${
          isBlocking ? 'will' : 'may'
        } not be able to pay workers that are missing an address.`,
      },
    };
  }

  if (isMissingSocial) {
    const label = 'Missing SSN';

    return {
      ...config,
      label,
      attrs: {
        ...config.attrs,
        label,
        color: isBlocking ? 'tangerine' : 'gingerrr',
        title: `You ${
          isBlocking ? 'will' : 'may'
        } not be able to pay workers with missing social security numbers.`,
      },
    };
  }

  if (hasDeletedWorkerRole) {
    const label = 'Updated Role Info';

    return {
      ...config,
      label,
      attrs: {
        ...config.attrs,
        label,
        color: 'inevitable',
        title: `This worker has earnings related to a role that has been updated. Please confirm these earnings.`,
      },
    };
  }

  return config;
};

const filters = [
  {
    id: 'SALARY',
    label: 'Salary',
    method: (item) => item.meta.isSalary,
  },
  {
    id: 'HOURLY',
    label: 'Hourly',
    method: (item) => !item.meta.isSalary && !item.meta.isContractor,
  },
  {
    id: 'CONTRACTOR',
    label: 'Contractors',
    method: (item) => item.meta.isContractor,
  },
  {
    id: 'MISSING_INFO',
    label: 'Missing Info',
    method: (item) => item.meta.isMissingSocial || item.meta.isMissingAddress,
  },
  {
    id: 'DELETED_ROLES',
    label: 'Needs Confirmation',
    method: (item) => item.meta.hasDeletedWorkerRole,
  },
];

const header = {
  worker: {
    label: 'Worker',
    sortable: true,
    priority: true,
    allowOverflow: true,
  },
  name: {
    sortable: true,
    label: 'Worker',
    visible: false,
    overflowText: true,
  },
  workerType: {
    label: 'Type',
    sortable: true,
    visible: true,
    width: 70,
  },
  status: {
    label: 'Issues',
    sortable: true,
    allowOverflow: true,
    alignment: 'center',
    visible: true,
  },
  totalEarnings: {
    label: 'Earnings',
    width: 100,
  },
  actions: {
    label: '',
    width: 90,
  },
};

const getDeletedRoleStatus = (item, payrollId) =>
  item.hasDeletedWorkerRole &&
  !getUserPreference(`made-selection:${payrollId}-${item.id}`);

export const mapItem = (payItem, emit) => {
  if (!payItem) {
    return {};
  }

  const isContractor = !!payItem.contractor;
  const entityInfo = isContractor ? payItem.contractor : payItem.employee;

  const hasDeletedWorkerRole = getDeletedRoleStatus(
    payItem,
    payItem.payroll.id
  );

  const { firstName, lastName, title, contractorBusinessName } =
    entityInfo.profile;

  const name =
    isContractor && !!contractorBusinessName
      ? contractorBusinessName
      : `${firstName} ${lastName}`;

  const isBlocking =
    entityInfo?.checkOnboarding?.status === CheckOnboardingStatus.BLOCKING;

  const isMissingSocial = entityInfo?.remainingOnboardingSteps?.includes(
    EmployeeOnboardingSteps.Ssn
  );

  const isMissingAddress = entityInfo?.remainingOnboardingSteps?.includes(
    EmployeeOnboardingSteps.EmployeeDetails
  );

  const isSalary =
    !isContractor && entityInfo.payType.toUpperCase() === PayTypes.SALARY;

  const workerType = (() => {
    if (isContractor) {
      return 'Contractor';
    }

    if (isSalary) {
      return 'Salary';
    }

    return 'Hourly';
  })();

  const status = getStatusBadge(
    isMissingSocial,
    hasDeletedWorkerRole,
    isMissingAddress,
    isBlocking
  );

  return {
    id: payItem.id,
    meta: {
      isContractor,
      isSalary,
      isMissingSocial,
      isMissingAddress,
      hasDeletedWorkerRole,
    },
    worker: {
      component: UserAvatar,
      label: `${name}, ${title}`,
      attrs: {
        size: 'large',
        name,
        title,
      },
    },
    name,
    workerType,
    status: status.label ? status : '',
    totalEarnings: {
      component: FlashingText,
      attrs: {
        displayText: formatCurrency(payItem.grossPay, 2),
        class: 'font-bold',
      },
    },
    actions: {
      component: BaseButton,
      attrs: {
        label: 'Edit Pay',
        variant: 'inverted-primary',
        size: 'small',
        id: `edit-pay-btn`,
      },
      handlers: {
        click() {
          const emitInfo = {
            id: payItem.id,
            isContractor,
          };

          emit('edit-pay', emitInfo);
        },
      },
    },
  };
};

export const getHeaderConfig = (header, screen) => {
  switch (screen.breakpoint) {
    case 'xs':
    case 'sm':
      header.name.visible = true;

      header.workerType.visible = false;
      header.worker.visible = false;
      header.status.visible = false;
      break;

    case 'md':
    case 'lg':
      header.name.visible = true;
      header.workerType.visible = true;

      header.worker.visible = false;
      header.status.visible = false;

      break;

    case 'xl':
    case '2xl':
      header.workerType.visible = true;
      header.worker.visible = true;
      header.status.visible = true;

      header.name.visible = false;
      break;

    default:
      break;
  }

  return header;
};

const mapBody = (payroll, emit) => {
  if (!payroll) {
    return [];
  }

  const { items, contractorPayments } = payroll;

  return [
    // Bol filters to prevent issue from BAMBO-3870 causing FE errors
    ...items.filter(Boolean).map((i) => mapItem(i, emit)),
    ...contractorPayments.filter(Boolean).map((i) => mapItem(i, emit)),
  ];
};

export default defineComponent({
  name: 'RunPayItemsTable',
  components: {
    DataTable,
  },
  props: {
    payroll: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const tableBody = computed(() =>
      props.payroll ? mapBody(props.payroll, emit) : []
    );

    return {
      filters,
      tableBody,
    };
  },
  computed: {
    tableHeader() {
      // vue-screen isn't available in setup
      // Setting just the header config updates here
      // @ts-ignore, ignoring type check on this.$screen
      return getHeaderConfig(header, this.$screen);
    },
  },
});
