import Contentful from '@/services/Contentful';

const ENTRY_ID = 'Yw0WOxOMoGbtI723UK2gu';

let content = null;

const fetchContent = async () => {
  if (!content) {
    const response = await Contentful.getEntry(ENTRY_ID, {
      include: 10,
    });

    content = response.fields.content.reduce(
      (obj, { fields }) => ({
        ...obj,
        [fields.slug]: fields,
      }),
      {}
    );
  }

  return content;
};

export default fetchContent;
