import { defineStore } from 'pinia';

export const useAcknowledgments = defineStore('acknowledgments', {
  actions: {
    async acknowledgeAll({ ackIds }) {
      const res = this.$nuxt.$axios.post(`/v0/companies/v1/bulk-acknowledge`, {
        ackIds,
      });

      return res;
    },
  },
});

export default null;
