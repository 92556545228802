import { useApolloQuery } from '@/gql/apolloWrapper';
import {
  UpcomingNonCoreSubscriptionBillingChange,
  useGetNonCoreSubscriptionBillingChangesQuery,
} from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import isAdmin from '@/utils/isAdmin';
import { ref } from '@nuxtjs/composition-api';

const useUpcomingBillingChanges = (tier: number, force = false) => {
  const isLoading = ref(false);
  const upcomingBillingChanges = ref<
    UpcomingNonCoreSubscriptionBillingChange[]
  >([]);

  const { companyId } = useCurrentCompany();

  if (isAdmin.value) {
    const { onResult } = useApolloQuery(
      useGetNonCoreSubscriptionBillingChangesQuery,
      {
        data: {
          companyId: companyId.value,
          tierNumber: tier,
        },
      },
      { pending: isLoading },
      { force }
    );

    onResult(({ getNonCoreSubscriptionBillingChanges: res }) => {
      upcomingBillingChanges.value = res;
      isLoading.value = false;
    });
  }

  return {
    isLoading,
    upcomingBillingChanges,
  };
};

export default useUpcomingBillingChanges;
