// TODO: Deprecate in favor of usePolicies()

import Vue from 'vue';

const BASE_URL = process.env.API_URL;

function create(data) {
  return {
    _id: null,
    _employees: {},
    _company: {},
    _reference: {
      model: null,
      _data: null,
    },

    name: '',
    description: '',
    icon: '',

    steps: [],

    active: false,
    created_at: null,

    ...data,
  };
}

function set(state, payload) {
  const item = create(payload);

  if (!state._byId[item._id]) {
    state.ids.push(item._id);
  }

  Vue.set(state._byId, item._id, item);

  return item;
}

export const state = () => ({
  ids: [],
  _byId: {},
});

export const mutations = {
  set(state, payload) {
    set(state, payload);
  },
  setAll(state, payload) {
    payload.forEach((policy) => {
      const readyStep = policy.steps.find((step) => step.name === 'ready');
      if (readyStep && readyStep.complete) {
        policy.approver = readyStep._completedBy;
      }
      set(state, policy);
    });
  },
};

export const getters = {
  my(state) {
    return state.ids.map((i) => state._byId[i]);
  },

  // Used for TaskCenter - DocumentsAwaitingApproval component
  policiesAwaitingApproval(state, getters) {
    const policyStatuses = ['declined', 'sent'];

    return getters.my.filter((policy) =>
      policyStatuses.includes(policy.status)
    );
  },

  policiesAwaitingApprovalCount(state, getters) {
    return getters.policiesAwaitingApproval.length;
  },
};

let currentFetchingFn;

export const actions = {
  getMyPoliciesSimplified({ commit, state: policiesState }, force = false) {
    if (policiesState?.ids?.length && !force) {
      return Promise.resolve(state);
    }

    if (currentFetchingFn) {
      return currentFetchingFn;
    }

    currentFetchingFn = async () => {
      return this.$axios.get('/v0/app/my/policiesSimplified').then((res) => {
        return res.data;
      });
    };

    return currentFetchingFn().then((policies) => {
      currentFetchingFn = null;
      commit('setAll', policies);

      return policies;
    });
  },

  decline({ commit }, { note, policy_id: policyId }) {
    return this.$axios
      .post(`/v0/documents/v1/policy/${policyId}/lifecycle/reject`, { note })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  approve({ commit }, { policy_id: policyId }) {
    return this.$axios
      .post(`/v0/documents/v1/policy/${policyId}/lifecycle/approve`, null)
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },
};
