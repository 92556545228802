import { Address, CoreAddress } from '../../types/company';

export const getFullAddress = (address: Address): string => {
  if (!address) {
    return '';
  }

  const { line1, line2, city, state, zip } = address;

  return `${line1} ${line2 || ''} ${city}, ${state}${zip ? ',' : ''} ${zip}`
    .replace('  ', ' ')
    .trim();
};

export const mapToCoreAddress = ({
  line1: address,
  line2: address2,
  city,
  state,
  zip,
}: Address): CoreAddress => ({
  address,
  address2,
  city,
  state,
  zip,
});

export const mapFromCoreAddress = ({
  address: line1,
  address2: line2,
  city,
  state,
  zip,
}: CoreAddress): Address => ({
  line1,
  line2,
  city,
  state,
  zip,
});
