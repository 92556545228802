import { useAdvisorsStore } from '@/store/advisors';
import { setupPinia } from '@/store/pinia.helpers';
import type { Advisor } from '@/store/advisors';

export const mockData = [
  {
    id: '66aa9359d1e3812713cc947e',
    role: 'hrm',
    isPrimary: false,
    user: {
      id: '6074f9fcff346100cb984246',
      name: 'Ashley Meadows',
      avatarUrl: 'path/to/hrm/avatar.png',
      yearsExperience: '',
      calendlySlug: 'qa1bambee',
    },
    previous: {
      id: '61fbf79588f5ed5984f15a9c',
      name: 'Bambee Customer Support',
      avatarUrl: '',
      yearsExperience: '',
      calendlySlug: '',
    },
  },
  {
    id: '66aa935fd1e3812713cc9491',
    role: 'ae',
    isPrimary: false,
    user: {
      id: '61fbf79588f5ed5984f15a9c',
      name: 'Bambee Customer Support',
      avatarUrl: '',
      yearsExperience: '',
      calendlySlug: '',
    },
    previous: {
      id: '6074f9fcff346100cb984246',
      name: 'Ashley Meadows',
      avatarUrl: 'https://cdn.filestackcontent.com/CdoRAs1NRWeIoNVPg7cO',
      yearsExperience: '',
      calendlySlug: 'qa1bambee',
    },
  },
  {
    id: '65ca2e89bc4f52a166fdd4f4',
    role: 'oc',
    isPrimary: true,
    user: {
      id: '6074f9fcff346100cb984246',
      name: 'Ashley Meadows',
      avatarUrl: 'https://cdn.filestackcontent.com/CdoRAs1NRWeIoNVPg7cO',
      yearsExperience: '',
      calendlySlug: 'qa1bambee',
    },
    previous: {
      id: '60876184a524dbd22ccab7a3',
      name: 'payroll bambee',
      avatarUrl: '',
      yearsExperience: '',
      calendlySlug: 'qa2bambee',
    },
  },
  {
    id: '64d2c6e44cef0b268ff92165',
    role: 'bm',
    isPrimary: false,
    user: {
      id: '6074f9fcff346100cb984246',
      name: 'Ashley Meadows',
      avatarUrl: 'https://cdn.filestackcontent.com/CdoRAs1NRWeIoNVPg7cO',
      yearsExperience: '',
      calendlySlug: 'qa1bambee',
    },
    previous: null,
  },
  {
    id: '66aa9354d1e3812713cc946b',
    role: 'hrc',
    isPrimary: false,
    user: {
      id: '61fbf79588f5ed5984f15a9c',
      name: 'Bambee Customer Support',
      avatarUrl: '',
      yearsExperience: '',
      calendlySlug: '',
    },
    previous: {
      id: '6074f9fcff346100cb984246',
      name: 'Ashley Meadows',
      avatarUrl: 'https://cdn.filestackcontent.com/CdoRAs1NRWeIoNVPg7cO',
      yearsExperience: '',
      calendlySlug: 'qa1bambee',
    },
  },
] as Advisor[];

export const mockAdvisors = (data: Advisor[] = mockData) => {
  setupPinia();

  const { setAdvisors } = useAdvisorsStore();
  setAdvisors(data as Advisor[]);

  return useAdvisorsStore();
};

export const stubAdvisors = (data: Advisor[] = mockData) => {
  setupPinia();

  const mock = jest.mock('@/store/advisors', () => () => ({
    useAdvisorsStore: () => ({
      getAdvisors: jest.fn().mockReturnValue(data),
    }),
  }));

  return mock;
};
