import launchDarkly from '@bambeehr/vue-launch-darkly';
import wait from '@/utils/wait';

const maxTotalAttemptTime = 750; // in ms
const maxTotalRetries = 20;

// Sometimes there are race-conditions when trying to evaluate flags from launch darkly
// Usually you'll see this during a cold start of a page that has route guards looking for LD values
// As a utility, this allows flags to be retrieved
// If there are no flags registered (typically the case when LD hasn't hydrated), retry a few times, each time on a delay
const getFeatureFlags = async (attempts = 1) => {
  const flags = launchDarkly.getFlags();

  // If there are no flags returned, try again
  if (!Object.keys(flags).length && attempts <= maxTotalRetries) {
    await wait(maxTotalAttemptTime / maxTotalRetries); // Pause

    return getFeatureFlags(attempts + 1);
  }

  return flags;
};

/**
 * Fetches a flag from Launch Darkly by name. Retries with a delay if launch darkly data hasn't been made available yet
 * This is particularly helpful when looking for LD values within a route guard, where the retries and delay help to avoid potential race conditions
 * @param {String} flagKey name of the Launch Darkly flag to look up
 * @returns Promise - Value of the launch darkly flag
 */
export const getLaunchDarklyFlag = async (flagKey) => {
  const flags = await getFeatureFlags();

  return flags[flagKey];
};

export default null;
