

































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import ScheduleBenefitsCallModal from '@/modules/Benefits/components/ScheduleBenefitsCallModal';
import CalendlyEvent from '@/components/scheduler/CalendlyEvent';
import BenefitsExample from './imgs/BenefitsExample';
import useContentful from '@/hooks/useContentful/useContentful';
import {
  BaseCard,
  TypeDisplay,
  TypeBody,
  BaseIcon,
  BaseButton,
} from '@bambeehr/pollen';
import { getUserPreference, setUserPreference } from '@/utils/userPreference';
import LocalStorageKey from '@/modules/Benefits/const/localStorageKey';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useNotification from '@bambeehr/use-notifications';

const productBenefits = [
  'Attract and retain employees',
  'Consolidate HR, payroll, and benefits in a single platform',
  'A team of experts ready to help you find your perfect plan',
];

const getScheduledMeetingId = (companyId) =>
  getUserPreference(`${LocalStorageKey.BENEFITS_OVERVIEW}-${companyId}`) || '';

const CONTENT_ID = '2hVfNsi7NPkr5Vda8TkP2D';

export default defineComponent({
  name: 'BenefitsMarketingCard',
  components: {
    BenefitsExample,
    BaseButton,
    BaseCard,
    BaseIcon,
    CalendlyEvent,
    ScheduleBenefitsCallModal,
    TypeBody,
    TypeDisplay,
  },
  setup() {
    const { addInfo } = useNotification();
    const { companyId } = useCurrentCompany();
    const showScheduleModal = ref(false);
    const benefitsMeetingId = ref(getScheduledMeetingId(companyId.value));

    const { fetchContent } = useContentful();
    const content = ref();

    (async () => {
      content.value = await fetchContent(CONTENT_ID);
    })();

    const toggleScheduleModal = () => {
      showScheduleModal.value = !showScheduleModal.value;

      // Check if a new meeting has been set
      benefitsMeetingId.value = getScheduledMeetingId(companyId.value);
    };

    const handleCanceled = () => {
      benefitsMeetingId.value = '';
      setUserPreference(
        `${LocalStorageKey.BENEFITS_OVERVIEW}-${companyId.value}`,
        ''
      );
      addInfo(
        'Your previously scheduled meeting has been rescheduled or canceled.'
      );
    };

    return {
      benefitsMeetingId,
      content,
      handleCanceled,
      productBenefits,
      showScheduleModal,
      toggleScheduleModal,
    };
  },
});
