









import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import { Company } from '@/modules/payroll/types/company';
import {
  defineComponent,
  PropType,
  ref,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import {
  TransitionClass,
  overrideTransitionClass,
} from '@/components/WizardFlow/WizardFlow.vue';

export default defineComponent({
  name: 'PaySchedule',
  props: {
    companyId: {
      type: String as PropType<string>,
    },
  },
  beforeRouteEnter(to, _, next) {
    const { fullPath } = to;
    // Handling if user navigates to /payroll-setup/pay-schedule
    if (!fullPath.includes('create') && !fullPath.includes('preview')) {
      next('/payroll-setup/pay-schedule/create');
    }
    next();
  },
  setup(props) {
    const router = useRouter();
    const { getCompany } = usePayrollService();

    const company = ref<Company | null>(null);

    // Fetching / refreshing company data for sequenced events & navigation
    function getCompanyData(callBack?: Function) {
      getCompany(<string>props.companyId, {
        data: company,
      });

      watch(company, () => {
        if (callBack) {
          callBack();
        }
      });
    }

    getCompanyData();

    function updatedSchedule() {
      getCompanyData(() => {
        overrideTransitionClass.value = TransitionClass.FORWARDS;
        router.push('/payroll-setup/pay-schedule/preview');
      });
    }

    return {
      company,
      updatedSchedule,
    };
  },
});
