const DateFormat = Object.freeze({
  // Ex: 02/01/2020
  MM_DD_YYYY: 'MM/dd/yyyy',
  // Ex: Mon 02/01/2020
  WD_MM_DD_YYYY: 'E MM/dd/yyyy',
  // Ex: 02/01/20
  MM_DD_YY: 'MM/dd/yy',
  // Ex: 2020-02-01
  YYYY_MM_DD: 'yyyy-MM-dd',
  // Ex: Tuesday, December 21st, 2021
  EEEE_MMMM_DO_YYYYY: 'EEEE, MMMM do, yyyy',
  // Ex: Tuesday, December 21st
  EEEE_MMMM_DO: 'EEEE, MMMM do',
  // Ex: Tues, Dec 21st, 2021
  E_MMM_DO_YYYYY: 'E, MMM do, yyyy',
  // Ex: 5:40pm
  TIME: 'h:mmaaa',
  // Ex: Saturday
  DAY_OF_WEEK: 'EEEE',
  // Ex: 25
  DAY_OF_MONTH: 'd',
  // Ex: 25th
  DAY_OF_MONTH_EXT: 'do',
  // Ex: March
  MONTH: 'MMMM',
  // Ex: Jan
  MONTH_SHORT: 'MMM',
  MM_YY: 'MM/yy',
  // Ex: Monday 10:00am
  DAY_TIME: 'EEEE h:mmaaa',
  // Ex: Aug 24, 2024
  MMM_DD_YYYY: 'MMM dd, yyyy',
  // Ex: Aug 24
  MMM_DD: 'MMM dd',
  // Ex: 2024
  YEAR: 'yyyy',
});

export default DateFormat;
