const ROOT = 'managed-services';

export const managedServicesEvents = {
  PAGE_VIEW: `${ROOT}-page-view`,
  TALK_TO_EXPERT_MODAL_LAUNCHED: `${ROOT}-talk-to-expert-modal-launched`,
  TALK_TO_EXPERT_MODAL_CLOSED: `${ROOT}-talk-to-expert-modal-closed`,
  TALK_TO_EXPERT_MODAL_SCHEDULED: `${ROOT}-talk-to-expert-call-scheduled`,
  NOTIFICATION_REQUESTED: `${ROOT}-notification-requested`,
  SCHEDULE_SERVICE_MODAL_LAUNCHED: `${ROOT}-schedule-service-modal-launched`,
  SCHEDULE_SERVICE_MODAL_SCHEDULED: `${ROOT}-schedule-service-modal-scheduled`,
  PURCHASE_SUBSCRIPTION_MODAL_LAUNCHED: `${ROOT}-purchase-subscription-modal-launched`,
  PURCHASED_SUBSCRIPTION: `${ROOT}-purchase-subscription-modal-purchased`,
};

export default null;
