import { FeedModels } from '@/constants/feeds';
import Store from '@/constants/Store';
import { isAssignedToUser } from '@/utils/documents';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import Vue from 'vue';
import { ModelNames as CapModelNames } from '../lib/globals/capTypes';
import useCapsStore from '@/store/caps';

export const state = () => ({
  feeds: {},
  feedIdsByUserId: {},
});

const getUserId = (state) => {
  return get(state, 'current_user._id');
};

export const mutations = {
  setFeeds(state, { userId, feeds }) {
    const newFeeds = keyBy(feeds, '_id');
    const userFeedIds = Object.keys(newFeeds);

    state.feeds = {
      ...state.feeds,
      ...newFeeds,
    };

    Vue.set(state.feedIdsByUserId, userId, userFeedIds);
  },

  updateFeedData(state, { feedId, data }) {
    if (state.feeds[feedId]) {
      Vue.set(state.feeds[feedId], '_data', data);
    }
  },

  removeFeeds(state, { feedIds, userId }) {
    const newFeeds = state.feedIdsByUserId[userId].filter(
      (feedId) => !feedIds.includes(feedId)
    );

    Vue.set(state.feedIdsByUserId, userId, newFeeds);
  },
};

export const getters = {
  feedsForUserInScope(state, getters, rootState) {
    const userId = getUserId(rootState);

    if (!state.feedIdsByUserId[userId]) {
      return [];
    }

    return state.feedIdsByUserId[userId]
      .map((feedId) => state.feeds[feedId])
      .filter((feed) => feed._data);
  },

  totalFeedsCount:
    (state, getters, rootState, rootGetters) =>
    (includeLegacyMilestones = false) => {
      return (
        getters.acknowledgementDocumentCount +
        getters.unsignedDocumentCount +
        rootGetters['caps/capsCount'] +
        rootGetters['policies/policiesAwaitingApprovalCount'] +
        (includeLegacyMilestones
          ? rootGetters['milestones/accountSetupMilestonesIncompleteCount']
          : 0)
      );
    },

  acknowledgements(state, getters) {
    return getters.feedsForUserInScope.filter(
      (feed) => feed.model === FeedModels.ACKNOWLEDGEMENT
    );
  },

  documents(state, getters) {
    return getters.feedsForUserInScope.filter(
      (feed) => feed.model === FeedModels.DOCUMENT
    );
  },

  employeeProfileFeeds(state, getters) {
    return getters.feedsForUserInScope.filter(
      (feed) =>
        feed.model !== FeedModels.DOCUMENT &&
        feed.model !== FeedModels.ACKNOWLEDGEMENT
    );
  },

  // Beta task center release
  acknowledgementDocumentCount(state, getters) {
    return getters.acknowledgements.reduce(
      (arr, notice) => [...arr, ...notice._data.files],
      []
    ).length;
  },

  unsignedDocumentCount(state, getters, rootState) {
    const userId = get(rootState, 'current_user._id');

    return getters.documents.filter((d) => {
      const { signed: employeeSigned, manager_signed: managerSigned } = d._data;
      const isEmployee = isAssignedToUser(d._data, userId);

      return isEmployee ? !employeeSigned : !managerSigned;
    }).length;
  },
};

export const actions = {
  fetchFeeds({ dispatch, rootGetters }) {
    const calls = [];

    // Get employees data if owner/admin
    // Need to make these additional calls to refresh Counter (# of tasks to do) in left-navigation
    if (rootGetters[Store.auth.Getters.IS_COMPANY_ADMIN]) {
      calls.push(dispatch('caps/fetchCAPs', null, { root: true }));
      calls.push(
        dispatch('policies/getMyPoliciesSimplified', null, { root: true })
      );
    }

    return Promise.all(calls);
  },

  /**
   * @deprecated
   *
   * Supports db feeds entities
   * TODO: switch to logical feed entity
   */
  async getFeeds({ commit, rootGetters, rootState }) {
    const userId = getUserId(rootState);

    try {
      const res = await this.$axios.get('/v0/app/my/feed');

      const feedsWithoutCaps = res.data.filter(
        (feed) => !CapModelNames.includes(feed.model)
      );

      commit('setFeeds', {
        userId,
        feeds: feedsWithoutCaps,
      });

      if (rootGetters[Store.auth.Getters.IS_EMPLOYEE]) {
        const capFeeds = res.data.filter((feed) =>
          CapModelNames.includes(feed.model)
        );

        useCapsStore().addCAPs({
          userId,
          capFeeds,
        });
      }

      const termination = (
        feedsWithoutCaps.find(
          (feed) => feed.model === FeedModels.TERMINATION
        ) || {}
      )._data;

      if (typeof termination !== 'undefined') {
        commit('termination/saveTermination', termination, { root: true });
      }

      return res.data;
    } catch (e) {
      return e;
    }
  },

  updateFeedData({ commit, getters }, { data }) {
    const feed = getters.feedsForUserInScope.find(
      (f) => f._data._id === data._id
    );

    if (feed) {
      commit('updateFeedData', {
        feedId: feed._id,
        data,
      });
    }
  },

  acknowledgeAll({ rootGetters: { companyId } }, { ackIds }) {
    return this.$axios.post(
      `/v0/companies/v1/company/${companyId}/bulk-acknowledge`,
      { ackIds }
    );
  },
};
