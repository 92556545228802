// Manages cache state of the guard

let hasRolePermissions = false;
export const getHasRolePermissions = () => hasRolePermissions;
export const setHasRolePermissions = (has = false) => {
  hasRolePermissions = has;
};

let hasValidCompanyStatus = false;
export const getHasValidCompanyStatus = () => hasValidCompanyStatus;
export const setHasValidCompanyStatus = (has = false) => {
  hasValidCompanyStatus = has;
};

export default null;
