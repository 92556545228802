




























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'GlowButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    glow: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: false,
    },
    hoverAnimate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
});
