







import { defineComponent } from '@nuxtjs/composition-api';
import ActionPendingModal from '@/components/ActionPendingModal/ActionPendingModal';

export default defineComponent({
  name: 'SavingImportEarningsModal',
  components: {
    ActionPendingModal,
  },
});
