import currency from '@bambeehr/currency';

/**
 * Takes a number or string value and strips any non-number or decimal point
 * characters.
 * @param {Number|String} val
 * @return {String}
 */

export const currencyConvert = (val) => {
  console.warn(`Deprecated in favor of currency().value`);

  return val ? `${val}`.replace(/[^0-9\-.]+/g, '') : '';
};

/**
 * Takes a number and returns a USA formatted currency string.
 * Ex: 10 => '$10.00'
 * @param {Number|String} val
 * @return {String}
 */

export const toCurrency = (val) => {
  console.warn(`Deprecated in favor of currency().format(). Please upgrade.`);

  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
  }).format(val);
};

/**
 * Returns true if passed value can be parsed as a valid number and is not less
 * than 0.
 * @param {Number|String} val
 * @return {Boolean}
 */
export const currencyValidator = (val) => {
  console.warn(`Deprecated in favor of currency(). Please upgrade.`);
  const num = currencyConvert(val);

  // @ts-ignore
  return !(Number.isNaN(parseInt(num, 10)) || num < 0);
};

/**
 * Format a Number into currency with the format "$xxx,xxx.xx"
 * @param {Number} val
 * @return {String}
 */
export const formatCurrency = (price, decimals = 0) => {
  return currency(price).format({ precision: decimals });
};

/**
 *
 * @param {Number} amount
 * @returns {String}
 */
export const convertToDollars = (amount) => {
  if (!amount) {
    return '$0.00';
  }

  return formatCurrency(amount / 100, 2);
};
