































































































































import {
  ref,
  computed,
  defineComponent,
  useStore,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import ContentfulEntryIDs from '@/lib/globals/ContentfulEntryIDs';
import Contentful from '@/services/Contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import {
  BaseButton,
  TypeDisplay,
  TypeBody,
  BaseCard,
  SelectInput,
  BaseIcon,
} from '@bambeehr/pollen';
import WizardFlowFooter from '@/components/WizardFlow/WizardFlowFooter.vue';
import BambeeAndFinchLogo from '@/modules/payroll/components/BambeeAndFinchLogo/BambeeAndFinchLogo.vue';
import { useFinchManualSetupCompletedMutation } from '@/gql/generated';
import PublicClient from '@/gql/apolloClient';

interface ContentMap {
  intro: string;
  stepsToAuth: any;
}

interface Content {
  fields: ContentMap;
}

enum ProvidersWithAccountant {
  GUSTO = 'GUSTO',
  QUICKBOOKS = 'QUICKBOOKS',
  ADP_WORKFORCE = 'ADP_WORKFORCE_NOW',
  ZENEFITS = 'ZENEFITS',
  TRINET = 'TRINET',
  JUSTWORKS = 'JUSTWORKS',
}

enum ProvidersNoAccountant {
  ADP_RUN = 'ADP_RUN',
  PAYCHEX = 'PAYCHEX',
  BAMBOO_HR = 'BAMBOO_HR',
  INSPERITY = 'INSPERITY',
  NAMELY = 'NAMELY',
  PAYLOCITY = 'PAYLOCITY',
  SQUARE = 'SQUARE',
  PAYCOR = 'PAYCOR',
}

const providerOptions = [
  {
    label: 'Gusto',
    value: ProvidersWithAccountant.GUSTO,
    automated: true,
  },
  {
    label: 'Quickbooks Payroll',
    value: ProvidersWithAccountant.QUICKBOOKS,
    automated: true,
  },
  {
    label: 'ADP Workforce Now',
    value: ProvidersWithAccountant.ADP_WORKFORCE,
    automated: true,
  },
  {
    label: 'Zenefits',
    value: ProvidersWithAccountant.ZENEFITS,
    automated: true,
  },
  {
    label: 'Trinet',
    value: ProvidersWithAccountant.TRINET,
    automated: true,
  },
  {
    label: 'Justworks',
    value: ProvidersWithAccountant.JUSTWORKS,
    automated: true,
  },
  {
    label: 'ADP Run',
    value: ProvidersNoAccountant.ADP_RUN,
    automated: false,
  },
  {
    label: 'Paychex Flex',
    value: ProvidersNoAccountant.PAYCHEX,
    automated: false,
  },
  {
    label: 'BambooHR',
    value: ProvidersNoAccountant.BAMBOO_HR,
    automated: false,
  },
  {
    label: 'Insperity',
    value: ProvidersNoAccountant.INSPERITY,
    automated: false,
  },
  {
    label: 'Namely',
    value: ProvidersNoAccountant.NAMELY,
    automated: false,
  },
  {
    label: 'Paylocity',
    value: ProvidersNoAccountant.PAYLOCITY,
    automated: false,
  },
  {
    label: 'Square Payroll',
    value: ProvidersNoAccountant.SQUARE,
    automated: false,
  },
  {
    label: 'Paycor',
    value: ProvidersNoAccountant.PAYCOR,
    automated: false,
  },
];

const getProviderCopy = async (providerType: string) => {
  const content = await Contentful.getEntry(
    ContentfulEntryIDs.FINCH_STEPS[providerType]
  );

  return content;
};

export default defineComponent({
  name: 'ConnectProvider',
  components: {
    WizardFlowFooter,
    BaseButton,
    TypeDisplay,
    TypeBody,
    BaseCard,
    BambeeAndFinchLogo,
    SelectInput,
    BaseIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const { currentUser } = store.getters;

    const selectedProvider = ref('');
    const providerCopy = ref<ContentMap | null>(null);

    const providerInfo = computed(() =>
      providerOptions.find((p) => p.value === selectedProvider.value)
    );

    const finchLink = computed(() =>
      // There are intentionally spaces in the below url because that's how Finch wants it :shrug:
      providerInfo.value
        ? `https://connect.tryfinch.com/authorize?payroll_provider=${providerInfo.value.value.toLowerCase()}&client_id=${
            process.env.FINCH_CLIENT_ID
          }&products=company directory employment individual payment pay_statement ssn&redirect_uri=${
            process.env.FINCH_AUTH_REDIRECT
          }`
        : ''
    );

    watch(providerInfo, async (info) => {
      if (info && !info.automated) {
        const copy = <Content>await getProviderCopy(info?.value);

        if (copy) {
          providerCopy.value = {
            intro: copy.fields.intro,
            stepsToAuth: documentToHtmlString(copy.fields.stepsToAuth),
          };
        }
      }
    });

    const { mutate: finchManualSetupCompleted, onDone } =
      // @ts-ignore
      useFinchManualSetupCompletedMutation();

    const handleButtonClick = () => {
      finchManualSetupCompleted({
        data: {
          companyName: currentUser._company.name,
          payrollProvider: selectedProvider.value,
        },
      });
    };

    onDone(() => router.push('/payroll-setup/provider-confirmation'));

    return {
      handleButtonClick,
      providerOptions,
      selectedProvider,
      providerCopy,
      providerInfo,
      finchLink,
    };
  },
  layout: 'wizard',
});
