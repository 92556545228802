









import { defineComponent } from '@nuxtjs/composition-api';
import WorkplaceListItem from '@/components/Workplaces/WorkplaceListItem.vue';
import useWorkplaces from './useWorkplaces';

export default defineComponent({
  name: 'WorkplaceList',
  components: {
    WorkplaceListItem,
  },
  setup() {
    const { sortedWorkplaces } = useWorkplaces();

    return { sortedWorkplaces };
  },
});
