




















































































import { BaseButton, BaseIcon, TypeDisplay, TypeBody } from '@bambeehr/pollen';
import { computed } from '@nuxtjs/composition-api';
import useCurrentUser from '@/hooks/useCurrentUser';
import Launch from '@/modules/StaffManagement/components/AddStaffWizard/imgs/Launch.vue';

export default {
  components: {
    TypeBody,
    TypeDisplay,
    Launch,
    BaseIcon,
    BaseButton,
  },
  props: {
    isTrainingEligible: {
      type: Boolean,
      required: true,
    },
    workerName: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { currentUser } = useCurrentUser();
    const companyName = computed(() => currentUser.value?._company?.name);

    return {
      companyName,
    };
  },
};
