































































































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import {
  BaseButton,
  BaseDivider,
  BaseIcon,
  TypeBody,
  ModalDialog,
} from '@bambeehr/pollen';
import useTierUpgradeNotice from './useTierUpgradeNotice';
import TierUpgradeNoticePanel from './TierUpgradeNoticePanel.vue';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';

const upgradePerks = Object.freeze({
  KEEP_HR_MANAGER: 'Keep your HR Manager',
  INCREASED_LEVEL_OF_SERVICE: 'An increased level of service',
  HR_EXPERTISE: 'HR expertise specifically for companies of your size',
});

export default defineComponent({
  name: 'TierUpgradeNoticeModal',
  components: {
    BaseButton,
    BaseDivider,
    BaseIcon,
    TypeBody,
    ModalDialog,
    TierUpgradeNoticePanel,
  },
  setup() {
    const {
      currentPlan,
      currentPlanIntervalPrice,
      planByEmployeeCount,
      planByEmployeeCountIntervalPrice,
      staffMembers,
      handleModalClose,
      isModalOpen,
      isTrial,
    } = useTierUpgradeNotice();
    const { currentPayrollTier: payrollType } = useCompanyBillingInfo();

    const currentTierNumber = computed(() => currentPlan.value.tierNumber);
    const upcomingTierNumber = computed(
      () => planByEmployeeCount.value.tierNumber
    );
    const currentMinSize = computed(() => currentPlan.value.minSize);
    const currentMaxSize = computed(() => currentPlan.value.maxSize);
    const upcomingMinSize = computed(() => planByEmployeeCount.value.minSize);
    const upcomingMaxSize = computed(() => planByEmployeeCount.value.maxSize);
    const currentEmployeeCount = computed(() => staffMembers.value.length);

    const perksUponUpgrade = computed(() => {
      // if the plan the user is upgrading from is a T0 they don't get the first perk - "Keep your HR Manager"
      if (currentPlan.value.tierNumber === 0) {
        return [
          upgradePerks.INCREASED_LEVEL_OF_SERVICE,
          upgradePerks.HR_EXPERTISE,
        ];
      }

      return [
        upgradePerks.KEEP_HR_MANAGER,
        upgradePerks.INCREASED_LEVEL_OF_SERVICE,
        upgradePerks.HR_EXPERTISE,
      ];
    });

    return {
      currentPlan,
      currentPlanIntervalPrice,
      planByEmployeeCount,
      planByEmployeeCountIntervalPrice,
      staffMembers,
      perksUponUpgrade,
      handleModalClose,
      isModalOpen,
      isTrial,
      payrollType,
      currentTierNumber,
      upcomingTierNumber,
      currentMinSize,
      currentMaxSize,
      upcomingMinSize,
      upcomingMaxSize,
      currentEmployeeCount,
    };
  },
});
