










































import { TypeDisplay, TypeBody, BaseIcon } from '@bambeehr/pollen';
import Aetna from '@/modules/Benefits/images/Aetna.vue';
import Anthem from '@/modules/Benefits/images/Anthem.vue';
import Cigna from '@/modules/Benefits/images/Cigna.vue';
import Humana from '@/modules/Benefits/images/Humana.vue';
import Kaiser from '@/modules/Benefits/images/Kaiser.vue';
import UnitedHealthcare from '@/modules/Benefits/images/UnitedHealthcare.vue';
import BambeePlusSimplyInsured from '@/modules/Benefits/images/BambeePlusSimplyInsured.vue';

const featureList = [
  'Attract and retain employees',
  'Consolidate HR, payroll, and benefits in a single platform',
  'A team of experts ready to help you find your perfect plan',
];

const providerList = [Anthem, Aetna, UnitedHealthcare, Kaiser, Cigna, Humana];

export default {
  name: 'BenefitsAdBanner',
  components: {
    Aetna,
    Anthem,
    BambeePlusSimplyInsured,
    BaseIcon,
    Cigna,
    Humana,
    Kaiser,
    TypeBody,
    TypeDisplay,
    UnitedHealthcare,
  },
  setup: () => ({
    featureList,
    providerList,
  }),
};
