<!-- eslint-disable vue/no-v-for-template-key-on-child -->
<template>
  <div>
    <!-- overflow-y-auto will make the sticky button container element at the botom -->
    <div
      class="drawer-forms flex flex-col h-full overflow-y-auto justify-between pb-8"
    >
      <div class="form-input-group flex flex-col">
        <!-- Dummy element to avoid immediate tabbing on Select Input when opening drawer-->
        <span tabindex="0" />
        <div class="mx-6 mt-6 flex">
          <RadioToggle
            v-model="timeInputPreference"
            class="w-full flex justify-between flex-grow"
            :options="inputPreferenceOptions"
          />
        </div>

        <div
          class="gross-pay flex flex-row mx-6 mt-4 items-center justify-between"
        >
          <TypeBody
            tag="div"
            variant="link-x-small-tight"
            weight="strong"
            class="text-gray-0"
          >
            Total Earnings
          </TypeBody>
          <TypeBody
            v-if="employeeGrossPay"
            tag="div"
            variant="link-x-small-tight"
            weight="strong"
          >
            {{ employeeGrossPay.formatted }}
          </TypeBody>
        </div>
        <div class="mx-6 my-4">
          <BaseBanner
            v-if="hasBulkUploadedEarnings"
            id="bulk-edit-banner"
            variant="explainer"
            :show-close="false"
          >
            To keep your worker's hours consistent, please update your time
            tracking service with any changes made here.
          </BaseBanner>
        </div>
        <div v-if="earningsHelperState" class="px-6">
          <BaseBanner @close="toggleEarningsHelper">
            <template>
              <span class="text-primary-default">
                Need help with earning types?
              </span>
            </template>
            <template #description>
              <div v-show="showEarningsHelperText">
                <div>
                  Learn about different earning types and how they impact tax
                  calculations and compliance.
                </div>

                <div class="mt-2 -ml-1">
                  <ExplainerTooltipModal entry-id="6g0NSUguiWOjQ2jwIPWGxl">
                    <BaseButton size="small" level="2">
                      Read the guide
                    </BaseButton>
                  </ExplainerTooltipModal>
                </div>
              </div>
            </template>
            <template #actions>
              <BaseLink class="outline-none" @click="toggleEarningsHelperText">
                {{ showEarningsHelperText ? 'Hide' : 'Show' }}
              </BaseLink>
            </template>
          </BaseBanner>
        </div>
        <div
          v-for="roleItem in roleItems"
          id="earnings-container"
          :key="roleItem.id"
          class="earnings-container mx-6 mt-4"
        >
          <div v-if="!isSalaryWorker && !hasOneRole" class="mb-4 flex gap-x-4">
            <SelectInput
              class="flex-grow"
              :value="roleItem.id"
              :show-reset="false"
              :options="roleOptions"
              placeholder="Role"
              @input="updateRoleType(roleItem, $event)"
            />
            <span
              class="remove-icon-button block text-base-500 cursor-pointer flex-grow-0"
            >
              <IconButton
                class="opacity-40 hover:opacity-100 transition-opacity w-6 h-10"
                icon="closeCircle"
                size="giant"
                variant="secondary"
                flat
                @click="handleRemoveGroup(roleItem)"
              />
            </span>
          </div>

          <div
            class="flex flex-col space-y-4 mb-4"
            :class="{
              'pl-4 border-l-2 border-base-400': !isSalaryWorker && !hasOneRole,
            }"
          >
            <EarningsBanners
              v-if="!!roleItem.deletedAt && roleItem.replacement"
              class="mb-4 pr-10"
              :deleted-role="roleItem"
              :new-role="roleItem.replacement"
              :worker-name="workerName"
              :pay-rate="roleItem.payRate"
              :has-made-pay-rate-selection="roleItem.hasMadeDeletedSelection"
              @update="handleDeletedRoleSelection(roleItem, $event)"
            />
            <BaseBanner
              v-if="roleItem.hasUpdatedLegacyEarning"
              variant="explainer"
              :show-close="false"
              message="The pay rate for this role has been updated since this payroll was drafted."
              description="Please confirm the pay rate and earnings for this role and save your changes."
            />
            <template v-for="(payItem, index) in roleItem.pay">
              <div
                :key="`${payItem.id}-${payItem.type}`"
                class="flex items-center"
              >
                <div class="grid grid-cols-2 gap-4 flex-grow mr-4">
                  <div class="earning-type-input">
                    <SelectInput
                      :value="payItem.type"
                      :show-reset="false"
                      :options="earningSelectOptions"
                      placeholder="Earning Type"
                      @input="updatePayType(payItem, $event)"
                    />
                  </div>

                  <template v-if="isHourlyType(payItem.type)">
                    <HoursMinutesInput
                      v-if="isHoursMinutesPreference"
                      :value="payItem.minutes"
                      :disabled="isPayInputpDisabled(payItem)"
                      @input="updatePayAmountByMinutes(payItem, $event)"
                    />
                    <NumberInput
                      v-else
                      :value="
                        payItem.decimal || minutesToDecimal(payItem.minutes)
                      "
                      :disabled="isPayInputpDisabled(payItem)"
                      @blur="updatePayAmountByDecimal(payItem, $event)"
                    />
                  </template>

                  <template v-if="isNonHourlyType(payItem.type)">
                    <TextInput
                      :value="
                        payItem.amount
                          ? currency(payItem.amount).format({ symbol: '' })
                          : ''
                      "
                      prefix="$"
                      placeholder="Amount"
                      :disabled="isPayInputpDisabled(payItem)"
                      @blur="
                        updateNonHourlyPayAmount(
                          payItem,
                          currency($event).value
                        )
                      "
                    />
                  </template>
                </div>

                <span
                  class="remove-icon-button block text-base-500 cursor-pointer"
                >
                  <IconButton
                    class="opacity-40 hover:opacity-100 transition-opacity w-6 h-10"
                    icon="closeCircle"
                    size="giant"
                    variant="secondary"
                    flat
                    @click="handleRemovePay(roleItem, payItem.id)"
                  />
                </span>
              </div>
              <div v-if="isBonusType(payItem.type)">
                <div class="-mt-2 pl-4 border-l-2 border-base-400">
                  <TypeBody
                    variant="link-x-small-tight"
                    weight="strong"
                    class="mb-4 text-base-800"
                  >
                    Select Bonus Type
                  </TypeBody>
                  <RadioGroup
                    :id="`bonus-type-${index}`"
                    :value="payItem.subtype"
                    class="mt-2"
                    :name="`bonus-type-${index}`"
                    :vertical="!$screen.sm"
                    :options="BonusOptions"
                    @input="updateBonusSubtype(payItem, $event)"
                  />
                  <TypeBody
                    variant="text-tiny-tight"
                    class="mt-4 text-base-600"
                    tag="div"
                  >
                    Learn the difference between
                    <ExplainerTooltipModal :entry-id="bonusTypeEntryId" />
                  </TypeBody>
                </div>
              </div>
            </template>
          </div>

          <div class="button-add-earnings w-full">
            <BaseButton
              flat
              :class="{ 'ml-6': !hasOneRole && !isSalaryWorker }"
              @click="handleAddEarning(roleItem)"
            >
              Add Earning Type
            </BaseButton>
          </div>
        </div>

        <div class="px-6 mt-6">
          <BaseButton
            v-if="!isSalaryWorker && !hasOneRole"
            variant="inverted-primary"
            size="large"
            block
            :disabled="!hasUnusedRoles"
            @click="handleAddRole()"
          >
            Add Role
          </BaseButton>
        </div>

        <div
          id="earnings-pay-method"
          class="pay-method flex flex-row mx-6 mt-6 py-6 border-b border-t border-base-400 items-center justify-between"
        >
          <div>
            <div class="flex flex-col">
              <TypeOverline
                variant="small-tight"
                tag="div"
                class="text-base-800 flex flex-col"
              >
                Pay Method
              </TypeOverline>

              <TypeBody
                v-if="payrollPayment.needsPaymentMethod"
                variant="text-x-small-tight"
                tag="div"
                class="text-base-600 mt-1"
              >
                Direct deposit not set up
              </TypeBody>

              <TypeBody
                v-if="payrollPayment.requestManualCheck"
                variant="text-x-small-tight"
                tag="div"
                class="text-base-600 mt-1"
              >
                Manual check requested
              </TypeBody>
            </div>
          </div>

          <div class="pay-method-input w-1/2">
            <SelectInput
              v-model="form.paymentMethod"
              :options="payrollPayment.options"
              :selectable="isSelectable"
              :show-reset="false"
            />
          </div>
        </div>
        <div id="earnings-note" class="note">
          <div class="note__header mx-6 mt-6 pb-4 items-center">
            <TypeOverline variant="small-tight" tag="div" class="text-gray-0">
              Note
            </TypeOverline>
          </div>
          <div class="note__input mx-6 pb-4">
            <TextareaInput
              v-model="form.note"
              :disabled="!canAddNote"
              :invalid="hasNoteErr"
              :error="noteErr"
              size="normal"
              :placeholder="notePlaceholder"
            />
          </div>
          <div class="mx-6 mb-6">
            <CheckboxInput
              v-model="form.noteVisible"
              :disabled="!canAddNote"
              variant="standard"
              size="dense"
              label="Visible to Worker"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- ACTIONS CONTAINER -->
    <div
      class="actions-container w-full py-4 px-6 bg-white border-t sticky bottom-0 border-base-400 mt-auto overflow-hidden flex items-center"
    >
      <BaseButton variant="secondary" size="large" @click="handleSaveEdits">
        Save Earnings
      </BaseButton>

      <BaseButton
        variant="tertiary"
        flat
        size="large"
        @click="handleDiscardEdits"
      >
        Discard
      </BaseButton>
    </div>
    <!-- End of actions-container -->
  </div>
  <!-- END OF CONTAINER -->
</template>

<script>
import ExplainerTooltipModal from '@/components/ExplainerTooltipModal';
import FeatureFlags from '@/constants/FeatureFlags';
import HoursMinutesInput from '@/modules/payroll/components/HoursMinutesInput/HoursMinutesInput';
import currency from '@bambeehr/currency';
import {
  BaseBanner,
  BaseButton,
  BaseLink,
  CheckboxInput,
  IconButton,
  NumberInput,
  RadioGroup,
  RadioToggle,
  SelectInput,
  TextareaInput,
  TextInput,
  TypeBody,
  TypeOverline,
} from '@bambeehr/pollen';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import { computed, ref, useStore, watch } from '@nuxtjs/composition-api';

import ceil from 'lodash/ceil';
import cloneDeep from 'lodash/cloneDeep';
import shortid from 'shortid';

import useState from '@/hooks/useState/useState';
import { ContentfulExplainerModalEntryIDs } from '@/lib/globals/ContentfulEntryIDs';
import EarningsBanners from '@/modules/payroll/components/EarningsDrawer/EarningsBanners';
import {
  AllOptions,
  ContractContractorTypes,
  ContractorTypes,
  EarningType,
  HourlyTypes,
  NonHourlyTypes,
  NPOTypes,
  ReligiousNPOTypes,
} from '@/modules/payroll/constants/earning-types';
import {
  PaymentMethod,
  PayTypes,
  UserPreference,
} from '@/modules/payroll/constants/payroll';
import { getUserPreference, setUserPreference } from '@/utils/userPreference';
import { calculateEarningAmount, grossEarnings } from '@bambeehr/payroll';
import { EarningSubType } from '@bambeehr/payroll/dist/constants/earning-types';
import useNotifications from '@bambeehr/use-notifications';

const bonusTypeEntryId = ContentfulExplainerModalEntryIDs.BONUS_TYPES;

const DefaultEarningValues = Object.freeze({
  amount: 0,
  description: null,
  altDescription: '',
  minutes: 0,
  subtype: null,
  type: 'DEFAULT',
});

const EarningSources = Object.freeze({
  MANUAL: 'MANUAL',
  UPLOAD: 'UPLOAD',
});

const PaymentMethodOptions = [
  PaymentMethod.MANUAL,
  PaymentMethod.DIRECT_DEPOSIT,
];

const BonusOptions = [
  EarningSubType.DISCRETIONARY,
  EarningSubType.NON_DISCRETIONARY,
  EarningSubType.GIFT,
];

export const InputPreference = Object.freeze({
  HOURS_MINUTES: 'HOURS_MINUTES',
  DECIMAL: 'DECIMAL',
});

const inputPreferenceOptions = [
  {
    value: InputPreference.HOURS_MINUTES,
    label: 'Hours & Minutes',
  },
  {
    value: InputPreference.DECIMAL,
    label: 'Decimal Hours',
  },
];

// 60 is for mins in an hour
export const minutesToDecimal = (minutes = 0) =>
  // Get the rounded up decimal portion of the hours
  ceil(minutes / 60, 2);
export const decimalToMinutes = (decimal = 0) =>
  // Get the whole number of hours * 60 to get the minutes
  Math.trunc(decimal) * 60 +
  // Get the decimal portion of the hours
  ceil((decimal % 1) * 60);

export const getRolePrefKey = (payrollId, workerRoleId) =>
  `use-role:${payrollId}-${workerRoleId}`;

export const groupRoleEarnings = (payrollId, roles, payItem, isContractor) => {
  const { pay } = payItem;
  const primaryRole = roles.find((r) => r.isPrimary);
  const hasNullRolesInPayItems = pay.some((p) => !p.workerRoleId && p.amount);
  const primaryRoleNotEqual = payItem?.basePayRate !== primaryRole?.payRate;

  return roles
    .map((role) => ({
      ...role,
      pay: pay
        .filter(
          (p) =>
            p.workerRoleId === role.id || (role.isPrimary && !p.workerRoleId)
        )
        // If the earning / payment doesn't have a workerRoleId, we'll set it to default here
        .map((p) => {
          let thisRole = role;

          if (role.isPrimary && !p.workerRoleId && primaryRole) {
            thisRole = primaryRole;
          }

          return {
            ...p,
            workerRoleId: thisRole?.id,
            description: isContractor
              ? undefined
              : thisRole?.companyRole?.title || '',
          };
        }),
      replacement: role.deletedAt
        ? roles.find(
            (r) =>
              r.companyRole.id === role.companyRole.id &&
              r.id !== role.id &&
              !r.deletedAt
          )
        : undefined,
      hasMadeDeletedSelection: role.deletedAt
        ? getUserPreference(getRolePrefKey(payrollId, role.id))
        : undefined,
      hasUpdatedLegacyEarning: hasNullRolesInPayItems && primaryRoleNotEqual,
    }))
    .filter((r) => !!r.pay.length);
};

export const ungroupRoleEarnings = (roleEarnings) =>
  roleEarnings.reduce(
    (acc, cur) => [
      ...acc,
      ...cur.pay.map((i) => ({
        ...i,
        payRate: cur.payRate,
      })),
    ],
    []
  );

export default {
  components: {
    BaseBanner,
    BaseButton,
    BaseLink,
    CheckboxInput,
    ExplainerTooltipModal,
    HoursMinutesInput,
    IconButton,
    NumberInput,
    RadioGroup,
    RadioToggle,
    SelectInput,
    TextareaInput,
    TextInput,
    TypeBody,
    TypeOverline,
    EarningsBanners,
  },

  props: {
    isContractor: {
      type: Boolean,
      required: true,
    },
    payrollItem: {
      type: Object,
      required: true,
    },
    // wil set the Input options, earnings types for this form
    employeeType: {
      type: String,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    isSavingForm: {
      type: Boolean,
      required: true,
    },
    payrollPayment: {
      type: Object,
      required: true,
    },
    workerName: {
      type: String,
      required: true,
    },
    payrollId: {
      type: String,
      required: true,
    },
    isNonProfit: {
      type: Boolean,
      default: false,
    },
    isReligiousOrg: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const roles = computed(() => props.roles);

    const payRate = computed(
      () => roles.value.find((r) => r.isPrimary)?.payRate
    );
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { payrollItem, employeeType, isContractor } = props;

    const timeInputPreference = ref(
      getUserPreference(UserPreference.INPUT_EARNINGS_AS_DECIMAL)
        ? InputPreference.DECIMAL
        : InputPreference.HOURS_MINUTES
    );

    const isHoursMinutesPreference = computed(
      () => timeInputPreference.value === InputPreference.HOURS_MINUTES
    );

    watch(isHoursMinutesPreference, (isHourMinutes) => {
      setUserPreference(
        UserPreference.INPUT_EARNINGS_AS_DECIMAL,
        !isHourMinutes
      );
    });

    let cloned = cloneDeep(payrollItem);
    const contractorEarningSelectOptions =
      payrollItem.payType === PayTypes.CONTRACT
        ? ContractContractorTypes
        : ContractorTypes;

    const reimbursementsAsPay = payrollItem.reimbursements
      ? payrollItem.reimbursements.map((reimbursement) => ({
          ...reimbursement,
          type: EarningType.REIMBURSEMENT.value,
        }))
      : null;

    cloned = {
      ...cloned,
      pay: reimbursementsAsPay
        ? [...cloned.pay, ...reimbursementsAsPay]
        : cloned.pay,
    };
    const shouldExcludeCashTipsFromOT = computed(
      () =>
        launchDarkly.getFlags()[
          FeatureFlags.EXCLUDE_CASH_TIPS_FROM_OT_BASE_RATE
        ] || false
    );

    const excludedFromOTBaseRate = computed(() =>
      shouldExcludeCashTipsFromOT.value ? [EarningType.CASH_TIPS.value] : []
    );

    const form = ref(cloned);
    const earningSelectOptions = computed(() => {
      let additionalTypes = [];
      if (isContractor) {
        return contractorEarningSelectOptions;
      }

      if (props.isNonProfit) {
        additionalTypes = NPOTypes;
      }

      if (props.isReligiousOrg) {
        additionalTypes = ReligiousNPOTypes;
      }

      return [...AllOptions, ...additionalTypes];
    });
    const paymentMethodOptions = PaymentMethodOptions;
    const pay = ref([]);
    const note = ref('');
    const nextEarningId = ref(shortid.generate());
    const isDiscardingChanges = ref(false);
    const noteErrLabel = 'Enter at least one earning type before adding a note';
    const { paymentMethod } = payrollItem;

    const roleItems = ref(
      groupRoleEarnings(props.payrollId, roles.value, form.value, isContractor)
    );

    const getRoleIds = (list) =>
      list.reduce((acc, cur) => [...acc, cur.id], []);

    const usedRoleIds = computed(() => getRoleIds(roleItems.value));

    const unusedRoleIds = computed(() =>
      getRoleIds(
        roles.value.filter(
          (r) => !r.deletedAt && !usedRoleIds.value.includes(r.id)
        )
      )
    );

    const hasUnusedRoles = computed(() => !!unusedRoleIds.value.length);

    const roleOptions = computed(() =>
      roles.value
        .filter((r) => !r.deletedAt || usedRoleIds.value.includes(r.id))
        .map((r) => ({
          label: `${r.companyRole?.title} | ${currency(r.payRate).format()}`,
          value: r.id,
        }))
    );

    const employeeGrossPay = computed(() => {
      const raw = grossEarnings(
        payRate.value,
        employeeType,
        form.value.pay,
        excludedFromOTBaseRate.value
      );

      return {
        raw,
        formatted: currency(raw).format(),
      };
    });

    const canAddNote = computed(() => !!employeeGrossPay.value?.raw);

    const isSalaryWorker = computed(
      () => roles.value?.find((r) => r.isPrimary)?.payType === PayTypes.SALARY
    );

    const hasNoteErr = computed(
      () => !canAddNote.value && form.value.note?.length > 0
    );

    const hasBulkUploadedEarnings = computed(() => {
      return form.value.pay?.some(
        (payItem) => payItem?.source === EarningSources.UPLOAD
      );
    });

    const noteErr = computed(() =>
      hasNoteErr.value ? noteErrLabel.value : ''
    );

    const notePlaceholder = computed(() =>
      canAddNote.value ? 'Enter any notes here' : noteErrLabel.value
    );

    const isHourlyType = (earningType) =>
      HourlyTypes.map(({ value }) => value).includes(earningType);

    const isNonHourlyType = (earningType) =>
      NonHourlyTypes.map(({ value }) => value).includes(earningType);

    const isBonusType = (earningType) =>
      earningType === EarningType.BONUS.value;

    const isSelectable = (option) => !option.disabled;

    function setEarningAmount(earning, newAmount) {
      const matchingRole = roleItems.value.find(
        (r) => r.id === earning.workerRoleId
      );
      const matchingEarning = matchingRole.pay.find((p) => p.id === earning.id);

      matchingEarning.amount = currency(newAmount).value || 0;
    }

    function updateEarningAmount(earning) {
      const roleRate = roles.value.find(
        (r) => r.id === earning.workerRoleId
      )?.payRate;

      const earningAmount = calculateEarningAmount(
        earning.id,
        ungroupRoleEarnings(roleItems.value),
        roleRate,
        employeeType,
        excludedFromOTBaseRate.value
      );

      if (earningAmount !== earning.amount) {
        setEarningAmount(earning, earningAmount);
      }
    }

    function updateBonusSubtype(
      earning,
      subtype = EarningSubType.DISCRETIONARY.value
    ) {
      const matchingSubtypeLabel = BonusOptions.find(
        (t) => t.value === subtype
      )?.label;

      earning.subtype = subtype;
      earning.altDescription = earning.description
        ? `${earning.description} - ${matchingSubtypeLabel}`
        : matchingSubtypeLabel;
    }

    function updateOtherEarningAmounts(filteredId) {
      // Double forEach isn't ideal, but these collections are very small
      roleItems.value.forEach((roleItem) => {
        const otherEarnings = roleItem.pay.filter((e) => e.id !== filteredId);
        otherEarnings.forEach(updateEarningAmount);
      });
    }

    function updatePayAmountByMinutes(earning, newMinutes) {
      if (newMinutes !== earning.minutes) {
        earning.minutes = newMinutes;
        earning.decimal = minutesToDecimal(newMinutes);

        updateEarningAmount(earning);
        updateOtherEarningAmounts(earning.id);
      }
    }

    function updatePayAmountByDecimal(earning, newTime) {
      const currentDecimal = earning.decimal;
      if (newTime !== currentDecimal) {
        // Minutes are used to calculate the earning amount
        earning.minutes = decimalToMinutes(newTime);
        // The decimal value is stored for a record of what the user inputted
        // It is translated (above) to minutes for the calculation
        earning.decimal = newTime;
        updateEarningAmount(earning);
        updateOtherEarningAmounts(earning.id);
      }
    }

    function isPayInputpDisabled(earning) {
      const earningSelectOptionsValues = earningSelectOptions.value.map(
        (option) => {
          return option.value;
        }
      );

      return !earningSelectOptionsValues.includes(earning.type);
    }

    function updateNonHourlyPayAmount(earning, newAmount) {
      const normalizedNewAmount = currency(newAmount).value;
      const finalAmount = normalizedNewAmount >= 0 ? normalizedNewAmount : 0;
      if (finalAmount !== earning.amount) {
        setEarningAmount(earning, finalAmount);
        updateOtherEarningAmounts(earning.id);
      }
    }

    function handleSaveEdits() {
      if (noteErr.value) {
        return;
      }
      emit('save', form.value);
    }

    function handleDiscardEdits() {
      isDiscardingChanges.value = true;
      emit('discardEditsClick'); // will trigger watch for isDrawerActive
    }

    function handleAddEarning(roleItem, shouldAddToRoleGroup) {
      roleItem.pay.push({
        ...DefaultEarningValues,
        id: shortid.generate(),
        workerRoleId: roleItem.id,
        description: isContractor
          ? undefined
          : roleItem?.companyRole?.title || '',
      });

      if (shouldAddToRoleGroup) {
        roleItems.value.push(roleItem);
      }
      updateOtherEarningAmounts();
    }

    function handleRemovePay(roleItem, removeId) {
      roleItem.pay = roleItem.pay.filter((earning) => earning.id !== removeId);
      updateOtherEarningAmounts();
    }

    function handleRemoveGroup(roleItem) {
      roleItems.value = roleItems.value.filter((i) => i.id !== roleItem.id);
    }

    function updatePayType(earning, newType) {
      const originalType = earning.type;
      if (newType !== earning.type) {
        if (newType === 'REIMBURSEMENT' || earning.type === 'REIMBURSEMENT') {
          earning.id = shortid.generate();
        }

        if (newType === EarningType.BONUS.value) {
          updateBonusSubtype(earning);
        }

        earning.type = newType;

        // To prevent stale data and invalid values withing different earning types,
        // reset the minutes & amount where applicable
        if (isHourlyType(originalType) && isNonHourlyType(newType)) {
          earning.minutes = 0;
          updateNonHourlyPayAmount(earning, 0);
        } else if (isNonHourlyType(originalType) && isHourlyType(newType)) {
          updatePayAmountByMinutes(earning, 0);
        } else {
          updateEarningAmount(earning);
          updateOtherEarningAmounts(earning.id);
        }
      }
    }

    function updateRoleType(roleItem, newRoleId) {
      const matchingActiveRole = roleItems.value.find(
        (i) => i.id === newRoleId
      );
      const newRolePay = roleItem.pay.map((i) => ({
        ...i,
        workerRoleId: newRoleId,
      }));

      const newRole = roles.value.find((r) => r.id === newRoleId);

      if (matchingActiveRole) {
        matchingActiveRole.pay = [...matchingActiveRole.pay, ...newRolePay];

        handleRemoveGroup(roleItem);

        return;
      }

      Object.assign(roleItem, {
        ...newRole,
        pay: newRolePay,
      });

      updateOtherEarningAmounts();
    }

    function handleAddRole(payItems = []) {
      const firstUnusedRole = roles.value.find(
        (r) => r.id === unusedRoleIds.value[0]
      );

      handleAddEarning(
        {
          ...firstUnusedRole,
          pay: payItems,
        },
        true
      );
    }

    function handleDeletedRoleSelection(roleItem, selectedRoleId) {
      const hasSelectedDeletedRole = selectedRoleId === roleItem.id;

      // Setting a flag to prevent showing a banner on subsequent editing sessions of payroll
      setUserPreference(
        `made-selection:${props.payrollId}-${payrollItem.id}`,
        true
      );

      if (hasSelectedDeletedRole) {
        roleItem.hasMadeDeletedSelection = true;
        setUserPreference(getRolePrefKey(props.payrollId, roleItem.id), true);

        return;
      }

      updateRoleType(roleItem, selectedRoleId);
    }

    watch(
      roleItems,
      (items) => {
        updateOtherEarningAmounts();
        form.value.pay = ungroupRoleEarnings(items);
      },
      {
        deep: true,
        immediate: true,
      }
    );

    watch(
      roles,
      (res) => {
        const primaryRole = res.find((r) => r.isPrimary);

        if (!roleItems.value.length && primaryRole) {
          handleAddRole();
        }
      },
      { immediate: true }
    );

    const hasOneRole = computed(
      () => !hasUnusedRoles.value && roleItems.value.length === 1
    );

    // Earnings helper banner + modal
    const showEarningsHelperText = ref(false);
    const toggleEarningsHelperText = () => {
      showEarningsHelperText.value = !showEarningsHelperText.value;
    };

    const showEarningsHelper = ref(true);

    const { hydrateFromLocalStorage, state: earningsHelperState } = useState(
      showEarningsHelper,
      'earningsHelper'
    );

    const toggleEarningsHelper = () => {
      const { addInfo } = useNotifications();
      showEarningsHelper.value = !showEarningsHelper.value;

      addInfo('You can find this information by clicking "need help" anytime.');
    };

    hydrateFromLocalStorage();

    return {
      BonusOptions,
      bonusTypeEntryId,
      canAddNote,
      currency,
      earningSelectOptions,
      employeeGrossPay,
      form,
      handleAddEarning,
      handleAddRole,
      handleDeletedRoleSelection,
      handleDiscardEdits,
      handleRemoveGroup,
      handleRemovePay,
      handleSaveEdits,
      hasBulkUploadedEarnings,
      hasNoteErr,
      hasOneRole,
      hasUnusedRoles,
      inputPreferenceOptions,
      isBonusType,
      isHourlyType,
      isHoursMinutesPreference,
      isNonHourlyType,
      isPayInputpDisabled,
      isSalaryWorker,
      isSelectable,
      minutesToDecimal,
      nextEarningId,
      note,
      noteErr,
      noteErrLabel,
      notePlaceholder,
      pay,
      paymentMethod,
      paymentMethodOptions,
      roleItems,
      roleOptions,
      setEarningAmount,
      timeInputPreference,
      updateBonusSubtype,
      updateEarningAmount,
      updateNonHourlyPayAmount,
      updateOtherEarningAmounts,
      updatePayAmountByDecimal,
      updatePayAmountByMinutes,
      updatePayType,
      updateRoleType,
      showEarningsHelper,
      earningsHelperState,
      showEarningsHelperText,
      toggleEarningsHelperText,
      toggleEarningsHelper,
    };
  },
};
</script>

<style scoped>
/* Necessary to apply full width style to RadioToggle  */

>>> .radio-toggle label {
  @apply w-1/2;
}
</style>
