





























































































import useAddStaffFormState from '../hooks/useAddStaffFormState';
import useAddStaffForm from '../hooks/useAddStaffForm';
import { defineComponent } from '@nuxtjs/composition-api';
import {
  TextInput,
  PhoneInput,
  DateInput,
  BaseDivider,
  RadioGroup,
  AnnularThrobber,
} from '@bambeehr/pollen';
import AddStaffFormGroup from './AddStaffFormGroup';
import AddStaffFormRoles from './AddStaffFormRoles';
import AddStaffInviteChecks from './AddStaffInviteChecks';

import usStateSelect from '@/shared/components/form-components/us-state-select.vue';

export default defineComponent({
  components: {
    TextInput,
    PhoneInput,
    AddStaffFormGroup,
    DateInput,
    usStateSelect,
    BaseDivider,
    RadioGroup,
    AddStaffFormRoles,
    AddStaffInviteChecks,
    AnnularThrobber,
  },
  setup() {
    const { emailLabel, phoneLabel, workerRoles, workerId, rolesAreLoading } =
      useAddStaffForm();
    const { v$, formState, isInvalid, needsPayrollInfo, employeeOptions } =
      useAddStaffFormState();

    return {
      formState,
      isInvalid,
      v$,
      emailLabel,
      phoneLabel,
      needsPayrollInfo,
      workerRoles,
      employeeOptions,
      workerId,
      rolesAreLoading,
    };
  },
});
