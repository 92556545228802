







import { useStore, useRouter } from '@nuxtjs/composition-api';
import useStaff from './useStaff';
import useCheckOnboarding from '@/modules/payroll/hooks/useCheckOnboarding';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import EmployeeSetupCard from '@/modules/payroll/components/EmployeeSetupCard/EmployeeSetupCard.vue';
import { Route } from '@/modules/payroll/constants/payroll';

export default {
  components: {
    EmployeeSetupCard,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { onboardingLink } = useStaff();
    const { companyId } = useCurrentCompany();
    const { currentUser } = store.getters;
    const isContractor = currentUser?.profile?.contractor;

    const {
      onDone: onOnboardingComplete,
      openOnboarding,
      isLoading: isLoadingOnboardingModal,
    } = useCheckOnboarding(companyId.value, 'ee');

    const openModal = () => {
      openOnboarding(onboardingLink.value);
    };

    onOnboardingComplete(() => {
      router.push(Route.MY_PAY);
    });

    return {
      isContractor,
      isLoadingOnboardingModal,
      openModal,
      openOnboarding,
    };
  },
};
