import { ref, Ref } from '@nuxtjs/composition-api';
import useState from '@/hooks/useState/useState';

export enum CommunicationName {
  DASHBOARD_WELCOME = 'welcomeBanner',
  PAYROLL_WELCOME = 'payrollWelcomeModal',
  DEPUTY_WELCOME = 'deputyWelcomeModal',
  MY_TEAM_UPDATES = 'myTeamUpdates',
}

const LOCAL_STORAGE_COMMUNICATION_KEY: string = 'communication';

let initialized: boolean;
const isReady: Ref<boolean> = ref(false);

export const communication: Record<CommunicationName, boolean> = {
  [CommunicationName.DASHBOARD_WELCOME]: true,
  [CommunicationName.PAYROLL_WELCOME]: true,
  [CommunicationName.DEPUTY_WELCOME]: true,
  [CommunicationName.MY_TEAM_UPDATES]: true,
};

const { hydrateFromLocalStorage, state: communicationState } = useState(
  communication,
  LOCAL_STORAGE_COMMUNICATION_KEY
);

const setupHook = async (): Promise<void> => {
  hydrateFromLocalStorage();
  isReady.value = true;
};

const useCommunicationState = (forceTest: boolean = false) => {
  if (!initialized || forceTest) {
    initialized = true;
    setupHook();
  }

  return {
    CommunicationName,
    communicationState,
    isReady,
  };
};

export default useCommunicationState;
