<template>
  <div class="tos-container" @scroll="onScroll">
    <TypeBody id="tosContainer" class="flex flex-col gap-4 p-4" v-html="copy" tag="div"></TypeBody>
  </div>
</template>

<script >
import copy from './TOS.copy';
import {TypeBody} from '@bambeehr/pollen'
export default {
  props: {
    legal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TypeBody
  },
  setup(){
    return {
      copy,
    }
  },


  mounted() {
    if (this.legal) {
      document.getElementById('legal-disclaimer').scrollIntoView();
    }
    this.tosContainer = document.getElementById('tosContainer').offsetHeight;
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
      if (this.offsetTop >= this.tosContainer - 1000) {
        this.$emit('acknowledged');
      }
    },
  },
};
</script>

<style scoped>
>>> h3 {
  @apply font-bold;
}
</style>
