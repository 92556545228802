/**
 * NOTE: Current implementation is chiefly reliant on Netlify, in that:
 * * TestVariants map to Branch names (named in github, but surfaced as a env variable via Netlify)
 * * testKey is set as an environment variable name in Netlify (https://app.netlify.com/sites/appbambee/configuration/env)
 *
 * GIVEN:
 * * Netlify has a test key (AB_TEST_NAME) set as an Environment variable AND has been rebuilt (to apply said variable)
 *
 * THEN:
 * * Bucketing and Entry events (via segment) will fire once per refresh.
 *
 */

import bam from '@/lib/bam';

// Define the test variants
const TestVariants = {
  TEST: 'test-production',
  CONTROL: 'master',
} as const;

// Get the current A/B test name from the environment variables
const abTestName: string = process.env.AB_TEST_NAME || '';

// Get the current branch name (test variant) from the environment variables
const branchName: string = process.env.TEST_BRANCH || '';

// Check if an A/B test is running
const isTestRunning: boolean = !!abTestName;

// Check if the current branch matches the test variant
export const isTestVariant: boolean = branchName === TestVariants.TEST;

// Check if the current branch matches the control variant
export const isControlVariant: boolean = branchName === TestVariants.CONTROL;

let isBucketed = false;

// Send a tracking event if the current page load is part of an A/B test and hasn't been bucketed yet
export const bucketTest = (
  additionalContext: Record<string, string> = {}
): void => {
  if (isTestRunning && !isBucketed) {
    isBucketed = true;
    bam.track(abTestName, {
      ...additionalContext,
      'test-variant': branchName,
    });
  }
};

let hasEnteredTest = false;

// Send an entry event if the current page load is part of an A/B test and hasn't entered yet
export const enterTest = (
  additionalContext: Record<string, string> = {}
): void => {
  if (isTestRunning && !hasEnteredTest) {
    hasEnteredTest = true;
    bam.track('ab-test-entry', {
      ...additionalContext,
      'test-name': abTestName,
      'test-variant': branchName,
    });
  }
};
