export const Types = Object.freeze({
  FINAL_WRITTEN_WARNING: 'FINAL_WRITTEN_WARNING',
  PIP: 'PIP',
  WRITTEN_WARNING: 'WRITTEN_WARNING',
  VERBAL_WARNING: 'VERBAL_WARNING',
});

export const Titles = Object.freeze({
  [Types.FINAL_WRITTEN_WARNING]: 'Final Written Warning',
  [Types.PIP]: 'Performance Improvement Plan',
  [Types.WRITTEN_WARNING]: 'Written Warning',
  [Types.VERBAL_WARNING]: 'Verbal Warning',
});

export const Paths = Object.freeze({
  [Types.WRITTEN_WARNING]: 'written-warning',
  [Types.FINAL_WRITTEN_WARNING]: 'written-warning',
  [Types.VERBAL_WARNING]: 'verbal-warning',
  [Types.PIP]: 'pip',
});

export const Statuses = Object.freeze({
  APPROVAL: 'awaiting approval',
  DRAFT: 'draft',
  MANAGER: 'awaiting manager updates',
  SIGNATURE: 'awaiting signature',
});

export const StepNames = Object.freeze({
  CREATED: 'created',
  APPROVAL: 'approval',
  APPROVED: 'approved',
  SIGN: 'sign',
  FINAL_PRINT: 'finalPrint',
  MEETING: 'meeting',
  EMPLOYEE_SIGN: 'employe_sign',
});

export const ModelNames = ['Pip', 'Verbalwarning', 'Writtenwarning'];
