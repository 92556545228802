







































import { defineComponent, computed } from '@nuxtjs/composition-api';
import {
  BaseCard,
  BaseTag,
  TypeDisplay,
  TypeOverline,
  BaseButton,
  TypeBody,
} from '@bambeehr/pollen';

export default defineComponent({
  components: {
    BaseCard,
    BaseTag,
    TypeDisplay,
    TypeOverline,
    BaseButton,
    TypeBody,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    courseCount: {
      type: Number,
      required: true,
    },
    isStarted: {
      type: Boolean,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    isBundle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const btnText = computed(() => {
      if (props.isCompleted) {
        return props.courseCount > 1
          ? 'Download Certificates'
          : 'Download Certificate';
      }
      if (props.isStarted) {
        return 'Resume';
      }

      return `Start ${props.isBundle ? 'Program' : 'Course'}`;
    });

    return {
      btnText,
    };
  },
});
