<template>
  <div>
    <TrainingRegistrationCard>
      <div class="flex flex-col items-center mt-6 max-w-lg mx-auto text-center">
        <TypeBody
          variant="link-medium-tight"
          weight="strong"
          class="text-gray-0 mb-4"
        >
          Training Enrollment Submitted
        </TypeBody>

        <AnimatedCheckmark class="mb-4 text-72" />

        <TypeBody variant="text-small" weight="medium" class="text-base-600">
          Best Practice HR Policy must be combined with clear workforce training
          in order to guide employees toward the right behaviors. Well done.
        </TypeBody>

        <TypeBody
          variant="text-small"
          weight="medium"
          class="text-base-600 mt-2"
        >
          Your HR Manager will now begin enrolling your employees in training.
          They will reach out to you when this is completed.
        </TypeBody>

        <BaseButton
          variant="primary"
          :flat="true"
          class="mt-6"
          @click="handleAskQuestion"
        >
          <TypeBody variant="link-small" weight="strong">
            Ask A Question
          </TypeBody>
        </BaseButton>
      </div>
    </TrainingRegistrationCard>
  </div>
</template>

<script>
import { computed, useRouter, useStore } from '@nuxtjs/composition-api';
import { BaseButton, TypeBody } from '@bambeehr/pollen';
import TrainingRegistrationCard from '@/modules/Training/components/TrainingRegistrationCard';
import AnimatedCheckmark from '@/components/AnimatedCheckmark/AnimatedCheckmark';
import { HrRequestGroupKey, HrRequestSlug } from '@/gql/generated';
import { hrRequestFullSlug } from '@/modules/HrRequest/constants/RequestTypesData';
import { useAdvisorsStore } from '@/store/advisors';

export default {
  components: {
    BaseButton,
    TypeBody,
    TrainingRegistrationCard,
    AnimatedCheckmark,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const handleAskQuestion = () => {
      const hrRequestSlug = hrRequestFullSlug({
        groupKey: HrRequestGroupKey.Support,
        slug: HrRequestSlug.AdminSupport,
      });
      router.push(`/requests/${hrRequestSlug}`);
    };

    const advisorStore = useAdvisorsStore();
    const hrManagerAvatar = computed(() => advisorStore.hrManagerAvatar);

    return {
      handleAskQuestion,
      hrManagerAvatar,
    };
  },
};
</script>

<style lang="scss" scoped>
.speech-triangle {
  border-color: transparent transparent #e9e3f2 transparent;
  border-style: solid;
  border-width: 0 0 10px 10px;
  height: 0;
  width: 0;
}
</style>
