<template>
  <div class="summary-tile__line-item items-center flex sm:block">
    <div class="flex justify-between">
      <TypeBody
        class="text-base-600 mb-0.5 flex justify-between"
        :class="{ 'mb-1': emphasize }"
        tag="div"
        variant="text-x-small-tight"
        weight="medium"
      >
        <span>{{ name }}</span>
        <span v-if="!!$slots.tooltip" class="tooltip ml-2">
          <slot name="tooltip" />
        </span>
      </TypeBody>

      <LedgerModalTrigger v-if="details" :details="details" />
    </div>

    <TypeBody
      class="text-base-800 ml-auto"
      tag="div"
      :variant="typeVariant"
      weight="strong"
    >
      <span v-if="highlightChanges">
        <FlashingText :display-text="formattedValue" />
      </span>
      <span v-else>
        {{ formattedValue }}
      </span>
    </TypeBody>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import currency from '@bambeehr/currency';
import { TypeBody } from '@bambeehr/pollen';
import { formatDate, formatTime } from '@/utils/date';
import LedgerModalTrigger from '@/modules/payroll/components/LedgerModalTrigger/LedgerModalTrigger';
import FlashingText from '@/components/FlashingText';

const LINE_ITEM_TYPES = Object.freeze({
  Currency: 'currency',
  Date: 'date',
  DateTime: 'datetime',
});

const TYPE_VARIANTS = Object.freeze({
  Large: 'large',
  Default: 'text',
});

export default {
  name: 'SummaryTileLineItem',
  components: {
    FlashingText,
    LedgerModalTrigger,
    TypeBody,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: LINE_ITEM_TYPES.Currency,
      validator(val) {
        return Object.values(LINE_ITEM_TYPES).includes(val);
      },
    },
    emphasize: {
      type: Boolean,
      required: false,
    },
    highlightChanges: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, Date, String],
      required: true,
    },
    details: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const formattedValue = computed(() => {
      if (props.type === LINE_ITEM_TYPES.Date) {
        return formatDate(props.value);
      }
      if (props.type === LINE_ITEM_TYPES.DateTime) {
        return `${formatTime(props.value)} ${formatDate(props.value)}`;
      }

      return currency(props.value).format(); // Default
    });

    const typeVariant = computed(() => {
      if (props.emphasize) {
        return TYPE_VARIANTS.Large;
      }

      return TYPE_VARIANTS.Default; // Default
    });

    return {
      formattedValue,
      typeVariant,
    };
  },
};
</script>
