import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentUser from '@/hooks/useCurrentUser';
import bam from '@/lib/bam';

export const trackEvent = (event: string, payload: any = {}) => {
  const { companyId, company } = useCurrentCompany();
  const { currentUserId, currentUser } = useCurrentUser();
  const data = {
    companyId: companyId.value,
    userId: currentUserId.value,
    company: {
      name: company.value.name,
      dba: company.value.dba,
      phone: company.value.profile?.phone,
      plan: company.value.plan,
    },
    user: {
      role: currentUser.value?.role,
      name: currentUser.value?.profile?.full_name,
    },
    ...payload,
  };

  bam.track(`apa-${event}`, data);

  return data;
};

export default null;
