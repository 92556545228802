











































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { TypeBody, BaseIcon } from '@bambeehr/pollen';

export default defineComponent({
  name: 'FaqItem',
  components: {
    TypeBody,
    BaseIcon,
  },
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      // Optional as this can be a slot
      default: '',
    },
  },
  setup() {
    const isOpen = ref(false);

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      toggleOpen,
    };
  },
});
