import { CompanyStatuses } from '@/constants/companies';
import get from 'lodash/get';
import canPurchaseTrainingService from './canPurchaseTraining';
import hasCoursesAssigned from './hasCoursesAssigned';

let canAccess: Boolean;
export const clearCache = () => {
  canAccess = false;
};

// Requires a paying company PLUS one of the following:
// * Has training flag enabled (covers all admin/owners that have permissions to purchase, or have already purchased)
// * Is employee with courses (gives access to training portal for employees)
const canAccessTrainingModule = async (
  userId: String,
  store
): Promise<Boolean> => {
  if (canAccess) {
    return true;
  }

  const { currentUser } = store.getters;
  const companyStatus = get(currentUser, '_company.status');

  const isCompanyPaying = companyStatus === CompanyStatuses.PAYING;
  const isTrial = companyStatus === CompanyStatuses.TRIAL;

  if (!isCompanyPaying && !isTrial) {
    return false;
  }

  const canPurchaseTraining: Boolean = await canPurchaseTrainingService(store);

  let hasCourses: Boolean = false;

  hasCourses = await hasCoursesAssigned(userId);

  canAccess = canPurchaseTraining || hasCourses; // Cache value for subsequent checks

  return canAccess;
};

export default canAccessTrainingModule;
