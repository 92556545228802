<template>
  <!-- FE to do (PAY-1681): Add 'description' prop to text input to replace this component -->
  <div class="flex flex-col mt-2">
    <TypeBody
      v-if="showLabel"
      tag="div"
      class="font-bold"
      :variant="textVariant"
      :class="{ 'text-error-default': invalid && shouldShowTextInvalid }"
    >
      <slot name="pre-label" />
      {{ label }}
      <slot name="post-label" />
    </TypeBody>
    <TypeBody
      v-if="description"
      tag="div"
      :variant="size"
      class="mt-1 text-base-700"
      weight="regular"
    >
      <slot name="pre-description" />
      {{ description }}
      <slot name="post-description" />
    </TypeBody>
    <slot name="default">
      <TextInput
        :id="name"
        :invalid="invalid"
        :disabled="isDisabled"
        class="mt-2"
        :placeholder="$attrs.placeholder || label"
        :name="name"
        :value="value"
        :size="inputSize"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </slot>
  </div>
</template>
<script>
import '@bambeehr/pollen/src/tailwind/theme';
import { TypeBody, TextInput } from '@bambeehr/pollen';
import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'CompanyInfoFormRow',
  components: {
    TextInput,
    TypeBody,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      required: false,
    },
    shouldShowTextInvalid: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: String,
      require: false,
      default: '',
    },
    inputSize: {
      type: String,
      default: 'normal',
    },
  },
  setup(props) {
    const textVariant = computed(() =>
      props.size === 'large' ? 'medium' : 'link-x-small-tight'
    );

    return {
      textVariant,
    };
  },
};
</script>
