

















import { TypeOverline } from '@bambeehr/pollen';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SimpleCard',
  components: {
    TypeOverline,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
