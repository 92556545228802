




























import {
  AvatarImage,
  CheckboxInput,
  TypeBody,
  SelectInput,
} from '@bambeehr/pollen';
import { defineComponent } from '@nuxtjs/composition-api';
import UserInfo from '@/modules/Training/components/UserInfo/UserInfo';
import { Language, LanguageLabels } from '@/modules/Training/types/TrainingApi';

export default defineComponent({
  name: 'TrainingEnrollmentFormField',
  components: {
    AvatarImage,
    CheckboxInput,
    TypeBody,
    SelectInput,
    UserInfo,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    language: {
      type: String, // ENG, SPN
      required: false,
      default: Language.ENGLISH,
    },
  },
  setup() {
    const languageOptions = [
      {
        value: Language.ENGLISH,
        label: LanguageLabels.ENGLISH,
      },
      {
        value: Language.SPANISH,
        label: LanguageLabels.SPANISH,
      },
    ];

    return {
      languageOptions,
    };
  },
});
