import Cookie from 'js-cookie';
import { CookieNames } from '@/constants/Cookies';

const useNull =
  !['production', 'stage'].includes(process.env.ENV) ||
  process.env.NETLIFY_CONTEXT === 'deploy-preview';

const domain = useNull ? null : process.env.host;

export function getCookie(name) {
  return Cookie.get(name, { domain });
}

export function setCookie(name, value, options = {}) {
  const config = domain
    ? {
        domain,
        sameSite: 'None',
        secure: true,
        ...options,
      }
    : options;

  Cookie.set(name, value, config);
}

export function deleteCookie(name) {
  Cookie.remove(name, { domain });
}

export function deleteAccessTokenCookies() {
  deleteCookie(CookieNames.ACCESS_TOKEN);
  deleteCookie(CookieNames.REFRESH_TOKEN);
}
