






























import { defineComponent, ref, Ref } from '@nuxtjs/composition-api';
import {
  BaseButton,
  TypeDisplay,
  AnnularThrobber,
  BaseIcon,
} from '@bambeehr/pollen';
import useContentful from '@/hooks/useContentful/useContentful';
import ContentfulRichText from '@/components/contentful/ContentfulRichText';

export default defineComponent({
  name: 'ContentfulExplainer',
  components: {
    BaseButton,
    TypeDisplay,
    ContentfulRichText,
    AnnularThrobber,
    BaseIcon,
  },
  props: {
    entryId: {
      type: String,
      required: true,
    },
  },
  emits: ['next', 'close'],
  setup(props) {
    // Prepare to fetch Contentful content
    const contentIsReady = ref(false);
    const { fetchContent } = useContentful();
    const contentfulContent: Ref<any> = ref({});
    const ctas = ref([]);

    // Fetch the content when component is mounted
    (async () => {
      const content = await fetchContent(props.entryId);
      contentfulContent.value = content || {};
      ctas.value = content.ctas;
      contentIsReady.value = true;
    })();

    return {
      contentIsReady,
      contentfulContent,
      ctas,
    };
  },
});
