import { defineStore } from 'pinia';
import keyBy from 'lodash/keyBy';
import Vue from 'vue';
import {
  ModelNames as CapModelNames,
  Statuses as CapStatuses,
} from '../lib/globals/capTypes';
import { useDefaultStore } from '@/store/index';
import { useAuthStore } from '@/store/auth';

const getUserId = () => {
  const defaultStore = useDefaultStore();

  // @ts-ignore -- can remove when index file is updated
  return defaultStore.currentUser?._id;
};

const useCapsStore = defineStore('caps', {
  state: () => ({
    caps: {},
    capIdsByUserId: {},
  }),
  getters: {
    capsForUserInScope(state): any[] {
      const userId = getUserId();

      if (!state.capIdsByUserId[userId]) {
        return [];
      }

      return state.capIdsByUserId[userId]
        .map((capId) => state.caps[capId])
        .filter((feed) => feed._data && feed._employee);
    },

    allCaps(): any[] {
      return this.capsForUserInScope.filter((feed) => {
        const cap = feed._data;
        if (!CapModelNames.includes(feed.model)) {
          return false;
        }

        if (feed.model === 'Verbalwarning') {
          return true;
        }

        if (!cap.steps) {
          return false;
        }

        const authStore = useAuthStore();
        const { isCompanyAdmin } = authStore;

        if (isCompanyAdmin) {
          if (cap.isFinal && !cap.steps[cap.steps.length - 1].active) {
            return true;
          }

          return cap.status !== CapStatuses.SIGNATURE;
        }

        // only final written warnings have 7 CAP steps
        // all other CAPs have 5 steps
        // only show final written warning for employees if on the cap has reached the LAST step
        // cap.steps[6] is the 'employee_sign' step for final written warnings
        return cap.steps[cap.steps.length - 1].active;
      });
    },

    capsCount(): number {
      return this.allCaps.length;
    },
  },

  actions: {
    async fetchCAPs() {
      const userId = getUserId();
      const authStore = useAuthStore();
      const { companyId } = authStore;

      try {
        const res = await this.$nuxt.$axios.get(
          `/v0/companies/v1/company/${companyId}/caps`
        );

        const payload = {
          userId,
          capFeeds: res.data.capFeeds,
        };

        this.setCaps(payload);
      } catch (e) {
        console.log(e);
      }
    },

    addCAPs(payload) {
      this.setCaps(payload);
    },

    async deleteCAP({ id, type, feedId }) {
      const userId = getUserId();

      try {
        await this.$nuxt.$axios.delete(`/v0/caps/v1/cap/${type}/${id}`);

        this.removeCAP({
          feedIds: [feedId],
          userId,
        });
      } catch (e) {
        console.log(e);
      }
    },

    updateCAP({ data }: { data: any; feedId: string }) {
      const feed = this.capsForUserInScope.find(
        (f) => f._data._id === data._id
      );

      if (feed) {
        this.updateCAP({
          feedId: feed._id,
          data,
        });
      }
    },

    setCaps({ userId, capFeeds }) {
      const newCaps = keyBy(capFeeds, '_id');
      const userCapIds = Object.keys(newCaps);

      this.caps = {
        ...this.caps,
        ...newCaps,
      };

      Vue.set(this.capIdsByUserId, userId, userCapIds);
    },

    udpateCAP({ feedId, data }) {
      if (this.caps[feedId]) {
        Vue.set(this.caps[feedId], '_data', data);
      }
    },

    removeCAP({ feedIds, userId }) {
      const newFeeds = this.capIdsByUserId[userId].filter(
        (feedId) => !feedIds.includes(feedId)
      );

      Vue.set(this.capIdsByUserId, userId, newFeeds);
    },
  },
});

export default useCapsStore;
