import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import CachePolicy from '@/gql/CachePolicy';
import {
  Company,
  useGetCompanyVerboseQuery,
  useUnenrollUsersMutation,
  PurchasedBundle,
  PurchasedTopic,
} from '@/gql/generated';
import useNotifications from '@bambeehr/use-notifications';
import { computed, ref } from '@nuxtjs/composition-api';
import uniqBy from 'lodash/uniqBy';

let w2List;
let contractorList;
let currentUser;
const contentId = ref('');
const searchQuery = ref('');
const showEnrollmentModal = ref(false);
const progressModalItem = ref();

const { addSuccess } = useNotifications();

export const getContentInfo = (company: Company, cId: string) => {
  const products = company?.purchasedProducts;
  const bundle = products?.bundles?.find((c) => c?.bundleId === cId);
  const topic = products?.topics?.find((c) => c?.topicId === cId);
  const content = (bundle || topic) as PurchasedBundle & PurchasedTopic;

  return {
    ...content,
    id: content?.bundleId || content?.topicId,
    isBundle: !!bundle,
  };
};

export const getAllRecordsByKey = (records, key, id) => {
  if (!records) {
    return [];
  }

  const recordsList =
    records.enrollments ||
    records.topics.reduce((acc, cur) => [...acc, ...cur.enrollments], []);

  return recordsList
    .filter((r) => r.userMngId === id)
    .reduce((acc, cur) => [...acc, cur[key]], []);
};

export const getDecoratedCurrentUserList = (
  content: PurchasedBundle & PurchasedTopic,
  staffList,
  curUser
) => {
  if (!content || (!content.enrollments && !content.topics?.[0]?.enrollments)) {
    return [];
  }

  const isBundle = !!content.topics?.length;
  const enrollments = uniqBy(
    content?.enrollments || content?.topics?.[0]?.enrollments || [],
    'userMngId'
  );

  return enrollments.map((enrollment) => {
    const allCompleted = getAllRecordsByKey(
      content,
      'completedAt',
      enrollment?.userMngId
    ).every((r) => !!r);
    const someStarted = getAllRecordsByKey(
      content,
      'startedAt',
      enrollment?.userMngId
    ).some((r) => !!r);

    const topics = isBundle
      ? content.topics.map((topic) => ({
          ...topic,
          enrollments: topic?.enrollments.filter(
            (i) => i?.userMngId === enrollment?.userMngId
          ),
        }))
      : [];

    return {
      ...enrollment,
      topics,
      isCompleted: allCompleted,
      isStarted: someStarted && !allCompleted,
      isNotStarted: !someStarted && !allCompleted,
      user:
        enrollment?.userMngId === curUser._id
          ? {
              ...curUser,
              id: curUser._id,
            }
          : staffList.find((u) => u.id === enrollment?.userMngId),
    };
  });
};

export const getFilteredUserList = (userList, filterQuery) =>
  userList.filter((e) =>
    e.user?.profile?.full_name
      ?.toLowerCase()
      .includes(filterQuery.toLowerCase())
  );

const { companyId } = useCurrentCompany();
const trainingCompany = ref();
const isLoading = ref(false);

const getContent = (shouldHardRefresh = false) => {
  useApolloQuery(
    useGetCompanyVerboseQuery,
    { data: { companyId: companyId.value } },
    {
      data: trainingCompany,
      pending: isLoading,
    },
    { placeholderPick: ({ company: res }) => res },
    {
      fetchPolicy: shouldHardRefresh
        ? CachePolicy.NETWORK_ONLY
        : CachePolicy.CACHE_FIRST,
    }
  );
};

const currentContent = computed(() =>
  getContentInfo(trainingCompany.value, contentId.value)
);

// Helpers
const bundleId = computed(
  () => (currentContent.value.topics?.length && currentContent.value.id) || ''
);
const topicId = computed(
  () => (!currentContent.value.topics?.length && currentContent.value.id) || ''
);
const isBundle = computed(() => !!bundleId.value);

const currentUserList = computed(() =>
  getDecoratedCurrentUserList(
    currentContent.value,
    [...(w2List?.value || []), ...(contractorList?.value || [])],
    currentUser?.value
  )
);

const contentCounts = computed(() => {
  const completed = currentUserList.value.reduce(
    (acc, cur) => acc + (cur.isCompleted ? 1 : 0),
    0
  );
  const started = currentUserList.value.reduce(
    (acc, cur) => acc + (cur.isStarted ? 1 : 0),
    0
  );
  const notStarted = currentUserList.value.reduce(
    (acc, cur) => acc + (cur.isNotStarted ? 1 : 0),
    0
  );

  return {
    completed,
    started,
    notStarted,
  };
});

const filteredUserList = computed(() =>
  getFilteredUserList(currentUserList.value, searchQuery.value)
);

const isUnenrolling = ref(false);
const unenrollingName = ref('');
const unenrollUser = ({ userId, user }) => {
  const { onDone, mutate } = useApolloMutation(useUnenrollUsersMutation, {
    pending: isUnenrolling,
  });

  unenrollingName.value = user?.profile?.full_name || '';

  mutate({
    data: {
      bundleId: bundleId.value,
      topicId: topicId.value,
      userIds: [user.id],
      companyId: companyId.value,
    },
  });

  onDone(() => {
    addSuccess('User unenrolled');
    getContent(true);
  });
};

const toggleProgressModal = (item?) => {
  // Don't show progress modal if it's a topic
  if (item && item.topicId) {
    return;
  }

  progressModalItem.value = item;
};
const toggleEnrollmentModal = () => {
  showEnrollmentModal.value = !showEnrollmentModal.value;
};

const useEmployerTrainingDetail = (
  id?: string,
  w2?,
  contractors?,
  curUser?
) => {
  if (id) {
    contentId.value = id;
    getContent();
  }

  if (w2) {
    w2List = w2;
  }

  if (contractors) {
    contractorList = contractors;
  }

  if (curUser) {
    currentUser = curUser;
  }

  return {
    bundleId,
    contentCounts,
    currentContent,
    currentUserList,
    filteredUserList,
    isBundle,
    isLoading,
    isUnenrolling,
    progressModalItem,
    searchQuery,
    showEnrollmentModal,
    toggleEnrollmentModal,
    toggleProgressModal,
    topicId,
    unenrollingName,
    unenrollUser,
  };
};

export default useEmployerTrainingDetail;
