export enum Step {
  DISCUSSION_TOPICS = 'DISCUSSION_TOPICS',
  ABOUT_COMPANY = 'ABOUT_COMPANY',
  BUSINESS_LOCATION = 'BUSINESS_LOCATION',
  ABOUT_YOU = 'ABOUT_YOU',
  ABOUT_EMPLOYEES = 'ABOUT_EMPLOYEES',
  SELECT_FIRST_ACTION = 'SELECT_FIRST_ACTION',
  SCHEDULE_CALL = 'SCHEDULE_CALL',
  REQUEST_DETAILS = 'REQUEST_DETAILS',
}

export const journeys = {
  ER: {
    LITE: [
      Step.DISCUSSION_TOPICS,
      Step.ABOUT_COMPANY,
      Step.BUSINESS_LOCATION,
      Step.ABOUT_EMPLOYEES,
    ],
    DEDICATED: [
      Step.DISCUSSION_TOPICS,
      Step.ABOUT_COMPANY,
      Step.BUSINESS_LOCATION,
      Step.ABOUT_YOU,
      Step.ABOUT_EMPLOYEES,
      Step.SELECT_FIRST_ACTION,
      Step.SCHEDULE_CALL,
      Step.REQUEST_DETAILS,
    ],
  },
  EE: {},
};

export const pathDefaults = {
  [Step.DISCUSSION_TOPICS]: {
    slug: 'discussion-topics',
    id: Step.DISCUSSION_TOPICS,
    label: 'Disucssion Topics',
    progressOrder: 1,
    hideBack: true,
  },
  [Step.ABOUT_COMPANY]: {
    slug: 'about-company',
    id: Step.ABOUT_COMPANY,
    label: 'Disucssion Topics',
    progressOrder: 2,
  },
  [Step.BUSINESS_LOCATION]: {
    slug: 'business-location',
    id: Step.BUSINESS_LOCATION,
    label: 'Business Location',
    progressOrder: 3,
  },
  [Step.ABOUT_YOU]: {
    slug: 'about-you',
    id: Step.ABOUT_YOU,
    label: 'About You',
    progressOrder: 4,
  },
  [Step.ABOUT_EMPLOYEES]: {
    slug: 'about-employees',
    id: Step.ABOUT_EMPLOYEES,
    label: 'About Employees',
    progressOrder: 5,
  },
  [Step.SELECT_FIRST_ACTION]: {
    slug: 'select-first-action',
    id: Step.SELECT_FIRST_ACTION,
    label: 'Select First Action',
    progressOrder: 6,
  },
  [Step.SCHEDULE_CALL]: {
    slug: 'schedule-call',
    id: Step.SCHEDULE_CALL,
    label: 'Schedule Call',
    progressOrder: 7,
  },
};
