<template>
  <div class="flex flex-col items-center">
    <HexNum class="flex-grow-0 flex-shrink-0 mb-4" variant="secondary">
      {{ number }}
    </HexNum>

    <TypeBody weight="strong" class="mb-4 text-center flex-grow" tag="div">
      {{ title }}
    </TypeBody>

    <BaseTag
      outline
      color="gray"
      size="medium"
      class="flex-grow-0 flex-shrink-0 mb-4"
    >
      {{ length }} min
    </BaseTag>

    <TypeBody
      variant="caption"
      weight="medium"
      class="text-center text-base-600"
      tag="div"
    >
      {{ price }} per employee
    </TypeBody>
  </div>
</template>

<script>
import { TypeBody, BaseTag } from '@bambeehr/pollen';
import HexNum from '@/components/utility/HexNum/hex-num.vue';

export default {
  name: 'TrainingRegistrationCourseCallout',
  components: {
    TypeBody,
    BaseTag,
    HexNum,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    // length = minutes
    length: {
      type: Number,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
  },
};
</script>
