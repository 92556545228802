

























































import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import EmployerTrainingDetailListItem from './EmployerTrainingDetailListItem';
import { Statuses } from '@/components/StatusIndicator';
import useEmployerTrainingDetails from './useEmployerTrainingDetail';
import BundleBreakdownModal from '@/modules/Training/components/BundleBreakdownModal';

import {
  TextInput,
  SelectInput,
  AnnularThrobber,
  TypeBody,
} from '@bambeehr/pollen';

const statusOptions = [
  {
    label: 'In Progress',
    value: Statuses.IN_PROGRESS,
  },
  {
    label: 'Completed',
    value: Statuses.COMPLETED,
  },
  {
    label: 'Not Started',
    value: Statuses.NOT_STARTED,
  },
];

export default defineComponent({
  components: {
    AnnularThrobber,
    BundleBreakdownModal,
    EmployerTrainingDetailListItem,
    SelectInput,
    TextInput,
    TypeBody,
  },
  setup() {
    const router = useRouter();
    const {
      currentUserList,
      filteredUserList,
      isLoading,
      searchQuery,
      unenrollUser,
      currentContent,
      toggleProgressModal,
      progressModalItem,
    } = useEmployerTrainingDetails();

    const goToProfile = (id) => {
      router.push(`/employee/${id}/profile`);
    };

    return {
      currentContent,
      currentUserList,
      filteredUserList,
      goToProfile,
      isLoading,
      searchQuery,
      statusOptions,
      unenrollUser,
      toggleProgressModal,
      progressModalItem,
    };
  },
});
