<template>
  <div
    class="hex-num relative w-8 h-8"
    :class="{
      'text-base-300': isPrimary,
      'text-primary-weak': isSecondary,
    }"
  >
    <svg viewBox="0 0 32 32" class="fill-current h-full w-full">
      <path
        d="M18.4,0.6l9.4,5.5c1.4,0.8,2.6,2.8,2.6,4.5v10.8c0,1.7-1.1,3.6-2.6,4.5l-9.4,5.4c-0.7,0.4-1.7,0.6-2.6,0.6
	c-0.9,0-1.9-0.2-2.6-0.6l-9.4-5.4c-1.4-0.8-2.6-2.8-2.6-4.5V10.6c0-1.7,1.1-3.6,2.6-4.5l9.4-5.5C14.7-0.2,17-0.2,18.4,0.6z"
      />
      <path
        d="M18.4,0.6l9.4,5.5c1.4,0.8,2.6,2.8,2.6,4.5v10.8c0,1.7-1.1,3.6-2.6,4.5l-9.4,5.4c-0.7,0.4-1.7,0.6-2.6,0.6
	c-0.9,0-1.9-0.2-2.6-0.6l-9.4-5.4c-1.4-0.8-2.6-2.8-2.6-4.5V10.6c0-1.7,1.1-3.6,2.6-4.5l9.4-5.5C14.7-0.2,17-0.2,18.4,0.6z"
      />
      <path
        d="M18.4,0.7l9.4,5.5c1.4,0.8,2.6,2.8,2.6,4.5v10.8c0,1.7-1.1,3.6-2.6,4.5l-9.4,5.4c-0.7,0.4-1.7,0.6-2.6,0.6
	c-0.9,0-1.9-0.2-2.6-0.6l-9.4-5.4c-1.4-0.8-2.6-2.8-2.6-4.5V10.6c0-1.7,1.1-3.6,2.6-4.5l9.4-5.5C14.7-0.2,17-0.2,18.4,0.7z"
      />
    </svg>
    <TypeBody
      class="top-0 left-0 absolute flex justify-center items-center w-full h-full"
      :class="{
        'text-base-700': isPrimary,
        'text-primary-default': isSecondary,
      }"
      tag="div"
      weight="strong"
      variant="link-medium-tight"
    >
      <slot />
    </TypeBody>
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api';
import { TypeBody } from '@bambeehr/pollen';

const Variants = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
});

export default {
  name: 'HexNum',
  components: {
    TypeBody,
  },
  props: {
    variant: {
      type: String,
      required: false,
      default: Variants.PRIMARY,
    },
  },
  setup(props) {
    const isPrimary = ref(props.variant === Variants.PRIMARY);
    const isSecondary = ref(props.variant === Variants.SECONDARY);

    return {
      isPrimary,
      isSecondary,
    };
  },
};
</script>
