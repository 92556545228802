


















import {
  defineComponent,
} from '@nuxtjs/composition-api';
import { TypeDisplay, TypeBody, BaseIcon } from '@bambeehr/pollen';

export default defineComponent({
  name: 'PayrollCancellationConfirmationModal',
  components: {
    BaseIcon,
    TypeBody,
    TypeDisplay,
  },
  props: {},
  emits: ['close'],
});
