<template>
  <div class="flex gap-8 items-center">
    <TextInput
      v-model="formState.filter"
      class="flex-grow"
      pre-icon="search"
      placeholder="Search Staff"
    />

    <CheckboxInput
      label="Select All"
      label-position="left"
      class="flex-shrink-0"
      :value="isSelectAll"
      :disabled="completelyEnrolled || !state.unfilledSeats"
      @input="toggleSelectAll"
    />
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { TextInput, CheckboxInput } from '@bambeehr/pollen';
import useTrainingEnrollmentForm from '@/modules/Training/hooks/useTrainingEnrollmentForm/useTrainingEnrollmentForm';

export default {
  name: 'TrainingEnrollmentFormFilter',
  components: {
    TextInput,
    CheckboxInput,
  },
  setup() {
    const { state, select, deselect } = useTrainingEnrollmentForm();

    const completelyEnrolled = computed(() => {
      return !state.loading && state.enrolled.length === state.allUsers.length;
    });

    const isSelectAll = computed(() => {
      return (
        completelyEnrolled.value ||
        !!(
          state.notEnrolled.length &&
          state.selected.length === state.notEnrolled.length
        )
      );
    });

    const toggleSelectAll = () => {
      const idsToToggle = state.notEnrolled.map((ee) => ee.id);

      if (isSelectAll.value) {
        deselect(idsToToggle);
      } else {
        select(idsToToggle);
      }
    };

    return {
      state,
      isSelectAll,
      toggleSelectAll,
      formState: state,
      completelyEnrolled,
    };
  },
};
</script>
