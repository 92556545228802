import { getFullAddress } from '@/utils/address';
import { Address } from '@@/types/company';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const { Lookup: AutoComplete } = SmartyStreetsSDK.usAutocompletePro;
const { Lookup: Verify } = SmartyStreetsSDK.usStreet;

const credentials = new SmartyStreetsCore.SharedCredentials(
  <string>process.env.SMARTY_STREETS_KEY
);
const builderAutoComplete = new SmartyStreetsCore.ClientBuilder(
  credentials
).withLicenses(['us-autocomplete-pro-cloud']);
const builderVerify = new SmartyStreetsCore.ClientBuilder(credentials)
  .withBaseUrl(<string>process.env.HOST)
  .withLicenses(['us-core-cloud']);

const clientAutoComplete = builderAutoComplete.buildUsAutocompleteProClient();
const clientVerify = builderVerify.buildUsStreetApiClient();

export interface AddressValidation {
  isValid: boolean;
  needsFormatting?: boolean;
  validatedAddress?: Address;
  fullAddress?: string;
}

export const addressesAreEqual = (
  formatted: Address,
  original: Address
): boolean => {
  const getFirstLine = (address) =>
    `${address.line1} ${address.line2 || ''}`.replace(/\s\s/g, ' ').trim();

  return (
    getFirstLine(formatted) === getFirstLine(original) &&
    formatted.state === original.state &&
    formatted.zip === original.zip &&
    formatted.city === original.city
  );
};

export const validateAddress = (
  address: Address
): Promise<AddressValidation> => {
  return new Promise((resolve, reject) => {
    const verify = new Verify();

    verify.street = address.line1;
    verify.secondary = address.line2 || '';
    verify.city = address.city;
    verify.state = address.state;
    verify.zipCode = address.zip;
    verify.match = 'enhanced';

    clientVerify
      .send(verify)
      .then((response) => {
        const result = response?.lookups[0]?.result[0];
        // When a result it returned, that means that we have a valid address
        // When using 'ehhanced' match we need to make sure that the recordType is not null as that would indicate an invalid address
        const isValid = !!result && !!result.metadata?.recordType;

        if (isValid) {
          const validatedAddress = {
            line1: result.deliveryLine1,
            line2: result.deliveryLine2 || '',
            city: result.components.cityName,
            state: result.components.state,
            zip: result.components.zipCode,
          };

          resolve({
            validatedAddress,
            isValid,
            // Even if the address is valid, it may need some formatting updates
            needsFormatting: !addressesAreEqual(validatedAddress, address),
            fullAddress: getFullAddress(validatedAddress),
          });
        }

        resolve({
          isValid,
        });
      })
      .catch((err) => reject(err));
  });
};

export const suggestAddresses = (
  addressPartial: string,
  options?: { maxResults: number }
): Promise<Address[]> => {
  return new Promise((resolve, reject) => {
    const lookup = new AutoComplete(addressPartial);

    Object.assign(lookup, options);

    clientAutoComplete
      .send(lookup)
      .then(({ result }) => {
        resolve(
          result.map(
            ({
              streetLine: line1,
              secondary: line2,
              zipcode: zip,
              city,
              state,
            }) => {
              // Align to our formatting styles
              return {
                line1,
                line2,
                zip,
                city,
                state,
              };
            }
          )
        );
      })
      .catch((err) => reject(err));
  });
};
