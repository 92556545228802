export const EarningType = Object.freeze({
  AMOUNT_TIME: {
    label: 'Payment (hours)',
    value: 'AMOUNT_TIME',
  },
  AMOUNT_VALUE: {
    label: 'Payment (dollars)',
    value: 'AMOUNT_VALUE',
  },
  BONUS: {
    label: 'Bonus',
    value: 'BONUS',
  },
  CASH_TIPS: {
    label: 'Cash Tips',
    value: 'CASH_TIPS',
  },
  COMMISSION: {
    label: 'Commission',
    value: 'COMMISSION',
  },
  DOUBLE_OVERTIME: {
    label: 'Double Overtime',
    value: 'DOUBLE_OVERTIME',
  },
  GROUP_TERM_LIFE: {
    label: 'Group Term Life',
    value: 'GROUP_TERM_LIFE',
  },
  HOURLY: {
    label: 'Regular Hours',
    value: 'HOURLY',
  },
  OTHER_IMPUTED: {
    label: 'Other Imputed',
    value: 'OTHER_IMPUTED',
  },
  NON_HOURLY_REGULAR: {
    label: 'Non-hourly Regular',
    value: 'NON_HOURLY_REGULAR',
  },
  OVERTIME: {
    label: 'Overtime',
    value: 'OVERTIME',
  },
  PAID_HOLIDAY: {
    label: 'Paid Holiday (1x)',
    value: 'PAID_HOLIDAY',
  },
  PAID_HOLIDAY_1_5X: {
    label: 'Paid Holiday (1.5x)',
    value: 'PAID_HOLIDAY_1_5X',
  },
  PAYCHECK_TIPS: {
    label: 'Paycheck Tips',
    value: 'PAYCHECK_TIPS',
  },
  PTO: {
    label: 'PTO',
    value: 'PTO',
  },
  REIMBURSEMENT: {
    label: 'Reimbursement',
    value: 'REIMBURSEMENT',
  },
  SALARIED: {
    label: 'Salary  ',
    value: 'SALARIED',
  },
  SEVERANCE: {
    label: 'Severance',
    value: 'SEVERANCE',
  },
  SICK: {
    label: 'Sick',
    value: 'SICK',
  },
  ALLOWANCE: {
    label: 'Allowance',
    value: 'ALLOWANCE',
  },
  REPORTED_ALLOWANCE: {
    label: 'Reported Allowance',
    value: 'REPORTED_ALLOWANCE',
  },
  CLERGY_HOUSING: {
    label: 'Clergy Housing',
    value: 'CLERGY_HOUSING',
  },
  REPORTED_CLERGY_HOUSING: {
    label: 'Reported Clergy Housing',
    value: 'REPORTED_CLERGY_HOUSING',
  },
});

// All options until we have correct values coming from BE
export const AllOptions = [
  EarningType.SALARIED,
  EarningType.HOURLY,
  EarningType.OVERTIME,
  EarningType.DOUBLE_OVERTIME,
  EarningType.NON_HOURLY_REGULAR,
  EarningType.PTO,
  EarningType.SICK,
  EarningType.CASH_TIPS,
  EarningType.PAID_HOLIDAY,
  EarningType.PAID_HOLIDAY_1_5X,
  EarningType.PAYCHECK_TIPS,
  EarningType.BONUS,
  EarningType.COMMISSION,
  EarningType.REIMBURSEMENT,
  EarningType.SEVERANCE,
];

// The following earning types require an hours amount
export const HourlyTypes = [
  EarningType.AMOUNT_TIME,
  EarningType.DOUBLE_OVERTIME,
  EarningType.HOURLY,
  EarningType.OVERTIME,
  EarningType.PAID_HOLIDAY,
  EarningType.PAID_HOLIDAY_1_5X,
  EarningType.PTO,
  EarningType.SALARIED,
  EarningType.SICK,
];

// The following earning types do not accept an hours amount.
export const NonHourlyTypes = [
  EarningType.AMOUNT_VALUE,
  EarningType.NON_HOURLY_REGULAR,
  EarningType.BONUS,
  EarningType.CASH_TIPS,
  EarningType.COMMISSION,
  EarningType.GROUP_TERM_LIFE,
  EarningType.OTHER_IMPUTED,
  EarningType.PAYCHECK_TIPS,
  EarningType.REIMBURSEMENT,
  EarningType.SEVERANCE,
  EarningType.ALLOWANCE,
  EarningType.REPORTED_ALLOWANCE,
  EarningType.CLERGY_HOUSING,
  EarningType.REPORTED_CLERGY_HOUSING,
];

export const ContractContractorTypes = [
  EarningType.AMOUNT_VALUE,
  EarningType.REIMBURSEMENT,
];

export const ContractorTypes = [
  EarningType.AMOUNT_TIME,
  EarningType.AMOUNT_VALUE,
  EarningType.REIMBURSEMENT,
];

export const NPOTypes = [EarningType.ALLOWANCE, EarningType.REPORTED_ALLOWANCE];

export const ReligiousNPOTypes = [
  EarningType.ALLOWANCE,
  EarningType.REPORTED_ALLOWANCE,
  EarningType.CLERGY_HOUSING,
  EarningType.REPORTED_CLERGY_HOUSING,
];
