import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import { DefaultLandingRoutes } from '@/lib/globals/routes';
import { OnboardingMilestoneTag } from '@/modules/OnboardingWizard/constants/OnboardingMilestoneTag';
import { Milestones } from '@/modules/TaskCenter/types/Milestones';
import { getEventScheduledStatus } from '@/gql/services/payroll.service';
import { hrIntroCallEvent } from '@/lib/globals/calendly';

import getPlanPackage from '@/helpers/accessControl/getPlanPackage';
import useCompanyStore from '@/store/companies';

export const DONT_REDIRECT_PATH = '/lets-go';

const filterSkippableMilestones = (onboardingMilestones) => {
  return onboardingMilestones.filter(
    (m) =>
      // remove the milestones that are skippable
      m.milestone !== Milestones.WELCOME_CALL &&
      m.milestone !== Milestones.ACKNOWLEDGE_HRM
  );
};

const filterLitePlanMilestones = (onboardingMilestones) => {
  return onboardingMilestones.filter(
    (m) =>
      // remove the milestones that are skippable
      m.milestone !== Milestones.WELCOME_CALL &&
      m.milestone !== Milestones.ACKNOWLEDGE_HRM &&
      m.milestone !== Milestones.ADD_STAFF
  );
};

const filterRoadmapMilestone = (onboardingMilestones) => {
  return onboardingMilestones.filter(
    (m) => m.milestone !== Milestones.WELCOME_CALL
  );
};

let isEventScheduled;

export default async ({ redirect, store, route }) => {
  const { currentUser } = store.getters;

  if (!currentUser) {
    return;
  }

  if (isEventScheduled === undefined) {
    const { data: eventData } = await getEventScheduledStatus({
      email: currentUser._auth.email,
      eventSlug: hrIntroCallEvent,
    });

    isEventScheduled = !!eventData?.isScheduled;
  }
  const { isBambeeLite } = await getPlanPackage(
    currentUser?._company?.stripe?.customer?.id
  );

  const { currentPlan } = useCurrentPlan({ store });

  let onboardingMilestones = useCompanyStore().onboardingMilestonesByTag(
    OnboardingMilestoneTag.INTRO
  );

  if (!onboardingMilestones) {
    redirect(DefaultLandingRoutes.USER.path);

    return;
  }

  // if current plan is chatOnly
  // filter out the milestones that are skippable
  // they should not be considered when checking if onboarding is completed
  // otherwise, check if all onboarding milestones are completed
  let onboardingCompleted;

  if (isEventScheduled) {
    onboardingMilestones = filterRoadmapMilestone(onboardingMilestones);
  }

  if (isBambeeLite) {
    onboardingCompleted = filterLitePlanMilestones(onboardingMilestones).every(
      (m) => m.status
    );
  } else if (currentPlan.value?.chatOnly) {
    onboardingCompleted = filterSkippableMilestones(onboardingMilestones).every(
      (m) => m.status
    );
  } else {
    onboardingCompleted = onboardingMilestones.every((m) => m.status);
  }

  const dontRedirect = route.path.includes(DONT_REDIRECT_PATH);

  if (onboardingCompleted && !dontRedirect) {
    redirect(DefaultLandingRoutes.USER.path);
  }
};
