import { getEmployeeStatus } from '@/gql/services/payroll.service';

const validateEmployeeStatus = async (store) => {
  const { currentUser } = store.getters;

  const { data: user } = await getEmployeeStatus(currentUser._id, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return !!user?.deputyId;
};

export const canAccess = async (store) => {
  const userIsTimeAndAttendanceUser = await validateEmployeeStatus(store);

  return userIsTimeAndAttendanceUser;
};

export const guard = async ({ redirect, store }) => {
  const canAccessTimeAndAttendance = await validateEmployeeStatus(store);

  if (!canAccessTimeAndAttendance) {
    redirect('/');
  }
};

export default guard;
