import FeatureFlags from '@/constants/FeatureFlags';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany/useCurrentCompany';
import HrPracticePreviewModal from '@/modules/OnLoginTasks/components/modals/HrPracticePreviewModal.vue';
import ReportCardPreviewModal from '@/modules/OnLoginTasks/components/modals/ReportCardPreviewModal.vue';

import {
  useGetMyCoreUserQuery,
  useUpdateCoreUserStateMutation,
} from '@/gql/generated';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import { computed, getCurrentInstance, ref } from '@nuxtjs/composition-api';

let initiated;
const isRootModalOpen = ref<boolean>(false);
const isFetching = ref<boolean>();
const userStatesList = ref<any>();
const userRole = ref<string>('');
const bciMigratedFeatureFlag = computed<boolean>(
  () => launchDarkly.getFlags()[FeatureFlags.ENABLE_BCI_TO_HR_COMPLIANCE]
);
const { companySettings } = useCurrentCompany();
const showBciReportCardPreviewModal = ref<boolean>();
const canShowHrPracticePreviewModal = computed(
  () =>
    companySettings.value.bciToHRPracticeMigrated &&
    Boolean(!userStatesList.value?.viewedBciToHrPracticeModal) &&
    userRole.value === 'user'
);
const canShowBciReportCardPreviewModal = computed(
  () =>
    companySettings.value.bciToHRPracticeMigrated &&
    Boolean(!userStatesList.value?.viewedBciToHrPracticeReportCardModal) &&
    userRole.value === 'user'
);

const componentList = computed(() => {
  const compList: any[] = [];
  if (isFetching.value) {
    return compList;
  }
  if (bciMigratedFeatureFlag.value) {
    if (canShowHrPracticePreviewModal.value) {
      compList.push(HrPracticePreviewModal);
    }
    if (
      showBciReportCardPreviewModal.value &&
      canShowBciReportCardPreviewModal.value
    ) {
      compList.push(ReportCardPreviewModal);
    }
  }

  return compList;
});

// Set Report Card Modal
const setReportCardPreviewModal = (value) => {
  showBciReportCardPreviewModal.value = value;
};

/**
 * Fetch User States
 */
const fetchUserStates = () => {
  // @ts-ignore: Type error on adding undefined
  const { onResult } = useApolloQuery(useGetMyCoreUserQuery);

  onResult((result) => {
    const { getMyCoreUser } = result;
    userStatesList.value = getMyCoreUser.states;
    userRole.value = getMyCoreUser.role;
    isFetching.value = false;
  });
};

const openRootModal = () => {
  isRootModalOpen.value = true;
};

const closeRootModal = () => {
  if (!componentList.value.length) {
    isRootModalOpen.value = false;
  }
};

const toggleUserStateOff = ({ key, value }) => {
  const { mutate: updateUserState, onDone: onUpdatedUserState } =
    useApolloMutation(useUpdateCoreUserStateMutation);
  updateUserState({
    input: {
      [key]: value,
    },
  });
  onUpdatedUserState((data) => {
    userStatesList.value = data.updateCoreUserState;
    closeRootModal();
  });
};

const setup = () => {
  initiated = true;
  isFetching.value = true;
  fetchUserStates();
};

const useOnLoginTasks = () => {
  const vueInstance = getCurrentInstance();

  if (!initiated && vueInstance) {
    setup();
  }

  return {
    closeRootModal,
    componentList,
    isFetching,
    isRootModalOpen,
    canShowHrPracticePreviewModal,
    canShowBciReportCardPreviewModal,
    userStatesList,
    openRootModal,
    setReportCardPreviewModal,
    showBciReportCardPreviewModal,
    toggleUserStateOff,
  };
};

export default useOnLoginTasks;
