<template>
  <ActionPendingModal message="Purchasing the Core Training Bundle">
    <ContentfulRichText
      v-if="purchaseinProgressText"
      :content="purchaseinProgressText"
    />
  </ActionPendingModal>
</template>

<script>
import ContentfulRichText from '@/components/contentful/ContentfulRichText';
import ActionPendingModal from '@/components/ActionPendingModal/ActionPendingModal.vue';
import usePurchaseTrainingContent from '@/modules/Training/components/PurchaseTraining/usePurchaseTrainingContent';

export default {
  name: 'PurchaseTrainingPendingModal',
  components: {
    ContentfulRichText,
    ActionPendingModal,
  },
  setup() {
    const { purchaseinProgressText } = usePurchaseTrainingContent();

    return {
      purchaseinProgressText,
    };
  },
};
</script>
