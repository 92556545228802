





















import {
  defineComponent,
  ref,
  PropType,
  computed,
} from '@nuxtjs/composition-api';
import { AnnularThrobber } from '@bambeehr/pollen';
import FaqItem from '@/components/FaqItem';
import Contentful from '@/services/Contentful';
import ContentfulRichText from '@/components/contentful/ContentfulRichText';

enum Emits {
  CLOSE = 'close',
}

interface Faq {
  question: string;
  answer: string;
}

const parseToFaqList = (content: any): Faq[] =>
  content.fields.list.map((item) => ({
    question: item.fields.title,
    answer: item.fields.body,
  }));

export default defineComponent({
  name: 'FqaDrawer',
  components: {
    FaqItem,
    ContentfulRichText,
    AnnularThrobber,
  },
  props: {
    entryId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  emits: [Emits.CLOSE],
  setup(props) {
    const content = ref<Faq[]>([]);
    const isLoading = ref(true);

    // Get content
    (async () => {
      const res = await Contentful.getEntry(props.entryId);

      content.value = parseToFaqList(res);
      isLoading.value = false;
    })();

    const listGroups = computed(() => {
      const marker = Math.ceil(content.value.length / 2);
      const left = content.value.slice(0, marker);
      const right = content.value.slice(marker);

      return [left, right];
    });

    return {
      content,
      Emits,
      isLoading,
      listGroups,
    };
  },
});
