<template>
  <DrawerPanel
    :active="active"
    :show-close="false"
    @close="$emit('update:active', false)"
  >
    <template #header>
      <EarningsHeader
        v-if="!isLoading && !workerRolesLoading && employee"
        :full-name="employee.fullName"
        :employment-type="employee.employmentType"
        :pay-rate="payRate"
        :role="title"
        :pay-type="employee.payType"
        :is-contractor="item.isContractor"
        @backIconClick="$emit('update:active', false)"
      />
    </template>

    <div>
      <div
        v-if="isLoading || workerRolesLoading"
        class="flex items-center justify-center py-8 h-full"
      >
        <AnnularThrobber />
      </div>
      <PayrollItemForm
        v-else
        :is-contractor="item.isContractor"
        :payroll-item="item.item"
        :payroll-payment="item.payment"
        :employee-type="employee.payType"
        :pay-rate="payRate"
        :is-saving-form="isSavingForm"
        :roles="workerRoles"
        :worker-name="employee.fullName"
        :payroll-id="payrollId"
        :is-religious-org="isReligiousOrg"
        :is-non-profit="isNonProfit"
        @save="save"
        @discardEditsClick="$emit('update:active', false)"
      />
    </div>
  </DrawerPanel>
</template>

<script>
import { ref, computed, watch } from '@nuxtjs/composition-api';
import { DrawerPanel, AnnularThrobber } from '@bambeehr/pollen';
import EarningsHeader from '@/modules/payroll/components/EarningsDrawer/EarningsHeader';
import { EarningType } from '@/modules/payroll/constants/earning-types';
import PayrollItemForm from '@/modules/payroll/components/EarningsDrawer/PayrollItemForm';
import currency from '@bambeehr/currency';
import { getUserPreference, setUserPreference } from '@/utils/userPreference';
import useNotifications from '@bambeehr/use-notifications';

import {
  useUpdatePayrollItemMutation,
  useUpdateContractorPaymentMutation,
  useGetUserRolesQuery,
  useGetCompanyQuery,
  TaxExemptStatus,
} from '@/gql/generated';
import CachePolicy from '@/gql/CachePolicy';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useTour from '@/hooks/useTour';

export const getMismatchedPayInfo = (item) => {
  const { basePayRate } = item.item;
  const { payRate } = item.staff;

  return {
    isMismatched: basePayRate !== payRate,
    basePayRate: {
      raw: basePayRate,
      formatted: currency(basePayRate).format(),
    },
    payRate: {
      raw: payRate,
      formatted: currency(payRate).format(),
    },
  };
};

export default {
  components: {
    DrawerPanel,
    EarningsHeader,
    PayrollItemForm,
    AnnularThrobber,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    payrollId: {
      type: String,
      required: true,
    },
    forceDataFetch: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // BAMBO-1564: Temporary update until we refactor the payrollItemDrawer
    if (props.isLoading) {
      return {};
    }

    const { addSuccess } = useNotifications();
    const { companyId } = useCurrentCompany();

    const workerRoles = ref([]);
    const workerRolesLoading = ref(false);
    const { onResult } = useApolloQuery(
      useGetUserRolesQuery,
      {
        showDeleted: true,
        userId: props.item.staff.id,
      },
      { pending: workerRolesLoading },
      undefined,
      {
        fetchPolicy: props.forceDataFetch
          ? CachePolicy.NETWORK_ONLY
          : CachePolicy.CACHE_FIRST,
      }
    );

    onResult(({ getCoreUserById: res }) => {
      workerRoles.value = res.employment.workerRoles.filter(
        (r) => !!r.companyRole
      );
    });

    const companyType = ref();
    useApolloQuery(
      useGetCompanyQuery,
      { id: companyId.value },
      { data: companyType },
      { placeholderPick: ({ getCompany: res }) => res.taxExemptStatus }
    );
    const isReligiousOrg = computed(
      () => companyType.value === TaxExemptStatus.ReligiousExempt
    );
    const isNonProfit = computed(
      () => companyType.value === TaxExemptStatus.NonProfitExempt
    );

    const { mutate: updatePayrollItem, onDone: doneUpdatingPayrollItem } =
      useApolloMutation(useUpdatePayrollItemMutation);
    const {
      mutate: updateContractorPayment,
      onDone: doneUpdatingContractorPayment,
    } = useApolloMutation(useUpdateContractorPaymentMutation);

    // We store their payRate selection in LS so that if they selected to keep
    // basePayRate we don't ask them to select it again.
    const payRatePreferenceKey = `pay-rate:${props.payrollId}-${props.item.staff.id}`;
    const payRatePreference = Number(getUserPreference(payRatePreferenceKey));

    const mismatchedPayInfo = computed(() => getMismatchedPayInfo(props.item));

    // It's possible for this particular edge case to happen multiple times in a payroll
    // so we need to verify that the local store that we're setting is still correct.
    const payPreferenceMatchesItem = computed(
      () =>
        payRatePreference === mismatchedPayInfo.value.basePayRate.raw ||
        payRatePreference === mismatchedPayInfo.value.payRate.raw
    );
    const hasMadePayRateSelection = ref(
      !!payRatePreference && payPreferenceMatchesItem.value
    );

    // Setting payRate based on the set preference if it exists and matches
    // otherwise we'll use the standard basePayRate (most cases).
    const primaryRole = computed(() =>
      workerRoles.value.find((r) => r.isPrimary)
    );
    const payRate = computed(() => primaryRole.value?.payRate);
    const title = computed(() => primaryRole.value?.companyRole?.title);
    const formattedPayRate = computed(() => currency(payRate.value).format());

    const isSavingForm = ref(false);
    const savingData = ref(null);
    const savingError = ref(null);

    const save = (form) => {
      if (isSavingForm.value) {
        return;
      }

      const payWithValue = form.pay.filter(
        ({ amount, minutes }) => !!amount || !!minutes
      );

      if (props.item.isContractor) {
        const pay = payWithValue.map(
          ({
            amount,
            minutes,
            id,
            type,
            workerRoleId,
            description,
            decimal,
          }) => ({
            description,
            workerRoleId,
            minutes,
            id: !Number.isNaN(+id) ? id : null,
            type,
            decimal,
            amount,
          })
        );

        const data = {
          basePayRate: payRate.value,
          entries: pay,
          id: form.id,
          note: form.note,
          noteVisible: form.noteVisible,
          paymentMethod: form.paymentMethod,
          version: form.version,
        };

        updateContractorPayment({ data });
        doneUpdatingContractorPayment(({ updateContractorPayment: res }) => {
          savingData.value = res;
        });
      } else {
        const pay = payWithValue
          .filter(({ type }) => type !== EarningType.REIMBURSEMENT.value)
          .map(
            ({
              amount,
              code,
              description,
              altDescription,
              minutes,
              decimal,
              id,
              type,
              workerRoleId,
              subtype,
            }) => ({
              workerRoleId,
              minutes,
              code,
              description: altDescription || description,
              id: !Number.isNaN(+id) ? id : null,
              type,
              subtype,
              decimal,
              amount,
            })
          );

        const reimbursements = payWithValue
          .filter(({ type }) => type === EarningType.REIMBURSEMENT.value)
          .map(({ amount, id }) => ({
            id: !Number.isNaN(+id) ? id : null,
            amount,
          }));

        const data = {
          basePayRate: payRate.value,
          earnings: pay,
          id: form.id,
          note: form.note,
          noteVisible: form.noteVisible,
          paymentMethod: form.paymentMethod,
          reimbursements,
          version: form.version,
        };

        updatePayrollItem({ data });

        doneUpdatingPayrollItem(({ updatePayrollItem: res }) => {
          savingData.value = res;
        });
      }
    };

    watch(savingData, (savedData) => {
      if (savedData) {
        emit('update:active', false);
        emit('updated', savedData);
      }
    });

    const employee = computed(() => props.item.staff);

    const updateBasePayRate = (rate) => {
      payRate.value = rate;
      hasMadePayRateSelection.value = true;
      addSuccess(
        `Using pay rate of ${formattedPayRate.value} for this payroll`
      );

      setUserPreference(payRatePreferenceKey, rate);
    };

    return {
      employee,
      formattedPayRate,
      hasMadePayRateSelection,
      isSavingForm,
      mismatchedPayInfo,
      payRate,
      save,
      updateBasePayRate,
      workerRoles,
      workerRolesLoading,
      title,
      isReligiousOrg,
      isNonProfit,
    };
  },
};
</script>
<style scoped>
>>> .drawer-panel {
  @apply max-w-full md:max-w-2xl;
}
</style>
