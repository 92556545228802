






















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import {
  NumberInput,
  TypeBody,
  TypeDisplay,
  TypeOverline,
} from '@bambeehr/pollen';
import currency from '@bambeehr/currency';
import usePurchaseTrainingContent from '@/modules/Training/components/PurchaseTraining/usePurchaseTrainingContent';

export default defineComponent({
  name: 'ManualSeatInput',
  components: {
    NumberInput,
    TypeBody,
    TypeDisplay,
    TypeOverline,
  },
  props: {
    value: {
      type: Number as PropType<Number>,
      required: false,
      default: 1,
    },
  },
  setup(_, { emit }) {
    const {
      pending: isLoading,
      manualStaffPrompt,
      pricePerBundle,
    } = usePurchaseTrainingContent();

    const onInput = (newNum: Number) => emit('input', newNum);

    const bundlePrice = computed(() => {
      const cost: Number = pricePerBundle.value;

      return currency(cost).format();
    });

    return {
      onInput,
      bundlePrice,
      manualStaffPrompt,
      isLoading,
    };
  },
});
