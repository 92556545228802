import { CANCELED } from '@/constants';

export const CompanyStatuses = Object.freeze({
  CANCELED,
  INACTIVE: 'inactive',
  LEAD: 'lead',
  PAYING: 'paying',
  TRIAL: 'trial',
});

export default {
  CompanyStatuses,
};
