type DecoratedExecute = (...args: any[]) => Promise<any>;
type CachedResults = Map<string, any>;
type ActiveFetches = Map<string, Promise<any>>;
type Count = Map<string, number>;

type DecoratedFn = {
    config: (tmpOptions: { force?: boolean }) => { execute: DecoratedExecute },
    execute: DecoratedExecute,
    count: Count
    cache: CachedResults
}
type FnDecorator = ((fetchingFn: Function) => DecoratedFn);

// List of registered fetch functions, mapped by key (function as string) to the decorated function
export const registeredFetchFns: Map<string, DecoratedFn> = new Map();


/**
 * This decorator function is used to register a memoized function that will manage cache and regulates the number of concurrent requests that can be made
 * @param fetchingFn - the fetch function to register
 * @returns a decorated function that can be used to execute or configure and then execute the fetch function
 */
const fnDecorator: FnDecorator = (fetchingFn: Function): DecoratedFn => {
    let defaultOptions = { force: false };
    let options = { ...defaultOptions };
    const cache: CachedResults = new Map();
    const activeFetches: ActiveFetches = new Map();
    const count: Count = new Map();

    const execute = async (...passThroughArgs) => {
        // If the force option is set, then we want to execute the fetch function regardless of whether or not it is cached
        const { force } = options;
        
        passThroughArgs = passThroughArgs || [];
        
        // Create a key to use for this permutation of arguments
        const uniqueCallKey = JSON.stringify(passThroughArgs);

        // If we already have an active fetch for this key, return the corresponding promise
        if (activeFetches.has(uniqueCallKey)) {
            return activeFetches.get(uniqueCallKey);
        }

        // If we have a cached result for this key, return the cached result unless told to force a fetch
        if (!force && cache.has(uniqueCallKey)) {
            return cache.get(uniqueCallKey);
        }

        // Create a promise that will be used to track the active fetch
        // This will be used to prevent multiple concurrent fetches, as any subsequent calls will return this promise and will resolve with the same result
        const fetchPromise = (async () => {
            return await fetchingFn(...passThroughArgs)
        })();

        // Add the promise to the active fetches map by key
        activeFetches.set(uniqueCallKey, fetchPromise);

        const result = await fetchPromise;

        // Cache the result, increment the count, and remove the active fetch by key
        cache.set(uniqueCallKey, result);
        count.set(uniqueCallKey, (count.get(uniqueCallKey) || 0) + 1);
        activeFetches.delete(uniqueCallKey);

        // Restore the default options
        options = defaultOptions;

        return result;
    }

    const config = (tmpOptions: { force?: boolean } = {}) => {
        options = {
            ...options,
            ...tmpOptions,
        }
        return { execute };
    }


    return {
        config,
        execute,
        // Snapshot of the current state of count
        get count(): Count {
            return new Map(count)
        },
        // Snapshot of the current state of cache
        get cache(): CachedResults {
            return new Map(cache)
        },
    }
}

/**
 * Register a fetch function to be "memoized" and have its concurrent calls managed
 * @param fn - the fetch function to register
 * @returns a decorated function wrapping your fetch function, allowing you to execute or configure and then execute in a controlled manner
 */
export const registerFetchFn = (fn: Function): DecoratedFn => {
    if (!fn) {
        throw new Error('fetch function is required');
    }

    // We want to register the function by a key that is unique to the function signature
    // The key should be derived and deterministic, so we can use the function.toString() to get a unique key
    const key = fn.toString();

    if (!!registeredFetchFns.get(key)) {
        return registeredFetchFns.get(key) as DecoratedFn; // Will never be undefined at this point
    }

    const decoratedFn = fnDecorator(fn);
    if (decoratedFn) {
        registeredFetchFns.set(key, decoratedFn);
    }

    return decoratedFn;
}

export default registerFetchFn;