import Vue from 'vue';

function create_ticket(data) {
  const t = {
    comments: [],
  };

  return Object.assign(t, data);
}

export const state = () => ({
  ids: [],
  _byId: {},

  users: [],
});

export const getters = {
  all(state) {
    return state.ids.map((id) => state._byId[id]);
  },
  get(state) {
    return function (id) {
      return state._byId[id];
    };
  },

  user(state) {
    return function (id) {
      return state.users.find((u) => u.id == id);
    };
  },
};

export const mutations = {
  set(state, payload) {
    const ticket = create_ticket(payload);

    if (!state._byId[ticket._id]) {
      state.ids.push(ticket._id);
    }

    Vue.set(state._byId, ticket._id, ticket);
  },

  setAll(state, payload) {
    const tickets = payload.map((t) => create_ticket(t));

    state.ids = tickets.map((t) => t._id);
    state._byId = tickets.reduce((byId, t) => {
      byId[t._id] = t;

      return byId;
    }, {});
  },

  setComments(state, { ticket_id, comments, users }) {
    const ticket = state._byId[ticket_id];

    ticket.comments = comments;

    state.users = users;
  },

  setComment(state, { ticket_id, comment }) {
    const ticket = state._byId[ticket_id];

    ticket.comments.push(comment);
  },
};

export const actions = {
  create({ commit }, ticket) {
    return this.$axios
      .post('/v0/app/ticket/create', {
        type: ticket.type,
        title: ticket.title,
        text: ticket.text,
        employee_id: ticket.employee_id,
        files: ticket.files,
        scheduleCall: ticket.scheduleCall,
        todo_type: ticket.todo_type || ticket.todoType,
      })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  getAll({ commit }) {
    return this.$axios.get('/v0/app/ticket/my').then((res) => {
      commit('setAll', res.data);

      return res.data;
    });
  },

  get({ commit }, ticket_id) {
    return this.$axios.get(`/v0/app/ticket/${ticket_id}`).then((res) => {
      commit('set', res.data);

      return res.data;
    });
  },

  respond({ commit }, { ticket_id, response }) {
    return this.$axios
      .post(`/v0/app/ticket/${ticket_id}/respond`, { response })
      .then((res) => {
        commit('setComment', { ticket_id,
comment: res.data.comment });

        return res.data;
      });
  },

  assign({ commit }, { ticket_id, user_id }) {
    return this.$axios
      .post(`/v0/app/ticket/${ticket_id}/assign`, { user_id })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  updateVisibility({ commit }, { ticket_id, hidden }) {
    return this.$axios
      .post(`/v0/app/ticket/${ticket_id}/update-visibility`, { hidden })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  changeStatus({ commit }, { ticket_id, status }) {
    return this.$axios
      .post(`/v0/app/ticket/${ticket_id}/change-status`, { status })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  getComments({ commit }, { ticket_id }) {
    return this.$axios
      .get(`/v0/app/ticket/${ticket_id}/comments`)
      .then((res) => {
        commit('setComments', {
          ticket_id,
          comments: res.data.comments,
          users: res.data.users,
        });
      });
  },
};
