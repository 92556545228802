import { useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentUser from '@/hooks/useCurrentUser';

import {
  BenefitOnboardStatus,
  GetEmployeeStatusQuery,
  useGetEmployeeStatusQuery,
} from '@/gql/generated';
import { computed, ref, watch } from '@nuxtjs/composition-api';

const user = ref<GetEmployeeStatusQuery>();
const isContractor = ref(false);

const canAccess = computed(() => {
  const status = user.value?.getEmployee?.benefitOnboarding?.status;

  return (
    !isContractor.value &&
    !!status &&
    status !== BenefitOnboardStatus.Ineligible
  );
});

const setBenefitsUser = (id) => {
  if (id && !isContractor.value) {
    useApolloQuery(
      useGetEmployeeStatusQuery,
      { id },
      { data: user },
      // Silent fail if user is not found.
      { handleErrors: false }
    );
  }
};

const getCompanyStatus = () => {
  const { currentUser } = useCurrentUser();

  watch(
    currentUser,
    (res) => {
      if (res) {
        isContractor.value = res?.profile?.contractor;
        setBenefitsUser(res?._id);
      }
    },
    { immediate: true }
  );
};

const useEmployerBenefitsAccess = () => {
  getCompanyStatus();

  return {
    canAccess,
  };
};

export default useEmployerBenefitsAccess;
