import {
  useGetResignationListQuery,
  useGetTerminationListQuery,
} from '@/gql/generated';
import { useApolloQuery } from '@/gql/apolloWrapper';

import { ref } from '@nuxtjs/composition-api';

const terminations = ref<Array<any>>([]);
const resignations = ref<Array<any>>([]);
export const endOfEmploymentListRaw = ref<any[]>([]);

export const fetchEndOfEmployment = (): void => {
  const { onResult: setTerminations } = useApolloQuery(
    useGetTerminationListQuery,
    {
      data: {
        limit: 100,
        offset: 0,
      },
    }
  );
  setTerminations((result) => {
    const { terminationList } = result;
    terminations.value = terminationList;
    endOfEmploymentListRaw.value = [
      ...resignations.value,
      ...terminations.value,
    ];
  });
  const { onResult: setResignations } = useApolloQuery(
    useGetResignationListQuery,
    {
      data: {
        limit: 100,
        offset: 0,
      },
    }
  );
  setResignations((result) => {
    const { resignationList } = result;
    resignations.value = resignationList;
    endOfEmploymentListRaw.value = [
      ...resignations.value,
      ...terminations.value,
    ];
  });
};

export default null;
