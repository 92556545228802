




































import { TypeBody } from '@bambeehr/pollen';
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

interface Crumb {
  label: string;
  to?: string;
  handler?: () => {};
}

export default defineComponent({
  name: 'BreadCrumb',
  components: {
    TypeBody,
  },
  props: {
    crumbs: {
      type: Array as PropType<Crumb[]>,
      required: true,
    },
  },
  setup(props) {
    const currentCrumb = computed<Crumb>(() => {
      if (props.crumbs && props.crumbs.length > 0) {
        return props.crumbs[props.crumbs.length - 1];
      }

      return { label: '' };
    });

    return {
      currentCrumb,
    };
  },
});
