import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import Routes from '@/modules/Training/constants/routes';
import canAccessTrainingModule from '@/modules/Training/helpers/canAccessTrainingModule';
import hasPurchasedTraining from '@/modules/Training/helpers/hasPurchasedTraining';
import isTrainingEmployerRole from '@/modules/Training/helpers/isTrainingEmployerRole';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import FeatureFlags from '@/constants/FeatureFlags';
import getPlanPackage from '@/helpers/accessControl/getPlanPackage';

export default async ({
  store,
  redirect,
  route,
}: {
  store: any;
  redirect: any;
  route: any;
}): Promise<void> => {
  const isAdmin = isTrainingEmployerRole(store);
  const {
    currentUser,
    companyId,
  }: {
    currentUser: any;
    companyId: String;
  } = store.getters;
  const { _id: userId }: { _id: String } = currentUser;

  // First: Check if the user has base access to the module
  // This should cover employers ineligible for purchasing as well as employees without courses
  const hasBaseAccess: Boolean = await canAccessTrainingModule(userId, store);

  if (!hasBaseAccess) {
    redirect('/');

    return;
  }

  const { isTierZero } = useCurrentPlan({ store });

  // if the user is tier zero, don't redirect them to the purchase page
  if (isTierZero.value) {
    return;
  }

  // Second: If the user hasn't purchased Bambee's in app training, send them to purchase it
  // Only show this if the new feature flag is on.
  const hasPurchased: Boolean = await hasPurchasedTraining(companyId);

  // Catalog feature flag checks
  // If the customer has already purchased training we'll consider the catalog enabled
  const isCatalogEnabled =
    (await launchDarkly.getFlags()[FeatureFlags.TRAINING_CATALOG]) ||
    hasPurchased;

  if (!hasPurchased && !isCatalogEnabled) {
    // If the user is admin, send them to the register page.
    // Send EEs to root as they can't register for the company.
    redirect(isAdmin ? Routes.REGISTRATION : '/');
  }

  const { isBambeeLite } = await getPlanPackage(
    currentUser?._company?.stripe?.customer?.id
  );

  if (!hasPurchased && isCatalogEnabled && route.path === Routes.ER_PORTAL) {
    // Send the user to the training overview if they haven't purchased training
    if (isBambeeLite) {
      redirect(Routes.ER_CATALOG);
    }

    redirect(Routes.ER_OVERVIEW);
  }

  // Checking user's role and redirecting to the correct page
  if (!isAdmin && route.path.includes(Routes.ER_PORTAL)) {
    redirect(Routes.EE_PORTAL);
  }

  if (route.path.includes(Routes.ER_CATALOG) && !isCatalogEnabled) {
    redirect(Routes.ER_PORTAL);
  }

  if (route.path.includes(Routes.EE_CATALOG) && !isCatalogEnabled) {
    redirect(Routes.EE_PORTAL);
  }
};
