

















































import { TypeDisplay, BaseCard, TypeBody, BaseButton } from '@bambeehr/pollen';
import { defineComponent } from '@nuxtjs/composition-api';
import SquareTag from '@/components/SquareTag/SquareTag.vue';
import CatalogRecommendedTag from '@/modules/Training/components/ContentCatalog/CatalogRecommendedTag';

export default defineComponent({
  name: 'CourseCard',
  components: {
    TypeDisplay,
    BaseCard,
    TypeBody,
    BaseButton,
    SquareTag,
    CatalogRecommendedTag,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    mins: {
      type: Number,
      default: 0,
    },
    postText: {
      type: String,
      default: '',
    },
    isRecommended: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {},
});
