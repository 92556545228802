import { readonly, ref } from '@nuxtjs/composition-api';
import shortId from 'shortid';

export const NotificationTypes = Object.freeze({
  FATAL: 'fatal',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
});

const notifications = ref([]);

/**
 * Adds a notifcation to the stack
 * @param {Object} param0 Notification configuration. Requires at least `message`. Other optional properties are: id (generated if not provided), category (for ease of filtering), and meta (for any other properties needed)
 * @returns {Number} Returns id of notification since this could be shortId generated
 */
const add = ({
  message,
  title,
  category,
  meta = {},
  type = NotificationTypes.INFO,
  id = shortId.generate(),
}) => {
  if (!message && !title) {
    return null;
  }

  notifications.value.push({
    title,
    message,
    category,
    type,
    meta,
    id,
  });

  return id;
};

const addError = (err, options) => {
  add({
    ...options,
    type: NotificationTypes.ERROR,
    message: err?.message || err?.[0]?.message || err || 'Something went wrong',
  });
};

const addFatal = (err, options) => {
  add({
    ...options,
    type: NotificationTypes.FATAL,
    message:
      err?.message ||
      err?.[0]?.message ||
      err ||
      'Something went terribly wrong',
  });
};

const addSuccess = (message, options) => {
  add({
    ...options,
    type: NotificationTypes.SUCCESS,
    message,
  });
};

const addInfo = (message, options) => {
  add({
    ...options,
    type: NotificationTypes.INFO,
    message,
  });
};

const remove = (id) => {
  notifications.value = notifications.value.filter((n) => n.id !== id);
};

export const useNotifications = () => {
  return {
    add,
    addInfo,
    addFatal,
    addError,
    addSuccess,
    remove,
    notifications: readonly(notifications),
  };
};

export default useNotifications;
