import { computed, ComputedRef, ref, Ref } from '@nuxtjs/composition-api';
import { policyInScope } from '@/hooks/usePolicies/usePolicies';
import mapUsersToPolicyStaffMembers from '@/modules/PolicyCenter/utils/mapPolicyUsers';
import { PolicyStaffMember } from '@/modules/PolicyCenter/models';
import { StaffStates } from '@/modules/StaffManagement/models';
import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';

interface ItemizedStaff {
  [key: string]: PolicyStaffMember;
}

/**
 * Settings / Utilities (Fuse Search Options)
 */
const Fuse = require('fuse.js');

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  keys: ['profile.staffName', 'profile.jobTitle', 'roles'],
};

/**
 * STATES
 */
const policyStaffList = ref<PolicyStaffMember[]>([]);
const searchQuery: Ref<string | null> = ref(null);

/**
 * COMPUTED / GETTERS
 */
const documentIds = computed<Array<string> | undefined>(() =>
  policyInScope.value?._documents.map((doc) => doc._id as string)
);

/**
 * Keyed by ID Staff List
 */
const itemizedStaffList: ComputedRef<ItemizedStaff> = computed(() =>
  keyBy(policyStaffList.value, 'id')
);

/**
 * searchedEmployees is the final result for this hook for the StaffList value
 */
export const searchedEmployees: ComputedRef<PolicyStaffMember[]> = computed(
  () => {
    if (searchQuery.value) {
      return new Fuse(policyStaffList.value, fuseOptions).search(
        searchQuery.value
      );
    }

    return policyStaffList.value;
  }
);

/**
 * METHOD / SETUP
 * Sets the main policyStaffList which sets all other computed values.
 * using PolicyCenter own mapper to add
 */
const setPolicyStaffList = (staff: any[] | undefined = []) => {
  /**
   * Map RawSimplifiedEmployees List to PolicyStaffMember
   * Then Filter out NOT_INVITED (Not Onboarded) staff states
   */
  policyStaffList.value = mapUsersToPolicyStaffMembers(
    staff, // use recipients once "NOT SENT EMPLOYEES" gql end point is available
    documentIds.value
  ).filter(
    (policyStaff: PolicyStaffMember) =>
      policyStaff.staffState !== StaffStates.NOT_INVITED
  );
};

// Set Search Query
const setSearchQuery = (value: string | null): void => {
  const query = value;
  debounce(() => {
    searchQuery.value = query;
  }, 250)();
};

const usePolicyStaffList = () => {
  return {
    itemizedStaffList,
    policyStaffList,
    searchedEmployees,
    searchQuery,
    setPolicyStaffList,
    setSearchQuery,
  };
};

export default usePolicyStaffList;
