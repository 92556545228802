/**
 * Takes a person's first and last name and returns their formatted initials
 * @param {string} firstName - first name to extract initial from
 * @param {string} lastName - last name to extract initial from
 * @returns {string} Formatted initials or empty string
 */
export function getInitials(firstName = '', lastName = '') {
  const argsAreStrings = [firstName, lastName].every(
    (name) => typeof name === 'string'
  );

  if (!argsAreStrings || !firstName || !lastName) {
    console.warn(`Cannot create initials`);

    return '';
  }

  return `${firstName[0]}${lastName[0]}`.toUpperCase();
}

export function getFirstNameWithInitial(fullName) {
  const [firstName, lastName] = fullName.split(' ');

  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName.charAt(0)}.`;
}

export default {
  getInitials,
  getFirstNameWithInitial,
};
