<template>
  <div>
    <nuxt-child :key="$route.path" class="mt-8" />
  </div>
</template>

<script>
import { trainingRegistrationRouteGuard } from '@/modules/Training/middleware';

export default {
  name: 'TrainingRegistrationRoot',
  middleware: [trainingRegistrationRouteGuard],
};
</script>
