














































































import SimpleCard from '@/components/SimpleCard/SimpleCard.vue';
import american from '@/modules/Settings/assets/cc-icons/American Express.svg';
import blank from '@/modules/Settings/assets/cc-icons/Blank.svg';
import diner from '@/modules/Settings/assets/cc-icons/Dinersclub.svg';
import discover from '@/modules/Settings/assets/cc-icons/Discover.svg';
import jcb from '@/modules/Settings/assets/cc-icons/JCB.svg';
import mastercard from '@/modules/Settings/assets/cc-icons/Mastercard.svg';
import visa from '@/modules/Settings/assets/cc-icons/Visa.svg';
import { convertToDollars } from '@/utils/currency';
import {
  BaseButton,
  BaseCard,
  BaseDivider,
  BaseIcon,
  BaseTag,
  ModalDialog,
  TypeBody,
  TypeDisplay,
  TypeOverline,
} from '@bambeehr/pollen';
import { PropType, computed, defineComponent } from '@nuxtjs/composition-api';

interface CreditCard {
  id: string;
  brand: string;
  last4: string;
  expMonth: string;
  expYear: string;
}

interface IOrderDetails {
  totalBilledNow: number;
  totalTaxNow: number;
  annualSavings: number;
  proratedDiscountAtPurchase: number;
  planCost: number;
  planGrossAmount: number;
  annualDiscount: number;
}

enum CreditCardBrand {
  AMERICAN = 'American Express',
  DINERS = 'Diners Club',
  DISCOVER = 'Discover',
  JCB = 'JCB',
  MASTER = 'MasterCard',
  UNIONPAY = 'UnionPay',
  VISA = 'Visa',
  UNKNOWN = 'Unknown',
}

export default defineComponent({
  name: 'UpgradeAccepted',
  components: {
    ModalDialog,
    TypeBody,
    TypeDisplay,
    BaseDivider,
    BaseCard,
    BaseTag,
    BaseIcon,
    TypeOverline,
    BaseButton,
    SimpleCard,
  },
  props: {
    orderDetails: {
      type: Object as PropType<IOrderDetails>,
      required: true,
    },
    card: {
      type: Object as PropType<CreditCard>,
      required: true,
    },
  },
  setup(props) {
    const { card, orderDetails } = props;

    const getCCIcon = computed(() => {
      switch (card?.brand as string) {
        case CreditCardBrand.AMERICAN:
          return american;
        case CreditCardBrand.DINERS:
          return diner;
        case CreditCardBrand.DISCOVER:
          return discover;
        case CreditCardBrand.JCB:
          return jcb;
        case CreditCardBrand.MASTER:
          return mastercard;
        case CreditCardBrand.UNIONPAY:
          return blank;
        case CreditCardBrand.VISA:
          return visa;
        case CreditCardBrand.UNKNOWN:
        default:
          return blank;
      }
    });

    const subtotal = computed(() => {
      return orderDetails?.totalBilledNow - orderDetails?.totalTaxNow;
    });
    const planCredit = computed(() => {
      return orderDetails?.proratedDiscountAtPurchase;
    });
    const annualPercentage = computed(
      () => (orderDetails?.annualDiscount / orderDetails?.planGrossAmount) * 100
    );

    const priceDetails = computed(() => {
      // This check can be removed when Sales tax goes live.
      // it is currently behind a feature flag on the BE.
      // -- FIX ME CX-319--
      if (orderDetails?.totalTaxNow > 0) {
        return [
          {
            text: 'Bambee Plan x 12 Months',
            price: {
              text: convertToDollars(orderDetails?.planGrossAmount),
            },
            weight: 'medium',
          },
          {
            text: `${annualPercentage.value}% Annual Plan Discount`,
            price: {
              text: `-${convertToDollars(orderDetails?.annualDiscount)}`,
            },
            weight: 'medium',
          },
          {
            text: 'Current Plan Credit',
            price: {
              text: `${convertToDollars(planCredit.value)}`,
            },
            weight: 'medium',
          },
          {
            text: 'Subtotal',
            price: {
              text: convertToDollars(subtotal.value),
            },
            weight: 'medium',
          },
          {
            text: 'Sales Tax',
            price: {
              text: convertToDollars(orderDetails?.totalTaxNow),
              class: 'text-success-default',
            },
          },
          {
            text: 'Total',
            price: {
              text: convertToDollars(orderDetails?.totalBilledNow),
            },
            weight: 'strong',
            class: 'font-bold',
          },
        ];
      }

      return [
        {
          text: 'Bambee Plan x 12 Months',
          price: {
            text: convertToDollars(orderDetails?.planGrossAmount),
          },
          weight: 'medium',
        },
        {
          text: `${annualPercentage.value}% Annual Plan Discount`,
          price: {
            text: `-${convertToDollars(orderDetails?.annualDiscount)}`,
          },
          weight: 'medium',
        },
        {
          text: 'Current Plan Credit',
          price: {
            text: `${convertToDollars(planCredit.value)}`,
          },
          weight: 'medium',
        },
        {
          text: 'Total',
          price: {
            text: convertToDollars(orderDetails?.totalBilledNow),
          },
          weight: 'strong',
          class: 'font-bold',
        },
      ];
    });

    const newPlanExpiration = computed(() => {
      const today = new Date();

      return `${today.getMonth() + 1}/${today.getDate()}/${
        today.getFullYear() + 1
      }`;
    });

    return {
      priceDetails,
      getCCIcon,
      newPlanExpiration,
    };
  },
});
