














































import {
  defineComponent,
  computed,
  ref,
  PropType,
} from '@nuxtjs/composition-api';
import StaffCard from '@/components/StaffCard/StaffCard.vue';
import StaffCardContent from '@/components/StaffCard/StaffCardContent.vue';
import StatusIndicator from '@/components/StatusIndicator';
import DotMenuPopover from '@/components/DotMenuPopover';
import { TypeBody, BaseIcon } from '@bambeehr/pollen';
import format from 'date-fns/format';
import EmployeeTrainingHistoryModal from '@/modules/Training/components/EmployeeTrainingHistoryModal';
import getStatus from '@/modules/Training/utils/getStatus';
import useFileDownloader from '@/composables/useFileDownloader';
import useNotifications from '@bambeehr/use-notifications';
import { PurchasedTopic } from '@/gql/generated';

export const getStatusLabel = (
  topicId: string,
  isCompleted: boolean,
  topics: PurchasedTopic[]
) => {
  // If it's a topic or completed, return null and let the status indicator handle the label
  if (topicId || isCompleted) {
    return null;
  }

  const completedCount = topics.reduce(
    (acc, cur) => acc + (cur.enrollments?.[0]?.completedAt ? 1 : 0),
    0
  );

  return `${completedCount} of 4 Completed`;
};

export default defineComponent({
  components: {
    BaseIcon,
    DotMenuPopover,
    EmployeeTrainingHistoryModal,
    StaffCard,
    StaffCardContent,
    StatusIndicator,
    TypeBody,
  },
  props: {
    state: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      required: true,
    },
    enrolledAt: {
      type: String,
      required: true,
    },
    startedAt: {
      type: String,
      default: '',
    },
    completedAt: {
      type: String,
      default: '',
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isStarted: {
      type: Boolean,
      default: false,
    },
    topicId: {
      type: String,
      default: '',
    },
    bundleId: {
      type: String,
      default: '',
    },
    completedCount: {
      type: Number,
      default: 0,
    },
    topics: {
      type: Array as PropType<PurchasedTopic[]>,
      default: () => [],
    },
    certificateDownloadUrl: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { addInfo } = useNotifications();
    const { fileDownloader } = useFileDownloader();
    const showHistoryModal = ref(false);

    const downloadCertificateBundle = () => {
      const parts = `${props.certificateDownloadUrl}`.split('/certificates/');
      const url = props.bundleId
        ? `${parts[0]}/certificates-secure/by-user-bundle/${props.user.id}/${props.bundleId}`
        : `${props.certificateDownloadUrl}`;
      fileDownloader(url, '');
      addInfo(`One moment while we generate the certificates...`);
    };

    const getActionItems = (emit, staff, completedAt) => [
      {
        label: 'Go to profile',
        handler: () => emit('profile', staff?.id),
      },
      {
        label: 'Unenroll',
        handler: () => emit('unenroll', staff?.id),
      },
      ...(completedAt
        ? [
            {
              label: 'Download certificate',
              handler: () => downloadCertificateBundle(),
            },
          ]
        : []),
    ];

    const toggleHistoryModal = () => {
      showHistoryModal.value = !showHistoryModal.value;
    };

    const menuItems = computed(() => [
      {
        label: 'View Training History',
        handler: () => toggleHistoryModal(),
      },
      ...getActionItems(emit, props.user, props.completedAt),
    ]);

    const status = computed(() =>
      getStatus(props.isStarted, props.isCompleted)
    );

    const assignedDate = computed(() =>
      format(new Date(props.enrolledAt), 'MM/dd/yyyy')
    );

    const statusLabel = computed(() =>
      getStatusLabel(props.topicId, props.isCompleted, props.topics)
    );

    const handleProgressClick = () => {
      if (props.bundleId) {
        emit('show-progress');
      }
    };

    return {
      assignedDate,
      menuItems,
      showHistoryModal,
      status,
      toggleHistoryModal,
      statusLabel,
      handleProgressClick,
    };
  },
});
