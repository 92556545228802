










































import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  computed,
} from '@nuxtjs/composition-api';
import { TypeDisplay, TypeBody } from '@bambeehr/pollen';
import GlowModal from '@/components/GlowModal.vue';
import { header } from '@/modules/StaffManagement/constants/staffTable';
import TinLogo from '@/components/TinLogo.vue';
import LoadingMessage from '@/modules/AI/components/LoadingMessage.vue';

const loadingMessages = [
  'Starting up our machine learning systems',
  'Referencing thousands of company data points',
  'Analyzing your company data',
  'Calculating your company’s risk',
  'Inspecting your company’s compliance',
  'Reviewing your company’s HR policies',
  'Engaging machine learning to analyze your company data',
  'Integrating employee information',
  'Inspecting your company’s compliance',
  'Synchronizing with global HR benchmarks',
  'Cross-checking compliance regulations across regions',
  'Optimizing company data processing pipelines',
  'Retrieving historical performance metrics',
  'Extracting key insights from company reports',
  'Validating data integrity across datasets',
  'Processing employee feedback statistics',
  'Mapping organizational structure',
  'Compiling detailed compliance analytics',
  'Benchmarking against industry standards',
  'Assessing workforce trends',
  'Integrating payroll data into analysis',
  'Simulating potential HR scenarios',
  'Highlighting key areas for improvement',
  'Performing advanced sentiment analysis',
  'Aligning data streams with global HR norms',
  'Scanning employee turnover trends',
  'Cross-referencing benefits package details',
  'Analyzing hiring pipeline efficiency',
  'Running predictive workforce analytics',
  'Scanning organizational health metrics',
  'Tracking employee satisfaction indicators',
  'Consolidating financial impact reports',
  'Identifying talent gaps within departments',
  'Aggregating multi-client benchmarking insights',
  'Compiling regulatory audit preparation data',
  'Evaluating training program efficacy',
  'Processing leadership performance metrics',
  'Aggregating risk assessment profiles',
  'Aligning workforce demographics with compliance needs',
  'Monitoring employee engagement scores',
  'Highlighting compliance vulnerabilities',
  'Mapping out interdepartmental communication trends',
  'Analyzing diversity and inclusion metrics',
  'Scanning external market data for comparison',
  'Summarizing employee performance reviews',
  'Optimizing resource allocation models',
  'Scanning historical HR decisions for trends',
  'Cross-analyzing internal survey responses',
  'Validating compliance certificates',
  'Generating actionable insights for decision-makers',
  'Preparing the final detailed analysis report',
];

export default defineComponent({
  name: 'NewBambeeModal',
  components: {
    TypeBody,
    TypeDisplay,
    GlowModal,
    TinLogo,
    LoadingMessage,
  },
  props: {
    header: {
      type: String,
      default: 'Launching HR Intelligence',
    },
    body: {
      type: String,
      default: '',
    },
    statusText: {
      type: String,
      default: '',
    },
    list: {
      type: Array as PropType<string[]>,
      default: () => loadingMessages,
    },
    maxVisible: {
      type: Number,
      default: 3,
    },
    delayTime: {
      type: Number,
      default: 5000,
    },
  },
  setup(props) {
    let interval;
    const key = ref(0);

    const randomizedList = computed(() =>
      [...props.list].sort(() => Math.random() - 0.5)
    );

    onMounted(() => {
      setTimeout(() => {
        const iconEl = document.querySelector('.modal-icon');
        iconEl?.classList.add('show');
      }, 200);

      interval = setInterval(() => {
        if (key.value + 1 <= props.maxVisible) {
          key.value += 1;
        } else {
          clearInterval(interval);
        }
      }, props.delayTime);
    });

    return {
      key,
      randomizedList,
    };
  },
});
