

























import { defineComponent, useStore } from '@nuxtjs/composition-api';
import {
  BaseButton,
  BaseCard,
  ModalDialog,
  TypeBody,
  TypeDisplay,
} from '@bambeehr/pollen';
import ReportCardPreview from '@/modules/OnLoginTasks/components/images/ReportCardPreview.vue';
import {
  EmitCloseCurrentModal,
  EmitSendTo,
  PreviewModalComponents,
} from '@/modules/OnLoginTasks/constants';
import HrComplianceRoutes, {
  HR_COMPLIANCE_DASHBOARD,
} from '@/modules/HrCompliance/constants/HrComplianceRoutes';
import {
  Milestones,
  MilestoneTags,
} from '@/modules/TaskCenter/types/Milestones';
import { PracticeStatuses } from '@/gql/services/company.interface';
import useCompanyStore from '@/store/companies';

export default defineComponent({
  name: 'ReportCardPreviewModal',
  components: {
    BaseButton,
    BaseCard,
    ReportCardPreview,
    ModalDialog,
    TypeBody,
    TypeDisplay,
  },
  emits: [EmitCloseCurrentModal],
  setup(_, { emit }) {
    const store = useStore();

    const getHrPracticeMilestone = () =>
      useCompanyStore().onboardingMilestonesByTag(MilestoneTags.HR_PRACTICE);

    const handleModalOff = () => {
      // Passing the Component Name of the Modal being closed out
      emit(
        EmitCloseCurrentModal,
        PreviewModalComponents.REPORT_CARD_PREVIEW_MODAL
      );
    };

    const redirecToTaskCenter = () =>
      emit(EmitSendTo, HR_COMPLIANCE_DASHBOARD.path);

    const redirectToReportCard = () =>
      emit(EmitSendTo, HrComplianceRoutes.REPORT_CARDS);

    const handleLearnMore = () => {
      const HrPracticeMilestone = getHrPracticeMilestone();
      const isHrPracticeCompleted = HrPracticeMilestone.find(
        (milestone) =>
          milestone.milestone === Milestones.HR_PRACTICE &&
          milestone.status === PracticeStatuses.COMPLETED
      );

      console.log('ispractice complete: ', isHrPracticeCompleted);

      // IF Hr Practice Complete, Send to Report Cards
      // IF NOT complete, send to task center
      if (isHrPracticeCompleted) {
        redirectToReportCard();
      } else {
        redirecToTaskCenter();
      }
      handleModalOff();
    };

    return {
      handleLearnMore,
      handleModalOff,
    };
  },
});
