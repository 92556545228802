










import { defineComponent } from '@nuxtjs/composition-api';
import BaseNotifications from '@/components/BaseNotifications/BaseNotifications.vue';
import ErrorBoundary from '@/modules/payroll/components/ErrorBoundary/ErrorBoundary.vue';

export default defineComponent({
  name: 'Blank',
  components: {
    BaseNotifications,
    ErrorBoundary,
  },
});
