import { useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';

import {
  Company,
  useGetCompanyVerboseQuery,
  CourseTopic,
  BundleResponse,
  PurchasedTopic,
  PurchasedBundle,
} from '@/gql/generated';
import { computed, ref } from '@nuxtjs/composition-api';
import partition from 'lodash/partition';
import { CORE_BUNDLE_ID } from '@/modules/Training/constants';

type Course = PurchasedTopic & PurchasedBundle & BundleResponse & CourseTopic;

export const prepString = (str: string) => str.toLowerCase().trim();

export const decorateTrainingLists = <T extends Course[]>(
  originalList: T,
  query: string
) => {
  const [recommended, all] = partition(
    originalList
      .filter(
        (i) =>
          prepString(i.name).includes(prepString(query)) ||
          i.topics?.some((t) => t?.name.includes(query))
      )
      .map((i) => ({
        ...i,
        bundleId: i.topics?.length ? i.id : '',
        topicId: i.topics?.length ? '' : i.id,
        isBundle: !!i.topics?.length,
        completedSeats: i.counts?.completed || 0,
        totalSeats:
          (i.counts?.completed || 0) +
          (i.counts?.inProgress || 0) +
          (i.counts?.notStarted || 0),
        unfilledSeats: i.unfilledSeats || 0,
        details: i.topics?.map((topic) => topic?.name).join(', '),
        price: i?.price,
      })),
    (item) => item.bundleId === CORE_BUNDLE_ID || item.id === CORE_BUNDLE_ID
  );

  return {
    recommended,
    all,
    hasBothGroups: !!recommended.length && !!all.length,
    hasNoResults: !recommended.length && !all.length,
  };
};

const { companyId } = useCurrentCompany();
const trainingCompany = ref<Company>();
const isFetching = ref(false);

const availableTrainingList = computed(() => {
  if (!trainingCompany.value) {
    return [];
  }
  const { bundles, topics } = trainingCompany.value.availableProducts;

  // for net new customers that havent enrolled in training yet we want to display the core bundle by default
  if (!trainingCompany.value.purchasedProducts.topics.length) {
    return bundles as Course[];
  }

  return [...bundles, ...topics] as Course[];
});

const purchasedTrainingList = computed(() => {
  if (!trainingCompany.value) {
    return [];
  }

  const { bundles, topics } = trainingCompany.value.purchasedProducts;

  return [...bundles, ...topics] as Course[];
});

const searchQuery = ref('');

// This combines the available and purchased training lists
// if the user has already purchased a seat for the training it will be in the purchased list
// if the user has not purchased a seat for the training it will be in the available list
// this is used to display the training list on the Training page in the add staff wizard
// we want to make sure we display trainings they have not purchased yet so we don't lose out on those sales
const mergedAndParsedList = computed(() => {
  // prices are coming from availableProducts
  // customer has not purchased before give them at least the core bundle
  // customer has purchased will show only the previously purchased courses
  // filtering on available courses that are part of the purchased list because we want the price information from the first list
  const merged = !trainingCompany.value?.purchasedProducts.topics.length
    ? [...purchasedTrainingList.value, ...availableTrainingList.value]
    : [
        ...purchasedTrainingList.value,
        ...availableTrainingList.value.filter(
          (course) =>
            // Core bundle needs to show for everyone
            course.id === CORE_BUNDLE_ID ||
            // Available courses for which customers have purchased before
            purchasedTrainingList.value
              .map((purchased) => purchased.id)
              .includes(course.id)
        ),
      ];

  const hash = new Map<string, Course>();
  merged.forEach((course) => {
    const existing = hash.get(course.id);
    if (existing) {
      hash.set(course.id, {
        ...existing,
        ...course,
      });
    } else {
      hash.set(course.id, course);
    }
  });

  const parsed = [...hash].map(([_, course]) => ({
    ...course,
  }));

  return parsed;
});

const trainingLists = computed(() =>
  decorateTrainingLists(
    purchasedTrainingList.value as Course[],
    searchQuery.value
  )
);

// TODO - this lists all the topics even if those topic are already in a bundle
// we should only list the topics that are not already in a bundle
// for example core bundle has the following topics - Workplace Safety, Sexual Harassment, Workplace Violence, Business Ethics
// these topics should not show up in the list of available topics because they are already in the core bundle
// this should be true for all bundles
// https://bambee.atlassian.net/browse/RAP-1194
const availableAndPurchasedTrainingLists = computed(() =>
  decorateTrainingLists(mergedAndParsedList.value, searchQuery.value)
);

const updateSearchQuery = (query: string) => {
  searchQuery.value = query;
};

const fetchCompanyTrainings = () => {
  if (!companyId.value) {
    return;
  }

  useApolloQuery(
    useGetCompanyVerboseQuery,
    { data: { companyId: companyId.value } },
    {
      data: trainingCompany as any,
      pending: isFetching,
    },
    { placeholderPick: ({ company }) => company }
  );
};

const useEmployerTrainingList = () => {
  return {
    trainingLists,
    isFetching,
    searchQuery,
    availableAndPurchasedTrainingLists,
    fetchCompanyTrainings,
    updateSearchQuery,
  };
};

export default useEmployerTrainingList;
