import usePolicies from '@/hooks/usePolicies/usePolicies';
import usePolicyStaffFilter, {
  resetFilters
} from '@/modules/PolicyCenter/hooks/usePolicyStaffFilter';
import {
  PolicyStaffStates,
  PolicyTab,
  PolicyType
} from '@/modules/PolicyCenter/models';
import { computed, ref, Ref } from '@nuxtjs/composition-api';

/**
 * States
 */
const TabOptions = [PolicyTab.IMPLEMENTED, PolicyTab.PREVIEW];
const policyDetailsTab = ref<PolicyTab>(PolicyTab.IMPLEMENTED);
const isImplementationModalOn: Ref<boolean | undefined> = ref();
const viewPolicyAs: Ref<string | null> = ref(null);

/**
 * Methods / Actions
 */
// Set's the Staff Member to view "Policy As"
const setViewPolicyAs = (employeeId: string) => {
  viewPolicyAs.value = employeeId;
  policyDetailsTab.value = PolicyTab.PREVIEW;
};

const toggleImplementationModal = (value) => {
  resetFilters();
  isImplementationModalOn.value = value;
};

const usePolicyDetails = () => {
  const { policyInScope } = usePolicies();
  const { groupedStaff } = usePolicyStaffFilter();

  const reaffirmationNeededCount = computed(
    () =>
      groupedStaff.value[PolicyStaffStates.REAFFIRMATION_NEEDED]?.length || 0
  );

  const signedCount = computed(
    () => groupedStaff.value[PolicyStaffStates.SIGNED]?.length || 0
  );

  const notSignedCount = computed(
    () => groupedStaff.value[PolicyStaffStates.NOT_SIGNED]?.length || 0
  );

  const totalCount = computed(
    () =>
      reaffirmationNeededCount.value + signedCount.value + notSignedCount.value
  );

  // PolicyInScope dependent computed properties
  const policyKind = computed<PolicyType>(() => policyInScope.value?.kind);

  const policyCount = computed(() => ({
    total: totalCount.value,
    signed: signedCount.value,
    reaffirmationNeeded: reaffirmationNeededCount.value,
    notSigned: notSignedCount.value,
  }));

  return {
    isImplementationModalOn,
    policyCount,
    policyDetailsTab,
    policyInScope,
    policyKind,
    PolicyTab,
    setViewPolicyAs,
    TabOptions,
    toggleImplementationModal,
    viewPolicyAs,
  };
};

export default usePolicyDetails;
