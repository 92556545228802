import useCurrentCompany from '@/hooks/useCurrentCompany';
import {
  useGetTrainingCompanyQuery,
  CourseTopic,
  BundleResponse,
  PurchasedTopic,
  PurchasedBundle,
} from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';
import { ref } from '@nuxtjs/composition-api';
import useTrainingRecommendations from '@/modules/Training/hooks/useTrainingRecommendations/useTrainingRecommendations';

let init;

const isReady = ref(false);

const availableCourses = ref<CourseTopic[]>([]);
const availableBundles = ref<BundleResponse[]>([]);
const purchasedProducts = ref<PurchasedTopic[]>([]);
const purchasedBundles = ref<PurchasedBundle[]>([]);

const setupHook = () => {
  const { companyId } = useCurrentCompany();

  const { onResult } = useApolloQuery(useGetTrainingCompanyQuery, {
    data: {
      companyId: companyId.value,
    },
  });

  onResult(({ company: res }) => {
    availableBundles.value = res.availableProducts.bundles as BundleResponse[];
    availableCourses.value = res.availableProducts.topics as CourseTopic[];

    purchasedProducts.value = res.purchasedProducts.topics as PurchasedTopic[];
    purchasedBundles.value = res.purchasedProducts.bundles as PurchasedBundle[];

    isReady.value = true;
  });

  init = true;
};

const getTopicInfo = (topicId: string) => {
  const { pendingRecommendations } = useTrainingRecommendations();

  return {
    ...(availableCourses.value.find((c) => c.id === topicId) || {}),
    isPurchased: !!purchasedProducts.value.find((p) => p.id === topicId),
    isRecommended: !!pendingRecommendations.value.find(
      (r) => r.resourceId === topicId
    ),
  };
};

const getBundleInfo = (bundleId: string) =>
  availableBundles.value.find((b) => b.id === bundleId);

const useTrainingCatalog = () => {
  if (!init) {
    setupHook();
  }

  return {
    availableCourses,
    availableBundles,
    isReady,
    getTopicInfo,
    getBundleInfo,
  };
};

export default useTrainingCatalog;
