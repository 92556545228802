

















































import { defineComponent } from '@nuxtjs/composition-api';
import { IconButton, TypeBody } from '@bambeehr/pollen';
import PayrollCard from '@/modules/payroll/components/PayrollCard/PayrollCard.vue';

export default defineComponent({
  name: 'FileDownloadTile',
  components: {
    PayrollCard,
    IconButton,
    TypeBody,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: '',
    },
    detail: {
      type: String,
      required: true,
    },
    subDetail: {
      type: String,
      default: '',
    },
  },
});
