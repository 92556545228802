import get from 'lodash/get';

/**
 * Checks if document is meant to be signed (not countersigned!) by user given
 *
 * @param {Object} doc Document object
 * @param {string} employeeId
 *
 * @returns {boolean}
 */
export const isAssignedToUser = (doc = {}, employeeId = '') => {
  const documentEmployeeId = get(doc, 'signature_contact._user', doc._employee);

  return documentEmployeeId === employeeId;
};

export default {
  isAssignedToUser,
};
