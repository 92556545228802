import { isBefore } from 'date-fns';

export function sortPayrollPayByFullName(payrollItem1, payrollItem2) {
  const item1 = payrollItem1?.staff;
  const item2 = payrollItem2?.staff;
  const name1 = `${item1?.firstName?.toUpperCase()} ${item1?.lastName?.toUpperCase()}`;
  const name2 = `${item2?.firstName?.toUpperCase()} ${item2?.lastName?.toUpperCase()}`;

  if (name1 < name2) {
    return -1;
  }

  if (name1 > name2) {
    return 1;
  }

  return 0;
}
export function sortPayrollsByPayday(payroll1, payroll2) {
  const payday1 = new Date(payroll1.payday);
  const payday2 = new Date(payroll2.payday);

  if (isBefore(payday1, payday2)) {
    return -1;
  }

  if (isBefore(payday2, payday1)) {
    return 1;
  }

  return 0;
}

export default {
  sortPayrollPayByFullName,
  sortPayrollsByPayday,
};
