export const RemainingOnboardingSteps = Object.freeze({
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  FILING_AUTHORIZATION: 'FILING_AUTHORIZATION',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  SETUP_PARAMETERS: 'SETUP_PARAMETERS',
  SSN: 'SSN',
  EIN: 'EIN',
  WITHHOLDINGS: 'WITHHOLDINGS',
  EMPLOYEE_DETAILS: 'EMPLOYEE_DETAILS',
  COMPANY_DEFINED_ATTRIBUTES: 'COMPANY_DEFINED_ATTRIBUTES',
});

export const RemainingOnboardingStepLabels = Object.freeze({
  BANK_ACCOUNT: 'Bank Account',
  FILING_AUTHORIZATION: 'Filing Authorization',
  PAYMENT_METHOD: 'Pay Method',
  SETUP_PARAMETERS: 'Setup Parameters',
  SSN: 'SSN',
  EIN: 'EIN',
  WITHHOLDINGS: 'Withholdings',
  COMPANY_DEFINED_ATTRIBUTES: 'Company Info',
});

export const StaffType = Object.freeze({
  EMPLOYEE: 'EMPLOYEE',
  CONTRACTOR: 'CONTRACTOR',
});
