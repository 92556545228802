























































import { TypeBody, TypeDisplay, BaseButton, BaseCard } from '@bambeehr/pollen';
import CompanyGoals from '@/modules/payroll/components/CompanyGoals';

export default {
  name: 'GetPayrollConfirmation',
  components: {
    TypeBody,
    BaseButton,
    TypeDisplay,
    CompanyGoals,
    BaseCard,
  },
  setup() {
    return {};
  },
};
