





































import PayrollTOS from '@/modules/payroll/pages/PayrollTOS.vue';
import {
  onMounted,
  ref,
  reactive,
  computed,
  watch,
  useStore,
  PropType,
  useRouter,
  useRoute,
} from '@nuxtjs/composition-api';
import { TypeDisplay, TypeBody } from '@bambeehr/pollen';
import CompanyInfoForm, {
  Form,
  validateEmail,
  validateWebsite,
  RadioBool,
} from '@/modules/payroll/components/CompanyInfoForm/CompanyInfoForm.vue';
import { wizardActionState } from '@/modules/payroll/pages/employer/onboarding/OnboardingRoot.vue';
import useNotifications from '@bambeehr/use-notifications';
import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import { Company } from '@/modules/payroll/types/company';
import omit from 'lodash/omit';
import format from 'date-fns/format';
import addWeeks from 'date-fns/addWeeks';
import nextWednesday from 'date-fns/nextWednesday';
import useAddressForm from '@/components/AddressForm/useAddressForm';
import { trackEvent } from '@/modules/payroll/utils/track-apa';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import {
  IndustryType,
  useGetPayrollOnboardingInfoQuery,
  useSubmitPayrollOnboardingFormMutation,
  OnboardingGarnishmentOrDeduction,
} from '@/gql/generated';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import CachePolicy from '@/gql/CachePolicy';
import useContentful from '@/hooks/useContentful/useContentful';
import { isNil } from 'lodash';

const FORM_HEADER_ENTRY_ID = '53EACdUG0npUwA3EFv21Ra';

const emptyForm = {
  name: '',
  dba: '',
  phone: '',
  line1: '',
  line2: '',
  zip: '',
  city: '',
  state: '',
  checkIndustry: '' as IndustryType,
  fein: '',
  numOfContractors: 0,
  numOfFullTimeHourlyEmployees: 0,
  numOfFullTimeSalaryEmployees: 0,
  numOfPartTimeEmployees: 0,
  canSignForm: RadioBool.TRUE,
  processedPayrollsThisYear: RadioBool.TRUE,
  signatoryFirstName: '',
  signatoryLastName: '',
  signatoryTitle: '',
  signatoryEmail: '',
  website: '',
  feinValidationSatisfied: false,
  onboardingOffersEmployeeBenefits: null,
  onboardingHasGarnishmentsOrDeductions: null,
  onboardingGarnishmentsOrDeductions: [],
  onboardingGarnishmentsOrDeductionsOther: '',
};

const checkIfFormIsFilled = (form: Form): boolean => {
  const signatoryInfo =
    form.canSignForm === RadioBool.TRUE
      ? [
          'signatoryFirstName',
          'signatoryLastName',
          'signatoryTitle',
          'signatoryEmail',
        ]
      : [];
  const hasGarnishments =
    form.onboardingHasGarnishmentsOrDeductions === RadioBool.TRUE;

  const garnishmentSelections =
    !form.onboardingHasGarnishmentsOrDeductions || hasGarnishments
      ? []
      : [
          'onboardingGarnishmentsOrDeductions',
          'onboardingGarnishmentsOrDeductionsOther',
        ];

  const garnishmentOther =
    hasGarnishments &&
    form.onboardingGarnishmentsOrDeductions.includes(
      OnboardingGarnishmentOrDeduction.Other
    )
      ? []
      : ['onboardingGarnishmentsOrDeductionsOther'];

  const omitList = [
    // FEIN and name are validated and saved by the VerifyFeinForm component
    // DBA and line2 are optional
    'dba',
    'line2',
    'fein',
    'name',
    'website',
    ...signatoryInfo,
    ...garnishmentSelections,
    ...garnishmentOther,
  ];

  const required = Object.values(omit(form, omitList));

  return !required.some((r) => {
    if (Array.isArray(r)) {
      return r.length === 0;
    }

    return r === null || r === '';
  });
};

const assignFormValues = (form, addressForm, res) => {
  const { getCoreCompany: coreCompany, getCompany: payrollCompany } = res;

  let onboardingOffersEmployeeBenefits: null | RadioBool = null;

  if (!isNil(payrollCompany?.onboardingOffersEmployeeBenefits)) {
    onboardingOffersEmployeeBenefits =
      payrollCompany?.onboardingOffersEmployeeBenefits
        ? RadioBool.TRUE
        : RadioBool.FALSE;
  }

  let onboardingHasGarnishmentsOrDeductions: null | RadioBool = null;

  if (!isNil(payrollCompany?.onboardingHasGarnishmentsOrDeductions)) {
    onboardingHasGarnishmentsOrDeductions =
      payrollCompany?.onboardingHasGarnishmentsOrDeductions
        ? RadioBool.TRUE
        : RadioBool.FALSE;
  }

  Object.assign(form, {
    ...coreCompany,
    ...payrollCompany,
    canSignForm: !payrollCompany?.signatoryEmail
      ? RadioBool.TRUE
      : RadioBool.FALSE,
    processedPayrollsThisYear: payrollCompany?.processedPayrollsThisYear
      ? RadioBool.TRUE
      : RadioBool.FALSE,
    numOfContractors: coreCompany.numOfContractors || 0,
    numOfFullTimeHourlyEmployees: coreCompany.numOfFullTimeHourlyEmployees || 0,
    numOfFullTimeSalaryEmployees: coreCompany.numOfFullTimeSalaryEmployees || 0,
    numOfPartTimeEmployees: coreCompany.numOfPartTimeEmployees || 0,
    onboardingOffersEmployeeBenefits,
    onboardingHasGarnishmentsOrDeductions,
    onboardingGarnishmentsOrDeductions:
      payrollCompany?.onboardingGarnishmentsOrDeductions || [],
    onboardingGarnishmentsOrDeductionsOther:
      payrollCompany?.onboardingGarnishmentsOrDeductionsOther || '',
  });

  Object.assign(addressForm, coreCompany.address);
};

export default {
  name: 'CompanyInfo',
  components: {
    CompanyInfoForm,
    TypeDisplay,
    TypeBody,
    PayrollTOS,
  },
  props: {
    companyId: {
      type: String as PropType<string>,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { companyId } = store.getters;
    const { addError } = useNotifications();
    const { createCompanyInCheck, getCompany, updateCompany } =
      usePayrollService();
    const { workingForm: addressForm, addressIsValid } = useAddressForm();

    const { fetchContent } = useContentful();

    const isSaving = ref(false);
    const disableForm = ref(false);
    const company = ref<Company | null>(null);
    const isLoadingCompany = ref(false);
    const isValidatingForm = ref(false);
    const shouldHideModal = ref(true);
    const titleCopy = ref({});

    const form: Form = reactive(emptyForm);

    (async () => {
      const content = await fetchContent(FORM_HEADER_ENTRY_ID);

      titleCopy.value = content;
    })();

    const { onResult } = useApolloQuery(
      useGetPayrollOnboardingInfoQuery,
      {
        id: companyId,
      },
      undefined,
      undefined,
      // Make sure we're always getting the latest data
      { fetchPolicy: CachePolicy.NETWORK_ONLY }
    );

    onResult((res) => assignFormValues(form, addressForm, res));

    watch(addressForm, (updated) => {
      Object.assign(form, updated);
    });

    const isGetStartedPage = computed(() =>
      route.value.path.includes('get-started')
    );

    const formIsFilled = computed(() => checkIfFormIsFilled(form));

    const hasValidPhone = computed<boolean>(() => form.phone.length === 10);
    const formIsValid = computed(
      () =>
        hasValidPhone.value &&
        addressIsValid.value &&
        form.feinValidationSatisfied &&
        (!form.website.trim() || validateWebsite(form.website)) &&
        (form.canSignForm === RadioBool.TRUE ||
          validateEmail(form.signatoryEmail))
    );

    getCompany(props.companyId as string, {
      data: company,
      pending: isLoadingCompany,
    });

    const unwatch = watch(company, (res) => {
      shouldHideModal.value = !!(res as Company).tosVersion;
      unwatch();
    });

    const {
      onDone,
      onError,
      mutate: save,
    } = useApolloMutation(useSubmitPayrollOnboardingFormMutation);

    async function saveForm(callBack) {
      isSaving.value = true;

      const canSignForm = form.canSignForm === RadioBool.TRUE;
      const processedPayrollsThisYear =
        form.processedPayrollsThisYear === RadioBool.TRUE;
      const eeCounts = {
        numOfContractors: form.numOfContractors,
        numOfFullTimeHourlyEmployees: form.numOfFullTimeHourlyEmployees,
        numOfFullTimeSalaryEmployees: form.numOfFullTimeSalaryEmployees,
        numOfPartTimeEmployees: form.numOfPartTimeEmployees,
      };
      const onboardingHasGarnishmentsOrDeductions =
        form.onboardingHasGarnishmentsOrDeductions === RadioBool.TRUE;
      const onboardingGarnishmentsOrDeductions =
        onboardingHasGarnishmentsOrDeductions
          ? form.onboardingGarnishmentsOrDeductions
          : [];
      const onboardingGarnishmentsOrDeductionsOther =
        onboardingHasGarnishmentsOrDeductions &&
        onboardingGarnishmentsOrDeductions.includes(
          OnboardingGarnishmentOrDeduction.Other
        )
          ? form.onboardingGarnishmentsOrDeductionsOther
          : '';

      const onboardingOffersEmployeeBenefits =
        form.onboardingOffersEmployeeBenefits === RadioBool.TRUE;

      const coreData = {
        id: companyId,
        name: form.name,
        website: form.website,
        dba: form.dba,
        phone: form.phone,
        fein: form.fein,
        ...eeCounts,
        address: {
          line1: form.line1,
          line2: form.line2,
          city: form.city,
          state: form.state,
          zipCode: form.zip,
        },
      };

      const payrollData = {
        id: companyId,
        checkIndustry: form.checkIndustry,
        processedPayrollsThisYear,
        signatoryFirstName: (!canSignForm && form.signatoryFirstName) || '',
        signatoryLastName: (!canSignForm && form.signatoryLastName) || '',
        signatoryTitle: (!canSignForm && form.signatoryTitle) || '',
        signatoryEmail: (!canSignForm && form.signatoryEmail) || '',
        onboardingHasGarnishmentsOrDeductions,
        onboardingGarnishmentsOrDeductions,
        onboardingGarnishmentsOrDeductionsOther,
        onboardingOffersEmployeeBenefits,
      };

      const enrollmentProfile = {
        id: companyId,
        ...eeCounts,
      };

      onDone((res) => {
        isLoadingCompany.value = true;

        callBack();
      });

      onError((error) => {
        isSaving.value = false;
        isLoadingCompany.value = false;
        addError(
          `Unable to save company info: ${
            error.networkError?.message ||
            error.responseErrors?.[0]?.error ||
            error
          }`
        );
      });

      save({
        coreData,
        payrollData,
        enrollmentProfile,
        syncCompanyData: {
          id: companyId,
        },
        checkCompanyInfo: {
          id: companyId,
          startDate: format(
            nextWednesday(addWeeks(new Date(), 2)),
            'yyyy-MM-dd'
          ),
        },
        shouldCreateCompanyInCheck: !!(company.value && !company.value.checkId),
      });
    }

    wizardActionState.value = {
      next: {
        label: 'Continue',
        action: (next) => {
          isValidatingForm.value = true;
          if (formIsFilled.value && formIsValid.value) {
            isLoadingCompany.value = true;
            saveForm(() => {
              const nextRoute = isGetStartedPage.value
                ? '/payroll-setup/confirmation'
                : undefined;

              next(nextRoute);
            });
          }
          trackEvent('payroll-onboarding-form-submitted', form);
        },
      },
      finishLater: isGetStartedPage.value
        ? undefined
        : {
            label: 'Finish Later',
            action: (finishLater) => {
              saveForm(finishLater);
            },
          },
      disableNext: disableForm.value,
      loadingNext: false,
    };

    function setNextBtnState(stateOptions: {
      disableNext: boolean;
      loadingNext: boolean;
    }) {
      wizardActionState.value = {
        ...wizardActionState.value,
        ...stateOptions,
      };
    }

    watch([isSaving, isLoadingCompany], ([saving, loading]) => {
      setNextBtnState({
        disableNext: !!loading,
        loadingNext: !!saving,
      });

      disableForm.value = saving || loading;
    });

    function hideTOS(version?: string) {
      if (!version) {
        router.push('/');

        return;
      }

      updateCompany(
        {
          tosVersion: version,
          id: props.companyId as string,
        },
        {}
      );
      shouldHideModal.value = true;
    }

    // Reset top position
    onMounted(() => {
      if (window.scrollTo) {
        window.scrollTo(0, 0);
      }
    });

    // APA Tracking
    const { isOnboarding } = usePayrollOnboardingStatus();

    if (isOnboarding.value) {
      trackEvent('payroll-onboarding-company-info');
    }

    return {
      company,
      disableForm,
      form,
      formIsFilled,
      formIsValid,
      hasValidPhone,
      hideTOS,
      isValidatingForm,
      saveForm,
      setNextBtnState,
      shouldHideModal,
      titleCopy,
    };
  },
};
