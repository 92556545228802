<template>
  <div>
    <portal :key="$route.path" to="crumbs">
      <span>Payroll</span>
    </portal>
    <AnnularThrobber v-if="isLoading" class="mx-auto mt-44 mb-32" />
    <div v-else>
      <div class="flex justify-end">
        <PayrollFaqDrawer class="z-10 relative">
          <template v-if="isOnOverviewPage && $screen.lg" #footer>
            <BaseButton level="2" @click="toggleGuidedTour(true)">
              View Guided Tour
            </BaseButton>
          </template>
        </PayrollFaqDrawer>
      </div>
      <div class="mt-2 md:-mt-8">
        <TabControls
          :key="tabSelected"
          tabset-id="payroll-tabs"
          :value="tabSelected"
          :options="options"
          @input="handleTabInput"
        />
      </div>
      <div>
        <template v-if="!!company">
          <nuxt-child :company-id="companyId" :company="company" />
        </template>
      </div>
    </div>
    <FeatureTour
      v-if="$screen.lg && showTour"
      :tour-id="tourId"
      :floating-steps="floatingSteps"
      :guided-steps="guideSteps"
    />
  </div>
</template>

<script>
import { CompanyStatuses } from '@/modules/payroll/constants/company';
import {
  computed,
  ref,
  useStore,
  useRoute,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import { TabControls, AnnularThrobber, BaseButton } from '@bambeehr/pollen';
import {
  useGetCompanyLifecycleInfoQuery,
  CheckOnboardSteps,
  GoalStatus,
} from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';
import bambeePlusStatus from '@/modules/BambeePlus/utils/bambeePlusStatus';
import PayrollFaqDrawer from '@/components/FaqDrawer/PayrollFaqDrawer';
import FeatureTour from '@/components/FeatureTour';
import shortid from 'shortid';
import useTour from '@/hooks/useTour';
import { getUserPreference, setUserPreference } from '@/utils/userPreference';

let handleSkipClicked;
const floatingSteps = [
  {
    title: 'Payroll Overview',
    description:
      'This is the payroll overview page. You can create a new payroll, or view your existing payrolls.',
    imgSrc: '/assets/images/tours/payroll/payroll_start.jpg',
    nextBtn: {
      label: `Let's Go`,
    },
    prevBtn: {
      label: 'Skip',
      level: '3',
      class: 'opacity-50',
      preventDefault: true,
      onClick: () => {
        handleSkipClicked?.();
      },
    },
  },
];

const guideSteps = [
  {
    title: 'Your Payroll Dashboard',
    description:
      'This is your central hub for all things payroll. Monitor scheduled and off-cycle payrolls effortlessly.',
    imgSrc: '/assets/images/tours/payroll/overview.gif',
    id: 'payroll-tabs-overview-tab',
  },
  {
    title: 'Monitor Scheduled Payrolls',
    description:
      'A scheduled payroll is one that has been set up  according to your company’s pay schedule. Keep an eye on status, deadlines, earnings, pay periods, and pay dates.',
    id: 'payroll-list',
  },
  {
    title: 'Create Off-cycle Payrolls',
    description:
      'A scheduled payroll is one that has been set up  according to your company’s pay schedule. Keep an eye on status, deadlines, earnings, pay periods, and pay dates.',
    id: 'payroll-offcycle',
  },
  {
    title: 'Access Your Tax Documents',
    description:
      'Find, view, and download vital tax forms such as federal returns, state returns, and employee W-2s conveniently within Tax Docs.',
    id: 'payroll-tabs-tax-docs-tab',
    imgSrc: '/assets/images/tours/payroll/tax_docs.gif',
  },
  {
    title: 'Manage Your Payroll Settings',
    description: `Update bank details, add new workplaces, make changes to your tax details and more in Settings.`,
    id: 'payroll-tabs-settings-tab',
    imgSrc: '/assets/images/tours/payroll/settings.gif',
  },
  {
    title: `That's it - you're all set!`,
    description: `You're ready to start running payroll. If you need more guidance, click "Need Help?".`,
    isGlobalStep: true,
    imgSrc: '/assets/images/tours/payroll/payroll_final.jpg',
  },
];

export const Tab = Object.freeze({
  OVERVIEW: {
    label: 'Overview',
    value: 'overview',
    order: 0,
  },
  PAST_PAYROLLS: {
    label: 'Past Payrolls',
    value: 'past-payrolls',
    order: 1,
  },
  TAX_DOCS: {
    label: 'Tax Docs',
    value: 'tax-documents',
    order: 2,
  },
  REPORTS: {
    label: 'Reports',
    value: 'reports',
    order: 3,
  },
  SETUP: {
    label: 'Setup',
    value: 'setup',
    order: 4,
  },
  STAFF_PAY: {
    label: 'Worker Pay',
    value: 'worker-pay',
    order: 5,
  },
  SETTINGS: {
    label: 'Settings',
    value: 'settings',
    order: 6,
  },
});

export const handleCompanyRes = async (res, tabs, payrollCount) => {
  const company = res;
  const companyIsBlocked =
    company?.checkOnboarding?.status === CheckOnboardSteps.Blocking;
  const hasRequirements = company?.goals?.some(
    (goal) => goal.status === GoalStatus.Pending
  );
  const isPreLiveStatus = [
    CompanyStatuses.INITIALIZED,
    CompanyStatuses.PROVISIONED,
    CompanyStatuses.SETUP_COMPLETE,
  ].includes(company?.status);

  const isLiveCompany = !companyIsBlocked && !isPreLiveStatus;
  const hasRunPayrollBefore = !!payrollCount;

  // Reset tab state
  tabs.value = [];
  const { hasBambeePlus } = await bambeePlusStatus();

  switch (res?.getCompany?.status) {
    case CompanyStatuses.CANCELLED:
      tabs.value.push(Tab.PAST_PAYROLLS, Tab.TAX_DOCS);
      break;

    default:
      // Only show the overview tab if the company is live and unblocked to run payroll
      if (isLiveCompany) {
        tabs.value.push(Tab.OVERVIEW, Tab.REPORTS);
      }

      // If they've run payrolls before, always show the past payrolls and tax docs tabs
      // Or if they're a live company that will have payrolls soon, show the tabs
      if (hasRunPayrollBefore.value || isLiveCompany) {
        tabs.value.push(Tab.PAST_PAYROLLS, Tab.TAX_DOCS);
      }

      // If the company has requirements, show the setup tab so they can add them without manual intervention
      if (hasRequirements || hasBambeePlus) {
        tabs.value.push(Tab.SETUP, Tab.STAFF_PAY);
      }

      // Always show these tabs
      tabs.value.push(Tab.STAFF_PAY, Tab.SETTINGS);

      // Filter dupes
      tabs.value = [...new Set(tabs.value)];
      break;
  }
};

export default {
  components: {
    AnnularThrobber,
    TabControls,
    PayrollFaqDrawer,
    FeatureTour,
    BaseButton,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const tabs = ref([]);
    const hasRunPayrollBefore = ref(true);

    const company = ref();
    const { companyId } = store.getters;
    const { loading: isLoading, onResult: onCompanyResult } = useApolloQuery(
      useGetCompanyLifecycleInfoQuery,
      { id: companyId }
    );

    onCompanyResult(({ getCompany, listPayroll }) => {
      handleCompanyRes(getCompany, tabs, listPayroll.totalCount);
      company.value = getCompany;
      hasRunPayrollBefore.value = !!listPayroll.totalCount;
    });

    const handleTabInput = (tabName) => {
      const targetRoute = tabs.value.find((t) => t.label === tabName)?.value;
      if (targetRoute) {
        router.push(`/payroll/${targetRoute}`);
      }
    };

    const tabSelected = computed(
      () => tabs.value.find((t) => route.value.path.includes(t.value))?.label
    );

    const options = computed(() =>
      tabs.value.sort((a, b) => a.order - b.order).map((t) => t.label)
    );

    const storageKey = `${companyId}:has-viewed-or-skipped-payroll-tour`;

    const shouldShowKey = `${companyId}:show-edit-tour`;
    const showTour = ref(false);
    const toggleGuidedTour = (shouldShow) => {
      showTour.value = shouldShow ?? !showTour.value;
    };

    const tourId = shortid.generate();
    const { tourMap, onCompleted, onDismissed } = useTour(tourId);

    const isOnOverviewPage = computed(() =>
      route.value.path.includes('overview')
    );

    // If the user hasn't seen the tour before.
    watch(
      isOnOverviewPage,
      (res) => {
        if (res) {
          watch(
            company,
            (res) => {
              if (!res) {
                return;
              }

              // Show when the user hasn't run a payroll before
              const companyIsLive = res.status === CompanyStatuses.LIVE;
              const companyIsBlocked =
                !!res.checkOnboarding.blockingSteps.length;

              if (
                !getUserPreference(storageKey) &&
                companyIsLive &&
                !hasRunPayrollBefore.value &&
                !companyIsBlocked
              ) {
                toggleGuidedTour(true);
              }

              if (
                companyIsLive &&
                !hasRunPayrollBefore.value &&
                !companyIsBlocked
              ) {
                setUserPreference(shouldShowKey, true);
              }
            },
            { immediate: true }
          );

          onCompleted.value = () => {
            setUserPreference(storageKey, true);
            toggleGuidedTour(false);
          };

          onDismissed.value = () => {
            setUserPreference(storageKey, true);
            toggleGuidedTour(false);
          };
        } else {
          toggleGuidedTour(false);
        }
      },
      { immediate: true }
    );

    handleSkipClicked = () => {
      toggleGuidedTour(false);
      setUserPreference(storageKey, true);
    };

    return {
      companyId,
      isLoading,
      options,
      tabSelected,
      company,
      handleTabInput,
      showTour,
      tourMap,
      tourId,
      floatingSteps,
      guideSteps,
      toggleGuidedTour,
      isOnOverviewPage,
    };
  },
};
</script>

<style scoped>
>>> .tab-controls__link {
  @apply text-15 xl:text-16 px-3 lg:px-4;
}
</style>
