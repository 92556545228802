<template>
  <StickySidebarLayout>
    <!-- TITLE  -->
    <PageTitle v-if="$slots.title">
      <slot name="title" />
    </PageTitle>

    <!-- MAIN CONTENT: Default Slot -->
    <slot />

    <!-- LOADING: Submitting Payroll -->
    <LoadingModal v-if="isSubmitting">
      <template #header> Submitting payroll </template>
      Please do not leave this page or close your browser, while we submit your
      payroll. You will be redirected automatically once complete.
    </LoadingModal>

    <!-- SIDEBAR: Summary Tile -->
    <template v-if="hasPayrollPreview" #sidebar>
      <SummaryTile
        :total-gross="payrollTotals.employeeGross"
        :total-debited="payrollTotals.cashRequirement"
        :employee-pay-date="payrollPreviewConfig.payroll.payday"
        :approval-deadline="approvalDeadline"
        :withdraw-date="payrollPreviewConfig.payroll.withdrawDate"
        :payroll-status="payrollPreviewConfig.payroll.status"
        :payroll-status-date="payrollPreviewConfig.payroll.statusChangedAt"
      >
        <template v-if="actionButtons" #actions>
          <BaseBanner
            v-if="taxErrorBanner"
            class="mb-4"
            :show-close="false"
            :variant="taxErrorBanner.variant"
            :message="taxErrorBanner.message"
          >
            <span
              v-if="taxErrorBanner.richText"
              v-html="taxErrorBanner.message"
            ></span>
          </BaseBanner>
          <BaseBanner
            v-if="payrollPreviewConfig.banner"
            class="mb-4"
            :show-close="false"
            :variant="payrollPreviewConfig.banner.variant"
            :message="payrollPreviewConfig.banner.message"
          >
            <span
              v-if="payrollPreviewConfig.banner.richText"
              v-html="payrollPreviewConfig.banner.message"
            ></span>
          </BaseBanner>
          <BaseBanner
            v-if="showPastDepositMessage && hasNonZeroDirectDeposit"
            class="mb-4"
            :show-close="false"
            variant="error"
            message="Workers can only be paid with manual checks"
            :description="missedDirectDepositMsg"
          />

          <BaseBanner
            v-if="setupErrorInfo"
            class="mb-4"
            :show-close="false"
            :variant="setupErrorInfo.variant"
            :message="setupErrorInfo.message"
            :description="setupErrorInfo.description"
          />

          <BaseButton
            v-for="button in actionButtons"
            :key="button.key"
            class="mb-2"
            :disabled="button.disabled"
            size="large"
            :variant="button.variant"
            :flat="button.flat"
            @click="button.action"
          >
            {{ button.label }}
          </BaseButton>
        </template>
      </SummaryTile>
      <div class="flex justify-end mt-6 space-x-2">
        <BaseButton
          v-if="payrollPreviewConfig.isOffCycle"
          size="small"
          flat
          variant="tertiary"
          @click="toggleDeleteModal"
        >
          Delete Draft
        </BaseButton>

        <PayrollFaqDrawer has-tour @view-tour="$emit('view-tour')" />
      </div>
    </template>
    <portal to="layout">
      <ModalDialog
        v-if="showDeleteModal"
        id="delete-payroll"
        action-alignment="center"
        overlay="dark"
        size="small"
        @close="toggleDeleteModal"
      >
        <template #header> Delete payroll draft </template>
        <TypeBody
          class="text-base-700"
          tag="div"
          variant="text-x-small-tight"
          weight="regular"
        >
          Are you sure you would like to delete this draft? You will not be able
          to retrieve this draft.
        </TypeBody>
        <div class="flex justify-between mt-6">
          <BaseButton
            size="large"
            flat
            variant="secondary"
            @click="toggleDeleteModal"
          >
            Cancel
          </BaseButton>
          <BaseButton size="large" variant="tertiary" @click="deletePayroll">
            Delete Payroll
          </BaseButton>
        </div>
      </ModalDialog>
    </portal>
  </StickySidebarLayout>
</template>

<script>
import {
  ref,
  useStore,
  computed,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import {
  BaseButton,
  BaseBanner,
  ModalDialog,
  TypeBody,
} from '@bambeehr/pollen';
import useNotifications from '@bambeehr/use-notifications';

import { formatDate } from '@/utils/date';
import { getDeadlineStatuses } from '@/modules/payroll/utils/getDeadlineStatuses';
import { getMissedDirectDepositMessage } from '@/modules/payroll/utils/deadlineMessages';
import { RemainingOnboardingSteps } from '@/modules/payroll/constants/employee';
import { CheckOnboardingStatus } from '@/modules/payroll/constants/company';
import currency from '@bambeehr/currency';

import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal.vue';
import PageTitle from '@/modules/payroll/components/PageTitle/PageTitle';
import SummaryTile from '@/modules/payroll/components/SummaryTile/SummaryTile';
import StickySidebarLayout from './StickySidebarLayout';
import { LIST_PAYROLL } from '@/gql/queries/payroll_queries.gql';
import PayrollFaqDrawer from '@/components/FaqDrawer/PayrollFaqDrawer';

import {
  useDeletePayrollMutation,
  useApprovePayrollMutation,
  GoalName,
} from '@/gql/generated';

import { useApolloMutation } from '@/gql/apolloWrapper';
import useGoals from '@/modules/TaskCenter/hooks/useGoals/useGoals';
import useContactUsContent from '@/modules/ContactUs/hooks/useContactUsContent';

import useErrorHandler from '@/hooks/useErrorHandler';

const TAX_ERROR =
  'Federal Tax liabilities for this deposit period will exceed 100k';

const EMPLOYEE_BLOCKING_ERROR = "has a 'blocking' onboard_status";
const EMPLOYEE_DEACTIVATED_NET_PAY_SPLITS =
  'The following employees have deactivated net pay splits';

const PAYROLL_PAST_APPROVAL_DEADLINE = 'Payroll is past approval deadline';

export const ActionButtonKeys = Object.freeze({
  SUBMIT_PAYROLL: 'SUBMIT_PAYROLL',
  BACK_TO_EDIT: 'BACK_TO_EDIT',
  FINISH_LATER: 'FINISH_LATER',
  PREVIEW_PAYROLL: 'PREVIEW_PAYROLL',
  VIEW_SUMMARY: 'VIEW_SUMMARY',
  OVERVIEW: 'OVERVIEW',
});

export default {
  name: 'PayrollFlowLayout',
  components: {
    BaseBanner,
    BaseButton,
    LoadingModal,
    ModalDialog,
    PageTitle,
    StickySidebarLayout,
    SummaryTile,
    TypeBody,
    PayrollFaqDrawer,
  },
  props: {
    company: {
      type: Object,
      required: false,
      default: null,
    },
    payrollPreviewConfig: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['view-tour'],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { addSuccess, addError } = useNotifications();
    const { payrollGoal, completeGoalByName } = useGoals();
    const { customerSupportPhoneNumber } = useContactUsContent();

    const isSubmitting = ref(false);
    const isDeleting = ref(false);
    const showDeleteModal = ref(false);
    const showPastDepositMessage = ref(false);
    const taxErrorBanner = ref(null);
    const payroll = ref(null);
    const { companyId } = store.getters;
    const { handle: handleErrors } = useErrorHandler();

    const { mutate: deletePayrollService, onDone: onDelete } =
      useApolloMutation(useDeletePayrollMutation, {
        pending: isDeleting,
      });
    const {
      mutate: approvePayrollService,
      onDone: onPayrollApproved,
      onError: onPayrollApproveError,
    } = useApolloMutation(useApprovePayrollMutation, {
      pending: isSubmitting,
    });

    async function submitPayroll() {
      if (isSubmitting.value) {
        return;
      }

      const data = {
        id: props.payrollPreviewConfig.payroll.id,
        version: props.payrollPreviewConfig.payroll.version,
      };

      approvePayrollService({ data });

      onPayrollApproved(({ approvePayroll: res }) => {
        payroll.value = res;
        if (payrollGoal.value && !payrollGoal.value.completedAt) {
          completeGoalByName(GoalName.PayrollOnboarding);
        }
        router.push(
          `/payroll/${props.payrollPreviewConfig.payroll.id}/confirmation`
        );
      });

      onPayrollApproveError(({ responseErrors }) => {
        if (containsErrorMessage(responseErrors, TAX_ERROR)) {
          taxErrorBanner.value = {
            variant: 'error',
            richText: true,
            message: `Your company has a federal tax liability exceeding $100,000 for the current period. Please initiate a wire transfer to fund this payroll by contacting Bambee Support <br/><a href="tel:${customerSupportPhoneNumber.value}" target="_blank">${customerSupportPhoneNumber.value}</a>.`,
          };
        } else if (
          containsErrorMessage(responseErrors, EMPLOYEE_BLOCKING_ERROR)
        ) {
          addError(
            'One or more employees on this payroll have incomplete setup. Please complete their setup before submitting payroll.'
          );
        } else if (
          containsErrorMessage(responseErrors, PAYROLL_PAST_APPROVAL_DEADLINE)
        ) {
          addError(
            'Payroll is past approval deadline without approval, and further changes are not allowed.'
          );
        } else if (
          containsErrorMessage(
            responseErrors,
            EMPLOYEE_DEACTIVATED_NET_PAY_SPLITS
          )
        ) {
          addError(
            'One or more employees on this payroll have deactivated net pay splits. Please validate their bank account settings.'
          );
        } else {
          handleErrors(responseErrors);
        }
      });
    }

    function containsErrorMessage(response, message) {
      return response?.some((e) => e?.error?.message?.includes(message));
    }

    async function deletePayroll() {
      if (isDeleting.value) {
        return;
      }

      deletePayrollService(
        {
          data: {
            id: props.payrollPreviewConfig.payroll.id,
          },
        },
        {
          refetchQueries: [
            {
              query: LIST_PAYROLL,
              variables: {
                companyId,
              },
            },
          ],
        }
      );

      onDelete(() => {
        addSuccess('Payroll deleted');
        router.push(
          `/payroll/overview?deletedId=${props.payrollPreviewConfig.payroll.id}`
        );
      });
    }

    function toggleDeleteModal() {
      showDeleteModal.value = !showDeleteModal.value;
    }

    const isMissedDirectDepositWindow = computed(() => {
      return props.payrollPreviewConfig.payroll
        ? getDeadlineStatuses(props.payrollPreviewConfig.payroll)
            ?.isMissedDirectDepositWindow
        : false;
    });

    const approvalDeadline = computed(() => {
      return isMissedDirectDepositWindow.value
        ? props.payrollPreviewConfig.payroll.payday
        : props.payrollPreviewConfig.payroll.approvalDeadline;
    });

    const hasNonZeroDirectDeposit = computed(() => {
      return props.payrollPreviewConfig.hasNonZeroDirectDeposit;
    });

    const companyHasBlockedStatus = computed(() => {
      return (
        props.company?.checkOnboarding?.status ===
        CheckOnboardingStatus.BLOCKING
      );
    });

    const payrollTotals = computed(() => {
      return props.payrollPreviewConfig.payroll.totals || {};
    });

    const isMissingInfo = (entity) => {
      const remainingOnboardingSteps = entity?.remainingOnboardingSteps;
      const isCompleted =
        entity?.checkOnboarding?.status === CheckOnboardingStatus.COMPLETED;

      return (
        ((!isCompleted &&
          remainingOnboardingSteps?.includes(RemainingOnboardingSteps.SSN)) ||
          remainingOnboardingSteps?.includes(
            RemainingOnboardingSteps.EMPLOYEE_DETAILS
          )) ??
        false
      );
    };

    const isMissingBlockingInfo = (entity) => {
      const remainingOnboardingSteps = entity?.remainingOnboardingSteps;
      const isBlocking =
        entity?.checkOnboarding?.status === CheckOnboardingStatus.BLOCKING;

      return (
        isBlocking &&
        (remainingOnboardingSteps?.includes(RemainingOnboardingSteps.SSN) ||
          remainingOnboardingSteps?.includes(
            RemainingOnboardingSteps.EMPLOYEE_DETAILS
          ))
      );
    };

    const itemsWithMissingInfo = computed(() =>
      props.payrollPreviewConfig?.payroll
        ? [
            ...(props.payrollPreviewConfig.payroll?.contractorPayments?.filter(
              (p) => isMissingInfo(p?.contractor)
            ) || []),
            ...(props.payrollPreviewConfig.payroll?.items?.filter((i) =>
              isMissingInfo(i?.employee)
            ) || []),
          ]
        : null
    );

    const itemsWithMissingBlockingInfo = computed(() =>
      props.payrollPreviewConfig?.payroll
        ? [
            ...(props.payrollPreviewConfig.payroll?.contractorPayments?.filter(
              (p) => isMissingBlockingInfo(p?.contractor)
            ) || []),
            ...(props.payrollPreviewConfig.payroll?.items?.filter((i) =>
              isMissingBlockingInfo(i?.employee)
            ) || []),
          ]
        : null
    );

    const hasEarningItemsWithMissingInfo = computed(() =>
      itemsWithMissingInfo.value
        ? !!itemsWithMissingInfo.value?.reduce(
            (total, item) => total + item.grossPay,
            0
          )
        : null
    );

    const hasEarningItemsWithMissingBlockingInfo = computed(() =>
      itemsWithMissingBlockingInfo.value
        ? !!itemsWithMissingBlockingInfo.value?.reduce(
            (total, item) => total + item.grossPay,
            0
          )
        : null
    );

    const hasEmployeesWithMissingInfo = computed(
      () => !!itemsWithMissingInfo.value?.length
    );

    const hasEmployeesWithMissingBlockingInfo = computed(
      () => !!itemsWithMissingBlockingInfo.value?.length
    );

    const hasIncompleteInitialEmployeeSetup = computed(
      () => hasEmployeesWithMissingInfo.value && companyHasBlockedStatus.value
    );

    const shouldDisablePreviewPayrollBtn = computed(
      () =>
        !payrollTotals.value.employeeGross ||
        companyHasBlockedStatus.value ||
        hasEarningItemsWithMissingBlockingInfo.value ||
        hasIncompleteInitialEmployeeSetup.value
    );

    const hasEmployees = computed(() => !!payrollTotals.value.employeeNet);
    const hasContractors = computed(() => !!payrollTotals.value.contractorNet);

    const buttons = computed(() => {
      const employeeLabel = 'Run Payroll';
      const contractorLabel = 'Pay Contractors';

      let runPayrollLabel = employeeLabel;

      if (hasEmployees.value && hasContractors.value) {
        runPayrollLabel = `${employeeLabel} & ${contractorLabel}`;
      } else if (hasContractors.value) {
        runPayrollLabel = contractorLabel;
      }

      return [
        {
          key: ActionButtonKeys.SUBMIT_PAYROLL,
          label: runPayrollLabel,
          action: submitPayroll,
          variant: 'secondary',
          flat: false,
        },
        {
          key: ActionButtonKeys.BACK_TO_EDIT,
          label: 'Back to Edit',
          action: () => {
            router.push(
              `/payroll/${props.payrollPreviewConfig.payroll.id}/edit`
            );
          },
          variant: 'primary',
          flat: false,
        },
        {
          key: ActionButtonKeys.FINISH_LATER,
          label: 'Finish Later',
          action: () => {
            addSuccess('Payroll draft saved');
            router.push(`/payroll/overview`);
          },
          variant: 'secondary',
          flat: true,
        },
        {
          key: ActionButtonKeys.PREVIEW_PAYROLL,
          disabled: shouldDisablePreviewPayrollBtn.value,
          label: 'Preview Payroll',
          action: () => {
            if (
              isMissedDirectDepositWindow.value &&
              hasNonZeroDirectDeposit.value
            ) {
              showPastDepositMessage.value = true;

              return;
            }

            if (companyHasBlockedStatus.value) {
              return;
            }

            showPastDepositMessage.value = false;

            router.push(
              `/payroll/${props.payrollPreviewConfig.payroll.id}/preview`
            );
          },
          variant: 'secondary',
          flat: false,
        },
        {
          key: ActionButtonKeys.VIEW_SUMMARY,
          label: 'View Full Summary',
          action: () => {
            router.push(
              `/payroll/${props.payrollPreviewConfig.payroll.id}/summary`
            );
          },
          variant: 'primary',
          flat: false,
        },
        {
          key: ActionButtonKeys.OVERVIEW,
          label: 'Back to Overview',
          action: () => {
            router.push(`/payroll`);
          },
          variant: 'secondary',
          flat: true,
        },
      ];
    });

    const actionButtons = computed(() => {
      const actions = props.payrollPreviewConfig.actions || [];

      return actions
        .map((action) => buttons.value.find((button) => button.key === action))
        .filter((x) => !!x);
    });

    const missedDirectDepositMsg = computed(() => {
      return props.company
        ? getMissedDirectDepositMessage(props.company.processingPeriod)
        : '';
    });

    const hasPayrollPreview = computed(() => {
      return (
        props.payrollPreviewConfig &&
        !!Object.keys(props.payrollPreviewConfig.payroll).length
      );
    });

    const formattedPayroll = computed(() => {
      return {
        employeeNet: currency(
          props.payrollPreviewConfig.payroll.totals?.employeeNet
        ).format(),
        cashRequirement: currency(
          props.payrollPreviewConfig.payroll.totals?.cashRequirement
        ).format(),
        companyTaxes: currency(
          props.payrollPreviewConfig.payroll.totals?.companyTaxes
        ).format(),
        employeeTaxes: currency(
          props.payrollPreviewConfig.payroll.totals?.employeeTaxes
        ).format(),
        employeeGross: currency(
          props.payrollPreviewConfig.payroll.totals?.employeeGross
        ).format(),
        periodStart: formatDate(props.payrollPreviewConfig.payroll.periodStart),
        periodEnd: formatDate(props.payrollPreviewConfig.payroll.periodEnd),
      };
    });

    const setupErrorInfo = computed(() => {
      if (hasIncompleteInitialEmployeeSetup.value) {
        return {
          variant: 'error',
          message: 'Missing required worker information',
          description: `We require all workers' social security numbers and home addresses before submitting payroll.`,
        };
      }

      if (companyHasBlockedStatus.value) {
        return {
          variant: 'error',
          message: 'Company Setup Info Missing',
          description: 'Complete required tax setup before submitting payroll.',
        };
      }

      if (hasEarningItemsWithMissingBlockingInfo.value) {
        return {
          variant: 'error',
          message:
            'Unable to pay any workers missing their social security number or home address ',
          description:
            'Please remove any earnings for workers missing their social security number or home address to proceed with this payroll.',
        };
      }

      if (hasEmployeesWithMissingBlockingInfo.value) {
        return {
          variant: 'warning',
          message: 'Missing required worker information',
          description:
            'One or more workers have missing or invalid Social Security numbers or home addresses. This must be corrected to avoid tax filing issues and ensure timely payments. Please ask your workers to update their information.',
        };
      }

      if (hasEarningItemsWithMissingInfo.value) {
        return {
          variant: 'warning',
          message: 'Worker information incomplete',
          description:
            'One or more workers have missing or invalid Social Security numbers or home addresses. This must be corrected to avoid tax filing issues and ensure timely payments. Please ask your workers to update their information.',
        };
      }

      if (hasEmployeesWithMissingInfo.value) {
        return {
          variant: 'warning',
          message: 'Worker information incomplete',
          description:
            'One or more workers have missing or invalid Social Security numbers or home addresses. This must be corrected to avoid tax filing issues and ensure timely payments. Please ask your workers to update their information.',
        };
      }

      return null;
    });

    return {
      actionButtons,
      approvalDeadline,
      buttons,
      companyHasBlockedStatus,
      companyId,
      deletePayroll,
      formattedPayroll,
      hasEarningItemsWithMissingInfo,
      hasIncompleteInitialEmployeeSetup,
      hasEmployeesWithMissingInfo,
      hasNonZeroDirectDeposit,
      hasPayrollPreview,
      isMissedDirectDepositWindow,
      isSubmitting,
      itemsWithMissingInfo,
      missedDirectDepositMsg,
      payrollTotals,
      setupErrorInfo,
      shouldDisablePreviewPayrollBtn,
      showDeleteModal,
      showPastDepositMessage,
      submitPayroll,
      toggleDeleteModal,
      taxErrorBanner,
    };
  },
};
</script>
