import { computed, ref, useContext, watch } from '@nuxtjs/composition-api';
import { Axios, AxiosResponse } from 'axios';
import bam from '@/lib/bam';
import { debounce } from 'lodash';

const unreadMessagesCount = ref(0);
const hasUnreadMessages = ref(false);

let isInitialized;

const INTERCOM_UNREAD_CONVERSATIONS_URL =
  '/task/task/v1/users/intercom/unread-conversations';

const INTERCOM_WIDGET_OPENED_URL = '/task/task/v1/users/intercom/widget-opened';

const showChat = () => {
  try {
    Intercom('show');
  } catch (err) {
    window.DD_RUM?.addError({
      err,
      context: 'Unable to show Intercom chat widget',
    });
  }
};

const showMessages = () => {
  try {
    Intercom('showMessages');
  } catch (err) {
    window.DD_RUM?.addError({
      err,
      context: 'Unable to show Intercom messages',
    });
  }
};

const hideChat = () => {
  try {
    Intercom('hide');
  } catch (err) {
    window.DD_RUM?.addError({
      err,
      context: 'Unable to hide Intercom chat widget',
    });
  }
};

/**
 * @description creates a timestamp in our DB to be checked whether we should show a badge saying the customer has unread messages.
 * @param axios - Axios instance
 * @returns void
 */
const trackIntercomOpen = debounce((axios) => {
  try {
    axios.post(INTERCOM_WIDGET_OPENED_URL, {
      openedAt: new Date(),
    });
  } catch (err) {
    window.DD_RUM?.addError({
      err,
      context: 'Unable to track when Intercom was opened',
    });
  }
}, 500);

/**
 * @description Intercom is marking a conversation as read prematurely. This determines if a customer has unread intercom messages using this formula:
 * ```
 * last intercom widget opened < last message received
 * ```

 * @param axios - Axios instance
 * @returns boolean
 */
const getHasUnreadMessages = debounce((axios: Axios) => {
  axios
    .get(INTERCOM_UNREAD_CONVERSATIONS_URL)
    .then((res: AxiosResponse) => {
      if (res.data) {
        hasUnreadMessages.value = res.data as boolean;
      }
    })
    .catch((err) => {
      window.DD_RUM?.addError({
        err,
        context: 'Unable to determine if customer has unread Intercom messages',
      });
    });
}, 1000);

const useIntercom = () => {
  const { $axios } = useContext();

  if (!isInitialized) {
    getHasUnreadMessages($axios);
    isInitialized = true;
  }

  Intercom('onUnreadCountChange', (unreadCount) => {
    unreadMessagesCount.value = unreadCount;
  });

  Intercom('onShow', () => {
    trackIntercomOpen($axios);
    getHasUnreadMessages($axios);
  });

  Intercom('onHide', () => {
    hasUnreadMessages.value = false;
  });

  const shouldShowNewMessageBadge = computed(
    () => unreadMessagesCount.value || hasUnreadMessages.value
  );

  watch(shouldShowNewMessageBadge, (showNewMessageBadge) => {
    if (showNewMessageBadge) {
      bam.track('notification_bubble_displayed', {
        location: 'nav-conversations',
        value: `${unreadMessagesCount.value || 1}+`,
      });
    }
  });

  return {
    trackIntercomOpen,
    getHasUnreadMessages,
    showChat,
    showMessages,
    hideChat,
    unreadMessagesCount,
    shouldShowNewMessageBadge,
  };
};

export default useIntercom;
