import Store from '@/constants/Store';
import orderBy from 'lodash/orderBy';
import Vue from 'vue';

export const state = () => ({
  terminations: {},
  terminationsByCompany: {},
});

export const mutations = {
  removeTermination(state, terminationId) {
    const newTerminations = { ...state.terminations };
    delete newTerminations[terminationId];
    state.terminations = newTerminations;
  },

  saveTermination(state, termination) {
    Vue.set(state.terminations, termination._id, termination);
  },

  saveTerminations(state, { terminations, companyId }) {
    const newTerminations = {
      ...state.terminations,
    };

    const companyTerminations = [];
    terminations.forEach((item) => {
      newTerminations[item._id] = item;
      companyTerminations.push(item._id);
    });

    state.terminations = newTerminations;
    Vue.set(state.terminationsByCompany, companyId, companyTerminations);
  },

  saveTerminationStates(state, { terminationId, states }) {
    if (!state.terminations[terminationId]) {
      return;
    }

    Object.entries(states).forEach(([key, value]) => {
      Vue.set(state.terminations[terminationId].states, key, value);
    });
  },
};

export const getters = {
  terminationsByCompany: (state) => (companyId) => {
    const terminations = (state.terminationsByCompany[companyId] || [])
      .map((id) => state.terminations[id])
      .filter((t) => !!t);

    return orderBy(terminations, ['created_at'], ['desc']);
  },
  terminationsInScope: (state, getters, rootState, rootGetters) =>
    rootGetters[Store.auth.Getters.IS_COMPANY_ADMIN]
      ? getters.terminationsByCompany(rootGetters.companyId)
      : [],
  terminationsCountInScope: (state, getters) =>
    getters.terminationsInScope.length,
};

export const actions = {
  create({ commit }, terminationData) {
    return this.$axios
      .post('/v0/app/termination/', terminationData)
      .then((res) => {
        const { termination, feed, ticket, employee } = res.data;

        // TODO: simplify - too many things
        if (termination) {
          commit('saveTermination', termination);
        } else {
          commit('saveTermination', feed._data);

          commit('feed/set', feed, { root: true });
          commit('tickets/set', ticket, { root: true });
        }

        commit('users/setEmployee', employee, { root: true });
        commit('users/setUser', { user: employee }, { root: true });
      });
  },
  complete(_, terminationId) {
    return this.$axios.put(`/v0/app/termination/${terminationId}/complete`);
  },
  refuseSignDocument({ commit }, { terminationId, item }) {
    return this.$axios
      .put(`/v0/app/termination/${terminationId}/refuse-sign-document`, {
        type: item.type,
        refused: item.signRefused,
        refId: item._id,
      })
      .then((res) =>
        commit('saveTerminationStates', {
          terminationId,
          states: res.data,
        })
      );
  },
  addSignedDocument({ commit }, { terminationId, item, files }) {
    return this.$axios
      .post(`/v0/app/termination/${terminationId}/add-signed-documents`, {
        type: item.type,
        refId: item._id,
        files,
      })
      .then((res) =>
        commit('saveTerminationStates', {
          terminationId,
          states: res.data,
        })
      );
  },
  deleteSignedDocuments({ commit }, { terminationId, item }) {
    return this.$axios
      .delete(`/v0/app/termination/${terminationId}/delete-signed-documents`, {
        data: {
          documentId: item._id,
          type: item.type,
        },
      })
      .then((res) =>
        commit('saveTerminationStates', {
          terminationId,
          states: res.data,
        })
      );
  },

  async fetchTerminations({ commit }, companyId) {
    const { data } = await this.$axios.get(
      `/v0/companies/v1/company/${companyId}/terminations`
    );
    const { terminations } = data;
    commit('saveTerminations', {
      terminations,
      companyId,
    });
  },

  async removeTermination({ commit }, terminationId) {
    commit('removeTermination', terminationId);
  },
};
