import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import Routes from '@/modules/Training/constants/routes';
import canPurchaseTraining from '@/modules/Training/helpers/canPurchaseTraining';
import hasPurchasedTraining from '@/modules/Training/helpers/hasPurchasedTraining';
import { removeTrailingSlash } from '@/utils/routes';

const trainingRegistrationRouteGuard = async ({ redirect, store, route }) => {
  const canPurchase = await canPurchaseTraining(store);

  if (!canPurchase) {
    redirect('/');

    return;
  }

  const { isTierZero } = useCurrentPlan();

  const { companyId } = store.getters;
  const hasPurchased = await hasPurchasedTraining(companyId);

  const path = removeTrailingSlash(route.path);

  if (isTierZero.value && path === Routes.REGISTRATION) {
    redirect(Routes.EE_PORTAL);
  }

  // if the company already purchased training, route to the ER Portal page
  if (
    (hasPurchased && path === Routes.REGISTRATION) ||
    (hasPurchased && path === Routes.REGISTRATION_CONFIRMATION)
  ) {
    redirect(Routes.ER_PORTAL);
  }

  // if (!hasPurchased && path === Routes.REGISTRATION_CONFIRMATION) {
  //   redirect(Routes.REGISTRATION);
  // }
};

export default trainingRegistrationRouteGuard;
