



























































































import { defineComponent, PropType } from '@nuxtjs/composition-api';

import { BaseButton, TypeBody, BaseTable } from '@bambeehr/pollen';

const tableHeaderData = [
  'Period Start',
  'Period End',
  'Approval Deadline',
  'Pay Day',
];

export default defineComponent({
  name: 'PaySchedule',
  components: {
    BaseButton,
    TypeBody,
    BaseTable,
  },
  props: {
    payScheduleDays: {
      type: Array as PropType<string[][]>,
      required: true,
    },
    hasFullPaySchedule: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    btnSize: {
      type: String as PropType<string>,
      default: 'medium',
    },
    showButton: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  setup(_, { emit }) {
    function getAllScheduledPaydays() {
      emit('get-all-scheduled-paydays');
    }

    return {
      getAllScheduledPaydays,
      tableHeaderData,
    };
  },
});
