import { QnaTypes } from '@/constants/Qna';
import getStaffCountFromMap from '@/utils/getStaffCountFromMap';
import {
  computed,
  ComputedRef,
  reactive,
  ref,
  useStore,
} from '@nuxtjs/composition-api';
import CompanyOverviewQNA from './CompanyOverviewQNA';

interface EmployeeBreakdownInput {
  hourly: number;
  salary: number;
  partTime: number;
  contractor: number;
}

const result = ref<CompanyOverviewQNA>();
const state = reactive({
  errorMsg: '',
});

const useReportedStaff = () => {
  const store = useStore();

  const fetch = async () => {
    try {
      result.value = await store.dispatch(
        'qna/fetchByType',
        QnaTypes.COMPANY_OVERVIEW
      );
    } catch (error: any) {
      state.errorMsg = error;
    }
  };

  if (!result.value) {
    fetch();
  }

  const reportedStaffCount: ComputedRef<number> = computed(() =>
    getStaffCountFromMap(result.value?.answers?.employeeBreakdown)
  );

  const surveyId: ComputedRef<string | undefined> = computed(
    () => result.value?.id
  );

  const updateReportedStaffCount = ({
    breakdown,
  }: {
    breakdown: EmployeeBreakdownInput;
  }) => {
    const answers = {
      employeeBreakdown: {
        numOfFullTimeHourlyEmployees: breakdown.hourly,
        numOfFullTimeSalaryEmployees: breakdown.salary,
        numOfPartTimeEmployees: breakdown.partTime,
        numOfContractors: breakdown.contractor,
      },
    };

    return store.dispatch('qna/saveAnswers', {
      answers,
      id: surveyId.value,
    });
  };

  return {
    errorMsg: state.errorMsg,
    reportedStaffCount,

    fetch,
    updateReportedStaffCount,
  };
};

export default useReportedStaff;
