<template>
  <div
    class="has-text-centered wizardCard"
    :class="{ 'pip-wizard': !flush, 'pip-wizard-flush': flush }"
  >
    <div class="pip-header">
      <slot name="head" />
    </div>
    <div
      class="has-text-centered"
      :class="{ 'pip-body pt-8 pb-8': !flush, 'pip-body-flush': flush }"
    >
      <div class="absolute-wrapper">
        <slot name="body" />
        <div class="addButton">
          <slot name="addButton" />
        </div>
      </div>
      <div class="report">
        <slot name="report" />
      </div>
    </div>
    <div class="pip-foot">
      <slot name="foot">
        <!--<bam-button class="is-primary px-12">Next</button>-->
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    flush: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  computed: {
    hasSlotBody() {
      return this.$slots.body
    },
    hasSlotAddButton() {
      return this.$slots.addButton
    },
    hasSlotReport() {
      return this.$slots.report
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/shared.scss';

.pip-wizard {
  padding: 2rem;
  padding-bottom: 3rem;
}

.pip-wizard-flush {
  padding-bottom: 3rem;
}

.pip-body-flush {
}

.pip-wizard.report .pip-body .absolute-wrapper {
  display: none;
}

.input_box {
  margin: 0 auto;
  max-width: remy(500);
  padding: 1rem;
  position: relative;
}

.addButton {
  bottom: -0.75rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.file-name {
  background-color: $white-grey-3;
  color: $black-grey-2;
  font-size: remy(14);

  label.control {
    font-size: remy(12);
  }
}
</style>
