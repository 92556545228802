import useCompanyService from '@/hooks/useCompanyService';
import hasBulkStaffMessagingFlagEnabled from '@/modules/StaffManagement/accessControl/hasBulkStaffMessagingFlag';
import hasStaffMessagingFlagEnabled from '@/modules/StaffManagement/accessControl/hasStaffMessagingFlag';
import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import {
  MessageGroupType,
  MessageRecipients,
} from '@/modules/StaffManagement/models';
import { computed, ComputedRef, ref } from '@nuxtjs/composition-api';

const initialized = ref<boolean>(false);

// LaunchDarkly feature flags
const canAccessStaffMessaging = ref<boolean>(false);
const checkCanAccessStaffMessaging = async () => {
  canAccessStaffMessaging.value = await hasStaffMessagingFlagEnabled();
};

const canAccessBulkStaffMessaging = ref<boolean>(false);
const checkCanAccessBulkStaffMessaging = async () => {
  canAccessBulkStaffMessaging.value = await hasBulkStaffMessagingFlagEnabled();
};

// type of bulk message to send
const bulkMessageGroupType = ref<MessageGroupType>(MessageGroupType.CUSTOM);

// list of staff members to send a message to
const messageRecipients = ref<MessageRecipients>([]);
const messageTitleDecorator = ref<string>('');
const clearMessageRecipients = () => {
  messageRecipients.value = [];
  messageTitleDecorator.value = '';
  bulkMessageGroupType.value = MessageGroupType.CUSTOM;
};

const useStaffMessaging = () => {
  if (!initialized.value) {
    initialized.value = true;
    checkCanAccessStaffMessaging();
    checkCanAccessBulkStaffMessaging();
  }

  const { bulkMessageStaff } = useCompanyService();
  const { allStaff } = useStaffManagement();

  const startSendMessage = (
    staffIds: string[],
    titleDecorator = '',
    groupType: MessageGroupType = MessageGroupType.CUSTOM
  ) => {
    const staffList: MessageRecipients = [];
    staffIds.forEach((id) => {
      const user: any = allStaff.value.find((u: any) => u.id === id);
      if (user) {
        staffList.push({
          staffId: id,
          staffName: user.profile.staffName || 'Employee',
          active: user.active,
          signatureRequired: user.signatureRequired,
        });
      }
    });
    messageRecipients.value = staffList;
    messageTitleDecorator.value = titleDecorator;
    bulkMessageGroupType.value = groupType;
  };

  const showSendMessage: ComputedRef<boolean> = computed(() => {
    return canAccessStaffMessaging.value && messageRecipients.value.length > 0;
  });

  return {
    bulkMessageGroupType,
    bulkMessageStaff,
    canAccessStaffMessaging,
    canAccessBulkStaffMessaging,
    clearMessageRecipients,
    messageRecipients,
    messageTitleDecorator,
    showSendMessage,
    startSendMessage,
  };
};

export default useStaffMessaging;
