// TODO(kevin.lee) completely deprecate this file with a refactor
// use the other store (feed[s].js) going forward
// in the refactor, move all of the methods here to feeds.js
// add byEmployeeId attribute to state in feeds.js
// once these steps are completed, delete this file
import Vue from 'vue';

export const state = () => ({
  ids: [],
  byId: {},
  byEmployeeId: {},
});

function setFeed(state, feed) {
  if (!state.byId[feed._id]) {
    state.ids.push(feed._id);
  }

  Vue.set(state.byId, feed._id, feed);

  return feed;
}

function setEmployeeFeed(state, employeeId, feed) {
  if (!state.byEmployeeId[employeeId]) {
    Vue.set(state.byEmployeeId, employeeId, {
      ids: [],
      byId: {},
    });
  }

  if (!state.byEmployeeId[employeeId].byId[feed._id]) {
    state.byEmployeeId[employeeId].ids.push(feed._id);
  }

  Vue.set(state.byEmployeeId[employeeId].byId, feed._id, feed);

  return feed;
}

export const mutations = {
  set(state, payload) {
    setFeed(state, payload);
  },

  setAll(state, payload) {
    state.ids = [];
    state.byId = {};

    payload.forEach((i) => setFeed(state, i));
  },

  setEmployee(state, { employee_id: employeeId, payload }) {
    setEmployeeFeed(state, employeeId, payload);
  },
};

export const getters = {
  all(state) {
    return state.ids.map((i) => state.byId[i]);
  },

  employee: (state, getters) => (employeeId) => {
    return getters.all.filter((f) => f._employee === employeeId);
  },

  documents(state, getters) {
    return getters.all.filter((feed) => feed.model === 'Document');
  },

  noDocuments(state, getters) {
    return getters.all.filter((feed) => feed.model !== 'Document');
  },
};

export const actions = {
  getMyFeeds({ commit }) {
    return this.$axios.get('/v0/app/my/feed').then((res) => {
      commit('setAll', res.data);

      const termination = (
        res.data.find((f) => f.model === 'Termination') || {}
      )._data;

      if (typeof termination !== 'undefined') {
        commit('termination/saveTermination', termination, { root: true });
      }

      return res.data;
    });
  },
  // eslint-disable-next-line
  getEmployee({ commit }, employeeId) {
    if (employeeId && typeof employeeId === 'string') {
      return this.$axios
        .get(`/v0/app/feed/${employeeId}`)
        .then((res) => {
          commit('setAll', res.data);

          const termination = (
            res.data.find((f) => f.model === 'Termination') || {}
          )._data;

          if (typeof termination !== 'undefined') {
            commit('termination/saveTermination', termination, {
              root: true,
            });
          }

          return res.data;
        })
        .catch((error) => {
          window.DD_RUM?.addError(error);
        });
    }
  },

  createVerbalWarning({ commit }, { employee_id: employeeId }) {
    return this.$axios
      .post(`/v0/app/feed/verbal-warning/create`, { employee_id: employeeId })
      .then((res) => {
        commit('setEmployee', {
          employee_id: employeeId,
          payload: res.data,
        });

        return res.data;
      });
  },

  createWrittenWarning({ commit }, { employee_id: employeeId }) {
    return this.$axios
      .post(`/v0/app/feed/written-warning/create`, { employee_id: employeeId })
      .then((res) => {
        commit('setEmployee', {
          employee_id: employeeId,
          payload: res.data,
        });

        return res.data;
      });
  },

  createFinalWrittenWarning({ commit }, { employee_id: employeeId }) {
    return this.$axios
      .post(`/v0/app/feed/written-warning/create`, {
        employee_id: employeeId,
        isFinal: true,
      })
      .then((res) => {
        commit('setEmployee', {
          employee_id: employeeId,
          payload: res.data,
        });

        return res.data;
      });
  },

  createPip({ commit }, { employee_id: employeeId }) {
    return this.$axios
      .post(`/v0/app/feed/pip/create`, { employee_id: employeeId })
      .then((res) => {
        commit('setEmployee', {
          employee_id: employeeId,
          payload: res.data,
        });

        return res.data;
      });
  },

  printCap({ commit }, { feed_id: feedId }) {
    return this.$axios
      .post(`/v0/app/feed/cap/${feedId}/mark-printed`)
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  uploadSignedCap({ commit }, { feed_id: feedId, files }) {
    return this.$axios
      .post(`/v0/app/feed/cap/${feedId}/upload-signed`, { files })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  requestApproval({ commit }, { employee_id: employeeId, feed_id: feedId }) {
    return this.$axios
      .post(`/v0/app/feed/cap/${feedId}/request-approval`)
      .then((res) => {
        commit('setEmployee', {
          employeeId,
          payload: res.data,
        });

        return res.data;
      });
  },
};
