






import FilledLogo from '@/assets/images/logo/bambee-filled.svg?inline';
import {
  defineComponent,
  computed,
  ref,
  watch,
  nextTick,
} from '@nuxtjs/composition-api';
import useBambeePlus from '@/hooks/useBambeePlus/useBambeePlus';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo/useCompanyBillingInfo';

export default defineComponent({
  name: 'WelcomeMessage',
  components: {
    FilledLogo,
  },
  setup() {
    const { isBambeePlus } = useBambeePlus();
    const { isElite } = useCompanyBillingInfo();

    const logoClass = ref('');
    const isFlipped = ref(false);

    const startFlipAnimation = () => {
      nextTick(() => {
        setTimeout(() => {
          isFlipped.value = true;

          // Wait for the animation to complete, then flip back and change color
          setTimeout(() => {
            isFlipped.value = false;
            if (isBambeePlus.value) {
              logoClass.value = 'bambeePlus';
            } else if (isElite.value) {
              logoClass.value = 'elite';
            } else {
              logoClass.value = '';
            }
          }, 400); // Duration of the animation
        }, 1000); // Delay before starting the animation
      });
    };

    const companyPlanType = computed(() => {
      if (isBambeePlus.value) {
        return 'Bambee Plus';
      } else if (isElite.value) {
        return 'Elite';
      } else {
        return '';
      }
    });

    watch(
      companyPlanType,
      (newVal) => {
        if (newVal) {
          startFlipAnimation();
        }
      },
      {
        immediate: true,
      }
    );

    return {
      logoClass,
      isFlipped,
    };
  },
});
