import useMiddlewareContext from './hooks/useMiddlewareContext';
import isBefore from 'date-fns/isBefore';
import subDays from 'date-fns/subDays';
import LocalStorageKey from '@/constants/LocalStorage';
import { getFromLocalStorage, saveInLocalStorage } from '@/utils/localStorage';

const hardRedirect = (path) => {
  if (!window.location.href.includes(path)) {
    window.location.replace(path);
  }
};

// To reduce the risk of breaking hook-powered flows
// we only want to force update when the user is on these root level routes
const WHITE_LISTED_ROUTES = [
  '/login',
  '/task-center',
  '/dashboard',
  '/payroll',
  '/my-pay',
  '/employee',
  '/policy',
  '/hr-compliance',
  '/requests',
  '/voices',
  '/cabinet/active',
  '/insurance',
  '/benefits',
  '/refer-a-friend',
  '/settings',
];

export default () => {
  const { route } = useMiddlewareContext();
  const needsAppRefresh = window?.__needsAppUpdate;
  const versionUpdated = getFromLocalStorage(LocalStorageKey.VERSION_UPDATED);
  const versionUpdatedOverADayAgo =
    versionUpdated &&
    isBefore(new Date(versionUpdated), subDays(new Date(), 1));
  const isWhiteListedRoute = WHITE_LISTED_ROUTES.includes(route.path);

  if (
    needsAppRefresh &&
    (!versionUpdated || versionUpdatedOverADayAgo) &&
    isWhiteListedRoute
  ) {
    saveInLocalStorage(
      LocalStorageKey.VERSION_UPDATED,
      new Date().toISOString()
    );
    hardRedirect(route.path);
  }
};
