

























































































import SimpleCard from '@/components/SimpleCard/SimpleCard.vue';
import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import {
  BaseButton,
  BaseCard,
  BaseDivider,
  BaseIcon,
  BaseTag,
  ModalDialog,
  TypeBody,
  TypeDisplay,
  TypeOverline,
} from '@bambeehr/pollen';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { convertToDollars, formatCurrency } from '@/utils/currency';

const planDetails = [
  {
    icon: 'check',
    text: 'Keep your HR manager',
  },
  {
    icon: 'check',
    text: 'New 12-month plan starts today',
  },
  {
    icon: 'check',
    text: 'Lock in pricing for 12 months; only pay more if your staff grows',
  },
  {
    icon: 'check',
    text: 'Receive a prorated credit for the remainder of your current plan',
  },
];

export default defineComponent({
  name: 'IntervalUpgradeOffer',
  components: {
    ModalDialog,
    TypeBody,
    TypeDisplay,
    BaseDivider,
    BaseCard,
    BaseTag,
    BaseIcon,
    TypeOverline,
    BaseButton,
    SimpleCard,
  },
  props: {
    userSavings: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { currentPlan } = useCurrentPlan();
    const monthlyCost = computed(() => currentPlan.value?.monthlyPrice! * 12);

    const totalDueToday = computed(() =>
      convertToDollars(
        props.userSavings?.totalBilledNow - props.userSavings?.totalTaxNow
      )
    );

    const savings = computed(() =>
      Math.floor(
        props.userSavings?.annualDiscount + props.userSavings?.annualSavings
      )
    );

    const planCredit = computed(() =>
      convertToDollars(props.userSavings?.proratedDiscountAtPurchase)
    );

    const percentOff = computed(() =>
      Math.round(savings.value / monthlyCost.value)
    );

    const priceDetails = computed(() => [
      {
        text: 'Bambee Plan x 12 Months',
        price: {
          text: formatCurrency(monthlyCost.value),
          class: 'line-through',
        },
      },
      {
        text: `SAVE ${percentOff.value}%`,
        price: {
          text: `-${convertToDollars(savings.value)}`,
        },
        component: BaseTag,
        size: 'small',
      },
      {
        text: 'Current Plan Credit',
        price: {
          text: `${planCredit.value}`,
        },
      },
      {
        text: 'Total Due Today',
        price: {
          text: totalDueToday.value,
          class: 'text-success-default',
        },
      },
    ]);

    return {
      planDetails,
      priceDetails,
      percentOff,
      savings,
      convertToDollars,
    };
  },
});
