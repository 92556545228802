











import { defineComponent, ref } from '@nuxtjs/composition-api';
import { BaseButton } from '@bambeehr/pollen';
import Contentful from '@/services/Contentful';
import ContentfulEntryIDs from '@/lib/globals/ContentfulEntryIDs';
import downloadStringToFile from '@/utils/downloadStringToFile';
import { useApolloQuery } from '@/gql/apolloWrapper';
import { useGetCompanyQuery, TaxExemptStatus } from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';

export default defineComponent({
  name: 'DownloadTemplateButton',
  components: {
    BaseButton,
  },
  setup() {
    const { companyId } = useCurrentCompany();
    const companyType = ref();
    useApolloQuery(
      useGetCompanyQuery,
      { id: companyId.value },
      { data: companyType },
      { placeholderPick: ({ getCompany: res }) => res.taxExemptStatus }
    );
    const getId = (status: string) => {
      switch (status) {
        case TaxExemptStatus.NonProfitExempt:
          return ContentfulEntryIDs.PAYROLL_BULK_UPLOAD_NPO_TEMPLATE;
        case TaxExemptStatus.ReligiousExempt:
          return ContentfulEntryIDs.PAYROLL_BULK_UPLOAD_RELIGIOUS_NPO_TEMPLATE;
        default:
          return ContentfulEntryIDs.PAYROLL_BULK_UPLOAD_TEMPLATE;
      }
    };
    const id = ref(getId(companyType.value));
    const downloading = ref(false);
    const download = async () => {
      downloading.value = true;
      const content = await Contentful.getEntry(id.value);
      downloadStringToFile(
        content.fields.contents,
        content.fields.filename || 'bambee-payroll-standard-template.csv',
        'text/csv'
      );
      downloading.value = false;
    };

    return {
      download,
      downloading,
    };
  },
});
