




































































import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api';
import { BaseButton, TypeBody, TextInput } from '@bambeehr/pollen';

import useManageCompanyRoles from './useManageCompanyRoles';

export default defineComponent({
  components: {
    TextInput,
    TypeBody,
    BaseButton,
  },
  props: {
    role: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const title = ref(props.role?.title);
    // When creating a new role, we'll start it in the editing state
    const isEditing = ref(props.role.isNew);
    const inputEl = ref<any>(null);
    const inputError = ref('');

    const { saveRole, deleteRole } = useManageCompanyRoles();

    // Focus input on newly created role row
    onMounted(() => {
      inputEl.value?.$el?.querySelector('input')?.focus();
    });

    const toggleEditingState = () => {
      isEditing.value = !isEditing.value;
    };

    const clearInputError = () => {
      inputError.value = '';
    };

    const discardChanges = () => {
      title.value = props.role?.title;
      toggleEditingState();
      clearInputError();
    };

    const save = () => {
      clearInputError();
      saveRole(props.role, title.value, inputError);

      if (!inputError.value) {
        toggleEditingState();
      }
    };

    const roleCount = computed(() => props.role?.workerRoles?.length || 0);

    return {
      title,
      toggleEditingState,
      isEditing,
      discardChanges,
      roleCount,
      inputEl,
      save,
      deleteRole,
      inputError,
    };
  },
});
