<template>
  <div>
    <PayrollFlowLayout
      v-if="!isLoading && !!payroll && !fatalErr"
      :payroll-preview-config="payrollPreviewConfig"
    >
      <template #title>
        Payroll Preview for {{ formattedPayroll.periodStart }} -
        {{ formattedPayroll.periodEnd }}
      </template>

      <div class="border-b border-gray-5 mb-6">
        <TypeBody variant="text-small" tag="div" class="mb-2">
          Here's a summary of your payroll, please take a moment to review the
          information before submitting. If you'd like to share a detailed
          payroll report click below.
        </TypeBody>

        <BaseButton
          class="mb-6"
          variant="inverted"
          pre-icon="download"
          @click="downloadPayrollReport"
        >
          Payroll Report
        </BaseButton>
      </div>

      <div class="border-b border-base-400 mb-6">
        <TypeDisplay
          variant="x-small"
          weight="strong"
          tag="div"
          class="mb-6 text-black"
        >
          Compliance Summary
        </TypeDisplay>

        <div class="flex ml-1 flex-col gap-4 mb-6">
          <div class="flex gap-2 items-center">
            <BaseIcon
              icon="checkCircleSolid"
              class="fill-current text-success-default"
            />
            <TypeBody variant="text-small" weight="regular">
              Gross to net pay calculated
            </TypeBody>
          </div>
          <div
            v-if="payroll.totals.employeeNet"
            class="flex gap-2 items-center"
          >
            <BaseIcon
              icon="checkCircleSolid"
              class="fill-current text-success-default"
            />
            <TypeBody variant="text-small" weight="regular">
              Company federal, state, and local taxes calculated
            </TypeBody>
          </div>
          <div
            v-if="payroll.totals.employeeNet"
            class="flex gap-2 items-center"
          >
            <BaseIcon
              icon="checkCircleSolid"
              class="fill-current text-success-default"
            />
            <TypeBody variant="text-small" weight="regular">
              Employee federal, state, and local taxes calculated
            </TypeBody>
          </div>
          <div class="flex gap-2 items-center">
            <BaseIcon
              icon="checkCircleSolid"
              class="fill-current text-success-default"
            />
            <TypeBody variant="text-small" weight="regular">
              We’ll automatically file all taxes to the appropriate agencies
            </TypeBody>
          </div>
        </div>
      </div>

      <div class="flex justify-between items-center mb-4">
        <TypeDisplay
          variant="x-small"
          weight="strong"
          tag="div"
          class="text-black"
        >
          Earnings Summary
        </TypeDisplay>
        <BaseButton size="small" variant="inverted" @click="openStaffPayDrawer">
          View Worker Breakdown
        </BaseButton>
      </div>

      <EarningsTable
        :is-accordion="true"
        :earnings="companyEarningsSummary"
        :total="payroll.totals.liability"
        total-label="Total Company Cost"
        total-description="Amount the company is responsible for"
        :gross="payroll.totals.employeeGross"
      />

      <EarningsTable
        v-if="payroll.totals.employeeNet"
        :is-accordion="true"
        :earnings="employeeEarningsSummary"
        :total="payroll.totals.employeeNet"
        total-label="Total Employee Pay"
        total-description="Amount your employees will take home"
        :gross="payroll.totals.employeeGross"
      />

      <EarningsTable
        v-if="payroll.totals.contractorNet"
        :is-accordion="true"
        :earnings="contractorEarningsSummary"
        :total="payroll.totals.contractorNet"
        total-label="Total Contractor Pay"
        total-description="Amount all contractors will take home"
      />

      <DrawerPanel
        :active="showStaffPayDrawer"
        :show-close="false"
        size="giant"
        @close="closeStaffPayDrawer"
      >
        <div class="p-9">
          <header class="mb-6 cursor-pointer" @click="closeStaffPayDrawer">
            <TypeDisplay variant="small" weight="strong">
              <BaseIcon icon="chevronLeft" class="mr-3" />
              Worker Pay Breakdown
            </TypeDisplay>
          </header>
          <StaffPayTable :payroll-id="payroll.id" />
        </div>
      </DrawerPanel>
    </PayrollFlowLayout>
    <LoadingModal v-if="isLoading">
      <template #header> Generating payroll preview </template>
      Please do not leave this page or close your browser, while we generate
      your preview. You will be redirected automatically once complete.
    </LoadingModal>
    <div v-if="fatalErr">
      <portal to="crumbs">
        <span>Payroll Preview</span>
      </portal>
      <BaseBanner
        class="mt-4"
        :message="fatalErr.message"
        :description="fatalErr.description"
        :show-close="false"
        variant="error"
      >
        <template #actions>
          <BaseButton
            size="small"
            variant="tertiary"
            flat
            @click="reloadCurrentPage"
          >
            Reload
          </BaseButton>
          <BaseButton
            v-if="showEditErrorLink"
            size="small"
            variant="tertiary"
            flat
            tag="nuxt-link"
            :to="`/payroll/${payrollId}/edit`"
          >
            Back to Edit
          </BaseButton>
          <BaseButton
            size="small"
            variant="tertiary"
            flat
            tag="nuxt-link"
            to="/requests/pay-benefits-payroll"
          >
            Get Help
          </BaseButton>
        </template>
      </BaseBanner>
    </div>
  </div>
</template>

<script>
import useFileDownloader from '@/composables/useFileDownloader';
import { useApolloMutation } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';

import {
  getCompanyEarningsSummary,
  getContractorEarningsSummary,
  getEmployeeEarningsSummary,
} from '@/modules/payroll/components/EarningsTable/earningsSummary';
import EarningsTable from '@/modules/payroll/components/EarningsTable/EarningsTable.vue';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal.vue';
import StaffPayTable from '@/modules/payroll/components/StaffPayTable/StaffPayTable';
import { CheckError } from '@/modules/payroll/constants/errors';
import { PayrollStatus } from '@/modules/payroll/constants/payroll';
import PayrollFlowLayout, {
  ActionButtonKeys,
} from '@/modules/payroll/layouts/PayrollFlowLayout';
import { usePreviewPayrollMutation } from '@/gql/generated';
import filterPayrollItemsWithNoIncome from '@/modules/payroll/utils/filterPayrollItemsWithNoIncome';
import { sortPayrollPayByFullName } from '@/modules/payroll/utils/sorting';
import { FILE_SERVER_URL } from '@/plugins/apollo/config/createApolloClient';
import { formatDate } from '@/utils/date';
import currency from '@bambeehr/currency';
import {
  BaseBanner,
  BaseButton,
  BaseIcon,
  DrawerPanel,
  TypeBody,
  TypeDisplay,
} from '@bambeehr/pollen';
import useNotifications from '@bambeehr/use-notifications';
import {
  computed,
  ref,
  useContext,
  useRoute,
  useRouter,
} from '@nuxtjs/composition-api';

export default {
  components: {
    BaseBanner,
    BaseButton,
    DrawerPanel,
    LoadingModal,
    PayrollFlowLayout,
    StaffPayTable,
    TypeBody,
    BaseIcon,
    TypeDisplay,
    EarningsTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { addInfo, addError } = useNotifications();
    const { companyId } = useCurrentCompany();
    const { fileDownloader } = useFileDownloader();

    const payrollResult = ref(null);
    const isLoading = ref(true);
    const showCompanyCostDrawer = ref(false);
    const showStaffPayDrawer = ref(false);
    const fatalErr = ref(null);
    const showEditErrorLink = ref(true);

    const payrollId = computed(() => route.value?.params?.payroll_id);

    const {
      mutate: previewPayroll,
      onDone: onPreviewReady,
      onError: onPreviewError,
    } = useApolloMutation(usePreviewPayrollMutation, { pending: isLoading });

    previewPayroll({ id: payrollId.value });

    onPreviewReady(({ previewPayroll: res }) => {
      payrollResult.value = res;
    });

    onPreviewError(({ responseErrors, networkError }) => {
      if (responseErrors.length) {
        const err = responseErrors[0];
        const errType = err?.raw?.extensions?.code;

        switch (errType) {
          case CheckError.PAYROLL_ALREADY_APPROVED:
            addError(err?.error?.message);
            router.push(`/payroll/${payrollId.value}/summary`);
            break;

          case CheckError.NET_NEGATIVE_PAY:
            // This sometimes happens when an EE has strange withholdings.
            // Check doesn't support zeroing out their net pay yet, so we'll log and error so we can reach out to them.
            window.DD_RUM?.addError({
              errorType: errType,
              companyId: companyId.value,
              payrollId,
            });
            fatalErr.value = {
              message: 'Unable to Preview Payroll',
              description:
                'There is an issue with the tax calculations for this payroll. Please contact support.',
            };
            showEditErrorLink.value = false;
            break;

          default:
            showEditErrorLink.value = true;
            fatalErr.value = {
              message: 'Unable to Preview Payroll',
              description: err?.error?.message,
            };
        }
      }

      if (networkError) {
        fatalErr.value = {
          message: 'Unable to Preview Payroll',
          description:
            'We are unable to connect to our server. Please try again later.',
        };
      }
    });

    const payroll = computed(() => {
      if (!payrollResult.value) {
        return null;
      }

      return {
        ...payrollResult.value,
      };
    });

    const companyEarningsSummary = computed(() =>
      getCompanyEarningsSummary(payroll.value)
    );

    const employeeEarningsSummary = computed(() =>
      getEmployeeEarningsSummary(payroll.value)
    );

    const contractorEarningsSummary = computed(() =>
      getContractorEarningsSummary(payroll.value)
    );

    const payrollStatus = computed(
      () =>
        payroll.value?.status ||
        route.value.query?.status ||
        PayrollStatus.DRAFT
    );

    const formattedPayroll = computed(() => {
      return {
        companyTaxes: currency(payroll.value.totals?.companyTaxes).format(),
        employeeGross: currency(payroll.value.totals?.employeeGross).format(),
        employeeNet: currency(payroll.value.totals?.employeeNet).format(),
        employeeTaxes: currency(payroll.value.totals?.employeeTaxes).format(),
        liability: currency(payroll.value.totals?.liability).format(),
        periodEnd: formatDate(payroll.value.periodEnd),
        periodStart: formatDate(payroll.value.periodStart),
      };
    });

    const companyCostBreakdownData = computed(() => {
      return [
        {
          name: 'Total Earnings',
          value: formattedPayroll.value.employeeGross,
        },
        {
          name: 'Company Taxes',
          value: formattedPayroll.value.companyTaxes,
        },
        {
          name: 'Company Cost',
          value: formattedPayroll.value.liability,
        },
      ];
    });

    const staffPayBreakdownData = computed(() => {
      return [
        {
          name: 'Total Earnings',
          value: formattedPayroll.value.employeeGross,
        },
        {
          name: 'Employee Taxes',
          value: formattedPayroll.value.employeeTaxes,
        },
        {
          name: 'Total Net Pay',
          value: formattedPayroll.value.employeeNet,
        },
      ];
    });

    const payrollPreviewConfig = computed(() => {
      const thisPayroll = { ...payroll.value };

      const banner = {
        variant: 'explainer',
        message: 'Please review your payroll before submitting',
        richText: false,
      };
      const actions = [];
      if (payrollStatus.value === PayrollStatus.DRAFT) {
        actions.push(
          ActionButtonKeys.SUBMIT_PAYROLL,
          ActionButtonKeys.BACK_TO_EDIT,
          ActionButtonKeys.FINISH_LATER
        );
        delete thisPayroll.status; // Removes the header for the draft state (per the design)
      } else {
        // status === pending || processing
        actions.push(ActionButtonKeys.VIEW_SUMMARY, ActionButtonKeys.OVERVIEW);
      }

      return {
        payroll: {
          ...thisPayroll,
          totals: {
            ...thisPayroll.totals,
          },
        },
        actions,
        banner,
      };
    });

    function openCompanyCostDrawer() {
      showCompanyCostDrawer.value = true;
    }

    function closeCompanyCostDrawer() {
      showCompanyCostDrawer.value = false;
    }

    function openStaffPayDrawer() {
      showStaffPayDrawer.value = true;
    }

    function closeStaffPayDrawer() {
      showStaffPayDrawer.value = false;
    }

    function reloadCurrentPage() {
      window.location.reload();
    }

    function downloadPayrollReport() {
      fileDownloader(
        `${FILE_SERVER_URL}/document-secure/payroll-preview/${payrollId.value}`,
        `payroll-preview-${payrollId.value}`
      );
      addInfo('Downloading Payroll Report');
    }

    return {
      closeCompanyCostDrawer,
      closeStaffPayDrawer,
      companyCostBreakdownData,
      companyEarningsSummary,
      contractorEarningsSummary,
      downloadPayrollReport,
      employeeEarningsSummary,
      fatalErr,
      formattedPayroll,
      isLoading,
      openCompanyCostDrawer,
      openStaffPayDrawer,
      payroll,
      payrollId,
      payrollPreviewConfig,
      payrollResult,
      payrollStatus,
      reloadCurrentPage,
      showCompanyCostDrawer,
      showEditErrorLink,
      showStaffPayDrawer,
      staffPayBreakdownData,
    };
  },
};
</script>
