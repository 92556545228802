<template>
  <PayrollFlowLayout v-if="!isLoading && !!payroll" :payroll="payroll">
    <template #title>
      {{ titleText }} Checks For {{ formattedPayroll.periodStart }} -
      {{ formattedPayroll.periodEnd }}
    </template>

    <div class="grid grid-cols-12">
      <div
        class="col-span-12 lg:col-start-2 lg:col-span-10 xl:col-start-3 xl:col-span-8"
      >
        <TypeBody
          v-if="payroll.needsChecksEntered"
          variant="text-small"
          tag="div"
        >
          Below is a list of all of the workers that require a paper check.
          Please enter the check number below for record keeping purposes.
        </TypeBody>

        <TypeBody v-else variant="text-small" tag="div">
          If you void a check at any time, please enter the updated check number
          below for record keeping purposes. The payroll summary will reflect
          any updated check numbers.
        </TypeBody>

        <div class="mt-7">
          <TypeOverline
            variant="small-tight"
            tag="div"
            class="mb-4 text-gray-0"
          >
            WORKER LIST
          </TypeOverline>
          <ManualCheckTile
            v-for="item in manualPayrollItems"
            :id="item.id"
            :key="item.id"
            :avatar-url="item.staff.profile.avatarUrl"
            :full-name="item.staff.fullName"
            :first-name="item.staff.profile.firstName"
            :last-name="item.staff.profile.lastName"
            :position="item.staff.profile.title"
            :net-pay="item.netPay"
            :check-number="item.checkNumber"
            :max-length="checkMaxLength"
            :has-err="!checkNumIsValid(item.checkNumber) && shouldValidate"
            @update-check-number="updateCheckNumber"
          />
        </div>
        <BaseBanner
          v-if="checkErrList.length && shouldValidate"
          :show-close="false"
          variant="error"
          message="Missing check numbers"
          description="Please enter any missing check numbers, or press “Finish Later” to save your progress."
        />
        <div class="grid grid-cols-12 gap-0 mt-6 sm:grid-cols-8">
          <div
            class="col-span-10 col-start-2 xs:col-start-3 xs:col-span-8 sm:col-start-3 sm:col-span-4"
          >
            <BaseButton
              type="button"
              variant="secondary"
              size="giant"
              block
              @click="finishChecks"
            >
              {{ finishBtnText }}
            </BaseButton>
          </div>
          <div
            class="col-span-10 col-start-2 xs:col-start-3 xs:col-span-8 sm:col-start-3 sm:col-span-4"
          >
            <BaseButton
              v-if="payroll.needsChecksEntered"
              class="w-full mt-4"
              type="button"
              variant="secondary"
              size="giant"
              block
              flat
              @click="finishLater"
            >
              Finish Later
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <portal v-if="showFinishedModal" to="layout">
      <ModalDialog overlay="dark" size="medium">
        <template #header> Checks complete </template>
        <TypeBody variant="text-small" tag="div">
          Thank you for filling out the check numbers.
        </TypeBody>
        <div class="flex items-center justify-center mt-6">
          <!-- Dummy element to avoid immediate tabbing on Select Input when opening modal-->
          <span tabindex="0" />
          <BaseButton
            class="mr-2"
            type="button"
            variant="inverted"
            size="large"
            tag="nuxt-link"
            :to="`/payroll/${payrollId}/summary`"
          >
            View Summary
          </BaseButton>
          <BaseButton
            type="button"
            variant="primary"
            size="large"
            tag="nuxt-link"
            to="/payroll/overview"
          >
            Back to Payroll Overview
          </BaseButton>
        </div>
      </ModalDialog>
    </portal>
    <LoadingModal
      v-if="isSaving"
      header="Finishing manual checks"
      message="Please do not leave this page or close your browser, while we finish your manual checks. You will be redirected automatically once complete."
    />
  </PayrollFlowLayout>
</template>

<script>
import {
  BaseBanner,
  BaseButton,
  TypeBody,
  TypeOverline,
  ModalDialog,
} from '@bambeehr/pollen';
import {
  ref,
  computed,
  useRoute,
  useRouter,
  onDeactivated,
  watch,
} from '@nuxtjs/composition-api';
import cloneDeep from 'lodash/cloneDeep';
import { PaymentMethod } from '@/modules/payroll/constants/payroll';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal.vue';

import { formatDate } from '@/utils/date';
import PayrollFlowLayout from '@/modules/payroll/layouts/PayrollFlowLayout';
import ManualCheckTile from '@/modules/payroll/components/ManualCheckTile/ManualCheckTile.vue';
import { sortPayrollPayByFullName } from '@/modules/payroll/utils/sorting';
import useNotifications from '@bambeehr/use-notifications';
import { serializeList } from '@/modules/payroll/utils/payFactory';
import {
  useUpdatePayrollChecksMutation,
  useGetPayrollQuery,
  Payroll,
} from '@/gql/generated';

import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';

export default {
  components: {
    BaseBanner,
    BaseButton,
    ManualCheckTile,
    ModalDialog,
    LoadingModal,
    PayrollFlowLayout,
    TypeBody,
    TypeOverline,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { addSuccess } = useNotifications();

    const isSaving = ref(false);
    const myData = ref();
    const { mutate: updateChecks, onDone: onDoneUpdatingChecks } =
      useApolloMutation(useUpdatePayrollChecksMutation, {
        pending: isSaving,
        data: myData,
      });

    const payrollId = +route.value?.params.payroll_id;
    const checkMaxLength = 6;
    const isLoading = ref(true);

    const payroll = ref({});
    const showFinishedModal = ref(false);
    const shouldValidate = ref(false);

    const formattedPayroll = computed(() => {
      return {
        periodStart: formatDate(payroll.value.periodStart),
        periodEnd: formatDate(payroll.value.periodEnd),
      };
    });
    const finishBtnText = computed(() =>
      payroll.value.needsChecksEntered ? 'Finish Checks' : 'Update Checks'
    );
    const titleText = computed(() =>
      payroll.value.needsChecksEntered ? 'Manual' : 'Update'
    );

    const manualPayrollItems = computed(() => {
      const { contractorPayments, items } = payroll.value;
      const payrollStaff = serializeList([...items, ...contractorPayments]);

      return payrollStaff
        .filter(
          (i) => i.paymentMethod === PaymentMethod.MANUAL.value && !!i.netPay
        )
        .map((i) => ({
          ...i,
          staff: {
            ...i.staff,
            fullName:
              i.staff.profile.contractorBusinessName ||
              i.staff.profile.fullName,
          },
        }))
        .sort((i1, i2) => {
          return sortPayrollPayByFullName(i1, i2);
        });
    });

    const checkNumIsValid = (checkNumber) => {
      if (!checkNumber || !+checkNumber) {
        return false;
      }

      return checkNumber.length <= checkMaxLength;
    };

    const checkErrList = computed(() =>
      manualPayrollItems.value.filter((e) => !checkNumIsValid(e.checkNumber))
    );

    const formatChecks = (payItemList) =>
      payItemList.reduce((acc, payItem) => {
        const { checkNumber, id } = payItem;

        if (checkNumber) {
          acc.push({
            checkNumber,
            id,
          });
        }

        return acc;
      }, []);

    const formattedCheckNumbers = computed(() => ({
      contractors: formatChecks(
        manualPayrollItems.value.filter((i) => i.isContractor)
      ),
      employees: formatChecks(
        manualPayrollItems.value.filter((i) => !i.isContractor)
      ),
    }));

    const { onResult: onPayrollResult } = useApolloQuery(
      useGetPayrollQuery,
      {
        id: payrollId,
      },
      {
        pending: isLoading,
      }
    );

    onPayrollResult(({ getPayroll: res }) => {
      payroll.value = res;
    });

    function updateCheckNumber({ id, val }) {
      if (!Number.isNaN(+val)) {
        const thisPayroll = cloneDeep(payroll.value);
        const matchingPayrollItem = thisPayroll.items.find((i) => i.id === id);
        const matchingPayrollPayment = thisPayroll.contractorPayments.find(
          (i) => i.id === id
        );

        (matchingPayrollItem || matchingPayrollPayment).checkNumber =
          val.trim();
        payroll.value = thisPayroll;
      }
    }

    function saveChecks(hasFinishedChecks, callback) {
      if (isSaving.value) {
        return;
      }

      if (
        formattedCheckNumbers.value.contractors.length ||
        formattedCheckNumbers.value.employees.length
      ) {
        updateChecks({
          data: {
            checkNumbers: formattedCheckNumbers.value.employees,
            paymentCheckNumbers: formattedCheckNumbers.value.contractors,
            payrollId: payroll.value.id,
            needsChecksEntered: !hasFinishedChecks,
          },
        });

        onDoneUpdatingChecks(({ updatePayrollChecks: res }) => {
          callback();
        });

        return;
      }

      router.push('/payroll/overview');
    }

    function finishChecks() {
      shouldValidate.value = true;
      const message = payroll.value.needsChecksEntered
        ? 'Checks complete'
        : 'Check numbers updated';

      if (!checkErrList.value.length) {
        saveChecks(true, () => {
          showFinishedModal.value = !showFinishedModal.value;

          addSuccess(message);
        });
      }
    }

    function finishLater() {
      saveChecks(false, () => {
        addSuccess('Check numbers saved');
        router.push('/payroll/overview');
      });
    }

    onDeactivated(() => {
      showFinishedModal.value = false;
    });

    return {
      manualPayrollItems,
      checkErrList,
      checkMaxLength,
      checkNumIsValid,
      finishBtnText,
      finishChecks,
      finishLater,
      formattedCheckNumbers,
      formattedPayroll,
      isLoading,
      isSaving,
      shouldValidate,
      payroll,
      payrollId,
      saveChecks,
      showFinishedModal,
      titleText,
      updateCheckNumber,
    };
  },
};
</script>
