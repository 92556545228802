























import { TypeBody } from '@bambeehr/pollen';
import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'StaffCardContent',
  components: {
    TypeBody,
  },
  props: {
    description: {
      type: String as PropType<string>,
      required: false,
      default: 'N/A',
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
