import { getCompanySubscriptions } from '@/gql/services/payroll.service';
import { ProductKey, SubscriptionStatus } from '@/gql/generated';

let isBambeeStorage;
let isBambeePause;
let isBambeeLite;
let isBambeeBusiness;
let isBambeePlus;

let planInfo;

const getPlanInfo = async (stripeCustomerId: string) => {
  const info = (
    await getCompanySubscriptions({
      stripeCustomerId,
    })
  )?.data;

  isBambeePause = info?.some(
    (sub) =>
      sub.productCode === ProductKey.Pause &&
      (sub.status === SubscriptionStatus.Active ||
        sub.status === SubscriptionStatus.Trialing)
  );

  isBambeeStorage = info?.some(
    (sub) =>
      sub.productCode === ProductKey.Storage &&
      (sub.status === SubscriptionStatus.Active ||
        sub.status === SubscriptionStatus.Trialing)
  );

  isBambeeLite = info?.some(
    (sub) =>
      sub.productCode === ProductKey.Lite &&
      (sub.status === SubscriptionStatus.Active ||
        sub.status === SubscriptionStatus.Trialing)
  );

  isBambeeBusiness = info?.some(
    (sub) =>
      sub.productCode === ProductKey.Core &&
      (sub.status === SubscriptionStatus.Active ||
        sub.status === SubscriptionStatus.Trialing)
  );

  isBambeePlus = info?.some(
    (sub) =>
      sub.productCode === ProductKey.Plus &&
      (sub.status === SubscriptionStatus.Active ||
        sub.status === SubscriptionStatus.Trialing)
  );

  return info;
};

export default async (stripeId: string) => {
  if (!planInfo) {
    planInfo = await getPlanInfo(stripeId);
  }

  return {
    isBambeePause,
    isBambeeStorage,
    isBambeeLite,
    isBambeeBusiness,
    isBambeePlus,
  };
};
