var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex-col mb-12"},[_c('TextInput',{staticClass:"mb-10",attrs:{"id":"training-search","placeholder":"Search for a training course","pre-icon":"search"},on:{"input":_vm.updateSearchQuery},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_vm._v(" "),(_vm.isFetching)?_c('div',{staticClass:"flex items-center justify-center w-full py-5"},[_c('AnnularThrobber')],1):_c('div',[(
          _vm.availableAndPurchasedTrainingLists &&
          _vm.availableAndPurchasedTrainingLists.hasBothGroups
        )?_c('BaseDivider',{staticClass:"mb-6",attrs:{"size":"large","label":"Recommended"}}):_vm._e(),_vm._v(" "),(
          _vm.availableAndPurchasedTrainingLists &&
          _vm.availableAndPurchasedTrainingLists.recommended
        )?_c('div',{staticClass:"flex-col space-y-2 mb-10"},[_c('div',{staticClass:"space-y-2 mb-10"},_vm._l((_vm.availableAndPurchasedTrainingLists.recommended),function(item){return _c('TrainingListItem',_vm._b({key:item.id,attrs:{"is-disabled":_vm.shouldDisable(item)},on:{"training-selected":_vm.handleTrainingCourseSelected,"training-unselected":_vm.handleTrainingCourseUnselected}},'TrainingListItem',item,false))}),1)]):_vm._e(),_vm._v(" "),(
          _vm.availableAndPurchasedTrainingLists &&
          _vm.availableAndPurchasedTrainingLists.hasBothGroups
        )?_c('BaseDivider',{staticClass:"mb-6",attrs:{"size":"large","label":"All Training Courses"}}):_vm._e(),_vm._v(" "),(
          _vm.availableAndPurchasedTrainingLists &&
          _vm.availableAndPurchasedTrainingLists.all
        )?_c('div',{staticClass:"flex-col space-y-2"},_vm._l((_vm.availableAndPurchasedTrainingLists.all),function(item){return _c('TrainingListItem',_vm._b({key:item.id,attrs:{"is-disabled":_vm.shouldDisable(item)},on:{"training-selected":_vm.handleTrainingCourseSelected,"training-unselected":_vm.handleTrainingCourseUnselected}},'TrainingListItem',item,false))}),1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }