import { RetryLink } from '@apollo/client/link/retry';

const retryIf = (error) => {
  const doNotRetryCodes = [500, 400, 401, 429];

  return (
    !!error &&
    !doNotRetryCodes.includes(error.statusCode) &&
    error.graphQLErrors?.[0].extensions.code !== 'MAINTENANCE_MODE' &&
    error.graphQLErrors?.[0].extensions.code !== 'EXPECTED_SERVER_ERROR' &&
    error.graphQLErrors?.[0].extensions.code !== 'BAD_USER_INPUT'
  );
};

export default function (retryOptions) {
  const { delay, attempts } = retryOptions;

  return new RetryLink({
    delay: {
      jitter: true,
      ...delay,
    },
    attempts: {
      ...attempts,
      retryIf,
    },
  });
}
