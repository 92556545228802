export const ActivationStatus = Object.freeze({
  ACTIVATED: 'activated',
  CREATED: 'created',
  EMAIL: 'email-sent',
  PASSWORD_RESET: 'password-reset',
});

export const isActivated = (user) => {
  return user?._auth?.activation_status === ActivationStatus.ACTIVATED;
};

export default null;
