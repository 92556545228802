const ContentSlugs = Object.freeze({
  INTRO: 'intro',
  MEET_MANAGER: 'meet-your-manager',
  SCHEDULE_A_CALL: 'schedule-a-call',
  ABOUT_YOUR_COMPANY: 'about-your-company',
  COMPANY_AUDIT: 'company-audit',
  ADD_EMPLOYEES: 'add-employees',
  NO_STAFF: 'no-staff',
  CONFIRMATION: 'confirmation',
  CONFIRMATION_NO_STAFF: 'confirmation-no-staff',
});

export default ContentSlugs;
