








































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { AvatarImage, BaseIcon } from '@bambeehr/pollen';
import {
  useFloating,
  autoUpdate,
  flip,
  shift,
  offset,
  arrow,
  inline,
  Side,
} from '@floating-ui/vue';

export default defineComponent({
  name: 'ToolTip',
  components: {
    AvatarImage,
    BaseIcon,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    darkBg: {
      type: Boolean,
      default: false,
    },
    initallyOpen: {
      type: Boolean,
      default: false,
    },
    defaultPlacement: {
      type: String as PropType<Side>,
      default: 'bottom',
    },
    avatarUrl: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const reference = ref(null);
    const floating = ref(null);
    const arrowEl = ref(null);

    const { floatingStyles, middlewareData, placement } = useFloating(
      reference,
      floating,
      {
        whileElementsMounted: autoUpdate,
        placement: props.defaultPlacement,
        middleware: [
          offset(20),
          inline(),
          flip(),
          shift({ padding: 5 }),
          arrow({ element: arrowEl }),
        ],
      }
    );

    const arrowStyle = computed(() => {
      const arrow = middlewareData.value?.arrow;

      if (!arrow || !placement.value) {
        return {};
      }

      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[placement.value.split('-')[0]];

      return {
        left: arrow.x != null ? `${arrow.x}px` : '',
        top: arrow.y != null ? `${arrow.y}px` : '',
        right: '',
        bottom: '',
        [staticSide as string]: '-10px',
      };
    });

    const shouldShow = ref(props.initallyOpen ?? false);
    const showTooltip = () => {
      shouldShow.value = true;
    };

    const hideTooltip = () => {
      shouldShow.value = false;
    };

    return {
      showTooltip,
      hideTooltip,

      arrowStyle,
      shouldShow,
      arrowEl,
      reference,
      floating,
      floatingStyles,
    };
  },
});
