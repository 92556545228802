<template>
  <portal to="layout">
    <ModalDialog
      id="loader"
      :escape-button="false"
      action-alignment="center"
      overlay="dark"
      size="small"
    >
      <template v-if="!!$slots.header || !!header" #header>
        <slot name="header">
          {{ header }}
        </slot>
      </template>
      <slot name="loader">
        <AnnularThrobber class="mx-auto mb-4" />
      </slot>
      <TypeBody
        class="text-gray-2"
        tag="div"
        variant="text-x-small-tight"
        weight="regular"
      >
        <slot>{{ message }}</slot>
      </TypeBody>
    </ModalDialog>
  </portal>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { AnnularThrobber, ModalDialog, TypeBody } from '@bambeehr/pollen';

export default defineComponent({
  components: {
    AnnularThrobber,
    ModalDialog,
    TypeBody,
  },
  props: {
    header: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
  },
});
</script>
