




































import useAddStaffFormState from '../hooks/useAddStaffFormState';
import useAddStaffForm from '../hooks/useAddStaffForm';
import { defineComponent, watch, ref } from '@nuxtjs/composition-api';
import { CheckboxInput, ToolTip } from '@bambeehr/pollen';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';

export default defineComponent({
  components: {
    CheckboxInput,

    ToolTip,
  },
  setup() {
    const { hasBasicAccess, isReady } = usePlanAccess();
    const sendInviteEmail = ref(true);

    const { isEditing, showBeginOnboarding, inviteAsAdmin } = useAddStaffForm();
    const { formState } = useAddStaffFormState();

    sendInviteEmail.value = !!formState.beginOnboarding;

    watch(sendInviteEmail, (val) => {
      formState.beginOnboarding = val;
    });

    // If the customer is a lite customer, don't send the invite email
    watch(
      [isReady, hasBasicAccess],
      ([ready, hasAccess]) => {
        if (ready && !hasAccess) {
          sendInviteEmail.value = false;
        }
      },
      { immediate: true }
    );

    return {
      inviteAsAdmin,
      isEditing,
      showBeginOnboarding,
      sendInviteEmail,
      hasBasicAccess,
    };
  },
});
