








































































































































































































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import useProductSelector, { PurchaseOptions } from './useProductSelector';
import LoadingButtonLabel from '@/components/LoadingButtonLabel/LoadingButtonLabel';
import { BillingPeriod } from '@/gql/generated';
import {
  TypeDisplay,
  TypeBody,
  BlockSelector,
  BaseButton,
  BaseLink,
  TypeOverline,
  AnnularThrobber,
  ToolTip,
} from '@bambeehr/pollen';
import SquareTag from '@/components/SquareTag/SquareTag';
import bam from '@/lib/bam';

export default defineComponent({
  name: 'PlanCheckout',
  components: {
    TypeDisplay,
    SquareTag,
    BaseButton,
    TypeBody,
    BaseLink,
    LoadingButtonLabel,
    BlockSelector,
    TypeOverline,
    AnnularThrobber,
    ToolTip,
  },
  setup(_, { emit }) {
    const {
      currentCopy,
      currentProductOptions,
      currentTermModel,
      currentProductTermInfo,
      handleCTAClicked,
      isSubscribing,
      isLoading,
      isSubscription,
    } = useProductSelector();

    const finalPrice = computed(() => {
      const { id, formattedAnnualPrice, formattedPrice, price } =
        currentProductTermInfo.value || {};

      if (id === 'yearly') {
        return `${formattedAnnualPrice} + tax`;
      }

      if (formattedPrice) {
        return formattedPrice;
      }

      return price === 0 ? '' : '+ tax';
    });

    const handlePurchase = async () => {
      await handleCTAClicked(currentProductTermInfo.value);
      emit('next');
    };

    const showDetails = ref(false);

    const ctaCopy = computed(() => {
      const cta = `Buy ${currentCopy.value.title}`;

      return currentCopy.value.purchaseCta || cta;
    });

    const toggleDetails = (show: boolean) => {
      showDetails.value = show;
    };

    const handlePlanRadioUpdate = (option) => {
      currentTermModel.value = option.value;
      bam.trackEvent('plan_selected', {
        option: option?.id || option,
      });
      emit('plan-selected', option);
    };

    return {
      isLoading,
      showDetails,
      currentTermModel,
      currentProductTermInfo,
      toggleDetails,
      isSubscribing,
      handlePlanRadioUpdate,
      handleCTAClicked,
      currentCopy,
      currentProductOptions,
      isSubscription,
      ctaCopy,
      handlePurchase,
      finalPrice,
    };
  },
});
