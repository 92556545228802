<template>
  <div>
    <div v-if="isLoading" class="h-52 my-32 flex items-center justify-center">
      <AnnularThrobber class="mx-auto" />
    </div>
    <div v-else>
      <BaseBanner
        v-if="hasCancelled"
        class="mt-4"
        message="Come back anytime to Bambee Payroll"
        variant="explainer"
        :show-close="false"
      >
        <template #description>
          <TypeBody tag="div">
            You can still access your past payrolls and tax documents here if
            you ever need to download them again. If you'd like to consolidate
            your HR and payroll services again, contact {{ hrmName }} and set up
            a time to talk about the next steps.
          </TypeBody>
          <BaseButton tag="nuxt-link" :to="hrRequestPath" class="mt-2">
            Contact HR Manager
          </BaseButton>
        </template>
      </BaseBanner>
      <PayrollTileList
        class="mt-6"
        :payrolls="pastPayrolls"
        empty-msg="No past payrolls to display"
        @click="handlePayrollTileClick"
      />
    </div>
    <div
      v-if="totalCount && shouldShowPagination"
      class="w-full justify-center items-center mt-6"
    >
      <PaginationNavigation
        v-model="page"
        :max-pages="10"
        :page-size="limit"
        :total-items="totalCount"
        size="large"
        flat
      />
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  useStore,
  watch,
  useRouter,
  useRoute,
} from '@nuxtjs/composition-api';
import {
  AnnularThrobber,
  TypeBody,
  BaseBanner,
  BaseButton,
  PaginationNavigation,
} from '@bambeehr/pollen';
import { PayrollStatus } from '@/modules/payroll/constants/payroll';
import PayrollTileList from '@/modules/payroll/components/PayrollTile/PayrollTileList';
import {
  useListPayrollQuery,
  OrderByDirection,
  HrRequestGroupKey,
  HrRequestSlug,
} from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';
import { CompanyStatuses } from '@/modules/payroll/constants/company';
import { hrRequestFullSlug } from '@/modules/HrRequest/constants/RequestTypesData';
import { useAdvisorsStore } from '@/store/advisors';

const filterKey = 'payday';

export default {
  name: 'PayrollOverview',

  components: {
    PayrollTileList,
    AnnularThrobber,
    TypeBody,
    BaseBanner,
    BaseButton,
    PaginationNavigation,
  },

  props: {
    companyId: {
      type: String,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const payrolls = ref([]);
    const currentFilter = ref('');
    const hasCancelled = computed(
      () => props.company.status === CompanyStatuses.CANCELLED
    );
    const advisorStore = useAdvisorsStore();
    const hrmName = computed(() => advisorStore.hrManagerFirstName);
    const hrRequestPath = `/requests/${hrRequestFullSlug({
      groupKey: HrRequestGroupKey.PayBenefits,
      slug: HrRequestSlug.Payroll,
    })}`;

    const isLoading = ref(false);
    const page = ref(+route.value?.query?.page || 1);
    const totalCount = ref(0);
    const limit = 8;

    const fetchListPayroll = (pageNum = 1) => {
      const { onResult } = useApolloQuery(
        useListPayrollQuery,
        {
          companyId: props.companyId,
          page: pageNum,
          limit,
          orderBy: {
            key: 'approvalDeadline',
            direction: OrderByDirection.Desc,
          },
          advancedWhere: {
            AND: {
              status: {
                in: [PayrollStatus.PAID, PayrollStatus.PARTIALLY_PAID],
              },
            },
          },
        },
        {
          data: payrolls,
          pending: isLoading,
        },
        { placeholderPick: (res) => res?.listPayroll?.results || [] }
      );

      onResult(({ listPayroll: res }) => {
        totalCount.value = res.totalCount;
      });
    };

    const shouldShowPagination = computed(() => totalCount.value > limit);

    // Watch for page changes in the URL (mostly back/forth history)
    watch(route, (newRoute) => {
      const newPage = +newRoute?.query?.page;
      if (newPage && newPage !== page.value) {
        page.value = newPage;
      }
    });

    watch(
      page,
      (goToPage) => {
        // Update the URL for pagination history
        router.push({
          path: route.path,
          query: { page: goToPage },
        });
        fetchListPayroll(goToPage);
      },
      { immediate: true }
    );

    const pastPayrolls = computed(
      () =>
        payrolls.value
          .filter(
            (payroll) =>
              payroll.status === PayrollStatus.PAID ||
              payroll.status === PayrollStatus.PARTIALLY_PAID
          )
          .sort((a, b) => {
            return (
              new Date(b[filterKey]).getTime() -
              new Date(a[filterKey]).getTime()
            );
          }) || []
    );
    function handlePayrollTileClick(payroll) {
      this.$router.push(`/payroll/${payroll.id}/summary`);
    }

    return {
      currentFilter,
      handlePayrollTileClick,
      hasCancelled,
      hrmName,
      hrRequestPath,
      isLoading,
      pastPayrolls,
      page,
      totalCount,
      limit,
      shouldShowPagination,
    };
  },
};
</script>

<style scoped>
>>> .pagination-navigation__list {
  @apply justify-center;
}
</style>
