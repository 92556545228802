<template>
  <button
    :disabled="isDisabled"
    :class="['button', { 'is-loading': loading }]"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled() {
      return this.loading || this.disabled
    }
  }
}
</script>

<style lang="scss" scoped>
//@import '../../styles/app/shared.scss';
@import '~assets/css/shared.scss';

$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

//Bulma BUTTONS Variable
$button-color: $white;
$button-background-color: $purple;

$button-focus-color: $white;
$button-focus-border-color: transparent;
$button-focus-box-shadow-size: none;
$button-focus-box-shadow-color: rgba($link, 0.25);

$button-active-color: $link-active;
$button-active-border-color: $link-active-border;

$button-text-color: $text;
$button-text-hover-background-color: $background;
$button-text-hover-color: $text-strong;

$button-disabled-background-color: $white;
$button-disabled-border-color: $grey-lighter;
$button-disabled-shadow: none;
$button-disabled-opacity: 0.5;

$button-static-color: $grey;
$button-static-background-color: $white-grey-1;
$button-static-border-color: $grey-lighter;

//Bulma Dropdown Buttons
$dropdown-content-background-color: $white;
$dropdown-content-arrow: $link;
$dropdown-content-offset: 0.5rem;
$dropdown-content-radius: $radius;
$dropdown-content-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
  0 1px 3px 0 rgba(0, 0, 0, 0.1);
$dropdown-content-z: 20;

$dropdown-item-color: $grey-dark;
$dropdown-item-hover-color: $black;
$dropdown-item-hover-background-color: $background;
$dropdown-item-active-color: $link-invert;
$dropdown-item-active-background-color: $link;
$dropdown-divider-background-color: $border;

@mixin button-dimension($font-size) {
  font-size: $font-size;
  padding: 0 1rem;
  $ratio: 3.125;

  &.is-narrow {
    padding: 0 0.5rem;
  }

  &.is-wide {
    padding: 0 2.75em;
  }
  @if $font-size == $button-tiny {
    line-height: remy(24);
    padding: 0 0.8125rem;

    img {
      height: remy(12);
      width: remy(12);
    }
  } @else if $font-size == $button-small {
    line-height: remy(32);
    padding: 0 1.03125rem;

    img {
      height: remy(16);
      width: remy(16);
    }
  } @else if $font-size == $button-large {
    line-height: remy(48);

    img {
      height: remy(24);
      width: remy(24);
    }
  } @else {
    line-height: remy(40);

    img {
      height: remy(20);
      width: remy(20);
    }
  }
}
@mixin inverted-button() {
  &.is-danger,
  &.is-info,
  &.is-primary,
  &.is-warning,
  &.is-success {
    &:not([disabled]):hover {
      background-color: $white;
      filter: none;
    }
  }
}

@mixin clear-button() {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: $black;

  &.is-primary {
    color: $primary;
  }

  &.is-info {
    color: $blue;
  }

  &.is-danger {
    color: $danger;
  }

  &.is-warning {
    color: $warning;
  }

  &.is-success {
    color: $success;
  }

  &:not([disabled]):hover {
    background-color: transparent;
    filter: none;
  }
}

@mixin flat-hover($width, $color) {
  &.flat {
    box-shadow: none;
  }

  &.flat:hover {
    border: $width solid $color;
  }

  &.flat:active {
    border: $width solid $color;
  }

  &.flat:focus {
    border: $width solid $color;
  }
}

@mixin flat-hover-button($color) {
  &.flat {
    box-shadow: none;
  }

  &.flat:hover {
    background-color: $color;
    opacity: 0.85;
  }

  &.flat:focus {
    background-color: $color;
    opacity: 0.85;
  }
}

.button {
  border: 0;
  border-radius: 5px;
  display: inline-flex;
  font-size: $button-regular;
  font-weight: 600;
  height: auto;
  transition: all 200ms ease-in-out;
  @include button-dimension($button-regular);

  &.icon-is-right,
  &.icon-is-left {
    position: relative;

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.icon-is-right img {
    margin-right: 0.5em;
    right: 0;
  }

  &.icon-is-left img {
    left: 0;
    margin-left: 0.5em;
  }
}

.button.is-tiny {
  @include button-dimension($button-tiny);
}

.button.is-small {
  @include button-dimension($button-small);
}

.button.is-large {
  @include button-dimension($button-large);
}

.button {
  &:not([disabled]):hover {
    filter: brightness(110%) saturate(115%);
  }

  &.is-inverted {
    @include inverted-button();
  }

  &.is-clear {
    @include clear-button();
  }

  &.is-outlined:hover {
    filter: none;
  }

  &.is-dropdown {
    position: relative;

    .arrow {
      border-left: 0.35em solid transparent;
      border-right: 0.35em solid transparent;
      border-top: 0.35em solid $white;
      height: 0;
      margin-right: 0.5em;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
    }
  }

  &.rounded-full {
    border-radius: 100px !important;
  }

  &.block {
    display: block;
  }
}

.dropdown {
  &.is-active,
  &.is-hoverable:hover {
    .dropdown-menu {
      display: block;
      min-width: 100%;
    }
  }

  &.is-right {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
}
</style>
