import { render, staticRenderFns } from "./employee-welcome-layout.vue?vue&type=template&id=3a79f008&scoped=true&"
import script from "./employee-welcome-layout.vue?vue&type=script&lang=js&"
export * from "./employee-welcome-layout.vue?vue&type=script&lang=js&"
import style0 from "./employee-welcome-layout.vue?vue&type=style&index=0&id=3a79f008&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a79f008",
  null
  
)

export default component.exports