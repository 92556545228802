<template>
  <ErrorBoundary>
    <nuxt class="page-content notranslate" />
    <BaseNotifications />
    <portal-target name="preview-layout" />
  </ErrorBoundary>
</template>

<script>
import BaseNotifications from '@/components/BaseNotifications/BaseNotifications.vue';
import ErrorBoundary from '@/modules/payroll/components/ErrorBoundary/ErrorBoundary.vue';

export default {
  components: {
    BaseNotifications,
    ErrorBoundary,
  },
  head: {
    bodyAttrs: {
      class: 'print-body notranslate',
    },
  },
};
</script>
