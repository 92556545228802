<template>
  <div>
    <div
      v-if="state.loading || isFetching"
      class="flex justify-center items-center pt-5"
    >
      <AnnularThrobber />
    </div>

    <div v-else>
      <div v-if="notEnrolled.length">
        <BaseDivider label="Unenrolled" />
        <TrainingEnrollmentFormField
          v-for="user in notEnrolled"
          :key="user.id"
          :user-id="user.id"
          :selected="user.selected"
          :language="user.language"
          :disabled="state.saving"
          @input="toggleEnrollment($event)"
          @lang-input="updateLangPref"
        />
      </div>

      <div v-if="enrolled.length">
        <BaseDivider label="Enrolled" />
        <TrainingEnrollmentFormField
          v-for="user in enrolled"
          :key="user.id"
          :user-id="user.id"
          :selected="true"
          :language="user.language"
          :disabled="true"
          @input="toggleEnrollment($event)"
          @lang-input="updateLangPref($event)"
        />
      </div>

      <TypeBody v-if="state.filter && !users.length" class="text-base-800">
        No employees found with the name: {{ state.filter }}
      </TypeBody>
    </div>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { AnnularThrobber, BaseDivider, TypeBody } from '@bambeehr/pollen';
import useTrainingEnrollmentForm from '@/modules/Training/hooks/useTrainingEnrollmentForm/useTrainingEnrollmentForm';
import TrainingEnrollmentFormField from '@/modules/Training/components/TrainingEnrollmentForm/TrainingEnrollmentFormField';

export default {
  name: 'TrainingEnrollmentForm',
  components: {
    AnnularThrobber,
    BaseDivider,
    TrainingEnrollmentFormField,
    TypeBody,
  },
  setup() {
    const { state, select, deselect, updateLanguage, isFetching } =
      useTrainingEnrollmentForm();

    const users = computed(() => {
      const targetUsers = state?.allUsers || [];

      return targetUsers
        .sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
        .filter((u) => {
          if (!state.filter) {
            return true;
          }

          const fullName = `${u.firstName} ${u.lastName}`;

          return fullName.toLowerCase().includes(state.filter.toLowerCase());
        });
    });

    const enrolled = computed(() => {
      return users.value.filter((u) => u.enrolled);
    });

    const notEnrolled = computed(() => {
      return users.value.filter((u) => !u.enrolled);
    });

    const toggleEnrollment = (id) => {
      const u = users.value.find((x) => x.id === id);
      const isSelected = u?.selected;

      if (isSelected) {
        deselect([id]);
      } else {
        select([id]);
      }
    };

    const updateLangPref = (id, lang) => {
      updateLanguage([id], lang);
    };

    return {
      state,
      enrolled,
      notEnrolled,
      users,
      formState: state,
      toggleEnrollment,
      updateLangPref,
      isFetching,
    };
  },
};
</script>
