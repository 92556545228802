import phoneValidator from '@vtex/phone';
import '@vtex/phone/countries/USA';
import phoneFormatter from 'node-phone-formatter';

export const formats = {
  INTERNATIONAL: '+1 (NNN) NNN-NNNN',
  E164: '+1NNNNNNNNNN',
};
const phone = {
  validate(phoneNumber) {
    if (!phoneNumber) {
      return {
        value: null,
        isValid: false,
      };
    }

    const number = phoneFormatter.format(phoneNumber, formats.INTERNATIONAL);
    const tollFree = [
      '(800)',
      '(888)',
      '(877)',
      '(866)',
      '(855)',
      '(844)',
      '(833)',
    ];

    const isTollFree = tollFree.some((ac) => number.includes(ac));

    return {
      value: number,
      isValid: isTollFree || phoneValidator.validate(number, '1'),
    };
  },
  format(phoneNumber, format = formats.INTERNATIONAL) {
    return phoneFormatter.format(phoneNumber, format);
  },
};

export default phone;
