


























import { PropType, defineComponent, watch } from '@nuxtjs/composition-api';
import pick from 'lodash/pick';
import UsStates from '@/lib/globals/UsStates.json';
import { TextInput, CheckboxInput } from '@bambeehr/pollen';
import useAddressForm from '@/components/AddressForm/useAddressForm';

import { Workplace } from '@/modules/payroll/types/company';
import AddressForm from '@/components/AddressForm';

const addressLabels = {
  line1: 'Address',
};
export default defineComponent({
  name: 'WorkplaceModalForm',
  components: {
    AddressForm,
    TextInput,
    CheckboxInput,
  },
  props: {
    form: {
      type: Object as PropType<Workplace>,
      required: true,
    },
    isValidating: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disableAddress: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disableDefault: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { addressIsValid, workingForm: addressForm } = useAddressForm();

    Object.assign(
      addressForm,
      pick(props.form.address, ['line1', 'line2', 'city', 'state', 'zip'])
    );

    watch(
      addressIsValid,
      (isValid) => {
        emit('set-address-validation', isValid);
      },
      { immediate: true }
    );

    watch(
      addressForm,
      (address) => {
        emit('address-updated', address);
      },
      { immediate: true }
    );

    return {
      addressLabels,
      stateOptions: UsStates.map((s) => s.abbreviation),
      addressForm,
    };
  },
});
