<template>
  <div v-if="!isLoading">
    <div v-if="fatalErrors.length || importErrorMessage">
      <PageTitle>Add Hours and Earnings</PageTitle>

      <BaseBanner
        v-for="error in fatalErrors"
        :key="error.id"
        class="mb-2"
        :message="error.message"
        :description="error.description"
        variant="error"
        :show-close="false"
        @close="handleFatalErrorClose(error.id)"
      />

      <BaseBanner
        v-if="importErrorMessage"
        class="mb-4"
        :message="importErrorMessage"
        description="Try re-importing your earnings or entering them in manually. If this problem persists, contact your HR Manager."
        variant="error"
        :show-close="true"
        @close="handleImportErrorClose"
      />
    </div>
    <PayrollFlowLayout
      v-else
      :payroll-preview-config="payrollPreviewConfig"
      :company="company"
      @view-tour="toggleGuidedTour"
    >
      <template #title>
        <div class="w-28 xs:w-auto">
          <span class="hidden sm:inline"> Add Hours and Earnings For</span>
          {{ formatDate(payroll.periodStart) }} -
          {{ formatDate(payroll.periodEnd) }}
        </div>
      </template>
      <div>
        <div class="edit-wrapper sm:-mb-10">
          <div class="flex flex-col h-full">
            <div class="flex-grow-0">
              <BaseBanner
                v-if="withholdingsErr"
                message="Unable to save withholdings"
                description="Please try again, or coming back to it in a few minutes."
                variant="error"
                class="mb-6"
                @close="toggleWithholdingErr"
              />

              <BaseBanner
                v-if="hasWorkersWithDeletedRoles"
                message="Please confirm the correct pay rate for your workers"
                description="Some workers have pay rates that have been updated since this payroll was drafted. Please confirm the correct pay rate for your workers."
                variant="warning"
                class="mb-6"
                :show-close="false"
                @close="toggleWithholdingErr"
              />
              <div
                id="edit-import"
                class="flex flex-col md:flex-row justify-end sm:justify-start gap-2 flex-wrap mb-6"
              >
                <div>
                  <BaseButton
                    v-if="payrollPreviewConfig.isOffCycle"
                    level="2"
                    size="small"
                    pre-icon="transactionProcessingSolid"
                    @click="toggleWithholdingsModal"
                  >
                    Set Withholdings
                  </BaseButton>
                </div>
                <FlatfileTimeAttendanceImporter
                  class="hidden sm:block"
                  :payroll-id="payrollId"
                  @import-result="handleImportResult"
                />
                <BaseButton
                  v-if="isDeputyCustomer"
                  level="2"
                  size="small"
                  pre-icon="arrowCircle"
                  class="z-10 flex items-center mr-auto"
                  @click="toggleImportWarning"
                >
                  Import Hours
                </BaseButton>
              </div>
            </div>
            <div class="flex-1 sm:overflow-hidden -mx-4">
              <RunPayItemsTable
                id="run-pay-employee-list"
                class="h-full mb-6 sm:-mb-8"
                :payroll="payrollPayItems"
                :is-loading="isLoadingPayItems"
                @edit-pay="openEarningsDrawer"
              />
            </div>
          </div>
        </div>

        <PayrollItemDrawer
          v-if="isDrawerActive && selectedDrawerItem"
          :key="selectedDrawerItem.item && selectedDrawerItem.item.id"
          :active.sync="isDrawerActive"
          :is-loading="selectedDrawerItem.isLoading"
          :item="decoratedSelectedDrawerItem"
          :payroll-id="payrollId"
          :force-data-fetch="forceDataFetch"
          @updated="onItemUpdate"
        />
      </div>
      <OffCycleWizard
        v-if="showEditWithholdings"
        :selected-options="withholdingSelections"
        save-btn-label="Save Withholdings"
        @close="toggleWithholdingsModal"
        @expert-withholding-click="updatePayrollWithholdings"
      />
      <LoadingModal
        v-if="showImportLoading"
        header="Importing Hours to Your Payroll"
        message="This may take a moment"
      />
      <portal v-if="showImportWarning" to="layout">
        <ModalDialog
          :full-bleed="true"
          overlay="dark"
          @close="toggleImportWarning"
        >
          <div class="p-6 border-b border-gray-5 text-left">
            <TypeBody
              variant="link-large-tight"
              weight="strong"
              class="text-black"
            >
              Before You Import
            </TypeBody>
          </div>
          <div class="p-6 text-left">
            <TypeBody
              variant="text-x-small-tight"
              class="text-gray-0"
              tabindex="1"
            >
              When you import timesheets, the following earning types will be
              replaced by the values from your approved timesheets:
            </TypeBody>
            <ul class="pl-4 list-disc mt-3">
              <TypeBody
                variant="text-x-small-tight"
                class="text-gray-0"
                tag="li"
              >
                Regular Hours
              </TypeBody>
              <TypeBody
                variant="text-x-small-tight"
                class="text-gray-0"
                tag="li"
              >
                Overtime Hours
              </TypeBody>
              <TypeBody
                variant="text-x-small-tight"
                class="text-gray-0"
                tag="li"
              >
                Holiday Hours
              </TypeBody>
              <TypeBody
                variant="text-x-small-tight"
                class="text-gray-0"
                tag="li"
              >
                Sick Hours
              </TypeBody>
              <TypeBody
                variant="text-x-small-tight"
                class="text-gray-0"
                tag="li"
              >
                PTO Hours
              </TypeBody>
            </ul>
            <BlockSelector
              v-model="hasAgreedToImport"
              class="mt-5"
              label="I understand that imported hours I've overridden will be replaced by the hours in my timesheets"
            />
          </div>
          <div
            class="p-6 border-t border-base-400 flex justify-end items-center space-x-2"
          >
            <BaseButton
              flat
              variant="secondary"
              size="large"
              @click="toggleImportWarning"
            >
              Cancel
            </BaseButton>
            <BaseButton
              :disabled="!hasAgreedToImport"
              size="large"
              @click="handleAgreeClick"
            >
              Import
            </BaseButton>
          </div>
        </ModalDialog>
      </portal>
      <TimeImportModal
        v-if="showImportModal"
        :payroll-id="payrollId"
        @close="toggleImportModal"
        @imported="handleImporComplete"
        @error="handleImportError"
      />
    </PayrollFlowLayout>
    <FeatureTour
      v-if="$screen.lg && showTour"
      :tour-id="tourId"
      :floating-steps="floatingSteps"
      :guided-steps="guideSteps"
    />
  </div>
  <div v-else class="flex flex-col items-center mx-auto m-8">
    <AnnularThrobber />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import shortid from 'shortid';
import {
  ref,
  computed,
  useRoute,
  useStore,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import {
  BaseBanner,
  BaseButton,
  TypeBody,
  ModalDialog,
  AnnularThrobber,
  BlockSelector,
} from '@bambeehr/pollen';
import TimeImportModal from '@/modules/payroll/components/TimeImportModal';
import OffCycleWizard from '@/modules/payroll/components/OffCycleWizard/OffCycleWizard.vue';
import { sortPayrollPayByFullName } from '@/modules/payroll/utils/sorting';
import PayrollItemDrawer from '@/modules/payroll/components/EarningsDrawer/PayrollItemDrawer';
import useNotifications from '@bambeehr/use-notifications';
import PageTitle from '@/modules/payroll/components/PageTitle/PageTitle';
import PayrollFlowLayout, {
  ActionButtonKeys,
} from '@/modules/payroll/layouts/PayrollFlowLayout';
import { formatDate } from '@/utils/date';
import { payrollRedirectEditing } from '@/modules/payroll/middleware/payrollRedirect';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal';
import RunPayItemsTable from '@/modules/payroll/components/RunPayItemsTable';
import {
  EmploymentTypes,
  PayTypes,
  PaymentMethod,
  Withholdings,
  PayrollTypes,
  ContractorTypes,
  UserPreference,
} from '@/modules/payroll/constants/payroll';
import FlatfileTimeAttendanceImporter from '@/modules/payroll/components/FlatfileTimeAttendanceImporter';
import {
  useGetCompanyQuery,
  useGetPayrollInfoQuery,
  useUpdatePayrollMutation,
  useImportHoursMutation,
  useGetPayrollItemQuery,
  useGetContractorPaymentQuery,
  useGetPayrollPayItemsQuery,
} from '@/gql/generated';
import CachePolicy from '@/gql/CachePolicy';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import { getUserPreference, setUserPreference } from '@/utils/userPreference';
import { isProd } from '@/utils/env-helper';
import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import FeatureTour from '@/components/FeatureTour';
import useTour from '@/hooks/useTour';

const OnboardingStep = Object.freeze({
  WITHHOLDINGS: 'WITHHOLDINGS',
  SSN: 'SSN',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
});

const { addSuccess, addError, addInfo } = useNotifications();

const getDeletedRoleStatus = (item, payrollId) =>
  item.hasDeletedWorkerRole &&
  !getUserPreference(`made-selection:${payrollId}-${item.id}`);

let handleSkipClicked;
const floatingSteps = [
  {
    title: 'Get a start on running payroll',
    description: `Learn the basics of how to run payroll in Bambee Payroll™. It'll only take a minute.`,
    imgSrc: '/assets/images/tours/payroll/payroll_start.jpg',
    nextBtn: {
      label: `Let's Go`,
    },
    prevBtn: {
      label: 'Skip',
      level: '3',
      class: 'opacity-50',
      preventDefault: true,
      onClick: () => {
        handleSkipClicked?.();
      },
    },
  },
];

const getGuidedSteps = (goNext) => [
  {
    title: 'View Your Active Employees',
    description:
      'All active employees and contractors are be displayed here. Quickly verify earning totals and identify issues to maintain accuracy and compliance.',
    id: 'run-pay-employee-list',
  },
  {
    title: `Edit an Employee's Pay Details`,
    description: `Edit an employee's pay details such as earning types, amounts, and payment method for the current pay period.`,
    id: 'edit-pay-btn',
    onNextClick: () => {
      const firstBtn = document.getElementById('edit-pay-btn');

      if (firstBtn) {
        firstBtn.click();
      }

      goNext();
    },
  },
  {
    title: `Important: Confirm the worker's information`,
    description: `Before adding or editing earnings always confirm the correct name, title and pay rate for your selected worker.`,
    isGlobal: true,
  },
  {
    title: 'Add and Edit Earnings for this Payroll',
    description: `Add or modify earning types and amounts here to ensure each employee's pay reflects the work for this pay period.`,
    id: 'earnings-container',
  },
  {
    title: 'Change the payment method',
    description:
      'Switch the payment method between direct deposit (if set up) and manual check.',
    id: 'earnings-pay-method',
  },
  {
    title: 'Add notes for the record',
    description: `Document specifics about payroll adjustments, one-time bonuses, or any irregularities for a particular pay cycle. Keeping a record ensures clarity and is essential for audit trails and compliance. View your notes in Past Payrolls.`,
    id: 'earnings-note',
    onNextClick: () => {
      const overlayEl = document.getElementsByClassName(
        'drawer-panel__overlay'
      );

      if (overlayEl?.[0]) {
        overlayEl[0].click();
      }

      goNext();
    },
  },
  {
    title: `Expedite your payroll by importing your data`,
    description: `Stay organized with your scheduled payrolls. Keep an eye on status, deadlines, earnings, pay periods, and pay dates.`,
    id: 'edit-import',
  },
  {
    title: `Get a high level view of this pay period`,
    description: `View this payroll's gross pay, deadline, and pay date.`,
    id: 'edit-totals',
  },
  {
    title: `That's it - you're all set!`,
    description: `You're ready to start running payroll. If you need more guidance, click "Need Help?".`,
    isGlobalStep: true,
    imgSrc: '/assets/images/tours/payroll/payroll_final.jpg',
  },
];

export default {
  components: {
    BaseBanner,
    AnnularThrobber,
    BaseButton,
    BlockSelector,
    FlatfileTimeAttendanceImporter,
    LoadingModal,
    RunPayItemsTable,
    ModalDialog,
    OffCycleWizard,
    PageTitle,
    PayrollFlowLayout,
    PayrollItemDrawer,
    FeatureTour,
    TypeBody,
    TimeImportModal,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const { companyId } = store.getters;

    const payrollId = route.value?.params.payroll_id;
    const { allStaff } = useStaffManagement();

    const payroll = ref();
    const isDrawerActive = ref(false);
    const selectedDrawerItem = ref(null);
    const fatalErrors = ref([]);
    const withholdingsErr = ref(false);
    const showEditWithholdings = ref(false);
    // Used when profile information has been updated so we can make sure to pull fresh
    // This is a clearner method than using a listQuery refetch as it will only refetch the payroll info if they need it
    const forceDataFetch =
      sessionStorage.getItem(UserPreference.UPDATED_WORKER_INFO) === 'true';

    if (forceDataFetch) {
      sessionStorage.removeItem(UserPreference.UPDATED_WORKER_INFO);
    }

    const payrollPayItems = ref();
    const isLoadingPayItems = ref(false);

    const getPayrollPayItems = (force = false) => {
      useApolloQuery(
        useGetPayrollPayItemsQuery,
        {
          id: payrollId,
          companyId,
        },
        {
          data: payrollPayItems,
          pending: isLoadingPayItems,
        },
        { placeholderPick: ({ getCoreCompany: res }) => res.getPayroll },
        {
          fetchPolicy: force
            ? CachePolicy.NETWORK_ONLY
            : CachePolicy.CACHE_FIRST,
        }
      );
    };

    getPayrollPayItems();

    const mapContractorPayment = (item) => {
      if (!item) {
        return null;
      }

      const hasDeletedWorkerRole = getDeletedRoleStatus(item, payrollId);

      const {
        contractor: {
          profile: {
            firstName,
            lastName,
            title,
            contractorBusinessName,
            avatarUrl,
            contractorType,
          },
          payRate,
          id,
        },
        payType,
      } = item;
      const isBusinessContractor = contractorType === ContractorTypes.BUSINESS;

      return {
        id: item.id,
        isContractor: true,
        item: {
          ...item,
          pay: item.entries,
          hasDeletedWorkerRole,
        },
        pay: item.entries,
        isBusinessContractor,
        isMissingSocial: item?.contractor?.remainingOnboardingSteps?.includes(
          OnboardingStep.SSN
        ),
        staff: {
          id,
          avatarUrl,
          employmentType: EmploymentTypes.CONTRACTOR,
          firstName,
          payType,
          fullName: isBusinessContractor
            ? contractorBusinessName
            : `${firstName} ${lastName}`,
          lastName,
          payRate,
          role: title,
          title,
        },
      };
    };

    const mapPayrollItem = (item) => {
      if (!item) {
        return null;
      }

      const hasDeletedWorkerRole = getDeletedRoleStatus(item, payrollId);

      const { payRate, payType, id } = item.employee;

      const { firstName, lastName, avatarUrl, employeeType, title } =
        item.employee.profile;

      const fullName = `${firstName} ${lastName}`;

      let employmentType;
      switch (employeeType) {
        case 'FULL_TIME':
          employmentType = EmploymentTypes.FULL_TIME;
          break;
        case 'PART_TIME':
          employmentType = EmploymentTypes.PART_TIME;
          break;
        default:
          employmentType = ''; // going to be blank until API V2 update of employeeType being available
      }

      return {
        id: item.id,
        isContractor: false,
        pay: item.earnings,
        item: {
          ...item,
          hasDeletedWorkerRole,
          pay: item.earnings,
        },
        reimbursements: item.reimbursements,
        isMissingSocial: item?.employee?.remainingOnboardingSteps?.includes(
          OnboardingStep.SSN
        ),
        staff: {
          id,
          avatarUrl,
          employmentType,
          role: title,
          payType,
          payRate,
          fullName,
          firstName,
          lastName,
        },
      };
    };

    const payrollItems = computed(
      () => payrollPayItems.value?.items.map(mapPayrollItem) || []
    );
    const contractorPayments = computed(
      () =>
        payrollPayItems.value?.contractorPayments.map(mapContractorPayment) ||
        []
    );

    watch(payroll, (newPayroll) => {
      if (!newPayroll || payrollRedirectEditing(newPayroll, router)) {
        return;
      }

      if (payroll.value?.deletedAt || newPayroll.deletedAt) {
        router.push(
          `/payroll?deletedDraftRedirect=true&deletedId=${payrollId}`
        );
      }
    });

    const staffItems = computed(() => [
      // Bol filters to prevent issue from BAMBO-3870 causing FE errors
      ...payrollItems.value.filter(Boolean),
      ...contractorPayments.value.filter(Boolean),
    ]);

    const { result: company, loading: isLoadingCompany } = useApolloQuery(
      useGetCompanyQuery,
      {
        id: companyId,
      },
      undefined,
      undefined,
      {
        fetchPolicy: forceDataFetch
          ? CachePolicy.NETWORK_ONLY
          : CachePolicy.CACHE_FIRST,
      }
    );

    const isLoadingPayroll = ref(true);
    const isLoadingHourly = ref(false);
    const isLoadingSalaried = ref(false);
    const isLoadingPayments = ref(false);
    const hourlyItemsPage = ref(1);
    const hourlyItemsTotalCount = ref(0);
    const salaryItemsPage = ref(1);
    const salaryItemsTotalCount = ref(0);
    const paymentsPage = ref(1);
    const paymentsTotalCount = ref(0);
    const limit = 40;
    const selectedUserId = ref(null);
    const hasImportedItems = ref(false);

    const getPayrollInfo = (forceDataFetch = false, callback) => {
      const { onResult, onError: onGetPayrollErr } = useApolloQuery(
        useGetPayrollInfoQuery,
        {
          id: payrollId,
        },
        { pending: callback ? null : isLoadingPayroll },
        undefined,
        { fetchPolicy: forceDataFetch }
      );

      const unwatchResult = onResult(({ getPayroll: res }) => {
        isLoadingPayroll.value = false;

        payroll.value = {
          ...res,
          itemsPage: payroll.value?.itemsPage,
          contractorPaymentsPage: payroll.value?.contractorPaymentsPage,
        };

        callback?.();
        unwatchResult?.();
      });

      const unwatchErrors = onGetPayrollErr(() => {
        fatalErrors.value.push({
          message: 'Unable to load payroll',
          description: `Please try reloading this page, or coming back to it in a few minutes. `,
          id: shortid.generate(),
        });
        unwatchErrors?.();
      });
    };

    const getPayroll = (forceDataFetch = false, callback) => {
      getPayrollInfo(forceDataFetch, callback);
    };

    const handleSearchSelection = (userId) => {
      selectedUserId.value = userId;
    };

    const shouldShowHourlyItemsPagination = computed(
      () => hourlyItemsTotalCount.value > limit
    );
    const shouldShowSalaryItemsPagination = computed(
      () => salaryItemsTotalCount.value > limit
    );
    const shouldShowPaymentsPagination = computed(
      () => paymentsTotalCount.value > limit
    );
    const shouldShowSearchInput = computed(
      () =>
        selectedUserId ||
        shouldShowHourlyItemsPagination ||
        shouldShowSalaryItemsPagination ||
        shouldShowPaymentsPagination
    );

    getPayroll();

    function getPaymentOptions({ item }) {
      if (!item) {
        return {};
      }
      const entity = item.employee || item.contractor;

      const options = cloneDeep([
        PaymentMethod.MANUAL,
        PaymentMethod.DIRECT_DEPOSIT,
      ]);
      const needsPaymentMethod = entity.remainingOnboardingSteps.includes(
        OnboardingStep.PAYMENT_METHOD
      );
      const hasDirectDepositPreference =
        entity.paymentMethodPreference === PaymentMethod.DIRECT_DEPOSIT.value;

      if (needsPaymentMethod || !hasDirectDepositPreference) {
        options.find(
          (o) => o.value === PaymentMethod.DIRECT_DEPOSIT.value
        ).disabled = true;
      }

      return {
        options,
        needsPaymentMethod,
        requestManualCheck: !needsPaymentMethod && !hasDirectDepositPreference,
      };
    }

    const totalGrossPay = computed(() =>
      staffItems.value
        .map((i) => i.item)
        .reduce((acc, cur) => cur.grossPay + acc, 0)
    );

    const hasNonZeroDirectDeposit = computed(
      () =>
        [...staffItems.value, ...contractorPayments.value]
          .filter((item) => !!item.grossPay)
          .some(
            (item) =>
              item.item.paymentMethod === PaymentMethod.DIRECT_DEPOSIT.value
          ) || false
    );

    const payrollPreviewConfig = computed(() => {
      if (!payroll.value) {
        return {};
      }
      const actions = [];

      actions.push(
        ActionButtonKeys.PREVIEW_PAYROLL,
        ActionButtonKeys.FINISH_LATER
      );

      // To do: Update how we're tracking payroll flow layout summary tile state
      // Track in: https://bambee.atlassian.net/browse/PAY-983
      return {
        payroll: {
          ...payroll.value,
          items: payrollPayItems.value ? payrollPayItems.value.items : [],
          contractorPayments: payrollPayItems.value
            ? payrollPayItems.value.contractorPayments
            : [],
          status: null, // removes status header as per design spec
          totals: {
            ...payroll.value.totals,
            employeeGross: totalGrossPay.value,
            cashRequirement: null, // dont show debited totals from preview
            companyTaxes: null, // dont show tax info if already previewed
            employeeTaxes: null, // dont show tax info if already previewed
          },
        },
        actions,
        isOffCycle: payroll.value.type === PayrollTypes.OFF_CYCLE,
        hasNonZeroDirectDeposit: hasNonZeroDirectDeposit.value,
      };
    });

    const decoratedSelectedDrawerItem = computed(() => {
      const item = selectedDrawerItem.value;

      return item && !item.isLoading
        ? {
            ...item,
            payment: getPaymentOptions(item),
          }
        : {};
    });

    function decorateStaffInfo(staff) {
      const payItems = staff.reimbursements
        ? [...staff.pay, ...staff.reimbursements]
        : staff.pay;

      return {
        ...staff,
        grossPay: payItems.reduce((acc, cur) => acc + cur.amount, 0),
      };
    }

    const hourlyItems = computed(() =>
      payrollItems.value
        .filter((i) => i.staff.payType === PayTypes.HOURLY)
        .sort(sortPayrollPayByFullName)
        .map(decorateStaffInfo)
    );

    const salaryItems = computed(() =>
      payrollItems.value
        .filter((i) => i.staff.payType === PayTypes.SALARY)
        .sort(sortPayrollPayByFullName)
        .map(decorateStaffInfo)
    );

    const contractorItems = computed(() =>
      contractorPayments.value
        .sort(sortPayrollPayByFullName)
        .map(decorateStaffInfo)
    );

    const hasWorkersWithDeletedRoles = computed(
      () =>
        payrollItems.value.some(
          (i) =>
            i?.item.hasDeletedWorkerRole &&
            !getUserPreference(`made-selection:${payrollId}-${i.item.id}`)
        ) ||
        contractorPayments.value.some(
          (i) =>
            i?.item.hasDeletedWorkerRole &&
            !getUserPreference(`made-selection:${payrollId}-${i.item.id}`)
        )
    );

    const payrollHasEarnings = computed(() => {
      return payrollItems.value.find((i) => i.pay.find((e) => !!e.amount));
    });

    const draftErrors = computed(() => {
      return payrollHasEarnings.value
        ? []
        : [
            {
              message: `Enter at least one worker's earnings before continuing`,
            },
          ];
    });

    const withholdingSelections = computed(() => {
      const {
        applyBenefits,
        applyPostTaxDeductions,
        forceSupplementalWithholding,
      } = payroll.value.offCycleOptions;
      const withholdings = [];

      if (applyBenefits) {
        withholdings.push(Withholdings.APPLY_BENEFITS);
      }

      if (applyPostTaxDeductions) {
        withholdings.push(Withholdings.APPLY_POST_TAX_DEDUCTIONS);
      }

      if (forceSupplementalWithholding) {
        withholdings.push(Withholdings.FORCE_SUPPLEMENTAL_WITHHOLDING);
      }

      return withholdings;
    });

    function handleFatalErrorClose(id) {
      fatalErrors.value = fatalErrors.value.filter((e) => e.id !== id);
    }

    function openEarningsDrawer(listItem) {
      const { isContractor, id } = listItem;

      selectedDrawerItem.value = {
        ...listItem,
        isLoading: true,
      };

      const query = isContractor
        ? useGetContractorPaymentQuery
        : useGetPayrollItemQuery;

      useApolloQuery(
        query,
        {
          id,
          companyId,
          payrollId,
        },
        { data: selectedDrawerItem },
        {
          placeholderPick: (res) => {
            const payrollResult =
              res?.getCoreCompany?.getPayrollWithCompanyRoles;

            return isContractor
              ? mapContractorPayment(payrollResult.contractorPayment)
              : mapPayrollItem(payrollResult.payrollItem);
          },
        },
        {
          // If we've imported payroll payments, we can't trust the cache to be up to date
          // This will force a network request to get the latest data when the user is using out import feature
          fetchPolicy: hasImportedItems.value
            ? CachePolicy.NETWORK_ONLY
            : CachePolicy.CACHE_FIRST,
        }
      );

      isDrawerActive.value = true;
    }

    function toggleWithholdingsModal() {
      showEditWithholdings.value = !showEditWithholdings.value;
    }

    function toggleWithholdingErr() {
      withholdingsErr.value = !withholdingsErr.value;
    }

    const {
      mutate: updatePayrollMutation,
      onDone: doneUpdatingPayroll,
      onError: errorUpdatingPayroll,
    } = useApolloMutation(useUpdatePayrollMutation);

    function updatePayrollWithholdings(offCycleOptions) {
      toggleWithholdingsModal();

      updatePayrollMutation({
        data: {
          id: payrollId,
          offCycleOptions,
          payday: payroll.value.payday,
          periodEnd: payroll.value.periodEnd,
          periodStart: payroll.value.periodStart,
          version: payroll.value.version,
        },
      });

      doneUpdatingPayroll(({ updatePayroll: res }) => {
        if (res) {
          payroll.value = res;
          addSuccess('Withholdings saved');
        }
      });

      errorUpdatingPayroll(() => {
        toggleWithholdingErr();
      });
    }

    const isLoading = computed(
      () => isLoadingPayroll?.value || isLoadingCompany?.value
    );

    const importErrorMessage = ref('');
    const handleImportResult = (
      updatedPayroll,
      totalImported,
      erroredUsernames
    ) => {
      const numSuccessful = totalImported - erroredUsernames.length;

      if (numSuccessful === 0) {
        // import totally failed
        importErrorMessage.value = 'Failed to import earnings from file upload';
      } else if (erroredUsernames.length) {
        // some imports worked
        importErrorMessage.value = `Failed to import earnings for
          ${erroredUsernames.join(', ')}
        `;
        addSuccess(
          `${numSuccessful} of ${totalImported} workers' earnings imported.`
        );
      } else {
        // all imports worked
        addSuccess('Your earnings have been saved.');
      }
      if (updatedPayroll) {
        getPayrollPayItems(true);
        getPayrollInfo(false, () => {});
      }
      hasImportedItems.value = true;
    };

    const handleImportErrorClose = () => {
      importErrorMessage.value = '';
    };

    const showImportWarning = ref(false);
    const showImportLoading = ref(false);
    const hasAgreedToImport = ref(false);
    const toggleImportWarning = () => {
      showImportWarning.value = !showImportWarning.value;
    };
    const showImportModal = ref(false);

    const toggleImportModal = () => {
      showImportModal.value = !showImportModal.value;
    };
    const {
      mutate: importHours,
      onDone: doneImporting,
      onError: errorImporting,
    } = useApolloMutation(useImportHoursMutation);
    doneImporting(({ syncTimesheets: res }) => {
      // Persist loading state
      if (res.synced) {
        addSuccess('Import complete.');
      } else if (!res.earnings && !res.paymentEntries) {
        addInfo('No approved timesheets to import.');
      }

      getPayrollPayItems(true);
      getPayrollInfo(false, () => {
        showImportLoading.value = false;
      });
      hasImportedItems.value = true;
    });
    errorImporting((err) => {
      if (err.hasErrors) {
        addError('Import failed. Please try again.');
      }
      showImportLoading.value = false;
    });

    const handleAgreeClick = () => {
      hasAgreedToImport.value = false;
      toggleImportWarning();
      toggleImportModal();
    };

    const handleImporComplete = (count) => {
      hasImportedItems.value = true;
      getPayrollPayItems(true);
      toggleImportModal();
      addSuccess(
        `Hours have been imported for ${count} ${
          count > 1 ? 'workers' : 'worker'
        }.`
      );
    };

    const handleImportError = (errorMsg) => {
      showImportLoading.value = false;
      addError(errorMsg || 'Import failed. Please try again.');
    };

    const moreMenuItems = computed(() => [
      {
        label: 'Import hours from Deputy',
        icon: 'arrowCircleSolid',
        handler: () => {
          toggleImportWarning();
        },
      },
    ]);

    const isDeputyCustomer = computed(
      () => company.value?.getCompany?.deputyConnection?.hasAccessToken
    );

    function onItemUpdate() {
      // Refresh from cache
      getPayrollPayItems();
      getPayrollInfo(false, () => {});

      addSuccess('Earnings have been saved.');
    }

    const hasViewedKey = `${companyId}:has-viewed-or-skipped-payroll-edit-tour`;
    const shouldShowKey = `${companyId}:show-edit-tour`;
    const showTour = ref(false);
    const toggleGuidedTour = (shouldShow) => {
      showTour.value = shouldShow ?? !showTour.value;
    };

    if (getUserPreference(shouldShowKey) && !getUserPreference(hasViewedKey)) {
      toggleGuidedTour(true);
    } else {
      toggleGuidedTour(false);
    }

    const tourId = 'edit-payroll-tour';
    const { onCompleted, onDismissed, tourInst, goToNextStep } =
      useTour(tourId);

    onCompleted.value = () => {
      setUserPreference(hasViewedKey, true);
      toggleGuidedTour(false);
    };

    onDismissed.value = () => {
      setUserPreference(hasViewedKey, true);
      toggleGuidedTour(false);
    };

    handleSkipClicked = () => {
      toggleGuidedTour(false);
      setUserPreference(hasViewedKey, true);
    };

    const guideSteps = getGuidedSteps(goToNextStep);

    return {
      handleImporComplete,
      company,
      contractorItems,
      draftErrors,
      fatalErrors,
      formatDate,
      getPaymentOptions,
      handleFatalErrorClose,
      handleImportResult,
      handleImportErrorClose,
      hourlyItems,
      importErrorMessage,
      isDrawerActive,
      isLoading,
      isLoadingCompany,
      isLoadingPayroll,
      onItemUpdate,
      openEarningsDrawer,
      payroll,
      payrollHasEarnings,
      payrollId,
      payrollItems,
      payrollPreviewConfig,
      salaryItems,
      decoratedSelectedDrawerItem,
      selectedDrawerItem,
      showEditWithholdings,
      toggleWithholdingErr,
      toggleWithholdingsModal,
      totalGrossPay,
      updatePayrollWithholdings,
      withholdingSelections,
      withholdingsErr,
      hasWorkersWithDeletedRoles,
      forceDataFetch,
      hourlyItemsPage,
      hourlyItemsTotalCount,
      salaryItemsPage,
      salaryItemsTotalCount,
      limit,
      shouldShowHourlyItemsPagination,
      shouldShowSalaryItemsPagination,
      paymentsPage,
      paymentsTotalCount,
      shouldShowPaymentsPagination,
      handleSearchSelection,
      allStaff,
      shouldShowSearchInput,
      selectedUserId,
      isLoadingHourly,
      isLoadingSalaried,
      isLoadingPayments,
      moreMenuItems,
      showImportLoading,
      showImportWarning,
      toggleImportWarning,
      hasAgreedToImport,
      handleAgreeClick,
      isDeputyCustomer,
      showImportModal,
      toggleImportModal,
      isProd,
      payrollPayItems,
      isLoadingPayItems,
      handleImportError,
      showTour,
      toggleGuidedTour,
      tourId,
      floatingSteps,
      guideSteps,
    };
  },
};
</script>

<style scoped>
>>> .pagination-navigation__list {
  @apply justify-center;
}

>>> .toggle-input {
  @apply items-center;
}

>>> .data-table__wrapper__bottom {
  @apply bg-transparent py-2;
}

>>> .filters {
  @apply overflow-x-auto;
}

>>> .data-table,
>>> .data-table__wrapper__mid {
  @apply overflow-y-auto;
}

>>> .data-table__utils {
  @apply p-0 sm:px-4;
}

>>> .data-table__utils .filter:first-child {
  @apply ml-4 sm:ml-0;
}

>>> .data-table__utils .controls {
  @apply px-4 sm:px-0;
}

@media screen and (min-width: 768px) {
  .edit-wrapper {
    height: calc(100vh - 81px);
  }
}
</style>
