
























import { defineComponent } from '@nuxtjs/composition-api';
import TrainingFooter from './TrainingFooter.vue';
import TrainingList from './TrainingList.vue';
import { TypeDisplay, TypeBody } from '@bambeehr/pollen';
import useAddWorkerTraining from './useAddWorkerTraining';

export default defineComponent({
  components: {
    TrainingList,
    TypeDisplay,
    TrainingFooter,
    TypeBody,
  },
  props: {
    workerName: {
      type: String,
      required: true,
    },
    workerId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    useAddWorkerTraining(emit, props.workerId, props.workerName);
  },
});
