export const StoreModules = Object.freeze({
  AUTH: 'auth',
  COMPANIES: 'companies',
  VIDEO_TOURS: 'videoTours',
  FEATURE_FLAGS: 'featureFlags',
  REPORT_CARD: 'reportCard',
  FOLDER: 'folder',
});

export const auth = Object.freeze({
  Actions: {
    SWITCH_COMPANY: 'auth/switchCompany',
    LOGIN: 'auth/login',
    SSO_LOGIN: 'auth/ssoLogin',
  },
  Getters: {
    IS_COMPANY_ADMIN: 'auth/isCompanyAdmin',
    IS_EMPLOYEE: 'auth/isEmployee',
    IS_MANAGER: 'auth/isManager',
    IS_OWNER: 'auth/isOwner',
    IS_POLICY_APPROVER: 'auth/isPolicyApprover',
    IS_SUBSCRIBED: 'auth/isSubscribed',
    IS_DELINQUENT: 'auth/isDelinquent',
    IS_LONG_STANDING_DELINQUENT: 'auth/isLongStandingDelinquent',
    DELINQUENCY_DAYS: 'auth/delinquencyDays',
    ROLE: 'auth/role',
    COMPANY_ID: 'auth/companyId',
  },
});

export const videoTours = Object.freeze({
  Getters: {
    BY_ID: 'videoTours/byId',
    DISMISSED: 'videoTours/dismissed',
  },
  Actions: {
    FETCH_TOUR: 'videoTours/fetchVideoTour',
    DISMISS: 'videoTours/dismiss',
  },
});

export const featureFlags = Object.freeze({
  Getters: {
    FLAG: `${StoreModules.FEATURE_FLAGS}/flag`,
    IS_LOADING: `${StoreModules.FEATURE_FLAGS}/isLoading`,
  },

  Mutations: {
    SET_FLAGS: `${StoreModules.FEATURE_FLAGS}/setFlags`,
    SET_FLAG: `${StoreModules.FEATURE_FLAGS}/setFlag`,
    SET_LOADING: `${StoreModules.FEATURE_FLAGS}/setLoading`,
  },
});

export const companies = Object.freeze({
  Getters: {
    ONBOARDING_MILESTONES: `${StoreModules.COMPANIES}/onboardingMilestones`,
    ONBOARDING_MILESTONES_BY_TAG: `${StoreModules.COMPANIES}/onboardingMilestonesByTag`,
  },
});

export const reportCard = Object.freeze({
  Getters: {
    COMPANY_REPORT_CARDS: 'reportCard/companyReportCards',
    BY_ID: 'reportCard/byId',
    CURRENT_CARD: 'reportCard/currentCard',
  },
  Actions: {
    GET_BY_COMPANY_ID: 'reportCard/getByCompanyId',
  },
});

export const folder = Object.freeze({
  Getters: {
    FETCH_FOLDER_ALL: 'folder/all',
  },
  Actions: {
    UPLOAD_FILES: 'folder/uploadCompanyFiles',
    FETCH_FOLDER: 'folder/getMy',
    FETCH_COMPANY_FOLDER: 'folder/getMyCompanyFolderSimplified',
  },
});

const Store = Object.freeze({
  [StoreModules.AUTH]: auth,
  [StoreModules.COMPANIES]: companies,
  [StoreModules.VIDEO_TOURS]: videoTours,
  [StoreModules.FEATURE_FLAGS]: featureFlags,
  [StoreModules.REPORT_CARD]: reportCard,
  [StoreModules.FOLDER]: folder,
});

// This returns a non-namespaced version of the above constants object. This is
// useful withing store module files where method names are not namespaced.
export const getModuleKeys = (module) =>
  Object.entries(Store[module]).reduce(
    (moduleObj, [sectionKey, section]) => ({
      ...moduleObj,
      [sectionKey]: Object.entries(section).reduce(
        (sectionObj, [key, value]) => ({
          ...sectionObj,
          [key]: value.split('/').slice(1).join('/'),
        }),
        {}
      ),
    }),
    {}
  );

export default Store;
