import DateFormat from '@/constants/DateFormat';
import { UserCourse } from '@/gql/generated';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';

enum RecordType {
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
}

interface TrainingHistoryItems extends UserCourse {
  title: string;
  description: string;
  recordType: RecordType;
  btnText?: string;
}

const formatTrainingHistoryItems = (
  items: UserCourse[] = []
): TrainingHistoryItems[] => {
  const hasCompletedSome = items.some((item) => !!item.completedAt);

  return (
    [...items]
      .sort((a, b) =>
        isBefore(new Date(a.enrolledAt), new Date(b.enrolledAt)) ? 1 : -1
      )
      // Take single history recoreds and show the assignment and completion dates as items on the timeline
      .map((item) => {
        const name = item.name || item.bundleName;
        const courseIsCompleted = !!item.completedAt;
        const assignedDescription = `on ${format(
          new Date(item.enrolledAt),
          DateFormat.MM_DD_YYYY
        )}`;
        const completedDescription = courseIsCompleted
          ? `on ${format(new Date(item.completedAt), DateFormat.MM_DD_YYYY)}`
          : '';

        const completedTitle = courseIsCompleted
          ? `Earned ${name} Certificate`
          : `Needs to ${hasCompletedSome ? 'retake' : 'take'} ${name}`;

        const btnText = courseIsCompleted ? 'Download Certificate' : '';

        return [
          // Completed
          {
            ...item,
            id: `${item.id}-completed`,
            isCompleted: courseIsCompleted,
            title: completedTitle,
            description: completedDescription,
            recordType: RecordType.COMPLETED,
            btnText,
          },
          // Assigned
          {
            ...item,
            id: `${item.id}-assigned`,
            isCompleted: true,
            title: `Assigned ${name}`,
            description: assignedDescription,
            recordType: RecordType.ASSIGNED,
          },
        ];
      })
      .flat()
      // Filter out duplicates of "needs to take/retake" records and only show the most recent
      .filter(
        (i, index) =>
          !(index && i.recordType === RecordType.COMPLETED && !i.isCompleted)
      )
  );
};

export default formatTrainingHistoryItems;
