import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import CachePolicy from '@/gql/CachePolicy';
import {
  useCreateWorkplaceMutation,
  useListWorkplaceQuery,
  useUpdateWorkplaceMutation,
  Workplace,
} from '@/gql/generated';
import { Workplace as WorkplaceForm } from '@/modules/payroll/types/company';
import useNotifications from '@bambeehr/use-notifications';
import { computed, ref } from '@nuxtjs/composition-api';
import cloneDeep from 'lodash/cloneDeep';
import useCurrentCompany from '@/hooks/useCurrentCompany';

export const sortWorkplaces = (workplaces) => {
  const clone = cloneDeep(workplaces);

  return clone.sort(
    (a: Workplace, b: Workplace) => Number(a.id) - Number(b.id)
  );
};

const { addSuccess, addError } = useNotifications();
const { companyId } = useCurrentCompany();

// Consumer settings
const portalDestination = ref('layout');
const allowDelete = ref(false);

// UI state
const isSavingEdit = ref(false);
const isSavingNew = ref(false);
const isSavingDelete = ref(false);
const workplaces = ref<Workplace[]>([]);
const showRemoteBanner = ref(true);
const isSaving = computed<boolean>(
  () => isSavingEdit.value || isSavingNew.value || isSavingDelete.value
);

const savingHeaderLabel = computed(() => {
  if (isSavingEdit.value) {
    return 'Editing workplace';
  }

  if (isSavingDelete.value) {
    return 'Deleting workplace';
  }

  return 'Creating workplace';
});

const sortedWorkplaces = computed<Workplace[]>(() =>
  sortWorkplaces(workplaces.value)
);

const getWorkplaces = (id?: string) => {
  const shouldRefetch = !id;

  if (companyId.value) {
    useApolloQuery(
      useListWorkplaceQuery,
      { companyId: companyId.value },
      // @ts-ignore, fixing in BAMBO-2392
      { data: workplaces },
      { placeholderPick: ({ listWorkplace: res }) => res },
      {
        // Using this method instead of standard refetch query as it's cleaner in this context.
        fetchPolicy: shouldRefetch
          ? CachePolicy.NETWORK_ONLY
          : CachePolicy.CACHE_FIRST,
      }
    );
  }
};

const deleteWorkplace = (workplace: WorkplaceForm) => {
  const { mutate: updateWorkplace, onDone: onWorkplaceUpdated } =
    useApolloMutation(useUpdateWorkplaceMutation);
  isSavingDelete.value = true;
  updateWorkplace({
    data: {
      id: <string>workplace.id,
      active: false,
    },
  });

  onWorkplaceUpdated(({ updateWorkplace: res }) => {
    getWorkplaces();
    addSuccess(`${res.name} has been deleted`);
    isSavingDelete.value = false;
  });
};

const saveWorkplaceEdit = (workplace: WorkplaceForm) => {
  const {
    mutate: updateWorkplace,
    onDone: onWorkplaceUpdated,
    onError: onWorkplaceUpdateError,
  } = useApolloMutation(useUpdateWorkplaceMutation);
  isSavingEdit.value = true;
  updateWorkplace({
    data: {
      id: <string>workplace.id,
      default: workplace.default,
      name: workplace.name,
      active: true,
    },
  });

  onWorkplaceUpdated(({ updateWorkplace: res }) => {
    getWorkplaces();
    addSuccess(`${res.name || 'Your workplace'} has been updated`);
    isSavingEdit.value = false;
  });

  onWorkplaceUpdateError(({ networkError, responseErrors }) => {
    isSavingEdit.value = false;
    addError(
      `Error updating workplace: ${
        networkError?.message || responseErrors[0]?.error.message || ''
      }`
    );
  });
};

const saveNewWorkplace = (workplace: WorkplaceForm) => {
  const {
    mutate: createWorkplace,
    onDone: onWorkplaceCreated,
    onError: onWorkplaceError,
  } = useApolloMutation(useCreateWorkplaceMutation);
  isSavingNew.value = true;
  createWorkplace({
    data: {
      name: workplace.name || '',
      default: workplace.default,
      companyId: companyId.value,
      address: {
        line1: workplace.address.line1,
        line2: workplace.address.line2 || null,
        city: workplace.address.city,
        state: workplace.address.state,
        zip: workplace.address.zip,
        country: workplace.address.country || 'US',
      },
    },
  });

  onWorkplaceCreated(({ createWorkplace: res }) => {
    getWorkplaces();
    addSuccess(`${res.name || 'Your workplace'} has been created`);
    isSavingNew.value = false;
  });

  onWorkplaceError(({ networkError, responseErrors }) => {
    isSavingNew.value = false;
    addError(
      `Error creating workplace: ${
        networkError?.message || responseErrors[0]?.error.message || ''
      }`
    );
  });
};

const toggleRemoteBanner = () => {
  showRemoteBanner.value = !showRemoteBanner.value;
};

const useWorkplaces = () => ({
  allowDelete,
  deleteWorkplace,
  getWorkplaces,
  isSaving,
  isSavingEdit,
  isSavingDelete,
  isSavingNew,
  portalDestination,
  saveNewWorkplace,
  saveWorkplaceEdit,
  savingHeaderLabel,
  showRemoteBanner,
  sortedWorkplaces,
  toggleRemoteBanner,
  workplaces,
});

export default useWorkplaces;
