




















































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { TypeBody, BaseIcon, BaseButton, BaseDivider } from '@bambeehr/pollen';
import useAddWorkerTraining from './useAddWorkerTraining';

export default defineComponent({
  name: 'TrainingPurchaseSummary',
  components: {
    TypeBody,
    BaseIcon,
    BaseButton,
    BaseDivider,
  },
  setup() {
    const { selectedCoursesToBePurchasedInfo } = useAddWorkerTraining();

    const isOpen = ref(false);

    return {
      isOpen,
      selectedCoursesToBePurchasedInfo,
    };
  },
});
