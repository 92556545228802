/* eslint no-underscore-dangle: 0 */
// Useful reference:
// https://help.calendly.com/hc/en-us/articles/226766767-Pre-populate-invitee-information-in-the-booking-process

import { CallKinds, DEFAULT_CALL_KIND } from '@/constants/calendly';
import { calendlySalesEvent } from '@/lib/globals/calendly';
import serializeObject from '@/utils/serializeObject';
import * as shortid from 'shortid';

const CALENDLY_URL = 'https://calendly.com';
const DEFAULT_CALL_ID = shortid.generate();
const PRIMARY_COLOR = '68478d';

/**
 * Takes an object and builds a calendly URL prop for
 * the vue-calendly component
 * @param {Object} calendlyPayload
 * @return {String}
 */

export const generateCalendlyURL = ({
  eventLink, // if provided, will use provided event link
  answers, // Need this array [company name, guest phone #] to pre-populate fields
  callId, // Unique ID, most of the time this will not be passed in
  callKind, // Overview Call, HR General Call
  slug, // full team/event url combination (see generateHrIntroTeamCalendlySlug method below)
  ownerSlug, // Required if slug not passed - Bambee advisor (most of the time, primary advisor)
  eventType, // Required if slug not passed - Refer to lib/globals/calendly.js for examples
  guest, // Lead or Customer
  options,
}) => {
  const callKindUtm = Object.values(CallKinds).includes(callKind)
    ? callKind
    : DEFAULT_CALL_KIND;

  const utmContent = [
    guest._company?._id,
    guest._id,
    callId || DEFAULT_CALL_ID,
    callKindUtm,
  ].join('|');

  const params = {
    hide_event_type_details: 1,
    hide_landing_page_details: 1,
    primary_color: PRIMARY_COLOR,
    name: guest.profile.full_name,
    email: guest._auth?.email,
    utm_campaign: '',
    utm_content: utmContent,
    utm_medium: guest.profile.phone,
    utm_term: `app|${process.env.env}`,
    ...options, // additional param options
  };

  if (answers && answers.length) {
    answers.forEach((a, index) => {
      params[`a${index + 1}`] = a;
    });
  }

  const queryString = serializeObject(params);

  if (eventLink) {
    return `${eventLink.link}?${queryString}`;
  }

  let eventPath = slug || '';
  if (!eventPath && !!ownerSlug) {
    eventPath = `${ownerSlug}/${eventType}`;
  }

  if (!eventPath) {
    return '';
  }

  return `${CALENDLY_URL}/${eventPath}?${queryString}`;
};

export const generateSalesTeamCalendlySlug = (company, pricePlans) => {
  if (company?.profile?.num_of_employees === 'franchise') {
    return `${process.env.CALENDLY_SALES_FRANCHISE_TEAM}/${calendlySalesEvent}`;
  }

  const { tierNumber } = pricePlans.find((p) => p.value === company?.plan) || {
    tierNumber: 0,
  };

  switch (tierNumber) {
    case 1:
      return `${process.env.CALENDLY_SALES_TIER_1}/${calendlySalesEvent}`;
    case 2:
      return `${process.env.CALENDLY_SALES_TIER_2}/${calendlySalesEvent}`;
    case 3:
      return `${process.env.CALENDLY_SALES_TIER_3}/${calendlySalesEvent}`;
    case 4:
      return `${process.env.CALENDLY_SALES_TIER_4}/${calendlySalesEvent}`;
    case 5:
      return `${process.env.CALENDLY_SALES_TIER_5}/${calendlySalesEvent}`;
    case 6:
      return `${process.env.CALENDLY_SALES_TIER_6}/${calendlySalesEvent}`;
    case 7:
      return `${process.env.CALENDLY_SALES_TIER_7}/${calendlySalesEvent}`;
    default:
      return `${process.env.CALENDLY_SALES_TIER_1}/${calendlySalesEvent}`;
  }
};
