






























import { defineComponent, ref } from '@nuxtjs/composition-api';
import { TypeDisplay } from '@bambeehr/pollen';
import VideoThumb from '@/components/VideoThumb/VideoThumb.vue';
import WelcomeVideoModal from '@/components/WelcomeVideoModal/WelcomeVideoModal.vue';

export default defineComponent({
  name: 'TimeAndAttendanceUpsell',
  components: {
    TypeDisplay,
    VideoThumb,
    WelcomeVideoModal,
  },
  setup() {
    const showTourModal = ref(false);
    const toggleTourModal = () => {
      showTourModal.value = !showTourModal.value;
    };

    return {
      showTourModal,
      toggleTourModal,
    };
  },
});
