<template>
  <TermsModal
    v-if="!hideModal"
    :updated-date="updatedDate"
    :header="header"
    :agree-label="agreeLabel"
    :show-close="showClose"
    @close="closeModal"
    @submit="submitTermsAgreement"
  >
    <div class="text-left" v-html="body"></div>
  </TermsModal>
</template>
<script>
import { ref, useRouter, useStore } from '@nuxtjs/composition-api';
import format from 'date-fns/format';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import TermsModal from '@/components/TermsModal/TermsModal.vue';
import Contentful from '@/services/Contentful';
import ContentfulEntryIDs from '@/lib/globals/ContentfulEntryIDs';
import useNotifications from '@bambeehr/use-notifications';
import bam from '@/lib/bam';
import {
  useUpdateCompanyMutation,
  useGetDeputyTosInfoQuery,
} from '@/gql/generated';
import CachePolicy from '@/gql/CachePolicy';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import tos from '@/modules/TimeAndAttendance/lib/deputyTosHtmlString';
import isAfter from 'date-fns/isAfter';

export default {
  name: 'DeputyTOS',
  components: { TermsModal },
  props: {
    closeOnSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { companyId, currentUser } = store.getters;

    const { mutate: updateCompany } = useApolloMutation(
      useUpdateCompanyMutation
    );

    // Provided date of the TOS
    // Will eventually come from contentful
    const updatedDate = ref('9/29/2023');

    const header = ref('Bambee Time and Attendance Terms of Service');
    const body = ref(tos);
    const version = ref('');
    const agreeLabel = ref(
      'I have read and agree to the terms and conditions of use'
    );

    // TODO: Uncomment this when we have a TOS in contentful
    // Currently blocked by outstanding subscription issues
    // (async () => {
    //   const content = await Contentful.getEntry(
    //     ContentfulEntryIDs.DEPUTY_SERVICE_AGREEMENT
    //   );

    //   updatedDate.value = format(
    //     new Date(content.fields.updatedDate),
    //     'MMMM dd, yyyy'
    //   );
    //   header.value = content.fields.header;
    //   body.value = documentToHtmlString(content.fields.body);
    //   version.value = content.fields.version;
    //   agreeLabel.value = content.fields.agreeCheckText;
    // })();

    function sendSegmentEvent() {
      const { username, email } = currentUser._auth;
      bam.track('deputy_terms_agreed', {
        username,
        email,
        fullName: currentUser.profile.full_name,
        companyName: currentUser._company.name,
        dba: currentUser._company?.profile?.dba,
        timestamp: new Date(),
      });
    }

    // Closing this terms modal will take them to dashboard (home)
    function closeModal(agreedToTOS) {
      if (agreedToTOS) {
        sendSegmentEvent();
      }

      if (agreedToTOS) {
        emit('hide-modal', agreedToTOS);

        return;
      }

      router.push('/');
    }

    function submitTermsAgreement() {
      const { add } = useNotifications();

      add({
        id: 'DeputyTOS',
        message: `You've agreed to the Terms and Conditions`,
      });

      sendSegmentEvent();
      updateCompany({
        data: {
          deputyTosAcceptedAt: new Date(),
          id: companyId,
        },
      });

      closeModal(true);
    }

    return {
      updatedDate,
      header,
      body,
      agreeLabel,
      closeModal,
      submitTermsAgreement,
    };
  },
};
</script>

<style scoped>
>>> h1 {
  @apply font-bold;
}

>>> p {
  @apply py-2;
}
</style>
