import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  disableFragmentWarnings,
} from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import createHttpLink from './apollo-links/httpLink';
import createLoggerLink from './apollo-links/loggerLink';
import createRetryLink from './apollo-links/retryLink';
import createTimeoutLink from './apollo-links/timeoutLink';
import createWsLink from './apollo-links/wsLink';

if (process.env.NODE_ENV === 'production') {
  /* Disables "Warning: fragment with name <Fragment> already exists.
graphql-tag enforces all fragment names across your application to be unique; read more about
this in the docs: http://dev.apollodata.com/core/fragments.html#unique-names" */
  disableFragmentWarnings();
}

// TODO: can implement this after we convert this file to typescript
// enum APP_ENV {
//   LOCAL = 'local',
//   DEV = 'dev',
//   STAGE = 'stage',
//   PROD = 'production',
// }

export const GRAPHQL_SERVER_URL = process.env.PUBLICAPI_URL;

// PUBLICAPI_URL + "/payroll" is used for downloads
export const FILE_SERVER_URL =
  process.env.APP_ENV === 'local' && process.env.PAYROLL_SERVICE_URL
    ? process.env.PAYROLL_SERVICE_URL
    : `${process.env.PUBLICAPI_URL}/payroll`;

export default function createApolloClient({
  // URL to the HTTP API
  httpEndpoint = null,
  // Url to the Websocket API
  wsEndpoint = null,
  // Is currently Server-Side Rendering or not
  ssr = false,
  // Only use Websocket for all requests (including queries and mutations)
  websocketsOnly = false,
  // Default HTTP link if httpEndpoint is not defined
  defaultHttpEndpoint = `${GRAPHQL_SERVER_URL}/graphql`, // 2DO: process.env.DEFAULT_GQL_API
  // Options for the default cache
  inMemoryCacheOptions = {},
  // Additional Apollo client options
  defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-first', // cache-only, cache-first, cache-and-network, network-only
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      partialRefetch: true,
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  // httpOptions for the httpLink (headers, credentials, fetch, fetchOptions)
  httpOptions = {
    credentials: 'include',
  },
  // websocket options
  wsOptions = {},
  // retry options
  retryOptions = {
    delay: {
      initial: process.env.GQL_RETRY_DELAY_INTIAL,
      max: process.env.GQL_DELAY_MAX,
    },
    attempts: {
      max: process.env.GQL_RETRY_ATTEMPTS,
    },
  },
  // timeout in milliseconds
  timeout = process.env.GQL_TIMEOUT,
}) {
  const disableHttp = websocketsOnly && !ssr && wsEndpoint;

  // configure Apollo Memory Cache
  const cache = new InMemoryCache(inMemoryCacheOptions);

  let httpLink = null;
  const uri = httpEndpoint || defaultHttpEndpoint;
  if (!disableHttp) {
    // // create HTTP link
    httpLink = createHttpLink({
      uri,
      httpOptions,
    });
  }

  // create WebSocket Link
  let wsLink = null;
  if (process.browser && !!wsEndpoint) {
    wsLink = createWsLink({
      uri: wsEndpoint,
      wsOptions,
    });
  }

  // create Split Link if wsEndpoint is configured
  const splitLink =
    process.browser && !!wsEndpoint
      ? split(
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query);

            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          wsLink,
          httpLink
        )
      : httpLink;

  // create Logger Link
  const loggerLink = createLoggerLink();

  // create Retry link
  const retryLink = createRetryLink(retryOptions);
  // create timeout Link
  const timeoutLink = createTimeoutLink(timeout);

  const link = ApolloLink.from([loggerLink, retryLink, timeoutLink, splitLink]);

  const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
  });

  return client;
}
