// TODO: Would love to not have to pass in a store for this. Ultimately, it'd be logical to use a user ID to look up roles

const isTrainingEmployerRole = (store) => {
  const { getters } = store;
  const isOwner = getters['auth/isOwner'];
  const isAdmin = getters['auth/isCompanyAdmin'];
  const hasPermissions = isOwner || isAdmin;

  return hasPermissions;
};

export default isTrainingEmployerRole;
