import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import miniToastr from 'mini-toastr';

const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn',
};

miniToastr.init({
  types: toastTypes,
  style: {
    'mini-toastr__notification': {
      'mini-toastr-notification__message': {
        'border-radius': '0px',
      },
    },
  },
});

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

VueNotifications.config.timeout = 3000;
Vue.use(VueNotifications, options);
