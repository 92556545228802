export enum HrPractices {
  AUDIT = 'hr-practice-audit',
  DOCUMENT = 'hr-practice-document',
  PAYROLL = 'hr-practice-payroll',
  POLICY = 'hr-practice-policy',
  REPORT_CARD = 'hr-practice-report-card',
  TRAINING = 'hr-practice-training',
  VOICES = 'hr-practice-voices',
}

export default HrPractices;
