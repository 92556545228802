import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import { getPayTypeOptions, PayTypeCaps } from '@/lib/globals/users';
import CachePolicy from '@/gql/CachePolicy';
import {
  CompanyRole,
  useCreateCompanyRolesMutation,
  useGetCompanyRolesQuery,
  useGetUserRolesQuery,
  WorkerRole,
  WorkerRolePaytype,
} from '@/gql/generated';
import { computed, ref, watch } from '@nuxtjs/composition-api';
import shortId from 'shortid';

export enum RoleOptionAction {
  MANAGE = 'MANAGE',
}

export interface ClientWorkerRole extends WorkerRole {
  isNew?: boolean;
  isDeleted?: boolean;
  isEdited?: boolean;
}

let emit;

// ------- Utils ------- //
export const decorateRole = (roleInfo): ClientWorkerRole => ({
  userId: '',
  id: shortId.generate(),
  isPrimary: false,
  payRate: 0,
  payType: PayTypeCaps.HOURLY,
  deletedAt: '',
  isNew: false,
  isDeleted: false,
  isEdited: false,
  companyRole: {
    companyId: '',
    title: '',
    id: '',
    deletedAt: '',
    workerRoles: [],
  },
  ...roleInfo,
});

export const getNewRole = (isPrimary: boolean = false): ClientWorkerRole =>
  decorateRole({
    isPrimary,
    isNew: true,
  });

export const getRoleOptions = (
  query: string,
  currentList: string[],
  roleList: CompanyRole[]
) => {
  const lastOption = {
    value: RoleOptionAction.MANAGE,
    label: 'Manage Roles',
    icon: 'pen',
    callout: true,
  };

  return [
    ...roleList
      .filter((r) => !currentList.includes(r.id))
      .map((r) => ({
        value: r.id,
        label: r.title,
      })),
    ...(query ? [] : [lastOption]),
  ];
};

// ------- Consts ------- //
const { companyId } = useCurrentCompany();
const rolesModel = ref([] as ClientWorkerRole[]);
const isContractor = ref(false);
const roleSearchQuery = ref('');
const showManageCompanyRolesModal = ref(false);
const showSalaryRolesModal = ref(false);
const companyRoleList = ref<CompanyRole[]>([]);
const isValidating = ref(false);

// ------- Computed & Watchers ------- //
const currentActiveRoles = computed(() =>
  rolesModel.value.filter((r) => !r.isDeleted)
);

const currentRoleIds = computed(() =>
  rolesModel.value
    .reduce((a, c) => [...a, c.companyRole?.id || ''], [] as string[])
    .filter(Boolean)
);

const primaryRole = computed(() => rolesModel.value.find((r) => r.isPrimary));

const roleOptions = computed(() =>
  getRoleOptions(
    roleSearchQuery.value,
    currentRoleIds.value,
    companyRoleList.value
  )
);

const payTypeOptions = computed(() => {
  return getPayTypeOptions(isContractor.value, true);
});

const isSalaryWorker = computed(() =>
  rolesModel.value.some((r) => r.payType === PayTypeCaps.SALARY)
);

watch(
  rolesModel,
  (updatedModel) => {
    emit('input', updatedModel);
  },
  { deep: true }
);

// ------- FNs ------- //

const resetState = () => {
  rolesModel.value = [];
  isContractor.value = false;
  roleSearchQuery.value = '';
  showManageCompanyRolesModal.value = false;
  showSalaryRolesModal.value = false;
  isValidating.value = false;
};

const makeRolePrimary = (role: ClientWorkerRole) => {
  role.isPrimary = true;

  rolesModel.value = [
    role,
    ...rolesModel.value
      .filter((r) => r.id !== role.id)
      .map((r) => ({
        ...r,
        isPrimary: false,
      })),
  ];
};

const updateRoleSearchQuery = (query) => {
  roleSearchQuery.value = query;
};

const removeRole = (role: ClientWorkerRole) => {
  role.isDeleted = true;
  const modelRole = rolesModel.value.find((r) => r.id === role.id);

  if (modelRole) {
    modelRole.isDeleted = true;
  }
};

const updateRoleModel = (updatedRole) => {
  const matchingRole = rolesModel.value.find((r) => r.id === updatedRole.id);
  const matchingCompanyRole = companyRoleList.value.find(
    (r) => r.id === updatedRole.companyRole.id
  );

  if (matchingRole) {
    const isEdited =
      String(matchingRole.payRate) !== String(updatedRole.payRate) ||
      matchingRole.companyRole?.id !== updatedRole.companyRole.id ||
      matchingRole.payType !== updatedRole.payType;

    Object.assign(matchingRole, {
      // In order to not nullify a previous edited state, we'll only apply true here
      ...(isEdited ? { isEdited } : {}),
      ...updatedRole,
      companyRole: {
        ...(matchingCompanyRole || updatedRole.companyRole),
      },
    });

    if (updatedRole.isPrimary) {
      makeRolePrimary(matchingRole);
    }
  }

  return matchingRole || updatedRole;
};

const getCompanyRoles = (shouldHardRefresh?: boolean, callback?: Function) => {
  const { onResult } = useApolloQuery(
    useGetCompanyRolesQuery,
    { companyId: companyId.value },
    // @ts-ignore, ignoring type error bc of pick
    { data: companyRoleList },
    {
      placeholderPick: ({ getCoreCompany: res }) =>
        res.companyRoles.filter((r) => !r.deletedAt),
    },
    {
      fetchPolicy: shouldHardRefresh
        ? CachePolicy.NETWORK_ONLY
        : CachePolicy.CACHE_FIRST,
    }
  );
  onResult(({ getCoreCompany: res }) => {
    if (callback) {
      callback(res);
    }

    rolesModel.value.map((role) => {
      if (
        !res.companyRoles?.some((r) => r.id === role.companyRole?.id) &&
        role.companyRole
      ) {
        // @ts-ignore
        role.companyRole = {
          id: '',
          title: '',
        };
      }

      return role;
    });
  });
};

const createCompanyRole = (role, companyRoleTitle) => {
  const { mutate, onDone } = useApolloMutation(useCreateCompanyRolesMutation);

  mutate({
    data: {
      companyId: companyId.value,
      titles: [companyRoleTitle],
    },
  });

  onDone(({ createCompanyRoles: res }) => {
    if (res) {
      const currentRole = rolesModel.value.find((r) => r.id === role.id);

      getCompanyRoles(true, () => {
        if (currentRole) {
          currentRole.companyRole = res[0] as CompanyRole;
          // Removes focus from the select which removes search text
          (document.activeElement as HTMLInputElement)?.blur();
        }
      });
    }
  });
};

const createRole = (isPrimary?: boolean) => {
  rolesModel.value = [...rolesModel.value, getNewRole(isPrimary)];
};

const toggleManageCompanyRolesModal = () => {
  showManageCompanyRolesModal.value = !showManageCompanyRolesModal.value;
};

const toggleSalaryRolesModal = (show?: boolean) => {
  showSalaryRolesModal.value =
    show !== undefined ? show : !showSalaryRolesModal.value;
};

const dismissSalaryRolesModal = () => {
  if (primaryRole.value) {
    primaryRole.value.payType = PayTypeCaps.HOURLY as WorkerRolePaytype;
  }

  toggleSalaryRolesModal(false);
};

const acceptSalaryRolesModal = () => {
  if (primaryRole.value) {
    rolesModel.value = [
      ...rolesModel.value.map((r) =>
        r.isPrimary
          ? r
          : {
              ...r,
              isDeleted: true,
            }
      ),
    ];
  }

  toggleSalaryRolesModal(false);
};

const workerId = ref('');

const getWorkerRoles = (shouldHardRefresh?: boolean) => {
  if (!workerId.value) {
    rolesModel.value = [getNewRole(true)];

    return;
  }

  const { onResult } = useApolloQuery(
    useGetUserRolesQuery,
    {
      userId: workerId.value,
    },
    undefined,
    undefined,
    {
      fetchPolicy: shouldHardRefresh
        ? CachePolicy.NETWORK_ONLY
        : CachePolicy.CACHE_FIRST,
    }
  );

  onResult(({ getCoreUserById: res }) => {
    if (res.employment?.workerRoles?.length) {
      rolesModel.value = res.employment?.workerRoles.map(
        decorateRole
      ) as ClientWorkerRole[];

      if (primaryRole.value) {
        makeRolePrimary(primaryRole.value);
      }

      return;
    }

    rolesModel.value = [getNewRole(true)];
  });
};

const validate = (): boolean => {
  const isValid = rolesModel.value.every(
    (r) => r.isDeleted || r.companyRole?.id
  );

  isValidating.value = true;

  return isValid;
};

const useAddStaffFormRole = (props?, instEmit?, uId = '') => {
  if (instEmit) {
    emit = instEmit;
  }

  if (props) {
    isContractor.value = props.isContractor;
    showManageCompanyRolesModal.value = false;
    workerId.value = uId;
    isValidating.value = false;
    getCompanyRoles();
    getWorkerRoles(true);
  }

  return {
    companyRoleList,
    currentActiveRoles,
    currentRoleIds,
    isSalaryWorker,
    isValidating,
    payTypeOptions,
    roleOptions,
    roleSearchQuery,
    rolesModel,
    showManageCompanyRolesModal,
    showSalaryRolesModal,

    acceptSalaryRolesModal,
    createCompanyRole,
    createRole,
    dismissSalaryRolesModal,
    getCompanyRoles,
    makeRolePrimary,
    removeRole,
    resetState,
    toggleManageCompanyRolesModal,
    toggleSalaryRolesModal,
    updateRoleModel,
    updateRoleSearchQuery,
    validate,
  };
};

export default useAddStaffFormRole;
