































import {
  defineComponent,
  ref,
  useStore,
  computed,
} from '@nuxtjs/composition-api';

import { BaseCard } from '@bambeehr/pollen';
import BenefitsAdBanner from '@/modules/Benefits/components/BenefitsAdBanner/BenefitsAdBanner.vue';
import BenefitsOverviewActionTile from './BenefitsOverviewActionTile.vue';
import { toCurrency } from '@/utils/currency';
import { useApolloQuery } from '@/gql/apolloWrapper';

import { useGetCompanyEstimatedBenefitsCostQuery } from '@/gql/generated';

export default defineComponent({
  name: 'BenefitsOverview',
  components: {
    BenefitsAdBanner,
    BenefitsOverviewActionTile,
    BaseCard,
  },
  setup() {
    const store = useStore();
    const { company: companyStore } = store.getters;
    const monthlyPremium = ref();

    useApolloQuery(
      useGetCompanyEstimatedBenefitsCostQuery,
      { zip: companyStore.profile.zip },
      { data: monthlyPremium },
      {
        placeholderPick: ({ getCompanyEstimatedBenefitsCost: res }) =>
          res?.monthlyPremium && toCurrency(res?.monthlyPremium),
      }
    );

    const monthlyPremiumText = computed(() =>
      monthlyPremium.value ? `${monthlyPremium.value} per employee` : ''
    );

    return {
      monthlyPremium,
      monthlyPremiumText,
    };
  },
});
