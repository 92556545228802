import Vue from 'vue';
import VueConfetti from 'vue-confetti';

Vue.use(VueConfetti);

let confetti;

type ConfettiOptions = {
  duration?: number;
  colors?: string[]; // hexes
};
type CelebrateOptions = {
  duration?: number;
};

const celebrate = ({
  duration = 4000,
  colors = ['#481d72', '#481d72', '#16a7a7', '#eae3f2', '#c2f4f4'], // Bambee brand colors
}) => {
  if (!confetti) {
    console.warn('confetti not provided');
  }

  confetti.start({
    defaultColors: colors,
    defaultDropRate: 10,
    particlesPerFrame: 5,
    defaultType: 'rect',
  });

  // If a duration is specified, then assume the celebration should only last as long as configured
  // else, assume that the consumer will end the celebration manually
  if (duration) {
    setTimeout(() => {
      confetti.stop();
    }, duration);
  }
};

const useConfetti = (rootConfetti, globalOptions: ConfettiOptions = {}) => {
  if (!confetti) {
    confetti = rootConfetti;
  }

  return {
    celebrate: (options: CelebrateOptions = {}) => {
      celebrate({
        ...globalOptions,
        ...options,
      });
    },
    partyPooper: () => {
      confetti.stop();
    },
  };
};

export default useConfetti;
