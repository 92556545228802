








import { defineComponent, computed, watch } from '@nuxtjs/composition-api';
import useDelinquency from '@/modules/Billing/hooks/useDelinquency';
import useContactUsContent from '@/modules/ContactUs/hooks/useContactUsContent';
import { format } from 'date-fns';
import bam from '@/lib/bam';
import DelinquentBanner, {
  BannerMessage,
  BannerTypes,
} from '@/components/banners/DelinquentBanner.vue';

export default defineComponent({
  components: {
    DelinquentBanner,
  },

  setup() {
    const { customerSupportPhoneNumber } = useContactUsContent();
    const { delinquent, isPayrollLocked, isAdmin, cancellationDate } =
      useDelinquency();

    const isDelinquent = computed<boolean>(
      () => !!delinquent.value?.delinquent
    );

    const bannerMessage = computed<BannerMessage>(() => {
      if (!isDelinquent.value) {
        return {};
      }

      const dateFormatter = (date) => format(date, 'MMMM d');

      const formattedCancellationDate = cancellationDate.value
        ? dateFormatter(cancellationDate.value)
        : '';

      return isPayrollLocked.value
        ? {
            title: `Your access to Bambee Payroll™ is limited.`,
            body: `Your subscription to Bambee requires a new payment method. Until a new payment method is added, you will not be able to run compliant new payrolls. You still have access to your payroll records and tax documents. Need assistance? Contact support ${customerSupportPhoneNumber.value}.`,
          }
        : {
            title: `We were unable to process your payment.`,
            body: `The charge for your subscription was declined. Update your payment method by ${formattedCancellationDate} to resume access to your dedicated HR manager, essential HR services, company’s important HR data, and running payroll. Need assistance? Contact support ${customerSupportPhoneNumber.value}.`,
          };
    });

    const showBanner = computed<boolean>(
      () => isAdmin.value && isDelinquent.value
    );

    const bannerType = computed<string>(() =>
      isPayrollLocked.value ? BannerTypes.ERROR : BannerTypes.WARNING
    );

    const buttonVariant = computed<string>(() =>
      isPayrollLocked.value ? 'inverted-tertiary' : 'inverted-primary'
    );

    watch(showBanner, (show) => {
      if (show) {
        bam.track('payroll-delinquent-banner-shown', {
          delinquent: isDelinquent.value,
          payrollLocked: isPayrollLocked.value,
        });
      }
    });

    return {
      bannerMessage,
      showBanner,
      bannerType,
      buttonVariant,
    };
  },
});
