





































































import useFileDownloader from '@/composables/useFileDownloader';
import { useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentUser from '@/hooks/useCurrentUser';

import { useGetUserCoursesQuery } from '@/gql/generated';
import { getUserDoceboToken } from '@/gql/services/trainingService/training.service';
import mapCoursesToBundles from '@/modules/Training/utils/mapCoursesToBundles';
import useNotifications from '@bambeehr/use-notifications';
import {
  computed,
  defineComponent,
  ref,
  useRoute,
  watch,
  onUnmounted,
} from '@nuxtjs/composition-api';
import EmployeeTrainingDetailHeader from './EmployeeTrainingDetailHeader.vue';
import { AnnularThrobber } from '@bambeehr/pollen';
import useTrainingCatalog from '@/modules/Training/hooks/useTrainingCatalog';
import BreadCrumbs from '@/components/BreadCrumbs';
import { isDev } from '@/utils/env-helper';
import { BaseBanner } from '@bambeehr/pollen';

export const getCourseDetailsLabel = (isBundle: boolean) =>
  `About this ${isBundle ? 'Program' : 'Course'}`;

async function getAuthToken() {
  try {
    const response = await getUserDoceboToken();

    return response.data.token;
  } catch (err) {
    console.error('error fetching user token', err);
    throw err;
  }
}

export default defineComponent({
  components: {
    EmployeeTrainingDetailHeader,
    AnnularThrobber,
    BreadCrumbs,
    BaseBanner,
  },
  setup() {
    const route = useRoute();
    const { fileDownloader } = useFileDownloader();
    const { id } = route.value.params;
    const { currentUserId, isOwnerAdmin } = useCurrentUser();
    const { addSuccess } = useNotifications();
    const trainingItem = ref();
    const isLoading = ref(false);
    const kernelLoaded = ref(false);

    const isBundle = computed(() => trainingItem.value?.isBundle);

    const { availableBundles, availableCourses } = useTrainingCatalog();
    const coursesInfo = computed(() => {
      if (!trainingItem.value) {
        return [];
      }

      const contentId = isBundle.value
        ? trainingItem.value?.bundleId
        : trainingItem.value?.topicId;
      const topics = isBundle.value
        ? trainingItem.value?.topics
        : [trainingItem.value];

      const matchingContent = isBundle.value
        ? availableBundles.value.find((i) => i.id === contentId)
        : availableCourses.value.find((i) => i.id === contentId);

      return topics.map((topic) => ({
        id: topic.id,
        courseId: topic.doceboCourseId,
        imageUrl: matchingContent?.imageUrl,
        description: matchingContent?.description || '',
        name: matchingContent?.name || '',
        height: isBundle.value ? 300 : 500,
      }));
    });

    const description = computed(() =>
      isBundle.value
        ? trainingItem.value.description
        : coursesInfo.value[0].description
    );

    useApolloQuery(
      useGetUserCoursesQuery,
      { data: { userId: currentUserId.value } },
      {
        data: trainingItem,
        pending: isLoading,
      },
      {
        placeholderPick: ({ userCourses: res }) =>
          mapCoursesToBundles(res).find((i) => i.id === id),
      }
    );

    const shouldPlayOnLoad = ref(!!route.value.query.play);
    const courseDetailsLabel = computed(() =>
      getCourseDetailsLabel(isBundle.value)
    );

    const courseCount = computed(() => (isBundle.value ? 4 : 0));

    // Docebo flow player setup
    let initInterval;
    const launcher = ref();

    function initPlayer() {
      try {
        // @ts-ignore
        launcher.value = window?.DFlow?.launcher();
        if (launcher.value) {
          launcher.value.setTokenProvider(getAuthToken);
          launcher.value.start(
            process.env.TRAINING_SERVICE_DOCEBO_DOMAIN,
            process.env.TRAINING_SERVICE_FLOW_APP_ID
          );

          clearInterval(initInterval);
        }
      } catch (err) {
        console.error(err);
      }
    }

    // watch users course list, if user has unfinished courses,
    // initialize the player
    const initUnwatcher = watch(
      trainingItem,
      (val) => {
        if (val) {
          const doceboWebComponent = document.querySelector(
            'body > dcbo-flow-launcher'
          );
          // Allow dev env to load the page when the player fails
          kernelLoaded.value = !!doceboWebComponent || isDev;
          console.log('Running in dev mode...');
          // Double initialization of Docebo's web component causes an error
          if (!launcher.value && !doceboWebComponent) {
            initInterval = setInterval(initPlayer, 500);
            initUnwatcher();
          }
        }
      },
      { immediate: true }
    );

    const downloadCert = (item?) => {
      let certUrls: string[];
      if (isBundle.value) {
        certUrls = item
          ? [item.certificateDownloadUrl]
          : trainingItem.value?.certificateDownloadUrlList;
      } else {
        certUrls = [trainingItem.value?.certificateDownloadUrl];
      }
      certUrls.forEach((url) => {
        fileDownloader(url, '');
      });

      addSuccess(`Downloading. This may take a moment.`);
    };

    // watch kernel for nested change in eventManager to conditionally render loading state
    // eventManager ids are mapped to current kernal state
    watch(
      launcher,
      () => {
        // Allow dev env to load the page when the player fails
        kernelLoaded.value =
          !!document.querySelector('body > dcbo-flow-launcher') || isDev;
        console.log('Running in dev mode...');
      },
      {
        deep: true,
      }
    );

    onUnmounted(() => {
      if (initInterval) {
        clearInterval(initInterval);
      }
    });

    const crumbs = computed(() =>
      [
        isOwnerAdmin.value && {
          label: 'Training',
          to: '/training/overview',
        },
        {
          label: 'My Training',
          to: isOwnerAdmin.value ? '/training/my-courses' : '/my-training',
        },
        {
          label: trainingItem.value?.name || '',
        },
      ].filter(Boolean)
    );

    return {
      crumbs,
      trainingItem,
      courseDetailsLabel,
      isBundle,
      courseCount,
      downloadCert,
      kernelLoaded,
      coursesInfo,
      description,
    };
  },
  head() {
    return {
      script: [
        {
          hid: 'docebo',
          src: 'https://cdn1.dcbstatic.com/flow/kernels/Kernel-latest.bundle.min.js',
          defer: true,
          crossOrigin: 'anonymous',
        },
      ],
    };
  },
});
