import {
  StaffMember,
  StaffRoles,
  StaffStates
} from '@/modules/StaffManagement/models';
import { reactive } from '@nuxtjs/composition-api';
import { orderBy } from 'lodash';

export enum FilterTypes {
  STATUS = 'Status',
  ROLE = 'Role',
}
export const SortingKey = 'SortBy';

interface Filters {
  [FilterTypes.ROLE]: String | null;
  [FilterTypes.STATUS]: String | null;
  [SortingKey]: String | null;
}

const defaultState = Object.freeze({
  [FilterTypes.ROLE]: null,
  [FilterTypes.STATUS]: null,
  [SortingKey]: null,
});

const stateOptions = Object.keys(StaffStates).map((o) => StaffStates[o]);
// Intentionally excluding "Admin" and "Approver" roles here...
const roleOptions = [
  StaffRoles.EMPLOYEE,
  StaffRoles.MANAGER,
  StaffRoles.CONTRACTOR,
];

const staffFilters = [
  {
    label: FilterTypes.STATUS,
    name: FilterTypes.STATUS,
    options: stateOptions,
    placeholder: 'Current',
  },
  {
    label: FilterTypes.ROLE,
    name: FilterTypes.ROLE,
    options: roleOptions,
    placeholder: 'Any',
  },
];

export enum SortingTypes {
  ALPHA_ASC = 'A - Z',
  ALPHA_DESC = 'Z - A',
}

const sortOptions = [SortingTypes.ALPHA_ASC, SortingTypes.ALPHA_DESC];

const state = reactive<Filters>({ ...defaultState });

const setFilter = (type: FilterTypes, value: string | null) => {
  state[type] = value;
};

const setSorting = (value: string | null) => {
  state[SortingKey] = value;
};

const getState = () => state;

const setState = (newState) => {
  Object.assign(state, { ...newState });
};

const resetFilters = () => {
  Object.assign(state, { ...defaultState });
};

const filterAndSortStaff = (staffList: StaffMember[]) => {
  let staffArr: StaffMember[] = staffList;

  if (state[FilterTypes.ROLE]) {
    switch (state[FilterTypes.ROLE]) {
      case StaffRoles.MANAGER:
        staffArr = staffList.filter((m: StaffMember) =>
          m.roles.includes(StaffRoles.MANAGER)
        );
        break;
      case StaffRoles.ADMIN:
        staffArr = staffList.filter((m: StaffMember) =>
          m.roles.includes(StaffRoles.ADMIN)
        );
        break;
      case StaffRoles.APPROVER:
        staffArr = staffList.filter((m: StaffMember) =>
          m.roles.includes(StaffRoles.APPROVER)
        );
        break;
      case StaffRoles.CONTRACTOR:
        staffArr = staffList.filter((m: StaffMember) => m.contractor);
        break;
      case StaffRoles.EMPLOYEE:
      default:
        staffArr = staffList.filter(
          (m: StaffMember) =>
            !m.roles.includes(StaffRoles.MANAGER) && !m.contractor
        );
        break;
    }
  }

  // Filter by Staff Status
  if (state[FilterTypes.STATUS]) {
    staffArr = staffArr.filter(
      (m: StaffMember) => m.staffState === state[FilterTypes.STATUS]?.toString()
    );
  } else {
    // if not set (e.g. all statuses), filter out former employees
    staffArr = staffArr.filter(
      (m: StaffMember) => m.staffState !== StaffStates.FORMER
    );
  }

  // Sort By
  if (state[SortingKey]) {
    switch (state[SortingKey]) {
      case SortingTypes.ALPHA_ASC:
        staffArr = orderBy(
          staffArr,
          (m: StaffMember) => m.profile.staffName.toLowerCase(),
          ['asc']
        );
        break;
      case SortingTypes.ALPHA_DESC:
      default:
        staffArr = orderBy(
          staffArr,
          (m: StaffMember) => m.profile.staffName.toLowerCase(),
          ['desc']
        );
    }
  }

  return staffArr;
};

const useFilter = () => {
  return {
    currentFilters: state,
    getState,
    filterAndSortStaff,
    resetFilters,
    setFilter,
    setSorting,
    setState,
    sortOptions,
    staffFilters,
  };
};

export default useFilter;
