
















import { defineComponent } from '@nuxtjs/composition-api';
import { guard } from '../middleware/canAccessEmployerTimeAndAttendance';

export default defineComponent({
  name: 'TimeAndAttendanceRoot',
  middleware: [guard],
});
