












































































































































import {
  BaseCard,
  TypeBody,
  BaseButton,
  BaseIcon,
  ToolTip,
  BaseTag,
  BaseBanner,
  BaseLink,
} from '@bambeehr/pollen';
import {
  computed,
  PropType,
  defineComponent,
  reactive,
} from '@nuxtjs/composition-api';
import ExplainerTooltipModal from '@/components/ExplainerTooltipModal';
import { ContentfulExplainerModalEntryIDs } from '@/lib/globals/ContentfulEntryIDs';

import { Goal, Requirement, RequirementStatus, DocumentOption } from '@/gql/generated';

export interface DecoratedDocumentOption extends DocumentOption {
  entryId: string;
}

export interface DecoratedRequirement extends Requirement {
  isComplete: boolean;
  isOpen: boolean;
  isPending: boolean;
  isFailed: boolean;
  isClosed: boolean;
  documentOptions: DecoratedDocumentOption[];
  hasThreeOrMoreDocs: boolean;
}

export const mapRequirements = (
  requirements: Requirement[] = []
): DecoratedRequirement[] =>
  requirements.map((req) => ({
    ...req,
    isComplete: req.status === RequirementStatus.Resolved,
    isOpen: req.status === RequirementStatus.Open,
    isPending: req.status === RequirementStatus.Pending,
    isFailed: req.status === RequirementStatus.Failed,
    isClosed: req.status === RequirementStatus.Closed,
    hasThreeOrMoreDocs: req.documentOptions.length >= 3,
    documentOptions: req.documentOptions.map((option) => ({
      ...option,
      label: option.label,
      value: option.name,
      entryId:
        ContentfulExplainerModalEntryIDs.CHECK_REQUIREMENT_DOCUMENTS[
          option.name
        ],
    })),
  }));

export const createDocVisibilityModel = (
  requirements: DecoratedRequirement[] = []
): Record<string, boolean> => {
  const model: Record<string, boolean> = {};

  requirements.forEach((req) => {
    if (req.hasThreeOrMoreDocs) {
      model[req.id] = false;
    }
  });

  return model;
};

export const getUploadDocLabel = (req: Requirement): string => {
  // @ts-ignore until we have schema
  if (req.isFailed) {
    return 'or upload one of the following:';
  }

  // @ts-ignore until we have schema
  return req?.documentOptions?.length > 1
    ? `Upload one of the following documents for review:`
    : `Upload the following document for review:`;
};

export default defineComponent({
  name: 'CompanyGoal',
  components: {
    BaseCard,
    TypeBody,
    BaseIcon,
    BaseButton,
    ToolTip,
    BaseTag,
    BaseBanner,
    ExplainerTooltipModal,
    BaseLink,
  },
  props: {
    goal: {
      type: Object as PropType<Goal>,
      required: true,
    },
  },
  setup(props) {
    const decoratedReqs = computed(() =>
      mapRequirements(props.goal?.requirements)
    );

    const docVisibilityModel = reactive(
      createDocVisibilityModel(decoratedReqs.value)
    );

    const toggleDocVisibility = (id) => {
      docVisibilityModel[id] = !docVisibilityModel[id];
    };

    return {
      decoratedReqs,
      getUploadDocLabel,
      docVisibilityModel,
      toggleDocVisibility,
    };
  },
});
