








import { computed, watch } from '@nuxtjs/composition-api';
import { TypeBody, BaseButton } from '@bambeehr/pollen'
import useCookiePolicy from '@/modules/CookiePolicy/hooks/useCookiePolicy/useCookiePolicy'
import { TrackEvents as CookieTrackEvents } from '@/modules/CookiePolicy/constants/analytics'

export default {
    name: 'CookiePolicy',
    components: {
        TypeBody,
        BaseButton,
    },

    setup() {
        const { isReady: isCookiePolicyReady, hasAcknowledged, acknowledge, trackCookieEvent, policy } = useCookiePolicy();

        const handleAcknowledgeClick = () => {
            acknowledge();
            trackCookieEvent(CookieTrackEvents.POLICY_ACKNOWLEDGED);
        }

        const shouldShow = computed(() => {
            return isCookiePolicyReady.value && !hasAcknowledged.value
        })

        watch(shouldShow, (shouldShowVal) => {
            if (shouldShowVal) {
                trackCookieEvent(CookieTrackEvents.BANNER_SHOWN);
            }
        }, { immediate: true });

        return {
            handleAcknowledgeClick,
            shouldShow,
            policy,
        };
    },
};
