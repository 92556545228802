




























import {
  ref,
  computed,
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import { BaseButton, TypeBody, CheckboxInput } from '@bambeehr/pollen';

export default defineComponent({
  name: '',
  components: {
    BaseButton,
    TypeBody,
    CheckboxInput,
  },
  props: {
    indeterminate: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    selectedLabel: {
      type: String as PropType<string>,
      default: 'selected',
    },
    totalCount: {
      type: Number as PropType<number>,
      required: true,
    },
    selectedCount: {
      type: Number as PropType<number>,
      required: true,
    },
    buttonLabel: {
      type: String as PropType<string>,
      default: 'Send',
    },
    buttonDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
