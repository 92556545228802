import useCreditCardFailedModal from '@/components/CreditCardFailedModal/useCreditCardFailedModal';
import { apiVersion } from '@/constants/Stripe';
import { useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentUser from '@/hooks/useCurrentUser';
import {
  GetCompanyPaymentSources,
  PaymentSources,
  useGetCompanySourcesQuery,
} from '@/gql/generated';
import useNotifications from '@bambeehr/use-notifications';
import {
  computed,
  getCurrentInstance,
  ref,
  useContext,
  useStore,
  watch,
} from '@nuxtjs/composition-api';
import get from 'lodash/get';
import useCompanyStore from '@/store/companies';

let isInitialized;

const isReady = ref(false);

let company;
let store;
let context;

const stripe = ref<any>();
const stripeCCInput = ref<any>();

const isLoading = ref(false);

const billingInfo = ref<PaymentSources>({
  companyId: '',
  sources: [],
});

export interface BillingInfo {
  cardholderName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  last4?: string;
}

export const getDefaultBillingInfo = (info: PaymentSources): BillingInfo => {
  const defaultInfo = info.sources.find((source) => source.default);

  return {
    cardholderName: defaultInfo?.name || '',
    addressLine1: defaultInfo?.addressLine1 || '',
    addressLine2: defaultInfo?.addressLine2 || '',
    city: defaultInfo?.addressCity || '',
    state: defaultInfo?.addressState || '',
    country: defaultInfo?.country || '',
    last4: defaultInfo?.last4 || '',
  };
};

const updateCardAPIRoute = `/v0/app/my/self/update-company-credit-card`;

const defaultBillingInfo = computed(() =>
  getDefaultBillingInfo(billingInfo.value)
);

const last4CCdigits = computed(() => defaultBillingInfo.value.last4);

const plan = computed(() => company.plan);

// eslint-disable-next-line no-underscore-dangle
const companyId = computed(() => company._id);

const { addError, addSuccess } = useNotifications();

const { closeModal } = useCreditCardFailedModal();

const fetchPaymentSources = async (force = false): Promise<void> => {
  const { onResult: setPaymentSources } = useApolloQuery(
    useGetCompanySourcesQuery,
    {
      data: {
        companyId: companyId.value,
      } as GetCompanyPaymentSources,
    },
    {},
    {
      force,
    }
  );

  setPaymentSources((result: any): void => {
    billingInfo.value = result.getCompanySources as PaymentSources;
  });
};

const updatePayment = async ({
  cardholderName,
  addressLine1,
  addressLine2,
  city,
  state,
  country,
}: BillingInfo): Promise<void | null> => {
  const { $axios } = context;

  isLoading.value = true;

  const { token, error } = await stripe.value.createToken(stripeCCInput.value, {
    name: cardholderName,
    address_line1: addressLine1,
    address_line2: addressLine2,
    address_city: city,
    address_state: state,
    address_country: country,
  });

  if (error) {
    addError(`Payment Error: ${error.message}`);

    return null;
  }

  try {
    const res = await $axios.post(updateCardAPIRoute, {
      token: token.id,
    });

    const companyStore = useCompanyStore();
    companyStore.setBillingInfo({
      companyId,
      info: res.data,
    });

    addSuccess('Card has been added and set as default payment');
    closeModal();

    isLoading.value = false;

    await fetchPaymentSources();

    return null;
  } catch (err: any) {
    let message;
    if (!err.response) {
      message = err;
    } else if (err.response.status === 400) {
      const { data } = err.response;
      message = get(data, 'data.stripeError') || data.message || data;
    } else {
      message = err.response.statusText;
    }
    isLoading.value = false;
    addError(`Payment Error: ${message}`);

    return null;
  }
};

const initStripeElement = (): void => {
  stripe.value = new (window as any).Stripe(
    process.env.stripe_public as string,
    {
      apiVersion,
    }
  );

  const stripeCCElement = stripe.value.elements();
  stripeCCInput.value = stripeCCElement.create('card');
};

const setupHook = async (force = false) => {
  isInitialized = true;
  // reset loading state

  context = useContext();
  store = useStore();
  fetchPaymentSources(force);
  isReady.value = true;
  isLoading.value = false;
};

const usePayment = (force = false) => {
  const { currentUser } = useCurrentUser();

  const vm = getCurrentInstance();

  watch(
    currentUser,
    (user) => {
      const compny = get(user, '_company', {});
      company = compny;
    },
    {
      immediate: true,
    }
  );

  if (!isInitialized && vm) {
    setupHook(force);
  }

  return {
    billingInfo,
    defaultBillingInfo,
    plan,
    company,
    isLoading,
    stripeCCInput,
    last4CCdigits,
    initStripeElement,
    updatePayment,
    fetchPaymentSources,
    isReady,
  };
};

export default usePayment;
