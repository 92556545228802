import { ref } from '@nuxtjs/composition-api';

export function useBusinessManagerScheduler(vueStore, tier: number) {
  const bmCalendlyUrl = ref<null | string>(null);

  vueStore
    .dispatch('calendly/getBusinessManagerCalendlyUrl', tier)
    .then((res) => {
      bmCalendlyUrl.value = res;
    });

  return {
    bmCalendlyUrl,
  };
}

export default useBusinessManagerScheduler;
