import useFilter from '@/modules/StaffManagement/hooks/useStaffFilter';
import {
  GroupedStaffMembers,
  StaffMember,
  StaffStates,
} from '@/modules/StaffManagement/models';
import {
  computed,
  ComputedRef,
  ref,
  Ref,
  shallowRef,
  useStore,
} from '@nuxtjs/composition-api';
import mapUsersToStaffMembers from '../utils/mapUsersToStaffMembers';
import { useAdvisorsStore } from '@/store/advisors';

const Fuse = require('fuse.js');

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  keys: ['profile.staffName', 'profile.jobTitle', 'roles'],
};

let store;
const searchQuery = ref<string | null>(null);

const initialized: Ref<Boolean> = ref(false);
const loading: Ref<Boolean> = ref(false);
const allEmployees = shallowRef([]);

// ***************************
// Utils
// ***************************

const fetchEmployees = async (force = false) => {
  if (store) {
    loading.value = true;
    allEmployees.value = await store.dispatch(
      'users/getSimplifiedMyEmployees',
      force
    );
    loading.value = false;
  }
};

/**
 * Takes a list of staff members and returns a "map"
 * of key/array pairs grouped by StaffStates
 */
const groupUsers = (userList: StaffMember[]): GroupedStaffMembers => {
  /**
   * Because don't know the order the users will be in,
   * we want to force the order of the map keys
   * to match the StaffStates enum
   */
  const mapped = {};
  Object.keys(StaffStates).forEach((key) => {
    mapped[StaffStates[key]] = [];
  });
  userList.forEach((user: StaffMember) => {
    mapped[user.staffState].push(user);
  });

  /**
   * Now we'll only include keys/states that actually
   * contain 1 or more users
   */
  const grouped = {};
  Object.keys(mapped).forEach((key) => {
    if (mapped[key].length) {
      grouped[key] = mapped[key];
    }
  });

  return grouped as GroupedStaffMembers;
};

const reloadEmployees = () => fetchEmployees(true);

const setupHook = async () => {
  initialized.value = true;
  store = useStore();
  await fetchEmployees();
};

// ***************************
// Computed
// ***************************
const primaryAdvisor: ComputedRef<any> = computed(
  () => useAdvisorsStore()?.primaryAdvisor
);

const currentUser: ComputedRef<any> = computed(
  () => store?.state?.current_user
);

const currentEmployee: ComputedRef<any> = computed(
  () => store?.state?.users?.current_employee
);

const allStaff: ComputedRef<StaffMember[]> = computed(() =>
  mapUsersToStaffMembers(allEmployees.value)
);

/**
 * list of staff members filtered, sorted, and optionally searched
 */
const filteredEmployees: ComputedRef<StaffMember[]> = computed(() => {
  const { filterAndSortStaff } = useFilter();
  let filterSortedStaff = filterAndSortStaff(allStaff.value);
  if (searchQuery.value) {
    filterSortedStaff = new Fuse(filterSortedStaff, fuseOptions).search(
      searchQuery.value
    );
  }

  return filterSortedStaff;
});

const groupedFilteredStaff: ComputedRef<GroupedStaffMembers> = computed(() =>
  groupUsers(filteredEmployees.value)
);

const noStaffFound = computed(
  () => !loading && !Object.keys(groupedFilteredStaff.value)
);

const hasEmployees = computed(() => !!allEmployees.value.length);

const staffCount = computed(() => allStaff.value?.length);

const nonInvitedStaff = computed(() =>
  allStaff.value?.filter(
    (member) => member.staffState === StaffStates.NOT_INVITED
  )
);
const hasNonInvitedStaff = computed(() =>
  allStaff.value?.some(
    (member) => member.staffState === StaffStates.NOT_INVITED
  )
);

const setSearchQuery = (value = '') => {
  const query = value;
  searchQuery.value = query;
};

const useStaffManagement = () => {
  if (!initialized.value) {
    setupHook();
  }

  return {
    allEmployees,
    allStaff,
    currentEmployee,
    currentUser,
    filteredEmployees,
    groupedFilteredStaff,
    hasEmployees,
    hasNonInvitedStaff,
    loading,
    nonInvitedStaff,
    noStaffFound,
    primaryAdvisor,
    searchQuery,
    staffCount,

    fetchEmployees,
    groupUsers,
    reloadEmployees,
    setSearchQuery,
  };
};

export default useStaffManagement;
