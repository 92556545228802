



































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { BaseIcon, BaseButton, TypeDisplay, TypeBody } from '@bambeehr/pollen';
import {
  ProductKey,
  useRequestPayrollCancellationMutation,
} from '@/gql/generated';
import { useApolloMutation } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import LoadingButtonLabel from '@/components/LoadingButtonLabel';
import bam from '@/lib/bam';
import usePayrollRootModal from '@/modules/payroll/components/PayrollRootModal/usePayrollRootModal';

export default defineComponent({
  name: 'PayrollCancellationModal',
  components: {
    BaseIcon,
    BaseButton,
    TypeBody,
    TypeDisplay,
    LoadingButtonLabel,
  },
  props: {},
  emits: ['close', 'next'],
  setup(_, { emit }) {
    const { payrollCancellationState } = usePayrollRootModal();
    const { companyId } = useCurrentCompany();
    const isCancelling = ref(false);

    const { onDone, mutate } = useApolloMutation(
      useRequestPayrollCancellationMutation,
      {
        pending: isCancelling,
      }
    );
    onDone(() => {
      bam.track('billing-page-payroll-cancellation-requested');
      isCancelling.value = false;
      payrollCancellationState.hasRequestedPayrollCancellation = true;
      emit('next');
    });

    const handleCancellation = async () => {
      isCancelling.value = true;
      mutate({
        data: {
          companyId: companyId.value,
        },
      });
    };

    return {
      handleCancellation,
      isCancelling,
      ProductKey,
    };
  },
});
