




import { onBeforeMount, onBeforeUnmount } from '@nuxtjs/composition-api';

// https://help.calendly.com/hc/en-us/articles/360019861794
// https://help.calendly.com/hc/en-us/articles/360020052833-Advanced-embed-options#3
enum CalendlyEventKind {
  SCHEDULED = 'calendly.event_scheduled',
  TIME_SELECTED = 'calendly.date_and_time_selected',
  VIEWED = 'calendly.event_type_viewed',
}

enum CalendlyCardEmits {
  SCHEDULED = 'event-scheduled',
  TIME_SELECTED = 'event-time-selected',
  VIEWED = 'event-viewed',
}
export default {
  props: {
    height: {
      type: Number,
      default: 550,
    },
    url: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const onCalendlyMessage = (e) => {
      let emitType;

      /* eslint-disable default-case */
      switch (e?.data?.event) {
        case CalendlyEventKind.SCHEDULED:
          emitType = CalendlyCardEmits.SCHEDULED;
          break;

        case CalendlyEventKind.TIME_SELECTED:
          emitType = CalendlyCardEmits.TIME_SELECTED;
          break;

        case CalendlyEventKind.VIEWED:
          emitType = CalendlyCardEmits.VIEWED;
          break;
      }

      if (emitType) {
        emit(emitType, e.data);
      }
    };

    onBeforeMount(() => {
      window.addEventListener('message', onCalendlyMessage);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('message', onCalendlyMessage);
    });

    return {
      onCalendlyMessage,
    };
  },
};
