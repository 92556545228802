













































import { defineComponent, ref, PropType } from '@nuxtjs/composition-api';
import {
  DrawerPanel,
  BaseButton,
  TypeDisplay,
  AnnularThrobber,
} from '@bambeehr/pollen';
import FaqItem from '@/components/FaqItem';
import Contentful from '@/services/Contentful';
import ContentfulRichText from '@/components/contentful/ContentfulRichText';

enum Emits {
  CLOSE = 'close',
}

interface Faq {
  question: string;
  answer: string;
}

const parseToFaqList = (content: any): Faq[] =>
  content.fields.list.map((item) => ({
    question: item.fields.title,
    answer: item.fields.body,
  }));

export default defineComponent({
  name: 'FqaDrawer',
  components: {
    DrawerPanel,
    FaqItem,
    TypeDisplay,
    BaseButton,
    ContentfulRichText,
    AnnularThrobber,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    entryId: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String,
      default: 'Frequently Asked Questions',
    },
  },
  emits: [Emits.CLOSE],
  setup(props) {
    const content = ref<Faq[]>([]);
    const isLoading = ref(true);

    // Get content
    (async () => {
      const res = await Contentful.getEntry(props.entryId);

      content.value = parseToFaqList(res);
      isLoading.value = false;
    })();

    return {
      content,
      Emits,
      isLoading,
    };
  },
});
