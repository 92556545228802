// Avoids type warning when importing generated types
// WARN in <file> "export 'Type' was not found in '@/<folder>'"
import { BulkMessageStaff as BulkMessageStaffGenerated } from '@/gql/generated';

export type BulkMessageStaff = BulkMessageStaffGenerated;
/**
 * the types of actions we can take to modify staff members
 */
export enum StaffActions {
  CAP = 'cap', // start a corrective action procedure
  DETAIL = 'detail', // view user details
  PROFILE = 'profile', // view user profile
  DOCUMENTS = 'documents', // view user documents
  MESSAGE = 'message', // send a message to a user or group of users
  ONBOARD = 'onboard', // start onboarding a user
  TERMINATE = 'terminate', // not like the T1000 type of termination...
}

/**
 * specific types of CAP's
 */
export enum CorrectiveActions {
  FINAL_WRITTEN_WARNING = 'final-written-warning', // uh-oh
  INCIDENT = 'incident', // document a prior incident
  PIP = 'pip', // create a new Performance Improvement Plan
  VERBAL_WARNING = 'verbal-warning', // document a prior verbal warning
  WRITTEN_WARNING = 'written-warning', // when they just won't listen
}

/**
 * the various states of the onboarding funnel employees travel through
 */
export enum StaffStates {
  NOT_INVITED = 'Not Invited',
  INVITED = 'Invited',
  ACTIVE = 'Active',
  FORMER = 'Former',
}

export enum FormerLabels {
  RESIGNED = 'Resigned',
  TERMINATED = 'Terminated',
}

export const StateDescriptions = Object.freeze({
  [StaffStates.NOT_INVITED]:
    'Staff have been added to your Bambee account, but have not yet received an invitation',
  [StaffStates.INVITED]:
    'Staff have been invited to Bambee, but have not logged in yet',
  [StaffStates.ACTIVE]: 'Staff have logged in and are active in Bambee',
});

/**
 * TODO: how do these vary from @/constants/users UserRoles ??
 */
export enum StaffRoles {
  ADMIN = 'Admin',
  APPROVER = 'Approver',
  MANAGER = 'Manager',
  EMPLOYEE = 'Employee',
  CONTRACTOR = 'Contractor',
}

/**
 * bare-bones data necessary to define a document
 */
export interface Document {
  id: string;
  signed: boolean;
}

/**
 * this is the format user data is stored in the Vuex store
 */
export interface EmployeeFlags {
  email_notification: boolean;
  onboard_request: boolean;
  onboarded: boolean;
  termination_request: boolean;
  terminated: boolean;
  viewedTaskCenter: boolean;
}
export interface Employee {
  _id: string;
  active: boolean | null;
  approver: boolean | null;
  avatar_url: string | null;
  documents: any[];
  firstName: string;
  lastName: string;
  manager: boolean | null;
  name: string;
  role: string;
  states: EmployeeFlags | null;
  title: string;
}

export enum MessageGroupType {
  CUSTOM = 'CUSTOM',
  INACTIVE = 'INACTIVE',
  SIGNATURE_REQUIRED = 'SIGNATURE_REQUIRED',
}

export enum MessageType {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
}
// @deprecated in favor of using a boolean for active
export enum StaffStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SIGNATURE_REQUIRED = 'SIGNATURE_REQUIRED',
}

/**
 * this is the shape we want user data to look like for the
 * Staff Management page
 */
export interface StaffMemberProfile {
  avatarUrl: string | null;
  staffName: string;
  jobTitle: string;
}
export interface StaffMember {
  id: string;
  contractor?: boolean;
  documents: Document[];
  flags: EmployeeFlags | null;
  onboardInviteSent: boolean;
  profile: StaffMemberProfile;
  roles: StaffRoles[];
  staffState: StaffStates;
  active: boolean;
  signatureRequired: boolean;
}

export type GroupedStaffMembers = Map<StaffStates, StaffMember[]>;

/**
 * list of staff members to send a message to
 */
export interface MessageRecipient {
  staffName: string;
  staffId: string;
  active: boolean;
  signatureRequired: boolean;
}

export type MessageRecipients = MessageRecipient[];

export enum BulkMessageScopes {
  ALL = 'all',
  NEVER_LOGGED_IN = 'never-logged-in',
  HAVENT_SIGNED = 'havent-signed',
}

export const BulkMessageScopesConfig = Object.freeze({
  [BulkMessageScopes.ALL]: {
    label: 'All Staff',
    messageTitleDecorator: '.',
    messageGroupType: MessageGroupType.CUSTOM,
  },
  [BulkMessageScopes.NEVER_LOGGED_IN]: {
    label: 'Never Logged In',
    messageTitleDecorator: ' who have never logged in to Bambee.',
    messageGroupType: MessageGroupType.INACTIVE,
  },
  [BulkMessageScopes.HAVENT_SIGNED]: {
    label: `Haven't Signed Policies`,
    messageTitleDecorator: ' who have not signed all their policies.',
    messageGroupType: MessageGroupType.SIGNATURE_REQUIRED,
  },
});

export default null;
