










































import { defineComponent } from '@nuxtjs/composition-api';
import { BaseBanner, BaseButton } from '@bambeehr/pollen';
import useAddressForm from '@/components/AddressForm/useAddressForm';

export default defineComponent({
  name: 'AddressFormMessaging',
  components: {
    BaseBanner,
    BaseButton,
  },
  setup() {
    return useAddressForm();
  },
});
