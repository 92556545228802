





















































import { defineComponent } from '@nuxtjs/composition-api';
import { BaseBanner, ModalDialog, TypeBody } from '@bambeehr/pollen';
import HexNum from '@/components/utility/HexNum/hex-num';
import DownloadTemplateButton from './DownloadTemplateButton';

export default defineComponent({
  name: 'ImportHoursIntroductionModal',
  components: {
    BaseBanner,
    DownloadTemplateButton,
    HexNum,
    ModalDialog,
    TypeBody,
  },
  setup(_, { emit }) {
    const handleCancel = () => {
      emit('cancel');
    };
    const handleUpload = () => {
      emit('upload');
    };

    return {
      buttonActions: {
        primary: {
          label: 'Upload Report',
          handler: handleUpload,
        },
        secondary: {
          label: 'Cancel',
          handler: handleCancel,
        },
      },
      handleCancel,
      instructions: [
        `Export a report (.csv, .tsv, .txt) from your time tracking software for the pay period. To help the import go smoothly, make sure there is one record per row and use a decimal value for hours.`,
        `Upload your report, and we'll automatically map your data for you to review.`,
        `When you’re ready to submit, continue to the last screen to import your earnings.`,
      ],
    };
  },
});
