import { defineStore } from 'pinia';
import { CANCELED } from '@/constants';
import { CallKinds } from '@/constants/calendly';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import maxBy from 'lodash/maxBy';

let callsFetchedAt;

export const CACHE_LENGTH_SECONDS = 30;

export const useCallsStore = defineStore('calls', {
  state: (): { calls: any[]; callsFetchedAt: null | Date } => ({
    calls: [],
    callsFetchedAt: null,
  }),
  getters: {
    ovCall(state) {
      return maxBy(
        state.calls.filter(
          (c) =>
            c.callKind === CallKinds.OVERVIEW_CALL && c.disposition !== CANCELED
        ),
        'createdAt'
      );
    },

    upgradeCall(state) {
      return maxBy(
        state.calls.filter((c) => c.callKind === CallKinds.ESSENTIAL_UPGRADE),
        'createdAt'
      );
    },
  },
  actions: {
    async fetch({ companyId }) {
      // (kevin.lee) This is a caching method to prevent us from spamming
      // our backend with fetch requests for calls.
      // If less than 30 seconds have passed since fetch, return calls from store.
      // Otherwise, make a network request to refresh calls.
      if (
        this?.calls?.length &&
        callsFetchedAt &&
        differenceInSeconds(callsFetchedAt, new Date()) < CACHE_LENGTH_SECONDS
      ) {
        return this.calls;
      }

      // Otherwise, make a network request to fetch calls
      const res = await this.$nuxt.$axios.get(
        `/v0/companies/v1/company/${companyId}/calls`
      );

      this.calls = res.data;
      callsFetchedAt = new Date();

      return res.data;
    },
  },
});
