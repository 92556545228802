










































































import { defineComponent, useStore, watch } from '@nuxtjs/composition-api';
import { AnnularThrobber, TypeBody } from '@bambeehr/pollen';
import Calendar from '@/components/Calendar';
import useCalendlyEvent from './useCalendlyEvent';

export default defineComponent({
  name: 'ScheduleBenefitsCallModal',
  components: {
    AnnularThrobber,
    TypeBody,
    Calendar,
  },
  props: {
    meetingId: {
      type: String,
      required: true,
    },
    showWho: {
      type: Boolean,
      default: true,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { isLoading, meetingInfo, callIsCancelled } = useCalendlyEvent(
      store,
      props.meetingId
    );

    watch(
      callIsCancelled,
      (isCancelled) => {
        if (isCancelled) {
          emit('canceled');
        }
      },
      { immediate: true }
    );

    return {
      isLoading,
      meetingInfo,
      callIsCancelled,
    };
  },
});
