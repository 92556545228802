





































import { defineComponent } from '@nuxtjs/composition-api';
import { TypeDisplay, TypeBody, BaseButton } from '@bambeehr/pollen';

export default defineComponent({
  name: 'Explainer',
  components: {
    TypeDisplay,
    TypeBody,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    reason: {
      type: String,
      required: true,
    },
    featureList: {
      type: Array,
      required: true,
    },
    primaryActionCTA: {
      type: String,
      required: true,
    },
  },
  emits: ['link-out', 'next'],
});
