enum EmployerOnboarding {
  COMPLETED = 'COMPLETED',
  BOR_COMPLETED = 'BOR_COMPLETED',
  ENROLLING = 'ENROLLING',
  SUBMITTED = 'SUBMITTED',
  BOR_SUBMITTED = 'BOR_SUBMITTED',
  PENDING_EMPLOYEE_ENROLLMENT = 'PENDING_EMPLOYEE_ENROLLMENT',
  FINISHED_EMPLOYEE_ENROLLMENT = 'FINISHED_EMPLOYEE_ENROLLMENT',
}

export default EmployerOnboarding;
