/**
 * Standardizes the Apollo query contract for predictable, App usage.
 * @param {Object} apolloClient The created apollo client for a particular module
 * @returns {Function} A function that calls Apollo's query and maps the result to App's specifications (https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query)
 */
const query = (apolloClient) => {
  /**
   * Generic function. Does a GraphQL query and standardizes the response
   * @param {Object} q GraphQL mutation to execute
   * @param {Object} variables GraphQL variables for the query
   * @param {String} resultKey The property to drill down on in the GraphQL result object
   * @param {Object} options Options to pass to the GraphQL mutation
   * @returns {Promise} A standardized response containing an object of the following: {errors, loading, data}
   */
  return async (q, variables, resultKey, options) => {
    const { data, errors, loading } = await apolloClient
      .query({
        query: q,
        variables,
        options,
      })
      .catch((err) => ({ errors: err }));

    return {
      errors,
      loading,
      data: data && data[resultKey],
    };
  };
};

export default query;
