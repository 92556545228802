import {
  AnswersWizardSectionData,
  AnswerWizardSection,
  SectionKey,
} from '@/modules/AnswersWizard/types/answersWizardSection';
import { computed, reactive } from '@nuxtjs/composition-api';
import useAnswersWizardAssets from './useAnswersWizardAssets';

// eslint-disable-next-line no-shadow
export enum Subsections {
  INTRO = 'Intro',
  STEPS = 'Steps',
  OUTRO = 'Outro',
}

/*
  Internal state
 */
interface AnswerWizardState {
  newUserSession: boolean;
  manuallySetSectionKey: string;
  manualSetSubsectionKey: Subsections;
}

const initialState: AnswerWizardState = Object.freeze({
  newUserSession: true,
  manuallySetSectionKey: '',
  manualSetSubsectionKey: Subsections.INTRO,
});

const state = reactive<AnswerWizardState>({ ...initialState });

/*
  Extendable, public state
 */
export interface AnswersWizardWritableState {
  sectionsLoading: boolean;
  sections: AnswerWizardSection[];
  sectionsData: {
    [key: string]: AnswersWizardSectionData;
  };
  activeSectionLoading: boolean;
  activeSectionData: AnswersWizardSectionData;
}

const emptySectionData: AnswersWizardSectionData = Object.freeze({
  sectionKey: '',
  title: '',
  status: '',
  description: '',
  introDescription: '',
  successOutroTitle: '',
});
const initialDataStore: AnswersWizardWritableState = Object.freeze({
  sectionsLoading: true,
  sections: [],
  sectionsData: {},
  activeSectionLoading: true,
  activeSectionData: emptySectionData,
});

const dataStore = reactive<AnswersWizardWritableState>({
  ...initialDataStore,
});

/*
  Sections
 */
const sections = computed<AnswerWizardSection[]>(() => dataStore.sections);

const activeSectionKey = computed<SectionKey>(() => {
  if (!state.newUserSession) {
    return state.manuallySetSectionKey;
  }

  const firstIncompleteSection = dataStore.sections.find(
    (section) => !section.completed
  );

  return firstIncompleteSection?.sectionKey || state.manuallySetSectionKey;
});

const activeSectionData = computed<AnswersWizardSectionData | undefined>(
  () => dataStore.sectionsData[activeSectionKey.value]
);

const totalSections = computed<number>(() => dataStore?.sections?.length);

const activeSectionIndex = computed<number>(() =>
  dataStore.sections.findIndex(
    (section) => section.sectionKey === activeSectionKey.value
  )
);

/**
 * undefined -> not loaded
 * null -> the last one, complete wizard
 * AnswerWizardSection -> next section
 */
const nextSection = computed<undefined | AnswerWizardSection | null>(() => {
  if (!dataStore.sections.length) {
    return undefined;
  }

  const nextIncomplete = dataStore.sections
    .slice(activeSectionIndex.value + 1)
    .find((section) => !section.completed);

  return nextIncomplete || null;
});

/**
 * undefined -> ignore the state
 * null -> complete wizard
 * SectionKey -> next sectino key
 */
const nextSectionKey = computed<undefined | SectionKey | null>(() => {
  return nextSection.value === null ? null : nextSection.value?.sectionKey;
});

/*
  Subsections (within active section)
 */
const activeSubsection = computed<Subsections>(() => {
  if (!state.newUserSession) {
    return state.manualSetSubsectionKey;
  }

  if (Object.keys(activeSectionData.value?.survey?.answers || {}).length) {
    return Subsections.STEPS;
  }

  return Subsections.INTRO;
});

const nextSubsection = (subsection: Subsections) => {
  switch (subsection) {
    case Subsections.INTRO:
      return Subsections.STEPS;
    case Subsections.STEPS:
      return Subsections.OUTRO;
    case Subsections.OUTRO:
    default:
      return Subsections.INTRO;
  }
};

/*
  User Session

  During one session, we force the section/step rather than derive it from data
  This behavior may be changed when data state will be instantly synced with BE

  Session should start with the first active user action
  (e.g., completion of a step / subsection)
 */
const startUserSession = () => {
  if (!state.manuallySetSectionKey) {
    state.manuallySetSectionKey = activeSectionKey.value;
  }

  state.newUserSession = false;
};
const completeUserSession = () => {
  state.newUserSession = true;
};

/*
  Manual Controls
 */
// TODO: reevaluate moving everything to instance
const reinit = () => {
  Object.assign(state, initialState);
  Object.assign(dataStore, initialDataStore); // TODO: deep
};

const completeSubsection = (completedSubsection: Subsections) => {
  startUserSession();

  state.manualSetSubsectionKey = nextSubsection(completedSubsection);
};

const completeSection = () => {
  if (typeof nextSectionKey.value === 'string') {
    state.manuallySetSectionKey = nextSectionKey.value;
  }
};

/*
  Wizard Loading States
 */
const isWizardLoading = computed<boolean>(
  () => dataStore.sectionsLoading && !Object.keys(dataStore.sectionsData).length
);

const isSectionLoading = computed<boolean>(
  () => isWizardLoading.value || !activeSectionData.value
);

const useAnswersWizard = () => {
  const { getWizardImages } = useAnswersWizardAssets();

  return {
    dataStore,
    reinit,
    // Loaders
    isWizardLoading,
    isSectionLoading,
    // Sections
    sections,
    activeSectionData,
    activeSectionKey,
    nextSectionKey,
    nextSection,
    activeSubsection,
    completeSubsection,
    completeSection,
    totalSections,
    activeSectionIndex,
    // Session
    startUserSession,
    completeUserSession,
    getWizardImages,
  };
};

export default useAnswersWizard;
