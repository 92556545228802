











































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { BaseIcon, BaseLink, TypeBody, TypeDisplay } from '@bambeehr/pollen';

export default defineComponent({
  name: 'PaySettings',
  components: {
    BaseIcon,
    BaseLink,
    TypeBody,
    TypeDisplay,
  },
  props: {
    title: {
      type: String,
      default: 'Payment Information',
    },
    infoRows: {
      type: Array as PropType<{ label: string; value: string }[]>,
      default: () => [],
    },
    link: {
      type: Object as PropType<{ label: string }>,
      default: () => ({}),
    },
  },
});
