import useCurrentCompany from '@/hooks/useCurrentCompany/useCurrentCompany';
import hadHrPracticeEnabledEver from '@/modules/HrCompliance/helpers/accessControl/hadHrPracticeEnabledEver';
import {
  Milestone,
  Milestones,
  MilestoneStatuses,
  MilestoneTags,
} from '@/modules/TaskCenter/types/Milestones';
import { computed, useStore, ref } from '@nuxtjs/composition-api';
import keyBy from 'lodash/keyBy';
import useCompanyStore from '@/store/companies';

const { companySettings } = useCurrentCompany();

const isBciToHRPracticeConvert = companySettings.value.bciToHRPracticeMigrated;
const BciToHrPracticeMilestoneStatus = [
  MilestoneStatuses.COMPLETED,
  MilestoneStatuses.SKIPPED,
];
const MilestoneStatusToFilter = computed<MilestoneStatuses[]>(() =>
  isBciToHRPracticeConvert
    ? BciToHrPracticeMilestoneStatus
    : [MilestoneStatuses.COMPLETED]
);

const getMilestones = ref();
const milestones = computed<Milestone[]>(
  () => getMilestones.value?.(MilestoneTags.HR_PRACTICE) || []
);

const milestonesByKey = computed(() => keyBy(milestones.value, 'milestone'));

const incompleteMilestones = computed<Milestone[]>(() =>
  (milestones.value || []).filter(
    (milestone: Milestone) =>
      !MilestoneStatusToFilter.value.includes(milestone.status)
  )
);

const incompleteMilestonesByKey = computed(() =>
  keyBy(incompleteMilestones.value, 'milestone')
);

const milestonesLeft = computed(() => incompleteMilestones.value.length);

const HrPracticeCompleted = computed(
  () =>
    milestonesByKey.value[Milestones.HR_PRACTICE]?.status ===
    MilestoneStatuses.COMPLETED
);

let initialized = false;
const useHrComplianceMilestones = () => {
  const store = useStore();

  if (!initialized) {
    getMilestones.value = useCompanyStore().onboardingMilestonesByTagV2();

    initialized = true;
  }

  const completeAddStaffMilestone = async () => {
    // Don't complete milestone if user has not activated HR Practice
    const enabledHrPractice = await hadHrPracticeEnabledEver(store);
    if (!enabledHrPractice) {
      return null;
    }

    return useCompanyStore().updateOnboardingMilestone({
      milestone: Milestones.ADD_STAFF,
      completed: true,
    });
  };

  return {
    HrPracticeCompleted,
    // Milestones
    milestonesByKey,
    incompleteMilestonesByKey,
    milestonesLeft,
    milestones,
    completeAddStaffMilestone,
  };
};

export default useHrComplianceMilestones;
