/* eslint-disable import/prefer-default-export */
import { CompanyStatuses } from '@/constants/companies';
import { CookieNames } from '@/constants/Cookies';
import { getModuleKeys } from '@/constants/Store';
import { UserRoles } from '@/constants/users';
import { deleteCookie, setCookie } from '@/lib/cookie-helpers';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import get from 'lodash/get';
import { useAdvisorsStore } from '@/store/advisors';
import { defineStore } from 'pinia';
import { useDefaultStore } from '@/store';
import useCompanyStore from '@/store/companies';

const { Actions, Getters } = getModuleKeys('auth');

// Lock app if customer has been delinquent for over this THRESHOLD number of days.
const LONG_STANDING_DELINQUENCY_THRESHOLD = 60;

export const actions = {
  // TODO(jon.jandoc): BAM-1661 need better handling for this event. Need store
  // clear mutations, so reset not required.
  async [Actions.SWITCH_COMPANY]({ rootGetters }, companyId) {
    if (rootGetters.companyId === companyId) {
      return;
    }
    const res = await this.$axios.post(
      `/auth/v4/switch/company`,
      {
        companyId,
      },
      {
        withCredentials: true,
      }
    );
    if (res.status === 201) {
      const { nonce } = res.data;
      deleteCookie(CookieNames.ACCESS_TOKEN);
      deleteCookie(CookieNames.REFRESH_TOKEN);
      const thisUrl = new URL(window.location);
      const redirectUrl = encodeURI(
        `${thisUrl.protocol}//${thisUrl.host}?switch_token=${nonce}`
      );
      window.location.replace(
        `https://bambee.com/switch.html?env=${process.env.APP_ENV}&app=app&redirectUrl=${redirectUrl}`
      );
    }
  },

  async [Actions.LOGIN]({ dispatch }, { email, password, nonce, otpCode }) {
    deleteCookie(CookieNames.ACCESS_TOKEN);
    deleteCookie(CookieNames.REFRESH_TOKEN);
    let authPayload;

    const loginPayload = {
      email,
      password,
    };

    const mfaLoginPayload = {
      email,
      password,
      mfa: {
        nonce,
        response: otpCode,
        rememberDevice: false,
      },
    };

    if (nonce?.length > 1 && otpCode?.length > 1) {
      authPayload = mfaLoginPayload;
    } else {
      authPayload = loginPayload;
    }

    const loginResponse = await this.$axios.post(
      `/auth/v3/login`,
      authPayload,
      {
        baseURL: `${process.env.PUBLICAPI_URL}`,
        withCredentials: true,
      }
    );

    const { accessToken, refreshToken } = loginResponse.data;
    setCookie(CookieNames.ACCESS_TOKEN, accessToken);
    setCookie(CookieNames.REFRESH_TOKEN, refreshToken);

    const advisor = useAdvisorsStore();

    const self = await dispatch('refreshSelf', null, { root: true });

    advisor.getAdvisors(self._company._id);

    dispatch('tickets/getAll', null, { root: true });

    useCompanyStore().getMyCompanies();

    dispatch('getHRExpectations', null, { root: true });
    dispatch('pricePlans/fetch', {}, { root: true });

    return self;
  },

  async [Actions.SSO_LOGIN](
    { dispatch },
    { email, password, nonce, otpCode, clientId, redirect }
  ) {
    deleteCookie(CookieNames.ACCESS_TOKEN);
    deleteCookie(CookieNames.REFRESH_TOKEN);
    let authPayload;

    const loginPayload = {
      email,
      password,
      redirect,
    };

    const mfaLoginPayload = {
      email,
      password,
      redirect,
      mfa: {
        nonce,
        response: otpCode,
        rememberDevice: false,
      },
    };

    if (nonce?.length > 1 && otpCode?.length > 1) {
      authPayload = mfaLoginPayload;
    } else {
      authPayload = loginPayload;
    }

    const loginResponse = await this.$axios.post(
      `/auth/v3/sso/login/${clientId}`,
      authPayload,
      {
        baseURL: `${process.env.PUBLICAPI_URL}`,
        withCredentials: true,
      }
    );

    const { redirect: url } = loginResponse.data;

    return url;
  },
};

export const getters = {
  [Getters.IS_COMPANY_ADMIN]: (state, getters) =>
    [UserRoles.ADMIN, UserRoles.USER].includes(getters[Getters.ROLE]),
  [Getters.IS_EMPLOYEE]: (state, getters) =>
    getters[Getters.ROLE] === UserRoles.EMPLOYEE,
  [Getters.IS_MANAGER]: (state, getters, rootState) =>
    getters[Getters.IS_COMPANY_ADMIN] ||
    get(rootState.current_user, 'permissions.manager'),
  [Getters.IS_OWNER]: (state, getters, rootState) => {
    const currentUserId = get(rootState.current_user, '_id', 'noUserId');

    return (
      get(rootState.current_user, '_company._owner') === currentUserId ||
      get(rootState.current_user, '_company._owner._id') === currentUserId
    );
  },
  [Getters.IS_POLICY_APPROVER]: (state, getters, rootState) =>
    getters[Getters.IS_COMPANY_ADMIN] ||
    get(rootState.current_user, 'permissions.approver'),
  [Getters.IS_SUBSCRIBED]: (state, getters, rootState) =>
    [CompanyStatuses.PAYING, CompanyStatuses.TRIAL].includes(
      get(rootState.current_user, '_company.status')
    ),
  [Getters.IS_DELINQUENT]: (state, getters, rootState) =>
    get(rootState.current_user, '_company.stripe.delinquent', false),
  [Getters.DELINQUENCY_DAYS]: (state, getters, rootState) => {
    const delinquentSince = get(
      rootState.current_user,
      '_company.stripe.original_payment_date'
    );
    if (delinquentSince == null) {
      return 0;
    }
    const delinquencyDays = differenceInDays(
      new Date(),
      parseISO(delinquentSince)
    );

    return delinquencyDays;
  },
  [Getters.IS_LONG_STANDING_DELINQUENT]: (state, getters) => {
    const longDelinquent =
      getters[Getters.IS_DELINQUENT] &&
      getters[Getters.DELINQUENCY_DAYS] >= LONG_STANDING_DELINQUENCY_THRESHOLD;

    return longDelinquent;
  },
  [Getters.ROLE]: (state, getters, rootState) =>
    get(rootState.current_user, 'role', null),
  [Getters.COMPANY_ID]: (state, getters, rootState) =>
    get(rootState, 'current_user._company._id', null),
};

export const useAuthStore = defineStore('auth', {
  state: () => ({}),
  actions: {},
  getters: {
    isCompanyAdmin() {
      return [UserRoles.ADMIN, UserRoles.USER].includes(this.role);
    },
    role() {
      const defaultStore = useDefaultStore();

      return defaultStore.currentUser?.role;
    },
    companyId() {
      const defaultStore = useDefaultStore();

      return defaultStore.currentUser?._company?._id;
    },
  },
});
