<template>
  <div class="content-block" v-html="htmlString" />
</template>

<script>
import interpolateContent from '@/utils/interpolateContent';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { richTextOptions } from './contentfulOptions';

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
    contentMap: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    htmlString() {
      return interpolateContent(
        documentToHtmlString(this.content, richTextOptions),
        this.contentMap
      );
    },
  },
};
</script>
