









import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import MarketingBanner from '@/components/MarketingBanner/MarketingBanner.vue';

import { BaseButton } from '@bambeehr/pollen';
import useServiceUpdates, {
  ServiceUpdateEventTracking,
} from '@/hooks/useServiceUpdates/useServiceUpdates';

export default defineComponent({
  name: 'ServiceUpdateBanner',
  components: {
    MarketingBanner,
    BaseButton,
  },
  setup() {
    const { openModal, logEvent } = useServiceUpdates();

    onMounted(() => {
      logEvent(ServiceUpdateEventTracking.UPGRADE_NOTIFICATION_SHOWN);
    });

    return {
      openModal,
    };
  },
});
