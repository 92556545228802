import gql from 'graphql-tag';

/*
  Please use schema.graphql (generated) for const naming:
  <Query_Name>_<Query_Type>
 */

export const PRACTICE_FRAGMENT = gql`
  fragment PracticeParts on Practice {
    id
    status
    setupProgress
    state

    title
    shortName
    shortDescription
    description
    introDescription
    iconUrl
    illustrationUrl
    outroIllustrationUrl
    successOutroTitle
  }
`;

export const SURVEY_FRAGMENT = gql`
  fragment SurveyParts on Survey {
    id
    type
    answers
    completedAt
    questions
  }
`;

export const LIST_PRACTICE_QUERY = gql`
  query ListPractice($companyId: String!) {
    listPractice(companyId: $companyId) {
      ...PracticeParts
    }
  }
  ${PRACTICE_FRAGMENT}
`;

export const GET_PRACTICE_QUERY = gql`
  query getPractice($input: GetPracticeInput!) {
    getPractice(input: $input) {
      ...PracticeParts

      survey {
        ...SurveyParts
      }
    }
  }
  ${PRACTICE_FRAGMENT}
  ${SURVEY_FRAGMENT}
`;

export const GET_HR_SCORE_QUERY = gql`
  query getHrScore($companyId: String!) {
    getHrScore(companyId: $companyId)
  }
`;

export const GET_SURVEY_QUERY = gql`
  query companySurvey($data: GetCompanySurveyInput!) {
    companySurvey(data: $data) {
      ...SurveyParts
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const GET_COMPANY_SETTINGS = gql`
  query GetCompanySettings($data: String!) {
    getCompanySettings(companyId: $data) {
      id
      key
      type
      value
    }
  }
`;

export const SAVE_COMPANY_ANSWER_MUTATE = gql`
  mutation saveCompanyAnswer($data: SaveCompanyAnswerInput!) {
    saveCompanyAnswer(data: $data) {
      ...SurveyParts
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const COMPLETE_COMPANY_SURVEY_MUTATE = gql`
  mutation completeCompanySurvey($data: CompleteCompanySurveyInput!) {
    completeCompanySurvey(data: $data) {
      ...SurveyParts
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const COMPLETE_COMPANY_PRACTICE_MUTATE = gql`
  mutation completeCompanyPractice($data: CompletePracticeInput!) {
    completeCompanyPractice(data: $data) {
      ...PracticeParts

      survey {
        ...SurveyParts
      }
    }
  }

  ${PRACTICE_FRAGMENT}
  ${SURVEY_FRAGMENT}
`;

export const BULK_MESSAGE_STAFF = gql`
  mutation BulkMessageStaff($data: BulkMessageStaffInput!) {
    bulkMessageStaff(data: $data) {
      errors {
        errorMsg
        staffId
      }
      successfulStaffIds
    }
  }
`;
