import useJobDescriptions from '@/modules/JobDescriptions/hooks/useJobDescriptions';
import { computed, ref, watch } from '@nuxtjs/composition-api';
import isCompanyAdmin from '@/helpers/accessControl/isCompanyAdmin';
import useCurrentUser from '@/hooks/useCurrentUser';
import useCreateJDFeatureFlag from '@/modules/JobDescriptions/hooks/CreateJobDescriptionForm/useCreateJDFeatureFlag';

let init;
const hasInactiveJobDescriptions = ref(false);

const setupHook = () => {
  const { inactiveJobDescriptions } = useJobDescriptions();

  watch(
    inactiveJobDescriptions,
    (val) => {
      hasInactiveJobDescriptions.value = !!val.length;
    },
    {
      immediate: true,
    }
  );

  init = true;
};

const useJobDescriptionGroup = () => {
  const { currentUser } = useCurrentUser();
  const { isCreateJobDescriptionsEnabled } = useCreateJDFeatureFlag();
  const isAdmin = computed(() => isCompanyAdmin(currentUser.value));

  if (!init && isAdmin.value) {
    setupHook();
  }

  const jobDescriptionActionItemCount = computed(() => {
    let count = 0;

    if (
      isAdmin.value &&
      isCreateJobDescriptionsEnabled.value &&
      hasInactiveJobDescriptions.value
    ) {
      count += 1;
    }

    return count;
  });

  return {
    isAdmin,
    jobDescriptionActionItemCount,
  };
};

export default useJobDescriptionGroup;
