const Routes = Object.freeze({
  REGISTRATION: '/get-training',
  REGISTRATION_CONFIRMATION: '/get-training/confirmation',
  ER_PORTAL: '/training',
  EE_PORTAL: '/my-training',
  ER_OVERVIEW: '/training/overview',
  ER_CATALOG: '/training/catalog',
  EE_CATALOG: '/my-training/catalog',
});

export default Routes;
