<template>
  <div>
    <AiModal
      v-if="showAIUpsellModal && !isAIUpsellLoading"
      @close="closeAIUpsellModal"
      @primary="handleOptIn"
      @secondary="sendToMarketing"
    />

    <NewBambeeModal
      v-if="isAIUpsellLoading"
      header="We're getting you set up"
      :list="initList"
      status-text="Activating"
    />
    <AILaunchPadModal
      v-if="showLaunchPad"
      @exit="handleExitFromLaunchPad"
      @start-chat="handleStartChat"
      @close="showLaunchPad = false"
    />
    <NewBambeeModal
      v-if="isLoading"
      header="Welcome to HR Intelligence"
      status-text="Starting Up"
      :max-visible="5"
    />
  </div>
</template>

<script>
import AiModal from '@/components/AiModal.vue';
import NewBambeeModal from '@/components/NewBambeeModal.vue';
import AILaunchPadModal from '@/components/AILaunchPadModal.vue';
import useAIUpsell from '@/modules/AI/hooks/useAIUpsell';
import useAIConversation from '@/modules/AI/hooks/useAIConversation';

import { ref, watch, useRouter } from '@nuxtjs/composition-api';

export default {
  name: 'AIUpsellModals',
  components: {
    AiModal,
    NewBambeeModal,
    AILaunchPadModal,
  },
  setup() {
    const router = useRouter();
    const {
      showAIUpsellModal,

      closeAIUpsellModal,
      optInAi,
      isLoading: isAIUpsellLoading,
      isAIOptedIn,
    } = useAIUpsell();
    const { handleSendMessage, isLoading } = useAIConversation();

    const showLaunchPad = ref(false);

    watch([isAIUpsellLoading, isAIOptedIn], ([loading, isOpted]) => {
      if (isOpted) {
        showAIUpsellModal.value = false;
      }

      if (!loading && isOpted) {
        showLaunchPad.value = true;
      }
    });

    const handleOptIn = () => {
      optInAi();
    };

    const sendToMarketing = () => {
      showAIUpsellModal.value = false;
      router.push('/hr-intelligence');
    };

    const handleExitFromLaunchPad = () => {
      showLaunchPad.value = false;
      router.push('/dashboard');
    };

    const handleStartChat = (asst, prompt) => {
      handleSendMessage(prompt, asst);
      showLaunchPad.value = false;
    };

    return {
      showAIUpsellModal,
      isAIUpsellLoading,
      closeAIUpsellModal,
      handleOptIn,
      showLaunchPad,
      handleExitFromLaunchPad,
      handleStartChat,
      isLoading,
      sendToMarketing,
    };
  },
};
</script>
