<template>
  <div>
    <BaseLink class="ledger-modal-btn" @click="toggleModal">
      <TypeBody tag="span" variant="caption" weight="strong">
        {{ detailLinkText }}
      </TypeBody>
    </BaseLink>

    <portal v-if="showModal" to="layout">
      <ModalDialog
        action-alignment="center"
        :actions="modalActions"
        overlay="dark"
        :overlay-close="true"
        size="medium"
        @close="toggleModal"
        @primary="toggleModal"
      >
        <template #header>
          {{ modalTitle }}
        </template>
        <LedgerList
          :list="detailsList"
          :total-label="details.totalLabel"
          :total="details.total"
        />
      </ModalDialog>
    </portal>
  </div>
</template>

<script>
import { ref, computed } from '@nuxtjs/composition-api';
import { TypeBody, ModalDialog, BaseLink } from '@bambeehr/pollen';
import LedgerList from '@/modules/payroll/components/LedgerList/LedgerList';
import shortid from 'shortid';

export default {
  name: 'LedgerModalTrigger',
  components: {
    TypeBody,
    ModalDialog,
    LedgerList,
    BaseLink,
  },
  props: {
    /**
     * Details object contains:
     * @prop {{description: String, amount: Number}[]} list (required) Arr of ledger items
     * @prop {Number} total For total row in ledger list
     * @prop {String} totalLabel Label for total row in ledger list
     * @prop {String} title Modal title
     * */
    details: {
      type: Object,
      required: true,
      validator(val) {
        const requiredKeys = ['list'];
        const valKeys = Object.keys(val);

        return requiredKeys.every((k) => valKeys.includes(k));
      },
    },
    detailLinkText: {
      type: String,
      required: false,
      default: 'Details',
    },
  },
  setup(props) {
    const showModal = ref(false);
    const modalTitle = computed(() => props.details.title || 'Details');
    const detailsList = computed(() => {
      return props.details.list.map((i) => ({
        ...i,
        id: shortid.generate(),
      }));
    });
    const modalActions = { primary: { label: 'Close' } };

    function toggleModal() {
      showModal.value = !showModal.value;
    }

    return {
      toggleModal,
      showModal,
      modalTitle,
      detailsList,
      modalActions,
    };
  },
};
</script>
