






































import AddStaffForm from '@/components/AddStaffForm';
import { BaseButton, TypeDisplay, TypeBody } from '@bambeehr/pollen';
import { ref } from '@nuxtjs/composition-api';
import LoadingButtonLabel from '@/components/LoadingButtonLabel';
import useNotifications from '@bambeehr/use-notifications';

export default {
  components: {
    AddStaffForm,
    BaseButton,
    LoadingButtonLabel,
    TypeDisplay,
    TypeBody,
  },
  setup(_, { emit }) {
    const isSaving = ref(false);

    const toggleLoadingStateOff = (showError = false) => {
      const { addError } = useNotifications();
      isSaving.value = false;

      if (showError) {
        addError(
          'There were errors in your form. Please check your inputs and try again.'
        );
      }
    };

    const setSaveStatus = (status: boolean) => {
      const saveBtn = document.getElementById('save-staff-btn');
      if (saveBtn) {
        saveBtn.click();
        isSaving.value = true;
      }
    };

    const handleSuccess = (userInfo) => {
      emit('next', userInfo);
      toggleLoadingStateOff();
    };

    return {
      isSaving,
      setSaveStatus,
      handleSuccess,
      toggleLoadingStateOff,
    };
  },
};
