














import { defineComponent } from '@nuxtjs/composition-api';
import HexLoader from '@/components/HexLoader/HexLoader.vue';

export default defineComponent({
  name: 'LoadingButtonLabel',
  components: {
    HexLoader,
  },
  props: {
    /**
     * toggles the button loading state on/off
     */
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
  },
});
