import WizardAssetSlugs from '@/modules/AnswersWizard/constants/WizardAssetSlugs';
import Contentful from '@/services/Contentful';
import { reactive } from '@nuxtjs/composition-api';

const state = reactive({
  hrPracticeWizardImages: {},
});

async function fetchContentfulAssets() {
  if (Object.keys(state.hrPracticeWizardImages).length) {
    return;
  }

  const finalWizardOutro = await Contentful.getAsset(
    WizardAssetSlugs.HR_WIZARD_FINAL_OUTRO_IMAGE
  );

  state.hrPracticeWizardImages = {
    [WizardAssetSlugs.HR_WIZARD_FINAL_OUTRO_IMAGE]: finalWizardOutro,
  };
}

const getWizardImages = (key: string) =>
  state.hrPracticeWizardImages[key]?.fields?.file?.url || '';

const useAnswersWizardAssets = () => {
  fetchContentfulAssets();

  return {
    fetchContentfulAssets,
    getWizardImages,
  };
};

export default useAnswersWizardAssets;
