








































import {
  computed,
  defineComponent,
  ref,
  PropType,
} from '@nuxtjs/composition-api';
import {
  StaffPayrollStatus,
  getPayrollStatus,
} from '@/modules/payroll/utils/getStaffItemPayrollStatus';
import { useGetWorkerStatusQuery, Employee, Contractor } from '@/gql/generated';
import { TypeBody, CheckboxInput } from '@bambeehr/pollen';
import { formatDate } from '@/utils/date';
import { convertToLabel } from '@/modules/payroll/utils/normalizeRemainingSteps';
import { PaymentMethod } from '@/modules/payroll/constants/payroll';
import { useApolloQuery } from '@/gql/apolloWrapper';

import PayrollStatusDot from '@/modules/payroll/components/PayrollStatusDot/PayrollStatusDot.vue';

const getWorkerFromResult = ({
  getEmployee,
  getContractor,
}: {
  getEmployee: Employee | null;
  getContractor: Contractor | null;
}) => {
  if (!getEmployee && !getContractor) {
    return null;
  }

  return {
    ...(getContractor || getEmployee),
  };
};

const getPaymentMethodLabel = (paymentMethodPreference: string = '') =>
  paymentMethodPreference === PaymentMethod.DIRECT_DEPOSIT.value
    ? PaymentMethod.DIRECT_DEPOSIT.label
    : PaymentMethod.MANUAL.label;

export default defineComponent({
  name: 'AddStaffPayrollStatus',
  components: {
    TypeBody,
    CheckboxInput,
    PayrollStatusDot,
  },
  props: {
    workerId: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props) {
    const worker = ref();

    useApolloQuery(
      useGetWorkerStatusQuery,
      { id: props.workerId },
      { data: worker },
      {
        handleErrors: false,
        placeholderPick: getWorkerFromResult,
      }
    );

    const onPayrollDate = computed(() =>
      worker.value.createdAt
        ? formatDate(new Date(worker.value?.createdAt), '')
        : ''
    );

    const paymentMethod = computed(() =>
      getPaymentMethodLabel(worker.value?.paymentMethodPreference)
    );

    const missingInformation = computed(() =>
      convertToLabel(worker.value?.checkOnboarding?.remainingSteps || [])
    );

    const staffPayrollStatus = computed(() =>
      getPayrollStatus({
        ...worker.value,
        includesPayrollInfo: true,
      })
    );
    const onPayroll = computed(
      () => staffPayrollStatus.value === StaffPayrollStatus.ON_PAYROLL
    );

    return {
      missingInformation,
      onPayrollDate,
      paymentMethod,
      staffPayrollStatus,
      onPayroll,
    };
  },
});
