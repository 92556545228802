/**
 * Creates a redirect string to be appended to urls that have been redirected to the login screen */
function toRedirString(path = '', queryParams = {}) {
  const cleanParams: string[] = [];
  Object.keys(queryParams).forEach((key) => {
    if (key?.length && queryParams[key]?.length && key !== 'redir') {
      cleanParams.push(`${key}=${queryParams[key]}`);
    }
  });
  const paramsStr = cleanParams.join('%26');
  let redirStr = '';
  if (path.length) {
    redirStr = `${path}`;
  }
  if (paramsStr.length) {
    redirStr = `${redirStr}?${paramsStr}`;
  }

  return redirStr;
}

export default toRedirString;
