




















import { computed, defineComponent, useRouter } from '@nuxtjs/composition-api';
import { AnnularThrobber, ModalDialog } from '@bambeehr/pollen';

import useOnLoginTasks from '@/modules/OnLoginTasks/hooks/useOnLoginTasks';
import {
  PreviewModalComponents,
  UserStateKeys,
} from '@/modules/OnLoginTasks/constants';

export default defineComponent({
  name: 'RootModal',
  components: {
    AnnularThrobber,
    ModalDialog,
  },
  setup() {
    const router = useRouter();
    const {
      closeRootModal,
      componentList,
      isFetching,
      setReportCardPreviewModal,
      toggleUserStateOff,
    } = useOnLoginTasks();

    const currentComponent = computed(() => componentList.value[0]);

    const handleClose = (previewModal: PreviewModalComponents) => {
      switch (previewModal) {
        case PreviewModalComponents.HR_PRACTICE_PREVIEW_MODAL:
          toggleUserStateOff({
            key: UserStateKeys[previewModal],
            value: true,
          });
          break;
        case PreviewModalComponents.REPORT_CARD_PREVIEW_MODAL:
          toggleUserStateOff({
            key: UserStateKeys[previewModal],
            value: true,
          });
          setReportCardPreviewModal(false);
          break;
        default:
          closeRootModal();
      }
    };

    const handleRedirect = (toPath: string) => {
      console.log('toPath: ', toPath);
      router.push(toPath);
    };

    return {
      currentComponent,
      handleClose,
      handleRedirect,
      isFetching,

      componentList,
    };
  },
});
