








































































import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import { TypeBody, TypeDisplay, BaseCard } from '@bambeehr/pollen';
import { PulseVariant } from '@/components/AnimatedBullet/AnimatedBullet.vue';
import WizardInfoLayout, {
  CheckType,
} from '@/modules/BambeePlus/components/WizardInfoLayout';
import FaqBlock from '@/components/FaqBlock';
import ResourceLinkCard from '@/components/ResourceLinkCard';

const defaultItem = {
  isComplete: true,
  bullet: 'bullet',
  shouldPulse: false,
  variant: PulseVariant.SECONDARY,
};

const newTab = (link) => {
  window.open(link, '_blank');
};

const list = [
  {
    ...defaultItem,
    text: 'Manage your schedule',
  },
  {
    ...defaultItem,
    text: 'Clock in and out',
  },
  {
    ...defaultItem,
    text: 'Request time off',
  },
];

export default defineComponent({
  name: 'TimeAndAttendance',
  components: {
    BaseCard,
    WizardInfoLayout,
    TypeBody,
    TypeDisplay,
    FaqBlock,
    ResourceLinkCard,
  },
  setup() {
    const router = useRouter();
    const cards = [
      {
        img: '/images/time-and-attendance/rocket.svg',
        title: 'Guide: Getting started in Deputy',
        description: 'A quickstart guide for employees',
        action() {
          newTab(
            `https://help.deputy.com/hc/en-au/articles/4614717563919-Getting-started-as-an-Employee-in-Deputy`
          );
        },
      },
      {
        img: '/images/time-and-attendance/tablet.svg',
        title: 'Having issues with the Deputy app?',
        description: 'Get help from Deputy’s Help Center',
        action() {
          newTab(
            `https://help.deputy.com/hc/en-au/categories/4614208952975-Deputy-mobile-apps`
          );
        },
      },
      {
        img: '/images/time-and-attendance/paper.svg',
        title: 'Ask a time and attendance question',
        description: 'Get help from Bambees',
        action() {
          router.push(`/requests/support-website-app-help`);
        },
      },
    ];

    return {
      CheckType,
      list,
      cards,
    };
  },
});
