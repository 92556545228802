/**
 * Helper that sanitizes a path string to not have a trailing slash
 * This is particularly important when comparing paths to one another.
 * Often browsers will add a trailing slash when a route is hit directly
 * @param {String} path The URL path
 * @returns {String} String without the trailing slash
 */
export const removeTrailingSlash = (path = '') => {
  return path.replace(/\/\s*$/, '');
};

export default null;
