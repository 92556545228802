import Contentful from '@/services/Contentful';

const content = new Map();

const fetchContent = async (entryId: string, force: boolean = false) => {
    // Pull from cache first
    if (content.has(entryId) && !force) {
        return content.get(entryId);
    } else {    
        const response = await Contentful.getEntry(entryId);
        content.set(entryId, response.fields);
    }


  return content.get(entryId);
};

const useContentful = () => {
    return {
        fetchContent,
        getCache: () => new Map(content),
    };
};

export default useContentful;
