





















































































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { PaySchedule } from '@/modules/payroll/types/company';

import { TypeBody } from '@bambeehr/pollen';

export default defineComponent({
  name: 'PayScheduleDetails',
  components: {
    TypeBody,
  },
  props: {
    paySchedule: {
      type: Object as PropType<PaySchedule>,
      required: true,
    },
    processingPeriodLabel: {
      type: String as PropType<string>,
      required: true,
    },
    payFrequencyLabel: {
      type: String as PropType<string>,
      required: true,
    },
    hideDates: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    payScheduleName: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props) {
    const name = computed(() => props.paySchedule.name || 'Pay Schedule');

    return {
      name,
    };
  },
});
