






























































































import {
  defineComponent,
  computed,
  ref,
  PropType,
} from '@nuxtjs/composition-api';
import {
  BaseButton,
  TypeBody,
  UserAvatar,
  CheckboxInput,
} from '@bambeehr/pollen';
import PayrollCard from '@/modules/payroll/components/PayrollCard/PayrollCard.vue';
import currency from '@bambeehr/currency';
import { PaymentMethod, PayTypes } from '@/modules/payroll/constants/payroll';
import {
  StaffPayrollStatus,
  getPayrollStatus,
} from '@/modules/payroll/utils/getStaffItemPayrollStatus';
import { normalizeSteps } from '@/modules/payroll/utils/normalizeRemainingSteps';
import PayrollStatusDot from '@/modules/payroll/components/PayrollStatusDot/PayrollStatusDot.vue';
import { SuperStaffItem } from '@/modules/payroll/components/StaffDrawer/types/staffDrawer';

export default defineComponent({
  name: 'StaffTile',
  components: {
    BaseButton,
    CheckboxInput,
    PayrollCard,
    PayrollStatusDot,
    TypeBody,
    UserAvatar,
  },
  props: {
    staffItem: {
      type: Object as PropType<SuperStaffItem>,
      required: true,
    },
    isSelected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hideCheckbox: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const payrollStatus = computed(() => getPayrollStatus(props.staffItem));

    const payrollStatusDescription = ref('');
    const fullName = computed(
      () =>
        `${props.staffItem.profile.first_name} ${props.staffItem.profile.last_name}`
    );
    const imageURL = computed(() => props.staffItem?.profile?.avatar_url || '');

    const staffPayRateTerm = computed(() => {
      switch (props.staffItem.profile?.type?.toUpperCase()) {
        case PayTypes.SALARY:
          return 'year';
        case PayTypes.HOURLY:
          return 'hour';
        default:
          return 'contract';
      }
    });

    const staffPayRate = computed(() =>
      props.staffItem?.profile?.pay_rate
        ? `${currency(props.staffItem?.profile?.pay_rate).format()}/${
            staffPayRateTerm.value
          }`
        : ''
    );

    const paymentMethod = computed(() => {
      switch (props.staffItem.paymentMethodPreference) {
        case PaymentMethod.MANUAL.value:
          return PaymentMethod.MANUAL.label;
        case PaymentMethod.DIRECT_DEPOSIT.value:
          return PaymentMethod.DIRECT_DEPOSIT.label;
        default:
          return '-';
      }
    });

    const actionLabel = computed(() =>
      payrollStatus.value === StaffPayrollStatus.MISSING_INFO
        ? 'Set Up'
        : 'Edit'
    );

    const payrollStatusText = computed(() => {
      const workerTerm = props.staffItem.isContractor
        ? 'Contractor'
        : 'Employee';
      switch (payrollStatus.value) {
        case StaffPayrollStatus.REMAINING_STEPS:
          return `${workerTerm} Action Required`;
        case StaffPayrollStatus.ON_PAYROLL:
          return 'On Payroll';
        case StaffPayrollStatus.MISSING_INFO:
          return 'Missing Info';
        default:
          return 'Off Payroll';
      }
    });

    if (payrollStatus.value === StaffPayrollStatus.REMAINING_STEPS) {
      payrollStatusDescription.value = normalizeSteps(
        props.staffItem.checkOnboarding?.remainingSteps
      );
    }

    const displayName = computed(
      () => props.staffItem.profile.contractor_business_name || fullName.value
    );

    const title = computed(() =>
      props.staffItem.profile.contractor_business_name
        ? fullName.value
        : props.staffItem.profile.role
    );

    const toggleSelectedState = (isSelected) => {
      const { isContractor, id } = props.staffItem;

      emit('selected', {
        id,
        isSelected,
        isContractor,
      });
    };

    return {
      actionLabel,
      displayName,
      imageURL,
      paymentMethod,
      payrollStatus,
      payrollStatusDescription,
      payrollStatusText,
      staffPayRate,
      title,
      toggleSelectedState,
    };
  },
});
