import { CompanyStatuses } from '@/modules/payroll/constants/company';
import {
  getCompanyOnboardingStatus,
  listWorkplaces,
} from '@/gql/services/payroll.service';
import { removeTrailingSlash } from '@/utils/routes';

export enum Routes {
  HOME = '/dashboard',
  ONBOARDING_ROOT = '/payroll-setup',
  GET_STARTED = '/payroll-setup/get-started',
  CONFIRMATION = '/payroll-setup/confirmation',
  COMPANY_INFO = '/payroll-setup/company-info',
  LOCATIONS = '/payroll-setup/locations',
  PAY_SCHEDULE = '/payroll-setup/pay-schedule',
  PAY_SCHEDULE_PREVIEW = '/payroll-setup/pay-schedule/preview',
  PAY_SCHEDULE_CREATE = '/payroll-setup/pay-schedule/create',
  BANK_TAX = '/payroll-setup/bank-tax',
  SIGNATORY_CONFIRMATION = '/payroll-setup/signatory-confirmation',
  PAYROLL_SETUP = '/payroll/setup',
}

export default async ({ store, redirect, route }) => {
  const { path = '' } = route;
  const toPath = removeTrailingSlash(path);

  const isAdmin = store.getters['auth/isCompanyAdmin'];
  const isOwner = store.getters['auth/isOwner'];
  const currentUserEmail = store.getters?.currentUser?._auth?.email;

  const hasPermissions = isAdmin || isOwner;

  // User doesn't have permission to onboard
  if (!hasPermissions) {
    redirect(Routes.HOME);

    return;
  }

  const { companyId } = store.getters;
  const { data: company } = await getCompanyOnboardingStatus(companyId);
  const hasBackedOut = company?.status === CompanyStatuses.BACKED_OUT;
  const isProvisioned = company?.status === CompanyStatuses.PROVISIONED;

  // Company not provisioned or backed out of payroll
  if (!company || hasBackedOut || isProvisioned) {
    redirect(Routes.HOME);

    return;
  }

  if (
    // GET_STARTED & CONFIRMATION are a special flow that should allow deep linking without route validation
    toPath === Routes.GET_STARTED ||
    toPath === Routes.CONFIRMATION
  ) {
    return;
  }

  const hasCompletedOnboardingForm = company.checkId && company.checkIndustry;

  // If user isn't init'ed in Payroll
  if (!hasCompletedOnboardingForm && toPath !== Routes.COMPANY_INFO) {
    redirect(Routes.COMPANY_INFO);

    return;
  }

  const hasPaySchedule = company.paySchedules?.length;
  const hasSkippedPaySchedule = company.needsPayScheduleAssistance;
  const hasBlockingSteps = company.checkOnboarding?.blockingSteps?.length;
  const hasSetSignatory = !!company.signatoryEmail;
  const isSignatory = currentUserEmail === company.signatoryEmail;

  // If you don't have blocking steps that means that you're fully onboarded so we'll send you to the setup landing page
  if (company.checkId && !hasBlockingSteps && !hasBackedOut) {
    redirect(Routes.PAYROLL_SETUP);
  }

  // We'll redirect users based on data if they hit the root
  if (toPath === Routes.ONBOARDING_ROOT) {
    // If they have a Pay Schedule they've already passed this step
    if (hasPaySchedule || hasSkippedPaySchedule) {
      redirect(Routes.BANK_TAX);

      return;
    }

    const { data: locations } = await listWorkplaces(companyId);
    const hasAddedLocations = locations?.length > 1;

    // Check if they have more than one location, which would indicate they've added workplaces in this flow
    if (hasAddedLocations) {
      redirect(Routes.PAY_SCHEDULE);

      return;
    }

    redirect(Routes.LOCATIONS);

    return;
  }

  // Protecting against skipping the Pay Schedules steps
  if (toPath === Routes.BANK_TAX || toPath === Routes.SIGNATORY_CONFIRMATION) {
    if (!(hasPaySchedule || hasSkippedPaySchedule)) {
      redirect(Routes.PAY_SCHEDULE);
    }

    if (toPath === Routes.BANK_TAX && hasSetSignatory && !isSignatory) {
      redirect(Routes.SIGNATORY_CONFIRMATION);
    }
  }

  // Protecting against skipping the Pay Schedules steps
  if (toPath === Routes.PAY_SCHEDULE_PREVIEW) {
    if (hasSkippedPaySchedule) {
      redirect(Routes.PAY_SCHEDULE);
    }
  }
};
