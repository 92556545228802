import { PayerType } from '@/modules/payroll/constants/payroll';

const getTaxesByPayerType = (payerType, payrollItems = []) => {
  return payrollItems.reduce((acc, item) => {
    if (item.taxes) {
      acc.push(...item.taxes.filter((t) => t.payer === payerType));
    }

    return acc;
  }, []);
};

export const getPostTaxDeductionsList = (payrollItems = []) => {
  return payrollItems.reduce((acc, item) => {
    if (item.postTaxDeductions) {
      acc.push(...item.postTaxDeductions);
    }

    return acc;
  }, []);
};

export const getEmployeeTaxList = (payrollItems) =>
  getTaxesByPayerType(PayerType.EMPLOYEE, payrollItems);

export const getCompanyTaxList = (payrollItems) =>
  getTaxesByPayerType(PayerType.COMPANY, payrollItems);

export const totalTaxesByType = (taxTypeList = []) => {
  return taxTypeList.reduce((acc, tax) => {
    const taxMatch = acc.find((i) => i.description === tax.description);
    if (taxMatch) {
      taxMatch.amount += tax.amount;
    } else {
      acc.push({
        description: tax.description,
        amount: tax.amount,
      });
    }

    return acc;
  }, []);
};
