



import { computed, defineComponent } from '@nuxtjs/composition-api';
import { StaffPayrollStatus } from '@/modules/payroll/utils/getStaffItemPayrollStatus';

export default defineComponent({
  props: {
    payrollStatus: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const isSettingUpPay =
      props.payrollStatus === StaffPayrollStatus.REMAINING_STEPS;

    const dotColor = computed(() => {
      switch (props.payrollStatus) {
        case StaffPayrollStatus.ON_PAYROLL:
          return 'bg-success-default';
        case StaffPayrollStatus.REMAINING_STEPS:
          return 'bg-warning-default';
        case StaffPayrollStatus.MISSING_INFO:
          return 'bg-error-default';
        case StaffPayrollStatus.OFF_PAYROLL:
        default:
          return 'bg-base-500';
      }
    });

    return {
      dotColor,
      isSettingUpPay,
    };
  },
});
