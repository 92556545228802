import { WebSocketLink } from '@apollo/client/link/ws';

export default function ({ uri, wsOptions }) {
  const wsLink = new WebSocketLink({
    uri,
    options: {
      reconnect: true,
      ...wsOptions,
    },
  });

  return wsLink;
}
