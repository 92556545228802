import {
  useGetMyTrainingRequestsQuery,
  RequestStatus,
  useMakeTrainingRequestMutation,
  useCancelTrainingRequestMutation,
  CoreRequest,
  TrainingRequestInput,
  RequestCancelInput,
  RequestResourceType,
} from '@/gql/generated';
import bam from '@/lib/bam';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';

import { ref } from '@nuxtjs/composition-api';

let init;

const myRequests = ref<CoreRequest[]>([]);
const isLoading = ref(true);

const getUserRequests = (force = false) => {
  const { onResult } = useApolloQuery(
    useGetMyTrainingRequestsQuery,
    { data: {} },
    undefined,
    { force }
  );

  onResult(({ myRequests: res }) => {
    myRequests.value = res?.filter((r) =>
      [
        RequestResourceType.TrainingBundle,
        RequestResourceType.TrainingTopic,
      ].includes(r.resourceType)
    ) as CoreRequest[];
    isLoading.value = false;
  });
};

const makeRequest = (requestInfo: TrainingRequestInput) => {
  const { mutate, onDone, onError } = useApolloMutation(
    useMakeTrainingRequestMutation
  );

  mutate({ data: requestInfo });

  bam.track('course_catalog_ee_requested_content', {
    content: requestInfo,
  });

  return {
    onDone,
    onError,
  };
};

const cancelRequest = (requestInfo: RequestCancelInput) => {
  const { mutate, onDone, onError } = useApolloMutation(
    useCancelTrainingRequestMutation
  );

  mutate({ data: requestInfo });

  bam.track('course_catalog_ee_cancelled_requested_content', {
    content: requestInfo,
  });

  return {
    onDone,
    onError,
  };
};

const setupHook = async () => {
  getUserRequests();
  init = true;
};

const refreshUserRequests = (force = false) => {
  getUserRequests(force);
};

const useEmployeeTrainingRequests = () => {
  if (!init) {
    setupHook();
  }

  return {
    myRequests,
    isLoading,
    refreshUserRequests,
    makeRequest,
    cancelRequest,
  };
};

export default useEmployeeTrainingRequests;
