import { MilestoneGroups, MilestoneNames } from '@/constants/milestones';
import { get } from 'lodash';

const { BASIC_AUDIT, HR_AUDIT, INSURANCE_INFORMATION } = MilestoneNames;
const { BCI } = MilestoneGroups;

export const state = () => ({
  loading: null,
  milestones: [],
});

export const getters = {
  byGroup({ milestones }) {
    return (group) => {
      return milestones.filter((m) => m.group === group);
    };
  },

  essentialMilestones({ milestones }) {
    return milestones.filter((m) => m.group === BCI || m.name === BASIC_AUDIT);
  },

  accountSetupMilestonesIncompleteCount(_, getters, rootState, rootGetters) {
    const { isChatOnly } = rootGetters;
    const accountSetupMilestones = isChatOnly
      ? getters.essentialMilestones
      : getters.byGroup(BCI);

    return accountSetupMilestones.filter((milestone) => !milestone.completed)
      .length;
  },

  milestoneCompleted() {
    return function ({ milestones, milestoneName }) {
      const milestone = milestones.find((m) => m.name === milestoneName);

      return milestone && milestone.completed;
    };
  },

  hrAuditCallCompleted({ milestones }, getters) {
    return getters.milestoneCompleted({
      milestones,
      milestoneName: HR_AUDIT,
    });
  },

  insuranceCompleted({ milestones }, getters) {
    return getters.milestoneCompleted({
      milestones,
      milestoneName: INSURANCE_INFORMATION,
    });
  },
};

export const mutations = {
  setMilestones(state, milestones) {
    state.milestones = [];
    milestones.forEach((m) => {
      /* eslint-disable no-use-before-define */
      state.milestones.push(createMilestone(m));
      /* eslint-enable */
    });
  },

  setLoading(state, value) {
    state.loading = value;
  },

  completeMilestone(state, milestoneName) {
    state.milestones = state.milestones.map((milestone) =>
      milestone.name === milestoneName
        ? {
            ...milestone,
            completed: true,
            completedAt: new Date(),
          }
        : milestone
    );
  },
};

export const actions = {
  fetchMilestones({ state, dispatch }) {
    if (state.loading === null) {
      return dispatch('getCompanyScores');
    }

    return state.loading;
  },
  getMilestones({ commit, rootState }) {
    const companyId = get(rootState, 'current_user._company._id');

    if (!companyId) {
      return [];
    }

    const request = this.$axios
      .get(`/v0/companies/v1/company/${companyId}/milestones`)
      .then((res) => {
        commit('setMilestones', res.data);
      });

    commit('setLoading', request);

    return request;
  },
};

function createMilestone(payload) {
  return {
    _id: payload._id,
    group: payload.group,
    name: payload.name,
    label: payload.label,
    description: payload.description,
    completed: !!payload.completedAt,
    progress: payload.progress,
    icon: payload.icon,
    actionText: payload.actionText,
    actionType: payload.actionType,
    action: payload.action,
  };
}
