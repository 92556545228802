import { CompanyStatuses } from '@/constants/companies';
import { UserRoles } from '@/constants/users';
import Vue from 'vue';
import { defineStore } from 'pinia';
import { useDefaultStore } from '@/store';

const useCompanyStore = defineStore('company', {
  state: () => ({
    data: {},
    billingInfo: {},
    cancellationItems: {},
    onboardingMilestones: {},
    onboardingTasks: {},
  }),
  actions: {
    setCompanies(companies) {
      this.data = companies.reduce(
        (obj, company) => ({
          ...obj,
          [company._id]: company,
        }),
        this.data
      );
    },

    reset() {
      this.data = {};
      this.billingInfo = {};
      this.cancellationItems = {};
    },

    setCancellationItems(items) {
      this.cancellationItems = items;
    },

    setBillingInfo({ companyId, info }) {
      this.billingInfo = {
        ...this.billingInfo,
        [companyId]: info,
      };
    },

    setOnboardingMilestones({ companyId, milestones }) {
      Vue.set(this.onboardingMilestones, companyId, milestones);
    },

    setOnboardingMilestone({ companyId, milestone }) {
      const index = this.onboardingMilestones[companyId]?.findIndex(
        (m) => m.milestone === milestone.milestone
      );

      Vue.set(this.onboardingMilestones[companyId], index, milestone);
    },

    setOnboardingTasks({ companyId, tasks }) {
      Vue.set(this.onboardingTasks, companyId, tasks);
    },

    setOnboardingTask({ companyId, task }) {
      const index = this.onboardingTasks[companyId].findIndex(
        (t) => t.type === task.type
      );

      Vue.set(this.onboardingTasks[companyId], index, task);
    },

    async getMyCompanies() {
      const { data } = await this.$nuxt.$axios.get(`/v0/app/my/companies`, {
        params: { all: true },
      });
      this.reset();
      this.setCompanies(data);

      return data;
    },

    async getCancellationItems() {
      const { data } = await this.$nuxt.$axios.get(
        `/v0/app/my/company-cancellation-information`
      );
      this.setCancellationItems(data);

      return data;
    },

    reservePayrollSlot(companyId) {
      return this.$nuxt.$axios.post(
        `/company/companies/v2/${companyId}/payroll-reservation`
      );
    },

    signPayrollTOS(companyId) {
      return this.$nuxt.$axios.post(
        `/v0/companies/v1/company/${companyId}/payroll-tos-signed`,
        null
      );
    },

    async getOnboardingMilestones() {
      const defaultStore = useDefaultStore();
      const { currentUser, companyId } = defaultStore;

      const isAllowed = [UserRoles.ADMIN, UserRoles.USER].includes(
        // @ts-ignore
        currentUser?.role
      );
      if (isAllowed) {
        try {
          const res = await this.$nuxt.$axios.get(
            `/company/companies/v2/${companyId}/onboarding`
          );

          if (!res) {
            return;
          }

          this.setOnboardingMilestones({
            companyId,
            milestones: res.data.onboardingMilestones,
          });
        } catch (e) {
          window.DD_RUM?.addError(e);
        }
      }
    },

    async updateOnboardingMilestone({ milestone, completed }) {
      const defaultStore = useDefaultStore();
      const { companyId } = defaultStore;

      try {
        const res = await this.$nuxt.$axios.put(
          `/company/companies/v2/${companyId}/onboarding`,
          {
            milestone,
            completed,
          }
        );

        this.setOnboardingMilestone({
          companyId,
          milestone: res.data,
        });
      } catch (e) {
        window.DD_RUM?.addError(e);
      }
    },

    async getOnboardingTasks() {
      const defaultStore = useDefaultStore();
      const { currentUser, companyId } = defaultStore;

      const isAllowed = [UserRoles.ADMIN, UserRoles.USER].includes(
        // @ts-ignore
        currentUser?.role
      );
      if (isAllowed) {
        try {
          const res = await this.$nuxt.$axios.get(
            `/company/company/${companyId}/tasks`
          );

          if (!res) {
            return;
          }

          this.setOnboardingTasks({
            companyId,
            tasks: res.data,
          });

          // eslint-disable-next-line consistent-return
          return res.data;
        } catch (e) {
          window.DD_RUM?.addError(e);
        }
      }
    },

    async completeOnboardingTask({ type }) {
      const defaultStore = useDefaultStore();
      const { companyId } = defaultStore;

      try {
        // This put request will return the whole array of tasks given when a task is updated there is a potential that the parent goal will get completed as well.
        const res = await this.$nuxt.$axios.put(
          `/company/company/${companyId}/tasks`,
          {
            type,
          }
        );

        this.setOnboardingTasks({
          companyId,
          tasks: res.data,
        });
      } catch (e) {
        window.DD_RUM?.addError(e);
      }
    },
    byId(id) {
      return this.data[id];
    },

    onboardingMilestonesByTag(tag): any {
      return this.onboardingMilestonesGetter?.filter((milestone) =>
        milestone.tags?.includes(tag)
      );
    },

    onboardingMilestonesByTagV2(tag?): any {
      return (this.onboardingMilestonesGetter || []).filter((milestone) =>
        milestone.tags?.includes(tag)
      );
    },
    onboardingTaskByType(type): any {
      const defaultStore = useDefaultStore();
      const { companyId } = defaultStore;

      return this.onboardingTasks[companyId].find((task) => task.type === type);
    },
  },
  getters: {
    active(state): any[] {
      return Object.values(state.data).filter(
        // @ts-ignore
        (company) => company.status !== CompanyStatuses.INACTIVE
      );
    },
    all(state): any[] {
      return Object.values(state.data);
    },
    billingInfoGetter(state): any {
      const defaultStore = useDefaultStore();
      const { companyId } = defaultStore;

      return state.billingInfo[companyId];
    },
    onboardingMilestonesGetter(state): any[] {
      const defaultStore = useDefaultStore();
      const { companyId } = defaultStore;

      return state.onboardingMilestones[companyId];
    },

    onboardingTasksGetter(state): any {
      const defaultStore = useDefaultStore();
      const { companyId } = defaultStore;

      return state.onboardingTasks[companyId];
    },
    onboardingTaskByGoals(state): any {
      const defaultStore = useDefaultStore();
      const { companyId } = defaultStore;

      // Goals the parentId is null
      return state.onboardingTasks[companyId]?.filter((task) => !task.parentId);
    },
  },
});

export default useCompanyStore;
