export const state = () => ({
  currentReview: {},
  allReviews: [],
  status: {
    next: '',
    managerEnd: '',
    ownerEnd: '',
    managerCanReview: '',
    ownerCanReview: '',
  },
});

export const getters = {};

export const mutations = {
  setCurrent(state, { review }) {
    state.currentReview = review;
  },
  setAll(state, { reviews }) {
    state.allReviews = reviews;
  },
  setIndividual(state, { review }) {
    state.currentReview = review;
    const index = state.allReviews.findIndex((aR) => {
      return aR._id == review._id;
    });
    index >= 0
      ? state.allReviews.splice(index, 1, review)
      : state.allReviews.push(review);
  },
  setCanEdit(
    state,
    { next, managerEnd, ownerEnd, managerCanReview, ownerCanReview }
  ) {
    state.status = {
      next,
      managerEnd,
      ownerEnd,
      managerCanReview,
      ownerCanReview,
    };
  },
};

export const actions = {
  getSchedule({ commit }) {
    return this.$axios.get('/v0/app/employee-review/schedule').then((res) => {
      commit('setCanEdit', res.data);
    });
  },
  create({ commit }, { employee_id, company_id }) {
    return this.$axios
      .post('/v0/app/employee-review/create', { employee_id,
company_id })
      .then((res) => {
        commit('setIndividual', { review: res.data });

        return res.data;
      });
  },
  saveReview({ commit }, { review_id, reviews }) {
    return this.$axios
      .post(`/v0/app/employee-review/${review_id}/save`, { reviews })
      .then((res) => {
        commit('setIndividual', { review: res.data });

        return res.data;
      });
  },
  getById({ commit }, { review_id }) {
    return this.$axios
      .post(`/v0/app/employee-review/${review_id}/getById`)
      .then((res) => {
        commit('setIndividual', { review: res.data });

        return res.data;
      });
  },
  reviewSaved({ commit }, { review_id, notes }) {
    return this.$axios
      .post(`/v0/app/employee-review/${review_id}/saved`, { notes })
      .then((res) => {
        commit('setIndividual', { review: res.data });

        return res.data;
      });
  },
  reviewMeet({ commit }, { review_id, reviewScore, additionalNotes }) {
    return this.$axios
      .post(`/v0/app/employee-review/${review_id}/meet`, {
        reviewScore,
        additionalNotes,
      })
      .then((res) => {
        commit('setIndividual', { review: res.data });

        return res.data;
      });
  },
  reviewAcknowledge({ commit }, { review_id }) {
    return this.$axios
      .post(`/v0/app/employee-review/${review_id}/acknowledge`)
      .then((res) => {
        commit('setIndividual', { review: res.data });

        return res.data;
      });
  },
  reviewEmailSend({ commit }, { review_id }) {
    return this.$axios
      .post(`/v0/app/employee-review/${review_id}/send`)
      .then((res) => {
        commit('setIndividual', { review: res.data });

        return res.data;
      });
  },
  getByCompany({ commit }, { company_id }) {
    return this.$axios
      .get(`/v0/app/employee-review/getByCompany/${company_id}`)
      .then((res) => {
        commit('setAll', { reviews: res.data });
      });
  },

  saveEmployeeNote({ commit }, { review_id, employeeNote }) {
    return this.$axios
      .post(`/v0/app/employee-review/${review_id}/saveEmployeeNote`, {
        employeeNote,
      })
      .then((res) => {
        commit('setIndividual', { review: res.data });

        return res.data;
      });
  },
};
