import useNotifications from '@bambeehr/use-notifications';

interface CertDownloadItem {
  certificateDownloadUrl?: string;
  certificateDownloadUrlList?: string[];
}

const downloadCertificates = (
  item: CertDownloadItem,
  fileDownloader: Function
): void => {
  if (item) {
    const { addSuccess } = useNotifications();

    const certUrls = (
      item?.certificateDownloadUrlList || [item?.certificateDownloadUrl]
    ).filter(Boolean);

    certUrls.forEach((url) => {
      if (url) {
        fileDownloader(url, '');
      }
    });
    addSuccess('Downloading. This may take a moment.');
  }
};

export default downloadCertificates;
