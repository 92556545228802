





































































































import TrainingEnrollmentForm from '@/modules/Training/components/TrainingEnrollmentForm/TrainingEnrollmentForm';
import TrainingEnrollmentFormActions from '@/modules/Training/components/TrainingEnrollmentForm/TrainingEnrollmentFormActions';
import TrainingEnrollmentFormFilter from '@/modules/Training/components/TrainingEnrollmentForm/TrainingEnrollmentFormFilter';
import useTrainingEnrollmentForm from '@/modules/Training/hooks/useTrainingEnrollmentForm/useTrainingEnrollmentForm';
import {
  BaseBanner,
  BaseIcon,
  ModalDialog,
  TypeBody,
  TypeDisplay,
  TabControls,
} from '@bambeehr/pollen';
import useNotifications from '@bambeehr/use-notifications';
import { computed, PropType, onMounted, watch } from '@nuxtjs/composition-api';
import { CORE_BUNDLE_ID } from '@/modules/Training/constants';

export default {
  name: 'TrainingEnrollmentModal',
  components: {
    BaseBanner,
    BaseIcon,
    ModalDialog,
    TypeDisplay,
    TrainingEnrollmentForm,
    TrainingEnrollmentFormFilter,
    TrainingEnrollmentFormActions,
    TypeBody,
    TabControls,
  },
  props: {
    topicId: {
      type: String as PropType<string>,
      required: true,
    },
    bundleId: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    selectedIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      state,
      isFetching,
      cancel,
      tabOptions,
      tabModel,
      isNewPurchase,
      select,
    } = useTrainingEnrollmentForm(
      props.bundleId as string,
      props.topicId as string
    );
    const completelyEnrolled = computed(() => {
      return state.enrolled?.length === state.allUsers?.length;
    });

    const isCoreBundle = computed(() => props.bundleId === CORE_BUNDLE_ID);

    const { addSuccess, addError } = useNotifications();
    const onEnrollSave = (enrollmentNumber) => {
      emit('enrolled', enrollmentNumber);
      addSuccess(
        `Successfully enrolled ${enrollmentNumber} employee${
          enrollmentNumber > 1 ? 's' : ''
        }`
      );
      emit('close');
    };

    const onEnrollErr = (err) => {
      addError(err);
    };

    const handleClose = () => {
      emit('close');
      cancel();
    };

    if ((props.selectedIds as string[])?.length) {
      onMounted(() => {
        const unwatch = watch(state, () => {
          select(props.selectedIds as string[]);
          unwatch?.();
        });
        select(props.selectedIds as string[]);
      });
    }

    return {
      state,
      completelyEnrolled,
      onEnrollSave,
      onEnrollErr,
      handleClose,
      isFetching,
      tabOptions,
      tabModel,
      isCoreBundle,
      isNewPurchase,
    };
  },
};
