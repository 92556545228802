


























































































































































































import {
  defineComponent,
  ref,
  computed,
  useStore,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  TypeDisplay,
  TypeBody,
  BaseButton,
  BaseBanner,
} from '@bambeehr/pollen';
import PriceTool from '@/modules/TimeAndAttendance/components/PriceTool/PriceTool.vue';
import CheckList from '@/components/CheckList/CheckList.vue';
import FaqBlock from '@/components/FaqBlock/FaqBlock.vue';
import WizardInfoLayout from '@/modules/BambeePlus/components/WizardInfoLayout.vue';
import PurchasetimeAndAttendance from '@/modules/TimeAndAttendance/components/PurchaseTimeAndAttendance/PurchaseTimeAndAttendance.vue';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal.vue';
import useNotifications from '@bambeehr/use-notifications';
import usePayrollCompany from '@/hooks/usePayrollCompany';
import VideoTourStrip from '@/modules/TimeAndAttendance/components/VideoTourStrip/VideoTourStrip.vue';
import { setUserPreference } from '@/utils/userPreference';
import LocalStorageKey from '@/constants/LocalStorage';
import {
  DeputyEdition,
  guard,
} from '@/modules/TimeAndAttendance/middleware/canAccessEmployerTimeAndAttendance';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentUser from '@/hooks/useCurrentUser';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import {
  usePurchaseTimeAndAttendanceMutation,
  useProvisionDeputyInstallMutation,
  GoalName,
  useGetCompanyStatusQuery,
  useUpgradeInstallMutation,
} from '@/gql/generated';
import useGoals from '@/modules/TaskCenter/hooks/useGoals/useGoals';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import { MarketingEvents } from '@/modules/TimeAndAttendance/constants';
import bam from '@/lib/bam';
import { useAdvisorsStore } from '@/store/advisors';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';

const monthlyPrice = 19;
const perEmployeePrice = 4;

const checkList = [
  'No long-term contracts',
  'Automatic account setup',
  'Grows with your team',
  'Cancel anytime',
];
const itemDefault = {
  isComplete: false,
  bullet: '&check;',
  shouldPulse: false,
  variant: 'primary',
};

const generalFeaturesList = [
  {
    ...itemDefault,
    text: 'Efficient Employee Scheduling',
  },
  {
    ...itemDefault,
    text: 'Time & Attendance Tracking',
  },
  {
    ...itemDefault,
    text: 'Easily Import Hours into Bambee Payroll™',
  },
];

const featureList = [
  {
    icon: 'calendar',
    title: 'Easy Scheduling',
    description: 'Schedule your team in minutes and reduce unnecessary costs.',
  },
  {
    icon: 'telescope',
    title: 'View Real-Time Attendance',
    description: `Track who's working, who's running late, and who's on break in real-time.`,
  },
  {
    icon: 'clock',
    title: 'Precise Time Tracking',
    description: 'Capture accurate timesheets for hassle-free payroll.',
  },
  {
    icon: 'coffee',
    title: 'Break Compliance Tools',
    description:
      'Easily uphold health and break standards with auto-reminders. ',
  },
  {
    icon: 'hourglass',
    title: 'Time-Off Accruals',
    description: 'Automatically track employee time-off accruals with ease. ',
  },
  {
    icon: 'alarmclock',
    title: 'Time-Clock App',
    description:
      'Turn any computer, phone, or tablet into a secure employee time clock.',
  },
  {
    icon: 'device',
    title: 'All-In-One Employee App',
    description:
      'Empower your team by providing them with tools to manage their schedule.',
  },
  {
    icon: 'money',
    title: 'Seamless Payroll',
    description:
      'Upload timesheets to Bambee Payroll to save time and avoid mistakes.',
  },
];

export default defineComponent({
  name: 'TimeAndAttendanceUpsell',
  components: {
    BaseBanner,
    BaseButton,
    CheckList,
    FaqBlock,
    LoadingModal,
    PriceTool,
    PurchasetimeAndAttendance,
    TypeBody,
    TypeDisplay,
    VideoTourStrip,
    WizardInfoLayout,
  },
  middleware: [guard],
  setup() {
    const store = useStore();
    const advisorStore = useAdvisorsStore();
    const advisorFirstName = computed(
      () => advisorStore.primaryAdvisorFirstName
    );
    const router = useRouter();
    const { isBambeePlus } = usePlanAccess();
    const { companyId } = useCurrentCompany();
    const { addSuccess, addError } = useNotifications();
    const deputyEdition = ref<string | null>();
    const { hasTimeAndAttendanceAddOn } = useCompanyBillingInfo();

    const { onResult: onCompanyResult } = useApolloQuery(
      useGetCompanyStatusQuery,
      { id: companyId.value }
    );

    onCompanyResult(({ getCompany }) => {
      deputyEdition.value = getCompany?.deputyConnection?.edition;
    });

    const showTourModal = ref(false);
    const toggleTourModal = () => {
      showTourModal.value = !showTourModal.value;
    };

    const showPurchaseModal = ref(false);
    const {
      mutate: upgradingInstall,
      loading: isUpgradingInstall,
      onDone: doneUpgradingInstall,
    } = useApolloMutation(useUpgradeInstallMutation);

    const upgradeDeputyInstall = () => {
      upgradingInstall({
        data: {
          companyId: companyId.value,
        },
      });

      doneUpgradingInstall(() => {
        router.push('/time-and-attendance');
      });
    };
    const togglePurchaseModal = () => {
      // If company had T&A once but was downgraded, upgrade them and re-route
      if (
        showPurchaseModal.value === false &&
        hasTimeAndAttendanceAddOn.value &&
        deputyEdition.value !== DeputyEdition.PAID
      ) {
        upgradeDeputyInstall();
      } else {
        showPurchaseModal.value = !showPurchaseModal.value;

        if (showPurchaseModal.value) {
          bam.trackEvent(MarketingEvents.PURCHASE_MODAL_OPENED);
        } else {
          bam.trackEvent(MarketingEvents.PURCHASE_MODAL_CLOSED);
        }
      }
    };

    const { goals } = useGoals(true);
    const plusGoalIsCompleted = computed(() =>
      goals.value.some(
        (g) => g.name === GoalName.BambeePlusSetup && !!g.completedAt
      )
    );

    const {
      mutate: provision,
      loading: isProvisioning,
      onDone: doneProvisioning,
    } = useApolloMutation(useProvisionDeputyInstallMutation);
    const provisionCompany = () => {
      // If company had T&A once but was downgraded, upgrade them and re-route
      if (
        hasTimeAndAttendanceAddOn.value &&
        deputyEdition.value !== DeputyEdition.PAID
      ) {
        upgradeDeputyInstall();

        return;
      }
      provision({
        data: {
          companyId: companyId.value,
        },
      });

      doneProvisioning(() => {
        setUserPreference(
          `${companyId.value}:${LocalStorageKey.PURCHASED_TIME_AND_ATTENDANCE}`,
          true
        );
        addSuccess('Time and Attendance has been initalized!');
        router.push('/get-time-and-attendance/processing');
      });
    };

    const showLoadingModal = ref(false);
    const toggleLoadingModal = (shouldShow?: boolean) => {
      showLoadingModal.value =
        shouldShow !== undefined ? shouldShow : !showLoadingModal.value;
    };

    const { staffOnboardingTotals } = usePayrollCompany();
    const totalCost = computed(
      () =>
        (staffOnboardingTotals?.value?.timeAttendanceEmployees ?? 1) *
          perEmployeePrice +
        monthlyPrice // computed from BE but default to 1 if any issues resolving
    );

    const staffCount = computed(() =>
      staffOnboardingTotals.value?.onboardedEmployees > 4
        ? staffOnboardingTotals.value?.onboardedEmployees
        : 5
    );

    const handleError = () => {
      addError(
        'We were unable to complete your purchase for Time and Attendance. Please try again later.'
      );
      toggleLoadingModal(false);
    };

    const handlePurchase = () => {
      const { currentUserId } = useCurrentUser();
      togglePurchaseModal();
      toggleLoadingModal(true);

      const {
        mutate: purchase,
        onDone,
        onError,
      } = useApolloMutation(usePurchaseTimeAndAttendanceMutation);
      purchase({
        data: {
          companyId: companyId.value,
          currentUserId: currentUserId.value,
        },
      });

      onDone((res) => {
        if (res.purchaseTimeAttendance.subscriptionId) {
          setUserPreference(
            `${companyId.value}:${LocalStorageKey.PURCHASED_TIME_AND_ATTENDANCE}`,
            true
          );
          addSuccess('Time and Attendance has been added to your account!');
          toggleLoadingModal(false);
          bam.trackEvent(MarketingEvents.PURCHASED);
          router.push('/get-time-and-attendance/processing');
        } else {
          bam.trackEvent(MarketingEvents.PURCHASE_FAILED);
          handleError();
        }
      });

      onError(() => {
        bam.trackEvent(MarketingEvents.PURCHASE_FAILED);
        handleError();
      });
    };

    bam.trackEvent(MarketingEvents.PAGE_VIEWED);

    return {
      featureList,
      showTourModal,
      toggleTourModal,
      checkList,
      monthlyPrice,
      perEmployeePrice,
      staffCount,
      generalFeaturesList,
      showPurchaseModal,
      isUpgradingInstall,
      togglePurchaseModal,
      handlePurchase,
      showLoadingModal,
      totalCost,
      advisorFirstName,
      isBambeePlus,
      provisionCompany,
      isProvisioning,
      plusGoalIsCompleted,
    };
  },
});
