<template>
  <portal v-if="isModalOpen" to="layout">
    <ModalDialog
      :id="id"
      :actions="actions"
      @close="$emit('close-modal')"
      @primary="$emit('close-modal')"
    >
      <TypeBody weight="medium">{{ note }}</TypeBody>
    </ModalDialog>
  </portal>
</template>

<script>
import '@nuxtjs/composition-api';
import { ModalDialog, TypeBody } from '@bambeehr/pollen';

export default {
  name: 'NoteModal',

  components: {
    ModalDialog,
    TypeBody,
  },

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
    note: {
      required: true,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
  },

  setup() {
    const actions = { primary: { label: 'Close' } };

    return {
      actions,
    };
  },
};
</script>
