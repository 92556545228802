






























































import {
  AnnularThrobber,
  TypeBody,
  BaseDivider,
  BlockSelector,
  BaseIcon,
  ToolTip,
} from '@bambeehr/pollen';
import useAddWorkerPolicies from '@/modules/StaffManagement/components/AddStaffWizard/Policies/useAddWorkerPolicies';

export default {
  components: {
    AnnularThrobber,
    BaseDivider,
    TypeBody,
    BlockSelector,
    BaseIcon,
    ToolTip,
  },
  setup() {
    const {
      allCompanyPolicies,
      filteredDocumentGroups,
      hasMatchingDocuments,
      searchQuery,
      showOnlySelected,
      isLoading,
    } = useAddWorkerPolicies();

    return {
      allCompanyPolicies,
      filteredDocumentGroups,
      hasMatchingDocuments,
      searchQuery,
      showOnlySelected,
      isLoading,
    };
  },
};
