import { useGlobalScript } from '@/hooks/useGlobalScript';
import bam from '@/lib/bam';
import { ref } from '@nuxtjs/composition-api';

import {
  useGetPayrollSignatoryInfoQuery,
  useGenerateCompanyOnboardingLinkMutation,
} from '@/gql/generated';
import { useApolloQuery, useApolloMutation } from '@/gql/apolloWrapper';

declare global {
  interface Window {
    Check: any;
  }
}

export enum CheckOnboardingEvent {
  LOADED = 'check-onboard-app-loaded',
  COMPLETED = 'check-onboard-app-completed',
  CLOSED = 'check-onboard-app-closed',
  UPDATED_TAX_FORMS = 'check-onboard-tax-setup-form-updated',
}

// Custom Check component for onboarding.
const { script: check } = useGlobalScript(
  'https://cdn.checkhq.com/onboard-initialize.js',
  'Check'
);

const useCheckOnboarding = (
  companyId: string,
  onboardingEntity: string,
  onboardingLink?: string
) => {
  const isLoading = ref(false);
  const hasCompleted = ref(false);
  const handler = ref();
  const doneHandler = ref<Function>();
  const closedHandler = ref<Function>();

  function handleOnboardingEvent(event) {
    /* eslint-disable default-case */
    switch (event) {
      case CheckOnboardingEvent.LOADED:
        // The checkonboarding modal takes a moment to visually appear after
        // trigering the loaded event. This makes for a smoother UIUX.
        setTimeout(() => {
          isLoading.value = false;
        }, 500);
        break;

      case CheckOnboardingEvent.COMPLETED:
        hasCompleted.value = true;
        break;

      case CheckOnboardingEvent.UPDATED_TAX_FORMS:
        break;

      case CheckOnboardingEvent.CLOSED:
        if (closedHandler.value) {
          closedHandler.value();
        }

        if (hasCompleted.value && doneHandler.value) {
          doneHandler.value();
        }

        break;
    }

    // Logging so we can start to tie into the onboarding flow
    bam.track(`${onboardingEntity}-onboarding-event`, {
      event,
      companyId,
    });
  }

  function onDone(fn: Function) {
    doneHandler.value = fn;
  }

  function onClosed(fn: Function) {
    closedHandler.value = fn;
  }

  const openOnboarding = (link?: string) => {
    isLoading.value = true;
    handler.value = check.value.create({
      link: onboardingLink || link,
      onEvent: handleOnboardingEvent,
    });

    handler.value.open();
  };

  const closeOnboarding = () => {
    if (handler.value) {
      handler.value.close();
    }
  };

  const getCompanyOnboardingLink = () => {
    const { onResult } = useApolloQuery(
      useGetPayrollSignatoryInfoQuery,
      { id: companyId },
      undefined,
      { force: true }
    );

    const { mutate: getLink, onDone: onLinkResult } = useApolloMutation(
      useGenerateCompanyOnboardingLinkMutation
    );

    onResult(({ payrollCompany: res }) => {
      getLink({
        data: {
          companyId,
          signerName: `${res?.signatoryFirstName} ${res?.signatoryLastName}`,
          email: res?.signatoryEmail || '',
          signerTitle: res?.signatoryTitle || '',
        },
      });
    });

    return {
      onLinkResult,
    };
  };

  return {
    closeOnboarding,
    hasCompleted,
    isLoading,
    onClosed,
    onDone,
    openOnboarding,
    getCompanyOnboardingLink,
  };
};

export default useCheckOnboarding;
