export const CallKinds = Object.freeze({
  OVERVIEW_CALL: 'overview-call',
  ESSENTIAL_UPGRADE: 'essential-upgrade',
  HR_TRANSITION_INTRO: 'hr-transition-intro',
  BENEFITS_OVERVIEW: 'benefits-overview',
  SELF_SERVICE_HR_STRATEGY: 'self-service-hr-strategy',
  CANCELLATION_REQUEST: 'cancellation-request',
  PAYROLL_OV_CALL: 'payroll-overview-call',
  TRAINING_INTEREST: 'bambee-product-interest-in-app',
  HRM_SUPPORT: 'hrm-support',
});

export const DEFAULT_CALL_KIND = CallKinds.OVERVIEW_CALL;

export default {
  CallKinds,
  DEFAULT_CALL_KIND,
};
