




































































































































































































































































































import {
  reactive,
  PropType,
  defineComponent,
  watch,
} from '@nuxtjs/composition-api';
import cloneDeep from 'lodash/cloneDeep';
import {
  BaseBanner,
  BaseLink,
  DateInput,
  RadioGroup,
  SelectInput,
  TextInput,
  TypeBody,
} from '@bambeehr/pollen';
import ToolTip from '@/components/ToolTip';

import {
  PayFrequency,
  PayDayOfSemiMonth,
  PayDayOfWeek,
} from '@/modules/payroll/constants/payroll';
import { PaySchedule, PayScheduleFrequency } from '@/gql/generated';

export interface PayScheduleWorkingForm extends PaySchedule {
  payDayOfWeek?: string;
  payDayOfMonth?: string;
}

const payFreqOptions = Object.values(PayFrequency);
const payDayOfWeekOptions = Object.values(PayDayOfWeek);
const payDayOfSemiMonthOptions = Object.values(PayDayOfSemiMonth);
const payDayOfMonthOptions = Array.from(Array(31).keys()).map((i) => {
  const day = `${i + 1}`;

  return {
    label: i === 30 ? 'Last day of month' : day,
    value: day,
  };
});

export default defineComponent({
  name: 'PayScheduleForm',
  components: {
    BaseBanner,
    BaseLink,
    DateInput,
    RadioGroup,
    SelectInput,
    TextInput,
    TypeBody,
    ToolTip,
  },
  props: {
    paySchedule: {
      type: Object as PropType<PayScheduleWorkingForm>,
      required: true,
    },
    periodEndErr: {
      type: String as PropType<string>,
      default: '',
    },
    paydayErr: {
      type: String as PropType<string>,
      default: '',
    },
    payFreqErr: {
      type: String as PropType<string>,
      default: '',
    },
    showPaydayWarning: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showPaydayTooSoonError: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    payDayOfErr: {
      type: String as PropType<string>,
      default: '',
    },
    inlineError: {
      type: String as PropType<string>,
      default: '',
    },
    isSemiMonthly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isMonthly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isBiWeekly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isWeekly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showFirstPayday: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    firstPayPeriodEndOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    firstPaydayOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const form = reactive(cloneDeep(props.paySchedule));

    watch(form, (updatedForm) => {
      emit('updated', updatedForm);
    });

    // If the user updates the firstPeriodEnd we need to remove the previous payday
    function resetPayday() {
      form.firstPayday = '';
    }

    function resetNonInitial() {
      // Reset all form values starting at day of.
      // This is to prevent values from existing but being hidden in the UI
      form.payDayOfWeek = '';
      form.payDayOfMonth = '';
      form.firstPayday = '';
      form.firstPeriodEnd = '';
    }

    return {
      form,
      payFreqOptions,
      payDayOfWeekOptions,
      payDayOfSemiMonthOptions,
      payDayOfMonthOptions,
      resetPayday,
      resetNonInitial,
    };
  },
});
