import { DefaultLandingRoutes } from '@/lib/globals/routes';

export default ({ redirect, store }) => {
  // If current user has paid his/her subscription, don't let them see the component using this middleware
  const allowedStatuses = ['paying', 'trial'];

  if (allowedStatuses.includes(store.state.current_user._company.status)) {
    redirect(DefaultLandingRoutes.USER.path);
  }
};
