import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';

import {
  useConfirmUserMfaEnrollmentMutation,
  useCreateMfaChallengeMutation,
  useEnrollMfaDeviceMutation,
  useGetMfaEnrollmentsQuery,
  useResendChallengeMutation,
  AuthMfaType,
} from '@/gql/generated';
import useNotifications from '@bambeehr/use-notifications';
import { computed, readonly, ref } from '@nuxtjs/composition-api';

const mfaEnrolled = ref<boolean>();
const isMfaEnabled = computed(() => mfaEnrolled.value);
let addError;

const nonce = ref();
const phone = ref();

const getMfaStatus = (force: boolean = false) => {
  const { result, onResult } = useApolloQuery(
    useGetMfaEnrollmentsQuery,
    {
      includeUncomfirmed: false,
    },
    undefined,
    { force }
  );
  onResult((res) => {
    mfaEnrolled.value = res.getMyMfaEnrollments?.length > 0;
  });

  return {
    result,
  };
};

const enrollMfa = (mfaType: AuthMfaType, phoneNumber: string) => {
  const response = ref();

  const { errors, loading, mutate, onDone, onError } = useApolloMutation(
    useEnrollMfaDeviceMutation
  );

  mutate({
    input: {
      mfaType,
      phoneNumber,
    },
  });

  onDone((res) => {
    response.value = res;
    nonce.value = res.enrollMfaDevice.nonce;
  });

  onError(() => {
    addError('Phone number is invalid, please try again');
  });

  return {
    mutate,
    loading,
    response,
    errors,
  };
};

const createMfaChallenge = (reason: string, type: AuthMfaType) => {
  const response = ref();

  const { errors, loading, mutate, onDone } = useApolloMutation(
    useCreateMfaChallengeMutation
  );

  mutate({
    reason,
    type,
  });

  onDone((res) => {
    response.value = res;
    nonce.value = res.createChallenge?.nonce;
    phone.value = res.createChallenge?.metadata?.phoneLast4;
  });

  return {
    mutate,
    loading,
    response,
    errors,
  };
};

const resendChallenge = (
  nonceVal: string,
  reason: string,
  type: AuthMfaType
) => {
  const response = ref();

  const { errors, loading, mutate, onDone } = useApolloMutation(
    useResendChallengeMutation
  );

  mutate({
    nonceId: nonceVal,
    reason,
    type,
  });

  onDone((res) => {
    response.value = res;
    nonce.value = res.resendChallenge?.nonce;
    phone.value = res.resendChallenge?.metadata?.phoneLast4;
  });

  return {
    mutate,
    loading,
    response,
    errors,
  };
};

const confirmOtp = (otpCode: string, options?: object) => {
  const response = ref();
  const { errors, mutate, loading, onDone } = useApolloMutation(
    useConfirmUserMfaEnrollmentMutation
  );
  mutate(
    {
      input: {
        nonce: nonce.value,
        response: otpCode,
      },
    },
    options
  );

  onDone((res) => {
    response.value = res;
    mfaEnrolled.value = res.confirmUserMfaEnrollment.mfaType !== null;
  });

  return {
    response,
    errors,
    loading,
  };
};

const setupHook = () => {
  addError = useNotifications().addError;
};

const useMfa = () => {
  setupHook();

  return {
    // States
    isMfaEnabled,
    mfaEnrolled,
    nonce: readonly(nonce),
    phone: readonly(phone),
    // Methods
    enrollMfa,
    confirmOtp,
    getMfaStatus,
    createMfaChallenge,
    resendChallenge,
  };
};

export default useMfa;
