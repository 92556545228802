


































































import {
  ref,
  computed,
  reactive,
  PropType,
  defineComponent,
  watch,
} from '@nuxtjs/composition-api';
import { Workplace } from '@/modules/payroll/types/company';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { Address } from '@@/types/company';

import { BaseButton, ModalDialog } from '@bambeehr/pollen';
import WorkplaceModalForm from '@/components/Workplaces/WorkplaceModalForm.vue';
import useWorkplaces from './useWorkplaces';
import { validateProps } from '@/components/AddressForm/useAddressForm';

export const emptyForm = {
  name: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
  },
  default: false,
};

export default defineComponent({
  name: 'WorkplaceModal',
  components: {
    BaseButton,
    ModalDialog,
    WorkplaceModalForm,
  },
  props: {
    workplace: {
      type: Object as PropType<Workplace>,
      default: null,
    },
    label: {
      type: String as PropType<string>,
      default: 'Create New Workplace',
    },
    header: {
      type: String as PropType<string>,
      default: 'Create New Workplace',
    },
    buttonVariant: {
      type: String as PropType<string>,
      default: 'primary',
    },
    buttonSize: {
      type: String as PropType<string>,
      default: 'large',
    },
    buttonLabel: {
      type: String as PropType<string>,
      default: '',
    },
    disableAddress: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['workplace-saved'],
  setup(props, { emit }) {
    // If the address is existing on the workplace this is an edit modal UX.
    const isEditing = computed(() => !!props.workplace?.address?.line1);
    const showModal = ref(false);
    const isValidating = ref(false);
    const addressIsValid = ref(false);
    const {
      portalDestination,
      allowDelete,
      saveWorkplaceEdit: saveWorkplaceService,
      saveNewWorkplace: saveNewWorkplaceService,
      deleteWorkplace: deleteWorkplaceService,
    } = useWorkplaces();

    const saveLabel = computed(
      () =>
        props.buttonLabel ||
        (props.workplace ? 'Save Edits' : 'Create Workplace')
    );
    const isDefault = computed(() => props.workplace?.default || false);
    const workplace = computed(() => props.workplace);
    const showDeleteBtn = computed(
      () => isEditing.value && !isDefault.value && allowDelete.value
    );

    const getWorkplace = () =>
      cloneDeep(workplace.value) || cloneDeep(emptyForm);

    const form = reactive<Workplace>(getWorkplace());

    // Watch to keep incoming updates updated in the form (because we're using refetchQuery)
    watch(workplace, (updatedWorkplace) => {
      Object.assign(form, updatedWorkplace);
    });

    watch(showModal, () => {
      if (isEditing.value) {
        Object.assign(form, getWorkplace());
      }
    });

    const hasErrors = computed<boolean>(() => {
      const clonedVals = [
        ...Object.values(form),
        ...Object.values(pick(form.address, validateProps)),
      ];
      const hasEmptyStrings = clonedVals.some(
        (v) => typeof v === 'string' && !v
      );

      return hasEmptyStrings;
    });

    function toggleModal() {
      showModal.value = !showModal.value;
    }

    function saveWorkplace() {
      isValidating.value = true;
      if (!hasErrors.value) {
        (isEditing.value ? saveWorkplaceService : saveNewWorkplaceService)(
          form
        );
        showModal.value = false;
        emit('workplace-saved');

        // Reset form
        Object.assign(form, cloneDeep(emptyForm));
        isValidating.value = false;
      }
    }

    function deleteWorkplace() {
      deleteWorkplaceService(form);
      showModal.value = false;
    }

    function setAddressValidation(isValid: boolean) {
      addressIsValid.value = isValid;
    }

    function updateAddress(address: Address) {
      Object.assign(form.address, address);
    }

    return {
      addressIsValid,
      allowDelete,
      deleteWorkplace,
      form,
      hasErrors,
      isDefault,
      isEditing,
      isValidating,
      portalDestination,
      saveLabel,
      saveWorkplace,
      setAddressValidation,
      showDeleteBtn,
      showModal,
      toggleModal,
      updateAddress,
    };
  },
});
