<template>
  <div class="state-select">
    <b-field :label="label" :type="{ 'is-danger': error }">
      <b-select
        v-if="!pollen"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :invalid="invalid"
        @input="$emit('input', $event)"
      >
        <option
          v-for="(state, index) in plainStates"
          :key="index"
          :value="state.abbreviation"
        >
          {{ state.name }}
        </option>
      </b-select>

      <SelectInput
        v-else
        :id="selectInputId"
        :value="value"
        :options="pollenStates"
        :placeholder="placeholder"
        :required="required"
        :label="pollenLabel"
        :invalid="invalid"
        @input="$emit('input', $event)"
      />
    </b-field>

    <b-tooltip
      v-if="tooltip"
      label="To help you comply with state employment regulations, we need to know which state this person works in."
      class="tooltip-employeeState"
      position="is-left"
      multilined
    >
      <figure class="image is-24x24">
        <h3 class="b-text is-16">?</h3>
      </figure>
    </b-tooltip>
  </div>
</template>

<script>
import { SelectInput } from '@bambeehr/pollen';
import UsStates from '@/lib/globals/UsStates.json';

export default {
  components: {
    SelectInput,
  },

  props: {
    error: {
      type: Boolean,
    },
    value: {},
    placeholder: {
      type: String,
      default: 'Select a state',
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    pollen: {
      type: Boolean,
      default: false,
    },
    pollenLabel: {
      type: String,
      default: '',
    },
    selectInputId: {
      type: String,
      default: '',
    },
  },

  computed: {
    plainStates() {
      return UsStates;
    },
    pollenStates() {
      return UsStates.map((state) => ({
        label: state.name,
        value: state.abbreviation,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
//@import '../../styles/app/shared.scss';
@import '~assets/css/shared.scss';

.state-select {
  align-items: center;
  display: flex;
  width: 100%;

  .field {
    flex: 1 1 80%;
  }

  figure.image {
    align-items: center;
    background-color: $primary;
    border: 1px solid $white-grey-4;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    width: 1.5rem;

    h3 {
      color: $white;
    }
  }

  .tooltip::before {
    left: calc(50% + 1rem);
    transform: translateX(calc(-50% - 0.5rem));
  }

  .tooltip::after {
    left: calc(50% + 1rem);
    transform: translateX(calc(-50% - 0.5rem));
    white-space: normal;
    width: 20rem;
  }
}

.state-select:focus {
  outline: none;
}
</style>
