






















































import {
  defineComponent,
  computed,
  ref,
  useStore,
} from '@nuxtjs/composition-api';
import { BaseButton, UserAvatar, TypeBody } from '@bambeehr/pollen';
import FaqDrawer from '@/components/FaqDrawer';
import HrRoles from '@/constants/HrRoles';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';
import { useAdvisorsStore } from '@/store/advisors';

export default defineComponent({
  name: 'PayrollFaqDrawer',
  components: {
    BaseButton,
    FaqDrawer,
    UserAvatar,
    TypeBody,
  },
  props: {
    hasTour: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['view-tour'],
  setup(_, { emit }) {
    const { isPayrollPremium, isPayrollTierFeatureEnabled } =
      useCompanyBillingInfo();
    const store = useStore();

    const advisorStore = useAdvisorsStore();
    const teamMembers = computed(() => advisorStore.hrAdvisors);

    const helpAvatar = computed(() => {
      const bm = teamMembers.value.find((member) => member.role === HrRoles.BM);
      const hrm = teamMembers.value.find(
        (member) => member.role === HrRoles.HRM
      );

      return (bm || hrm)?.user.avatarUrl;
    });

    const showFaqs = ref(false);
    const toggleFaqs = (shouldShow?: boolean) => {
      showFaqs.value = shouldShow ?? !showFaqs.value;
    };

    const showChat = () => {
      toggleFaqs();
      Intercom('showMessages');
    };

    const handleViewTour = () => {
      emit('view-tour');
      toggleFaqs();
    };

    return {
      showFaqs,
      toggleFaqs,
      helpAvatar,
      showChat,
      handleViewTour,
      isPayrollPremium,
      isPayrollTierFeatureEnabled,
    };
  },
});
