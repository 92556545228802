import Contentful from '@/services/Contentful';

const ENTRY_ID = '6pNz1nDmuqwOa53nZ7gkK7';

let content = null;

const fetchContent = async () => {
  if (!content) {
    const response = await Contentful.getEntry(ENTRY_ID);
    content = response.fields;
  }

  return content;
};

export default fetchContent;
