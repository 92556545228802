














































import {
  AnnularThrobber,
  RadioToggle,
  TypeDisplay,
  BaseButton,
} from '@bambeehr/pollen';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { StaffType } from '@/modules/payroll/constants/employee';
import EmployeePayTable from './EmployeePayTable.vue';
import ContractorPayTable from './ContractorPayTable.vue';
import { serializeList } from '@/modules/payroll/utils/payFactory';
import useStaffPayTable from './useStaffPayTable';
import { useApolloQuery } from '@/gql/apolloWrapper';

import { useGetPayrollQuery } from '@/gql/generated';
import filterPayrollItemsWithNoIncome from '../../utils/filterPayrollItemsWithNoIncome';
import {
  PaymentMethod,
  PayrollStatus,
} from '@/modules/payroll/constants/payroll';

const staffTypeOptions = [
  {
    label: 'EMPLOYEES',
    value: StaffType.EMPLOYEE,
  },
  {
    label: 'CONTRACTORS',
    value: StaffType.CONTRACTOR,
  },
];

export default defineComponent({
  name: 'StaffPayTable',

  components: {
    AnnularThrobber,
    RadioToggle,
    EmployeePayTable,
    ContractorPayTable,
    TypeDisplay,
    BaseButton,
  },

  props: {
    payrollId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup({ payrollId }) {
    const {
      allPayItems,
      formattedPayItems,
      totals: payTableTotals,
      formattedTotals,
      hasContractors,
      hasEmployees,
      payrollIsApproved,
    } = useStaffPayTable();

    const isLoading = ref(false);
    const payrollResult = ref<any>(null);

    useApolloQuery(
      useGetPayrollQuery,
      {
        id: payrollId,
      },
      {
        pending: isLoading,
        data: payrollResult,
      }
    );

    const staffType = ref<string>(StaffType.EMPLOYEE);

    const payroll = computed(() => {
      if (!payrollResult.value?.getPayroll) {
        return null;
      }
      const { items, contractorPayments, totals } =
        payrollResult.value.getPayroll;
      const result = {
        ...payrollResult.value.getPayroll,
        items: filterPayrollItemsWithNoIncome(items),
        contractorPayments: filterPayrollItemsWithNoIncome(contractorPayments),
      };
      allPayItems.value = [
        ...serializeList(result.items),
        ...serializeList(result.contractorPayments),
      ];
      payTableTotals.value = totals;
      payrollIsApproved.value = result.approvedAt !== null;
      staffType.value = hasEmployees.value
        ? StaffType.EMPLOYEE
        : StaffType.CONTRACTOR;

      return result;
    });

    const isPayrollApproved = computed(() =>
      [
        PayrollStatus.PAID,
        PayrollStatus.PARTIALLY_PAID,
        PayrollStatus.PENDING,
        PayrollStatus.PROCESSING,
      ].includes(payroll.value.status)
    );

    const hasManualChecks = computed(() =>
      [...payroll.value.items, ...payroll.value.contractorPayments].some(
        (i) =>
          i.paymentMethod === PaymentMethod.MANUAL.value &&
          // Only W2s have 'netPay', checking if gross pay is present only for contractors
          (!!i.netPay || (!!i.grossPay && !!i.contractor))
      )
    );

    const showManualChecksBtn = computed(
      () => isPayrollApproved.value && hasManualChecks.value
    );

    const isEmployeeView = computed(
      () => staffType.value === StaffType.EMPLOYEE
    );

    return {
      isLoading,
      payroll,
      formattedPayItems,
      formattedTotals,
      staffTypeOptions,
      staffType,
      isEmployeeView,
      hasEmployees,
      hasContractors,
      hasManualChecks,
      showManualChecksBtn,
    };
  },
});
