export enum Routes {
  ROOT = '/time-and-attendance',
}

// hash = url hash shown in the browser
// path = destination hash for embedded iframe
// using 2 props because the hash in the browser is different than the hash used in the iframe
// and product wants the browser hash to match the tab label
export const SubRoutes = {
  OVERVIEW: {
    label: 'Overview',
    hash: 'overview',
    path: 'me',
  },
  LOCATIONS: {
    label: 'Locations',
    hash: 'locations',
    path: 'locations',
  },
  SCHEDULE: {
    label: 'Schedule',
    hash: 'schedule',
    path: 'roster',
  },
  TIMESHEETS: {
    label: 'Timesheets',
    hash: 'timesheets',
    path: 'approve',
  },
  EXPORT: {
    label: 'Export',
    hash: 'export',
    path: 'export',
  },
  PEOPLE: {
    label: 'People',
    hash: 'people',
    path: 'team',
  },
  REPORTS: {
    label: 'Reports',
    hash: 'reports',
    path: 'report',
  },
  SETTINGS: {
    label: 'Settings',
    hash: 'settings',
    path: '#modal-location-install-config',
  },
  WORKPLACES: {
    label: 'Workplaces',
    hash: 'workplaces',
    path: '',
  },
} as const;

export enum MarketingEvents {
  PAGE_VIEWED = 'T_AND_A_MARKETING_PAGE_VIEWED',
  PURCHASE_MODAL_OPENED = 'T_AND_A_MARKETING_PURCHASE_MODAL_OPENED',
  PURCHASE_MODAL_CLOSED = 'T_AND_A_MARKETING_PURCHASE_MODAL_CLOSED',
  PURCHASED = 'T_AND_A_MARKETING_PURCHASED',
  PURCHASE_FAILED = 'T_AND_A_MARKETING_PURCHASE_FAILED',
  WORKPLACE_ADDED = 'T_AND_A_MARKETING_WORKPLACE_ADDED',
  PROVISIONING_COMPLETED = 'T_AND_A_MARKETING_PROVISIONING_COMPLETED',
}

export default {
  Routes,
  SubRoutes,
};
