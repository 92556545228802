export const BASE_URL = process.env.API_URL;

export const state = () => ({
  data: {},
});

export const mutations = {
  setAll(state, items) {
    state.data = items.reduce(
      (obj, item) => ({
        ...obj,
        [item._id]: item,
      }),
      state.data
    );
  },
  set(state, item) {
    state.data = {
      ...state.data,
      [item._id]: item,
    };
  },
};

export const getters = {
  all: (state) => Object.values(state.data),
  byId: (state) => (id) => state.data[id] || null,
  byType: (state) => (type) =>
    Object.values(state.data).find((item) => item.type === type) || null,
};

export const actions = {
  async fetchById({ commit }, id) {
    const { data } = await this.$axios.get(`/v0/qna/v1/${id}`);
    commit('set', data);

    return data;
  },

  async fetchByType({ state, commit }, type) {
    const qnaType = Object.values(state.data || {}).find(
      (item) => item.type === type
    );
    if (qnaType) {
      return qnaType;
    }
    const { data } = await this.$axios.get(`/v0/qna/v1/by-type/${type}`);
    commit('set', data);

    return data;
  },

  async saveAnswers({ commit }, { answers = {}, progress = {}, id }) {
    const { data } = await this.$axios.patch(`/v0/qna/v1/${id}`, {
      answers,
      progress,
    });
    commit('set', data);

    return data;
  },

  // Used by legacy surveys only. This differs from saveAnswers in that the
  // questions in legacy surveys are also mutable.
  async update(
    { commit },
    { answers = {}, progress = {}, questions = {}, id }
  ) {
    const { data } = await this.$axios.post(`/v0/qna/v1/${id}/update`, {
      answers,
      progress,
      questions,
    });
    commit('set', data);

    return data;
  },

  async complete({ commit }, id) {
    const { data } = await this.$axios.post(`/v0/qna/v1/${id}/complete`, null);
    commit('set', data);

    return data;
  },
};
