import { Pie } from 'vue-chartjs';

export default {
  extends: Pie,
  props: {
    dataset: {
      type: Object,
      required: true,
    },
    cutoutPercentage: {
      type: Number,
      default: 70,
    },
    borderWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tooltip: false,
      tooltipValue: '',
    };
  },
  mounted() {
    const chartOption = {
      // @ts-ignore
      cutoutPercentage: this.cutoutPercentage,
      animation: {
        animateRotate: true,
      },
      legend: {
        display: false,
      },
      elements: {
        arc: {
          // @ts-ignore
          borderWidth: this.borderWidth,
        },
      },
      data: {
        datasets: [
          // @ts-ignore
          this.dataset,
        ],
      },
      tooltips: {
        enabled: false,
      },
    };
    // @ts-ignore
    this.renderChart({ datasets: [this.dataset] }, chartOption);
  },
};
