















import {
  defineComponent,
  PropType,
  ref,
  computed,
} from '@nuxtjs/composition-api';
import { BaseBanner } from '@bambeehr/pollen';
import { fetchContent } from '@/modules/OnboardingWizard/utils/copy';
import launchDarkly from '@bambeehr/vue-launch-darkly';

export default defineComponent({
  name: 'CompanyInfo',
  components: {
    BaseBanner,
  },
  props: {
    // To be used with the contentful entry type: Base Banner Notice
    // https://app.contentful.com/spaces/tb3mx4vb7wqk/content_types/baseBannerNotice/fields
    entryId: {
      type: String as PropType<string>,
      required: true,
    },
    ldFlagId: {
      type: String as PropType<string>,
      default: '',
    },
    showClose: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const isVisible = ref(false);
    const message = ref();
    const description = ref();
    const variant = ref();

    const isFeatureEnabled = computed(
      () => !props.ldFlagId || launchDarkly.getFlags()[props.ldFlagId]
    );

    (async () => {
      const res = await fetchContent(props.entryId);

      // Message is required in Contentful
      message.value = res?.message;
      description.value = res?.description || '';
      variant.value = res?.variant || 'passive';
      isVisible.value = res?.showBanner;
    })();

    return {
      message,
      description,
      isVisible,
      variant,
      isFeatureEnabled,
    };
  },
});
