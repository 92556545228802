





























import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import useEmployerTrainingDetails from './useEmployerTrainingDetail';
import getCommaList from '@/utils/getCommaList';
import shortId from 'shortid';
import {
  BaseButton,
  BaseTag,
  IconButton,
  ToolTip,
  TypeDisplay,
  TypeOverline,
} from '@bambeehr/pollen';

export default defineComponent({
  components: {
    BaseButton,
    BaseTag,
    IconButton,
    ToolTip,
    TypeDisplay,
    TypeOverline,
  },
  setup() {
    const popperKey = ref();
    const { toggleEnrollmentModal, currentContent, isBundle } =
      useEmployerTrainingDetails();

    const courseList = computed(() =>
      getCommaList(currentContent.value.topics.map((t) => t?.name || ''))
    );

    // Gross hack to resolve popper js bug
    // Fixing here: BAMBO-3360
    const handleToggle = () => {
      const contentEl = document.querySelector(
        '.tool-tip__content'
      ) as HTMLElement;
      const contentIsVisible = contentEl?.offsetHeight;
      if (contentEl && contentIsVisible) {
        popperKey.value = shortId.generate();
      }
    };

    return {
      currentContent,
      isBundle,
      toggleEnrollmentModal,
      courseList,
      handleToggle,
      popperKey,
    };
  },
});
