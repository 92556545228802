import { useApolloQuery } from '@/gql/apolloWrapper';
import hasPayrollBulkuploadFeatureFlagEnabled from '@/modules/payroll/accessControl/hasPayrollBulkuploadFlag';
import { StaffList } from '@/modules/payroll/lib/flatfile/models';
import {
  BulkEarningsImportResult,
  Payroll,
  useGetCompanyStaffQuery,
} from '@/gql/generated';
import { Ref, ref } from '@nuxtjs/composition-api';

const canAccessBulkUpload = ref(false);
const showImportIntro = ref(false);
const savingImport = ref(false);
const staffList: Ref<StaffList> = ref([]);

const checkHasAccess = async () => {
  canAccessBulkUpload.value = await hasPayrollBulkuploadFeatureFlagEnabled();
};

const setSavingImport = (saving = false) => {
  savingImport.value = saving;
};

const cleanEmployeeList = (list) =>
  list.filter((i) => !!(i?.id || i?._id) && i?.checkId);

const fetchCompanyStaff = (companyId: string) => {
  const { onResult: onStaffResult } = useApolloQuery(useGetCompanyStaffQuery, {
    id: companyId,
  });

  onStaffResult(({ getCompany: res }) => {
    const employees =
      res?.employees?.map((e) => {
        return {
          ...e,
          contractor: false,
        };
      }) || [];
    const contractors =
      res?.contractors?.map((e) => {
        return {
          ...e,
          contractor: true,
        };
      }) || [];
    staffList.value = [
      ...cleanEmployeeList(employees),
      ...cleanEmployeeList(contractors),
    ].map((s) => {
      return {
        firstName: s.profile.firstName,
        lastName: s.profile.lastName,
        title: s.profile.title,
        id: s.id,
        contractor: s.contractor,
        fullName: `${s.profile.firstName} ${s.profile.lastName}`,
      };
    });
  });
};

const cancelUpload = () => {
  showImportIntro.value = false;
};

const startUpload = () => {
  showImportIntro.value = true;
};

export type ParsedUploadResponse = {
  payroll: Payroll;
  totalImported: number;
  erroredStaffNames: string[];
};
const parseUploadResponse = (
  response: BulkEarningsImportResult,
  staff: StaffList
): ParsedUploadResponse => {
  const numErrs: number = response?.errors?.length || 0;
  const numSuccess: number = response?.successfulStaffIds?.length || 0;
  const out: ParsedUploadResponse = {
    payroll: response.payroll,
    totalImported: numSuccess + numErrs,
    erroredStaffNames: [],
  };
  if (numErrs) {
    response.errors.forEach((error) => {
      const staffMember = staff.find((s) => {
        return s.id === error?.staffId;
      });
      if (staffMember) {
        out.erroredStaffNames.push(staffMember.fullName);
      }
    });
    out.erroredStaffNames.sort();
  }

  return out;
};

const useFlatfileTimeAttendance = () => ({
  canAccessBulkUpload,
  cancelUpload,
  checkHasAccess,
  fetchCompanyStaff,
  parseUploadResponse,
  savingImport,
  setSavingImport,
  showImportIntro,
  staffList,
  startUpload,
});

export default useFlatfileTimeAttendance;
