/**
 * A utility that will sum the types of employees based on a map
 * @param {Object} map key/value where the key is the employee type and the value is the number of reported ees for that type
 * @param {string[]} targetedKeys specific employee types to count. If left blank/empty the count will be based on all reported employee types
 * @returns {Number} The sum of the reported employee types
 */
const getStaffCountFromMap = (map, targetedKeys = []): number => {
  if (!map) {
    return 0;
  }

  const keys = targetedKeys.length ? targetedKeys : Object.keys(map);

  return keys.reduce((acc, key) => {
    if (typeof map[key] !== 'number') {
      return acc + 0;
    }

    return acc + map[key];
  }, 0);
};

export default getStaffCountFromMap;
