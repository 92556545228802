import { PayTypes } from '@/modules/payroll/constants/payroll';
import currency from '@bambeehr/currency';

/**
 * Ensures an Employee's pay rate is represented in hours
 * @param {String} payType Employee Pay Type. See PayTypes constant for options
 * @param {Number} payRate Employee's Pay Rate. This number represents the amount of money over the course of an agreed upon time (hour for hourly, year for salary)
 * @returns Number normalized Pay Rate when converted to hours
 */
export default function toHourlyRate(payType, payRate) {
  if (payType === PayTypes.SALARY) {
    return currency(payRate).divide(2080).value; // 2080 = 40hrs a week x 52 weeks a year
  }

  // Default handles as if already hourly
  return currency(payRate).value;
}
