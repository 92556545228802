




































import { ref, defineComponent, watch } from '@nuxtjs/composition-api';
import { BaseButton, DrawerPanel } from '@bambeehr/pollen';
import cloneDeep from 'lodash/cloneDeep';
import TaxDocumentFilters from '@/modules/payroll/components/TaxDocuments/TaxDocumentFilters.vue';

export default defineComponent({
  name: 'MobileTaxDocumentFilters',
  components: {
    BaseButton,
    DrawerPanel,
    TaxDocumentFilters,
  },
  props: {
    yearOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isFilterDrawerOpen = ref(false);
    const initialState = ref();

    const toggleFilterDrawer = () => {
      isFilterDrawerOpen.value = !isFilterDrawerOpen.value;
    };

    watch(isFilterDrawerOpen, (isOpen) => {
      if (isOpen) {
        initialState.value = cloneDeep(props.value);
      }
    });

    const resetState = () => {
      toggleFilterDrawer();

      // Allow time for animation to finish
      setTimeout(() => {
        props.value.year = initialState.value.year;
        props.value.type = initialState.value.type;
      }, 200);
    };

    return {
      isFilterDrawerOpen,
      toggleFilterDrawer,
      resetState,
    };
  },
});
