export const state = () => ({
  loaded: false,
  loading: false,
  pricePlans: [],
});

export const getters = {
  all(state) {
    return state.pricePlans;
  },
  activePlans(state) {
    return state.pricePlans.filter((p) => p.status === 'active' && !p.pause);
  },
};

export const mutations = {
  setPricePlans(state, pricePlans) {
    state.pricePlans = pricePlans;
    state.loaded = !!(pricePlans || []).length;
  },

  setLoading(state, bool) {
    state.loading = bool;
  },
};

export const actions = {
  async fetch({ commit, state }, { force = false } = {}) {
    const { loaded, loading } = state;
    if (!force && (loaded || loading)) {
      return;
    }

    const status = ['active', 'deprecated'];
    const internal = true;
    const partner = '__all__';

    commit('setLoading', true);

    try {
      const res = await this.$axios.get('/billing/billing/v2/price-plans', {
        params: {
          partner,
          internal,
          status: status.join(','),
        },
      });

      commit('setPricePlans', res.data);
    } finally {
      commit('setLoading', false);
    }
  },
};
