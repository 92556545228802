/**
 * Standardizes the Apollo watchQuery contract for predictable, App usage.
 * @param {Object} apolloClient The created apollo client for a particular module
 * @returns {Function} A function that calls Apollo's watchQuery and maps the result to App's specifications (https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.watchQuery)
 */
const watchQuery = (apolloClient) => {
  /**
   * Generic function. Does a GraphQL watchQuery
   * @param {Object} q GraphQL query to request
   * @param {Object} variables GraphQL variables for the query
   * @param {String} resultKey The property to drill down on in the GraphQL result object
   * @returns {Observable}
   */
  return (q, variables, resultKey) => {
    return apolloClient
      .watchQuery({
        query: q,
        variables,
      })
      .map((res) => {
        const { data, errors, loading } = res;

        return {
          errors,
          loading,
          data: data?.[resultKey],
        };
      });
  };
};

export default watchQuery;
