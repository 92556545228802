import { PayerType } from '@/modules/payroll/constants/payroll';
import currency from '@bambeehr/currency';
import { StaffPayItem } from './payFactory';

interface BenefitByPayerType {
  description: string;
  amount: number;
}

const getBenefitsByPayerType = (
  payerType: string,
  items: StaffPayItem[] = []
): BenefitByPayerType[] => {
  const contributionKey =
    PayerType.EMPLOYEE === payerType
      ? 'employeeContributionAmount'
      : 'companyContributionAmount';

  const myList = items
    .filter((i) => !!i.benefits)
    .map((i) => {
      return i.benefits.map((b) => {
        return {
          description: b.description,
          amount: currency(b[contributionKey]).value,
        };
      });
    });

  return myList?.length ? myList.flat() : [];
};

// Takes an array of payroll items and returns a single array of all company paid benefits
export const getCompanyContributionList = (items) =>
  getBenefitsByPayerType(PayerType.COMPANY, items);

// Takes an array of payroll items and returns a single array of all employee paid benefits
export const getEmployeeContributionList = (items) =>
  getBenefitsByPayerType(PayerType.EMPLOYEE, items);
