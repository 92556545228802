






import { defineComponent } from '@nuxtjs/composition-api';
import employerPayrollGuard from '@/modules/payroll/middleware/employerPayrollGuard';
import employerPayrollSetupGuard from '@/modules/payroll/middleware/employerPayrollSetupGuard';

export default defineComponent({
  name: 'EmployerPayrollRoot',
  middleware: [employerPayrollGuard, employerPayrollSetupGuard],
});
