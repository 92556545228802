<template>
  <div class="py-20 flex flex-col items-center px-4">
    <SitBack />
    <TypeDisplay
      tag="div"
      variant="small"
      weight="strong"
      class="text-primary-default text-center"
    >
      Sit back and relax, you're all set
    </TypeDisplay>
    <TypeBody variant="text-small" class="text-gray-2 mt-2 text-center">
      Your HR manager will reach out to you once your Bambee Payroll is
      set up
    </TypeBody>
    <BaseButton tag="nuxt-link" to="/task-center" size="giant" class="mt-6">
      Return To Bambee Home
    </BaseButton>
  </div>
</template>
<script>
import { useRoute } from '@nuxtjs/composition-api';
import { TypeDisplay, TypeBody, BaseButton } from '@bambeehr/pollen';
import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import SitBack from '@/modules/payroll/components/SitBack/SitBack.vue';

export default {
  name: 'ProviderConfirmation',
  components: {
    SitBack,
    TypeDisplay,
    TypeBody,
    BaseButton,
  },
  layout: 'wizard',
  setup() {
    const route = useRoute();
    const { upgradeFinchCode } = usePayrollService();

    const finchCode = route.value?.query?.code;

    if (finchCode) {
      upgradeFinchCode({
        code: finchCode,
      });
    }
  },
};
</script>
