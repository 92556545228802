































import { TypeBody, IconButton } from '@bambeehr/pollen';
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import PayrollCard from '@/modules/payroll/components/PayrollCard/PayrollCard.vue';

// Will replace with autogen
export interface TaxDoc {
  description?: string;
  filedOn: string;
  id: string;
  jurisdiction: string;
  label: string;
  quarter: string;
  documentClass: string;
  year: number;
  staffId?: string;
  staffInfo?: {
    id: string;
    firstName: string;
    lastName: string;
    contractorBusinessName?: string;
    isContractor: boolean;
  };
  companyInfo: {
    name: string;
  };
}
export default defineComponent({
  components: {
    IconButton,
    PayrollCard,
    TypeBody,
  },
  props: {
    doc: {
      type: Object as PropType<TaxDoc>,
      required: true,
    },
  },
});
