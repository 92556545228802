










import { defineComponent, ref } from '@nuxtjs/composition-api';
import FloatingTour from '@/components/FloatingTour';
import type { FloatingTourStep } from '@/components/FloatingTour/FloatingTour.vue';
import useTour from '@/hooks/useTour';
import type { Step } from '@/hooks/useTour/useTour';
import type { PopoverDOM } from 'driver.js';

export interface GuidedTourStep {
  imgSrc: string;
  title: string;
  description: string;
  id?: string;
  isGlobalStep?: boolean;
  onNextClick?: () => void;
  onPrevClick?: () => void;
}

// Driver.js only allows template customizations through the onPopoverRender callback
// This function adds the markup we need to the popover to style it like our non-driver popovers
const addPopoverBling = (popover: PopoverDOM, imgSrc = ''): void => {
  const progressText = popover.progress.innerText;
  const description = popover.description.innerText;
  const title = popover.title.innerText;

  const markup = `
	<div class="rounded-t-lg overflow-hidden ${imgSrc ? '' : 'hidden'}" >
		<img src="${imgSrc}" class="object-cover">
	</div>
	<div>
		<div class="px-4 pb-2">
			<div class="type-body mt-4 mb-2 type-body-text-x-small-tight type-body-text-x-small-tight--regular text-base-500">
				${progressText}
			</div>
			<div class="type-display  mb-2 type-display-tiny type-display-tiny--strong">
				${title}
			</div>
			<div class="type-body mb-4 type-body-text-x-small type-body-text-x-small--regular">
				${description}
			</div>
		</div>
	</div>
	`;

  popover.progress.style.display = 'none';
  popover.description.style.display = 'none';
  popover.title.style.display = 'none';

  popover.wrapper.insertAdjacentHTML('afterbegin', markup);
};

const mapGuidedSteps = (guidedSteps: GuidedTourStep[]): Step[] =>
  guidedSteps.map((item) => ({
    ...item,
    isActive: true,
    onPopoverRender: (popover) => {
      addPopoverBling(popover, item.imgSrc);
    },
  }));

export default defineComponent({
  name: 'FeatureTour',
  components: {
    FloatingTour,
  },
  props: {
    floatingSteps: {
      type: Array as () => Array<FloatingTourStep>,
      default: () => [],
    },
    guidedSteps: {
      type: Array as () => Array<GuidedTourStep>,
      default: () => [],
    },
    tourId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { steps, start } = useTour(props.tourId);
    steps.value = mapGuidedSteps(props.guidedSteps);

    const showFloatingTour = ref(true);
    const handleFloatingComplete = () => {
      showFloatingTour.value = false;

      // kick off guided tour here
      start();
    };

    return {
      handleFloatingComplete,
      showFloatingTour,
    };
  },
});
