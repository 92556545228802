

























import { defineComponent } from '@nuxtjs/composition-api';
import { BaseBanner, TypeDisplay, TypeBody } from '@bambeehr/pollen';

export default defineComponent({
  name: 'AnnouncementBanner',
  components: {
    BaseBanner,
    TypeDisplay,
    TypeBody,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {},
});
