import {
  useRouter,
  ref,
  computed,
  Ref,
  ComputedRef,
} from '@nuxtjs/composition-api';
import { Route } from 'vue-router';

// Declare a variable to track if the router history hook is already set up.
let isHistoryHookInitialized = false;

// Create a reactive reference to an array that stores the route history.
const routeHistory: Ref<Route[]> = ref([]);

// Create a computed property that returns the most recent route in the history array.
const previousRoute: ComputedRef<Route | undefined> = computed(() => {
  // Array.prototype.at(-1) fetches the last item from the array
  return routeHistory.value.at(-1);
});

// Setup a hook to capture route changes.
const setupHistoryHook = () => {
  // Get the current router instance
  const router = useRouter();

  // Use router's 'beforeEach' hook to track every route transition
  router.beforeEach((to, from, next) => {
    // Check if the current route is not already the last in our history array
    // If it is not, push the current route to the history array
    if (routeHistory.value?.at(-1)?.fullPath !== from?.fullPath) {
      routeHistory.value.push(from);
    }
    // Proceed to the next navigation strategy fn
    next();
  });

  // Mark history hook as initialized
  isHistoryHookInitialized = true;
};

// Provide a function to access route history and previous route
const useRouterHistory = () => {
  // If the history hook is not initialized, setup it
  if (!isHistoryHookInitialized) {
    setupHistoryHook();
  }

  // Return the history array and previous route as reactive data
  return {
    routeHistory,
    previousRoute,
  };
};

// Export the function
export default useRouterHistory;
