<template>
  <div class="file-upload" @click="openUploaderModal">
    <slot v-if="!inline">Upload</slot>
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  getCurrentInstance,
} from '@nuxtjs/composition-api';

export default {
  name: 'FileUpload',

  props: {
    singleSelect: {
      type: Boolean,
      default: false,
    },
    maxSelectSize: {
      type: Number,
      default: 50,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: Array,
      default() {
        return [
          '.pdf',
          'image/*',
          'video/*',
          'audio/*',
          'text/*',
          'application/*',
        ];
      },
    },
  },

  emits: ['files'],

  setup(props, { emit }) {
    const { $filestack: filestack, $toast: toast } = getCurrentInstance().proxy;

    const maxFiles = computed(() =>
      props.singleSelect ? 1 : props.maxSelectSize
    );

    const openUploader = () => {
      const uploaderConfig = {
        storeTo: {
          location: 's3',
        },
        accept: props.accept,
        maxFiles: maxFiles.value,
        fromSources: ['local_file_system', 'googledrive', 'onedrive'],
        onFileSelected: (file) => {
          if (!file.size) {
            toast.open({
              type: 'is-error',
              message: 'File is empty',
            });
          }
        },
        onUploadDone: (res) => {
          emit('files', res.filesUploaded);
        },
      };

      if (props.inline) {
        uploaderConfig.displayMode = 'inline';
        uploaderConfig.container = '.file-upload';
      }

      filestack.picker(uploaderConfig).open();
    };

    const openUploaderModal = () => {
      if (!props.inline) {
        openUploader();
      }
    };

    onMounted(() => {
      if (props.inline) {
        openUploader();
      }
    });

    return {
      openUploaderModal,
    };
  },
};
</script>
