import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import { ref, useStore } from '@nuxtjs/composition-api';

const onboardingLink = ref('');

const useStaff = () => {
  const store = useStore();
  const { currentUser } = store.getters;
  const isContractor = currentUser?.profile?.contractor;
  const { _id: userId } = currentUser;
  const {
    generateContractorOnboardingLink,
    generateEmployeeOnboardingLink,
    getEmployeeWithPayrollItems,
    getContractorWithPayments,
  } = usePayrollService();

  const { data: currentStaff, pending } = isContractor
    ? getContractorWithPayments(userId, '')
    : getEmployeeWithPayrollItems(userId, '');

  const getOnboardingLink = isContractor
    ? generateContractorOnboardingLink
    : generateEmployeeOnboardingLink;

  getOnboardingLink(userId, {
    data: onboardingLink,
  });

  return {
    currentStaff,
    onboardingLink,
    pending,
    isContractor,
  };
};

export default useStaff;
