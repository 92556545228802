












import { ref, watch, useRoute, computed } from '@nuxtjs/composition-api';
import { AnnularThrobber } from '@bambeehr/pollen';
import pym from 'pym.js';
import { usePartnerAuth, CLIENT } from '@/store/partnerAuth';

enum BenefitsSubRoute {
  REGISTER = 'register',
  CONNECT = 'connect',
  EMPLOYEE_CONNECT = 'employee-connect',
}

const createFrame = (src: string) => {
  return new pym.Parent('benefits-frame', src);
};

export default {
  name: 'BenefitsConnect',
  components: {
    AnnularThrobber,
  },
  setup() {
    const route = useRoute();
    const isLoadingFrame = ref(true);
    const isTransferring = computed(() => route.value.path.includes('connect'));
    const isEE = computed(() => route.value.path.includes('my-benefits'));

    const code = ref();
    (async () => {
      const auth = usePartnerAuth();
      code.value = await auth.getCode(CLIENT.BENEFITS);
    })();

    watch(code, (res) => {
      let subRoute = isTransferring.value
        ? BenefitsSubRoute.REGISTER
        : BenefitsSubRoute.CONNECT;

      if (isEE.value) {
        subRoute = BenefitsSubRoute.EMPLOYEE_CONNECT;
      }

      if (res) {
        const pymFrame = createFrame(
          `${process.env.BENEFITS_URL}/${subRoute}?code=${res.data.nonce}`
        );

        pymFrame.iframe.onload = () => {
          isLoadingFrame.value = false;
        };

        // Pym adds scrolling="no" by default, setting that to auto to allow scrolling if pym fails for whatever reason.
        pymFrame.iframe.scrolling = 'auto';
      }
    });

    return {
      isLoadingFrame,
    };
  },
};
