<template>
  <PayrollFlowLayout v-if="!isLoading && payroll" :payroll="payroll">
    <template #title>
      Payroll Submitted for {{ formattedPayroll.periodStart }} -
      {{ formattedPayroll.periodEnd }}
    </template>

    <BaseBanner
      v-if="error"
      :message="error"
      variant="error"
      :show-close="false"
    />

    <div v-else>
      <section class="mt-6 ml-40 mr-40 confirmation-summary">
        <TypeBody variant="text-small" tag="div">
          If there are no issues we will automatically process and debit your
          account
        </TypeBody>

        <TypeBody class="mb-5" tag="div" variant="link-small" weight="strong">
          {{ formattedPayroll.cashRequirement }}
        </TypeBody>

        <TypeBody tag="div" weight="strong" class="mb-5">
          <div class="flex justify-between mb-2">
            <label>Total Payroll</label>
            <span>{{ formattedPayroll.liability }}</span>
          </div>
          <div v-if="payroll.withdrawDate" class="flex justify-between mb-2">
            <label>Withdraw Date</label>
            <span>{{ formattedPayroll.withdrawDate }}</span>
          </div>
          <div class="flex justify-between">
            <label>Pay Date</label>
            <span>{{ formattedPayroll.payday }}</span>
          </div>
        </TypeBody>

        <TypeBody
          v-if="payroll.needsChecksEntered"
          tag="div"
          class="mb-6 text-left"
        >
          For workers you need to pay by check, click the Write Checks button to
          view net pay amounts and record check numbers.
        </TypeBody>

        <nav class="flex justify-start">
          <BaseButton
            type="button"
            variant="primary"
            size="large"
            tag="nuxt-link"
            to="/payroll/overview"
          >
            Back to Payroll Overview
          </BaseButton>
          <BaseButton
            type="button"
            variant="inverted"
            size="large"
            tag="nuxt-link"
            :to="`/payroll/${payrollId}/summary`"
            class="ml-4"
          >
            View Summary
          </BaseButton>

          <BaseButton
            v-if="payroll.needsChecksEntered"
            type="button"
            variant="inverted"
            size="large"
            tag="nuxt-link"
            :to="`/payroll/${payrollId}/checks`"
            class="ml-4 write-checks"
          >
            Write Checks
          </BaseButton>
        </nav>
      </section>
    </div>
  </PayrollFlowLayout>
</template>

<script>
import currency from '@bambeehr/currency';
import { BaseButton, TypeBody, BaseBanner } from '@bambeehr/pollen';
import { formatDate } from '@/utils/date';
import PayrollFlowLayout from '@/modules/payroll/layouts/PayrollFlowLayout';

import { payrollRedirectConfirmation } from '@/modules/payroll/middleware/payrollRedirect';
import { ref, computed, useRoute, useRouter } from '@nuxtjs/composition-api';

import { useGetPayrollQuery } from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';
import useConfetti from '@/hooks/useConfetti/useConfetti';

export default {
  components: {
    BaseButton,
    TypeBody,
    PayrollFlowLayout,
    BaseBanner,
  },
  setup(_, { root }) {
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const payrollResult = ref(null);
    const payrollError = ref(null);
    const payrollId = computed(() => +route.value.params?.payroll_id);
    const { celebrate } = useConfetti(root.$confetti);

    const { onResult: onPayrollResult } = useApolloQuery(
      useGetPayrollQuery,
      {
        id: payrollId,
      },
      {
        pending: isLoading,
      }
    );

    onPayrollResult(({ getPayroll: res }) => {
      payrollResult.value = res;

      if (payrollRedirectConfirmation(res, router)) {
        return;
      }

      if (res) {
        celebrate();
      }
    });

    const formattedPayroll = computed(() => {
      return {
        cashRequirement: currency(
          payrollResult.value.totals?.cashRequirement
        ).format(),
        liability: currency(payrollResult.value.totals?.liability).format(),
        periodStart: formatDate(payrollResult.value.periodStart),
        periodEnd: formatDate(payrollResult.value.periodEnd),
        withdrawDate: formatDate(payrollResult.value.withdrawDate),
        payday: formatDate(payrollResult.value.payday),
      };
    });

    return {
      isLoading,
      payroll: payrollResult,
      payrollId,
      formattedPayroll,
      error: payrollError,
    };
  },
};
</script>
