export const state = () => ({
  calendlyModal: false,
  calendlyData: {},
});

export const getters = {
  showCalendlyModal: (state) => state.calendlyModal,
  calendlyData: (state) => state.calendlyData,
};

export const mutations = {
  toggleShowCalendlyModal(state, value) {
    state.calendlyModal = value;
  },
  setCalendlyData(state, data) {
    state.calendlyData = data;
  },
};
