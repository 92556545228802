<template>
  <div v-if="canAccessBulkUpload">
    <div class="flex items-center">
      <BaseButton
        level="2"
        size="small"
        data-fs="bulk-upload-payroll-earnings"
        pre-icon="upload"
        @click="startUpload"
      >
        Import Earnings via CSV
      </BaseButton>
    </div>
    <ImportHoursIntroductionModal
      v-if="showImportIntro"
      @cancel="cancelUpload"
      @upload="
        openFlatfileModal({
          publishableKey: FLATFILE_PUBLISHABLE_KEY,
        })
      "
    />
    <SavingImportEarningsModal v-if="savingImport" />
  </div>
</template>

<script>
import { defineComponent, useStore, useRoute } from '@nuxtjs/composition-api';
import { BaseButton } from '@bambeehr/pollen';
import useNotifications from '@bambeehr/use-notifications';
import bam from '@/lib/bam';
import {
  allFields,
  numericalFields,
} from '@/modules/payroll/lib/flatfile/tna-config';
import useCurrentUser from '@/hooks/useCurrentUser';
import ImportHoursIntroductionModal from './ImportHoursIntroductionModal';
import SavingImportEarningsModal from './SavingImportEarningsModal';
import useFlatfileTimeAttendance from './useFlatfileTimeAttendance';
import { useBulkEarningsImportMutation } from '@/gql/generated';
import { useApolloMutation } from '@/gql/apolloWrapper';
import api, { FlatfileClient } from '@flatfile/api';
import { initializeFlatfile } from '@flatfile/javascript';
import { FlatfileListener } from '@flatfile/listener';
import { configToBlueprint, convertHook } from '@flatfile/v2-shims';
import { recordHook } from '@flatfile/plugin-record-hook';
import {
  validateContractorRecord,
  validateEmployeeRecord,
  getStaffError,
  flatfileTheme,
} from '@/modules/payroll/lib/flatfile/helpers';

export default defineComponent({
  name: 'FlatfileTimeAttendanceImporter',
  components: {
    BaseButton,
    ImportHoursIntroductionModal,
    SavingImportEarningsModal,
  },
  props: {
    payrollId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const { companyId } = store.getters;
    const { currentUser } = useCurrentUser();
    const { addError } = useNotifications();
    const {
      mutate: bulkUploadTimeAttendance,
      onDone: onDoneUploading,
      onError: onErrorUploading,
    } = useApolloMutation(useBulkEarningsImportMutation);

    const {
      canAccessBulkUpload,
      cancelUpload,
      checkHasAccess,
      fetchCompanyStaff,
      parseUploadResponse,
      savingImport,
      setSavingImport,
      showImportIntro,
      staffList,
      startUpload,
    } = useFlatfileTimeAttendance();
    // Can be public, can only be used to create new Spaces
    const FLATFILE_PUBLISHABLE_KEY =
      process.env.APP_ENV === 'production'
        ? 'pk_f3fd940ab95b42e4bc8a8142e3ba25a7'
        : 'pk_90f2391adcfe44febcec56f81838b251';

    checkHasAccess();
    fetchCompanyStaff(companyId);

    const handleImporterResults = async (results) => {
      setSavingImport(true);

      const bulkUploadPayload = {
        data: {
          payrollId: props.payrollId,
          results: JSON.stringify(results),
        },
      };

      bulkUploadTimeAttendance(bulkUploadPayload);

      onErrorUploading(() => {
        emit('import-result', null, 0, []);
        setSavingImport(false);
      });

      onDoneUploading(({ bulkEarningsImport: res }) => {
        const parsedResponse = parseUploadResponse(res, staffList.value);
        emit(
          'import-result',
          parsedResponse.payroll,
          parsedResponse.totalImported,
          parsedResponse.erroredStaffNames
        );
        setSavingImport(false);
      });
    };

    const oldRecordHook = (record) => {
      const match = staffList.value.find((s) => {
        return (
          s.firstName === record.firstName && s.lastName === record.lastName
        );
      });

      if (match) {
        const errs = match?.contractor
          ? validateContractorRecord(record)
          : validateEmployeeRecord(record);

        return {
          ...errs,
        };
      }

      return getStaffError(record.firstName, record.lastName);
    };

    // regex validators are currently not supported in Flatfile Platform SDK
    // https://support.flatfile.com/en/articles/7873726-how-can-i-use-regex-in-the-platform-sdk
    // thus, this field validator is duplicated here
    const numericalFieldsRecordHook = (record) => {
      const errors = {};
      numericalFields.forEach((field) => {
        const { key } = field;
        if (
          record[key] &&
          !record[key].match(/^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/)
        ) {
          errors[key] = {
            info: [
              {
                message: `Must be a positive numerical value`,
                level: 'error',
              },
            ],
          };
        }
      });

      return {
        ...errors,
      };
    };

    const flatfile = new FlatfileClient({
      token: process.env.FLATFILE_PUBLISHABLE_KEY,
    });

    const listener = FlatfileListener.create((listener) => {
      listener.use(
        recordHook('Earnings', (record) => {
          return convertHook(oldRecordHook)(record);
        })
      );
      listener.use(
        recordHook('Earnings', (record) => {
          return convertHook(numericalFieldsRecordHook)(record);
        })
      );
      listener.filter({ job: 'workbook:submitAction' }, (configure) => {
        configure.on('job:ready', async (event) => {
          const { jobId, workbookId } = event.context;
          await flatfile.jobs.ack(jobId, {
            info: 'Getting started.',
            progress: 10,
          });
          const { data: workbookSheets } = await api.sheets.list({
            workbookId,
          });
          const res = await api.records.get(workbookSheets[0].id);

          await flatfile.jobs.ack(jobId, {
            info: 'Uploading hours.',
            progress: 50,
          });
          await handleImporterResults(res.data.records);

          await flatfile.jobs.complete(jobId, {
            outcome: {
              acknowledge: true,
              message: 'Your file has been uploaded.',
            },
          });
          showImportIntro.value = false;
        });
      });
    });

    const openFlatfileModal = async ({ publishableKey }) => {
      const workbookConfig = {
        autoDetectHeaders: true,
        disableManualInput: true,
        fields: allFields,
        title: 'Upload Earnings',
        type: 'Earnings',
        theme: flatfileTheme,
      };
      const blueprint = configToBlueprint(workbookConfig);

      // configToBlueprint seems to ignore passed in 'action' config,
      // so manually setting action constraints here
      blueprint.actions[0].constraints = [
        { type: 'hasAllValid' },
        { type: 'hasData' },
      ];

      const flatfileOptions = {
        publishableKey,
        workbook: blueprint,
        name: 'Upload Space',
        listener,
        sidebarConfig: {
          showSidebar: false,
        },
        closeSpace: {
          operation: 'submitAction',
          onClose: () => {},
        },
      };
      const status = await initializeFlatfile(flatfileOptions);

      if (!status) {
        addError(
          'Earnings import is not available at this time. Please refresh and try again'
        );
        bam.track('flatfile-modal-failed');
      }
    };

    const showFlatfileImporter = () => {
      cancelUpload();
    };

    return {
      canAccessBulkUpload,
      cancelUpload,
      handleImporterResults,
      FLATFILE_PUBLISHABLE_KEY,
      showFlatfileImporter,
      openFlatfileModal,
      savingImport,
      showImportIntro,
      startUpload,
    };
  },
});
</script>
