import { BillingPeriod } from '@/constants/Billing';

const getBillingPeriodShortCopy = (billingPeriod) => {
  switch (billingPeriod) {
    case BillingPeriod.THREE_YEARS:
      return '3yrs';
    case BillingPeriod.TWO_YEARS:
      return '2yrs';
    case BillingPeriod.YEAR:
      return 'yr';
    case BillingPeriod.NINE_MONTHS:
      return '9mos';
    case BillingPeriod.HALF_YEAR:
      return '½yr';
    case BillingPeriod.QUARTER:
      return 'qtr';
    case BillingPeriod.MONTH:
      return 'mo';
    // no default
  }

  return '';
};

export default getBillingPeriodShortCopy;
