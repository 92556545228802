import { useApolloQuery } from '@/gql/apolloWrapper';
import {
  GetCompanyDelinquencyQuery,
  useGetCompanyDelinquencyQuery,
  CompanyStatus,
  useGetCompanyQuery,
} from '@/gql/generated';
import { ref, computed } from '@vue/composition-api';
import useCurrentUser from '@/hooks/useCurrentUser';
import { differenceInDays, addDays } from 'date-fns';
import { DelinquentStage } from '@/modules/Billing/hooks/useDelinquency.interfaces';

export const useDelinquency = () => {
  const LOCKED_DAYS_THRESHOLD = 30;
  const PAYROLL_LOCKED_DAYS_THRESHOLD = 60;
  const CANCELLATION_DAYS_THRESHOLD = 90;
  const { currentUser, companyId } = useCurrentUser();
  const delinquent = ref<Partial<
    GetCompanyDelinquencyQuery['getCompanyDelinquency']
  > | null>(null);
  const isPayrollCustomer = ref<boolean>(false);
  const isAdmin = computed<boolean>(() => currentUser.value?.role === 'user');
  const delinquentDays = computed<number>(() =>
    delinquent.value?.delinquentSince
      ? differenceInDays(new Date(), new Date(delinquent.value.delinquentSince))
      : 0
  );

  // todo: move to the backend (https://bambee.atlassian.net/browse/BACKEND-591)
  const cancellationDate = computed<Date | null>(() => {
    if (!delinquent.value || !delinquent.value.delinquent) {
      return null;
    }

    const delinquentDate = new Date(delinquent.value.delinquentSince);

    return addDays(delinquentDate, CANCELLATION_DAYS_THRESHOLD);
  });

  const daysTillCancellation = computed<number>(() => {
    if (!cancellationDate.value) {
      return 0;
    }

    return differenceInDays(cancellationDate.value, new Date());
  });

  const isCloseToCancellation = computed<boolean>(
    () => CANCELLATION_DAYS_THRESHOLD - delinquentDays.value < 30
  );

  // todo: move to the backend (https://bambee.atlassian.net/browse/BACKEND-591)
  const lockoutDate = computed<Date | null>(() => {
    if (!delinquent.value || !delinquent.value.delinquent) {
      return null;
    }

    const delinquentDate = new Date(delinquent.value.delinquentSince);

    return addDays(delinquentDate, LOCKED_DAYS_THRESHOLD);
  });

  // todo: move to backend (https://bambee.atlassian.net/browse/BACKEND-591)
  const isPayrollLocked = computed<boolean>(() => {
    return delinquentDays.value > PAYROLL_LOCKED_DAYS_THRESHOLD;
  });

  // todo: move to the backend (https://bambee.atlassian.net/browse/BACKEND-591)
  const payrollLockoutDate = computed<Date | null>(() => {
    if (!delinquent.value || !delinquent.value.delinquent) {
      return null;
    }

    const delinquentDate = new Date(delinquent.value.delinquentSince);

    return addDays(delinquentDate, PAYROLL_LOCKED_DAYS_THRESHOLD);
  });

  // todo: move to backend (https://bambee.atlassian.net/browse/BACKEND-591)
  const delinquentStage = computed<DelinquentStage | null>(() => {
    if (!delinquent.value?.delinquent) {
      return null;
    }

    if (delinquent.value?.lockout) {
      return DelinquentStage.LOCKED;
    }

    return DelinquentStage.NEW;
  });

  const { onResult } = useApolloQuery(useGetCompanyDelinquencyQuery, {
    data: {
      companyId: companyId.value,
    },
  });

  const { onResult: onPayrollCompanyResult } = useApolloQuery(
    useGetCompanyQuery,
    { id: companyId.value },
    undefined,
    // False to prevent unhelpful errors from surfacing to non-admin users
    { handleErrors: false }
  );

  onResult((result) => {
    delinquent.value = result.getCompanyDelinquency;
  });

  onPayrollCompanyResult((result) => {
    isPayrollCustomer.value = result.getCompany?.status === CompanyStatus.Live;
  });

  return {
    delinquent,
    delinquentStage,
    delinquentDays,
    lockoutDate,
    isAdmin,
    isPayrollCustomer,
    isPayrollLocked,
    payrollLockoutDate,
    isCloseToCancellation,
    cancellationDate, // will be moving to backend in the future
    daysTillCancellation,
  };
};

export default useDelinquency;
