export const TaskCenterContentSlugs = Object.freeze({
  HERO_CARD: 'task-center-hero-card',
  HERO_CARD_HEADING: 'task-center-hero-card-heading',
  HERO_CARD_BODY: 'task-center-hero-card-body',

  HR_PRACTICES: 'task-center-card-hr-practices',
  EMPLOYEES: 'task-center-card-employees',
  AUDIT: 'task-center-card-audit',

  STAFF_HEADING: 'task-center-card-staff-heading',
  STAFF_BODY: 'task-center-card-staff-body',
  STAFF_TITLE: 'task-center-card-staff-title',
  STAFF_TAG: 'task-center-card-staff-tag',
  STAFF_BUTTON: 'task-center-card-staff-button',

  AUDIT_HEADING: 'task-center-card-audit-heading',
  AUDIT_TITLE: 'task-center-card-audit-title',
  AUDIT_BODY: 'task-center-card-audit-body',
  AUDIT_TAG: 'task-center-card-audit-tag',
  AUDIT_BUTTON: 'task-center-card-audit-button',

  HR_PRACTICES_HEADING: 'task-center-card-hr-practices-heading',
  HR_PRACTICES_TITLE: 'task-center-card-hr-practices-title',
  HR_PRACTICES_BODY: 'task-center-card-hr-practices-body',
  HR_PRACTICES_TAG: 'task-center-card-hr-practices-tag',
  HR_PRACTICES_BUTTON: 'task-center-card-hr-practices-button',
  HR_PRACTICES_BUTTON_CONTINUE: 'task-center-card-hr-practices-button-continue',

  EMPLOYEE_VOICES_HEADING: 'task-center-card-employee-voices-heading',
  EMPLOYEE_VOICES_TITLE: 'task-center-card-employee-voices-title',
  EMPLOYEE_VOICES_BODY: 'task-center-card-employee-voices-body',
  EMPLOYEE_VOICES_BUTTON: 'task-center-card-employee-voices-button',
});

export default TaskCenterContentSlugs;
