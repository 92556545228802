import { UserRoles } from '@/constants/users';
import { ActivationStatus } from '@/lib/activationStatus';
import { StaffMember, StaffRoles, StaffStates } from '../models';

/**
 * takes in a list of users according to response from https://publicapi.dev.bambee.com/v0/app/my/employees/simplified
 * * and translates them into StaffMember objects
 */
export default function mapUsersToStaffMembers(
  users: any[] = []
): StaffMember[] {
  return onlyUnique(users).map(eachUserToStaffMember) || [];
}

export function onlyUnique(arr: any[] = []) {
  if (!arr.length) {
    return arr;
  }
  const UNIQUE_KEY = '_id';

  return [...new Map(arr.map((item) => [item[UNIQUE_KEY], item])).values()];
}

export function getUserRoles(user) {
  const roles: StaffRoles[] = [];
  if (user?.manager) {
    roles.push(StaffRoles.MANAGER);
  }
  if (user?.approver) {
    roles.push(StaffRoles.APPROVER);
  }

  if ([UserRoles.ADMIN, UserRoles.USER].includes(user.role)) {
    roles.push(StaffRoles.ADMIN);
  } else {
    roles.push(StaffRoles.EMPLOYEE);
  }

  return roles;
}

export function getStaffState(user) {
  let staffState: StaffStates = StaffStates.FORMER;
  if (user.active) {
    const userActivationStatus = user?.activation_status || null;

    // Null check to make backwards compatible with old users
    if (userActivationStatus === null) {
      if (user.states.terminated) {
        staffState = StaffStates.FORMER;
      } else if (user.states.onboard_request) {
        staffState = StaffStates.INVITED;
      } else if (user.states.onboarded) {
        staffState = StaffStates.ACTIVE;
      } else {
        staffState = StaffStates.NOT_INVITED;
      }
    } else if (
      // Original condition, but fails when missing activation_status
      !userActivationStatus ||
      userActivationStatus === ActivationStatus.CREATED
    ) {
      staffState = StaffStates.NOT_INVITED;
    } else if (userActivationStatus === ActivationStatus.EMAIL) {
      staffState = StaffStates.INVITED;
    } else {
      staffState = StaffStates.ACTIVE;
    }
  }

  return staffState;
}

function eachUserToStaffMember(user) {
  const staffState = getStaffState(user);
  const roles = getUserRoles(user);
  const active =
    user.active && user?.activation_status === ActivationStatus.ACTIVATED;
  const signatureRequired = user.documents?.some((doc: any) => !doc.signed);

  return {
    id: user._id,
    contractor: user.contractor || false,
    documents: user.documents,
    flags: user.states,
    onboardInviteSent: user.states.onboard_request,
    profile: {
      avatarUrl: user.avatar_url,
      staffName: user.name,
      jobTitle: user.title,
    },
    roles,
    staffState,
    active,
    signatureRequired,
  };
}
