import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import { StaffMember, StaffStates } from '@/modules/StaffManagement/models';
import { computed, ref } from '@nuxtjs/composition-api';

type SelectedStaffList = { [key: string]: boolean };
const selectedStaff = ref<SelectedStaffList>({});

export enum GroupSelectionState {
  DESELECTED = 'deselected',
  SELECTED = 'selected',
  INDETERMINATE = 'indeterminate',
}
type GroupSelectionStatus = { [key: string]: GroupSelectionState };
type GroupDisabledStatus = { [key: string]: boolean };

const setStaffSelection = (staffId: string, selected: boolean = true) => {
  selectedStaff.value = {
    ...selectedStaff.value,
    [staffId]: selected,
  };
};

const toggleStaffSelection = (staffId: string): void => {
  const selected = selectedStaff.value[staffId] || false;
  selectedStaff.value = {
    ...selectedStaff.value,
    [staffId]: !selected,
  };
};

const clearStaffSelections = (): void => {
  selectedStaff.value = {};
};

const deriveGroupSelectedState = (
  staff: StaffMember[]
): GroupSelectionState => {
  if (!staff || !staff.length) {
    return GroupSelectionState.DESELECTED;
  }
  if (staff.every((user) => selectedStaff.value[user.id])) {
    return GroupSelectionState.SELECTED;
  }
  if (staff.some((user) => selectedStaff.value[user.id])) {
    return GroupSelectionState.INDETERMINATE;
  }

  return GroupSelectionState.DESELECTED;
};

const useStaffSelection = () => {
  const { allStaff } = useStaffManagement();

  const deselectStaffGroup = (which: StaffStates): void => {
    allStaff.value
      .filter((user) => user.staffState === which)
      .forEach((user) => setStaffSelection(user.id, false));
  };

  const selectStaffGroup = (which: StaffStates): void => {
    if (which === StaffStates.NOT_INVITED) {
      clearStaffSelections();
      allStaff.value
        .filter(
          (user) =>
            user.staffState === StaffStates.NOT_INVITED &&
            !user.onboardInviteSent
        )
        .forEach((user) => setStaffSelection(user.id, true));
    } else {
      deselectStaffGroup(StaffStates.NOT_INVITED);
      allStaff.value
        .filter((user) => user.staffState === which)
        .forEach((user) => setStaffSelection(user.id, true));
    }
  };

  const groupSelectionStatus = computed<GroupSelectionStatus>(() => {
    return {
      [StaffStates.ACTIVE]: deriveGroupSelectedState(
        allStaff.value.filter((user) => user.staffState === StaffStates.ACTIVE)
      ),
      [StaffStates.INVITED]: deriveGroupSelectedState(
        allStaff.value.filter((user) => user.staffState === StaffStates.INVITED)
      ),
      [StaffStates.NOT_INVITED]: deriveGroupSelectedState(
        allStaff.value.filter(
          (user) => user.staffState === StaffStates.NOT_INVITED
        )
      ),
    };
  });

  const groupDisabledStatus = computed<GroupDisabledStatus>(() => {
    return {
      [StaffStates.ACTIVE]:
        groupSelectionStatus.value[StaffStates.NOT_INVITED] !==
        GroupSelectionState.DESELECTED,
      [StaffStates.INVITED]:
        groupSelectionStatus.value[StaffStates.NOT_INVITED] !==
        GroupSelectionState.DESELECTED,
      [StaffStates.NOT_INVITED]:
        groupSelectionStatus.value[StaffStates.ACTIVE] !==
          GroupSelectionState.DESELECTED ||
        groupSelectionStatus.value[StaffStates.INVITED] !==
          GroupSelectionState.DESELECTED,
    };
  });

  const selectedStaffIds = computed<string[]>(() => {
    return Object.keys(selectedStaff.value)
      .filter((staffId) => {
        return selectedStaff.value[staffId];
      })
      .sort();
  });

  const numSelectedStaff = computed<number>(() => {
    return selectedStaffIds.value.length;
  });

  const hasStaffSelected = computed<boolean>(() => {
    return numSelectedStaff.value > 0;
  });

  return {
    clearStaffSelections,
    deselectStaffGroup,
    groupDisabledStatus,
    groupSelectionStatus,
    hasStaffSelected,
    numSelectedStaff,
    selectedStaff,
    selectedStaffIds,
    selectStaffGroup,
    setStaffSelection,
    toggleStaffSelection,
  };
};

export default useStaffSelection;
