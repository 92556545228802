























































































































































import { defineComponent, ref, computed, Ref } from '@nuxtjs/composition-api';
import GlowModal from '@/components/GlowModal.vue';
import useContentful from '@/hooks/useContentful/useContentful';
import { ContentfulEntryIDs } from '@/lib/globals/ContentfulEntryIDs';
import {
  BaseButton,
  TypeBody,
  TypeDisplay,
  TypeOverline,
  AnnularThrobber,
} from '@bambeehr/pollen';
import ContentfulRichText from '@/components/contentful/ContentfulRichText';
import LoadingButtonLabel from '@/components/LoadingButtonLabel';

export default defineComponent({
  components: {
    GlowModal,
    BaseButton,
    TypeBody,
    TypeDisplay,
    ContentfulRichText,
    TypeOverline,
    AnnularThrobber,
    LoadingButtonLabel,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: 'We are updating your account.',
    },
    hasUpgraded: {
      type: Boolean,
      default: false,
    },
    loadingThread: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'learn-more', 'opt-in', 'explore'],
  setup() {
    // Prepare to fetch Contentful content
    const contentIsReady = ref(false);
    const { fetchContent } = useContentful();
    const contentfulContent: Ref<any> = ref({});

    const headerImage = computed(
      () => contentfulContent.value?.image?.fields?.file?.url || ''
    );
    const title = computed(() => contentfulContent.value?.title);
    const ctaUrl = computed(() => contentfulContent.value?.ctaUrl || '');
    const description = computed(() => contentfulContent.value?.description);

    const handleLearnMore = () => {
      window.open(ctaUrl.value, '_blank');
    };

    // Fetch the content when component is mounted
    (async () => {
      const content = await fetchContent(ContentfulEntryIDs.AI_ANNOUNCEMENT);
      contentfulContent.value = content || {};
      contentIsReady.value = true;
    })();

    return {
      contentIsReady,
      contentfulContent,
      headerImage,
      title,
      description,
      handleLearnMore,
    };
  },
});
