import { getEmployeeStatus } from '@/gql/services/payroll.service';

export enum BenefitOnboardingStatus {
  ENROLLED = 'ENROLLED',
  ENROLLING = 'ENROLLING',
  INELIGIBLE = 'INELIGIBLE',
  NOT_ENROLLING = 'NOT_ENROLLING',
  OPTED_OUT = 'OPTED_OUT',
}

const validateCompanyStatus = async (store) => {
  const { currentUser } = store.getters;

  // State is cached via apollo-client
  const { data: user } = await getEmployeeStatus(currentUser._id, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const status = user?.benefitOnboarding?.status;
  const canAccessBenefits =
    !!status && status !== BenefitOnboardingStatus.INELIGIBLE;

  return {
    canAccessBenefits,
  };
};

export default async ({ redirect, store }) => {
  const { currentUser } = store.getters;
  if (!currentUser) {
    return;
  }

  const { canAccessBenefits } = await validateCompanyStatus(store);

  if (!canAccessBenefits) {
    redirect('/');
  }
};
