import Store from '@/constants/Store';
import { OnboardingMilestoneTag } from '@/modules/OnboardingWizard/constants/OnboardingMilestoneTag';
import useCompanyStore from '@/store/companies';

const hasCompletedSomeMilestones = (store) => {
  const onboardingMilestones = useCompanyStore().onboardingMilestonesByTag(
    OnboardingMilestoneTag.INTRO
  );

  // AcknowledgeHrm is a milestone that is completed upon account creation.
  return onboardingMilestones?.some(
    (m) => !!m.status && m.milestone !== 'AcknowledgeHrm'
  );
};

export default hasCompletedSomeMilestones;
