<template>
  <div>
    <ContentBanner
      :entry-id="ContentfulBannerEntryIDs.EE_PAYROLL_PAYSTUBS"
      :ld-flat-id="FeatureFlags.CONTENT_BANNERS.EE_PAYROLL_PAYSTUBS"
      class="mb-6"
    />

    <AnnularThrobber v-if="isLoading" class="mx-auto my-32" />

    <BaseBanner
      v-else-if="!isLoading && error"
      variant="error"
      :show-close="false"
    >
      Something went wrong: {{ error }}
    </BaseBanner>

    <div v-else-if="visiblePayItems.length">
      <div class="mb-5 flex justify-between items-center">
        <TypeBody
          variant="text-large-tight"
          weight="regular"
          class="text-gray-0"
        >
          {{ pageTitle }}
        </TypeBody>
        <div v-if="filterOptions.length > 1" class="flex items-center">
          <TypeBody class="mr-2" variant="text-small"> Filter By: </TypeBody>
          <SelectInput
            v-model="currentFilter"
            size="dense"
            class="w-36"
            :options="filterOptions"
            :show-reset="false"
          />
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <DashboardTile
          v-for="{ id, netPay, note, noteVisible, payroll } in visiblePayItems"
          :key="id"
          @click="downloadPaystub(id)"
        >
          <template #heading>
            Paid on {{ payroll.payday }}
            <span class="md:hidden">- {{ netPay }}</span>
          </template>

          <template #secondary>
            <div class="flex justify-between items-center">
              <div class="hidden md:block">
                {{ netPay }}
              </div>
              <div v-if="note && noteVisible">
                <!-- Design calls for a button or a link depending on the layout -->

                <BaseLink
                  class="hidden md:block border-r border-solid border-base-400 pr-4"
                  @click.stop="toggleNoteModal(note)"
                >
                  <TypeBody tag="span" variant="caption" weight="strong">
                    View Note
                  </TypeBody>
                </BaseLink>

                <BaseButton
                  variant="inverted"
                  size="small"
                  class="mt-4 md:hidden"
                  @click.stop="toggleNoteModal(note)"
                >
                  <TypeBody tag="span" variant="caption" weight="strong">
                    View Note
                  </TypeBody>
                </BaseButton>
              </div>
            </div>
          </template>
          Pay Period: {{ payroll.periodStart }} - {{ payroll.periodEnd }}
        </DashboardTile>
      </div>
    </div>

    <div
      v-else-if="!visiblePayItems.length"
      class="flex flex-col gap-10 items-center"
    >
      <img
        class="w-80"
        src="/assets/images/payroll/slot-selling/illo-wage-right.svg"
        alt=""
      />
      <TypeBody
        variant="text-large-tight"
        weight="regular"
        class="text-center text-gray-2"
      >
        No {{ payStubsLabel }} yet, please come back after your pay day.
      </TypeBody>
    </div>
    <NoteModal
      :is-modal-open="isNoteModalOpen"
      :note="currentNote"
      @close-modal="toggleNoteModal"
    />
  </div>
</template>

<script>
import ContentBanner from '@/components/ContentBanner';
import useFileDownloader from '@/composables/useFileDownloader';
import { ContentfulBannerEntryIDs } from '@/lib/globals/ContentfulEntryIDs';
import DashboardTile from '@/modules/payroll/components/DashboardTile/DashboardTile';
import NoteModal from '@/modules/payroll/components/NoteModal/NoteModal';
import FeatureFlags from '@/constants/FeatureFlags';
import {
  PaymentMethod,
  PayrollStatus,
} from '@/modules/payroll/constants/payroll';
import employeePayrollGuard from '@/modules/payroll/middleware/employeePayrollGuard';
import { FILE_SERVER_URL } from '@/plugins/apollo/config/createApolloClient';
import { formatDate } from '@/utils/date';
import currency from '@bambeehr/currency';
import {
  AnnularThrobber,
  BaseBanner,
  BaseButton,
  BaseLink,
  SelectInput,
  TypeBody,
} from '@bambeehr/pollen';
import { computed, ref, useStore, watch } from '@nuxtjs/composition-api';
import { getYear, isBefore, isToday } from 'date-fns';
import { get } from 'lodash';
import {
  useGetEmployeePayrollItemsQuery,
  useGetContractorPaymentsQuery,
} from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';

export default {
  components: {
    AnnularThrobber,
    ContentBanner,
    NoteModal,
    BaseBanner,
    BaseButton,
    BaseLink,
    DashboardTile,
    TypeBody,
    SelectInput,
  },
  middleware: employeePayrollGuard,
  setup() {
    const store = useStore();
    const { fileDownloader } = useFileDownloader();
    const { currentUser } = store.getters;
    const isContractor = currentUser?.profile?.contractor;
    const { _id: userId } = currentUser;

    const staff = ref();
    const { errors, isLoading } = useApolloQuery(
      isContractor
        ? useGetContractorPaymentsQuery
        : useGetEmployeePayrollItemsQuery,
      { id: userId },
      { data: staff }
    );

    const error = computed(() =>
      errors.value.hasErrors ? errors.value.errors[0] : null
    );

    const payItems = computed(() => {
      const items = isContractor
        ? staff.value.getContractor?.payments
        : staff.value?.getEmployee?.payrollItems;

      if (!items) {
        return [];
      }

      return items
        ? items
            // First remove any items that have no grossPay or netPay
            .filter((i) => !!i.grossPay || !!i.netPay)
            // Then filter by payroll and payment status
            .filter(({ payroll, paymentMethod }) => {
              const paydayIsTodayOrPast =
                isToday(new Date(payroll.payday)) ||
                isBefore(new Date(payroll.payday), new Date());
              const payrollIsPaid =
                payroll.status === PayrollStatus.PAID ||
                payroll.status === PayrollStatus.PARTIALLY_PAID;
              const payrollIsApproved =
                payroll.status === PayrollStatus.PROCESSING ||
                payroll.status === PayrollStatus.PENDING;
              const payrollIsManualCheck =
                paymentMethod === PaymentMethod.MANUAL.value;

              return (
                paydayIsTodayOrPast &&
                (payrollIsPaid || (payrollIsApproved && payrollIsManualCheck))
              );
            })
            // Mapping to smaller object with only necessary data in proper format
            .map(({ id, netPay, note, noteVisible, payroll }) => {
              const { payday, periodStart, periodEnd } = payroll;

              return {
                id,
                netPay: currency(netPay).format(),
                note,
                noteVisible,
                payroll: {
                  payday: formatDate(payday),
                  periodStart: formatDate(periodStart),
                  periodEnd: formatDate(periodEnd),
                },
              };
            })
            // Sort to reverse chronological order
            .sort(
              (a, b) => new Date(b.payroll.payday) - new Date(a.payroll.payday)
            )
        : [];
    });

    // Filtering
    const filterKey = 'payroll.payday';
    const currentFilter = ref('');
    const serializeDateForFiltering = (date) =>
      date && String(getYear(new Date(date)));
    const filterOptions = computed(() => {
      const options = payItems.value.map((item) => {
        return get(item, filterKey, '');
      });

      // Get's all the paydays from the payroll items, formats them to be years, removes the duplicates, filters out the falsey values, sorts them, the reverses the sort so that the most current year is first.
      return [...new Set(options.map(serializeDateForFiltering))]
        .filter((x) => !!x)
        .sort()
        .reverse();
    });

    const visiblePayItems = computed(() => {
      return payItems.value.filter((item) => {
        const paydayYear = serializeDateForFiltering(get(item, filterKey, ''));

        return currentFilter.value === paydayYear;
      });
    });

    const updateCurrentFilter = (newVal) => {
      currentFilter.value = newVal;
    };

    // Setting initial value for filter
    const unwatchOptions = watch(
      filterOptions,
      (options) => {
        if (options?.length) {
          updateCurrentFilter(options[0]);
          unwatchOptions?.();
        }
      },
      { immediate: true }
    );

    const downloadPaystub = (payItemId) => {
      fileDownloader(
        `${FILE_SERVER_URL}/document-secure/${
          isContractor ? 'payment' : 'paystub'
        }/${payItemId}`,
        ''
      );
    };

    const isNoteModalOpen = ref(false);
    const currentNote = ref('');
    const toggleNoteModal = (note) => {
      currentNote.value = note || '';
      isNoteModalOpen.value = !isNoteModalOpen.value;
    };

    const pageTitle = isContractor ? 'Payments' : 'Pay Stubs';

    const payStubsLabel = computed(() =>
      isContractor ? 'payments' : 'pay stubs'
    );

    return {
      currentFilter,
      currentNote,
      downloadPaystub,
      error,
      filterOptions,
      isLoading,
      isNoteModalOpen,
      pageTitle,
      payItems,
      payStubsLabel,
      staff,
      toggleNoteModal,
      visiblePayItems,
      ContentfulBannerEntryIDs,
      FeatureFlags,
    };
  },
};
</script>
