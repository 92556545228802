<template>
  <div class="company-select">
    <component
      :is="hasMultipleCompanies ? 'PopoverMenu' : 'div'"
      v-if="activeCompany"
      class="static flex flex-col rounded"
      :class="[light ? 'switcher-light' : '']"
      v-bind="menuProps"
    >
      <div class="flex items-center py-1 pl-1 text-left w-full">
        <div class="flex-grow max-w-full truncate">
          <TypeBody
            tag="div"
            class="truncate"
            :class="[light ? 'text-white' : 'text-base-900']"
            variant="link-x-small-tight"
            weight="strong"
          >
            {{ activeCompanyName }}
          </TypeBody>
          <TypeOverline tag="div" class="text-base-500 truncate">
            {{ activeLocation }}
          </TypeOverline>
        </div>
        <BaseIcon
          v-if="hasMultipleCompanies"
          icon="caretDown"
          class="flex-shrink-0 ml-2 text-base-500 h-6 w-6"
        />
      </div>
    </component>
  </div>
</template>

<script>
import {
  BaseIcon,
  PopoverMenu,
  TypeBody,
  TypeOverline,
} from '@bambeehr/pollen';
import { CompanyStatuses } from '@/constants/companies';

export default {
  components: {
    BaseIcon,
    PopoverMenu,
    TypeBody,
    TypeOverline,
  },
  props: {
    active: {
      type: String,
      required: true,
    },
    companies: {
      type: Array,
      default: () => [],
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeCompany() {
      return this.companies.find((company) => company._id === this.active);
    },
    activeCompanyName() {
      return (
        (this.activeCompany?.profile?.dba || '').trim() ||
        this.activeCompany?.name
      );
    },
    activeLocation() {
      return this.activeCompany?.profile?.location_name;
    },
    companiesToDisplay() {
      // Dropdown should always show all active companies, plus inactive ones if
      // the user is currently logged into that inactive company.
      return this.companies.filter(
        (company) =>
          company.status !== CompanyStatuses.INACTIVE ||
          company === this.activeCompany
      );
    },
    hasMultipleCompanies() {
      return this.companiesToDisplay.length > 1;
    },
    menuProps() {
      return this.hasMultipleCompanies
        ? {
            items: this.companiesToDisplay.map((company) => ({
              icon: this.activeCompany === company ? 'check' : null,
              label: (company.profile?.dba || '').trim() || company.name,
              handler: () => {
                if (this.activeCompany === company) {
                  return;
                }
                this.$emit('input', company._id);
              },
            })),
          }
        : {};
    },
  },
};
</script>

<style scoped>
.popover-menu >>> .popover-menu__trigger {
  @apply bg-transparent hover:bg-primary-weak focus:bg-primary-weak border border-solid border-transparent focus:outline-none focus:border-primary-light rounded transition duration-ridiculous;
}

.popover-menu.switcher-light >>> .popover-menu__trigger {
  @apply hover:bg-primary-strong focus:bg-primary-strong;
}

.popover-menu >>> .base-icon {
  @apply text-secondary-default;
}

.popover-menu >>> .popover-menu__items {
  @apply -bottom-2 right-0 z-10 text-base-700;
}
</style>
