// TODO(jon.jandoc): All this needs to go. These should all be converted to
// utility functions. Many of these things are only used in a couple places.
// Mixins in general are bad for developer experience. This is especially bad
// for performance because we're adding all these properties to every single
// component instance.

import Vue from 'vue';

export default () => {
  const mixins = {
    computed: {
      cleavePhoneFormat() {
        return {
          delimiters: ['+1', ' ', '(', ')', ' ', '-'],
          blocks: [0, 0, 0, 3, 0, 3, 4],
        };
      },

      cleaveFEINFormat() {
        return {
          delimiters: ['-'],
          blocks: [2, 7],
          numericOnly: true,
        };
      },

      cleaveCurrencyFormat() {
        return {
          prefix: '$ ',
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          noImmediatePrefix: true,
        };
      },

      cleaveZipCode() {
        return {
          blocks: [5],
          numericOnly: true,
        };
      },

      cleaveYearFormat() {
        return {
          blocks: [4],
          numericOnly: true,
        };
      },

      current_user() {
        return this.$store.state.current_user;
      },

      paid() {
        return ['trial', 'paid', 'paying'].includes(
          this.$store.state.current_user._company.status
        );
      },

      primaryAdvisorTimeSlots() {
        return this.$store.state.advisors.primaryAdvisorTimeSlots;
      },
    },

    methods: {
      scrollTo(target) {
        const scrollto = window.document.querySelector(target);
        const container = window.document.querySelector('.body_content');

        this.$velocity(scrollto, 'scroll', {
          container,
          duration: 1500,
        });
      },

      cap_type(model) {
        switch (model) {
          case 'Pip':
            return 'pip';
          case 'Writtenwarning':
            return 'written-warning';
          case 'Verbalwarning':
            return 'verbal-warning';
          case 'Incident':
            return 'incident';
          default:
            return model;
        }
      },

      cap_label(model) {
        switch (model) {
          case 'Pip':
            return 'Performance Plan';
          case 'Writtenwarning':
            return 'Written Warning';
          case 'Verbalwarning':
            return 'Verbal Warning';
          case 'Incident':
            return 'Incident';
          default:
            return model;
        }
      },

      onboard_docs_type(type) {
        switch (type) {
          case 'employee_handbook':
            return {
              title: 'Handbook',
              type: 'handbook',
            };
          case 'harassment':
            return {
              title: 'Discrimination Policy',
              type: 'harassment',
            };
          case 'employee_agreement':
            return {
              title: 'Employee Agreement',
              type: 'employee agreement',
            };
          case 'hipaa_notice':
            return {
              title: 'HIPAA Notice',
              type: 'hipaa notice',
            };
          case 'employee_notice':
            return {
              title: 'Notice to Employee',
              type: 'notice to employee',
            };
          default:
            return {};
        }
      },

      validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(email);
      },

      employeeActionStatus(user) {
        function toTitleCase(str) {
          return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        }
        // type = 'is-danger', 'is-success', etc
        const status = {
          type: '',
          message: '',
        };

        if (!user.states) {
          return status;
        }

        const states = Object.keys(user.states);

        states.forEach((k) => {
          switch (k) {
            case '_resignation':
              if (!user.states[k]) {
                return;
              }

              if (user.states[k].status === 'started') {
                status.type = 'is-warning';
                status.message = 'Action Required';
              }

              if (
                [
                  'awaiting approval',
                  'awaiting signature',
                  'awaiting acknowledgement',
                ].includes(user.states[k].status)
              ) {
                status.type = 'is-success';
                status.message = toTitleCase(user.states[k].status);
              }

              break;
            case 'self_signup':
              if (user.states[k].registered && !user.states[k].activated) {
                status.type = 'is-warning';
                status.message = 'Action Required';
              }
              break;
            case 'termination_request':
              if (!user.states[k]) {
                return;
              }
              if (user.states.terminated) {
                status.type = 'none';
                status.message = 'Terminated';
              } else {
                status.type = 'is-warning';
                status.message = 'Action Required';
              }
              break;
            default:
              break;
          }
        });

        if (!status.message) {
          const { hidePayRate } = this.$store.getters;
          // User has completed record keeping, no action banner needed
          if (user.profile) {
            // quick & dirty workaround to deal with both plain users and employee users
            const { profile } = user;
            if (
              profile.state_work_in &&
              profile.first_name &&
              profile.last_name &&
              profile.role &&
              (profile.pay_rate || hidePayRate)
            ) {
              return status;
            }
          } else if (
            user.stateWorkIn &&
            user.firstName &&
            user.lastName &&
            user.role &&
            (user.payRate || hidePayRate)
          ) {
            return status;
          }

          status.type = 'is-error';
          status.message = 'Profile Incomplete';
        }

        return status;
      },

      errorHandler(options) {
        return (err) => this.handleError(err, options);
      },

      handleError(err, opts) {
        let options = {};
        if (typeof opts === 'string') {
          options = {
            message: opts,
            errorMessage: false,
          };
        } else if (opts === true) {
          options = {
            errorMessage: true,
          };
        } else if (typeof opts === 'function') {
          options = {
            handler: options,
          };
        }

        if (!options.doNotLog) {
          console.error(err);
        }

        // defaults
        options.type = 'is-danger';
        options.position = options.position || 'is-bottom';
        options.duration = options.duration || 5000;

        // resolve message
        if (err.response && err.response.status === 429) {
          // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/429
          options.message = 'Too Many Requests';
          const retryAfter = err.response.headers['Retry-After'];
          if (retryAfter) {
            const minutes = Math.floor(retryAfter / 60); // don't care about seconds... just minutes
            options.message += `. Please try again in ${minutes} minutes.`;
          }
        } else if (typeof options.message === 'object') {
          const messages = options.message;
          options.message =
            (err.response && messages[err.response.status]) || messages.default;
        }
        if (!options.message && options.errorMessage) {
          options.message = err.response && err.response.data;
        }
        options.message = options.message || err.message || 'Unexpected error';

        // display toast and execute additional operation if required
        const promise = this.$toast.open(options);
        if (typeof options.handler === 'function') {
          return promise.then(options.handler(err));
        }

        return promise;
      },
    },
  };

  Vue.mixin(mixins);
};
