





































import { ref, computed, defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, RadioGroup } from '@bambeehr/pollen';

export enum JurisdictionFilters {
  FED = 'FED',
  LOCAL = 'LOCAL',
  ALL = 'ALL',
}

export enum ClassFilters {
  EMPLOYEE = 'EMPLOYEE',
  CONTRACTOR = 'CONTRACTOR',
  COMPANY = 'COMPANY',
}

export const typeOptions = [
  {
    label: 'Federal',
    value: JurisdictionFilters.FED,
  },
  {
    label: 'State & Local',
    value: JurisdictionFilters.LOCAL,
  },
  {
    label: 'Workers (W2)',
    value: ClassFilters.EMPLOYEE,
  },
  {
    label: 'Contract Workers (1099)',
    value: ClassFilters.CONTRACTOR,
  },
];

export default defineComponent({
  name: 'TaxDocumentFilters',
  components: {
    RadioGroup,
    TypeBody,
  },
  props: {
    yearOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup: () => ({ typeOptions }),
});
