<template>
  <transition-group
    name="stagger-entrance"
    :css="false"
    appear
    @beforeEnter="beforeEnter"
    @afterEnter="afterEnter"
    @enter="enter"
  >
    <slot />
  </transition-group>
</template>

<script>
import '@nuxtjs/composition-api';

const Directions = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'up',
  DOWN: 'down',
});

export const Speeds = Object.freeze({
  SPEED: 250,
  STAGGER: 50,
  DELAY: 0,
});

export default {
  name: 'StaggerEntranceTransitionGroup',
  props: {
    // Time (ms) before the group starts to enter
    delay: {
      type: Number,
      required: false,
      default: Speeds.DELAY,
    },
    // Time (ms) between the start of each group item
    stagger: {
      type: Number,
      required: false,
      default: Speeds.STAGGER,
    },
    // Time (ms) duration of each group item's animation
    speed: {
      type: Number,
      required: false,
      default: Speeds.SPEED,
    },
    direction: {
      type: String,
      required: false,
      default: Directions.LEFT,
    },
  },
  setup(props) {
    let currentIndex = 0;

    return {
      beforeEnter(e) {
        e.style.animationDuration = `${props.speed}ms`;
        e.classList.add(`trans-stagger-entrance-${props.direction}-enter`);
      },
      enter: (e, complete) => {
        const thisIndex = currentIndex;
        currentIndex += 1;

        setTimeout(() => {
          e.classList.add(
            `trans-stagger-entrance-${props.direction}-enter-active`
          );
          complete();
        }, thisIndex * props.stagger + props.delay);
      },
      afterEnter: (e) => {
        e.classList.remove(`trans-stagger-entrance-${props.direction}-enter`);
      },
    };
  },
};
</script>

<style>
.trans-stagger-entrance-up-enter,
.trans-stagger-entrance-right-enter,
.trans-stagger-entrance-left-enter,
.trans-stagger-entrance-down-enter {
  opacity: 0;
}

.trans-stagger-entrance-left-enter-active {
  animation: stagger-entrance-in-left ease-out;
}

.trans-stagger-entrance-down-enter-active {
  animation: stagger-entrance-in-down ease-out;
}

.trans-stagger-entrance-up-enter-active {
  animation: stagger-entrance-in-up ease-out;
}

.trans-stagger-entrance-right-enter-active {
  animation: stagger-entrance-in-right ease-out;
}

@keyframes stagger-entrance-in-left {
  0% {
    opacity: 0;
    transform: translateX(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes stagger-entrance-in-right {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes stagger-entrance-in-down {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes stagger-entrance-in-up {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
