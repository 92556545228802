import { createUploadLink } from 'apollo-upload-client';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

/**
 * The following thunk attempts to ensure:
 * - Nuxt is ready (it needs to be before the app can load anyways)
 * - The configured axios instance, with our secure refresh token handler, is
 *   the only xhr driver being used.
 */
let axiosFetch;
function httpLinkfetch(url, options) {
  if (!window.$nuxt || !window.$nuxt.$axios) {
    console.warn('GraphQL Invoked before Nuxt was ready');

    return null;
  }
  if (!axiosFetch) {
    axiosFetch = buildAxiosFetch(window.$nuxt.$axios);
  }

  return axiosFetch(url, options);
}

export default function ({ uri, httpOptions }) {
  const {
    credentials = 'include',
    fetch = httpLinkfetch,
    fetchOptions = {},
    ...otherHttpOptions
  } = httpOptions;

  const httpLink = createUploadLink({
    uri,
    credentials,
    fetch,
    fetchOptions,
    ...otherHttpOptions,
  });

  return httpLink;
}
