import { ProductSubKey } from '@/gql/generated';

export const serviceList = [
  {
    slug: 'managed-terminations',
    key: ProductSubKey.ManagedTermination,
    name: 'Managed Terminations',
    calendlySlug: 'managed-services-brief-termination',
    schedulingCopy: {
      img: '/images/spyglass-person.svg',
      title: 'Schedule your Managed Termination Brief',
      duration: '30 minutes',
      description:
        'Your Terminations HR Expert meticulously reviews all details surrounding the upcoming termination.',
      agenda: [
        'Review Termination Details',
        'Discuss Options',
        'Determine Strategy',
      ],
    },
  },
  {
    slug: 'leave-of-absence-strategy',
    key: ProductSubKey.LeaveOfAbsenceStrategy,
    name: 'Leave of Absence Strategy',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'setting-up-hr-investigations',
    key: ProductSubKey.SettingUpHrInvestigations,
    name: 'Setting Up HR Investigations',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'job-descriptions',
    key: ProductSubKey.JobDescriptions,
    name: 'Job Descriptions',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'hr-policies',
    key: ProductSubKey.HrPolicies,
    name: 'HR Policies',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'hiring-guidance',
    key: ProductSubKey.HiringGuidance,
    name: 'Hiring Guidance',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'benefits-administration',
    key: ProductSubKey.BenefitsAdministration,
    name: 'Benefits Administration',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'org-design-consultation',
    key: ProductSubKey.OrgDesignConsultation,
    name: 'Org Design Consultation',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'immigration-consultation',
    key: ProductSubKey.ImmigrationConsultation,
    name: 'Immigration Consultation',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'manager-live-support',
    key: ProductSubKey.ManagerLiveSupport,
    name: 'Manager Live Support',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'employee-support',
    key: ProductSubKey.EmployeeSupport,
    name: 'Employee Support',
    calendlySlug: '',
    schedulingCopy: {
      img: '',
      title: '',
      duration: '',
      description: '',
      agenda: ['', '', ''],
    },
  },
  {
    slug: 'managed-corrective-actions',
    key: ProductSubKey.CorrectiveActions,
    name: 'Corrective Actions',
    calendlySlug: 'managed-services-brief-corrective-action',
    schedulingCopy: {
      img: '/images/spyglass-person.svg',
      title: 'Schedule your Corrective Action Brief',
      duration: '30 minutes',
      description:
        'Your HR Expert meticulously reviews all details surrounding the upcoming Corrective Action.',
      agenda: [
        'Review the Corrective Action details',
        'Discuss Options',
        'Determine Strategy',
      ],
    },
  },
];

export default null;
