































import { defineComponent } from '@nuxtjs/composition-api';
import {
  BaseButton,
  ModalDialog,
  TypeBody,
  BaseDivider,
} from '@bambeehr/pollen';
import useAddStaffFormRole from './useAddStaffFormRole';
import ManageCompanyRoles from '@/components/ManageCompanyRoles';

export default defineComponent({
  components: {
    BaseButton,
    ModalDialog,
    TypeBody,
    ManageCompanyRoles,
    BaseDivider,
  },
  setup() {
    const {
      showManageCompanyRolesModal,
      toggleManageCompanyRolesModal,
      getCompanyRoles,
    } = useAddStaffFormRole();

    return {
      showManageCompanyRolesModal,
      toggleManageCompanyRolesModal,
      getCompanyRoles,
    };
  },
});
