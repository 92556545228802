









import { defineComponent } from '@nuxtjs/composition-api';
import { guard } from '../middleware/canAccessEmployeeTimeAndAttendance';

export default defineComponent({
  name: 'EmployeeTimeAndAttendanceRoot',
  middleware: [guard],
});
