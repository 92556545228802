import { Survey } from '../../../types/survey';

export interface Setting {
  id: number;
  key: string;
  value: string;
}

// eslint-disable-next-line no-shadow
export enum PracticeStatuses {
  COMPLETED = 'COMPLETED',
  STARTED = 'STARTED',
  NOT_STARTED = 'NOT_STARTED',
}

// eslint-disable-next-line no-shadow
export enum PracticeState {
  ACTIVATED = 'ACTIVATED',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
}

export interface Practice {
  id: string;
  companyId: string;
  title: string;
  shortDescription: string;
  description: string;
  introDescription: string;
  shortName: string;

  iconUrl?: string;
  illustrationUrl?: string;
  outroIllustrationUrl?: string;
  successOutroTitle: string;

  status: PracticeStatuses;
  settings: Setting[];
  survey?: Survey;
  state: PracticeState;
}
