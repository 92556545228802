import FeatureFlags from '@/constants/FeatureFlags';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import { computed } from '@vue/composition-api';

const isNectarEnabled = computed(
  () => launchDarkly.getFlags()[FeatureFlags.ENABLE_NECTAR]
);

const isNectarServiceUpdateModalEnabled = computed(
  () => launchDarkly.getFlags()[FeatureFlags.ENABLE_NECTAR_UPGRADE_NOTIFICATION]
);

export const useNectarFeatureFlags = () => {
  return {
    isNectarEnabled,
    isNectarServiceUpdateModalEnabled,
  };
};

export default useNectarFeatureFlags;
