import { ProcessingPeriod } from '@/modules/payroll/constants/company';

export const getMissedDirectDepositMessage = (
  processingPeriod = ProcessingPeriod.FOUR_DAY
) => {
  return `We require ${
    processingPeriod === ProcessingPeriod.TWO_DAY ? '2' : '4'
  } business days to process direct deposit payments. Please select Manual Check as the pay method for all workers`;
};

export default { getMissedDirectDepositMessage };
