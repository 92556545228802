import Cookies from '@/constants/Cookies';
import { deleteCookie, getCookie, setCookie } from '@/lib/cookie-helpers';
import { jwtExpired } from '@/plugins/axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';
import useMiddlewareContext from './hooks/useMiddlewareContext';

let store: Context['store'];
let app: Context['app'];

const setTokenAndRefreshCurrentUser = async (
  token: string
): Promise<boolean> => {
  setCookie(Cookies.ACCESS_TOKEN, token);
  const isSuccess = await store
    .dispatch('refreshSelf', null, { root: true })
    .catch(() => false);

  return !!isSuccess;
};

const refreshAccessToken = async (
  companyName: string,
  email: string
): Promise<string> => {
  const company = {
    name: decodeURIComponent(companyName),
  };
  const user = {
    email: decodeURIComponent(email),
  };

  const { token = '' } = await app.$axios
    .$post('/v0/customer/register', {
      company,
      user,
    })
    .catch(() => ({}));

  return token;
};

const assertValidToken = async (token: string): Promise<boolean> => {
  const isTokenExpired = jwtExpired(token, 3000);
  if (isTokenExpired) {
    deleteCookie(Cookies.ACCESS_TOKEN);

    return false;
  }
  const isSuccess = await setTokenAndRefreshCurrentUser(token).catch(
    () => false
  );

  return !!isSuccess;
};

const LeadAuthTokenMiddleware: () => Promise<void> = async () => {
  const {
    route,
    app: appContext,
    store: storeContext,
  } = useMiddlewareContext();

  store = storeContext;
  app = appContext;

  const { query } = route;

  // Assert Lead Auth Token Logic
  // * Only allow for unpaid app
  // * Check for token, and validate token for expiry and authenticity
  //   * If valid token, then set the Bearer token for all API calls

  const token =
    query?.[Cookies.ACCESS_TOKEN] || getCookie(Cookies.ACCESS_TOKEN) || '';

  if (token) {
    const isValid = await assertValidToken(token);
    if (!isValid) {
      const companyName = query.company_name as string;
      const email = query.email as string;

      if (companyName && email) {
        const refreshedToken = await refreshAccessToken(companyName, email);

        if (refreshedToken) {
          await assertValidToken(refreshedToken);
        }
      }
    }
  }
};

export default LeadAuthTokenMiddleware;
