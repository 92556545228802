import { useApolloQuery } from '@/gql/apolloWrapper';

import {
  TrainingPricesResponse,
  useGetTrainingPricesQuery,
} from '@/gql/generated';
import fetchTrainingContent from '@/gql/services/trainingService/fetchContent';
import currency from '@bambeehr/currency';
import { computed, readonly, ref, Ref } from '@nuxtjs/composition-api';

// Cache
let isInit: Boolean = false;
const pending: Ref<Boolean> = ref(true);
const seatCount: Ref<number> = ref(0);
const prices: Ref<TrainingPricesResponse | null> = ref(null);

const trainingContent: Ref<any> = ref({});

const fetchContent = async () => {
  const content = await fetchTrainingContent();
  trainingContent.value = content;
  pending.value = false;

  // @ts-ignore, ignoring param error
  const { onResult: onTrainingPriceResult } = useApolloQuery(
    useGetTrainingPricesQuery
  );

  onTrainingPriceResult(({ getTrainingPrices: res }) => {
    prices.value = res;
  });
};

const purchaseinProgressText = computed(() => {
  return trainingContent.value.purchaseInProgress;
});

const legalText = computed(() => trainingContent.value.legalText);

const pricePerBundleCourse = computed(() =>
  // 4 = number of courses in bundle
  // 100 = convert to dollars
  prices.value?.bundle ? prices.value?.bundle / 4 / 100 : 0
);

const pricePerBundle = computed(() =>
  // 100 = convert to dollars
  prices.value?.bundle ? prices.value?.bundle / 100 : 0
);

const purchaseAgreementRaw = computed(() => {
  return trainingContent.value.purchaseAgreement || '';
});

const manualStaffPrompt = computed(() => {
  return trainingContent.value.manualStaffPrompt || '';
});

const purchaseButtonTextWithVariables = computed(
  () => trainingContent.value.purchaseButtonTextWithVariables
);

const trainingRegistrationContentMap = computed(() => {
  const numCoursesInBundle = 4;
  const cost: Number =
    pricePerBundleCourse.value * seatCount.value * numCoursesInBundle;

  return {
    PRICE_PER_COURSE: currency(pricePerBundleCourse.value).format(),
    EMPLOYEE_COUNT: seatCount.value,
    TOTAL_PRICE: currency(cost).format(),
  };
});

const updateSeatCount = (num: number) => {
  if (num) {
    seatCount.value = num;
  }
};

const usePurchaseTrainingContent = () => {
  if (!isInit) {
    fetchContent();
    isInit = true;
  }

  return {
    all: readonly(trainingContent),
    purchaseinProgressText,
    manualStaffPrompt,
    legalText,
    pricePerBundleCourse,
    purchaseAgreementRaw,
    purchaseButtonTextWithVariables,
    pending: readonly(pending),
    trainingRegistrationContentMap,
    updateSeatCount,
    pricePerBundle,
    prices,
  };
};

export default usePurchaseTrainingContent;
