import { sortBy } from 'lodash';
import { SelectableEmployees, StaffList } from './models';
import { contractorFields, employeeFields } from './tna-config';

export const flatfileTheme = Object.freeze({
  global: {
    backgroundColor: '#F9FAF9',
    textColor: '#121212',
    primaryTextColor: '#121212',
    secondaryTextColor: '#4F4F4F',
    successColor: '#00AB82',
    warningColor: '#EE8A2E',
  },
  buttons: {
    primary: {
      backgroundColor: '#481d72',
      border: '0 none',
    },
    secondary: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #481D72',
      color: '#481d72',
    },
    tertiary: {
      backgroundColor: '#ffffff',
      border: '1px solid #481d72',
      color: '#481d72',
    },
    headerMatchYes: {
      backgroundColor: '#481d72',
      border: '0 none',
    },
    headerMatchNo: {
      backgroundColor: '#ffffff',
      border: '1px solid #481D72',
      color: '#481d72',
    },
    success: {
      backgroundColor: '#481d72',
      border: '0 none',
    },
  },
  progressBar: {
    current: {
      color: '#481d72',
    },
    complete: {
      color: '#00AB82',
    },
    incomplete: {
      color: '#828281',
    },
  },
  header: {
    title: {
      color: '#121212',
    },
    closeButton: {
      color: '#828281',
      ':hover': {
        color: '#481d72',
      },
    },
  },
  manualInput: {
    table: {
      th: {
        backgroundColor: '#F1F3F2',
        color: '#4F4F4F',
        borderColor: '#E0E0DF',
      },
      td: {
        color: '#121212',
      },
    },
  },
  columnMatch: {
    content: {
      backgroundColor: '#ffffff',
    },
  },
  headerMatch: {
    root: {
      backgroundColor: '#ffffff',
    },
    table: {
      th: {
        backgroundColor: '#F1F3F2',
        color: '#4F4F4F',
        borderColor: '#E0E0DF',
      },
      td: {
        color: '#121212',
      },
    },
  },
  loader: {
    overlayColor: 'rgba(18, 18, 18, 0.5)',
  },
  iterator: {
    overlayColor: 'rgba(18, 18, 18, 0.5)',
  },
  dropzone: {
    content: {
      border: '3px dashed #E0E0DF',
    },
  },
});

export const staffListToSelections = (
  staffList: StaffList
): SelectableEmployees => {
  return sortBy(
    staffList.map((e) => {
      return {
        value: e.id,
        label: `${e.firstName} ${e.lastName} - ${e.title || 'N/A'}`,
      };
    }),
    ['label']
  );
};

export const recordHookInputToOutput = (record, base = {}) => {
  const merged = {
    ...record,
    ...base, // anything in base will overwrite values in record
  };
  const keys = Object.keys(merged);
  const out = {};
  keys.forEach((key) => {
    out[key] = {
      value: merged[key],
    };
  });

  return out;
};

export const validateContractorRecord = (record) => {
  const errs = {};
  employeeFields.forEach((field) => {
    const { key } = field;
    if (record[key]) {
      errs[key] = {
        info: [
          {
            message: `This field is not valid for contractors`,
            level: 'error',
          },
        ],
      };
    }
  });

  return errs;
};

export const validateEmployeeRecord = (record) => {
  const errs = {};
  contractorFields.forEach((field) => {
    const { key } = field;
    if (record[key]) {
      errs[key] = {
        info: [
          {
            message: `This field is not valid for employees`,
            level: 'error',
          },
        ],
      };
    }
  });

  return errs;
};

export const getStaffError = (firstName: string, lastName: string) => {
  return {
    firstName: {
      info: [
        {
          message: `We couldn't find a matching employee for ${firstName} ${lastName}, please update your document and try again...`,
          level: 'error',
        },
      ],
    },
    lastName: {
      info: [
        {
          message: `We couldn't find a matching employee for ${firstName} ${lastName}, please update your document and try again...`,
          level: 'error',
        },
      ],
    },
  };
};

export default null;
