

































































import {
  ref,
  useStore,
  watch,
  computed,
  useRoute,
} from '@nuxtjs/composition-api';
import {
  AnnularThrobber,
  TabControls,
  BaseBanner,
  BaseButton,
  TypeDisplay,
} from '@bambeehr/pollen';
import pym from 'pym.js';
import { getCompanyStatus } from '@/gql/services/payroll.service';
import { Routes, SubRoutes } from '../constants';
import { IPymFrame, ISsoAuthResponse } from '../models';
import useCurrentUser from '@/hooks/useCurrentUser';
import { isProd } from '@/utils/env-helper';
import DeputyTOS from '@/modules/TimeAndAttendance/components/DeputyTOS.vue';
import {
  useGetDeputyTosInfoQuery,
  useListPayrollCountQuery,
} from '@/gql/generated';
import { usePartnerAuth, CLIENT } from '@/store/partnerAuth';

import { useApolloQuery } from '@/gql/apolloWrapper';
import WelcomeVideoModal from '@/components/WelcomeVideoModal';
import useCommunicationState from '@/hooks/useCommunicationState';
// TODO lift and shift to global
import Workplaces from '@/components/Workplaces/Workplaces.vue';

const createFrame = (src: string) => {
  return new pym.Parent('time-and-attendance-frame', src);
};

export default {
  name: 'TimeAndAttendance',
  components: {
    AnnularThrobber,
    TabControls,
    DeputyTOS,
    WelcomeVideoModal,
    BaseBanner,
    BaseButton,
    TypeDisplay,
    Workplaces,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { currentUser, isOwnerAdmin } = useCurrentUser();
    const companyId = currentUser.value?.company?.id ?? store.getters.companyId;
    const code = ref<ISsoAuthResponse>();
    const host = ref<string>();
    (async () => {
      const { data: company } = await getCompanyStatus(companyId);
      const installId = company?.deputyConnection?.installId || '';
      host.value = installId ? `${installId}.na.deputy.com` : '';

      const auth = usePartnerAuth();
      code.value = await auth.getCode(CLIENT.TIME_AND_ATTENDANCE);
    })();

    const allowed = computed(() => `https://${host.value}`);
    const url = computed(() => {
      const staging = isProd ? '' : '&staging=1';

      return `${process.env.TIME_AND_ATTENDANCE_URL}/?provider=BM${staging}&host=${host.value}&code=`;
    });

    const tabs = ref(Object.values(SubRoutes));
    const options = computed(() => {
      if (isOwnerAdmin.value) {
        return tabs.value.map((t) => t.label);
      }

      return tabs.value
        .filter((t) => t.label !== SubRoutes.SETTINGS.label)
        .map((t) => t.label);
    });
    const tabFromWindow = computed(
      () =>
        tabs.value.find((t) => window.location.href.split('#').pop() === t.path)
          ?.label ?? SubRoutes.OVERVIEW.label
    );
    const tabSelected = ref(tabFromWindow.value);
    const isLoading = ref(true);
    const frame = ref<IPymFrame | null>(null);

    const tabIsWorkplaces = computed(
      () => tabSelected.value === SubRoutes.WORKPLACES.label
    );

    const navigate = (tabName) => {
      const route = tabs.value.find((t) => t.label === tabName);
      const isModal = route?.path === SubRoutes.SETTINGS.path;
      if (route) {
        const message = isModal
          ? {
              type: 'toggle-modal',
              action: 'toggle',
              id: route.path,
            }
          : {
              route: route.path,
              type: 'partner-navigation',
            };
        frame.value?.iframe?.contentWindow?.postMessage(message, allowed.value); // trigger navigation within iframe
        if (!isModal) {
          // only update the url if we not showing the settings modal
          window.history.pushState({}, '', `${Routes.ROOT}#${route.hash}`); // update the url hash... do not rerender the page
        }
        tabSelected.value = tabName;
      }
    };

    watch(code, (res) => {
      if (res) {
        const src = `${url.value}${res.data.nonce}`;
        frame.value = createFrame(src);
        if (!frame.value) {
          throw new Error('failed to initialize time and attendance frame');
        }

        frame.value.iframe.onload = () => {
          navigate(tabSelected.value);
          isLoading.value = false;
        };
        // Pym adds scrolling="no" by default, setting that to auto to allow scrolling if pym fails for whatever reason.
        frame.value.iframe.scrolling = 'auto';
      }
    });

    const { onResult, loading: isLoadingTos } = useApolloQuery(
      useGetDeputyTosInfoQuery,
      {
        id: companyId,
      }
    );

    const shouldHideModal = ref(true);
    onResult(({ getCompany: res }) => {
      // Verify that the user has not already agreed to the TOS
      // If the user is a manager but not the owner/admin, they don't have permissions to sign TOS
      shouldHideModal.value =
        !!res?.deputyTosAcceptedAt ||
        (currentUser.value?.permissions?.manager && !isOwnerAdmin.value);
    });

    const hideModal = () => {
      shouldHideModal.value = true;
    };

    const { onResult: onListResult } = useApolloQuery(
      useListPayrollCountQuery,
      {
        companyId,
      }
    );
    const { communicationState, CommunicationName } = useCommunicationState();
    const showIntroVideo = ref(false);

    onListResult(({ listPayroll: res }) => {
      // If the user doesn't have any payrolls or they have the intro query param, show the intro video when they haven't dismissed.
      showIntroVideo.value =
        (!!route.value.query?.intro || !res.totalCount) &&
        communicationState[CommunicationName.DEPUTY_WELCOME];
    });

    const toggleIntroVideo = () => {
      showIntroVideo.value = !showIntroVideo.value;

      if (!showIntroVideo.value) {
        communicationState[CommunicationName.DEPUTY_WELCOME] = false;
      }
    };

    const handleVideoSubmit = () => {
      toggleIntroVideo();
    };

    return {
      isLoading,
      navigate,
      tabSelected,
      options,
      shouldHideModal,
      hideModal,
      isLoadingTos,
      showIntroVideo,
      handleVideoSubmit,
      toggleIntroVideo,
      tabIsWorkplaces,
      companyId,
    };
  },
};
