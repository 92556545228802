import { BLOCKS, INLINES } from '@contentful/rich-text-types';

// Options found here: https://github.com/contentful/rich-text/tree/master/packages/rich-text-html-renderer#usage

export const imageOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: { fields },
      },
    }) =>
      `<img class="max-w-full my-2" src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
  },
};

export const richTextOptions = {
  renderNode: {
    ...imageOptions.renderNode,
    [BLOCKS.HEADING_1]: (node, next) =>
      `<h1 class="text-40">${next(node.content)}</h1>`,
    [BLOCKS.HEADING_2]: (node, next) =>
      `<h2 class="text-33">${next(node.content)}</h2>`,
    [BLOCKS.HEADING_3]: (node, next) =>
      `<h3 class="text-28">${next(node.content)}</h3>`,
    [BLOCKS.HEADING_4]: (node, next) =>
      `<h4 class="text-19">${next(node.content)}</h4>`,
    [BLOCKS.HEADING_5]: (node, next) =>
      `<h5 class="text-16">${next(node.content)}</h5>`,
    [BLOCKS.PARAGRAPH]: (node, next) =>
      `<p class="py-2">${next(node.content)}</p>`,
    [BLOCKS.UL_LIST]: (node, next) =>
      `<ul class="list-disc pl-4">${next(node.content)}</ul>`,
    [BLOCKS.OL_LIST]: (node, next) =>
      `<ol class="list-decimal pl-4">${next(node.content)}</ol>`,
    [INLINES.HYPERLINK]: (node, next) =>
      `<a href="${node.data.uri}"${
        node.data.uri.includes('bambee') ? '' : ' target="_blank"'
      }>${next(node.content)}</a>`,
  },
};
