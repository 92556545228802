import { getUserCourses } from '@/gql/services/trainingService';
import { toPromise } from '@/utils/observables';

let hasCourses: Boolean;
const hasCoursesAssigned = async (userId: String): Promise<Boolean> => {
  if (hasCourses) {
    return true;
  }

  const coursesResponse = await toPromise(() => {
    return getUserCourses(userId);
  });

  const courses: any[] = coursesResponse.data;
  hasCourses = !!courses?.length; // cache value

  return hasCourses;
};

export default hasCoursesAssigned;
